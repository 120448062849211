import React from "react";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import FieldDropDown from "../../shared/controls/fieldDropDown";

const FiltroEstaciones = ({ filtro, onChange }) => {
    const estatus = [
        { id: 1, nombre: "Activo" },
        { id: 2, nombre: "Inactivo" },
    ];

    return (
        <>
            <FieldTextBox name="nombre" label="Nombre" colMd={2} value={filtro.nombre} onChange={onChange} />

            <FieldDropDown
                name="estatus"
                options={estatus}
                label="Estatus"
                filter={true}
                colMd={2}
                valueById={true}
                value={filtro.estatus}
                onChange={onChange}
            />
        </>
    );
};

export default FiltroEstaciones;
