/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import moment from "moment";
import {ESTATUS_DIARIO} from "./StatusButton";
import * as numeral from "numeral";
import useApiEstatusDiarios from "../../api/reportes/useEstatusDiariosApi";
import FieldDropDown from "../../shared/controls/fieldDropDown";
import FieldTextArea from "../../shared/controls/fieldTextArea";
import FieldCalendar from "../../shared/controls/fieldCalendar";
import YupValidator from "../../shared/YupValidator";
import EstatusDiariosValidacionEsquema from "./EstatusDiariosValidacionEsquema";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import constantes from "../../catalogos/constantes";

const EditModal = ({visible, unidaDia, fecha, esDiaActual, onUpdateDatos, onHide}) => {
    const api = useApiEstatusDiarios();

    const [datos, setDatos] = useState({});
    const [viajes, setViajes] = useState([]);
    const [validation, setValidation] = useState({dirty: false, errors: {}});
    const validator = new YupValidator(EstatusDiariosValidacionEsquema);
    const disabled = !esDiaActual;

    useEffect(() => {
        if (unidaDia && visible) {
            setDatos({
                ...unidaDia,
                fechaCompromiso: unidaDia?.fechaCompromiso != null ? moment(unidaDia.fechaCompromiso)._d : null,
            });
            obtenerViajes().then();
            setValidation({dirty: false, errors: {}});
        }
    }, [unidaDia, visible]);

    const motivoSinOperador = (motivo)=>{
        return motivo?.id !== constantes.motivoEstatusDiarios.sinOperador &&
        motivo?.id !== constantes.motivoEstatusDiarios.disponibleSinOperador
    }

    const onUpdate = (value, name) => {
        const nuevos = {...datos, [name]: value};
        if (nuevos.motivo.id  === constantes.motivoEstatusDiarios.sinOperador ||
            nuevos.motivo.id  === constantes.motivoEstatusDiarios.disponibleSinOperador) {
            if (name === 'sinOperador')
                return;
            nuevos.sinOperador = true
        }

        if (nuevos.motivo.id  === constantes.motivoEstatusDiarios.disponibleConOperador){
            nuevos.sinOperador = false
        }

        setDatos(nuevos);

        if (validation.dirty) {
            validator.validate(nuevos).then(() => {
                setValidation({...validation, errors: validator.errors});
            });
        }
    };

    const onSave = async () => {
        datos.fechaCompromisoSinOperadorRequerida = motivoSinOperador(datos.motivo) && datos.sinOperador;
        const idDisponibleSinOperador = constantes.motivoEstatusDiarios.disponibleSinOperador;
        datos.noValidar =
            (datos.estatusId === ESTATUS_DIARIO.operando ||
                (datos.estatusId === ESTATUS_DIARIO.disponible &&
                    datos.motivo?.id !==idDisponibleSinOperador
                ));

        let esValido = await validator.validate(datos);


        if (esValido) {
            onUpdateDatos(unidaDia, datos);
        } else {
            setValidation({
                dirty: true,
                errors: validator.errors,
            });
        }
    };

    const obtenerViajes = async () => {
        let viajes = [];
        if (!esDiaActual) viajes = await api.viajes(unidaDia.unidadId, fecha);
        setViajes(viajes);
    };

    const renderFooter = () => {
        return (
            <div>
                {!disabled && (
                    <Button label={"Guardar"} className="guardar" icon="pi pi-check" autoFocus onClick={onSave}/>
                )}
            </div>
        );
    };

    return (
        <Dialog
            header={`Unidad ${datos?.numEconomico}`}
            visible={visible}
            position={"left"}
            modal
            style={{width: "350px"}}
            footer={renderFooter("displayPosition")}
            onHide={onHide}
            draggable={false}
            resizable={false}
        >
            <div
                className="form-g-diaria"
                style={{
                    minHeight:
                        datos.estatusId === ESTATUS_DIARIO.operando || datos.estatusId === ESTATUS_DIARIO.disponible
                            ? "200px"
                            : "600px",
                }}
            >
                {viajes.length > 0 && (
                    <div className="viajes">
                        <div className="title bold">Viajes</div>
                        <div className="font-blue bold right">{numeral(datos.facturacion).format("$0,0.00")}</div>
                        {viajes.map((viaje, index) => (
                            <React.Fragment key={index}>
                                <div className="detalle ruta">{viaje.ruta}</div>
                                <div className="detalle right">
                                    {numeral(viaje.facturacionSegmento).format("$0,0.00")}
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                )}
                {/* {datos.estatusId !== ESTATUS_DIARIO.operando && datos.estatusId !== ESTATUS_DIARIO.dispinible && */}
                <>
                    <span className="form-label">Motivo</span>
                    <div>
                        <FieldDropDown
                            name="motivo"
                            source="motivosEstatusDiarios"
                            errors={validation.errors}
                            params={{EstatusId: datos.estatusId}}
                            showClear={false}
                            value={datos.motivo}
                            onChange={onUpdate}
                        />
                    </div>
                </>
                {/* } */}
                <span className="form-label">Comentarios</span>
                <div className="comentarios">
                    <FieldTextArea
                        value={datos.comentarios}
                        rows={5}
                        name="comentarios"
                        onChange={onUpdate}
                        errors={validation.errors}
                        style={{width: "100%"}}
                    />
                </div>
                {datos.estatusId !== ESTATUS_DIARIO.operando &&
                    (datos.estatusId !== ESTATUS_DIARIO.disponible ||  datos.motivo?.id === constantes.motivoEstatusDiarios.disponibleSinOperador) &&
                    (
                    <>
                        <span className="form-label">Fecha Compromiso:</span>
                        <div>
                            <FieldCalendar
                                value={datos.fechaCompromiso}
                                showIcon
                                style={{width: "100%"}}
                                name="fechaCompromiso"
                                disabled={disabled}
                                onChange={onUpdate}
                                errors={validation.errors}
                                showButtonBar={false}
                                minDate={moment(unidaDia?.dia?.fecha)._d}
                            />
                        </div>
                    </>
                )}
                {
                    (datos.motivo?.id??0) !== 0  &&
                    motivoSinOperador(datos.motivo) &&
                    datos.motivo.id !== constantes.motivoEstatusDiarios.disponibleConOperador &&
                    (
                        <>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <span className="form-label" style={{marginRight:'10px'}}>Sin Operador</span>
                                <ButtonIcon iconName={'IoPersonSharp'}
                                            className={`operador-estatus-diario ${datos.sinOperador ? 'sin-operador' : ''}`}
                                            onClick={() => onUpdate(!datos.sinOperador, 'sinOperador')}/>
                            </div>
                            {datos.sinOperador && (
                                <>
                                    <span className="form-label">Fecha Compromiso Operador:</span>
                                    <div>
                                        <FieldCalendar
                                            value={datos.fechaCompromisoOperador}
                                            showIcon
                                            style={{width: "100%"}}
                                            name="fechaCompromisoOperador"
                                            disabled={disabled}
                                            onChange={onUpdate}
                                            errors={validation.errors}
                                            showButtonBar={false}
                                            minDate={moment(unidaDia?.dia?.fecha)._d}
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    )
                }


            </div>
        </Dialog>
    );
};

export default EditModal;
