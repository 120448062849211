import useServiceFactory from "../serviceFactory";
const apiUrl = `/rendimientos`;

const useApiDieselRendimiento = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_DIESEL_API_URL });

    return {
        filtrar: async (params) => service.get(`${apiUrl}`, { params, showLoading: true }),
        obtenerDetalle: async (params) => service.get(`${apiUrl}/detalles`, { params }),
        guardar: async (params) => service.post(`${apiUrl}`, params),
        actualizarFecha: async (params) => service.post(`${apiUrl}/actualizarFecha`, params),
        cambiarEstatusaRegistado: async (params) => service.post(`${apiUrl}/cambiarEstatusaRegistado`, params),
        cerrar: async (id, params) => service.post(`${apiUrl}/${id}/cerrar`, params),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, { showLoading: true }),
        eliminar: async (id) => service.delete(`${apiUrl}/${id}`, { showLoading: true }),
        guardarRendimientoPorArchivo: async (params) => service.post(`${apiUrl}/guardarRendimientoPorArchivo`, params),
        guardarRendimientoPdf: async (id, formData) => service.post(`${apiUrl}/${id}/guardarRendimientoPdf`, formData,{ showSuccess: true }),
        excel: async (params) =>
            service.get(`${apiUrl}/excel`, {
                params,
                showLoading: true,
                download: true,
                fileName: `Rendimientos.xlsx`,
            }),
        cancelar: async (id, ajusteId) => service.delete(`${apiUrl}/${id}/cancelar/${ajusteId}`, {}),
    };
};

export default useApiDieselRendimiento;
