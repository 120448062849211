import * as yup from "yup";

const expeditadoValidacionEsquema = yup.object().shape({
    tipoUnidad : yup.mixed().required(),
    cliente:yup.object({
        id: yup.number().required().min(1),
    }),
    subClienteId:yup.mixed().when("cliente.esLogistico",{
        is:true,
        then: yup.number().required().min(1)
    }),
    precioViaje: yup.number().required().min(1),
    comision: yup.number().required().min(0)
});

export default expeditadoValidacionEsquema;