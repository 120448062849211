import React from "react";
import { useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import agGridHelper from "../../shared/ag-grid-helper";
import useApiGeocercas from "../../api/catalogos/useApiGeocercas";
import { getSessionState } from "../../hooks/useSessionState";
import FiltroGeocercas from "./FiltroGeocercas";
import Filtro from "../../shared/componentes/filtro";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import { Sidebar } from "primereact/sidebar";
import DetalleGeocercas from "./GeocercasDetalle";
import constantes from "../constantes";
import useSeguridad from "../../Seguridad/useSeguridad";

const Geocercas = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.geocercas);
    const filtroKey = 'FiltroGeocercas';
    const [datos, setDatos] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [geocercaId, setGeocercaId] = useState(false);
    const api = useApiGeocercas();

    async function buscar(filtro, paramsApi) {
        agGridHelper.showLoad(gridApi, paramsApi, true);
        setDatos(await api.filtrar(filtro ?? getSessionState(filtroKey)));
        agGridHelper.hideLoad(gridApi, paramsApi, false);
    }

    const onGridReady = async (params) => {
        setGridApi(params.api);
    };

    async function actualizarGeocercas() {
        await api.actualizarGeocercas();
    }

    const ButtonsHeader = () => {
        return (
            <div className={'ag-grid header-icons'}>
                {!soloLectura &&
                    <ButtonIcon title={'Actualizar Geocercas'} onClick={actualizarGeocercas} iconName={'GrMapLocation'} />
                }
            </div>
        );
    };

    const editConcepto = (id) => {
        setSidebarVisible(true);
        setGeocercaId(id);
    };

    const rowButtons = ({ data, rowIndex, api, context }) => (
        <div className="ag-grid row-icons">
            <ButtonIcon title={'Editar'} iconName={'FaRegEdit'} onClick={() => context.editConcepto(data.id)} />
        </div>
    );

    async function onSave() {
        setSidebarVisible(false);
        await buscar();
    }

    return (
        <>
            <Sidebar visible={sidebarVisible} position="right" dismissable={false} className="p-sidebar-md" onHide={() => setSidebarVisible(false)}>
                <DetalleGeocercas geocercaId={geocercaId} onSave={onSave} close={() => setSidebarVisible(false)} soloLectura={soloLectura} />
            </Sidebar>
            <Filtro filtroKey={filtroKey} handleSubmit={buscar} defaultValue={{ nombre: '', activa: true }} gridApi={gridApi}
                classButtons="field-iconos btn-group">
                <FiltroGeocercas />
            </Filtro>
            <div className="ag-theme-balham grid-principal com-conceptosCobro">
                <AgGridReact
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    className=""
                    frameworkComponents={{
                        rowButtons: rowButtons,
                        gotoDetail: agGridHelper.GoToDetailFormatter,
                        buttonsHeader: ButtonsHeader,
                        dateFormatter: agGridHelper.DateFormatter,
                        imageFormatter: agGridHelper.ImageFormatter
                    }}
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    rowHeight={35}
                    context={{
                        editConcepto: editConcepto
                    }}>
                    <AgGridColumn field="nombre" headerName="Nombre" text cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn field="latitud" headerName="Latitud" text cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn field="longitud" headerName="Longitud" text cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn field="radio" headerName="Radio" text cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn field="prohibida" headerName="Geocerca Prohibida" text cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn field="puntoInteres" headerName="Es Punto de Interes" text cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn maxWidth={80}
                        headerName="VER"
                        cellRenderer="rowButtons"
                        pinned={'right'}
                        cellClass={'left'}
                        headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>
        </>
    );
};

export default Geocercas;