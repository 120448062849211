import React from "react";
import FieldInputNumber from "../../shared/controls/fieldInputNumber";
import produce from "immer";

const ParametroComercial = ({
  parametrosPorkm,
  parametrosPorDia,
  rendimientoDieselPorKmDefault,
  rendimientoDieselPorKm,
  costoDiesel,
  precioPorKilometro,
  onChange,
  errors,
}) => {

  function actualizarLista(lista, index, value, propertyName, namelist) {
    const nuevo = produce(lista, (draft) => {
      draft[index][propertyName] = value;
    });

    onChange(nuevo, namelist);
  }

  return (
    <>
      <div style={{ background: "white" }}>
        <div style={{ width: "100%" }} className={`section-container`}>
          <h5 style={{ width: "100%" }}>Comercial</h5>

          <div style={{ textAlign: "-webkit-center" }} className="flex-col-12">
            <table
              style={{ width: "100%", fontSize: "smaller" }}
              className="table table-borderless center"
            >
              <thead>
                <tr>
                  <th>COSTO DIESEL</th>
                  <th>RENDIMIENTO &#40; KM/LT &#41; (Rabon,Ton) </th>
                  <th>RENDIMIENTO &#40; KML/LT &#41; (Tractor)</th>
                  <th>PRECIO POR KILOMETRO</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <FieldInputNumber
                      label=""
                      className="small"
                      name="costoDiesel"
                      showButtonBar={false}
                      value={costoDiesel}
                      errors={errors[`costoDiesel`]}
                      fractionDigits={2}
                      onChange={(value, pName) =>
                        onChange(value, "costoDiesel")
                      }
                    />
                  </td>

                  <td>
                    <FieldInputNumber
                      label=""
                      className="small"
                      name="rendimientoDieselPorKm"
                      showButtonBar={false}
                      value={rendimientoDieselPorKm}
                      fractionDigits={2}
                      errors={errors[`rendimientoDieselPorKm`]}
                      onChange={(value, pName) =>
                        onChange(value, "rendimientoDieselPorKm")
                      }
                    />
                  </td>

                  <td>
                    <FieldInputNumber
                      label=""
                      className="small"
                      name="rendimientoDieselPorKmDefault"
                      showButtonBar={false}
                      fractionDigits={2}
                      value={rendimientoDieselPorKmDefault}
                      errors={errors[`rendimientoDieselPorKmDefault`]}
                      onChange={(value, pName) =>
                        onChange(value, "rendimientoDieselPorKmDefault")
                      }
                    />
                  </td>

                  <td>
                    <FieldInputNumber
                      label=""
                      className="small"
                      name="precioPorKilometro"
                      showButtonBar={false}
                      fractionDigits={2}
                      value={precioPorKilometro}
                      errors={errors[`precioPorKilometro`]}
                      onChange={(value, pName) =>
                        onChange(value, "precioPorKilometro")
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="flex-col-7">
            <div className="form-row">
              <table
                style={{ width: "100%", fontSize: "smaller" }}
                className="com-parametro table table-borderless center"
              >
                <thead>
                  <tr>
                    <th colSpan="4">PARAMETROS POR KM</th>
                    <th>PARAMETRO POR MILLA</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th>NOMINA</th>
                    <th>MANTENIMIENTO</th>
                    <th>GASTOS ADM</th>
                    <th>NOMINA</th>
                  </tr>
                </thead>
                <tbody>
                  {parametrosPorkm?.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td>{item.tipoUnidad}</td>
                          <td>
                            <FieldInputNumber
                                label=""
                                className="small"
                                name="nomina"
                                showButtonBar={false}
                                fractionDigits={2}
                                value={item.nomina}
                                errors={errors[`parametrosPorkm[${index}]`]}
                                onChange={(value, pName) =>
                                    actualizarLista(
                                        parametrosPorkm,
                                        index,
                                        value,
                                        pName,
                                        "parametrosPorkm"
                                    )
                                }
                            />
                          </td>
                          <td>

                            <FieldInputNumber
                                label=""
                                className="small"
                                name="mantenimiento"
                                showButtonBar={false}
                                fractionDigits={2}
                                value={item.mantenimiento}
                                errors={errors[`parametrosPorkm[${index}]`]}
                                onChange={(value, pName) =>
                                    actualizarLista(
                                        parametrosPorkm,
                                        index,
                                        value,
                                        pName,
                                        "parametrosPorkm"
                                    )
                                }
                            />
                          </td>
                          <td>
                            <FieldInputNumber
                                label=""
                                className="small"
                                name="gastosAdmin"
                                fractionDigits={2}
                                showButtonBar={false}
                                value={item.gastosAdmin}
                                errors={errors[`parametrosPorkm[${index}]`]}
                                onChange={(value, pName) =>
                                    actualizarLista(
                                        parametrosPorkm,
                                        index,
                                        value,
                                        pName,
                                        "parametrosPorkm"
                                    )
                                }
                            />
                          </td>
                          <td>
                            <FieldInputNumber
                                label=""
                                className="small"
                                name="precioMilla"
                                showButtonBar={false}
                                fractionDigits={2}
                                value={item.precioMilla}
                                errors={errors[`parametrosPorkm[${index}]`]}
                                onChange={(value, pName) =>
                                    actualizarLista(
                                        parametrosPorkm,
                                        index,
                                        value,
                                        pName,
                                        "parametrosPorkm"
                                    )
                                }
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex-col-5">
            <div className="form-row">
              <table
                  style={{width: "100%", fontSize: "smaller"}}
                  className="com-parametro table table-borderless center">
                <thead>
                <tr>
                  <th colSpan="3">PARAMETROS POR DIA</th>
                </tr>
                <tr>
                  <th></th>
                  <th>COSTO DE UNIDAD</th>
                  <th>SEGURO DE UNIDAD</th>
                </tr>
                </thead>
                <tbody>
                {parametrosPorDia?.map((item, index) => {
                  return (
                      <>
                        <tr>
                        <td>{item.tipoUnidad}</td>

                          <td>

                            <FieldInputNumber
                              label=""
                              className="small"
                              name="costoDiario"
                              showButtonBar={false}
                              fractionDigits={2}
                              value={item.costoDiario}
                              errors={errors[`parametrosPorDia[${index}]`]}
                              onChange={(value, pName) =>
                                actualizarLista(
                                  parametrosPorDia,
                                  index,
                                  value,
                                  pName,
                                  "parametrosPorDia"
                                )
                              }
                            />
                          </td>
                          <td>

                            <FieldInputNumber
                              label=""
                              className="small"
                              name="seguroDiario"
                              showButtonBar={false}
                              fractionDigits={2}
                              value={item.seguroDiario}
                              errors={errors[`parametrosPorDia[${index}]`]}
                              onChange={(value, pName) =>
                                actualizarLista(
                                  parametrosPorDia,
                                  index,
                                  value,
                                  pName,
                                  "parametrosPorDia"
                                )
                              }
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParametroComercial;
