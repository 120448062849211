/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import moment from "moment";
import * as numeral from "numeral";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import produce from "immer";
const Rendimiento = ({liquidacion,rendimientos,esOperador,onChange ,soloConsulta}) => {
    const actualizarRendimientos = (value, propertyName, index) => {
        const newValue = produce(rendimientos, (draft) => {
            let row = draft[index];
            row[propertyName] = value;      
          
        });

        onChange(newValue, 'rendimientos');
    }
    return (
        <>
            <div className="form-row">
                <table className="table table-borderless">
                    <thead className="thead-light">
                    <tr>
                        <th style={{ width: '5%' }}></th>
                        <th style={{width: '25%'}}>FECHA</th>
                        <th style={{width: '25%'}}>DIFERENCIA (LT)</th>
                        <th style={{width: '25%'}}>AJUSTE</th>
                        <th style={{width: '50%'}}></th>                        
                    </tr>
                    </thead>
                    <tbody>
                    {liquidacion.rendimientos?.map((item, index) => {
                        return (
                            <tr key={index}>
                                {!soloConsulta && liquidacion.aplazarMovimientos && esOperador ?
                                    <td>
                                        <FieldCheckBox name="seleccionado" value={item.seleccionado} label={item.tipo} colMd={12}
                                            onChange={(value, pname) => actualizarRendimientos(value, pname, index)} />
                                    </td>
                                    :
                                    <td>{item.tipo}</td>
                                }
                                <td>{moment(item.fecha).format('DD/MM/YYYY hh:mm a')}</td>
                                <td>{item.diferenciaLitros}</td>
                                <td>{numeral(item.ajuste).format('$0,0.00')}</td>
                                <td className="p-d-flex p-flex-column">
                                    <div>
                                        # Exhibiciones: <span className="bold">{item.numeroDeParcialidades}</span>
                                        <br/>
                                        # Pago: <span className="bold">{item.parcialidad}/{item.numeroDeParcialidades}</span>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </>
    )
};

export default Rendimiento;
