/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import * as numeral from "numeral";
import moment from "moment";
import IconLibrary from "../../../shared/iconLibrary";
import {produce} from "immer";

const Viajes = ({viajes, onChange, soloLectura}) => {

    const actualizar = (value, propertyName, index) => {
        const newValue = produce(viajes, (draft) => {
            draft[index][propertyName] = value;
        });

        onChange(newValue,'viajes');
    };

    return (
        <>
            <div className="form-row">
                <table className="table table-borderless">
                    <thead className="thead-light">
                    <tr>
                        {!soloLectura && <th/>}
                        <th style={{width: '15%'}}>FECHA</th>
                        <th style={{width: '15%'}}>CARTA PORTE</th>
                        <th style={{width: '40%'}}>VIAJE</th>
                        <th style={{width: '15%'}}>COMISIÓN</th>
                        <th style={{width: '5%'}}>RV</th>
                    </tr>
                    </thead>
                    <tbody>
                    {viajes?.map((item, index) => {
                        return (
                            <tr key={item.viajeId}>
                                {!soloLectura &&
                                <td>
                                    <FieldCheckBox name="seleccionado" value={item.seleccionado}
                                                   disabled={!item.seleccionable || soloLectura}
                                                   onChange={(value, pname) => actualizar(value, pname, index)}
                                    />
                                </td>                            
                                }

                                <td>{moment(item.fecha).format('DD/MM/YYYY hh:mm a')}</td>
                                <td>{item.cartaPorte}</td>
                                <td className="p-d-flex p-flex-column">
                                    <span className="cliente">{item.cliente}</span>
                                    <span>{item.ruta}</span>
                                    <div>
                                        Unidad: <label className="bold">{item.unidad}</label> | caja: <span
                                        className="bold">{item.caja}</span>
                                    </div>
                                </td>
                                <td>{numeral(item.comision).format('$0,0.00')}</td>
                                <td>
                                    {item.reciboDeValor &&
                                    <span className="check-blue">
                                        <IconLibrary iconName="BsCheck"/>
                                    </span>
                                    }
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </>
    )
};


export default Viajes;
