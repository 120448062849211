/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { MultiSelect } from "primereact/multiselect";
import useSourceUtils from "../useSouceUtils";
import { useReadOnly } from "../forms/ReadOnlyProvider";

const FieldMultiSelect = ({
    colMd,
    colSm,
    label,
    name,
    options,
    source,
    required,
    colLg,
    onChange,
    onBlur,
    value,
    error,
    errors,
    orderBy,
    showFilter,
    valueAsId,
    maxSelectedLabels,
    params,
    disabled,
    styles,
}) => {
    valueAsId = valueAsId ?? false;

    const { readOnly } = useReadOnly() ?? {};
    disabled = disabled ?? readOnly;

    const { currentOptions, setSelectedId, getSelected } = useSourceUtils(
        value,
        options,
        source,
        params,
        true,
        valueAsId,
        orderBy
    );

    const onDropChange = (event) => {
        const ids = event.value?.map((e) => e.id) ?? [];
        setSelectedId(ids);
        const value = valueAsId === true ? ids : event.value;
        fireChange(name, value);
    };

    function fireChange(itemName, itemValue) {
        if (onChange != null) {
            onChange(itemValue == null ? [] : itemValue, itemName);
        }
    }

    return (
        <>
            {label && (
                <div
                    className={`form-group d-flex flex-column col-lg-${colLg ?? colMd} col-md-${colMd ?? "4"} col-sm-${
                        colSm != null ? colSm : "12"
                    }`}
                >
                    <label htmlFor={name}>
                        {required ? "*" : ""}
                        {label}
                    </label>
                    <MultiSelect
                        value={getSelected()}
                        id={name}
                        options={currentOptions}
                        onChange={onDropChange}
                        className={`prime-form-control ${error || errors?.[name] ? "error" : ""}`}
                        optionLabel="nombre"
                        filter={showFilter}
                        showClear={true}
                        filterBy="nombre"
                        maxSelectedLabels={maxSelectedLabels ?? 2}
                        // placeholder={`Seleccionar ${label}`}
                        onBlur={onBlur}
                        disabled={disabled}
                    />
                </div>
            )}
            {!label && (
                <MultiSelect
                    value={getSelected()}
                    id={name}
                    options={currentOptions}
                    onChange={onDropChange}
                    className={`prime-form-control ${error || errors?.[name] ? "error" : ""}`}
                    optionLabel="nombre"
                    filter={showFilter}
                    style={styles}
                    filterBy="nombre"
                    placeholder={`Seleccionar`}
                    maxSelectedLabels={maxSelectedLabels ?? 2}
                    onBlur={onBlur}
                    disabled={disabled}
                />
            )}
        </>
    );
};

export default FieldMultiSelect;
