import useServiceFactory from "../serviceFactory";

const apiUrl = `/administracion/AjusteDiesel`;

const useApiAjusteDiesel = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, { params, showLoading: true }),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, { showLoading: true }),
        guardar: async (params) => service.post(`${apiUrl}`, params),
        actualizar: async (id, params) => service.put(`${apiUrl}/${id}`, params),
        excel: async (params) => service.get(`${apiUrl}/excel`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `AjustesDiesel.xlsx`
            })
    }
};

export default useApiAjusteDiesel;
