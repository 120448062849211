import * as yup from "yup";

const usuariosValidacionEsquema = yup.object().shape({
    nombres: yup.string().required(),
    email: yup.string().email('Correo Inválido').required(),
    username: yup.string().required(),
    patioUbicacion: yup.mixed().required(),
    password: yup.string()
        .trim()
        .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$/ , 'La contraseña debe tener al menos una letra mayúscula, una minúscula y un número')
        .when("id", {
            is: 0,
            then: yup.string().required()
        }),
    perfiles: yup.array().min(1, 'debe seleccionar al menos un perfil'),
    empleado: yup.mixed().required(),
});

export default usuariosValidacionEsquema;
