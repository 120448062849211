import { useState } from "react";
import { ESTATUS_DIARIO, MOTIVO } from "./StatusButton";

const useDias = () => {
    const [dias, setDias] = useState([]);
    const iniciar = (arregloDias) => {
        const mapDias = arregloDias.map((e) => {
            return {
                ...e,
                ...totales(e.unidades),
                unidades: new Map(e.unidades.map((u) => [u.unidadId, u])),
            };
        });
        setDias(mapDias);
    };

    const totales = (unidades) => {
        let result = {
            operando: 0,
            operandoPorcentaje: 0,
            conProblemas: 0,
            conProblemasPorcentaje: 0,
            enMantenimiento: 0,
            enMantenimientoPorcentaje: 0,
            disponible: 0,
            disponiblePorcentaje: 0,
            esOtraEmpresa: 0,
            esOtraEmpresaNoOPeracion: 0,
            estotalUnidades: 0,

            conProblemasOtraEmpresa: 0,
            enMantenimientoOtraEmpresa: 0,
            OperandoOtraEmpresa: 0,
            sinOperador: 0,
            indiciplina: 0,
            permiso: 0,
            correctivoPreventivo: 0,
            reparacionMayor: 0,
            siniestroGarantia: 0,

            sinOperadorPorcentaje: 0,
            indiciplinaPorcentaje: 0,
            permisoPorcentaje: 0,
            correctivoPreventivoPorcentaje: 0,
            reparacionMayorPorcentaje: 0,
            siniestroGarantiaPorcentaje: 0,
            facturando: 0,
            enTransito: 0,
            movimientos: 0,
            disponibleSinOperador: 0,
            disponibleConOperador: 0,
            sensoresGPSRótulación: 0,
            sinOperadorCount:0
        };

        for (let unidad of unidades) {
            result.estotalUnidades++;

            if (unidad.esOtro) continue;

            if (unidad.esOtraEmpresa) {
                result.esOtraEmpresa++;
            }

            if (unidad.esUnidadParaConteo) {
                if (unidad.motivo !== null) {
                    if (unidad.motivo.id === MOTIVO.sinOperador) result.sinOperador++;

                    if (unidad.motivo.id === MOTIVO.indiciplina) result.indiciplina++;

                    if (unidad.motivo.id === MOTIVO.permiso) result.permiso++;

                    if (unidad.motivo.id === MOTIVO.correctivoPreventivo) result.correctivoPreventivo++;

                    if (unidad.motivo.id === MOTIVO.reparacionMayor) result.reparacionMayor++;

                    if (unidad.motivo.id === MOTIVO.siniestroGarantia) result.siniestroGarantia++;

                    if (unidad.motivo.id === MOTIVO.facturando) result.facturando++;
                    if (unidad.motivo.id === MOTIVO.enTransito) result.enTransito++;
                    if (unidad.motivo.id === MOTIVO.movimientos) result.movimientos++;
                    if (unidad.motivo.id === MOTIVO.disponibleSinOperador) {
                        result.disponibleSinOperador++;
                    }
                    if (unidad.motivo.id === MOTIVO.disponibleConOperador) result.disponibleConOperador++;
                    if (unidad.motivo.id === MOTIVO.sensoresGPSRótulación) result.sensoresGPSRótulación++;
                }

                switch (unidad.estatusId) {
                    case ESTATUS_DIARIO.conProblemas:
                        result.conProblemas++;

                        break;
                    case ESTATUS_DIARIO.enMantenimiento:
                        result.enMantenimiento++;

                        break;
                    case ESTATUS_DIARIO.operando:
                        result.operando++;

                        break;
                    default:
                        result.disponible++;
                }

                if (unidad.sinOperador)
                    result.sinOperadorCount++;
            }
        }
        const dispo =
            result.operando +
            // result.esOtraEmpresa -
            result.esOtraEmpresaNoOPeracion -
            result.enMantenimientoOtraEmpresa -
            result.conProblemasOtraEmpresa;
        const total = result.conProblemas + dispo + result.disponible + result.enMantenimiento;

        //const total = result.operando + result.enMantenimiento + result.conProblemas + result.disponible;
        if (total > 0) {
            result.operandoPorcentaje = dispo / total;
            result.enMantenimientoPorcentaje = result.enMantenimiento / total;
            result.conProblemasPorcentaje = result.conProblemas / total;
            result.disponiblePorcentaje = result.disponible / total;
            result.operando = result.operando + result.OperandoOtraEmpresa;

            result.sinOperadorPorcentaje = result.sinOperador / total;
            result.indiciplinaPorcentaje = result.indiciplina / total;
            result.permisoPorcentaje = result.permiso / total;
            result.correctivoPreventivoPorcentaje = result.correctivoPreventivo / total;
            result.reparacionMayorPorcentaje = result.reparacionMayor / total;
            result.siniestroGarantiaPorcentaje = result.siniestroGarantia / total;
            result.sinOperadorCountPorcentaje = result.sinOperadorCount / total;
        } else result.disponiblePorcentaje = 1;

        return result;
    };

    const actualizar = (fecha, datos) => {
        const nuevos = dias.map((dia) => {
            if (dia.fecha !== fecha) {
                return dia;
            }
            dia.unidades.set(datos.unidadId, datos);
            return { ...dia, ...totales(dia.unidades.values()) };
        });

        setDias(nuevos);
    };

    return {
        dias,
        iniciar,
        actualizar,
    };
};

export default useDias;
