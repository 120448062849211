/* eslint-disable react-hooks/exhaustive-deps */
import {Dialog} from "primereact/dialog";
import React, {useEffect, useState} from "react";
import ExGrid from "../../shared/Grid/ExGrid";
import ExColumn from "../../shared/Grid/ExColumn";
import useApiLiberacionViaje from "../../api/administracion/useApiLiberacionViaje";
import {fechaUtc} from "../../shared/Grid/GridCellRenderers";
import FieldScanner from "../../shared/controls/fieldScanner";

const DocumentoHistorial = ({viajeId, documentoId, onClose})=>{
    const visible = documentoId != null;
    const [datos, setDatos] = useState([])

    const api = useApiLiberacionViaje();

    useEffect(()=>{
        load().then();
    },[documentoId])

    const load = async ()=>{
        if (documentoId == null)
            return;

        const datos = await api.historialDocumentos(viajeId,documentoId)
        setDatos(datos);
    }

    return(
        <Dialog header="Historial" visible={visible} style={{ width: '800px' }}
                onHide={onClose}>
            <ExGrid data={datos}>
                <ExColumn title={'Accion'} field={'accion'}></ExColumn>
                <ExColumn title={'Usuario'} field={'auditUser'}></ExColumn>
                <ExColumn title={'Archivo'} field={'urlArchivo'} cellRenderer={({value})=><FieldScanner value={value} disabled={true}/>}></ExColumn>
                <ExColumn title={'Fecha'} field={'fechaUtc'} cellRenderer={fechaUtc}></ExColumn>
            </ExGrid>
        </Dialog>
    )
}

export default DocumentoHistorial;