import React from "react";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import produce from "immer";
import FieldInputNumber from "../../../shared/controls/fieldInputNumber";
import FieldInputNss from "../../../shared/controls/customInputs/fieldInputNss";
import constantes from "../../../catalogos/constantes";
import useSeguridad from "../../../Seguridad/useSeguridad";

const IMMS = ({seguridadSocial,dataTipoEmpleado, name, onChange, errors}) => {
    function actualizar(value, propertyName) {

   
        const nuevo = produce(seguridadSocial ?? {}, draft => {
            draft[propertyName] = value
        });
        onChange(nuevo, name);
    }
    const seguridad = useSeguridad();
    const permisoInfonavit = seguridad.tieneAcceso(constantes.opciones.PermisoInfonavit);

    return (
        <>
            <div className="form-row">
                <h5 className="col-12">Seguro Social</h5>
                {/* <FieldTextBox name="nss" keyFilter="int" label="NSS" colMd={6} errors={errors}
                                  value={ seguridadSocial?.nss } onChange={actualizar}/> */}

               <FieldInputNss required name="nss" label="NSS" colMd={6} errors={errors}
                               value={ seguridadSocial?.nss }  onChange={actualizar}/> 

                <FieldTextBox name="unidadMedica" label="Unidad Médica" colMd={6} errors={errors}
                              value={ seguridadSocial?.unidadMedica }  onChange={actualizar}/>
                <FieldInputNumber required name="salarioIMMS" label="Salario IMMS" colMd={6} fractionDigits={2} errors={errors}
                    disabled={dataTipoEmpleado?.esOperador} value={dataTipoEmpleado?.esOperador? dataTipoEmpleado.calculoSalarioIMSS : seguridadSocial?.salarioIMMS  } onChange={actualizar}/>
                <FieldInputNumber required name="descuentoIMMS" label="Descuento IMMS" colMd={6} fractionDigits={2} errors={errors}
                  disabled={dataTipoEmpleado?.esOperador} value={dataTipoEmpleado?.esOperador? dataTipoEmpleado.calculoDescuentoIMSS : seguridadSocial?.descuentoIMMS  } onChange={actualizar}/>
                {(permisoInfonavit &&
                      <>
                       <h5 className="col-12">Créditos</h5>
                        <FieldTextBox name="creditoInfonavit" label="Crédito Infonavit" colMd={6} keyFilter="int" errors={errors}
                                        value={ seguridadSocial?.creditoInfonavit } onChange={actualizar}/>
                        <FieldInputNumber name="descuentoInfonavit" label="Descuento Infonavit" colMd={6} fractionDigits={2} errors={errors}
                                        value={ seguridadSocial?.descuentoInfonavit } onChange={actualizar}/>
                        <FieldInputNumber name="descuentoFONACOT" label="Descuento FONACOT" colMd={6} fractionDigits={2} errors={errors}
                                        value={ seguridadSocial?.descuentoFONACOT } onChange={actualizar}/>

                      </>
                    )}
            </div>
        </>
    )
};

export default IMMS;
