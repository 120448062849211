import React, { useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import agGridHelper from "../../shared/ag-grid-helper";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import { getSessionState } from "../../hooks/useSessionState";
import Filtro from "../../shared/componentes/filtro";
import FieldMultiSelect from "../../shared/controls/fieldMultiSelect";
import { Sidebar } from "primereact/sidebar";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import useApiLiquidaciones from "../../api/administracion/useApiLiquidaciones";
import LiquidacionDetalle from "./liquidacionDetalle";
import alerts from "../../shared/alerts";
import constantes from "../../catalogos/constantes";
import FieldCheckBox from "../../shared/controls/fieldCheckBox";
import useSeguridad from "../../Seguridad/useSeguridad";
const Liquidacion = ({ esquemaPago, semanaLaboral, soloLectura, consulta }) => {
    const filtroKey = 'FiltroLiquidacion' +
        ((esquemaPago === constantes.esquemaPago.liquidacion) ? "Operativa" :
            ((esquemaPago === constantes.esquemaPago.staff) ? "Staff" :
                (esquemaPago === constantes.esquemaPago.oficina) ? "Oficina" : "")
        );

    const esOperador = esquemaPago === constantes.esquemaPago.liquidacion;
    const [datos, setDatos] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [liquidacion, setLiquidacion] = useState({});
    const apiLiquidacion = useApiLiquidaciones();
    const seguridad = useSeguridad();
    const permisoEmpleadosEnBaja = seguridad.tieneAcceso(constantes.opciones.LiquidacionEmpleadosBaja);
    let liquidacionEnProceso = false; 
    const onGridReady = async (params) => {
        setGridApi(params.api);
    };

    function ObtenerFiltro(filtro) {
        filtro = filtro ?? getSessionState(filtroKey);
        return { ...filtro, esquemaPago: esquemaPago}
    }

    async function buscar(filtro, paramsApi) {
        agGridHelper.showLoad(gridApi, paramsApi, true);
        filtro = ObtenerFiltro(filtro)
        let datos = await apiLiquidacion.filtrar(filtro);
        setDatos(datos);
        agGridHelper.hideLoad(gridApi, paramsApi, false);

    }
  
   
    const verMovimiento = async (empleadoId) => {
        let filtro = ObtenerFiltro();
        await apiLiquidacion.obtener(empleadoId, filtro).then(liquidacionA => {
                setSidebarVisible(true);
                setLiquidacion(liquidacionA);
            })       
    };

    

    function exportar() {
        let filtro = ObtenerFiltro()
        apiLiquidacion.excel(filtro);
    }

    async function onSave() {
        setSidebarVisible(false);
        await buscar();
    }

   

    async function liquidar(api) {
        if (liquidacionEnProceso) {
            alerts.aviso('La liquidación ya está en proceso. Por favor, espere.');
            return;
        }    
        let rows = api.getSelectedRows();
        if (rows.length === 0) {
            alerts.aviso('Debe seleccionar mínimo un empleado para liquidar');
            return;
        }    
        liquidacionEnProceso = true;    
        if ((await alerts.preguntarSiNo("¿Está seguro de generar la liquidación?")) === true) {
            try {
                const ids = rows.map(e => e.empleado.id).join(",");
                await apiLiquidacion.liquidacionAdministrativa(ids);
                onSave();
            } catch (error) {
                console.error('Error al generar la liquidación:', error);
                alerts.error('Se produjo un error al generar la liquidación. Por favor, inténtelo de nuevo.');
            } finally {         
                liquidacionEnProceso = false;
            }
        } else {
            liquidacionEnProceso = false;
        }
    }
    

    const ButtonsHeader = ({ api }) => {
        return (
            <div className="ag-grid header-icons">
                <>{!esOperador && !soloLectura &&
                    <ButtonIcon title="Liquidar" onClick={() => liquidar(api)} iconName="BiListCheck" />
                }
                </>
                <ButtonIcon title="Descargar" onClick={exportar} iconName="BiDownload" />
            </div>
        );
    };

    const RowButtons = ({ data } ) => (
       
        <div className="ag-grid row-icons">
            <button className="btn btn-link btn-sm" title={'Editar'} onClick={() => verMovimiento(data.empleado.id)}>
                Ver
            </button>
        </div>
    );
     
    return (
        <>
            <Filtro filtroKey={filtroKey} handleSubmit={buscar} filtrarAlInicio={false}>
                <Filtros esOperador={esOperador} esquemaPago={esquemaPago} semanaLaboral={semanaLaboral} consulta= {consulta} permisoEmpleadosEnBaja={permisoEmpleadosEnBaja} />
            </Filtro>

            <Sidebar visible={sidebarVisible} position="right" dismissable={false} className="p-sidebar-xl"
                onHide={() => setSidebarVisible(false)}>
                <LiquidacionDetalle liquidacionActual={liquidacion} esOperador={esOperador}
                    onClose={() => setSidebarVisible(false)} soloLectura={soloLectura} esquemaPago={esquemaPago} />
            </Sidebar>

            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    frameworkComponents={
                        {
                            buttonsHeader: ButtonsHeader,
                            rowButtons: RowButtons,
                            imageFormatter: agGridHelper.ImageFormatter
                        }
                    }
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    rowHeight={35}
                    rowSelection={'multiple'}
                >
                    <AgGridColumn maxWidth={40} headerCheckboxSelection={true} checkboxSelection={true} hide={esOperador} />
                    <AgGridColumn maxWidth={45} cellClass={'imagen'} cellRenderer="imageFormatter" />
                    <AgGridColumn field="empleado.nombre" headerName="NOMBRE" flex={5} minWidth={280} cellClass={'left'} headerClass={'left'} />
                    <AgGridColumn field="totalComision" headerName="COMISIÓN" headerClass={'right'} cellClass={'right'} hide={!esOperador}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalPrestamos" headerName="PRÉSTAMOS" headerClass={'right'} cellClass={'right'}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalCajaAhorro" headerName="CAJA AHORRO" headerClass={'right'} cellClass={'right'}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalPrestamoCajaAhorro"  headerName="PRÉSTAMO CAJA AHORRO" flex={5} minWidth={180} headerClass={'right'} cellClass={'right'}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalAnticipos" headerName="ANTICIPOS" headerClass={'right'} cellClass={'right'} hide={!esOperador}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalGastos" headerName="GASTOS" maxWidth={80} headerClass={'right'} cellClass={'right'}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalCompensaciones" headerName="COMPENSACIONES" headerClass={'right'} cellClass={'right'} hide={!esOperador}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalDescuentos" headerName="DESCUENTOS" headerClass={'right'} cellClass={'right'} hide={!esOperador}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalGastosCajaChica" headerName="CAJA CHICA" headerClass={'right'} cellClass={'right'} hide={!esOperador}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalAjuste" headerName="AJUSTE" headerClass={'right'} cellClass={'right'} hide={!esOperador}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalBonos" headerName="BONOS" headerClass={'right'} cellClass={'right'}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalVacaciones" headerName="VACACIONES" headerClass={'right'} cellClass={'right'}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn  flex={4} field="totalPrimaVacacional" headerName="PRIMA VACACIONAL" headerClass={'right'} cellClass={'right'}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalDescuentos" headerName="DESCUENTOS" headerClass={'right'} cellClass={'right'}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalOtros" headerName="OTROS" headerClass={'right'} cellClass={'right'}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalImpuestos" headerName="IMPUESTOS" headerClass={'right'} cellClass={'right'}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    {/* {liquidacion.aplazarMovimientos  && */}
                        <AgGridColumn field="sueldoBase" headerName="SUELDO BASE" headerClass={'right'} cellClass={'right'}                      
                        valueFormatter={agGridHelper.currencyFormatter} />
                    {/* } */}
                    <AgGridColumn flex={3} field="totalPensionAlimenticia" headerName="PENSION ALIMENTICIA" headerClass={'right'} cellClass={'right'}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="total" headerName="POR LIQUIDAR" headerClass={'right'} cellClass={'right'}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn maxWidth={80}
                        headerName="VER"
                        cellRenderer="rowButtons"
                        pinned={'right'}
                        cellClass={'left'}
                        headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>
        </>
    )
};

const Filtros = ({ filtro, onChange, esOperador, esquemaPago, semanaLaboral,consulta,permisoEmpleadosEnBaja }) => {   
    const consultaParams = {
        operadores: esOperador,
        esquemaPago: esquemaPago,
        enBaja: filtro.enBaja 
    };

    return (
        <>
            <FieldMultiSelect name="empleadosId" source={consulta}  label="Empleado" colLg={2} colMd={3}
                value={filtro.empleadosId}   params={{ ...consultaParams }} onChange={onChange} showFilter valueAsId={true} />
            <div className="form-group col-md-4 col-sm-12">
                <label>Rango Nómina</label>
                <div className="col-auto">
                    <FieldTextBox name="nominaDe" colLg={2} placeholder={'Desde'}
                        value={filtro.nominaDe} onChange={onChange} />
                    <FieldTextBox name="nominaHasta" colLg={2} placeholder={'Hasta'}
                        value={filtro.nominaHasta} onChange={onChange} />
                    {(permisoEmpleadosEnBaja &&
                        <div className="col-md-4 col-sm-12">
                            <div className="col-auto">
                                <FieldCheckBox name="enBaja" colLg={8} colMd={6}
                                    label="Empleado en Baja" 
                                    value={filtro.enBaja ?? false}   onChange={onChange}/>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="form-group col-md-6 col-sm-12 right" >
                <label><b>Semana Laboral:</b> </label>
                {
                    semanaLaboral.fechaInicio && semanaLaboral.fechaFin &&
                    <span>
                        {` ${semanaLaboral.fechaInicio} - ${semanaLaboral.fechaFin}`}
                    </span>
                }
            </div>
        </>
    )
};

export default Liquidacion;
