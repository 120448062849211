import useServiceFactory from "../serviceFactory";

const apiUrl = `/administracion/asignacionEquipos`;

const useApiAsignacionEquipos= () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, { params, showLoading: true }),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, { showLoading: true }),
        obtenerAsignacionActual: async (id) => service.get(`${apiUrl}/ObtenerAsignacionActual/${id}`, { showLoading: true }),
        guardar: async (params) => service.post(`${apiUrl}`, params),
        desasignar: async (id) => service.put(`${apiUrl}/${id}`, { showLoading: true }),
    }
};

export default useApiAsignacionEquipos;
