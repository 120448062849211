import YupValidator from "../YupValidator";

//manejador que contiene los métodos comunes a las formas
class FormHandler {
    //parámetros del manejador
    //validation schema: el esquema de validación que se aplicará cuando se mande a llamar el save
    //void onSave(entity): función que se mandará a llamar después de que se guarde la forma
    //entitySaved apiSave(entity): función se llamará al momento de guardar la forma //usualmente será la función que llame al api del servidor debe regresar la entidad guardada para que sea enviada
    //          como parámetro al método onSave
    //void dispatch(action:{}): dispatch del form reducer que utilizará el manejador para cambiar los estados de la forma
    //errors onSetErrors(error): método que se llamará después de validar la forma y pasará como parámetro los errores y deberá regresar dichos errores modificados
    constructor({validationSchema, onSave, api, dispatch, onSetErrors}) {
        this.validador = new YupValidator(validationSchema);
        this.onSave = onSave;
        this.api = api;
        this.dispatch = dispatch;
        this.onSetErrors = onSetErrors;
        this.valdation = null;
    }

    setValidacionSchema(validacionSchema){
        this.validador = new YupValidator(validacionSchema);
    }

    //intenta gurdar la entidad y setea el valor
    async save(entity, callValidation, apiGuardar) {
        this.valdation = {result : true};
        if (callValidation !== false) {
            this.valdation = await this.validate(entity);
            this.dispatch({type: 'submitted'});
        }
        if (this.valdation.result === true) {
            let guardar = apiGuardar ?? this.api.guardar;
            const savedEntity = (entity.id??0) === 0 ? await  guardar(entity) : await this.api.actualizar(entity.id,entity);

            if (this.onSave != null) {
                this.onSave(savedEntity);
            }

            this.dispatch({type: 'saved', savedEntity: {...entity, ...savedEntity} });

            return savedEntity == null ? entity : savedEntity;
        }

        return entity;
    }

    async validateSubmit(entity){
        this.valdation = await this.validate(entity);
        this.dispatch({type: 'submitted'});
    }

    async validate(entity) {
        const result = await this.validador.validate(entity);
        const errors = this.onSetErrors == null ? this.validador.errors : this.onSetErrors(this.validador.errors);
        this.dispatch({type: 'validate', errors});
        return {result, errors};
    }
}

export default FormHandler;
