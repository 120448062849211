/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import {defaultGridStyle} from "./Styles";
import FormBody from "./FormBody";

const Form = ({values, onChange, onValidate,  columns, flow, width, submitted, formStyle, className, formDisabled, ...props}) => {

    const allChildren = React.Children.toArray(props.children);
    const fragments = allChildren.filter(e=>e.type === React.Fragment);
    const children = [...allChildren.filter(e=>e.type !== React.Fragment ), ...fragments.flatMap(e=>React.Children.toArray(e.props.children))];

    const [validations, setValidations] = useState(new Set());
    const [cancelValidations, setCancelValidations] = useState([]);

    useEffect(()=>{
        if (cancelValidations.length > 0 ) {
            for (let field of cancelValidations) {
                validations.delete(field);
            }
            setValidations(new Set(validations));
            setCancelValidations([]);
        }
    },[cancelValidations])


    const handleValidate = (field, error) => {
        const newValue = validations;
        if (error){
            newValue.add(field);
        }
        else {
            newValue.delete(field);
        }
        setValidations(new Set(newValue));
        if (onValidate != null)
            onValidate(newValue.size > 0);
    }


    const onDestroyField = (field)=>{
        if (validations != null && validations.has(field)){
            validations.delete(field);
            setValidations(new Set(validations));
        }
    }


    return (
        <div style={{...defaultGridStyle(width, columns),...formStyle}} className={`${submitted? 'submitted':''} ${className??''}`} >
            <FormBody
                values={values}
                onChange={onChange}
                onValidate={handleValidate}
                onDestroy= {onDestroyField}
                formDisabled={formDisabled}
                fields={children}
                submitted={submitted}
            />
        </div>
    )
}

export default Form;

Form.propTypes = {
    initialValues:PropTypes.object,
    columns: PropTypes.number,
    width:  PropTypes.number,
    gridStyle:PropTypes.object,
    submitted:PropTypes.bool
};

