import moment from "moment";
import { useState } from "react";
import useApiRecepcionRenovado from "../../../api/taller/useApiRecepcionRenovadoLlanta";
import Field from "../../../shared/forms/Field";
import Form from "../../../shared/forms/Form";
import FormDropDown from "../../../shared/forms/FormFields/FormDropDown";
import FormInputNumber from "../../../shared/forms/FormFields/FormInputNumber";
import FormTextArea from "../../../shared/forms/FormFields/FormTextArea";
import FormTextBox from "../../../shared/forms/FormFields/FormTextBox";
import SidebarEditForm from "../../../shared/forms/SideBarEditForm";
import FormCalendar from "../../../shared/forms/FormFields/FormCalendar";
import constantes from "../../constantes";

const RecepcionRenovadoDetalle = ({ recepcionRenovadoId, onSaved, onCancel, soloLectura }) => {
    const api = useApiRecepcionRenovado();
    const [readOnly, setReadOnly] = useState(false);

    const handleObtener = async (id) => {
        const entity = await api.obtener(id);
        return { ...entity };
    };

    const handleSave = async (entity) => {
        await api.actualizar(entity.id, entity);
    };

    const handleChange = (entity) => {
        setReadOnly(entity?.estatusId !== constantes.estatusRenovacionLlanta.envioRenovado);
    };

    const rechazar = async(id) => {
        await api.rechazar(id);
    };

    return (
        <>
            <SidebarEditForm id={recepcionRenovadoId}
                initialValues={{ fechaRecepcion: moment().startOf('day')._d }}
                getByIdFunction={handleObtener}
                saveFunction={handleSave}
                onSaved={onSaved}
                saveText={"Aceptar Renovado"}
                onCancel={onCancel}
                showCancelButton={true}
                deleteFunction={api.eliminar}
                showDeleteClosedButton={(!readOnly || !soloLectura)}
                deleteClosedFunction={rechazar}
                closedText={"Rechazar Renovado"}
                onChangeForm={handleChange}
                formDisabled={(readOnly || soloLectura)}>
                <Form columns={2}>
                    <Field field={"idCautin"} label={"Id (Cautín)"} required={true} cols={1} render={FormTextBox} disabled={true} />
                    <Field field={"proveedorId"} label={"Proveedor"} source="proveedorRenovadoLlanta" required={true} cols={1} 
                    render={FormDropDown} />
                    <Field field={"folio"} label={"Factura / Nota"} required={true} cols={1} render={FormTextBox} />
                    <Field field={"conceptoId"} label={"Concepto"} source="renovadoLlanta" required={true} cols={1} render={FormDropDown} />
                    <Field field={"profundidad"} label={"Profundidad"} required={true} cols={1} render={FormInputNumber} mode="decimal" maxFractionDigits={2} />
                    <Field field={"costo"} label={"Costo"} required={true} cols={1} render={FormInputNumber} mode="decimal" maxFractionDigits={2} />
                    <Field field={"fechaEnvio"} cols={1} label={"Fecha de Envio"} render={FormCalendar} disabled={true}/>                   
                    <Field field={"fechaRecepcion"} cols={1} label={"Fecha de Recepcion"} render={FormCalendar}/>                     
                    <Field field={"observaciones"} cols={2} label={"observaciones"} required={true} render={FormTextArea} />
                </Form>
            </SidebarEditForm>
        </>
    )
};

export default RecepcionRenovadoDetalle;