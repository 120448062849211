/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {RadioButton} from "primereact/radiobutton";
import useSourceUtils from "../../useSouceUtils";
import useInputValidation from "./useInputValidation";


const FormRadioButtonList = ({value,onChange,parent, field, options,  source, orderBy,required, validators, onValidate, params ,disabled, formDisabled}) => {
    const {
        currentOptions,
        setSelectedId
    } = useSourceUtils(value, options, source, params, true, true,  orderBy);

    const {validationErrorCss} = useInputValidation({
        value,
        field,
        required,
        validators,
        onValidate,
        parent
    });


    const onSelected =(id) => {
        setSelectedId(id)
        onChange(id);
    }

    return (
        <div className={`form-radiobutton-list ${validationErrorCss}`}
             style={{
                    display:"flex"
                }}>
            {currentOptions.map((option, index) => {
                return (
                    <React.Fragment key={index}>
                        <div className={`p-field-radiobutton`} style={{marginRight:'10px'}}>
                                <RadioButton inputId={`${field}_${index}`} name={option.nombre} value={option.id}
                                             disabled={disabled ?? formDisabled}
                                             onChange={(e) => onSelected(e.value)}
                                             checked={value === option.id}/>
                            <label htmlFor={`${field}_${index}`}>{option.nombre}</label>
                        </div>
                    </React.Fragment>
                )
            })}
        </div>
    )
};

export default FormRadioButtonList;

