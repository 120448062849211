/* eslint-disable react-hooks/exhaustive-deps */
import PDFViewer from "mgr-pdf-viewer-react";
import React, { useEffect, useState } from "react";

const ResponsivaPdfViewer = ({ pdfUrl }) => {
    const [mostrar, setMostrar] = useState(false);
    const [url, setUrl] = useState(pdfUrl);

    function actualizarUrl() {
        setUrl(pdfUrl);
    }
    useEffect(() => {
        setMostrar(true);
        actualizarUrl();
    }, []);
    useEffect(() => {
        const timer = setTimeout(actualizarUrl, 500);
        return () => clearTimeout(timer);
    }, [pdfUrl]);

    return (
        <>
            {mostrar &&
                <PDFViewer document={{ url: url }} />
            }
        </>
    );
};

export default ResponsivaPdfViewer;
