/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import InputFile from "../../controls/inputFile";
import useInputValidation from "./useInputValidation";
import {RequiredInputValidator} from "../validators";


const FormInputFile = ({value, field, parent, onChange, required, formDisabled, validators, fileTypes, onValidate,...props}) => {

    if (required)
        validators = [...(validators??[]), new RequiredInputValidator() ]

    const {validationErrorCss} = useInputValidation({
        value,
        field,
        required:false,
        validators,
        onValidate,
        parent
    });

    return (
        <>
            <InputFile value={value} disabled={formDisabled} onChange={onChange} fileTypes={fileTypes} className={validationErrorCss} />
        </>
    )
}

export default FormInputFile;
