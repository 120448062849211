import useServiceFactory from "./serviceFactory";
const useApiMenus = () =>
{
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return{
        obtenerTodos: async (token) => {
            const result = await service.get(`/seguridad/menus`, {token})
            return result;
        },
    }
};

export default useApiMenus ;


