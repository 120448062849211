/* eslint-disable react-hooks/exhaustive-deps */
import React  from 'react'
import SideBarEditForm from "../../../shared/forms/SideBarEditForm";
import Form from "../../../shared/forms/Form";
import Field from "../../../shared/forms/Field";
import FormTextBox from "../../../shared/forms/FormFields/FormTextBox";
import FormDropDown from "../../../shared/forms/FormFields/FormDropDown";
import useApiDieselTanque from "../../../api/taller/useApiDieselTanque";
import FormCalendar from "../../../shared/forms/FormFields/FormCalendar";
import FormInputNumber from "../../../shared/forms/FormFields/FormInputNumber";
import {fechaMaximaSolicitud, fechaMinimaSolicitud} from "../../../reportes/cajaChica/CajaConstantes";
import moment from "moment";
import {tiposProveedor} from "../DieselConstantes";

const CargaDetalle = ({combustibleId, cargaId, onSaved, onCancel, tanques}) => {
    const api = useApiDieselTanque();
    const tanqueId = tanques.length > 1 ? null : tanques[0]?.id;
    const ubicaciones = tanques.map(e=> { return  {id:e.id, nombre:e.ubicacion.nombre}});

    return (
        <>
            <SideBarEditForm id={cargaId}
                             initialValues={{
                                 enabled:true,
                                 fechaRegistro: moment().startOf('day')._d,
                                 combustibleId,
                                 tanqueId
                             }}
                             getByIdFunction={api.obtenerCarga}
                             saveFunction={api.guardarCarga}
                             onSaved={onSaved}
                             onCancel={onCancel}
                             showCancelButton ={true}
            >
                <Form columns={2}>
                    <h5 style={{gridColumnEnd:'span 2'}}>
                        Agregar Carga
                    </h5>
                    <Field field={"fechaRegistro"} label={"Fecha"} required={true}  minDate={fechaMinimaSolicitud} maxDate={fechaMaximaSolicitud} render={FormCalendar}/>
                    <Field field={"ordenCompra"} label={"Orden de Compra"} required={true} render={FormTextBox}/>
                    <Field field={"litros"} label={"Litros"} required={true} mode="decimal" maxFractionDigits={2} render={FormInputNumber}/>
                    <Field field={"precio"} label={"Precio"} required={true} mode="decimal" maxFractionDigits={2} render={FormInputNumber}/>
                    {
                        tanques?.length > 1 &&
                        <Field field={"tanqueId"} label={"Ubicacion"} required={true} options={ubicaciones} render={FormDropDown}/>
                    }
                    <Field field={"proveedorId"} label={"Proveedor"}  source="diesel/proveedores" params={{tipoProveedorId:tiposProveedor.pipas}} required={true} render={FormDropDown}/>
                    {/*<Field field={"Controlador"} label={"Controlador"} render={FormTextBox} disabled={true}/>*/}
                </Form>
            </SideBarEditForm>
        </>
    )
}

export default CargaDetalle;
