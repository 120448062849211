import React from "react";
import { TabPanel, TabView } from "primereact/tabview";
import useSessionState from "../../hooks/useSessionState";
import Vacaciones from "./vacaciones";
import VacacionesPorAutorizar from "./vacacionesPorAutorizar";
import constantes from "../../catalogos/constantes";
import useSeguridad from "../../Seguridad/useSeguridad";

const VacacionesSecciones = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.vacaciones);
    const [activeIndex, setActiveIndex] = useSessionState('tabSeguimiento', 0);

    return (
        <>
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="main-tab-view">
                <TabPanel headerStyle={(seguridad.tieneAcceso(constantes.menus.vacacionesSeccion) || soloLectura) ? { display: "inline" } : { display: "none" }} header="Vacaciones">
                    <Vacaciones soloLectura={soloLectura} />
                </TabPanel>
                <TabPanel headerStyle={seguridad.tieneAcceso(constantes.menus.vacacionesporAutorizar) ? { display: "inline" } : { display: "none" }} header="Vacaciones por Autorizar">
                    <VacacionesPorAutorizar soloLectura={soloLectura} />
                </TabPanel>
            </TabView>
        </>
    )
};

export default VacacionesSecciones;
