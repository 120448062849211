import React from "react";
import {useEmpresa} from "../../layout/EmpresaProvider";

const ImageFormatter = ({data, colDef }) =>{
    const {empresaId} = useEmpresa();
    return (
    <img alt=""
         height={30}
         width={30}
         style={{borderRadius:'50%'}}
         src={`${process.env.REACT_APP_API_URL}/archivos/imagen/${empresaId}?url=${data.imageUrl??''}`}
    />
)};

export default ImageFormatter;
