import { MultiStateCheckbox } from "primereact/multistatecheckbox";
import React, { useContext } from "react";
import { GridContext } from "./CajaChica";
import constantes from "../../catalogos/constantes";
import useSeguridad from "../../Seguridad/useSeguridad";

const CheckBoxRenderer = ({ data, ...api }) => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.cajaChica);
    const { grid, setGrid } = useContext(GridContext);

    const options = [
        { value: true, icon: `pi pi-check${data.puedeAbonar ? '-square' : ''}` }
    ];

    const onSelectedChange = (checked) => {
        if (!data.puedeAbonar)
            return;

        api.setValue(checked);
        setGrid({ ...grid, total: grid.total + data.monto * (checked ? 1 : -1) })
    }

    return (
        <div className={''}>
            <MultiStateCheckbox value={data.abonado} options={options} optionValue="value"
                className={(!data.puedeAbonar || !soloLectura) ? 'disabled' : ''}
                onChange={(e) => onSelectedChange(e.value != null)} />
        </div>
    );
};

export default CheckBoxRenderer;
