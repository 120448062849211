/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import useApiRevisionSemaforeo from "../../../api/taller/useApiRevisionSemaforeo";
import Field from "../../../shared/forms/Field";
import Form from "../../../shared/forms/Form";
import useForm from "../../../shared/forms/useForm";
import ExGrid from "../../../shared/Grid/ExGrid";
import FormCalendar from "../../../shared/forms/FormFields/FormCalendar";
import FormInputNumber from "../../../shared/forms/FormFields/FormInputNumber";
import ExColumn from "../../../shared/Grid/ExColumn";
import FormDropDown from "../../../shared/forms/FormFields/FormDropDown";
import FormTextArea from "../../../shared/forms/FormFields/FormTextArea";
import { Button } from "primereact/button";

const RevisionDetalle = ({ revisionId, onSave }) => {
    const initialValues = {
        revisionSemaforeo: {
            fechaRevision: moment().startOf('day')._d,
            revisiones: []
        }
    };
    const api = useApiRevisionSemaforeo();
    const {
        values: revision,
        setValues,
        onChange,
        onValidate,
        isValid
    } = useForm(initialValues);
    const [verRevision, setVerRevision] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        setDisabled(false);
        if (revisionId > 0) {
            setDisabled(true);
            setSubmitted(false);
            load(revisionId).then();
        }
    }, [revisionId]);

    const load = async (id) => {
        const result = await api.obtener(id);
        setValues(result);
        setVerRevision(true);
    };

    const cambiarUnidad = async (unidadId) => {
        let llantas = await obtenerLlantas(unidadId);
        setValues(llantas);
        setVerRevision(true);
    };

    const obtenerLlantas = async (unidadId) => {
        return await api.llantas(unidadId);
    };

    function presionClass(item) {
        if (item?.value < 80)
            return 'revision-llanta-error';
        else if (item?.value >= 80 && item?.value < 100)
            return 'revision-llanta-warning';
        else
            return 'revision-llanta-ok';
    };

    function profundidadClass(item) {
        if (item?.value <= 3)
            return 'revision-llanta-error';
        else if (item?.value > 3 && item?.value <= 6)
            return 'revision-llanta-warning';
        else
            return 'revision-llanta-ok';
    };

    const guardar = async () => {
        setSubmitted(true);
        if (!isValid)
            return;

        await api.guardar(revision);
        onSave();
    };

    return (
        <>
            <div className="form-container full-height" style={{ paddingLeft: "1px" }}>
                <Form columns={3} values={revision} onChange={onChange} onValidate={onValidate}
                    formDisabled={disabled}
                    className={`form-container full-height ${submitted ? 'submitted' : ''}`}>
                    <h5 style={{ gridColumnEnd: 'span 3' }}>
                        Revision Semaforeo
                    </h5>
                    <Field field={"unidadId"} label={"Unidad"} source="unidades" required={true}
                        render={({ onParentChange, parent, ...props }) =>
                            <FormDropDown {...props}
                                onChange={(unidadId) => cambiarUnidad(unidadId)} />
                        } />
                    {verRevision &&
                        <>
                            <Field field={"kmsAnteriores"} label={"Kms. Anteriores"}
                                render={(props) => <FormInputNumber {...props} disabled={true} />} />
                            <Field field={"fechaRevision"} label={"Fecha de revision"}
                                render={FormCalendar} required={true} />
                            <Field field={"fechaKmsAnteriores"} label={"Fecha Kms. Ant."}
                                render={(props) => <FormCalendar {...props} disabled={true} />} />
                            <Field field={"kmsActuales"} label={"Kms. Actuales"} required={true}
                                render={({ onParentChange, parent, ...props }) =>
                                    <FormInputNumber {...props}
                                        onChange={(kmsActuales) =>
                                            onParentChange({
                                                kmsActuales,
                                                kmsRecorridos: kmsActuales - parent.kmsAnteriores
                                            })
                                        } />} />
                            <Field field={"kmsRecorridos"} label={"Kms. Recorridos"}
                                render={FormInputNumber} required={true} disabled={true} />
                            <Field field={"revisiones"} cols={3}
                                render={({ value, onParentChange, ...props }) =>
                                    <ExGrid {...props} data={value}
                                        onChange={(data) => onParentChange({ revisiones: data })}
                                        getEntityId={(row) => row._uniqueUid}>
                                        <ExColumn field="llanta" title="Llanta" width={"150px"} />
                                        <ExColumn field="posicion" title="Posicion" width={"100px"} />
                                        <ExColumn field="profundidadAnt" title="Prof. Ant." width={"100px"} />
                                        <ExColumn field="profundidad" title="Profundidad (Mm)"
                                            cellRenderer={(props) => <FormInputNumber {...props}
                                                className={profundidadClass(props)} />}
                                            maxFractionDigits={2} max={25} required={true} width={"100px"} />
                                        <ExColumn field="presion" title="Presión (Lbs)"
                                            cellRenderer={(props) => <FormInputNumber {...props}
                                                className={presionClass(props)} />}
                                            maxFractionDigits={2} max={110} required={true} width={"100px"} />
                                        <ExColumn field="condicionId" title="Condicion" source="condicionesLlanta" required={true}
                                            cellRenderer={FormDropDown} />
                                        <ExColumn field="observaciones" title="Observaciones" rows={2} maxLength={255} required={true}
                                            cellRenderer={(props) => <FormTextArea {...props} width={'100%'} />} />
                                    </ExGrid>
                                } />
                            <Field cols={3} render={(...props) =>
                                <div className="d-flex justify-content-center mt-1">
                                    <img src={`../../../img/unidades/TipoId${revision?.tipoUnidadId}_.png`}
                                        height={150} alt="posicion" />
                                </div>
                            } />
                        </>
                    }
                </Form>
            </div>
            <div className="form-footer">
                {/* <Button label="Eliminar" className="p-button-outlined" type="button" /> */}
                {verRevision && revision.id === 0 &&
                    <Button label="Guardar" className="p-button-outlined" type="button" onClick={guardar} />
                }
            </div>
        </>
    )
};

export default RevisionDetalle;