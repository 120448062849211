/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import {Calendar} from "primereact/calendar";

const FormCalendarRange = ({value, onChange, field, ...props}) => {
    const handleChange = (newDates)=>{
        onChange(newDates);
    }

    return (
        <>
            <Calendar
                id={field}
                locale={'es'}
                dateFormat="dd/M/yy"
                value={value}
                style={{width:'100%'}}
                onChange={(e) => handleChange(e.value)}
                selectionMode="range"
                readOnlyInput
                {...props}
            />
        </>
    )
}

export default FormCalendarRange;
