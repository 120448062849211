import React from "react";
import {InfoBox} from "@react-google-maps/api";

const renderMarkersWithLabels = (posiciones, unidadViajeMap) => {
    return posiciones.map((item, index) => (
        <React.Fragment key={index}>
            <InfoBox
                position={item}
                options={{
                    closeBoxURL: ``,
                    enableEventPropagation: true,
                    disableAutoPan: true,
                    pixelOffset: new window.google.maps.Size(0, 0)
                }}
                key={`label-${index}`}
            >

                        <div
                            style={{
                                backgroundColor: 'transparent',
                                fontSize: '11px',
                                color: 'black',
                                textAlign: 'center',
                                display: 'flex',
                                // display: `${visibleEtiquetas ? 'block' : 'none'}`,
                            }}
                        >
                            <label style={{
                                backgroundColor:'white',
                                padding: '3px 5px',
                                borderRadius:'5px',
                                whiteSpace: 'nowrap',
                            }}>
                                {item.numeroEconomico}
                            </label>
                        </div>

            </InfoBox>
        </React.Fragment>
    ));
};

export default renderMarkersWithLabels