/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import imagenesUnidades from "./imagenesUnidades";
import constantes from "../../constantes";
import _ from "lodash";
import FieldDropDown from "../../../shared/controls/fieldDropDown";


//orden de las llantas por posición
//         pos[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22]
const orden = [0,1,2,3,5,4,6,7,9,8,10,11,13,12,14,15,17,16,18,19,20,21,22];


const InspeccionLlanta = ({entity, actualizar, errors, editable}) => {
    const [imagenUnidad, setImagenUnidad] = useState({});
    const [imagenCaja, setImagenCaja] = useState({});

    useEffect(()=>{ 
        setImagenUnidad(imagenesUnidades.llantas.find(ll=> ll.esUnidad && ll.tipoUnidadId === entity?.unidad?.tipoUnidadId));
        setImagenCaja(imagenesUnidades.llantas.find(ll=> !ll.esUnidad && ll.tipoUnidadId === (entity?.esExterno ? 1 : entity?.caja?.tipoUnidadId))); 
        
    },[entity?.unidad, entity?.caja, entity?.esExterno]);


    useEffect(()=>{
        setImagenUnidad(imagenesUnidades.llantas[0]);
        setImagenCaja(imagenesUnidades.llantas.find(ll=> !ll.esUnidad && ll.tipoUnidadId === (entity?.esExterno ? 1 : entity?.caja?.tipoUnidadId))); 
         
    },[entity?.nombreUnidad, entity?.esExterno]);

    function actualizarLlanta(value, index, propertyName) {
      
        var property ='idCautin';
      
        if(typeof value === 'object')        
            property = 'marcaLlanta';
      
        setImagenUnidad(imagenesUnidades.llantas.find(ll=> ll.esUnidad && ll.tipoUnidadId === entity?.unidad?.tipoUnidadId));
        setImagenCaja(imagenesUnidades.llantas.find(ll=> !ll.esUnidad && ll.tipoUnidadId === entity?.caja?.tipoUnidadId)); 
        actualizar(
            entity[propertyName].map((e,i)=> i !== index ? e : {
                    ...e,
                    [property]: value
                }
            ),
            propertyName
        ); 
    }

    return (
        <>
            <div className="section-container">

                <div className={`flex-col-12`}>
                    <h6>
                        Inspección
                    </h6>
                    <Inspeccion editable={editable} llantas={entity.llantasUnidad} tipoControlPatio={entity.tipo}  imagen={imagenUnidad} className="unidad" name="llantasUnidad" errors={errors}
                                 onChange={actualizarLlanta}/> 
                </div>
                <br/>
                {entity?.conCaja === true &&
                <div className="flex-col-12 ope-daños">
                    <h6>
                        Remolque
                    </h6>
                    <Inspeccion editable={editable} llantas={entity.llantasRemolque} tipoControlPatio={entity.tipo} validationDisabled ={entity.esExterno && entity.tipo !== constantes.tipoControlPatio.salida} imagen={imagenCaja} name="llantasRemolque" className="remolque" errors={errors}
                                onChange={actualizarLlanta}/>
                </div>
                }
            </div>
        </>
    );
};

const Inspeccion = ({llantas, imagen, onChange, className, name, errors,validationDisabled,editable}) => {

    const llantasOrdenadas = _.sortBy(llantas,[e=>orden[e.posicion]]);
      
    function getItemClass(item) {

        if(validationDisabled)
         return 'ope-llanta-ok';

        if (item.idCautin.length === 0 || item.idCautinOriginal.length === 0)
            return;

        return item.idCautin === item.idCautinOriginal ?  'ope-llanta-ok' :  'ope-llanta-error';
    }


    function getItemClassMarca(item) {

        if(validationDisabled)
         return 'ope-llanta-ok';

        if (item.marcaLlanta === null)
            return;

        return  item.marcaLlanta != null && item.marcaLlantaOriginal != null && item.marcaLlanta.id === item.marcaLlantaOriginal.id  ?  'ope-llanta-ok' :  'ope-llanta-error';
    }

    function changeValueHandler(value, index, name) {
        
        var posicion = llantasOrdenadas[index].posicion;
        var newIndex = llantas.findIndex(x => x.posicion === posicion);

        onChange(value, newIndex, name) ;

    }
    
    return (
        <>
            <div className={`ope-contenedor-llanta ope-llantas-center ${className}`}>
                <div className="ope-contenedor-imagen">
                    {imagen != null &&
                    <img className="ope-imagen-llantas" src={imagen.srcLlanta} alt={''}/>
                    }
                </div>
                {llantasOrdenadas?.map((item, index) => {
                    return (
                        <React.Fragment key={item.posicion}>
                            <span>{item.posicion}</span>
           
            <div  className="ope-contenedor-opcionesllanta ">

                   <span className="ope-llantas-center" style={{display:"flex"}}>
                                    <FieldTextBox width={"80%"} name="idCautin" value={item.idCautin} keyFilter={'int'}
                                        disabled={item.idCautinOriginal==='' || !editable}
                                        className={getItemClass(item)}
                                        error={errors?.[`${name}[${index}]`]}
                                        onChange={(value)=>changeValueHandler(value, index, name)}/>

                                <label style={{marginLeft:"10px"}}>Caut&iacute;n</label>
                     </span>        
                           
                                                
                   <span className="ope-llantas-center" style={{display:"flex"}}>

                        {item.id === 0 || editable? (
                            <>
                                           <FieldDropDown  width={"80%"}  name="marcaLlanta"  source="marcasLlanta"                                                       
                                                            filter={false}
                                                            required
                                                            className={getItemClassMarca(item)}
                                                            error={errors?.[`${name}[${index}]`]}
                                                            value={item.marcaLlanta}
                                                            disabled={item.idCautinOriginal===''}
                                                            onChange={(value)=>changeValueHandler(value, index, name)}
                                                         
                                            />


                        </>
                        ) : (
                            <FieldTextBox  width={"80%"} 
                            className={getItemClassMarca(item)}
                            error={errors?.[`${name}[${index}]`]}
                            value={item.marcaLlanta?.nombre ?? 'Sin Seleccion'}
                            disabled={true} 
                            />
                        )}

                       <label style={{marginLeft:"10px"}}>Marca</label>

                      </span>
                            </div>
                        </React.Fragment>
                    )
                })}
            </div>
        </>
    )
};

export default InspeccionLlanta;
