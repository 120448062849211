import React from "react";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import produce from "immer";
import FieldCheckBox from "../../shared/controls/fieldCheckBox";

const ConfigSTP = ({config, onChange, errors}) => {
    console.log(config)
    const actualizar = (value, name) => {
        const newValue = produce(config, draft => {
            draft[name] = value;
        });



        onChange(newValue, 'stpConfig');
    }

    return (
        <div className="form-row">
            <FieldCheckBox name={"stpHabilitada"} label={"STP Habilitada"}
                           onChange={actualizar} value={config.stpHabilitada} colMd={12}/>
            {config.stpHabilitada &&
                <>
                    <FieldTextBox name="empresa" label="Empresa" value={config.empresa} colMd={12} maxLength={100} required={true}
                                  onChange={actualizar} errors={errors}/>
                    <FieldTextBox name="cuentaOrdenante" label="Cuenta Ordenante" value={config.cuentaOrdenante} colMd={6} maxLength={18}
                                  required={true}
                                  onChange={actualizar} errors={errors}/>
                    <FieldTextBox name="institucionOperante" label="Institucion Operante" value={config.institucionOperante} colMd={6} 
                                  required={true}
                                  onChange={actualizar} errors={errors}/>
                     <FieldTextBox name="nombreOrdenante" label="Nombre Ordenante" value={config.nombreOrdenante} colMd={6}
                                  required={true}
                                  onChange={actualizar} errors={errors}/>
                    <FieldTextBox name="rfcOrdenante" label="RFC Ordenante" value={config.rfcOrdenante} colMd={6}
                                  maxLength={12} required={true}
                                  onChange={actualizar} errors={errors}/>
                    <FieldTextBox name="tipoCuentaOrdenante" label="Tipo Cuenta Ordenante" value={config.tipoCuentaOrdenante} colMd={6}
                                  maxLength={2} required={true}
                                  onChange={actualizar} errors={errors}/> 
  
                   
                   
                   
                  
                </>
            }
        </div>
    );
};

export default ConfigSTP;