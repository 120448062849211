/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import produce from "immer";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import alerts from "../../../shared/alerts";
import FieldAutoComplete from "../../../shared/controls/fieldAutoComplete";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import {Ruta} from "../cotizaciones/Rutas/ruta";
import constantes from "../../constantes";
import Cliente from "../../../catalogos/clientes/cliente";
import Parada from "../paradas/parada";
import FieldInputNumber from "../../../shared/controls/fieldInputNumber";
import YupValidator from "../../../shared/YupValidator";
import expeditadoValidacionEsquema from "./expeditadoValidacionEsquema";
import {Button} from "primereact/button";
import FieldRadioButtonList from "../../../shared/controls/fieldRadioButtonList";
import useApiExpeditados from "../../../api/comercial/expeditados";
import {useLoadingDispatch} from "../../../layout/show-loading-context";
import FieldMultiSelect from "../../../shared/controls/fieldMultiSelect";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import useSeguridad from "../../../Seguridad/useSeguridad";
import {default as constantesCatalogos} from '../../../catalogos/constantes';
import {useEmpresa} from "../../../layout/EmpresaProvider";

const DetalleExpeditado = () => {

    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantesCatalogos.menus.comercial);
    const soloLecturaComercial = !seguridad.esEditable(constantesCatalogos.menus.comercial);
    const dispatchRoot = useLoadingDispatch();
    const [expeditado, setExpeditado] = useState({paradas: []});
    const [submitted, setSubmitted] = useState(false);
    const [editable, setEditable] = useState(false);
    const [puedeSuspender, setPuedeSuspender] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [errors, setErrors] = useState({});
    const apiExpeditado = useApiExpeditados();
    const history = useNavigate();
    let {id} = useParams();
    const validador = new YupValidator(expeditadoValidacionEsquema);
    const [conceptos, setConcepto] = useState(false);
    const [completo, setCompleto] = useState(false);
    const [impoExpo,] = useState([
        {id: false, nombre: 'Impo'},
        {id: true, nombre: 'Expo'}
    ]);
    const {disable: disableEmpresa} = useEmpresa();
    useEffect(() => {
        async function cargarExpeditado() {
            const expeditado = await apiExpeditado.obtener(id);
            setExpeditado({...expeditado, ruta: new Ruta(expeditado.ruta)});
            setEditable(expeditado.estatus.id <= constantes.estatusExpeditados.altaPendiente);
            setPuedeSuspender(expeditado.estatus.id === constantes.estatusExpeditados.altaPendiente ||
                expeditado.estatus.id === constantes.estatusExpeditados.completo
            );
            dispatchRoot({type: 'setDatosPath', datosPath: expeditado?.codigoExpeditado});
            setConcepto(expeditado.conceptosCobro);
            setCompleto(expeditado.estatus.id === constantes.estatusExpeditados.completo);
        }

        cargarExpeditado();
        disableEmpresa();
    }, []);

    async function actualizar(value, propertyName) {
        if (propertyName === "conceptosCobro") {
            let list = [];
            value.forEach(function (c) {
                let concepto = conceptos.filter(function (cc) {
                    return cc.id === c.id
                });
                let monto = 0;
                if (concepto !== null && concepto.length > 0) {
                    monto = concepto[0].monto;
                }

                list.push({id: c.id, nombre: c.nombre, monto: monto});
            });

            setExpeditado(produce(expeditado, draft => {
                draft[propertyName] = list;

            }));


            if (conceptos.length < list.length) {
                setConcepto(list);
            }

            setIsDirty(true);
        } else {
            const nuevoExpeditado = produce(expeditado, draft => {
                draft[propertyName] = value
            });

            setExpeditado(nuevoExpeditado);

            if (submitted === true) {
                await validador.validate(nuevoExpeditado);
                setErrors(validador.errors)
            }
            if (propertyName === "subClienteId" && nuevoExpeditado.subClienteId != null) {
                let subCliente = await apiExpeditado.obtenerSubcliente(nuevoExpeditado.subClienteId);
                const nuevoExpeditadoActualizado = {
                    ...nuevoExpeditado,
                    aplicaFrightVerify: subCliente?.aplicaFrightVerify,
                    aplicaQS3: subCliente?.aplicaQS3
                };

                // Actualizar el estado con el nuevo objeto
                setExpeditado(nuevoExpeditadoActualizado);
            }
        }
    }

    async function actualizarTiposViaje(value, propertyName) {
        const nuevoExpeditado = produce(expeditado, draft => {
            draft[propertyName] = value
        });
        setExpeditado(nuevoExpeditado);
        setIsDirty(true);
        if (submitted === true) {
            await validador.validate(nuevoExpeditado);
            setErrors(validador.errors)
        }

        const esViajeRedondo = nuevoExpeditado != null && nuevoExpeditado.tipoViajeId === constantes.tipoViaje.redondo;
        actualizar(esViajeRedondo, "esViajeRedondo");
        cambiaTipo(esViajeRedondo, value, propertyName);
    }

    function actualizarParada(parada, index) {
        setExpeditado(produce(expeditado, draft => {
            draft.paradas[index] = parada;
        }));
    }

    async function back() {
        if (isDirty === false || await alerts.preguntarSiDeseaSalirSinGuardar()) {
            history('/comercial');
        }
    }

    async function guardar() {
        setSubmitted(true);
        if (expeditado.aplicaFrightVerify === true && ProveedorRequerido()) {
            return;
        }
        if (expeditado.aplicaEdiAODMEX === true && ProveedorRequerido()) {
            return;
        }

        if (expeditado?.internacional) {
            if (expeditado?.impoExpo) {
                if (expeditado?.paradas?.some(p => p.esOrigen && p.direccion?.pais?.id !== constantesCatalogos.paises.mexico)) {
                    await alerts.aviso("El origen no es valido para exportación.");
                    return;
                }

                if (expeditado?.paradas?.some(p => p.esDestino && p.direccion?.pais?.id === constantesCatalogos.paises.mexico)) {
                    await alerts.aviso("El destino no es valido para exportación.");
                    return;
                }
            } else {
                if (expeditado?.paradas?.some(p => p.esOrigen && p.direccion?.pais?.id === constantesCatalogos.paises.mexico)) {
                    await alerts.aviso("El origen no es valido para importación.");
                    return;
                }

                if (expeditado?.paradas?.some(p => p.esDestino && p.direccion?.pais?.id !== constantesCatalogos.paises.mexico)) {
                    await alerts.aviso("El destino no es valido para exportación.");
                    return;
                }
            }
        }

        await apiExpeditado.actualizar(id, {...expeditado, estatus: expeditado.estatus.id});
        history('/comercial');
    }

    async function registrar() {
        setSubmitted(true);
        const valido = await validador.validate(expeditado);
        setErrors(validador.errors);
        const paradasConError = expeditado.paradas.filter(e => e.direccion == null).length > 0;
        if (valido === false || paradasConError === true) {
            return;
        }
        if (expeditado.aplicaFrightVerify === true && ProveedorRequerido()) {
            return;
        }
        if (expeditado.aplicaEdiAODMEX === true && ProveedorRequerido()) {
            return;
        }
        await alerts.preguntarSiNo('¿Desea registrar el expeditado?');
        await apiExpeditado.completar(id, {...expeditado, estatus: expeditado.estatus.id});
        history('/comercial');
    }

    async function suspender() {
        if (await alerts.preguntarSiNo('¿Desea suspender el Expeditado?') === false) {
            return;
        }
        await apiExpeditado.suspender(id);
        history('/comercial');
    }

    async function cambiaTipo(esRedondo, value, propertyName) {
        const retorno = await apiExpeditado.cambiaTipo(id, esRedondo);
        setExpeditado(produce(expeditado, draft => {
            draft[propertyName] = value;
            draft.paradas = retorno;
        }));
    }

    async function agregarStop() {
        const paradas = await apiExpeditado.agregarParada(id, expeditado.tipoViajeId);
        setExpeditado(produce(expeditado, draft => {
            draft.paradas = paradas;
        }));
    }

    async function eliminarStop() {
        const paradas = await apiExpeditado.eliminarParada(id);
        setExpeditado(produce(expeditado, draft => {
            draft.paradas = paradas;
        }));
    }

    function clienteNoEncontrado() {
        return !((expeditado.cliente?.id ?? 0) > 0);
    }

    function ProveedorRequerido() {
        return ((expeditado.proveedor?.length ?? 0) === 0 && (expeditado.aplicaFrightVerify || expeditado.aplicaEdiAODMEX));
    }

    function codigoRutaRequerida() {
        return ((expeditado.proveedor?.length ?? 0) === 0 && (expeditado.aplicaFrightVerify || expeditado.aplicaEdiAODMEX));
    }

    function actualizarConceptos(concepto, index) {
        setExpeditado(produce(expeditado, draft => {
            draft.conceptosCobro[index].monto = concepto;
        }));
    }

    const onUpdateFreightVerify = (checked) => {
        setExpeditado({...expeditado, aplicaFrightVerify: checked, aplicaQS3: checked ? false : expeditado.aplicaQS3})
        setIsDirty(true);
    }

    const onUpdateQs3 = (checked) => {
        setExpeditado({
            ...expeditado,
            aplicaQS3: checked,
            aplicaFrightVerify: checked ? false : expeditado.aplicaFrightVerify
        })
        setIsDirty(true);
    }

    return (
        <>
            <div className="form-container">
                <div className={`form-filtro form-row`}>
                    <div className={`form-group col-md-4 col-sm-6`}>
                        <label htmlFor={'cliente'}>Cliente</label>
                        {clienteNoEncontrado() &&
                            <label className="autocomplete-not-found-item" style={{right: 150}}>No encontrado</label>}
                        <div className="col-auto">
                            <FieldAutoComplete name="cliente" disabled={!editable} source="clientes" freeText={true}
                                               errors={errors}
                                               value={expeditado.cliente} onChange={actualizar}/>
                            {editable && <Cliente onSave={(nuevoCliente) => actualizar(nuevoCliente, 'cliente')}/>}
                        </div>
                    </div>
                    <FieldCheckBox label="Freight Verify" c name="aplicaFrightVerify" disabled={!editable} colMd={1}
                                   colSm={2}
                                   value={expeditado.aplicaFrightVerify} onChange={(e) => onUpdateFreightVerify(e)}/>
                    <FieldCheckBox label="QS3" c name="aplicaQS3" disabled={!editable} colMd={1} colSm={2}
                                   value={expeditado.aplicaQS3} onChange={(e) => onUpdateQs3(e)}/>
                    <FieldCheckBox label="Aplica Edi AODMEX" name="aplicaEdiAODMEX" disabled={!editable} colMd={1}
                                   colSm={2}
                                   value={expeditado.aplicaEdiAODMEX} onChange={actualizar}/>
                    <FieldTextBox name="proveedor" label="No. Proveedor / Transportista" colMd={2} disabled={!editable}
                                  error={ProveedorRequerido() === true && submitted}
                                  value={expeditado.proveedor} onChange={actualizar}/>
                    <FieldTextBox name="codigoRuta" label="Código Ruta" colMd={2} disabled={!editable}
                                  error={codigoRutaRequerida() === true && submitted}
                                  value={expeditado.codigoRuta} onChange={actualizar}/>
                    <div className="field-iconos btn-group">
                        <ButtonIcon title='Regresar' iconName={'BiArrowBack'} onClick={back}/>
                        {!soloLectura &&
                            <>
                                {puedeSuspender &&
                                    <ButtonIcon title='Cancelar' iconName={'FcCancel'} onClick={suspender}/>}
                                {editable && <>
                                    <ButtonIcon title='Guardar' iconName={'AiOutlineSave'} onClick={guardar}/>

                                </>
                                }

                                <ButtonIcon title='Alta de Expeditado' className='action' iconName={'FaCheckCircle'}
                                            onClick={registrar} disabled={completo}/>
                            </>
                        }
                    </div>
                </div>
                <div className="form-row">
                    {expeditado.cliente?.esLogistico && (
                        <FieldDropDown
                            name="subClienteId"
                            label="SubCliente"
                            source="subClientes"
                            required={true}
                            params={{clienteId: expeditado.cliente?.id}}
                            showClear={true}
                            colMd={2}
                            value={expeditado.subClienteId}
                            valueById={true}
                            errors={errors}
                            onChange={actualizar}
                        />
                    )}
                    <FieldDropDown name="tipoUnidad" label="Tipo de Unidad" colMd={2} source="tiposUnidad" filter
                                   disabled={!editable}
                                   errors={errors}
                                   value={expeditado.tipoUnidad} onChange={actualizar}/>
                    <FieldInputNumber name="precioViaje" label="Precio Viaje" colMd={1} fractionDigits={2}
                                      errors={errors} disabled={!editable}
                                      value={expeditado.precioViaje} onChange={actualizar}/>
                    <FieldInputNumber name="comision" label="Comisión" colMd={1} fractionDigits={2} errors={errors}
                                      disabled={!editable}
                                      value={expeditado.comision} onChange={actualizar}/>
                    <div className={`form-group col-md-2 col-sm-6`}>
                        <label htmlFor="tipoViajeId">Viaje</label>
                        <FieldRadioButtonList source="tiposViaje" name="tipoViajeId" colMd={2} fractionDigits={2}
                                              disabled={!editable}
                                              value={expeditado?.tipoViajeId} onChange={actualizarTiposViaje}>
                        </FieldRadioButtonList>
                    </div>
                    <div className={`form-group col-md-2 col-sm-6`}>
                        <label htmlFor="tipoMonedaId">Moneda</label>
                        <FieldRadioButtonList source="monedas" name="tipoMonedaId" colMd={2} fractionDigits={2}
                                              disabled={!editable}
                                              value={expeditado?.tipoMonedaId} onChange={actualizar}>
                        </FieldRadioButtonList>
                    </div>
                    {/* <div className="form-group col-md-2 col-sm-6">
                        <label htmlFor="internacional">&nbsp;</label>

                    </div> */}
                    <div className="form-group col-md-6 col-sm-6">
                        <FieldCheckBox label="Internacional" name="internacional" disabled={!editable} colMd={1}
                                       colSm={2}
                                       value={expeditado?.internacional} onChange={actualizar}/>
                        {expeditado.internacional &&
                            <>
                                <FieldRadioButtonList options={impoExpo} name="impoExpo" colMd={2} disabled={!editable}
                                                      value={expeditado?.impoExpo} onChange={actualizar}>
                                </FieldRadioButtonList>
                            </>
                        }
                    </div>
                    {editable && !soloLectura &&
                        <Button label="Agregar Stop" className="p-button-text" icon="pi pi-plus-circle"
                                onClick={agregarStop}/>
                    }
                </div>
                <br/>
                <div className={`section-container ${submitted === true ? 'submitted' : ''}`}>
                    <div className="flex-col-6" style={{overflow: "auto"}}>
                        <h5>Origen . . . Destino</h5>
                        <div style={{width: "100px"}} className="com-paradas">
                            <Parada esSeparador={true} labelClassName="com-parada-ruta-label" textoBody="RUTA"/>
                            {expeditado.paradas.map((parada, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <Parada parada={parada} entidadId={id} api={apiExpeditado}
                                                readOnly={(!editable && soloLecturaComercial)}
                                                canBeDeleted={index === (expeditado.paradas.length - 2) && editable}
                                                onChange={(e) => actualizarParada(e, index)} onDelete={eliminarStop}/>
                                        {
                                            index < expeditado.paradas.length - 1 &&
                                            <Parada labelClassName="com-parada-puntos" textoHeader="..." textoBody="..."
                                                    esSeparador={true}/>
                                        }
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </div>
                    <div className="flex-col-6">
                        <h5>Conceptos de cobro</h5>
                        <div className={`form-filtro form-row`}>
                            <div className="form-group col-md-6 col-sm-6 text-right">
                                <label>Seleccionar Concepto de Cobro</label>
                            </div>
                            <div className="form-group col-md-6 col-sm-6">
                                <FieldMultiSelect showFilter={false} maxSelectedLabels={12} name="conceptosCobro"
                                                  source="conceptosCobro" colMd={4}
                                                  value={expeditado.conceptosCobro} onChange={actualizar}/>
                            </div>
                        </div>
                        <div className={`form-filtro form-row`}>
                            {
                                expeditado?.conceptosCobro?.map((item, index) =>
                                    <>
                                        <FieldInputNumber name="concepto" value={item.monto} required
                                                          label={`${item.nombre}`} colMd={3}
                                                          onChange={(e) => actualizarConceptos(e, index)}
                                                          fractionDigits={2} maxLength={6}/>
                                    </>
                                )
                            }
                        </div>
                    </div>
                    <div style={{height: "200px"}} className="flex-col-12">
                        <h5>Puntos de Interes</h5>
                        <FieldMultiSelect showFilter={true} maxSelectedLabels={12} name="puntosInteres"
                                          source="puntosInteres" colMd={12}
                                          value={expeditado.puntosInteres} onChange={actualizar}/>
                    </div>
                </div>
            </div>
        </>
    )
};

export default DetalleExpeditado;
