/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import constantes from "../../../../catalogos/constantes";
import FieldAutoComplete from "../../../../shared/controls/fieldAutoComplete";
import FieldCheckBox from "../../../../shared/controls/fieldCheckBox";
import FieldTextBox from "../../../../shared/controls/fieldTextBox";
import FieldCalendar from "../../../../shared/controls/fieldCalendar";
import FieldDropDown from "../../../../shared/controls/fieldDropDown";
import produce from "immer";
import moment from "moment";
import { Button } from "primereact/button";
import useApiViajeSeguimiento from "../../../../api/operaciones/useApiViajesSeguimiento";
import useSeguridad from "../../../../Seguridad/useSeguridad";

const RutasViajeEdi = ({ viaje, errors, actualizar }) => {
    const [rutasEdi, setDatos] = useState([]);
    const [permisoLimpiarCaptura, setpermisoLimpiarCaptura] = useState(false);
    const api = useApiViajeSeguimiento();
    const seguridad = useSeguridad();

    useEffect(() => {
        let permisoLimpiarCaptura = seguridad.tieneAcceso(constantes.opciones.LimpiarCapturaEdi);
        setpermisoLimpiarCaptura(permisoLimpiarCaptura);
        setDatos(viaje?.rutasEdi);
    });

    const actualizarRuta = (value, propertyName, index) => {

        const newValue = produce(rutasEdi, (draft) => {
            if (propertyName === "direccion" && value != null) {
                draft[index].planta = value.nombre;
                draft[index].direccionCompleta = value.direccionCompleta;
            }
            draft[index][propertyName] = value;

            if (propertyName === "incidenciaSalida") {
                draft[index].incidenciaSalida = value;
            }
            if (propertyName === "incidenciaLlegada") {
                draft[index].incidenciaLlegada = value;
            }
        });

        actualizar(newValue, "rutasEdi");
    };

    const limpiarCaptura = async (viajeRutaId, entrada, index) => {    
        await api.limpiarCapturaEdi(viaje.id, viajeRutaId, entrada);
        if (entrada === 1) {
            const newValue = produce(rutasEdi, (draft) => {
                draft[index].fechaRealLlegada = null;
                draft[index].horaRealLlegada = '';
                draft[index].incidenciaLlegada = null;
            });
            actualizar(newValue, "rutasEdi");
            setDatos(newValue);

        } else {
            const newValue = produce(rutasEdi, (draft) => {
                draft[index].fechaRealSalida = null;
                draft[index].horaRealSalida = '';
                draft[index].incidenciaSalida = null;
            });
            actualizar(newValue, "rutasEdi");
            setDatos(newValue);
        }
    }

    const cargaDescargaInvalido = (index) => {
        return errors[`rutasEdi[${index}]`]?.esCarga != null && !rutasEdi[index].esCarga && !rutasEdi[index].esDescarga
            && !rutasEdi[index].desenganchar;
    };

    const deshabilitado = (rutasEdi) => {
        return !viaje.rutasEditables && (viaje.tramoEnRuta >= rutasEdi.tramo
            && rutasEdi.stopNumber !== constantes.tipoRuta.destino && rutasEdi.stopNumber !== constantes.tipoRuta.retorno);
    };

    const autocompleteDeshabilitado = (rutasEdi) => {
        return deshabilitado(rutasEdi) || viaje.padreIdViajeVacio != null;
    };

    return (
        <table className="table table-sm">
            <thead className="thead-light">
                <tr>
                    <th />
                    <th className="text-center" style={{ width: '20%' }}></th>
                    <th></th>
                    <th></th>
                    <th className="text-center" style={{ width: '30%' }}>Entradas</th>
                    <th className="text-center" style={{ width: '30%' }}>Salidas</th>
                </tr>
            </thead>
            <tbody>
                {rutasEdi?.map((item, index) => {
                    return (
                        <tr key={item.id}>
                            <td>
                                <b>{item.nombre}</b>

                                {index + 1 < rutasEdi.length &&
                                    <div className="pt-2"><i className="pi pi-ellipsis-v" /></div>
                                }
                            </td>
                            <td>
                                {item.esNuevoTramo ?
                                    <>
                                        <FieldAutoComplete name="direccion"
                                            errors={errors[`rutasEdi[${index}]`]}
                                            source={`direcciones/${constantes.modulos.paradas}`}
                                            value={item.direccion}
                                            disabled={autocompleteDeshabilitado(item)}
                                            onChange={(value, name) => actualizarRuta(value, name, index)} />
                                        <div style={{ fontSize: '0.8em' }}>{item.direccionCompleta}</div>
                                    </>
                                    :
                                    <>
                                        <div><b>{item.planta}</b></div>
                                        <div style={{ fontSize: '0.8em' }}>{item.direccionCompleta}</div>
                                    </>
                                }
                            </td>
                            <td>
                                <div className={`${cargaDescargaInvalido(index) ? 'error' : ''}`}>
                                    <FieldCheckBox name="esCarga" label="Carga" value={item.esCarga}
                                        onChange={(value, name) => actualizarRuta(value, name, index)}
                                        disabled={true} index={index} />
                                    <FieldCheckBox name="desenganchar" label="Desenganchar" value={item.desenganchar}
                                        onChange={(value, name) => actualizarRuta(value, name, index)}
                                        disabled={true} index={index} />
                                    <FieldCheckBox name="esDescarga" label="Descarga"
                                        value={item.esDescarga}
                                        onChange={(value, name) => actualizarRuta(value, name, index)}
                                        disabled={true} index={index} />
                                </div>

                            </td>
                            <td>
                                <div>
                                    <div>{moment(item.fecha).format('DD/MM/YYYY')}</div>
                                    <div>{item.hora}</div>
                                </div>
                            </td>
                            <td>
                                <FieldCalendar name="fechaRealLlegada" className="xs" value={item.fechaRealLlegada}
                                    showButtonBar={false} errors={errors[`rutasEdi[${index}]`]}
                                    onChange={(value, name) => actualizarRuta(value, name, index)} />
                                <FieldTextBox type="time" name="horaRealLlegada" value={item.horaRealLlegada}
                                    errors={errors[`rutasEdi[${index}]`]}
                                    onChange={(value, name) => actualizarRuta(value, name, index)} />
                                <FieldDropDown name="incidenciaLlegada" colMd={4} source={`incidenciasEdi/${viaje.clienteId}`}
                                    onChange={(value, name) => actualizarRuta(value, name, index)}
                                    value={item.incidenciaLlegada} filter={false} errors={errors[`rutasEdi[${index}]`]}
                                    appendTo={document.body} />
                                {permisoLimpiarCaptura &&
                                    <div className="flex-grow-1">
                                        <Button label='Limpiar captura' type="button" className="p-button-danger ope-button-edi" onClick={() => limpiarCaptura(item.id, 1, index)} />
                                    </div>
                                }
                                <div className="flex-grow-1">
                                    {item.fechaCapturaLlegada !== null &&
                                    <>
                                        <div>Primera captura</div>                                    
                                        <div>{moment(item.fechaCapturaLlegada).format('DD/MM/YYYY')}</div>
                                        <div>{item.horaCapturaLlegada}</div>                                    
                                    </>
                                    }
                                </div>
                            </td>
                            <td>
                                <FieldCalendar name="fechaRealSalida" className="xs" value={item.fechaRealSalida}
                                    showButtonBar={false} errors={errors[`rutasEdi[${index}]`]}
                                    onChange={(value, name) => actualizarRuta(value, name, index)} />
                                <FieldTextBox type="time" name="horaRealSalida" value={item.horaRealSalida}
                                    errors={errors[`rutasEdi[${index}]`]}
                                    onChange={(value, name) => actualizarRuta(value, name, index)} />
                                <FieldDropDown name="incidenciaSalida" colMd={4} source={`incidenciasEdi/${viaje.clienteId}`}
                                    onChange={(value, name) => actualizarRuta(value, name, index)}
                                    value={item.incidenciaSalida} filter={false} errors={errors[`rutasEdi[${index}]`]}
                                    appendTo={document.body} />
                                {permisoLimpiarCaptura &&
                                    <div className="flex-grow-1">
                                        <Button label='Limpiar captura' type="button" className="p-button-danger ope-button-edi" onClick={() => limpiarCaptura(item.id, 0, index)} />
                                    </div>
                                }
                                <div className="flex-grow-1">
                                {item.fechaCapturaSalida !== null &&
                                <>
                                    <div>Primera captura</div>
                                    <div>{moment(item.fechaCapturaSalida).format('DD/MM/YYYY')}</div>
                                    <div>{item.horaCapturaSalida}</div>
                                </>
                                }
                                </div>                                
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    );
};


export default RutasViajeEdi;
