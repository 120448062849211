import React from "react";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import FieldDropDown from "../../shared/controls/fieldDropDown";
import FieldAutoComplete from "../../shared/controls/fieldAutoComplete";

const FiltroDirecciones = ({filtro, onChange}) => {
    return (
        <>
        <FieldTextBox name="nombre" label="Nombre" colMd={3} value={filtro.nombre} onChange={onChange}/>

    
        <FieldDropDown name="estado" source="estados" label="Estado" colMd={3} 
                value={filtro.estado} onChange={onChange}/>

         <FieldAutoComplete name="ciudad" label="Ciudad" source="municipios" params={filtro.estado}
                                   colMd={3}
                                   value={filtro.ciudad} onChange={onChange}/>
         </>
    )
};

export default FiltroDirecciones;
