import { Sidebar } from "primereact/sidebar";
import { useState } from "react";
import moment from "moment";
import useApiRevisionSemaforeo from "../../../api/taller/useApiRevisionSemaforeo";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import RevisionDetalle from "./RevisionDetalle";
import FormFilter from "../../../shared/forms/FormFilter/FormFilter";
import useForm from "../../../shared/forms/useForm";
import Field from "../../../shared/forms/Field";
import FormCalendarRange from "../../../shared/forms/FormFields/FormCalendarRange";
import Filters from "../../../shared/forms/FormFilter/Filters";
import Buttons from "../../../shared/forms/FormFilter/Buttons";
import FormMultiSelect from "../../../shared/forms/FormFields/FormMultiSelect";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import agGridHelper from "../../../shared/ag-grid-helper";

const Revision = ({ soloLectura }) => {
    const filtroInicial = {
        fechas: [moment().startOf('month')._d, moment().startOf('day')._d]
    };
    const api = useApiRevisionSemaforeo();
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [revisionId, setRevisionId] = useState(0);
    const [datos, setDatos] = useState([]);
    const { values: filter, onChange: onFilterChange, onClear } = useForm(filtroInicial);

    const filtrar = async (filtro, paramsApi) => {
        const data = await api.filtrar(filtro);
        setDatos(data);
    };

    const nueva = () => {
        setRevisionId(0);
        setSidebarVisible(true);
    };

    const ver = async (id) => {
        setSidebarVisible(true);
        setRevisionId(id);
    };

    const onSave = async () => {
        await filtrar(filter);
        setSidebarVisible(false);

    };

    const ButtonsHeader = () => {
        return (
            <div className="ag-grid header-icons">
                {!soloLectura &&
                    <ButtonIcon className="grid-action" title={'Agregar'} iconName={'MdAddBox'} onClick={nueva} />
                }
            </div>
        )
    };

    const RowButtons = ({ data }) => (
        <div className="ag-grid row-icons">
            <button className="btn btn-link btn-sm" title={'Ver'} onClick={() => ver(data.id)}>
                VER
            </button>
        </div>
    );

    const RowProfundidad = ({ data }) => (
        <div>
            {data.profundidadBaja &&
                <span className="dot"
                    style={{ backgroundColor: '#ff4b4b' }} />
            }
            {data.profundidadMedia &&
                <span className="dot"
                    style={{ backgroundColor: '#FFCC0F' }} />
            }
            {data.profundidadNormal &&
                <span className="dot"
                    style={{ backgroundColor: '#00d661' }} />
            }
        </div>
    );

    const RowPresion = ({ data }) => (
        <div>
            {data.presionBaja &&
                <span className="dot"
                    style={{ backgroundColor: '#ff4b4b' }} />
            }
            {data.presionMedia &&
                <span className="dot"
                    style={{ backgroundColor: '#FFCC0F' }} />
            }
            {data.presionNormal &&
                <span className="dot"
                    style={{ backgroundColor: '#00d661' }} />
            }
        </div>
    );

    return (
        <>
            <Sidebar visible={sidebarVisible} position="right" dismissable={false} className="p-sidebar-xl" onHide={() => setSidebarVisible(false)}>
                <RevisionDetalle revisionId={revisionId} onSave={onSave} />
            </Sidebar>
            <FormFilter columns={4} filter={filter} onChange={onFilterChange} onClear={onClear}
                onFilter={(filtro) => filtrar(filtro, null)}>
                <Filters>
                    <Field field="unidadIds" label="Numero económico" source="unidades" render={FormMultiSelect} />
                    <Field label={"Fechas"} field="fechas" render={FormCalendarRange} />
                </Filters>
                <Buttons filter={true} clear={true} />
            </FormFilter>
            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    rowSelection={true}
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    frameworkComponents={{
                        buttonsHeader: ButtonsHeader,
                        rowButtons: RowButtons,
                        goToDetail: agGridHelper.GoToDetailFormatter,
                        dateFormatter: agGridHelper.DateTimeFormatter,
                        rowProfundidad: RowProfundidad,
                        rowPresion: RowPresion
                    }}
                    onGridReady={() => filtrar(filter).then()}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                >
                    <AgGridColumn field="id" headerName="ID REVISION" cellClass={'center'} flex={2} />
                    <AgGridColumn field="numeroEconomico" headerName="NO. ECONOMICO" headerClass={'center'} />
                    <AgGridColumn field="fechaRevision" headerName="FECHA REVISION" headerClass={'center'} cellRenderer={'dateFormatter'} />
                    <AgGridColumn field="kmsActuales" headerName="KMS. ACTUALES" headerClass={'center'}
                        valueFormatter={agGridHelper.decimalFormatter} />
                    <AgGridColumn field="kmsAnteriores" headerName="KMS. ANTERIORES" headerClass={'center'}
                        valueFormatter={agGridHelper.decimalFormatter} />
                    <AgGridColumn field="fechaKmsAnteriores" headerName="FECHA KMS. ANT." headerClass={'center'} cellRenderer={'dateFormatter'} />
                    <AgGridColumn field="kmsRecorridos" headerName="KMS. RECORRIDOS" headerClass={'center'}
                        valueFormatter={agGridHelper.decimalFormatter} />
                    <AgGridColumn maxWidth={100} headerName="Profundidad" cellRenderer="rowProfundidad" />
                    <AgGridColumn maxWidth={100} headerName="Presion" cellRenderer="rowPresion" />
                    <AgGridColumn maxWidth={80} headerName="VER" pinned={'right'}
                        cellRenderer="rowButtons"
                        headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>
        </>
    )
}

export default Revision;