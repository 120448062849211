import React from "react";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import FieldMultiSelect from "../../shared/controls/fieldMultiSelect";

const FiltroFlotillas = ({filtro, onChange}) => {
    return (
        <>
            <FieldTextBox name="consecutivo" label="ID" colMd={1} keyFilter={'int'}
                          value={filtro.consecutivo} onChange={onChange}/>
            <FieldTextBox name="numeroEconomico" label="Núm. Económico" colMd={1}
                          value={filtro.numeroEconomico} onChange={onChange}/>
            <FieldTextBox name="texto" label="Marca/Año/No. Serie/Placas" colMd={3}
                          value={filtro.texto} onChange={onChange}/>
            <FieldMultiSelect name="tiposId" source="tiposUnidadFlotilla" label="Tipo" colMd={2} valueAsId={true}
                           value={filtro.tiposId} onChange={onChange}/>
            <FieldMultiSelect name="estatusDocumentos" source="estatusDocumentos" label="Estatus Documentos" colMd={2} valueAsId={true}
                              value={filtro.estatusDocumentos} onChange={onChange}/>
            <FieldMultiSelect name="estatus" source="estatus/unidad" label="Estatus" colMd={1} valueAsId={true}
                              value={filtro.estatus} onChange={onChange}/>
            <FieldMultiSelect name="operadores" source="empleados" label="Operador" colMd={4} valueAsId={true}
                              value={filtro.operadores} onChange={onChange} params={{ todasLasEmpresas: true }} />
            <FieldMultiSelect name="coordinadores" source="empleados" label="Coordinador" colMd={4} valueAsId={true}
                              value={filtro.coordinadores} onChange={onChange} params={{ todasLasEmpresas: true }}/>
        </>
    )
};

export default FiltroFlotillas;
