import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import constantes from "../../../catalogos/constantes";
import FieldAutoComplete from "../../../shared/controls/fieldAutoComplete";
import { produce } from "immer";
import FieldInputNumber from "../../../shared/controls/fieldInputNumber";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import {getItemStyle, reorder} from "../../../shared/DragAndDropUtility";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";


const stopDestino = constantes.tipoRuta.destino;

const RutasSegmentos = ({ viaje, errors, actualizar, agregarTramoRuta, soloLectura = false }) => {
    const rutas = viaje?.rutas;
    const indexTramoEnRuta = rutas?.findIndex(e => e.tramo !== 0 && e.tramo === viaje.tramoEnRuta) ?? -1;
    const totalRutas = rutas.length;
    const sePuedeAgregarSegmento = indexTramoEnRuta <= totalRutas - 2;

    const onDragEnd = (result) => {
        const indiceInicial = result.source.index;
        const indiceDestino = result.destination.index;
        let indexRutaDestino = rutas?.findIndex(e => e.stopNumber === stopDestino) ?? -1;
        indexRutaDestino = indiceInicial < indexRutaDestino ? indexRutaDestino : indexRutaDestino + 1;
        const posicionesInvalidas = [0, indexRutaDestino, totalRutas - 1];

        if (!result.destination || indiceDestino <= indexTramoEnRuta || posicionesInvalidas.includes(indiceDestino)) {
            return;
        }

        const rutasNuevoOrden = reorder(rutas, indiceInicial, indiceDestino);
        const newValue = produce(rutasNuevoOrden, (draft) => {
            draft[indiceDestino].fecha = null;
            draft[indiceDestino].hora = null;
            draft[indiceDestino].comision = 0;
        });

        actualizar(newValue, "rutas", true);
    };

    const actualizarRuta = (value, propertyName, index) => {
        const newValue = produce(rutas, (draft) => {
            if (propertyName === "direccion" && value != null) {
                draft[index].planta = value.nombre;
                draft[index].direccionCompleta = value.direccionCompleta;
            }

            if (propertyName === "comision") {
                draft[index].errorComision = false;
            }

            draft[index][propertyName] = value;
        });

        obtenerTotalComision(propertyName, newValue);
        actualizar(newValue, "rutas");
    };

    const obtenerTotalComision = (propertyName, rutas) => {
        if (propertyName !== "comision") return;

        const totalComision = rutas.reduce((acumulado, e) => {
            return (e.comision ?? 0) + acumulado;
        },
            0);

        actualizar(totalComision, "comision");
    };

    function eliminarRuta(index) {
        const newValue = produce(rutas, draft => {
            draft.splice(index, 1);
        }
        );
        obtenerTotalComision("comision", newValue);
        actualizar(newValue, 'rutas', true);
    }

    const deshabilitado = (ruta) => {
        // Si el tramo que esta en ruta es mayor al tramo de la ruta, tambien se deshabilita esta ruta
        return !viaje.rutasEditables || viaje.tramoEnRuta >= ruta.tramo;
    };

    const deshabilitadoComision = (ruta) => {
        // Si el tramo que esta en ruta es mayor al tramo de la ruta, tambien se deshabilita esta ruta
        return (!viaje.rutasEditables || viaje.tramoEnRuta >= ruta.tramo) && (ruta.segmentoLiquidado || !viaje.editarComisionSegmentos);
    };

    return (
        <>
            <table className="table table-sm table-detail">
                <thead className="thead-light">
                <tr>
                    <th/>
                    <th>DIRECCIÓN</th>
                    <th>TRAMOS</th>
                    <th className="text-center">COMISIÓN</th>
                    <th className="text-center">ES TRANSFER</th>
                    <th className="header-icons">
                        {viaje.rutasEditables && sePuedeAgregarSegmento &&
                            <ButtonIcon title="Agregar" className="grid-action" iconName={'SiAddthis'}
                                        onClick={() => agregarTramoRuta(indexTramoEnRuta)}/>
                        }
                    </th>
                </tr>
                </thead>

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <tbody
                                ref={provided.innerRef}
                            >
                                {rutas.map((item, index) => (
                                    <Draggable key={item.id} draggableId={`${item.id}`} index={index}
                                        isDragDisabled={item.esOrigen || item.stopNumber >= stopDestino || deshabilitado(item)}>
                                        {(provided, snapshot) => (
                                            <tr
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <td>
                                                    <b>{item.nombre}</b>

                                                    {index + 1 < rutas.length &&
                                                        <div className="pt-2"><i className="pi pi-ellipsis-v"/></div>
                                                    }
                                                </td>
                                                <td>
                                                    {item.esNuevoTramo ?
                                                        <>
                                                            <FieldAutoComplete name="direccion"
                                                                               errors={errors[`rutas[${index}]`]}
                                                                               source={`direcciones/${constantes.modulos.paradas}`}
                                                                               value={item.direccion}
                                                                               disabled={deshabilitado(item) || soloLectura}
                                                                               onChange={(value, name) => actualizarRuta(value, name, index)}/>
                                                            <div
                                                                style={{fontSize: '0.8em'}}>{item.direccionCompleta}</div>
                                                        </>
                                                        : <>
                                                            <div><b>{item.planta}</b></div>
                                                            <div
                                                                style={{fontSize: '0.8em'}}>{item.direccionCompleta}</div>
                                                        </>
                                                    }
                                                </td>

                                                <td className="text-center">
                                                    <span>{item.tramo === 0 ? '' : item.tramo}</span>
                                                </td>
                                                <td>
                                                    {item.tramo != null && item.tramo > 0 &&
                                                        <FieldInputNumber name="comision" value={item.comision}
                                                                          fractionDigits={2}
                                                                          error={item.errorComision}
                                                                          onChange={(value, name) => actualizarRuta(value, name, index)}
                                                                          disabled={deshabilitadoComision(item) || soloLectura}
                                                        />
                                                    }
                                                </td>
                                                <td style={{display:'flex', justifyContent:'center'}}>
                                                    {item.tramo != null && item.tramo > 0 &&
                                                        <
                                                            FieldCheckBox
                                                            name={'esTransfer'}
                                                            value={item.esTransfer}
                                                            onChange={(value, name) => actualizarRuta(value, name, index)}
                                                            disabled={deshabilitadoComision(item) || soloLectura}
                                                        />
                                                    }
                                                </td>
                                                <td className="table-button">
                                                    {item.esNuevoTramo && rutas.length > 3 && !deshabilitado(item) &&
                                                        <ButtonIcon title={'Eliminar'} iconName={'RiDeleteBinLine'}
                                                                    onClick={() => eliminarRuta(index)}/>
                                                    }
                                                </td>
                                            </tr>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </tbody>
                        )}
                    </Droppable>
                </DragDropContext>
            </table>

        </>
    );
};

export default RutasSegmentos;
