/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import {Calendar} from "primereact/calendar";
import moment from "moment";

const FieldCalendarRange = ({
                                colMd,
                                colSm,
                                label,
                                name,
                                onChange,
                                onBlur,
                                value,
                                colLg,
                                className,
                                minDate,
                                maxDate
                            }) => {

    const [selectedDates, setSelectedDates] = useState(null);
    const [visible, setVisible] = useState(true);
    className = className ?? "";
    minDate = minDate ?? moment().add(-3, 'years')._d;
    maxDate = maxDate ?? moment().add(6, 'months')._d;

    useEffect(() => {
        updateSelectedValue();
    }, [value]);

    function updateSelectedValue() {
        if (value != null && selectedDates != null && value.length >= 2 && selectedDates.length >= 2 && value[0] === selectedDates[0] && value[1] === selectedDates[1]) {
            return
        }
        setVisible(false);
        setTimeout(() => {
            setVisible(true);
            if (value == null) {
                setSelectedDates(value);
            } else {
                setSelectedDates(value.map(e => moment(e)._d));
            }
        }, 0);
    }

    function onValueChange(event) {
        setSelectedDates(event.value);
        onChange(event.value, name);
    }

    return (
        <>
            {!visible &&
                <input type="text" className="form-control" id={name}/>
            }
            {visible &&
                <div
                    className={`form-group col-lg-${colLg ?? colMd} col-md-${colMd ?? '4'} col-sm-${colSm != null ? colSm : '12'} ${className}`}>
                    <label htmlFor={name}>{label}</label>
                    <Calendar id={name}
                              monthNavigator
                              value={selectedDates}
                              onChange={onValueChange}
                              onBlur={onBlur}
                              selectionMode="range"
                              locale={'es'}
                              dateFormat="dd/M/yy"
                              minDate={minDate}
                              maxDate={maxDate}
                              className={'prime-form-control'}/>
                </div>
            }
        </>
    )
};

export default FieldCalendarRange;
