/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from "react";

const ApplicationContext = React.createContext(undefined)

const useApplicationProvider = () => {
    const [visibility, setVisibility] = useState({header:true, menu:true})
    return {
        visibleHeader:visibility.header,
        visibleMenu:visibility.menu,
        hideMenuAndHeader:()=>setVisibility({header:false, menu:false})
    }
}

export const useApplication = () => {
    return React.useContext(ApplicationContext)
}

const ApplicationProvider = ({children}) => {
    const value = useApplicationProvider()
    return (
        <ApplicationContext.Provider value={value}>
            {children}
        </ApplicationContext.Provider>
    )
}

export default ApplicationProvider