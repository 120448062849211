import useForm from "../../../shared/forms/useForm";
import Form from "../../../shared/forms/Form";
import FormCalendarRange from "../../../shared/forms/FormFields/FormCalendarRange";
import {Dialog} from "primereact/dialog";
import Field from "../../../shared/forms/Field";
import moment from "moment";
import {Button} from "primereact/button";
import React from "react";
import useApiFlotillas from "../../../api/comercial/useApiFlotillas";

const ExportarRuta = ({exportarUnidadId, onCancel})=>{

    const visible = exportarUnidadId != null

    const api = useApiFlotillas()

    const {
        values,
        onChange,
        onValidate,
    } = useForm({fechas:[moment().startOf('day').add(-7,'day')._d, new Date()]});

    const onExport = async ()=>{
        await api.excelEventos({...values, id:exportarUnidadId})
    }

    const renderFooter = () => {
        return (
            <div>
                <Button label="Exportar" icon="pi pi-check" onClick={onExport} autoFocus />
            </div>
        );
    };

    return (
        <Dialog header="Exportar Ruta" visible={visible} style={{width: '400px'}} onHide={onCancel}
                footer={renderFooter()}
        >
            <Form columns={1} values={values} onChange={onChange} onValidate={onValidate}>
                <Field  label={'Rango de Fechas'} field={'fechas'} render={FormCalendarRange}></Field>
            </Form>
        </Dialog>
    )
}
export default ExportarRuta;