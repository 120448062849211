import {useState} from "react";

const UseMapa = () => {
    const [mapa, setMapa] = useState({center: {lat: 25.686, lng: -100.316}, zoom: 10})

    const localizar = (position, zoom) =>{
        setMapa({center: position, zoom: zoom ?? 11})
    }


    return {
        mapa,
        localizar
    }

}

export default UseMapa