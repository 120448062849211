/* eslint-disable react-hooks/exhaustive-deps */
import React  from 'react'
import * as numeral from "numeral";
import moment from "moment";

const FormFieldLabel = ({value,type, cssClass}) => {

    const formatValue = ()=>{
        switch (type) {
            case fieldLabelTypes.currency:
                return numeral(value).format('$0,0.00');
            case fieldLabelTypes.date:
                return moment(value).format('DD/MM/YYYY');
            default:
                return value;
        }
    }

    return (
        <>
            <span className={`bold ${cssClass}`}>{formatValue()}</span>
        </>
    )
}

export default FormFieldLabel;

export const fieldLabelTypes = {
    currency : 1,
    date:2
}
