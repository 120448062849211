import * as yup from "yup";

const asignacionValidacionEsquema = yup.object().shape({
    unidad: yup.mixed().required(),
    operador: yup.mixed().required(),
    // caja: yup.mixed()
    //     .when("unidadPuedeLlevarCaja", {
    //         is: true,
    //         then: yup.mixed().required()
    //     }),    
});

export default asignacionValidacionEsquema;
