import useServiceFactory from "../serviceFactory";

const apiUrl = `/administracion/liberacion`;
const apiUrlCartaPorte = `/operaciones/viajes/programacion`;

const useApiLiberacionViaje = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URL});
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, {params, showLoading: true}),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, {showLoading: true}),
        actualizarDocumentos: async (id, formData) => service.put(`${apiUrl}/${id}/actualizarDocumentos`, formData),
        generarReciboValor: async (id, formData) => service.put(`${apiUrl}/${id}/generarReciboValor`, formData),
        liberar: async (id, formData) => service.put(`${apiUrl}/${id}/liberar`, formData),
        validar: async (id, viajeid) => service.put(`${apiUrl}/${viajeid}/validar/${id}`, {}),
        rechazar: async (id, viajeid, formData) => service.put(`${apiUrl}/${id}/rechazar/${viajeid}`, formData),
        rechazarComentarios: async (id, viajeid, formData) => service.put(`${apiUrl}/${id}/rechazarComentarios/${viajeid}`, formData),
        rechazarLiberacion: async (id, formData) => service.put(`${apiUrl}/${id}/rechazarLiberacion`, formData),
        excel: async (params) => service.get(`${apiUrl}/excel`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `Liberacion.xlsx`
            }),
        pdf: async (id) => service.get(`${apiUrl}/${id}/pdfReciboValor`,
            {
                showLoading: true,
                download: true,
                fileName: "reciboValor.pdf"
            }),
        descargar: async (id, cartaPorte) => service.get(`${apiUrlCartaPorte}/${id}/pdfCartaPorte`,
            {
                showLoading: true,
                download: true,
                fileName: `cartaPorte_${cartaPorte}.pdf`
            }),
        eliminarArchivo: async (viajeId, id) => service.delete(`${apiUrl}/${viajeId}/documentos/${id}`, {
            showLoading: false,
            showSuccess: false
        }),
        notificaciones: async () => service.get(`${apiUrl}/notificaciones`),
        leerNotificacion: async (id) => service.put(`${apiUrl}/leerNotificacion/${id}`, {}, {showLoading: false}),
        quitarNotificacion: async (id) => service.put(`${apiUrl}/quitarNotificacion/${id}`, {}, {showLoading: false}),
        validarNotificaciones: async () => service.get(`${apiUrl}/validarNotificaciones`, {showLoading: false}),
        historialDocumentos: async (viajeId, documentoId) => service.get(`${apiUrl}/${viajeId}/documentos/${documentoId}/historial`, {}),
        obtenerDocumentos: async (viajeId) => service.get(`${apiUrl}/${viajeId}/todosLosDocumentos`, {}),
        solicitarRevision: async (id, params) => service.post(`${apiUrlCartaPorte}/solicitarRevision/${id}`, params, {showLoading: false})
    }
};

export default useApiLiberacionViaje;
