/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./EstatusDiarios.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import * as numeral from "numeral";
import {Button} from "primereact/button";
import IconMatenimiento from "../IconMantenimiento";
import {IoPersonSharp} from "react-icons/io5";

const EstatusDiarioUnidad = (index, unidad, contadorUnidades, setOpenModalAgregarUnidad, mostrarDivision, editable) => {
    return (
        <>
            <React.Fragment key={index}>
                <div
                    style={{
                        borderTop: index === 0 && mostrarDivision ? "9px solid #737373" : "",
                    }}
                    className="unidad">
                    <span className="font-blue bold">
                        {unidad.isDefault !== true && <> {numeral(unidad.facturacion).format("$0,0")}</>}
                        {unidad.isDefault === true && (
                            <>
                                {editable &&
                                    <Button
                                        style={{marginLeft: "1px", marginTop: "8px"}}
                                        type="button"
                                        className="p-button-text pl-0"
                                        icon="pi pi-plus-circle"
                                        onClick={() => setOpenModalAgregarUnidad(true)}
                                        label="Agregar Unidades"
                                    />
                                }
                            </>
                        )}
                    </span>
                    <div className={'unidad-icon'}>
                        {unidad.unidadNegocio}
                    </div>
                    <div>
                        <span className="economico">{unidad.numEconomico}</span>
                    </div>
                    <div className={'unidad-icon'}>
                        <IoPersonSharp fill={'blue'}
                                       title={`Operador asignado: ${unidad?.nombreOperador}\nCoordinador asignado: ${unidad?.nombreCoordinador}`}
                                       cursor={"pointer"}/>
                    </div>

                    <span className={'unidad-nombre'}>{unidad.nombre}</span>
                    <div className={'unidad-icon'}>
                        <IconMatenimiento unidad={unidad} height={"12px"}></IconMatenimiento>
                    </div>
                </div>
            </React.Fragment>
        </>
    );
};

export default EstatusDiarioUnidad;
