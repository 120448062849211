import moment from "moment";
import useApiReparacionCompraLlanta from "../../../api/taller/useApiReparacionCompraLlanta";
import Field from "../../../shared/forms/Field";
import Form from "../../../shared/forms/Form";
import FormAutocomplete from "../../../shared/forms/FormFields/FormAutocomplete";
import FormDropDown from "../../../shared/forms/FormFields/FormDropDown";
import { serialize } from "object-to-formdata";
import SidebarEditForm from "../../../shared/forms/SideBarEditForm";
import { useState } from "react";
import FormCalendar from "../../../shared/forms/FormFields/FormCalendar";
import FormInputNumber from "../../../shared/forms/FormFields/FormInputNumber";
import FormTextBox from "../../../shared/forms/FormFields/FormTextBox";
import FormTextArea from "../../../shared/forms/FormFields/FormTextArea";

const ReparacionCompraDetalle = ({ reparacionCompraId, onSaved, onCancel }) => {
    const api = useApiReparacionCompraLlanta();
    const [readOnly, setReadOnly] = useState(false);

    const handleObtener = async (id) => {
        const entity = await api.obtener(id);
        return { ...entity };
    };

    const handleSave = async (entity) => {
        const formData = serialize({ ...entity }, { indices: true, dotsForObjectNotation: true });
        await api.guardar(formData);
    };

    const handleChange = (entity) => {
        setReadOnly(reparacionCompraId > 0 || entity?.readOnly);
    };

    return (
        <>
            <SidebarEditForm id={reparacionCompraId}
                initialValues={{ fecha: moment().startOf('day')._d }}
                getByIdFunction={handleObtener}
                saveFunction={handleSave}
                onSaved={onSaved}
                onCancel={onCancel}
                showCancelButton={true}
                onChangeForm={handleChange}
                formDisabled={readOnly}>
                <Form columns={2}>
                    <Field field={"operador"} label={"Operador"} required={true} cols={1}
                        render={({ onParentChange, parent, ...props }) =>
                            <FormAutocomplete source="empleados" 
                            params={{ operadores: true }} {...props} />} />
                    <Field field={"unidadId"} label={"Número Economico"} source="unidades" required={true} render={FormDropDown} />
                    <Field field={"conceptoId"} label={"Reparacion / Compra"} cols={1} source="reparacionCompra"
                        required={true} render={FormDropDown} />
                    <Field field={"fecha"} cols={1} label={"Fecha de Compra"} required={true} render={FormCalendar} />
                    <Field field={"costo"} cols={1} label={"Costo"} required={true} mode="decimal" maxFractionDigits={2}
                        render={FormInputNumber} />
                    <Field field={"folio"} cols={1} label={"Factura / Nota"} required={true} render={FormTextBox} />
                    <Field field={"observaciones"} cols={2} label={"Observaciones"} required={true} render={FormTextArea} />
                </Form>
            </SidebarEditForm>
        </>
    )
};

export default ReparacionCompraDetalle;