import {Timeline} from "primereact/timeline";
import {utcToString} from "../../../../shared/format";
import {nombreColorIndicador, nombreIndicador} from "../SeguimentoConstantes";
import SeguimientoIcon from "../../../../icons/SeguimientoYMonitoreo/SeguimientoIcons";
import {useControlMap} from "../ControlMapProvider";
import React, {useState} from "react";
import {getColorIndicadores, getContrast} from "../SeguimientoMonitoreutility";
import AtenderIncidenciaModal from "./AtenderIncidenciaModal";

const TimelineRuta = ({viajeId, bitacora, onChangeBitacora}) => {
    const [incidenciaActual, setIncidenciaActual] = useState(null)
    const {setNotificacionSeleccionada} = useControlMap()

    const handleSeleccion = async (item) => {
        setNotificacionSeleccionada(
            {
                lat: item.posicionGps.latitud,
                lng: item.posicionGps.longitud
            }
        )
    }

    const handleAtender = async (incidencia) => {
        setIncidenciaActual(incidencia)
    }

    const customizedMarker = (item) => {
        const icono = nombreIndicador[item.indicador]
        const indicador = {color: item.color}

        return (
            <span className="custom-marker p-shadow-2" style={{backgroundColor: item.color}}>
                <span className={getColorIndicadores(indicador)}>
                    <SeguimientoIcon iconName={`${icono}${getContrast(indicador)}`}
                                     onClick={() => handleSeleccion(item)}
                    />
                </span>
            </span>
        );
    };


    return (
        <>
            <div className={'seg-info-viaje'} style={{overflowY: 'auto', paddingTop: '10px'}}>
                <Timeline value={bitacora} className="customized-timeline" marker={customizedMarker}
                          content={(item) =>
                              <div>
                                  <div style={{display: 'flex', gap: '5px', alignItems: 'center', paddingTop: '3px'}}>
                                      <small
                                          className="p-text-secondary"><b>{utcToString(item.fechaUtc, 'DD-MMM HH:mm')}</b></small>
                                      <small className="p-text-secondary"> {item.numeroEconomico} </small>
                                  </div>
                                  <div>
                                      {!item.atendido && item.color !== nombreColorIndicador.verde && item.color !== nombreColorIndicador.gris &&
                                          <small className="p-text-secondary seg-seguimiento-boton"
                                                 onClick={() => handleAtender(item)}>
                                              Atender
                                          </small>
                                      }
                                      {item.atendido &&
                                          <>
                                              <div className="seg-indicador-atendido">
                                                  <small className={'bold'}> Revisó: </small>
                                                  <small className="p-text-secondary">
                                                      {item.usuarioAtiende}
                                                  </small>
                                                  <small className="p-text-secondary seg-seguimiento-boton"
                                                         onClick={() => handleAtender(item)}>
                                                      Ver
                                                  </small>
                                              </div>
                                              <div className="seg-indicador-atendido">
                                                  <small className={'bold'}> Fecha: </small>
                                                  <small className="p-text-secondary">
                                                      {utcToString(item.fechaAtiende)}
                                                  </small>
                                              </div>
                                          </>
                                      }
                                  </div>
                              </div>
                          }
                          opposite={(item) => <small className="p-text-secondary">{item.mensaje}</small>}
                />
            </div>

            <AtenderIncidenciaModal setIncidenciaActual={setIncidenciaActual}
                                    incidenciaActual={incidenciaActual}
                                    viajeId={viajeId}
                                    onChangeBitacora={onChangeBitacora}/>

        </>
    )
}
export default TimelineRuta;