import React, { useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import agGridHelper from "../../../shared/ag-grid-helper";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import FiltroExpeditados from "./filtroExpeditados";
import { useNavigate } from "react-router-dom";
import alerts from "../../../shared/alerts";
import useApiExpeditados from "../../../api/comercial/expeditados";
import Filtro from "../../../shared/componentes/filtro";
import { getSessionState } from "../../../hooks/useSessionState";

const Expeditado = ({ soloLectura }) => {
    const filtroKey = 'filtrosExpeditado';
    const [datos, setDatos] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const history = useNavigate();
    const apiExpeditados = useApiExpeditados();
    const fechaInicio = new Date();
    fechaInicio.setDate(fechaInicio.getDate() - 14);
    const defaultFilter = { estatus: [], fechas: [fechaInicio, new Date()] };

    async function buscar(filtro, paramsApi) {
        filtro = filtro ?? getSessionState(filtroKey);
        const nuevoFiltro = {
            ...filtro,
            id: filtro.id === "" ? 0 : filtro.id,
            cliente: filtro.cliente?.nombre,
            tipoUnidadId: filtro.tipoUnidad?.id,
            estatus: filtro.estatus?.map(e => e.id),
            origen: filtro.rutaOrigen?.nombre,
            destino: filtro.rutaDestino?.nombre
        };

        agGridHelper.showLoad(gridApi, paramsApi, true);
        setDatos(await apiExpeditados.filtrar(nuevoFiltro));
        agGridHelper.hideLoad(gridApi, paramsApi, false);
    }

    const onGridReady = async (params) => {
        setGridApi(params.api);
    };

    async function nuevo() {
        const id = await apiExpeditados.guardar(0);
        history('/comercial/nuevo_expeditado/' + id);
    }

    async function clonar(gridApi) {

        let row = gridApi.getSelectedRows().pop();
        if (row == null) {
            alerts.aviso('Debe seleccionar un expeditado para clonarlo');
            return;
        }
        const nuevoId = await apiExpeditados.clonar(row.id);
        history('/comercial/nuevo_expeditado/' + nuevoId);
    }

    async function actualizarGeocercas() {
        await apiExpeditados.actualizarGeocercas();
    }


    const ButtonsHeader = ({ api }) => {
        return (
            <div className={'ag-grid header-icons'}>
                <ButtonIcon title={'Actualizar Geocercas'} onClick={actualizarGeocercas} iconName={'GrMapLocation'} />
                <ButtonIcon title={'Descargar'} onClick={() => agGridHelper.export(api)} iconName={'BiDownload'} />
                {!soloLectura &&
                    <>
                        <ButtonIcon title="Clonar" onClick={() => clonar(api)} iconName={'IoDuplicateOutline'} />
                        <ButtonIcon title="Agregar" className={'grid-action'} iconName={'MdAddBox'} onClick={nuevo} />
                    </>
                }
            </div>
        );
    };

    return (
        <>
            <Filtro filtroKey={filtroKey} handleSubmit={buscar} defaultValue={defaultFilter} gridApi={gridApi}>
                <FiltroExpeditados />
            </Filtro>

            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    rowSelection={'single'}
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    frameworkComponents={
                        {
                            goToDetail: agGridHelper.GoToDetailFormatter,
                            buttonsHeader: ButtonsHeader,
                            dateFormatter: agGridHelper.DateFormatter
                        }
                    }
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                >
                    <AgGridColumn maxWidth={40} checkboxSelection={true} />
                    <AgGridColumn field="codigoExpeditado" headerName="NO. EXPEDITADO" width={150} cellClass={'center'} flex={2} />
                    <AgGridColumn field="clienteNombre" headerName="CLIENTE" headerClass={'left'} cellClass={'left'}
                        width={300} minWidth={150} flex={6} resizable={true} />
                    <AgGridColumn field="rutaCiudad" headerName="RUTA" minWidth={100} cellClass={'center'} headerClass={'center'} flex={5} resizable={true} />
                    <AgGridColumn field="tipoUnidad" headerName="TIPO DE UNIDAD" cellClass={'left'} minWidth={100} flex={3} resizable={true} />
                    <AgGridColumn field="fecha" headerName="FECHA" minWidth={100} cellRenderer="dateFormatter"
                        cellClass={'center'} flex={2} resizable={true} />
                    <AgGridColumn field="codigoRuta" headerName="CODIGO DE RUTA" minWidth={100} flex={3} resizable={true} />
                    <AgGridColumn field="ruta" headerName="NOMBRE EXPEDITADO" minWidth={300} flex={3} resizable={true} />
                    <AgGridColumn field="precioViaje" headerName="FACTURACIÓN" headerClass={'center'} cellClass={'right'}
                        valueFormatter={agGridHelper.currencyFormatter} resizable={true} />
                    <AgGridColumn field="numeroDeStops" headerName="# STOPS" minWidth={70} cellClass={'center'}
                        flex={2} resizable={true} />
                    <AgGridColumn field="estatus" headerName="ESTATUS" cellClass={'center'} resizable={true} />
                    <AgGridColumn maxWidth={165} headerName="VER" cellRenderer="goToDetail" pinned={'right'}
                        path='/comercial/editar_expeditado' headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>
        </>
    )
};

export default Expeditado;
