import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import React, {useState} from "react";
import FieldAutoComplete from "../../../shared/controls/fieldAutoComplete";
import {produce} from "immer";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import useApiConvenios from "../../../api/comercial/convenios";

const ModalConvenio = ({visible, hide, id, onChange, onSave}) => {
    const [filtro, setFiltro] = useState({});
    const api = useApiConvenios();
    const [datos, setDatos] = useState([]);

    const cargar = async (filtro) => {
        const result = await api.filtrar(filtro);
        setDatos(result);
    };

    async function actualizarFiltro(value, name) {
        const newValue = produce(filtro, draft => {
            draft[name] = value
        });

        setFiltro(newValue);
        const nuevoFiltro = {
            ...newValue,
            numeroConvenio: newValue.numeroConvenio,
            cliente: newValue.cliente?.nombre,
            subClienteId: newValue.subCliente?.id,
            origen: newValue.rutaOrigen?.nombre,
            destino: newValue.rutaDestino?.nombre,
        };
        await cargar(nuevoFiltro);
    }

    const guardar = async (item) => {
        if (id > 0) {
            await api.guardarRelacionado(id, item.id);
            onSave();
        } else {
            onChange(item.id, "convenioRelacionadoId");
        }

        hide();
    };

    const renderFooterModal = () => (
        <div>
            <Button label="Cerrar" onClick={hide} className="p-button-outlined"/>
        </div>
    );

    return (
        <Dialog visible={visible} onHide={hide} style={{width: '75vw'}}
                footer={renderFooterModal} header={"Convenio Relacionado"}>
            <div className="form-row">
                <FieldAutoComplete
                    label={"Cliente"}
                    name="cliente"
                    source="clientes"
                    freeText
                    colMd={4}
                    value={filtro.cliente}
                    onChange={actualizarFiltro}/>
                <FieldDropDown
                    name="subCliente"
                    label="SubCliente"
                    source="subClientes"
                    params={{clienteId: filtro.cliente?.id}}
                    showClear={true}
                    value={filtro.subCliente}
                    colMd={4}
                    onChange={actualizarFiltro}/>
                <div className="form-group col-md-4 col-sm-12">
                    <label>Ruta</label>
                    <div className="col-auto">
                        <FieldAutoComplete
                            name="rutaOrigen"
                            source="rutasOrigen"
                            colMd={1}
                            freeText={true}
                            placeholder="Desde"
                            value={filtro.rutaOrigen}
                            onChange={actualizarFiltro}
                        />
                        <FieldAutoComplete
                            name="rutaDestino"
                            source="rutasDestino"
                            colMd={1}
                            freeText={true}
                            placeholder="Hasta"
                            value={filtro.rutaDestino}
                            onChange={actualizarFiltro}
                        />
                    </div>
                </div>
            </div>
            <div className="table-custom table-in-panel pl-2">
                <table className="table table-sm table-striped table-hover table-fixed">
                    <thead className="filter-panel">
                    <tr>
                        <th style={{width: "15%"}}>No. Convenio</th>
                        <th style={{width: "25%"}}>Cliente</th>
                        <th style={{width: "25%"}}>Sub-Cliente</th>
                        <th style={{width: "35%"}}>Ruta</th>
                    </tr>
                    </thead>
                    <tbody>
                    {datos?.map((item, index) => (
                        <tr key={index} onClick={()=>guardar(item)}>
                            <td style={{width: "15%"}}>{item.numeroConvenio}</td>
                            <td style={{width: "25%"}}>{item.cliente}</td>
                            <td style={{width: "25%"}}>{item.subCliente}</td>
                            <td style={{width: "35%"}}>{item.rutaCiudades}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </Dialog>
    );
};

export default ModalConvenio;
