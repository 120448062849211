import {Dialog} from "primereact/dialog";
import React, {useCallback, useEffect, useState} from "react";
import FieldTextBox from "../shared/controls/fieldTextBox";
import {ProgressSpinner} from "primereact/progressspinner";
import windowLogo from "../img/window-logo.png";
import useFormLogin from "./useFormLogin";
import alerts from "../shared/alerts";
import {useAuth} from "./AuthProvider";

const SesionExpired = () => {
    const [visible, setVisible] = useState(false)
    const {sessionExpired, isAuthenticated, logout} = useAuth()

    let intervalReview = null
    const reviewToken = useCallback(() => {
        if (intervalReview != null) {
            clearInterval(intervalReview)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        intervalReview = setInterval(function () {
            if (sessionExpired()) {
                setVisible(true)
                clearInterval(intervalReview)
            }
        }, 1000)
    }, [sessionExpired])


    useEffect(() => {
        if (isAuthenticated()) {
            reviewToken()
        }
        return () => {
            if (intervalReview != null) {
                clearInterval(intervalReview)
            }
        }
    }, [isAuthenticated,intervalReview, reviewToken])

    const {
        actualizar,
        validarUsuario,
        submitted,
        isSubmitting,
        login,
        handleLoginAzure
    } = useFormLogin();

    const onStorageUpdate = useCallback((e) => {
        const {key} = e
        if (key === 'authenticando') {
            const authenticando = localStorage.getItem('authenticando')
            if (authenticando === 'false') {
                localStorage.setItem('authenticando', false)
                setVisible(false)
            }
        }
    }, [])

    useEffect(() => {
        window.addEventListener('storage', onStorageUpdate)
        return () => {
            window.removeEventListener('storage', onStorageUpdate)
        }
    }, [onStorageUpdate])


    const onHide = async () => {
        if ((await alerts.preguntarSiNo("Se cerrará la sesión ¿desea continuar?")) === true) {
            setVisible(false)
            logout()
        }
    }

    const handleLogin = async () => {
        const validado = await validarUsuario(false)
        if (validado)
            setVisible(false)
    }

    const onKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleLogin();
        }
    }

    return (
        <Dialog header="Sesión Finalizada, iniciar sesión nuevamente " visible={visible} style={{width: '500px'}}
                onHide={() => {
                    onHide()
                }}>
            <div className={"form-login"} onKeyDown={onKeyDown}>
                <FieldTextBox label="Usuario"
                              colMd={12}
                              name="username"
                              error={login.username === '' && submitted}
                              onChange={actualizar}
                />
                <FieldTextBox label="Contraseña"
                              colMd={12}
                              name="password"
                              error={login.password === '' && submitted}
                              type={"password"}
                              onChange={actualizar}
                />
                <div className="form-group col-12">
                    {!isSubmitting && <button type="button" className="btn btn-primary form-control"
                                              onClick={handleLogin}>Entrar</button>}
                    {isSubmitting && <ProgressSpinner style={{height: 50}}/>}
                </div>
            </div>
            <div className={'microsoft-login'}>
                <div className="form-group col-12">
                    <button className="btn btn-login form-control" onClick={handleLoginAzure}>
                        <img src={windowLogo} alt={'window'} width={'22px'}/>
                        <span className="p-px-3">Continuar con  Microsoft</span>
                    </button>
                </div>
            </div>
            <div>

            </div>
        </Dialog>
    )
}
export default SesionExpired;