import React, { useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import agGridHelper from "../../../shared/ag-grid-helper";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import FiltroOrdenesTrabajo from "./filtroOrdenesTrabajo";
import { useNavigate } from "react-router-dom";
import UseApiOrdenServicio from "../../../api/taller/useApiOrdenServicio";
import Calendario from "./calendario/calendario";
import { getSessionState } from "../../../hooks/useSessionState";
import useSeguridad from "../../../Seguridad/useSeguridad";
import constantes from "../../../catalogos/constantes";
const OrdenesTrabajo = ({ soloLectura }) => {
    const filtroKey = 'filtrosOrdenTrabajo';
    const [datos, setDatos] = useState([]);
    const [filtros, setFiltros] = useState(null);
    const [esVistaDeLista, setEsVistaDeLista] = useState(true);
    const [gridApi, setGridApi] = useState(null);
    const history = useNavigate();
    const apiOrdenServicio = UseApiOrdenServicio();
    const seguridad = useSeguridad();
    const permisoUpdateInventario= seguridad.tieneAcceso(constantes.opciones.permisoUpdateInventario);
    async function buscar(filtros, paramsApi) {
        agGridHelper.showLoad(gridApi, paramsApi, true);
        setFiltros(filtros);
        setDatos(await apiOrdenServicio.filtrar(filtros));
        agGridHelper.hideLoad(gridApi, paramsApi, false);
    }

    const onGridReady = async (params) => {
        setGridApi(params.api);
    };

    async function nuevo() {
        history('/taller/mantenimiento/nuevo_orden/' + 0);
    }

    function exportar() {
        apiOrdenServicio.excel(getSessionState(filtroKey));
    }

    function actualizarInventario() {
        apiOrdenServicio.actualizarInventario();
    }

    const ButtonsHeader = ({ api }) => {
        return (
            <div className={'ag-grid header-icons'}>
                {(permisoUpdateInventario) &&
                    <ButtonIcon title={'Actualizar Inventario'} onClick={actualizarInventario} iconName={'FaWarehouse'} />
                }
                <ButtonIcon title={'Descargar'} onClick={exportar} iconName={'BiDownload'} />
                {!soloLectura &&
                    <ButtonIcon title="Agregar" className={'grid-action center'} iconName={'MdAddBox'} onClick={nuevo} />
                }
            </div>
        );
    };

    return (
        <>
            <FiltroOrdenesTrabajo filtroKey={filtroKey} handleSubmit={buscar} cambiarVista={setEsVistaDeLista} esVistaDeLista={esVistaDeLista} gridaApi={gridApi} />
            {esVistaDeLista &&
                <div className="ag-theme-balham grid-principal">
                    <AgGridReact
                        rowSelection={'single'}
                        enableSorting={true}
                        enableFilter={true}
                        pagination={true}
                        frameworkComponents={
                            {
                                goToDetail: agGridHelper.GoToDetailFormatter,
                                buttonsHeader: ButtonsHeader,
                                dateFormatter: agGridHelper.DateFormatter,
                             
                            }
                        }
                        onGridReady={onGridReady}
                        rowData={datos}
                        overlayLoadingTemplate={agGridHelper.loadingOverlay}
                        defaultColDef={agGridHelper.defaultColumns}
                        getRowClass={(params) => params.data.nombreColor}
                    >

                        <AgGridColumn field="codigo" headerName="ORDEN SERVICIO" cellClass={'center'} flex={2} />
                        <AgGridColumn field="mecanicos" headerName="MECANICO" headerClass={'center'} minWidth={300}
                            flex={2} />
                        <AgGridColumn field="tipoServicio" headerName="TIPO SERVICIO" minWidth={170} headerClass={'center'}
                            flex={3} />
                        <AgGridColumn field="descripcion" headerName="DESCRIPCION" flex={5} />
                        <AgGridColumn field="fechaProgramada" headerName="FECHA PROGRAMADA" cellRenderer="dateFormatter"
                            minWidth={100} flex={3} />
                        <AgGridColumn field="tipoUnidad" headerName="TIPO UNIDAD" minWidth={100} cellClass={'center'}
                            flex={3} />
                        <AgGridColumn field="numeroEconomico" headerName="NO. ECONOMICO" minWidth={120} flex={2}
                            headerClass={'center'} />
                        <AgGridColumn field="ultimoMantenimiento" headerName="ULTIMO MTTO" minWidth={120} cellRenderer="dateFormatter" />
                        <AgGridColumn field="estatus" headerName="ESTATUS" cellClass={'center'} />
                        <AgGridColumn maxWidth={120} headerName="VER" cellRenderer="goToDetail" pinned={'right'}
                            path='/taller/mantenimiento/editar_orden' headerComponent="buttonsHeader" />
                    </AgGridReact>
                </div>
            }
            {!esVistaDeLista &&
                <Calendario filtros={filtros} />
            }
        </>
    )
};

export default OrdenesTrabajo;
