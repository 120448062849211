import useServiceFactory from "../serviceFactory";
const apiUrl = `/taller/mantenimiento/OrdenesTrabajo`;

const useApiAlmacen = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });

    return {
        filtrar: async (id) => service.get(`${apiUrl}/almacenOrdenes/${id}`, {}),
        filtrarAlmacen: async (params) => service.get(`${apiUrl}/almacen`, {params}),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, {}),
        guardar: async (params) => service.post(`${apiUrl}/registraRefacciones`, params),
        cancelarSalidaDeArticulo: async (params) => service.patch(`${apiUrl}/cancelarSalidaArticulo`, params),
        excel: async (params) => service.get(`${apiUrl}/almacenexcel`,
        {
            params,
            showLoading: true,
            download: true,
            fileName: `Refacciones_OTs.xlsx`
        }) 
    }
};

export default useApiAlmacen;
