/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import UnidadCaja from "../UnidadCaja";
import IconTabs from "../../../../shared/iconTabs/IconTabs";
import IconTab from "../../../../shared/iconTabs/IconTab";
import InformacionRuta from "./InformacionRuta";
import InformacionOperador from "./InformacionOperador";
import TimelineRuta from "./TimelineRuta";
import Notificaciones from "./Notificaciones";
import InformacionUnidad from "./InformacionUnidad";
import {useSeguimientoMonitoreo} from "../SeguimientoMonitoreoProvider";
import useApiViajeSeguimientoMonitoreo from "../../../../api/operaciones/useApiSeguimientoMonitoreo";
import {Button} from "primereact/button";
import useApiViajeSeguimiento from "../../../../api/operaciones/useApiViajesSeguimiento";
import TerminarViajesEnRuta from "./TerminarViajesEnRuta";
import alerts from "../../../../shared/alerts";
import {updateArrayAtIndex} from "../../../../shared/arrayUtility";
import constantes from "../../../../catalogos/constantes";
import constantesOperacion from "../../../constantes";
import {programado} from "../SeguimientoMonitoreutility";

const InformacionViajeModal = () => {

    const {viajeSeleccionado, aplicarFiltro, ocultarViaje, refreshIndicadores} = useSeguimientoMonitoreo();
    const api = useApiViajeSeguimientoMonitoreo()
    const apiSeguimiento = useApiViajeSeguimiento();
    const [otrosViajesRuta, setOtrosViajesRuta] = useState({viajes: [], visible: false})
    const [viajePorIniciar, setViajePorIniciar] = useState(null)

    const [viaje, setViaje] = useState({})
    const visible = viajeSeleccionado != null;

    useEffect(() => {
        if (viajeSeleccionado != null) {
            cargarViaje().then()
        }
    }, [viajeSeleccionado]);

    const cargarViaje = async () => {
        const viaje = await api.getViajeById(viajeSeleccionado.viajeId,
            {unidadId: viajeSeleccionado.unidadId, cajaId: viajeSeleccionado.cajaId});
        setViaje(viaje)
    }

    if (!visible) {
        return null
    }

    const revisarIniciarViaje = async (viajeId) => {
        let listaViajesEnRuta = await apiSeguimiento.otrosViajesEnRutaPorUnidad({viajeId, unidadId: viaje.unidad.id});
        if (listaViajesEnRuta.length === 0) {
            await iniciarViaje(viajeId)
        } else {
            setOtrosViajesRuta({viajes: listaViajesEnRuta, visible: true});
            setViajePorIniciar(viajeId)
        }
    };

    const refresh = async ()=>{
        await aplicarFiltro()
        ocultarViaje()
    }

    const iniciarViaje = async (viajeId) => {
        await api.iniciar(viajeId, {})
        await refresh()
    }

    const onClose = () => {
        setOtrosViajesRuta({viajes: [], visible: false});
    }

    const terminarViaje = async () =>{

        const pregunta = !viaje.enUltimaGeocerca ?
            'Aún no se ha detectado la llegada al destino ¿Desea terminar el viaje?' :
            '¿Desea terminar el viaje?'

        if (await alerts.preguntarSiNo(pregunta)) {
            await apiSeguimiento.terminar(viaje.segmentoId, {})
            await refresh()
        }
    }

    const terminarSegmento = async () =>{

        if (await alerts.preguntarSiNo("¿Desea terminar el segmento?")) {
            await apiSeguimiento.terminar(viaje.segmentoId, {})
            await refresh()
        }
    }

    const onChangeBitacora = (bitacora) =>{
        let index = viaje.ultimosIndicadores.findIndex(e=>e.id === bitacora.id)
        let viajeActual  = viaje;
        if (index >= 0) {
            viajeActual.ultimosIndicadores = updateArrayAtIndex(viaje.ultimosIndicadores, index, bitacora)
        }

        index = viajeActual.bitacora.findIndex(e=>e.id === bitacora.id)

        if (index < 0)
            return

        setViaje({...viajeActual, bitacora: updateArrayAtIndex(viaje.bitacora, index, bitacora)});
    
        refreshIndicadores()
    }
    const solicitarDryRun = async () =>{

        if (await alerts.preguntarSiNoAdvertencia("¿Está seguro de generar el viaje como Dry Run?")) {
            await apiSeguimiento.solicitamosDryRun(viaje.id);
            await refresh()
        }
    }
    return (<>
            <div style={{width: '450px'}}
                 className="seg-modal">
                <div className={'seg-dialog-header'}>
                    <div className={'seg-dialog-title'}>
                        Información del viaje
                    </div>
                    <Button icon="pi pi-times" className="p-button-rounded p-button-text seg-dialog-close"
                            onClick={ocultarViaje}/>
                </div>
                <div className="p-fluid form-value">
                    <UnidadCaja viaje={viaje}/>
                </div>
                <IconTabs>
                    <IconTab icon={'Ruta'} tabId={0}>
                        <InformacionRuta viaje={viaje}/>
                    </IconTab>
                    <IconTab icon={'Operador'} tabId={1}>
                        <InformacionOperador viaje={viaje}/>
                    </IconTab>
                    <IconTab icon={'Camion'} tabId={4}>
                        <InformacionUnidad viaje={viaje}/>
                    </IconTab>
                    <IconTab icon={'Posicion'} tabId={2}>
                        <TimelineRuta viajeId={viaje.id} bitacora={viaje.bitacora} onChangeBitacora={onChangeBitacora}/>
                    </IconTab>
                    <IconTab icon={'Alarma'} tabId={3}>
                        <Notificaciones viajeId={viaje.id} bitacora={viaje.ultimosIndicadores} onChangeBitacora={onChangeBitacora}/>
                    </IconTab>
                </IconTabs>
                <div className={'fluid'} class={'dialog-footer center-buttons'}>
                    {
                        programado(viaje.estatus) &&
                        <Button label="Iniciar Viaje" className={'p-button-sm'} onClick={()=>revisarIniciarViaje(viaje.id)}/>
                    }
                    {viaje.puedeTerminarSegmento &&
                        <Button label={'Terminar Segmento'} className={'p-button-sm'} onClick={terminarSegmento}/>
                    }
                    {viaje.puedeIniciarSegmento &&
                        <Button label={'Iniciar Segmento'} className={'p-button-sm'} onClick={()=>revisarIniciarViaje(viaje.segmentoId)}/>
                    }
                    {viaje.puedeTerminarViaje &&
                        <Button label={'Terminar Viaje'} className={'p-button-sm'} onClick={terminarViaje}/>
                    }
                    {viaje.clienteId === constantes.cliente.Penske && 
                                                (viaje.estatus === constantesOperacion.estatusViajes.enRuta || 
                                                    viaje.estatus === constantesOperacion.estatusViajes.enRuta.estatusProgramado) && 
                        <Button label={'Dry Run'} className={'p-button-sm'} onClick={solicitarDryRun}/>
                    }
                </div>
            </div>
            <TerminarViajesEnRuta iniciarViaje={()=>iniciarViaje(viajePorIniciar)} viaje={viaje} onClose={onClose}
                                  otrosViajesRuta={otrosViajesRuta}/>
        </>
    )


}
export default InformacionViajeModal;
