/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import FieldInputNumber from "../../../shared/controls/fieldInputNumber";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import useApiConvenios from "../../../api/comercial/convenios";
import { useReducer } from "react";
import formReducer from "../../../shared/forms/formReducer";
import { agregarOrdenCompra } from "./OrdenCompraValidacionEsquema";
import FormHandler from "../../../shared/forms/formHandler";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import produce from "immer";

const AgregarOrdenDeCompra = ({ convenioId, viajes, visible, hide, onChange, update }) => {
    const api = useApiConvenios();
    const defaultEntity = () => ({
        entity: {
            viajesRealizados: 0,
            activa: true,
            actual: 'SI',
            viajes: []
        }
    });

    const [{ entity, errors }, dispatch] = useReducer(formReducer, {}, defaultEntity);

    const formHandler = new FormHandler({
        validationSchema: agregarOrdenCompra,
        api,
        dispatch
    });

    const actualizar = (value, propertyName) => {
        dispatch({ type: 'update', value: value, name: propertyName });

        if (propertyName === 'viajesTotales') {
            let count = 1;
            let list = [];
            viajes.forEach((item, index) => {
                let row = {
                    id: item.id,
                    cartaPorte: item.cartaPorte,
                    numeroFactura: item.numeroFactura,
                    comentarios: item.comentarios,
                    seleccionado: true,
                    desabilitado: false
                };

                if (count > value) {
                    row.seleccionado = false;
                    row.desabilitado = true;
                }

                list.push(row);
                count++;
            });

            onChange(list);

            const Idlist = list.filter(v => v.seleccionado).map(v => v.id);
            dispatch({ type: 'update', value: Idlist, name: 'viajes' });
            dispatch({ type: 'update', value: Idlist.length, name: 'viajesRealizados' });
            dispatch({ type: 'update', value: Idlist.length < value, name: 'activa' });
            dispatch({ type: 'update', value: Idlist.length < value ? 'SI' : 'NO', name: 'actual' });
        }
    };

    const seleccionar = (value, propertyName, index) => {
        const newValue = produce(viajes, (draft) => {
            let row = draft[index];
            row[propertyName] = value;
        });

        onChange(newValue);

        const list = newValue.filter(v => v.seleccionado).map(v => v.id);
        dispatch({ type: 'update', value: list, name: 'viajes' });
        dispatch({ type: 'update', value: list.length, name: 'viajesRealizados' });
        dispatch({ type: 'update', value: list.length < entity.viajesTotales, name: 'activa' });
        dispatch({ type: 'update', value: list.length < value ? 'SI' : 'NO', name: 'actual' });
    };

    const guardar = async () => {
        const validar = await formHandler.validate(entity);
        if (validar.result) {
            await api.agregarOrdenCompra(convenioId, entity);
            update(entity);
            clean();
            close();
        }
    };

    const close = () => {
        clean();
        hide();
    };

    const clean = () => {
        dispatch({
            type: "update",
            value: { ...defaultEntity }
        });
    };

    const renderFooterModal = () => {
        return (
            <div>
                <Button label="Cancelar" onClick={close} className="p-button-outlined" />
                <Button label="Guardar" onClick={guardar} autoFocus />
            </div>
        );
    };

    return (
        <Dialog header="Agregar Orden de Compra" visible={visible} style={{ width: '50vw' }} footer={renderFooterModal()} onHide={close}>
            <div className="form-row">
                <FieldTextBox name="ordenCompra" label="Orden de compra" colMd={8} value={entity.ordenCompra} onChange={actualizar} error={errors?.ordenCompra} />
                <FieldInputNumber name="viajesTotales" label="No. de viajes" colMd={4} value={entity.viajesTotales} onChange={actualizar} error={errors?.viajesTotales} />
            </div>
            {viajes?.length > 0 &&
                <table className="table table-borderless">
                    <thead>
                        <tr>
                            <th scope="col" style={{ width: '20%' }} className="center">CARTA PORTE</th>
                            <th scope="col" style={{ width: '20%' }} className="center">FACTURA</th>
                            <th scope="col" style={{ width: '60%' }} className="center">COMENTARIOS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {viajes?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <FieldCheckBox name="seleccionado" value={item.seleccionado} label={item.cartaPorte} colMd={12}
                                            disabled={item.desabilitado} onChange={(value, propertyName) => seleccionar(value, propertyName, index)} />
                                    </td>
                                    <td className="center">{item.numeroFactura}</td>
                                    <td>{item.comentarios}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            }
        </Dialog>
    )
}

export default AgregarOrdenDeCompra;