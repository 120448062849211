import useServiceFactory from "../serviceFactory";
const apiUrl = `/catalogos/ConfiguracionBono`;

const useApiConfiguracionBono = () => {
    const service = useServiceFactory({ url: process.env.REACT_APP_API_URL });
    return {
        filtrarTipoBono: async (params) => service.get(`${apiUrl}/filtrarTipoBono`, { params }),
        getTipoBono: async (id) => service.get(`${apiUrl}/getTipoBono/${id}`, { showLoading: true }),
        guardarTipoBono: async (params) => service.post(`${apiUrl}/guardarTipoBono`, params),
        eliminarTipoBono: async (id) => service.delete(`${apiUrl}/eliminarTipoBono/${id}`),
        filtrarConceptoBono: async (params) => service.get(`${apiUrl}/filtrarConceptoBono`, { params }),
        getConceptoBono: async (id) => service.get(`${apiUrl}/getConceptoBono/${id}`, { showLoading: true }),
        guardarConceptoBono: async (params) => service.post(`${apiUrl}/guardarConceptoBono`, params),
        eliminarConceptoBono: async (id) => service.delete(`${apiUrl}/eliminarConceptoBono/${id}`)
    }
};

export default useApiConfiguracionBono;