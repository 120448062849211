import useServiceFactory from "../serviceFactory";

const apiUrl = 'operaciones/configuracionLear';

const useApiConfiguracionLear = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, { params }),
        guardar: async (params) => service.post(`${apiUrl}`, params)
    }
};

export default useApiConfiguracionLear;