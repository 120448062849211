import React from "react";
import {useSeguimientoMonitoreo} from "../SeguimientoMonitoreoProvider";
import Mapa from "./Mapa";

const SeguimientoMonitoreoMapa = () => {
    const {posiciones, unidadesOcultas,unidades, ruta} = useSeguimientoMonitoreo();

      const getPosicionesVisibles = ()=>{
        return posiciones.filter(e=>!unidadesOcultas.has(e.unidadId));
    }

    return (<>
        <Mapa posiciones={getPosicionesVisibles()} unidadesOcultas={unidadesOcultas} unidades={unidades} ruta={ruta} />
    </>)
}
export default SeguimientoMonitoreoMapa;