/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Form from "../../shared/forms/Form";
import Field from "../../shared/forms/Field";
import useForm from "../../shared/forms/useForm";
import { Button } from "primereact/button";
import useApiMovimientos from "../../api/administracion/useApiMovimientos";
import moment from "moment";
import GastosMovimientos from "./GastosMovimientos";
import FormClipboardImage from "../../shared/forms/FormFields/FormClipboardImage";
import CompensacionMovimientos from "./CompensacionMovimientos";
import DescuentoMovimiento from "./DescuentoMovimiento"
import FormDropDown from "../../shared/forms/FormFields/FormDropDown";
import FormTextArea from "../../shared/forms/FormFields/FormTextArea";
import FormInputNumber from "../../shared/forms/FormFields/FormInputNumber";
import * as numeral from "numeral";
import ReadOnlyProvider from '../../shared/forms/ReadOnlyProvider';
import {TabPanel, TabView} from "primereact/tabview";
import ExGrid from "../../shared/Grid/ExGrid";
import ExColumn from "../../shared/Grid/ExColumn";
import {fechaUtc} from "../../shared/Grid/GridCellRenderers";
const initialValues = {

    solicitudDeGastos: {
        fecha: moment().startOf('day')._d
    }
};

const DetalleMovimientos = ({ movimientoId, cajaId, onCancel, onSaved, soloLectura }) => {
    const [submitted, setSubmitted] = useState(false);
    const api = useApiMovimientos();
    initialValues.cajaId = cajaId;
    useEffect(() => {
        setSubmitted(false);
        load(movimientoId).then();
    }, [movimientoId])

    const {
        values: solicitud,
        setValues,
        onChange,
        onValidate,
        isValid
    } = useForm(initialValues);

    const load = async (movimientoId) => {
        const result = await api.obtener(movimientoId);

        setValues(result);

    }

    const onSave = async () => {
        setSubmitted(true);
        if (!isValid) {
            return;
        }
        await onSaveComprobantes();
    }
    const onSaveComprobantes = async () => {
        const formData = new FormData();
        formData.append('file', solicitud.gastoUrl.file);
        const res = await api.archivoGasto(movimientoId, formData);
        solicitud.url = res.fileUrl;
        await api.actualizarComprobado(movimientoId, solicitud);
        onSaved();
    }
    return (
        <>
            <TabView>
                <TabPanel header={"Movimiento"}>
                    <ReadOnlyProvider defaultReadOnly={soloLectura}>
                        <Form columns={1} values={solicitud} width={'100%'}
                              onChange={onChange}
                              onValidate={onValidate}
                              submitted={submitted}
                              className={`form-container full-height full-width ${submitted ? 'submitted' : ''}`}>
                            <h5 style={{ gridColumnEnd: 'span 2' }}>
                                Movimiento
                            </h5>
                            <Field label="Empleado" field={"empleado"} disabled="true" source="empleados" cols={2}
                                   render={FormDropDown} required={true} />

                            {solicitud?.tipoGasto &&
                                <Field field="gastosMovimientos" cols={2} render={(props) =>
                                    <GastosMovimientos value={solicitud} cols={2} />
                                } />
                            }
                            {solicitud?.tipoCompensaciones &&
                                <Field field="compensacionMovimientos" cols={2} render={(props) =>
                                    <CompensacionMovimientos value={solicitud} />
                                } />
                            }
                            {solicitud?.tipoDescuentos &&
                                <Field field="descuentoMovimiento" cols={2} render={(props) =>
                                    <DescuentoMovimiento value={solicitud} />
                                } />
                            }

                            {solicitud?.tipoGasto && solicitud?.gastos?.map((gasto, index) => (

                                <React.Fragment key={index}>
                                    {gasto.comprobado === 0 &&
                                        <Field label="Monto a comprobar" field={"comprobado"} cols={2} rows={2} required={true}
                                               render={FormInputNumber} />
                                    }
                                    {gasto.comprobado !== 0 &&
                                        <div className="">
                                    <span>
                                        <span className="bold"> Comprobado: {numeral(gasto.comprobado).format('$0,0.00')}</span>
                                    </span>
                                        </div>
                                    }
                                    {gasto.comprobado === 0 &&
                                        <div className="form-footer">
                                            <Button label="Guardar" className="p-button-outlined" type="button"
                                                    onClick={() => onSave(false)} />
                                        </div>
                                    }

                                </React.Fragment>
                            ))}
                            <Field label="Nota" field={"nota"} disabled="true" cols={2} rows={4} required={true}
                                   render={FormTextArea} />

                            {solicitud?.tipoGasto && !soloLectura &&
                                <Field label="Evidencia Gasto" field={"gastoUrl"} required={true}
                                       render={FormClipboardImage} />
                            }
                        </Form>
                    </ReadOnlyProvider>
                </TabPanel>
                <TabPanel header={"Historial"}>
                    <ExGrid data={solicitud.historial ?? []}>
                        <ExColumn title={'Accion'} field={'accion'}></ExColumn>
                        <ExColumn title={'Usuario'} field={'auditUser'}></ExColumn>
                        <ExColumn title={'Fecha'} field={'fechaUtc'} cellRenderer={fechaUtc}></ExColumn>
                        <ExColumn title={"Comentarios"} field={'comentarios'}></ExColumn>
                    </ExGrid>
                </TabPanel>
            </TabView>
        </>
    )
}

export default DetalleMovimientos;

