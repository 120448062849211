import React, { useState } from "react";
import FieldCheckBox from "../../../../shared/controls/fieldCheckBox";
import NotificacionesIncidencias from "../notificacionesIncidencias";
import { Button } from "primereact/button";
import useApiViajeSeguimiento from "../../../../api/operaciones/useApiViajesSeguimiento";

const SeguimientoSegmento = ({ viaje, actualizar, index, segmentoEnCurso, onSave, soloLectura, segmentos }) => {
    const api = useApiViajeSeguimiento();
    const esSegmento = index != null;
    const esSegmentoEnCurso = segmentoEnCurso == null || viaje.id === segmentoEnCurso.id;
    const sePuedeTerminar = esSegmento && !segmentoEnCurso?.esUltimoSegmento && viaje.enRuta
        && (esSegmentoEnCurso || segmentoEnCurso.terminado);
    const [expandido, setExpandido] = useState(!!viaje.enCurso);
    const terminadoAnterior = index > 0 ? segmentos[index - 1].terminado : true;
    //Solo si existen segmentos se reciben index e indexEnCurso;
    const enCursoEditable = !viaje.porProgramar
        && !viaje.terminado
        && (index == null //Para un viaje sin segmentos no se manda el index
            || (index === 0 && segmentoEnCurso == null) //Es el primer segmento y no se ah iniciado ninguno
            || (index === segmentoEnCurso?.index) //Es el segmento en curso
            || (index - 1 === segmentoEnCurso?.index && (segmentoEnCurso.enRuta || segmentoEnCurso.terminado)) //Es el segmento siguiente a el que esta en curso
            || (terminadoAnterior && !segmentos[index].terminado ) // el segmento anterior ya finalizó y el segmento actual no
        )
        && (esSegmentoEnCurso || segmentoEnCurso.terminado || !viaje.enRuta);

    const accordionStyle = {
        display: 'grid',
        grid: '2em/1fr 4fr 5fr 3fr',
        alignItems: 'center',
        width: '99%',
        padding: '0.8rem 0'
    };

    const actualizarIncidencias = (incidencias) => {
        actualizar(incidencias, "incidencias");
    };

    const actualizarEnCurso = (enCurso) => {
        if (enCurso) {
            setExpandido(true);
        }
        actualizar(enCurso, "enCurso");
    };

    const terminar = async () => {
        await api.terminar(viaje.id, viaje);
        onSave()
    };

    return (
        <div className="shadow-sm border bg-body rounded mb-3">
            <div className="bg-light" style={accordionStyle}>
                {viaje.porProgramar ?
                    <div />
                    : <i className={`text-center cursor-pointer pi pi-chevron-${expandido ? 'down' : 'right'}`}
                        onClick={() => setExpandido(!expandido)} />
                }

                <div>
                    <div>{viaje.operador}</div>
                    <div>
                        Unidad: <b>{viaje.unidad}</b>&nbsp;
                        | Caja: <b>{viaje.caja != null ? viaje.caja : ' Vacío'}</b>
                    </div>
                </div>

                <div className="d-flex align-items-center">
                    {viaje.rutas?.map((r, index) => {
                        return <React.Fragment key={r.id}>
                            <div>{r.planta}</div>
                            {index < viaje.rutas.length - 1 &&
                                <i className="pi pi-arrow-right px-1"
                                    style={{ fontSize: '0.6rem' }} />
                            }
                        </React.Fragment>
                    }
                    )}
                </div>

                <div>
                    {viaje.porProgramar ?
                        <div>Segmento Por Programar</div>
                        : <FieldCheckBox name="enCurso" label="En Curso" value={viaje.enCurso}
                            onChange={actualizarEnCurso}
                            colMd={12} disabled={!enCursoEditable} />
                    }
                </div>
            </div>

            {expandido &&
                <NotificacionesIncidencias notificaciones={viaje.incidencias} actualizar={actualizarIncidencias}
                    disabled={!viaje.enCurso || viaje.terminado} soloLectura={soloLectura} />
            }

            {(sePuedeTerminar && !soloLectura) &&
                <div className="text-right mb-1 mr-1">
                    <Button className="btn-primary" label='Terminar' type="button" onClick={terminar} />
                </div>
            }
        </div>
    );
};


export default SeguimientoSegmento;
