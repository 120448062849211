/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import PDFViewer from 'mgr-pdf-viewer-react';
import {useAuth} from "../../../Seguridad/AuthProvider";

const CotizacionPdfViewer = ({pdfUrl}) => {
    const [mostrar, setMostrar] = useState(false);
    const [url,setUrl] = useState(pdfUrl);
    const {user:usuario} = useAuth();


    useEffect(() => {
        setMostrar(true);
        actualizarUrl();
    }, []);


    useEffect(() => {
        const timer = setTimeout(actualizarUrl,500);
        return () => clearTimeout(timer);
    }, [pdfUrl]);

    function actualizarUrl(){
        setUrl(pdfUrl);
    }

    return (
        <> {mostrar &&
        <PDFViewer document={{
            url: url + "/" + usuario.nombre
        }}/>
        }
        </>
    )
};

export default CotizacionPdfViewer;
