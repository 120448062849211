import React from "react";
import * as numeral from "numeral";

export const GastoRenderer = ({data}) => {
    return (
        <>
            <div className="cell-caja">
                <span className="tipo">
                    {data?.tipoSolicitud}
                </span>
                {data?.concepto != null &&
                    <span>
                        {data.concepto}
                    </span>
                }
            </div>
        </>
    )
};


export const MontoRenderer = ({data}) => {
    return (
        <>
            <div className="cell-caja">
                <span>
                    {numeral(data?.monto).format('$ 0,0.00')}
                </span>
                {data?.metodoPago != null &&
                    <span className="metodo-pago">
                    {data.metodoPago}
                </span>
                }
            </div>
        </>
    )
};




