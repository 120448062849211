/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import ReadOnlyProvider from "../../shared/forms/ReadOnlyProvider";
import DateFormatter from "../../shared/ag-grid-helper/dateFormatter";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import useApiAsignacionEquipos from "../../api/administracion/useAsignacionEquipos";
import produce from "immer";
import constantes from "../constantes";
import useSeguridad from "../../Seguridad/useSeguridad";

const Estatus = ({ historial, soloLectura,onChange,onSave}) => {
 const seguridad = useSeguridad();
 const apiAsignacion = useApiAsignacionEquipos();
 const permisoHistorialEmpleados = seguridad.tieneAcceso(constantes.opciones.HistorialEmpleados);

 async function eliminarDeLista(lista, index, namelist,id) {
    const nuevo = produce(lista, draft => {
        draft.splice(index, 1);
    }
    );

    onChange(nuevo, namelist);
    await apiAsignacion.desasignar(id);
    onSave();
}


    return (
        <ReadOnlyProvider defaultReadOnly={soloLectura }>
            <table className="table table-borderless">
                <thead className="thead-light">
                    <tr>
                        <th scope="col" style={{ width: '15%' }}>Empleado</th>                       
                        <th scope="col" style={{ width: '10%' }}>Departamento</th>                    
                        <th scope="col" style={{ width: '20%' }}>Jefe Inmediato</th>
                        <th scope="col" style={{ width: '10%' }}>Usuario</th>
                        <th scope="col" style={{ width: '20%' }}>Fecha</th>
                        <th scope="col" style={{ width: '20%' }}>Desasignar</th>
                    </tr>
                </thead>
                <tbody>
                    {historial?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="input">
                                    {item.empleado}
                                </td>
                                <td className="input">
                                    {item.departamento.nombre}
                                </td>
                                <td className="input">
                                    {item.jefeInmediato}
                                </td>
                                <td className="input">
                                    {item.auditUser}
                                </td>
                                <td className="input">
                                    <DateFormatter value={item.auditDate} />
                                </td>
                                <td className="input">
                                    {(!soloLectura || permisoHistorialEmpleados) && item.fechaDesasignada ==null &&
                                        <ButtonIcon title={'Eliminar'}
                                            onClick={() => eliminarDeLista(historial, index, "historial",item.id)}
                                            iconName={'RiDeleteBinLine'} />
                                    }
                                     {item.fechaDesasignada !=null  &&
                                        <>
                                            <DateFormatter value={item.fechaDesasignada} />
                                        </>
                                    }
                                </td>
                            </tr>
                        )
                    })}
                </tbody>                
            </table>
        </ReadOnlyProvider>
    )
};

export default Estatus;