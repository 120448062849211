import React from "react";
import {utcToString} from "../../../../shared/format";

const TooltipSignal = ({viaje}) => {
    return (
        <div className={'seg-tooltip-signal'}>
                <span className={'t-label'}>Estatus Unidad:</span>
                <span className={'t-value'}>{viaje.gpsUnidad?.actualizando ? 'Ok':'Sin Actualizar'}</span>
                <span className={'t-label'}>Actualizó hace:</span>
                <span className={'t-value'}>{viaje.gpsUnidad?.tiempoSinActualizar} ({utcToString(viaje.gpsUnidad?.fechaActualizacion)})</span>

                <span className={'t-label'} style={{marginTop:'10px'}}>Estatus Caja:</span>
                <span className={'t-value'} style={{marginTop:'10px'}}>{viaje.gpsCaja?.actualizando ? 'Ok':'Sin Actualizar'}</span>
                <span className={'t-label'}>Actualizó hace:</span>
                <span className={'t-value'}>{viaje.gpsCaja?.tiempoSinActualizar} ({utcToString(viaje.gpsCaja?.fechaActualizacion)})</span>
        </div>
    )
}
export default TooltipSignal;