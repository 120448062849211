import {Button} from "primereact/button";
import React, {useEffect, useState} from "react";
import useApiViaje from "../../../api/operaciones/useApiViajes";
import {Dialog} from "primereact/dialog";
import FieldCalendar from "../../../shared/controls/fieldCalendar";
import FieldRadioButtonList from "../../../shared/controls/fieldRadioButtonList";

const radioOptions = [{id: true, nombre: "Copiar para mañana"}, {id: false, nombre: "Copiar para Siguiente Semana"}];

const ClonarViaje = ({viajes, visible, hide, onSuccess}) => {
    const api = useApiViaje();
    const [entity, setEntity] = useState({});

    useEffect(() => {
        setEntity({})
    }, [visible]);

    const actualizar = (value, propertyName) => {
        setEntity({[propertyName]: value})
    };

    const clonar = async () => {
        const invalid = !entity.fechaCarga && entity.esCopiarParaManiana == null;
        setEntity({...entity, invalid});

        if (!invalid) {
            const ids = viajes.filter(e => e.seleccionado).map(e => e.id).join(",");
            await api.clonar(ids, entity);
            onSuccess();
        }
    };

    const renderFooterModal = () => {
        return (
            <div>
                <Button label="Cancelar" onClick={hide} className="p-button-outlined"/>
                <Button label="Agregar Viajes" onClick={clonar} autoFocus/>
            </div>
        );
    };

    return (
        <Dialog header="Clonar Viaje" visible={visible} style={{width: '40vw'}}
                footer={renderFooterModal()} onHide={hide}>
            <div className="form-row">
                <FieldCalendar name="fechaCarga" label="Fecha Carga" value={entity?.fechaCarga} colMd={4}
                               showButtonBar={false} error={entity?.invalid} onChange={actualizar}
                               appendTo={document.body} className="xs" minDate={new Date()}/>

                <FieldRadioButtonList name="esCopiarParaManiana" className="col-8 mt-2" options={radioOptions}
                                      value={entity?.esCopiarParaManiana} onChange={actualizar}/>
            </div>
        </Dialog>
    );
};


export default ClonarViaje;
