import React from "react";
import FieldTextBox from "../../shared/controls/fieldTextBox";
const FiltroAgenda= ({filtro, onChange}) => {
    return (
        <>         
            
            <FieldTextBox name="nombre" label="Nombre" colMd={2}
                          value={filtro.nombre} onChange={onChange}/>
           
        </>
    )
};

export default FiltroAgenda;
