import React from "react";
import './mantenimiento.css';
import { TabPanel, TabView } from "primereact/tabview";
import SolicitudesServicios from "./solicitudservicio/solicitudesServicios";
import OrdenesTrabajo from "./ordentrabajo/ordenesTrabajo";
import useSessionState from "../../hooks/useSessionState";
import constantes from "../../catalogos/constantes";
import Almacen from "./almacen/almacen";
import useSeguridad from "../../Seguridad/useSeguridad";

const Mantenimiento = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.mantenimiento);
    const [activeIndex, setActiveIndex] = useSessionState('tabMantenimiento', 0);

    return (
        <>
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e?.index)} className="main-tab-view">
                <TabPanel headerStyle={(seguridad.tieneAcceso(constantes.menus.solicitudServicio) || soloLectura) ? { display: "inline" } : { display: "none" }} header="Solicitud de Servicio">
                    <SolicitudesServicios soloLectura={soloLectura} />
                </TabPanel>

                <TabPanel headerStyle={seguridad.tieneAcceso(constantes.menus.ordenesTrabajo) ? { display: "inline" } : { display: "none" }} header="Ordenes de Trabajo">
                    <OrdenesTrabajo soloLectura={soloLectura} />
                </TabPanel>

                <TabPanel headerStyle={seguridad.tieneAcceso(constantes.menus.almacen) ? { display: "inline" } : { display: "none" }} header="Almacén">
                    <Almacen soloLectura={soloLectura} />
                </TabPanel>
            </TabView>
        </>
    )
};

export default Mantenimiento;