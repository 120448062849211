/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'

const ExHeader = ({headerRenderer, formDisabled, field, title, ...props}) => {
    return (
        <>
            {headerRenderer ?
                headerRenderer({field, formDisabled, title, ...props})
                :
                title
            }
        </>
    )
}

export default ExHeader;
