/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import numeral from "numeral";

const Cajas = ({cajas, onChange}) => {
    return (
        <div className="caja-tabs" >
            {cajas.map((caja, index) => (
                <div key={index} className={`caja-tab ${caja.activa ? 'activa' : '' }`} onClick={()=>{
                    onChange(
                        cajas.map((t,totalIndex)=>{return {...t, activa: totalIndex === index }}),
                        caja.id
                    )
                }}>
                    <span>{caja.nombre}</span>
                    <span className="monto">{numeral(caja.saldo).format('$0,0.00')}</span>
                    <span>{caja.referencia}</span>
                </div>
            ))}
        </div>
    )
}

export default Cajas;
