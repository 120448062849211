import * as yup from "yup";

const guardar = yup.object().shape({   
    fechaSolicitud: yup.string().required(),
    solicitante: yup.object({
        id: yup.number().required().min(1)
    }),
    unidad: yup.object({
        id: yup.number().required().min(1)
    }), 
    fallas: yup.array()
    .of(
        yup.object().shape({
            descripcion: yup.string().required(),
          
        })
    ),        
});

const solicitudServicioValidacionEsquema = {
    guardar
};

export default solicitudServicioValidacionEsquema;



