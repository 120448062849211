import moment from "moment/moment";
import FieldMultiSelect from "../../shared/controls/fieldMultiSelect";
import constantes from "../../catalogos/constantes";
import {estatusMap} from "./StatusButton";
import WeekPicker from "../../shared/controls/WeekPicker";
import React from "react";

const Filtros = ({filtro, onChange}) => {

    const maxDate = moment().startOf("days").add(1, 'days');

    const onChangeDays = (days) => {
        let newDate = moment(filtro.fecha).add(days, "days");
        if (newDate.isAfter(maxDate)) {
            newDate = moment(maxDate);
        }

        onChange(newDate.format("YYYY-MM-DD"), "fecha", true);
    };

    return (
        <>
            <div
                style={{height: "fitContent"}}
                className={`form-group d-flex flex-row col-lg-${2} col-md-${2 ?? "4"} col-sm-${"12"}`}
            >
                <span style={{minWidth: "18%", marginTop: "5px"}}>Unidad</span>

                <FieldMultiSelect
                    name="unidadIds"
                    source="unidadesParaEstatusDiarios"
                    styles={{height: "fit-content"}}
                    colMd={2}
                    colMob={3}
                    value={filtro.unidadIds}
                    onChange={onChange}
                    showFilter
                    valueAsId={true}
                />
            </div>

            <div
                style={{height: "fitContent"}}
                className={`form-group d-flex flex-row col-lg-${2} col-md-${2} col-sm-${"12"}`}
            >
                <span style={{minWidth: "30%", marginTop: "5px"}}>Tipo Unidad</span>

                <FieldMultiSelect
                    name="tipoUnidadIds"
                    source="tiposUnidad"
                    styles={{height: "fit-content"}}
                    colMd={2}
                    colMob={3}
                    value={filtro.tipoUnidadIds}
                    onChange={onChange}
                    valueAsId={true}
                />
            </div>
            <div
                style={{height: "fitContent"}}
                className={`form-group d-flex flex-row col-lg-${2} col-md-${2} col-sm-${"12"}`}
            >
                <span style={{minWidth: "30%", marginTop: "5px"}}>Coordinadores</span>
                <FieldMultiSelect
                    name="coordinadorIds"
                    source="empleados"
                    styles={{height: "fit-content"}}
                    colMd={2}
                    colMob={3}
                    value={filtro.coordinadorIds}
                    onChange={onChange}
                    valueAsId={true}
                    params={{puestoIds: constantes.puestosDeTrafico, todasLasEmpresas: true}}
                />
            </div>

            <div
                style={{height: "fitContent"}}
                className={`form-group d-flex flex-row col-lg-${2} col-md-${2} col-sm-${"12"}`}
            >
                <span style={{minWidth: "70px", marginTop: "5px"}}>Ubicación</span>
                <FieldMultiSelect
                    name="municipios"
                    source={'municipiosUnidades'}
                    styles={{height: "fit-content"}}
                    colMd={2}
                    showFilter={true}
                    colMob={3}
                    value={filtro.municipios}
                    onChange={onChange}
                    valueAsId={true}
                />
            </div>

            <div className={`form-group col-1`}></div>
            <div className={`g-diaria-leyendas`}>
                {Array.from(estatusMap.entries()).map(([id, value]) => (
                    <span className={value.clase} key={id} style={{backgroundColor: value.color}}>
                        {value.name}
                    </span>
                ))}
            </div>
            <div style={{position: "absolute", top: "15px", right: "200px", marginTop: "-3px"}}>
                <WeekPicker date={filtro.fecha} onMoveDays={onChangeDays} maxDate={maxDate} inverted={true}/>
            </div>
        </>
    );
};

export default Filtros;