import {SeparadorPuntoIcon} from "../../../icons/SeguimientoYMonitoreo/SeguimientoIcons";
import React from "react";

const UnidadCaja = ({viaje}) => {
    return (
        <div style={{width:'100%', display:"flex", justifyContent:"flex-start", gap:'10px', alignItems:'center' }}>
            <span className={'label'}>{viaje.unidad?.numeroEconomico }</span>
            {viaje.caja != null &&
                <SeparadorPuntoIcon/>
            }
            <span>{viaje.caja?.numeroEconomico}</span>
        </div>
    )
}
export default UnidadCaja;