
import React from "react";
import FieldInputMask from "../fieldInputMask";


const FieldInputPlacas = ({colMd, colSm, label, name, onChange, onBlur, value, keyFilter, rows, disabled,formatType,error, errors,required}) => {


    return (
        <>

        {label   &&
         <FieldInputMask maskVariable="******" name={name} label={label} colMd={colMd} required={required} errors={errors}
          value={ value }  onChange={onChange}/>
        }

         {!label  &&
          <FieldInputMask maskVariable="******" name={name} colMd={colMd} errors={errors}
           value={ value }  onChange={onChange}/>            
          }
      </>
    )
};


export default FieldInputPlacas;