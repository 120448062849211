/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import * as numeral from "numeral";

const Vacaciones = ({vacaciones}) => {
    return (
        <>
            <div className="form-row">
                <table className="table table-borderless">
                    <thead className="thead-light">
                    <tr>
                        <th className="center" style={{width: '30%'}}>FECHA</th>
                        <th className="center" style={{width: '20%'}}>DÍAS TOMADOS</th>
                        <th className="center" style={{width: '20%'}}>TRABAJADOS</th>
                        <th className="right" style={{width: '20%'}}>MONTO</th>
                    </tr>
                    </thead>
                    <tbody>
                    {vacaciones?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="center">{item.fecha}</td>
                                <td className="center"> {item.diasTomados}</td>
                                <td className="center">{item.trabajados ? 'SI' : 'NO'}</td>
                                <td className="right">{numeral(item.monto).format('$0,0.00')}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </>
    )
};


export default Vacaciones;
