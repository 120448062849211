/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import * as numeral from "numeral";
import moment from "moment";
import useApiDispersion from "../../api/administracion/useApiDispersion";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import alerts from "../../shared/alerts";

const DispersionDetalle = ({ onSave, dispersionActual, esOperador, onClose, soloLectura }) => {
    const [dispersion, setDispersion] = useState({});
    const api = useApiDispersion();

    useEffect(() => {
        setDispersion(dispersionActual);
    }, [dispersionActual]);

  
    async function rollbackLiquidacion(liquidacionId) {
        if (await alerts.preguntarSiNoAdvertencia('¿Desea cancelar la liquidación?')) {
            await api.cancelarliquidacion(dispersionActual.id, liquidacionId);
            var nuevaDispersion = {
                ...dispersion,
                liquidaciones: dispersion.liquidaciones.filter(e => e.id !== liquidacionId)
            };
            setDispersion(nuevaDispersion);
            if (nuevaDispersion.liquidaciones.length === 0) {
                onSave();
            }
        }
    }

    return (
        <>
            <div className="form-container full-height">
                <div className="adm-liquidacion-detalle">
                    <h5>Nómina {moment(dispersion.fecha).format('DD/MM/YYYY')}</h5>
                    <table className="table table-borderless">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col" style={{ width: '40%' }}>EMPLEADO</th>
                                <th scope="col" style={{ width: '40%' }}>NOMINA</th>
                                <th scope="col" style={{ width: '20%' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {dispersion?.liquidaciones?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="input">
                                            {item.empleado.nombre}
                                        </td>
                                        <td className="input">
                                            {numeral(item.total).format('$ 0,0.00')}
                                        </td>
                                        {(!dispersion.cerrada && !soloLectura) &&
                                            <td className="input">
                                                <ButtonIcon title={'Eliminar'}
                                                    onClick={() => rollbackLiquidacion(item.id)}
                                                    iconName={'RiDeleteBinLine'} />
                                            </td>}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="form-row adm-resumen-viajes">
                        <div className="totales">
                            <table>
                                <tbody>
                                    {esOperador && <tr>
                                        <td>Comisión</td>
                                        <td>{numeral(dispersion.totalComision).format('$ 0,0.00')}</td>
                                    </tr>}
                                    <tr>
                                        <td>Préstamos</td>
                                        <td>{numeral(dispersion.totalPrestamos).format('$ 0,0.00')}</td>
                                    </tr>
                                    <tr>
                                        <td>Caja Ahorro</td>
                                        <td>{numeral(dispersion.totalCajaAhorro).format('$ 0,0.00')}</td>
                                    </tr>
                                    
                                    {esOperador && <tr>
                                        <td>Anticipos</td>
                                        <td>{numeral(dispersion.totalAnticipos).format('$ 0,0.00')}</td>
                                    </tr>}
                                    {esOperador && <tr>
                                        <td>Ajuste</td>
                                        <td>{numeral(dispersion.totalAjuste).format('$ 0,0.00')}</td>
                                    </tr>}
                                    <tr>
                                        <td>Bonos</td>
                                        <td>{numeral(dispersion.totalBonos).format('$ 0,0.00')}</td>
                                    </tr>
                                    <tr>
                                        <td>Vacaciones</td>
                                        <td>{numeral(dispersion.totalVacaciones).format('$ 0,0.00')}</td>
                                    </tr>
                                    {esOperador && <tr>
                                        <td>Pension Alimenticia</td>
                                        <td>{numeral(dispersion.totalPensionAlimenticia).format('$ 0,0.00')}</td>
                                    </tr>}
                                    <tr>
                                        <td>TOTAL</td>
                                        <td>{numeral(dispersion.total).format('$ 0,0.00')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-footer">
                <Button label="Cancelar" type="button" className="p-button-outlined" onClick={onClose} />               
            </div>
        </>
    )
};


export default DispersionDetalle;
