import React, {useState} from "react";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import FieldTextArea from "../../../shared/controls/fieldTextArea";
import useApiEmpleados from "../../../api/catalogos/useApiEmpleados";
import FieldCalendar from "../../../shared/controls/fieldCalendar";
import FieldInputNumber from "../../../shared/controls/fieldInputNumber";
import moment from "moment";

const Abonoahorro = ({empleado, onRetiro}) => {
    const [visibleModal, setVisibleModal] = useState(false);
    const [comentarios, setComentarios] = useState('');
    const [abono, setAbono] = useState(0)
    const apiEmpleado = useApiEmpleados();

    const abonocajaahorro = async () => {               
        await apiEmpleado.abonoCajaahorro(empleado.id, {
            comentarios,
            abono,
            fecha: moment().startOf('day').toDate(),
            fechaInicioAhorro: empleado.nomina?.fechaInicio
        });
        setVisibleModal(false);
        onRetiro();              
        
    };
    
    const renderFooter = () => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => setVisibleModal(false)} className="p-button-text"/>
                <Button label="Confirmar" icon="pi pi-check" onClick={abonocajaahorro} autoFocus/>
            </div>
        );
    };

    return (
        <>
            <Dialog header="¿Desea abonar al prestámo de caja de ahorro al empleado?" visible={visibleModal} style={{width: '30vw'}}
                    footer={renderFooter('displayBasic')} onHide={() => setVisibleModal(false)}>
                <div className="form-row" style={{overflow:'hidden'}}>
                    <FieldCalendar name="fechaInicio" label="Fecha inicio de ahorro" colMd={12} className="small"
                                   disabled={true}
                                   value={empleado.nomina?.fechaInicio}
                                  />
                                   
                     <FieldInputNumber name="prestamoCajaAhorro" label="Monto" value={empleado.nomina?.prestamoCajaAhorro} colMd={6} fractionDigits={2} disabled={true} 
                                    />
                     <FieldInputNumber name="saldoPrestamoahorro" label="Saldo Préstamo Ahorro"  value={empleado.nomina?.saldoPrestamoahorro}  colMd={6} fractionDigits={2}  disabled={true} 
                                    />
                    <FieldInputNumber name="abono" label="Abono" values ={abono} onChange={setAbono} colMd={12} fractionDigits={2}   
                        />
                        
                    <FieldTextArea name="comentarios" label="Comentarios" colMd={12} rows={6}
                                   value={comentarios} onChange={setComentarios}/>

                </div>
            </Dialog>

            <Button label="Abono"  type="button"
                    onClick={() => setVisibleModal(true)}/>
        </>
    )
};

export default Abonoahorro;
