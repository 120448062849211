import moment from "moment";
import ButtonIcon from "../controls/ButtonIcon";
import React from "react";

export const fechaUtc=({value})=>{
    const fecha = moment(value);
    return fecha.format('YY-MMM-DD HH:mm');
}

export const fechaRenderer=({value})=>{
    if (value == null)
        return ''

    const fecha = moment(value);
    return fecha.format('YY-MMM-DD');
}

export const ExportRenderer=({context})=>{
    return (
        <ButtonIcon title={'Descargar'} onClick={()=>context.onExport()} iconName={'BiDownload'}/>
    )
}

export const ViewRenderer=({data, context})=>{
    return (
        <button className="btn btn-link btn-sm" title={'Ver'}
                onClick={() => context.viewDetail(data.id)}>
            Ver
        </button>
    )
}