import React from "react";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import FieldDropDown from "../../shared/controls/fieldDropDown";
import FieldMultiSelect from "../../shared/controls/fieldMultiSelect";

const FiltroEmpleados = ({filtro, onChange}) => {
    return (
        <>         
            <FieldTextBox name="numeroEmpleado" label="Id/Num de Gafete" colMd={1} 
                              value={filtro.numeroEmpleado} onChange={onChange}/>
            <FieldTextBox name="nombre" label="Nombre" colMd={2}
                          value={filtro.nombre} onChange={onChange}/>
            <FieldDropDown name="departamento" source="departamentos" label="Departamento" colMd={2} filter={true}
                           value={filtro.departamento} onChange={onChange}/>
            <FieldDropDown name="puesto" source="puestos" label="Puesto" colMd={2}  filter={true}
                           value={filtro.puesto} onChange={onChange}/>
            <FieldTextBox name="jefe" label="Jefe" colMd={2}
                          value={filtro.jefe} onChange={onChange}/>
            <FieldMultiSelect name="estatus" source="estatusEmpleados" label="Estatus" colMd={2}
                              value={filtro.estatus} onChange={onChange}/>
        </>
    )
};

export default FiltroEmpleados;
