import useServiceFactory from "../serviceFactory";

const apiUrl = `/servicio/banxico/tipocambio`;

const useApiBanxico = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}/filtrar`, { params, showLoading: true }),
        tipocambio: async () => service.get(`${apiUrl}/mxn/1`, { showLoading: false }),
        excel: async (params) => service.get(`${apiUrl}/excel`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `TipoDeCambio.xlsx`
            }),
        guardarTipoCambio: async () => service.post(`${apiUrl}/guardar`),
    }
};

export default useApiBanxico;
