/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import {useState} from "react";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import DocumentacionFisica from "../convenios/DocumentacionFisica";
import ParadaDetalle from "./paradaDetalle";
import useApiDirecciones from "../../../api/catalogos/useApiDirecciones";

const Parada = ({
                    parada,
                    esSeparador,
                    textoHeader,
                    textoBody,
                    labelClassName,
                    entidadId,
                    api,
                    onChange,
                    readOnly,
                    canBeDeleted,
                    onDelete,
                    clienteId,
                    onSave,
                    subClienteId,
                    convenioId
                }) => {
    const [verDocumentos, setVerDocumentos] = useState(false);
    const apiDir = useApiDirecciones();
    const [validoParaTimbrar, setValidoParaTimbrar] = useState(true);

    useEffect(() => {
        async function validar() {
            const params = {
                codigo: parada?.direccion?.codigoPostal ?? "X",
                estadoId: parada?.direccion?.estado?.id ?? 0,
                municipioId: parada?.direccion?.municipio?.id ?? 0,
                localidadId: parada?.direccion?.localidad?.id ?? 0
            };

            const cp = await apiDir.validarDireccion(params);
            setValidoParaTimbrar(cp);
        };

        validar().then();
    }, [parada]);

    return (
        <>
            {esSeparador !== true &&
                <div>
                    <div className={`com-parada`}>
                        <div className={`com-parada-header ${parada.direccion == null ? 'error' : ''}`}>
                            <span>
                                {parada.nombre}
                            </span>
                            <ParadaDetalle value={parada} onSave={(e) => onChange(e)} entidadId={entidadId} api={api}
                                           readOnly={readOnly} canBeDeleted={canBeDeleted} onDelete={onDelete}/>
                        </div>
                        {validoParaTimbrar ?
                            <span className="dot2" title={"Direccion Valida para timbrar."}
                                  style={{backgroundColor: '#00d661'}}/>
                            :
                            <span className="dot2" title={"Estado, Municipio, Localidad o Codigo Postal no valido para timbrar."}
                                  style={{backgroundColor: '#ff4b4b'}}/>
                        }
                        <div className="com-parada-direccion">
                            <span>
                                {parada.nombreDestino}
                            </span>
                            <span>
                                {parada.direccion?.calle} {parada.direccion?.noExterior} {parada.direccion?.noInterior}
                            </span>
                            <span>
                                {parada.direccion?.colonia}
                            </span>
                            <span>
                                {parada.direccion?.municipio?.nombre}, {parada.direccion?.estado?.nombre}
                            </span>
                            <span>
                                {parada.direccion?.codigoPostal}
                            </span>
                        </div>
                        <div className="com-separador"/>
                    </div>
                    <div className={`com-parada`}>
                        {((parada?.id ?? 0) > 0 && (parada?.esStop || parada?.esDestino)) &&
                            <>
                                <div className={`com-parada-header`}>
                                    <span>Documentos</span>
                                    <ButtonIcon iconName='FaRegEdit' onClick={() => setVerDocumentos(true)}/>
                                </div>
                                <div className="com-parada-direccion">
                                    {parada?.documentos?.map((item, index) => {
                                        return (
                                            <span
                                                key={index}><b>{item.nombre}</b> {item?.documentacion?.descripcion}</span>
                                        )
                                    })}
                                </div>
                                <div className="com-separador"/>
                                <DocumentacionFisica visible={verDocumentos} paradaId={parada?.id ?? 0}
                                                     clienteId={clienteId ?? 0} hide={() => setVerDocumentos(false)}
                                                     onSave={onSave} subClienteId={subClienteId ?? 0} convenioId={convenioId}/>
                            </>
                        }
                    </div>
                </div>
            }
            {esSeparador === true &&
                <div className="com-parada">
                    <div className="com-parada-header">
                        <span className={labelClassName}>{textoHeader}</span>
                    </div>
                    <div className="com-parada-direccion">
                        <span className={labelClassName}>{textoBody}</span>
                    </div>
                    <div className="com-separador"/>
                </div>
            }
        </>
    )
};

export default Parada;
