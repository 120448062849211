/* eslint-disable react-hooks/exhaustive-deps */
import useInputFile from "./useInputFile";
import {useEmpresa} from "../../layout/EmpresaProvider";
import {useReadOnly} from "../forms/ReadOnlyProvider";
import React, {useEffect, useState} from "react";
import IconLibrary from "../iconLibrary";
import PdfViewer from "../componentes/pdfViewer";
import ButtonIcon from "./ButtonIcon";
import useApi from "../../api/useApi";
import {SignalRContext} from "../../App";
import {v4 as uuidv4} from 'uuid';
import {ProgressSpinner} from "primereact/progressspinner";
import ImageViewer from "../componentes/imageViewer";
import constantes from "../../catalogos/constantes";
import swal from "sweetalert2";

const FieldScanner = ({
                          name,
                          value,
                          onChange,
                          error,
                          errors,
                          className,
                          disabled,
                          scanApiUrl,
                          uploadFileUrl,
                          params,
                          onEliminar,
                          showApprove,
                          onApprove,
                          onReject,
                          escanerId,
                          empresa,
                          visibleEscanear,
                          onReview,
                          revisar = false
                      }) => {
    params = params ?? {};
    const {
        file,
        uniqueName,
        vistaPrevia,
        setVistaPrevia,
        eliminar,
        download,
        handleUpload,
        updateFile
    } = useInputFile(onUpload, name);


    const [escaneoUuid, setEscaneoUuid] = useState(null)
    const [loading, setLoading] = useState(false)

    const api = useApi();

    const {empresaId} = useEmpresa();

    const {readOnly} = useReadOnly() ?? {};
    disabled = disabled ?? readOnly;


    useEffect(() => {
        updateFile(value);
    }, [value]);

    const scan = async () => {
        const uuid = uuidv4();
        setEscaneoUuid(uuid);
        setLoading(true);
        const result = await api.post(scanApiUrl, {...params, escaneoUuid: uuid, escanerId}, {
            showLoading: false,
            showSuccess: false,
            ignoreError: true
        })
        if (result.error === true) {
            setLoading(false);
            await swal.fire({
                titleText: 'Atención.',
                text: 'No se ha podido escanear el documento, favor de revisar el escaner eh intentarlo de nuevo',
                type: 'warning',
            });
        }
    }

    const onDelete = async () => {
        if (await eliminar() && onEliminar != null)
            onEliminar();
        if (onChange != null)
            onChange({isEmpty: true}, name)
    }

    SignalRContext.useSignalREffect(
        "escaneoTerminado", // Your Event Key
        (archivoDto) => {
            if (archivoDto.escaneoUuid === escaneoUuid) {
                updateFile(archivoDto.archivo);
                setLoading(false)
                onChange(archivoDto.archivo, name)
            }
        }
    );

    async function onUpload(file, name) {
        if (file.isEmpty)
            return;

        setLoading(true);
        const formData = new FormData();
        formData.append("file", file.file);
        const archivo = await api.post(uploadFileUrl, formData, {showLoading: false});
        setLoading(false);
        onChange(archivo, name)
    }


    return (
        <>
            {loading ?
                <div className={"image-upload"}>
                    <ProgressSpinner style={{width: '25px', height: '25px'}} strokeWidth="8"
                                     fill="var(--surface-ground)" animationDuration=".5s"/>
                    <ButtonIcon title={"Cancelar"} iconName={"FcCancel"} onClick={() => setLoading(false)}/>
                </div> :
                <>
                    <div className={`image-upload ${className} ${(error || errors?.[name]) ? 'error' : ''}`}>
                        {file.isEmpty && !disabled &&
                            <>
                                {visibleEscanear &&
                                    <ButtonIcon iconName={'MdOutlineScanner'} onClick={scan}></ButtonIcon>
                                }
                                <label htmlFor={uniqueName}>
                                    <IconLibrary iconName="BsSearch"/>
                                </label>
                                <input type="file" id={uniqueName} onChange={handleUpload}
                                       accept={"application/pdf,image/*"}/>

                            </>
                        }
                        {!file.isEmpty &&
                            <>
                                {(file.extension === 'pdf' || file.extension === 'png' || file.extension === 'jpg') &&
                                    <label className="download" onClick={() => setVistaPrevia(true)}>
                                        <IconLibrary iconName="GrFormView"/>
                                    </label>
                                }
                                <label className="download" onClick={download}>
                                    <IconLibrary iconName="MdAttachFile"/>
                                </label>

                                {!disabled &&
                                    <label onClick={onDelete}>
                                        <IconLibrary iconName="RiDeleteBinLine"/>
                                    </label>
                                }
                            </>
                        }
                    </div>
                    {/*Modal con la vista previa del documento pdf*/}
                    {(!file.isEmpty && file.extension === 'pdf') &&
                        <PdfViewer header="Vista previa del documento"
                                   urlApi={`archivos/vistaPrevia/${empresa ?? empresaId}?url=${file.url}`}
                                   descargar={download} visible={vistaPrevia}
                                   hide={() => setVistaPrevia(false)}
                                   showApprove={showApprove}
                                   onReject={onReject}
                                   onApprove={onApprove}
                                   onReview={onReview}
                                   revisar={revisar}/>
                    }

                    {(!file.isEmpty && constantes.extensionImagenes.includes(file.extension.toLowerCase())) &&
                        <ImageViewer header="Vista previa del documento"
                                     name="foto" value={file}
                                     onChange={null} hide={() => setVistaPrevia(false)}
                                     visible={vistaPrevia}
                                     urlApi={`archivos/vistaPrevia/${empresa ?? empresaId}?url=${file.url}`}
                                     showApprove={showApprove}
                                     onReject={onReject}
                                     onApprove={onApprove}
                                     empresa={empresa}/>
                    }
                </>
            }
        </>
    )
};


export default FieldScanner;
