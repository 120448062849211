import { Link } from "react-router-dom";
import React from "react";
import { SimpleImg } from "react-simple-img";
import { Accordion, AccordionTab } from "primereact/accordion";
import { ScrollPanel } from 'primereact/scrollpanel';

const alto = window.innerHeight - 100;

export const SubMenu = ({ menus, onSeleccionarMenu }) => {
    return (
        <ScrollPanel style={{ width: '100%', height: alto }}>
            {menus.map((menu, i) => (
                <React.Fragment key={i} >
                    {menu?.subMenus?.length === 0 ?
                        <Link key={i} to={menu.ruta} onClick={() => onSeleccionarMenu()}>
                            <div className="submenu-item">
                                <SimpleImg src={`../../../img/${menu.icono}`} height={25} width={25} />
                                <span className="nombre">{menu.nombre}</span>
                            </div>
                        </Link>
                        :
                        <Accordion multiple activeIndex={[0]}>
                            <AccordionTab key={i} header={
                                <>
                                    <span>{menu.nombre}</span>
                                </>
                            } headerClassName="accordion-title">
                                <>
                                    {menu?.subMenus?.map((item, j) => (
                                        <Link key={j} to={item.ruta} onClick={() => onSeleccionarMenu()}>
                                            <div className="submenu-item">
                                                <SimpleImg src={`../../../img/${item.icono}`} height={25} width={25} />
                                                <span className="nombre">{item.nombre}</span>
                                            </div>
                                        </Link>
                                    ))}
                                </>
                            </AccordionTab>
                        </Accordion>
                    }
                </React.Fragment>
            ))}
        </ScrollPanel>
    );
};