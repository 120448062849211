/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef, useState} from "react";

const indicadoresContext = React.createContext(undefined)

const useIndicadoresProvider = () => {
    const overlayClickRef = useRef(null);
    const [tipo, setTipo] = useState()
    const [viajeActual, setViajeActual] = useState()
    const [viajeRutaId, setViajeRutaId] = useState()

    const overlayOnClick = (event, type, viaje)=>{
        overlayClickRef.current.toggle(event)
        setTipo(type)
        setViajeActual(viaje)
        event.stopPropagation()

    }

    return {
        tipo,
        overlayClickRef,
        overlayOnClick,
        viajeActual,
        viajeRutaId,
        setViajeRutaId
    }
}

export const useIndicadores = () => {
    return React.useContext(indicadoresContext)
}

const IndicadoresProvider = ({children}) => {
    const value = useIndicadoresProvider()
    return (
        <indicadoresContext.Provider value={value}>
            {children}
        </indicadoresContext.Provider>
    )
}

export default IndicadoresProvider