/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import Filters from "../../shared/forms/FormFilter/Filters";
import Field from "../../shared/forms/Field";
import Buttons from "../../shared/forms/FormFilter/Buttons";
import FormMultiSelect from "../../shared/forms/FormFields/FormMultiSelect";
import FormFilter from "../../shared/forms/FormFilter/FormFilter";
import FormTextBox from "../../shared/forms/FormFields/FormTextBox";
import FormCalendarRange from "../../shared/forms/FormFields/FormCalendarRange";
import moment from "moment";
import useForm from "../../shared/forms/useForm";
import useApiOrderToCash from "../../api/operaciones/useApiOrderToCash";
import "./OrderToCash.css"
import ButtonIcon from "../../shared/controls/ButtonIcon";
import constantes from "../constantes";
import FieldCheckBox from '../../shared/controls/fieldCheckBox';
import DateFormatter from '../../shared/ag-grid-helper/dateFormatter';
import * as numeral from "numeral";
import {Button} from 'primereact/button';
import {pendientes, pendientesPorSource} from "./OrderToCashConstants";
import SeleccionarViaje from "./SeleccionarViaje";
import LigarSaas from "./LigarSaas";
import CancelarFecha from "./CancelarFecha";
import {AgDateFormatter} from "../../shared/ag-grid-helper/GridFormatters";
import OrderToCashHistorial from "./OrderToCashHistorial";
import Ruta from "./Ruta";
import useSeguridad from '../../Seguridad/useSeguridad';
import {default as constantesCatalogos} from '../../catalogos/constantes';
import IconLibrary from "../../shared/iconLibrary";
import Documentos from "./Documentos";
import {useLoadingDispatch} from "../../layout/show-loading-context";

const estatus = constantes.estatusViajes;

const filtroInicial = {
    fechas: [moment().startOf('day').add(-7, 'day')._d, moment().startOf('day')._d],
    estatusIds: [estatus.terminado]
};

const filtroPendientes = pendientesPorSource.filter(e => e.mostrarEnFiltro);

let orden = 0;

const OrderToCash = () => {
    const dispatch = useLoadingDispatch();
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantesCatalogos.menus.orderToCash);
    const api = useApiOrderToCash();
    const [datos, setDatos] = useState([]);
    const [viajesSeleccionados, setViajesSeleccionados] = useState([]);
    const [datosRegistrar, setDatosRegistrar] = useState(null);
    const [viajePorCancelar, setVajePorCancelar] = useState(null);
    const {values: filter, onChange: onFilterChange, onClear} = useForm(filtroInicial);
    const [todos, setTodos] = useState({visible: false, checked: false});
    const [viajeHistorialId, setViajeHistorialId] = useState(null);
    const [viajeRutaId, setViajeRutaId] = useState(null);
    const [viajeId, setViajeId] = useState(null)
    const [datosSaas, setLigarSaas] = useState(null);
    useEffect(() => {
        filtrar(filtroInicial).then();
    }, [])

    const filtrar = async (filtro) => {
        let result = await api.filtrar(filtro);

        if (result.length >= 500){
            dispatch({type: 'toast',
                message:'Mostrando los primeros 500 resultados. Actualizar los filtros para obtener resultados más específicos, o exportar a Excel', severity:'info',
                life: 7000
            });
        }

        setDatos(result);
        //agrupa los viajes por pendiente para saber si existen mas de 1 tipo de pendiente
        const pendientes = result.reduce((set, {pendiente}) => {
            set.add(pendiente);
            return set;
        }, new Set());

        setTodos({visible: pendientes.size === 1});
    }

    const handleFilter = async (filtro) => {
        await filtrar(filtro);
    }

    const onExport = async () => {
        await api.excel(filter);
    }

    const onExportCancelados = async () => {
        await api.excelCancelados(filter);
    }
    const seleccionar = (item) => {
        const seleccionado = !item.seleccionado;
        let pendiente = seleccionado ? item.pendiente : datos.find(v => v.seleccionado && v.viajeId !== item.viajeId)?.pendiente;
        let viajes = datos.map(e => {
            return {
                ...e,
                desabilitado: pendiente != null && e.pendiente !== pendiente,
                seleccionado: item.viajeId === e.viajeId ? seleccionado : e.seleccionado,
                orden: item.viajeId === e.viajeId ? orden++ : e.orden,
            }
        });
        setDatos(viajes);

    };

    const registrar = (viaje, pendiente) => {
        setViajesSeleccionados(viaje != null ? [viaje] : datos.filter(e => e.seleccionado));
        setDatosRegistrar({pendiente: pendiente});
    }

    const onViajesChanged = () => {
        setVajePorCancelar(null);
        setDatosRegistrar(null);
        setLigarSaas(null);
        filtrar(filter).then();
    }

    const seleccionarTodos = (checked) => {
        setTodos({...todos, checked});
        setDatos(datos.map(e => {
            return {...e, seleccionado: checked}
        }));
    }

    const mostrarDocumentos = (viaje)=>{
        setViajeId(viaje.viajeId);
    }
    const ligarFacturaSaas = (viaje, pendiente) => {

       setViajesSeleccionados(viaje != null ? [viaje] : datos.filter(e => e.seleccionado));
       setLigarSaas({pendiente: pendiente});
    }

    return (
        <>
            <div className="main-screen">
                <FormFilter columns={11} filter={filter} onChange={onFilterChange} onClear={onClear}
                            onFilter={handleFilter}>
                    <Filters>
                        <Field label={"Cliente"} field="clienteIds" source={"clientes"} render={FormMultiSelect}
                               cols={2}/>
                        <Field label={"Fechas"} field="fechas" render={FormCalendarRange} cols={2}/>
                        <Field label={"Carta Porte"} field="cartaPorte" render={FormTextBox}/>
                        <Field label={"Id Viaje"} field="idViaje" render={FormTextBox}/>
                        <Field label={"Estatus Viaje"} field="estatusIds" source={"estatusViaje"} cols={2}
                               render={FormMultiSelect}/>
                        <Field label={"Pendiente por"} field="pendientes" options={filtroPendientes} cols={1}
                               render={FormMultiSelect}/>
                        <Field label={"Lear"} field="esLear" cols={2}
                               render={FieldCheckBox}/>
                    </Filters>
                    <Buttons filter={true}
                             clear={true}
                    >
                        <ButtonIcon title={'Descargar'} onClick={onExport} iconName={'BiDownload'}/>
                        <ButtonIcon title={'Descargar Cancelados'} onClick={onExportCancelados} iconName={'BiDownload'}/>
                        {!soloLectura &&
                            <ButtonIcon title={'Registrar facturas'} onClick={() => registrar()}
                                        iconName={'BiReceipt'}/>
                        }
                    </Buttons>
                </FormFilter>
                <div className="divider"/>
                <div className='table-custom'>
                    <table className='table table-sm table-striped table-fixed' style={{height: '98%'}}>
                        <thead>
                        <tr>
                            <th style={{width: '3%'}}>
                                {todos.visible &&
                                    <FieldCheckBox name="seleccionado" value={todos.checked}
                                                   onChange={() => seleccionarTodos(!todos.checked)}/>
                                }
                            </th>
                            <th style={{width: '4%'}}>Fecha</th>
                            <th style={{width: '4%'}}>Carta Porte</th>
                            <th style={{width: '4%'}}>ID Viaje</th>
                            <th style={{width: '10%'}}>Cliente</th>
                            <th style={{width: '13%'}}>Viaje</th>
                            <th style={{width: '5%'}}>Número de factura</th>
                            <th style={{width: '5%'}}>Monto</th>
                            <th style={{width: '40px'}}>Mon.</th>
                            <th style={{width: '7%'}}>F. Carta porte</th>
                            <th style={{width: '7%'}}>F. Fin viaje</th>
                            <th style={{width: '7%'}}>F. Recepción Documentos</th>
                            <th style={{width: '7%'}}>F. Liberación Documentos</th>
                            <th style={{width: '7%'}}>F. Recepción Administracion</th>
                            <th style={{width: '7%'}}>F. Facturacion Manual</th>
                            <th style={{width: '7%'}}>F. Envio a Cliente</th>
                            <th style={{width: '7%'}}>Pago</th>
                            <th style={{width: '40px'}}></th>
                            <th style={{width: '40px'}}></th>
                            <th style={{width: '7%'}}>Ligar FacturaSaas</th>
                        </tr>
                        </thead>
                        <tbody className={"ocash-table"}>
                        {datos.map((item, index) => {
                            return (
                                <tr key={item.id} className={"center"}>
                                    <td style={{width: '3%'}}>
                                        {item.seleccionable &&
                                            <FieldCheckBox name="seleccionado" value={item.seleccionado}
                                                           disabled={item.desabilitado}
                                                           onChange={() => seleccionar(item)}/>
                                        }
                                    </td>
                                    <td style={{width: '4%'}}>   {AgDateFormatter({value: item.fecha})}</td>
                                    <td style={{width: '4%'}}>{item.cartaPorte}</td>
                                    <td style={{width: '4%'}}>{item.idViaje}</td>
                                    <td style={{width: '10%', textAlign: 'left'}}>{item.cliente}</td>
                                    <td style={{width: '13%', textAlign: 'left'}}
                                        className="ocash-cell-label left">
                                        <span className={"link"}
                                              onClick={() => setViajeRutaId(item.viajeId)}>{item.origenDestino}</span>
                                    </td>
                                    <td style={{width: '5%'}}>{item.numeroFactura}</td>
                                    <td style={{width: '5%'}}>{numeral(item.monto).format('$0,0.00')}</td>
                                    <td style={{width: '40px'}}>{item.moneda}</td>
                                    <td style={{width: '7%'}}>
                                        <DateFormatter
                                            value={item.timbradoCartaPorte ?? item.impresionCartaPorte}/><br/>
                                        <span className="ocash-cell-label">
                                                {item.timbradoCartaPorteUsuario ?? item.impresionCartaPorteUsuario}
                                            </span>
                                    </td>
                                    <td style={{width: '7%'}}>
                                        <DateFormatter value={item.finViaje}/><br/>
                                        <span className="ocash-cell-label">{item.finViajeUsuario}</span>
                                    </td>
                                    <td style={{width: '7%'}}>
                                        <DateFormatter value={item.recepcionDocumentos}/><br/>
                                        <span className="ocash-cell-label">{item.recepcionDocumentosUsuario}</span>
                                    </td>
                                    <td style={{width: '7%'}}>
                                        <div style={{display:'flex', alignItems:'center'}}>
                                            <div>
                                                <DateFormatter value={item.liberacionDocumentos}/><br/>
                                                <span
                                                    className="ocash-cell-label">{item.liberacionDocumentosUsuario}</span>
                                            </div>
                                            {item.mostarDescarga &&
                                                <label className="download" style={{fontSize:'1.5em', paddingLeft:'5px'}} onClick={()=>mostrarDocumentos(item)}>
                                                    <IconLibrary iconName="MdAttachFile"/>
                                                </label>
                                            }
                                        </div>
                                    </td>
                                    <td style={{width: '7%'}}>
                                        {(item.pendiente === pendientes.recepcionAdministracion && !soloLectura) ?
                                            <Button label="Registrar" className="p-button-link"
                                                    onClick={() => registrar(item)}>
                                            </Button>
                                            :
                                            <>
                                                <DateFormatter value={item.recepcionAdministracion}/><br/>
                                                <span
                                                    className="ocash-cell-label">{item.recepcionAdministracionUsuario}</span>
                                            </>
                                        }
                                    </td>
                                    <td style={{width: '7%'}}>
                                        {((item.numeroFactura ?? '') === '' && !soloLectura) ?
                                            <Button label="Registrar" className="p-button-link"
                                                    onClick={() => registrar(item, pendientes.facturacionManual)}></Button>
                                            :
                                            <>
                                                <DateFormatter value={item.facturacionManual}/><br/>
                                                <span
                                                    className="ocash-cell-label">{item.facturacionManualUsuario}</span>
                                            </>
                                        }
                                    </td>
                                    <td style={{width: '7%'}}>
                                        {(item.pendiente === pendientes.envioCliente && !soloLectura) ?
                                            <Button label="Registrar" className="p-button-link"
                                                    onClick={() => registrar(item)}></Button>
                                            :
                                            <>
                                                <DateFormatter value={item.envioDocumentosCliente}/><br/>
                                                <span
                                                    className="ocash-cell-label">{item.envioDocumentosClienteUsuario}</span>
                                            </>
                                        }
                                    </td>
                                    <td style={{width: '7%'}}>
                                        {AgDateFormatter({value: item.pago})}
                                    </td>
                                    <td style={{width: '40px'}}>
                                        <ButtonIcon title={'Historial'}
                                                    className={item.ultimoEventoCancelado === item.pendiente ? 'ocash-cancelado' : ''}
                                                    onClick={() => setViajeHistorialId(item.viajeId)}
                                                    iconName={'BiHistory'}/>
                                    </td>
                                    <td style={{width: '40px'}}>
                                        {item.cancelable && !soloLectura &&
                                            <ButtonIcon title={'Cancelar Registro Fecha'}
                                                        onClick={() => setVajePorCancelar(item)}
                                                        iconName={'FcCancel'}/>
                                        }
                                    </td>
                                    <td style={{width: '7%'}}>
                                        {!item.timbradoCartaPorte && item.numeroFactura == null &&
                                            <Button label="Ligar FacturaSaas" className="p-button-link"
                                                    onClick={() => ligarFacturaSaas(item,pendientes.timbradoCartaPorte)}>
                                            </Button>
                                            
                                         }
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
            <SeleccionarViaje viajes={viajesSeleccionados} onHide={() => setDatosRegistrar(null)}
                              datosRegistrar={datosRegistrar}
                              onRegistered={onViajesChanged}/>
            <LigarSaas viajes={viajesSeleccionados} onHide={() => setLigarSaas(null)}
            facturaViajeaLigar={datosSaas}
            onRegistered={onViajesChanged}/>                              
            <CancelarFecha viajeSeleccionado={viajePorCancelar} onHide={() => setVajePorCancelar(null)}
                           onCanceled={onViajesChanged}></CancelarFecha>
            <OrderToCashHistorial viajeId={viajeHistorialId} onHide={() => setViajeHistorialId(null)}/>
            <Ruta viajeId={viajeRutaId} onHide={() => setViajeRutaId(null)}></Ruta>
            <Documentos viajeId={viajeId} onHide={()=>setViajeId(null)}/>
        </>
    )
}

export default OrderToCash;
