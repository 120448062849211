const PrioridadTemplate = (option) => {
    const getColor = ()=>{
        if(option.id === 611){
            return 'red'
        }else if(option.id === 612){
            return 'orange'
        }else if(option.id ===613){
            return '#ffd13f'
        }
    }

    return (
        <div className="country-item" style={{color:getColor(), fontWeight:'500'}}>
            <div>{option.nombre}</div>
        </div>
    );
}

export default PrioridadTemplate