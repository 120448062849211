import React from "react";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import produce from "immer";
import FieldCheckBox from "../../shared/controls/fieldCheckBox";

const ConfigSaas = ({config, onChange, errors}) => {

    const actualizar = (value, name) => {
        const newValue = produce(config, draft => {
            draft[name] = value;
        });



        onChange(newValue, 'saasConfig');
    }

    return (
        <div className="form-row">
            <FieldCheckBox name={"facturacionHabilitada"} label={"Facturacion Habilitada"}
                           onChange={actualizar} value={config.facturacionHabilitada} colMd={12}/>
            {config.facturacionHabilitada &&
                <>
                    <FieldTextBox name="url" label="URL" value={config.url} colMd={12} maxLength={100} required={true}
                                  onChange={actualizar} errors={errors}/>
                    <FieldTextBox name="company" label="Numero" value={config.company} colMd={6} maxLength={5}
                                  required={true}
                                  onChange={actualizar} errors={errors}/>
                    <FieldTextBox name="location" label="Ubicacion" value={config.location} colMd={6} maxLength={5}
                                  required={true}
                                  onChange={actualizar} errors={errors}/>
                    <FieldTextBox name="selloDigitalId" label="Sello Digital" value={config.selloDigitalId} colMd={6}
                                  maxLength={2} required={true}
                                  onChange={actualizar} errors={errors}/>
                    <FieldTextBox name="serieDigital" label="Serie Digital" value={config.serieDigital} colMd={6}
                                  maxLength={2} required={true}
                                  onChange={actualizar} errors={errors}/>
                    <FieldTextBox name="satUsoCfdi" label="SAT Uso CFDI" value={config.satUsoCfdi} colMd={6}
                                  maxLength={3} required={true}
                                  onChange={actualizar} errors={errors}/>
                    <FieldTextBox name="tipoPermisoSCT" label="Tipo Permiso SCT" value={config.tipoPermisoSCT} colMd={6}
                                  maxLength={10} required={true}
                                  onChange={actualizar} errors={errors}/>
                    <FieldTextBox name="numeroPermisoSCT" label="Permiso SCT" value={config.numeroPermisoSCT} colMd={6}
                                  maxLength={10} required={true}
                                  onChange={actualizar} errors={errors}/>
                    <FieldTextBox name="usuario" label="Usuario" value={config.usuario} colMd={6} maxLength={100}
                                  required={true}
                                  onChange={actualizar} errors={errors}/>
                    <FieldTextBox name="password" label="Contraseña" value={config.password} colMd={6} maxLength={100}
                                  required={true}
                                  onChange={actualizar} errors={errors}/>
                </>
            }
        </div>
    );
};

export default ConfigSaas;