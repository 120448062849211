/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {Dialog} from "primereact/dialog";
import ExGrid from "../../shared/Grid/ExGrid";
import ExColumn from "../../shared/Grid/ExColumn";
import useApiViajes from "../../api/operaciones/useApiViajes";
import {AgDateTimeFormatter} from "../../shared/ag-grid-helper/GridFormatters";

const Ruta = ({viajeId, onHide}) => {
    const visible = viajeId != null;
    const [datos, setDatos] = useState([]);
    const api = useApiViajes();

    useEffect(() => {
        cargarRuta().then();
    }, [viajeId]);

    const cargarRuta = async () => {
        if (viajeId == null) {
            setDatos([]);
            return;
        }
        const ruta = await api.getRuta(viajeId)
        setDatos(ruta);
    }

    return (
        <>
            <Dialog header="Ruta" visible={visible} style={{width: '600px'}} onHide={onHide}>
                <ExGrid
                    headerClass="header-class"
                    data={datos}
                    style={{maxHeight: '50vh'}}
                >
                    <ExColumn field={'nombre'} title={'DIRECCIÓN'} width={'2fr'}></ExColumn>
                    <ExColumn field={'fecha'} title={'FECHA'} width={'1fr'} cellRenderer={AgDateTimeFormatter}></ExColumn>
                </ExGrid>
            </Dialog>
        </>
    )
}

export default Ruta;
