/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from "react";

const ControlMapContext = React.createContext(undefined)

const useControlMapProvider = () => {
    const [seleccionGeocercaVisible, setSeleccionGeocercaVisible] = useState(false)
    const [geocercasVisibles, setGeocercasVisibles] = useState([])
    const [geocercas, setGeocercas] = useState([])
    const [selected, setSelected] = useState({});
    const [visibleRuteo, setVisibleRuteo] = useState(false)
    const [lugaresRuteo, setLugaresRuteo] = useState([])
    const [legsMapeo, setLegsMapeo] = useState([])
    const [notificacionSeleccionada, setNotificacionSeleccionada] = useState(null)

    const mostrarOcultarGeocercas = (mostrar)=>{
        setGeocercasVisibles( mostrar ? geocercas : [] )
        if (mostrar){
            let selected = {}
            let geocercaGrupoId = null
            for (let geo of  geocercas ){
                if (geo.geocercaGrupoId !== geocercaGrupoId){
                    selected[`${geo.geocercaGrupoId}`] = {checked:true, partialChecked:false}
                    geocercaGrupoId = geo.geocercaGrupoId
                }

                selected[`${geo.geocercaGrupoId}_${geo.id}`] = {checked:true, partialChecked:false}
            }
            setSelected(selected)
        }
        else {
            setSelected([])
        }
    }

    const generarRuta = (direcciones)=>{
        setLugaresRuteo(direcciones.map(e=>{return{latitude: e.punto.lat,longitude: e.punto.lng}}))
    }

    const cerrarRuteo =()=>{
        resetRuteo()
        setVisibleRuteo(false)

    }

    const resetRuteo = ()=>{
        setLugaresRuteo([])
        setLegsMapeo([])
    }



    return {
        seleccionGeocercaVisible,
        mostrarSeleccionGeocerca :()=>setSeleccionGeocercaVisible(true),
        ocultarSeleccionGeocerca :()=>setSeleccionGeocercaVisible(false),
        geocercasVisibles,
        setGeocercasVisibles,
        geocercas,
        setGeocercas,
        mostrarOcultarGeocercas,
        selected,
        setSelected,
        visibleRuteo,
        setVisibleRuteo,
        cerrarRuteo,
        lugaresRuteo,
        generarRuta,
        legsMapeo,
        setLegsMapeo,
        resetRuteo,
        setNotificacionSeleccionada,
        notificacionSeleccionada
    }
}

export const useControlMap = () => {
    return React.useContext(ControlMapContext)
}

const ControlMapProvider = ({children}) => {
    const value = useControlMapProvider()
    return (
        <ControlMapContext.Provider value={value}>
            {children}
        </ControlMapContext.Provider>
    )
}

export default ControlMapProvider