/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from "react";
import ExGrid from "../../../shared/Grid/ExGrid";
import ExColumn from "../../../shared/Grid/ExColumn";
import {Dialog} from "primereact/dialog";
import UseApiOrdenServicio from "../../../api/taller/useApiOrdenServicio";
import {fechaRenderer} from "../../../shared/Grid/GridCellRenderers";

const UltimosServicios = ({unidadId, onHide}) => {
    const visible = unidadId != null;
    const api = UseApiOrdenServicio();
    const [data, setData] = useState([])

    const cargar = useCallback(async () => {
        const result = await api.ultimasOrdenes(unidadId);
        setData(result);
    },[unidadId])

    useEffect(() => {
        if (unidadId != null && unidadId > 0)
            cargar().then()
    }, [unidadId,cargar]);


    return (
        <Dialog header={`Últimas Ordenes ${data.length > 0 ? data[0].numeroEconomico : ''}`} visible={visible}
                className={'servicios-unidad'}
                onHide={onHide}
        >
            <ExGrid data={data} getEntityId={(row) => row._uniqueUid} formDisabled={true}>
                <ExColumn field="codigo" title={'No. de Orden'} width={'60px'} />
                <ExColumn field="solicitante" title={'Solicitante'}  width={'1.5fr'} />
                <ExColumn field="descripcion" title={'Descripción'} width={'2fr'} />
                <ExColumn field="fechaEntrada" title={'Fecha de Entrada'} cellRenderer={fechaRenderer} width={'90px'} />
                <ExColumn field="fechaCompromiso" title={'Fecha Compromiso'} cellRenderer={fechaRenderer}  width={'100px'}/>
                <ExColumn field="estatus" title={'Estatus'}  width={'130px'}/>
                <ExColumn field="mecanicos" title={'Mecánico'} cellRenderer={({value})=>value.join(',')} width={'1.2fr'} />
            </ExGrid>
        </Dialog>
    );
};

export default UltimosServicios;
