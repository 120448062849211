import {produce} from "immer";

//reducer para el manejo de los estados de de una forma modal
function formReducer(state, action) {
    switch (action.type) {
        case 'update':
            let isDirty = true;
            let entity = {};
            let errors = {...state.errors};
            if (action.name != null) {
                entity = produce(state.entity, draft => {
                    draft[action.name] = action.value
                });

                if (state.submitted && state.errors != null) {
                    const {[action.name]: remove, ...rest} = state.errors;
                    errors = rest;
                }
            } else {
                entity = action.value;
                isDirty = false;
            }

            return {...state, entity, isDirty, errors};
        case 'submitted':
            return {...state, submitted: true};
        case 'saved':
            const savedEntity = action.savedEntity != null ? action.savedEntity : state.entity;
            return {...state, visible: false, isDirty: false, entity: savedEntity};
        case 'validate':
            return {...state, errors: action.errors};

        case 'show':
            return {...state, visible: true};

        case 'hide':
            return {...state, visible: false};

        case 'reset': {
            return {...state, ...action.getDefault()};
        }

        default :
            throw new Error('invalid action');
    }
}

export default formReducer;
