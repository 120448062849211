/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import useApiInventariosCaja from "../../api/taller/useApiInventariosCaja";
import useForm from "../../shared/forms/useForm";
import Field from "../../shared/forms/Field";
import {Button} from "primereact/button";
import Form from "../../shared/forms/Form";
import {Dialog} from "primereact/dialog";
import FormInputFile from "../../shared/forms/FormFields/FormInputFile";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import FormCalendar from "../../shared/forms/FormFields/FormCalendar";
import moment from "moment";

const SubirInventario = ({visible,  onCancel, onSaved }) => {
    const api = useApiInventariosCaja();

    const {
        values,
        onChange,
    } = useForm({hora:moment().format('HH:mm'), fecha:moment().startOf('day')._d});

    const onSave = async () => {
        const formData = new FormData();
        formData.append("file", values.inventario.file);
        const fecha = moment(values.fecha).format('YYYY-MM-DD') + ' ' + values.hora;
        await api.subirInventario(fecha, formData )
        await onSaved();
    }

    return (
        <>
            <Dialog header={`Subir Inventario`} visible={visible} style={{ width: '400px' }}
                    onHide={onCancel}>
                <Form values={values}
                      onChange={onChange}
                      className={`form-container`}
                >
                    <Field label="Inventario (Excel)" field={"inventario"} fileTypes={'.xlsx'} render={FormInputFile}/>
                    <Field label="Fecha" field={"fecha"} render={FormCalendar}/>
                    <Field label="hora" field={"hora"} render={({value, onChange})=>
                        <FieldTextBox type="time" value={value} onChange={(hora)=>onChange(hora,'fecha')}/>
                    }/>
                </Form>

                <div className="form-footer">
                    <Button label="Subir" className="p-button-outlined" type="button" onClick={onSave} />
                </div>
            </Dialog>
        </>
    )
}

export default SubirInventario;
