import useServiceFactory from "../serviceFactory";
const apiUrl = `/catalogos/Clientes`;
const useApiClientes = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, {params}),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, {showLoading: true}),
        guardar: async (params) => service.post(`${apiUrl}`, params),
        actualizar: async (id,params) => service.put(`${apiUrl}/${id}`, params),
        subirConstancia: async(id, formData) => service.post(`${apiUrl}/${id}/constancia`, formData),
        eliminar: async (id) => service.delete(`${apiUrl}/${id}`),
        obtenerConveniosPorCliente: async (id) => service.get(`${apiUrl}/obtenerConveniosPorCliente/${id}`, {showLoading: true}),
        obtenerConceptos: async () => service.get(`${apiUrl}/obtenerConceptos`, {showLoading: true}),
        alta: async (id) => service.patch(`${apiUrl}/${id}/alta`, {}),
        baja: async (id) => service.put(`${apiUrl}/${id}/baja`, {}),
        subirAnexos: async(id, formData) => service.post(`${apiUrl}/${id}/archivos`, formData),
        excel: async (params) => service.get(`${apiUrl}/excel`,
            {
                ...params,
                showLoading: true,
                download: true,
                fileName: `Clientes.xlsx`
            })
    }
};

export default useApiClientes;
