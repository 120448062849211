import {useState} from "react";
import useApiSeguridad from "../api/seguridad";
import produce from "immer";
import jwtDecode from "jwt-decode";
import {useAuth} from "./AuthProvider";
import {useNavigate} from "react-router-dom";
import {getMsLoginUrl} from "./securityUtility";

const useFormLogin = () => {
    const [login, setLogin] = useState({username:'',password:''});
    const auth = useAuth()
    const [submitted,setSubmitted] = useState(false);
    const [isSubmitting,setIsSubmitting] = useState(false);
    const apiSeguridad = useApiSeguridad();

    const navigate = useNavigate()

    const   validarUsuario =  async(navigateHome) =>  {
       
        navigateHome = navigateHome??true;
        setSubmitted(true);
        if (login.username === '' || login.password === '')
            return false;
        setIsSubmitting(true);
        try {
            const result = await apiSeguridad.validar(login);
            if(!result.dobleFA){            
            const token = result.token
            const decoded = jwtDecode(token)
            auth.login({
                token: token,
                tokenExpiration: decoded.exp * 1000,
                email: decoded.preferred_username,
                nombre: result.nombre,
                username: result.username,
                empleadoId: result.empleadoId
            })
            if (navigateHome)
                navigate('/home')

            return true;
        }else{
            handleLoginAzure()
        }

        }catch (e) {
            setIsSubmitting(false);
            console.error(e)
            return false;
        }
    }

    const actualizar = (value, propertyName) => {
        const nuevoLogin = propertyName != null ? produce(login, draft => {
            draft[propertyName] = value
        }) : value;
        setLogin(nuevoLogin);
    }

    const onFormKeyPres = (event) => {
        if (event.keyCode === 13) {
            validarUsuario();
        }
    }

    const handleLoginAzure = () => {
        localStorage.setItem('authenticando', true)
        const left = (window.screen.availWidth / 2) - 200
        const top = (window.screen.availHeight / 2) - 250
        window.open(getMsLoginUrl(), 'NameOfTheWindow1',
            'width=400,height=500,top=' + top + ',left=' + left +
            ',toolbar=no,menubar=no,scrollbars=no,location=no,directories=no',
            'noopener')
    }

    return {
        onFormKeyPres,
        actualizar,
        validarUsuario,
        submitted,
        isSubmitting,
        login,
        handleLoginAzure
    }
}

export default useFormLogin
