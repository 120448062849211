
import useServiceFactory from "../serviceFactory";

const useApiConfiguracionCajaChica = () => {
    const apiUrl = `catalogos`;
    const service = useServiceFactory({ uri: process.env.REACT_APP_CAJA_API_URL });
    return {
        filtrarCajas: async (params) => service.get(`${apiUrl}/cajas`, {params}),
        obtenerCaja: async (id) => service.get(`${apiUrl}/cajas/${id}`, {showLoading: true}),
        guardarCaja: async (params) => service.post(`${apiUrl}/cajas`, params),
        filtrarTipoGastos: async (params) => service.get(`${apiUrl}/TiposDeGasto`, {params}),
        obtenerTipoGasto: async (id) => service.get(`${apiUrl}/TiposDeGasto/${id}`, {showLoading: true}),
        guardarTipoGasto: async (params) => service.post(`${apiUrl}/TiposDeGasto`, params),
        eliminarTipoGasto: async (id) => service.delete(`${apiUrl}/TiposDeGasto/${id}`),
        filtrarConceptos: async (params) => service.get(`${apiUrl}/conceptos`, {params}),
        obtenerConcepto: async (id) => service.get(`${apiUrl}/conceptos/${id}`, {showLoading: true}),
        guardarConcepto: async (params) => service.post(`${apiUrl}/conceptos`, params),
        eliminarConcepto: async (id) => service.delete(`${apiUrl}/conceptos/${id}`),
    }
};

export default useApiConfiguracionCajaChica;
