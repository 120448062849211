import React, { useState }  from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useReducer } from 'react';
import agGridHelper from "../../../shared/ag-grid-helper";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import Filtro from "../../../shared/componentes/filtro";
import FieldCalendarRange from "../../../shared/controls/fieldCalendarRange";
import FieldMultiSelect from "../../../shared/controls/fieldMultiSelect";
import { getSessionState  } from "../../../hooks/useSessionState";
import useApiViaje from '../../../api/operaciones/useApiViajes';
import { Sidebar } from "primereact/sidebar";
import FieldDropDown from '../../../shared/controls/fieldDropDown';
import FieldInputNumber from '../../../shared/controls/fieldInputNumber';
import FieldTextArea from "../../../shared/controls/fieldTextArea";
import formReducer from '../../../shared/forms/formReducer';
import FormHandler from "../../../shared/forms/formHandler";
import { gastoValidacionEsquema } from "./gastoValidacionEsquema";
import FieldCheckBox from '../../../shared/controls/fieldCheckBox';
import FieldRadioButtonList from "../../../shared/controls/fieldRadioButtonList";
import alerts from "../../../shared/alerts";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import swal from "sweetalert2";
import useSeguridad from "../../../Seguridad/useSeguridad";
import constantes from "../../../catalogos/constantes";
import './gastosSeguridad.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import constantesAdministracion from "../../../administracion/constantes";
const GastoSeguridad = () => {
    const [datos, setDatos] = useState([]);  
    const defaultEntity = () => ({ entity: { 
        operador: null,
        concepto: null,
        monto: null,
        comentario: null,
        urgente: true,
        extProv: constantes.tiposDepositoStp.CuentaXimple, 
        cuentas: null ,
        transferenciaStp:true
    } });

    const [{ entity, errors }, dispatch] = useReducer(formReducer, {}, defaultEntity);
    const filtroKey = 'FiltroGastosSeguridad';
    const [categoriaTipo, setCategoriaTipo] = useState(entity?.categoria ||  constantesAdministracion.categorias.Seguridad);
    const api = useApiViaje();
    const [viajeId, setviajeId] = useState(null);
    const [nuevoGastoSeguridad, setNuevoGastoSeguridad] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    async function buscar(filtro, paramsApi) {
        agGridHelper.showLoad(gridApi, paramsApi, true);
        setDatos(await api.obtenerGastosSeguridad(obtenerFiltro(filtro)));
        agGridHelper.hideLoad(gridApi, paramsApi, false);     
        console.log(datos)
    }
    const RowButtons = ({ data }) => (
        <div className="ag-grid row-icons">
           
        </div>
    );
    const onGridReady = async (params) => {
        setGridApi(params.api);
    };
    const obtenerFiltro = (filtro) => {
        filtro = filtro ?? getSessionState(filtroKey);

        return { ...filtro};
    };
   
    const [cuentasOperador, setcuentasOperador] = useState([]);
    const [operadorSelect, setoperadorSelect] = useState([]);

    const seguridad = useSeguridad();
    const externals = [  
        { id: 3, nombre: "Cuenta Ximple" },
        { id: 1, nombre: "Provisional" },
        { id: 2, nombre: "Externo" },
    ];
 
    const formHandler = new FormHandler({
        validationSchema: gastoValidacionEsquema,
        api,
        dispatch
    });
    let permisoTransferenciaStp = seguridad.tieneAcceso(constantes.opciones.permisoGastoUrgenteStp); 
    let permisoGastoUrgente = seguridad.tieneAcceso(constantes.opciones.permisoGastoUrgente); 
    const consultarCuentas = async (id) => {
        if (!id) return;
        const result = await api.cuentasBancarias(id);
        setcuentasOperador(result)
    };
    

    const actualizar = async (value,propertyName) => {  
        if (propertyName ==="operador" && (entity.extProv === constantes.tiposDepositoStp.CuentaXimple) ){
            consultarCuentas(value.id);             
        }
        if (propertyName === "extProv") {
            
            if (propertyName === "extProv") {
                dispatch({
                    type: 'update',
                    value: null,
                    name: 'operador' 
                });
                
                dispatch({
                    type: 'update',
                    value: null,
                    name: 'cuentas'
                });
        
                dispatch({
                    type: 'update',
                    value: null,
                    name: 'nombreexterno'
                });
        
                dispatch({
                    type: 'update',
                    value: null,
                    name: 'banco'
                });
        
                dispatch({
                    type: 'update',
                    value: null,
                    name: 'clabeAnticipo'
                });
        
                dispatch({
                    type: 'update',
                    value: null,
                    name: 'tipoGasto'
                });
        
                dispatch({
                    type: 'update',
                    value: null,
                    name: 'conceptoCajaChica' 
                });
        
                dispatch({
                    type: 'update',
                    value: null,
                    name: 'monto' 
                });
        
                dispatch({
                    type: 'update',
                    value: null,
                    name: 'comentario' 
                });
            } else {
 
                dispatch({ type: 'update', value, name: propertyName });
            }

        } else {
            dispatch({ type: 'update', value, name: propertyName });
        }   
        if(propertyName ==="categoria"){
            const nuevaCategoria = value.id;
            setCategoriaTipo(nuevaCategoria);
            entity.categoria= nuevaCategoria;
            const data =  api.consultarGastoCategoria(categoriaTipo)
            entity.tipoGasto = data;
            console.log(data)
        }
        dispatch({ type: 'update', value: value, name: propertyName });
        console.log(entity)   
    };  
    const validarClabe = async (value) => {
     console.log(value)
        if (/^\d+$/.test(value)) {
           if (value.length >= 1) {               
            if (isValidClabe(value)) {
                return { isValid: true }; 
            } else {
              await swal.fire({
                titleText: 'Atención.',
                text: 'Número CLABE no válido.',
                icon: 'warning',
              });
              return { isValid: false, error: 'CLABE no válida' };
            }
          }
          else if (value.length >= 1) {
            if (value.length >= 1) {                  
              if (isValidCardNumber(value)) {
                return { isValid: true }; 
              } else {                    
                await swal.fire({
                  titleText: 'Atención.',
                  text: 'Número de tarjeta no válido.',
                  icon: 'warning',
                });
                return { isValid: false, error: 'Número de tarjeta no válido.' };
              }
            } else {
              await swal.fire({
                titleText: 'Atención.',
                text: 'La longitud del número es incorrecta.',
                icon: 'warning',
              });
              return { isValid: false, error: 'Longitud incorrecta' };
            }
          } 
          else if (value.length >= 1) {
            await swal.fire({
              titleText: 'Atención.',
              text: 'La longitud del número es incorrecta.',
              icon: 'warning',
            });
            return { isValid: false, error: 'Longitud incorrecta' };
          }
        } else {
          if (value.length >= 1) {
            await swal.fire({
              titleText: 'Atención.',
              text: 'Debe contener solo números.',
              icon: 'warning',
            });
            return { isValid: false, error: 'Debe contener solo números' };
          }
        } 
    }   
    function isValidCardNumber(cardNumber) {
        let sum = 0;
        let alternate = false;
     
        for (let i = cardNumber.length - 1; i >= 0; i--) {
            let n = parseInt(cardNumber[i], 10);
            if (alternate) {
                n *= 2;
                if (n > 9) n -= 9;
            }
            sum += n;
            alternate = !alternate;
        }
     
        return sum % 10 === 0;
    }
     
    function isValidClabe(clabe) {
        if (clabe.length !== 18) return false;
     
        const factors = [3, 7, 1];
        let sum = 0;
     
        for (let i = 0; i < 17; i++) {
            const n = parseInt(clabe[i], 10);
            sum += n * factors[i % 3];
        }
     
        let checkDigit = 10 - (sum % 10);
        if (checkDigit === 10) checkDigit = 0;
     
        return checkDigit === parseInt(clabe[17], 10);
    }
     
    const guardar = async () => {
        const seguir = await formHandler.validate(entity);
        if(entity.transferenciaStp){
            if (seguir.result) {
                if ((await alerts.preguntarSiNo("¿Está seguro de realizar la transferencia?")) === true) {
                    if (seguir.result) {
                        let clabeValidar = entity.clabeAnticipo ==null ? entity.cuentas.id :entity.clabeAnticipo;
                        console.log(clabeValidar)
                        const valida = await validarClabe(clabeValidar);
                        if (valida.isValid) {
                            const nuevo = await api.agregarAnticipo(viajeId, entity);
                            nuevo.categoriaActual = categoriaTipo;
                            //onSave(nuevo);
                            close();
                            dispatch({
                                type: "update",
                                value: { ...defaultEntity }
                            });                           
                        }
                 
                    }
                }   
         }         
        }
        else{
            if (seguir.result) {
                const nuevo = await api.agregarAnticipo(viajeId, entity);
                nuevo.categoriaActual = categoriaTipo;
                //onSave(nuevo);
                dispatch({
                    type: "update",
                    value: { ...defaultEntity }
                });
                close();
            } 
        }             
    };
    const ButtonsHeader = () => {
        return (
            <div className="ag-grid header-icons">                
                <ButtonIcon title="Agregar Gasto Urgente" className="grid-action" iconName="MdAddBox"  onClick={() => setNuevoGastoSeguridad(true)}/>
            </div>
            
        );
    };
    const renderFooterModal = () => {
        return (
            <div>
                <Button label="Cancelar" onClick={close} className="p-button-outlined" />
                <Button label= "Transferir" onClick={guardar} autoFocus />
            </div>
        );
    }
    const consultarOperador = async (cartaPorte) => {
        if (cartaPorte > 0) {
            const result = await api.consultarOperadorSeguridad(cartaPorte);
            console.log(result)
            setviajeId(result.find(item => item.viajeId)?.viajeId);
            setoperadorSelect(result);
            
     }
    }
    const close = () => {       
        setcuentasOperador([])
        dispatch({
            type: "update",
            value: { 
                ...defaultEntity(), 
                cuentas: null,
                extProv:constantes.tiposDepositoStp.CuentaXimple
            }
        });  
        setNuevoGastoSeguridad(false)
    };

    return (
        <>
      
            <Sidebar visible={sidebarVisible} position="right" dismissable={false}
                className="p-sidebar-xl" onHide={() => setSidebarVisible(false)}>
            </Sidebar>

            <Filtro filtroKey={filtroKey} handleSubmit={buscar} filtrarAlInicio={false}
                classButtons="field-iconos btn-group">
                <Filtros />
            </Filtro>

            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                      enableSorting={true}
                      enableFilter={true}
                      pagination={true}
             
                      frameworkComponents={
                          {
                              buttonsHeader: ButtonsHeader,
                              rowButtons: RowButtons,
                              goToDetail: agGridHelper.GoToDetailFormatter,
                              dateFormatter: agGridHelper.DateTimeFormatter,
                          }
                      }
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    rowHeight={35}
                >
                    <AgGridColumn maxWidth={200} field="fecha" headerName="FECHA" cellRenderer="dateFormatter" flex={5} minWidth={200}/>
                    <AgGridColumn field="operador" headerName="EMPLEADO" flex={5} minWidth={280} cellClass={'left'} headerClass={'left'} />
                    <AgGridColumn field="cartaPorte" headerName="CARTA PORTE" flex={5} minWidth={100} cellClass={'left'} headerClass={'left'} />
                    <AgGridColumn field="noSolicitud" headerName="SOLICITUD" flex={5} minWidth={100} cellClass={'left'} headerClass={'left'} />
                    <AgGridColumn field="estatusStp" headerName="ESTATUS" flex={5} minWidth={100} cellClass={'left'} headerClass={'left'} />
                    <AgGridColumn field="categoria" headerName="CATEGORIA" flex={5} minWidth={120} cellClass={'left'} headerClass={'left'} />
                    <AgGridColumn field="gasto" headerName="GASTO" flex={5} minWidth={250} cellClass={'left'} headerClass={'left'} />
                    <AgGridColumn field="concepto" headerName="CONCEPTO" flex={5} minWidth={250} cellClass={'left'} headerClass={'left'} />                   
                    <AgGridColumn field="usuario" headerName="USUARIO SOLICITO" flex={5} minWidth={250} cellClass={'left'} headerClass={'left'} />
                   
                    <AgGridColumn maxWidth={80}
                        headerName="VER"
                        cellRenderer="rowButtons"
                        pinned={'right'}
                        cellClass={'left'}
                        headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>
            <Dialog header="Agregar Gasto Seguridad" visible={nuevoGastoSeguridad} hide={() => setNuevoGastoSeguridad(false)}   style={{ width: '90%', maxWidth: '900px' }} footer={renderFooterModal()} onHide={close}>       
            {(permisoTransferenciaStp) &&
               <div className="form-row">   
                <FieldCheckBox 
                        name="transferenciaStp" 
                        label="Transferencia Stp" 
                        value={entity.transferenciaStp} 
                        className="py-1" 
                        flexflow="row-reverse" 
                        colMd={12}
                        onChange={actualizar} 
                    />
                </div>  
            }  
            {(permisoGastoUrgente) &&
               <div className="form-row">   
                <FieldCheckBox 
                        name="urgente" 
                        label="Urgente" 
                        value={entity.urgente} 
                        className="py-1" 
                        flexflow="row-reverse" 
                        colMd={12}
                        onChange={actualizar} 
                    />
                </div>  
                }  
                {entity.transferenciaStp && (
                      <div className="d-flex justify-content-center">
                        <div className={`form-group col-md-6 col-sm-12}`}>                
                            <FieldRadioButtonList
                                name="extProv"
                                colMd={12}
                                options={externals}
                                value={entity.extProv}
                                onChange={actualizar}
                            />    
                        </div> 
                    </div> 
                )}   
                <div className="form-row">
                <>
                    <FieldTextBox 
                        name="cartaPorte" 
                        value={entity.cartaporte} 
                        label="Carta Porte"
                        required 
                        error={errors?.cartaporte}
                        colMd={6}
                        onChange={actualizar} 
                        onBlur={(event) => consultarOperador(event.target.value)}
                    />
                </>
                { (entity.extProv === constantes.tiposDepositoStp.CuentaXimple  || entity.extProv === constantes.tiposDepositoStp.Provisional)&&(
                    
                    <>
                           <FieldDropDown 
                            name="operador" 
                            options={operadorSelect}                                     
                            value={entity.operador}
                            label="Operador"
                            required 
                            colMd={6} 
                            error={errors?.operador}                           
                            onChange={actualizar} 
                        />
                           </>
                    )}
                    <>
                    {entity.operador && entity.transferenciaStp && (entity.extProv === constantes.tiposDepositoStp.CuentaXimple) && cuentasOperador.length > 0 && (
                            <FieldDropDown
                                name="cuentas"       
                                options={cuentasOperador}                  
                                error={errors?.cuentas}
                                label="Cuentas Operador"
                                colMd={12}
                                filter={true}
                                required={true}
                                value={entity.cuentas || []} 
                                onChange={actualizar}
                            />
                        )}
                    </>
                
                {entity.extProv===2 && (
                    <>
                        <FieldTextBox 
                            name="nombreexterno" 
                            value={entity.nombreexterno} 
                            label="Nombre"
                            required 
                            error={errors?.nombreexterno}
                            colMd={12}
                            onChange={actualizar} 
                        />
                    </>
                  )}
                {entity.transferenciaStp && (entity.extProv ===constantes.tiposDepositoStp.Externo || entity.extProv===constantes.tiposDepositoStp.Provisional) && (
                    <>
                        <FieldDropDown 
                            name="banco" 
                            source="bancos" 
                            value={entity.bancos} 
                            label="Banco" 
                            filter={true}
                            colMd={6} 
                            required 
                            error={errors?.banco}
                            onChange={actualizar} 
                        />
                        <FieldTextBox 
                            name="clabeAnticipo" 
                            value={entity.clabeAnticipo} 
                            label="Tarjeta o CLABE" 
                            colMd={6}
                            required 
                            error={errors?.clabeAnticipo}
                            onBlur={(event) => validarClabe(event.target.value)}
                            onChange={actualizar} 
                        />
                    </>
                )}
                 
                 <FieldDropDown name="categoria" source="cajachica/categorias" value={entity?.categoria} label="Categoria" 
                                     params={{ categoriaTipo: categoriaTipo }}   filter={true}
                    required colMd={6} error={errors?.categoria } onChange={actualizar}/>
                
                <FieldDropDown name="tipoGasto" source="cajachica/tipoGastos" value={entity?.tipoGasto} label="Tipo de Gasto" 
                                     params={{ categoriaTipo: categoriaTipo }}   filter={true}
                    required colMd={6} error={errors?.tipoGasto } onChange={actualizar} />
                <FieldDropDown name="conceptoCajaChica" source="cajachica/conceptos" value={entity?.conceptoCajaChica} label="Concepto"  filter={true}
                    required colMd={6} error={errors?.conceptoCajaChica} params={{ tipoGastoId: entity?.tipoGasto?.id }}
                    onChange={actualizar} />
                <FieldInputNumber name="monto" value={entity.monto} fractionDigits={2} label="Monto"
                    required colMd={6} error={errors?.monto}
                    onChange={actualizar} />
                <FieldTextArea name="comentario"  label="Comentario" value={entity.comentario} colMd={12} rows={3}
                                         error={errors?.comentario} onChange={actualizar} />
            </div>

        </Dialog>
        </>
    )
}
const Filtros = ({ filtro, onChange }) => {
   
    return (
        <>
  
         <FieldMultiSelect name="empleadosId" source="empleados"   label="Empleado" colLg={2} colMd={3} colSm={3}
                value={filtro.empleadosId}  onChange={onChange} showFilter valueAsId={true} />

            <FieldCalendarRange name="fechas" label="Fechas" colLg={2} colMd={3} colSm={3} value={filtro.fechas}
                onChange={onChange} />
        </>
    )
};
export default GastoSeguridad;
