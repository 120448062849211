import React from "react";
import LogoPage from "../img/unauthorized.png";

const Unauthorized =()=>{

    return (
        <div className="logoLandingPage-padre">
            <img className="logoLandingPage-hijo" alt="LogoPage" src={LogoPage}/>
        </div>
    );
};

export default Unauthorized;
