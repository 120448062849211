/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Form from "../../../shared/forms/Form";
import Field from "../../../shared/forms/Field";
import FormInputNumber from "../../../shared/forms/FormFields/FormInputNumber";
import useApiSource from "../../../api/source";

const ConversionGalones = ({ value: conversion, parent: carga, onChange, onValidate, formDisabled }) => {
    const [valorDolar, setValorDolar] = useState(false);
    const apiSource = useApiSource();
    const galonALitro = 3.78541;

    useEffect(() => {
        obtenerValorDolarPeso().then();
    }, []);

    const obtenerValorDolarPeso = async () => {
        const result = await apiSource.obtener("ObtenerTasaCambioDolarPeso");
        setValorDolar(result);
    };

    const handleChange = async (nuevoConversion) => {
        if (nuevoConversion.galon == null || nuevoConversion.precioGalonDolar == null) {
            onChange({ ...nuevoConversion, litros: undefined, precio: undefined });
            return;
        }

        const precioPorLitroEnDolares = nuevoConversion.precioGalonDolar / galonALitro;
        const tasaDeCambioDolarPesosMexicanos = nuevoConversion.tasaDeCambioDolarPesos ?? valorDolar;
        const precioEnPesosMexicanos = precioPorLitroEnDolares * tasaDeCambioDolarPesosMexicanos;
        const cantidadLitros = nuevoConversion.galon * galonALitro;

        onChange({
            ...nuevoConversion,
            litros: cantidadLitros,
            precio: precioEnPesosMexicanos,
            tasaDeCambioDolarPesos: tasaDeCambioDolarPesosMexicanos,
        });
    };

    return (
        <Form
            onChange={handleChange}
            columns={2}
            values={conversion}
            onValidate={onValidate}
            formDisabled={formDisabled}
        >
            <Field
                field={"galon"}
                label={"Galones"}
                required={true}
                mode="decimal"
                debounceTimeOut={500}
                maxFractionDigits={2}
                render={FormInputNumber}
                onChange={handleChange}
            />

            <Field
                field={"precioGalonDolar"}
                label={"Precio Usd"}
                mode="decimal"
                maxFractionDigits={2}
                render={FormInputNumber}
                onChange={handleChange}
            />
        </Form>
    );
};

export default ConversionGalones;
