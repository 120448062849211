import React from "react";
import FieldAutoComplete from "../../../shared/controls/fieldAutoComplete";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import FieldCalendarRange from "../../../shared/controls/fieldCalendarRange";
import FieldMultiSelect from "../../../shared/controls/fieldMultiSelect";
import FieldDropDown from "../../../shared/controls/fieldDropDown";

const FiltroCotizaciones = ({filtro, onChange}) => {
    return (
        <>
            <FieldTextBox name="noCotizacion" label="No. Cotización" colLg={1} keyFilter={'int'}
                          value={filtro.noCotizacion} onChange={onChange}/>
            <FieldAutoComplete name="cliente" source="clientes" label="Cliente" colMd={2}
                               value={filtro.cliente} onChange={onChange}/>                          
            <div className="form-group col-md-4 col-sm-12">
                <label>Ruta</label>
                <div className="col-auto">
                    <FieldAutoComplete name="rutaOrigen" source="rutasOrigen" colLg={2} freeText={true}
                                       placeholder={'Desde'}
                                       value={filtro.rutaOrigen} onChange={onChange}/>
                    <FieldAutoComplete name="rutaDestino" source="rutasDestino" colLg={2} freeText={true}
                                       placeholder={'Hasta'}
                                       value={filtro.rutaDestino} onChange={onChange}/>
                </div>
            </div>
            <FieldDropDown name="tipoUnidad" source="tiposUnidad" label="Tipo de Unidad" colLg={1}
                           value={filtro.tipoUnidad} onChange={onChange}/>
            <FieldCalendarRange name="fechas" label="Fechas" colLg={2}
                                value={filtro.fechas} onChange={onChange}/>
            <FieldMultiSelect name="estatus" source="estatusCotizacion" label="Estatus" colLg={2}
                              value={filtro.estatus} onChange={onChange}/>
        </>
    )
};

export default FiltroCotizaciones;
