import TableCheckListUnidad from "./tableCheckListUnidad"
import React from "react";
import produce from "immer";

const CheckListEstatusUnidad = ({disable, checkUnidad, onChange, onSubmit, errors, checkId, urlFirma}) => {
    async function actualizar(value, propertyName) {
        const nuevo = produce(checkUnidad ?? {}, draft => {
            draft[propertyName] = value
        });

        onChange(nuevo, "checkUnidad");
    }

    return (
        <>
            <div className="form-container">
                <TableCheckListUnidad errors={errors} disabled={disable} onChange={actualizar}
                                      checklist={checkUnidad?.checkList} checkId={checkId} urlFirma={urlFirma}/>
            </div>
        </>
    )
};

export default CheckListEstatusUnidad;
