import useServiceFactory from "../serviceFactory";
const apiUrl = `/seguridad/usuariosFiltro`;
const useApiUsuarioFiltro = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        obtener: async (modulo) => service.get(`${apiUrl}/${modulo}`, {showLoading: true}),
        guardar: async (modulo, params) => service.post(`${apiUrl}/${modulo}`, params),
        actualizar: async (id,params) => service.put(`${apiUrl}/${id}`, params)
    }
};

export default useApiUsuarioFiltro;
