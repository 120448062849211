import React from "react";
import FieldTextBox from "../controls/fieldTextBox";
import FieldCalendar from "../controls/fieldCalendar";
import produce from "immer";
import constantes from "../../catalogos/constantes";

const AddendaSoriana = ({ entity, actualizar, soloLectura = false }) => {

    function actualizarAddenda(value, propertyName) {
        const addenda = produce(entity.addenda, draft => {
            draft[propertyName] = value;
        }
        );
        actualizar(addenda, 'addenda');
    }

    return (
        <>
            {entity.addendaId === constantes.addendas.soriana &&
                <div>
                    <br />
                    <hr />
                    <div className="form-row">
                        <h5 className="col-12">Addenda Soriana</h5>

                        <FieldTextBox name="numeroProveedor" value={entity.addenda?.numeroProveedor} maxLength={20}
                            label="Número Proveedor" colMd={3} onChange={actualizarAddenda} disabled={entity.timbrado || soloLectura} />

                        <FieldTextBox name="cantidadUnidadCompra" value={entity.addenda?.cantidadUnidadCompra} maxLength={20}
                            label="Cantidad Unidad Compra" colMd={3} onChange={actualizarAddenda} disabled={entity.timbrado || soloLectura} />

                        <FieldTextBox name="folioPedido" value={entity.addenda?.folioPedido} maxLength={20}
                            label="Folio Pedido" colMd={3} onChange={actualizarAddenda} disabled={entity.timbrado || soloLectura} />

                        <FieldCalendar name="fechaEntregaMercancia"
                            label="Fecha Entrega MercancÍa"
                            className="small"
                            showButtonBar={false}
                            value={entity.addenda?.fechaEntregaMercancia}
                            colMd={3}
                            onChange={actualizarAddenda} disabled={entity.timbrado || soloLectura}
                        />
                    </div>
                    <div className="form-row">

                        <FieldTextBox name="tienda" value={entity.addenda?.tienda} maxLength={20}
                            label="Tienda" colMd={3} onChange={actualizarAddenda} disabled={entity.timbrado || soloLectura} />

                        <FieldTextBox name="numeroEntregaMercancia" value={entity.addenda?.numeroEntregaMercancia} maxLength={20}
                            label="Numero Entrega Mercancía" colMd={3} onChange={actualizarAddenda} disabled={entity.timbrado || soloLectura} />

                        <FieldTextBox name="folioNotaEntrada" value={entity.addenda?.folioNotaEntrada} maxLength={20}
                            label="Folio Nota Entrada" colMd={3} onChange={actualizarAddenda} disabled={entity.timbrado || soloLectura} />

                    </div>
                    <hr />
                    <br />
                </div>
            }
            {entity.addendaId === constantes.addendas.mabe &&
                <div>
                    <br />
                    <hr />
                    <div className="form-row">
                        <h5 className="col-12">Addenda MABE</h5>

                        <FieldTextBox name="ordenCompra" value={entity.addenda?.ordenCompra} maxLength={20}
                            label="Orden Compra" colMd={3} onChange={actualizarAddenda} disabled={entity.timbrado || soloLectura} />

                        <FieldTextBox name="plantaEntrega" value={entity.addenda?.plantaEntrega} maxLength={20}
                            label="Planta Entrega" colMd={3} onChange={actualizarAddenda} disabled={entity.timbrado || soloLectura} />

                        <FieldTextBox name="plantaEntregaCP" value={entity.addenda?.plantaEntregaCP} maxLength={20}
                            label="Código Postal" colMd={3} onChange={actualizarAddenda} disabled={entity.timbrado || soloLectura} />

                        <FieldTextBox name="plantaEntregaCalle" value={entity.addenda?.plantaEntregaCalle} maxLength={20}
                            label="Calle" colMd={3} onChange={actualizarAddenda} disabled={entity.timbrado} />
                    </div>
                    <div className="form-row">

                        <FieldTextBox name="plantaEntregaNoExterior" value={entity.addenda?.plantaEntregaNoExterior} maxLength={20}
                            label="No. Exterior" colMd={3} onChange={actualizarAddenda} disabled={entity.timbrado || soloLectura} />

                        <FieldTextBox name="plantaEntregaNoInterior" value={entity.addenda?.plantaEntregaNoInterior} maxLength={20}
                            label="No. Interior" colMd={3} onChange={actualizarAddenda} disabled={entity.timbrado || soloLectura} />

                        <FieldTextBox name="claveDeRuta" value={entity.addenda?.claveDeRuta} maxLength={20}
                            label="Clave De Ruta" colMd={3} onChange={actualizarAddenda} disabled={entity.timbrado || soloLectura} />

                    </div>
                    <hr />
                    <br />
                </div>
            }
        </>
    )
};

export default AddendaSoriana;