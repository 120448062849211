/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";
import moment from "moment";
const defaultHoras = [0,8]

const useRuta = (ruta) => {
    const [horas, setHoras] = useState(defaultHoras)
    const [rutaActual, setRutaActual] = useState({})
    const [puntosRuta, setPuntosRuta] = useState([])
    const [marcadores, setMarcadores] = useState([])
    const [stops, setStops] = useState([])


    useEffect(()=>{
        if (ruta?.puntos == null) {
            setRutaActual({puntos: []})
            setPuntosRuta([])
            setMarcadores([])
            setStops([])
            return
        }
        const rutaActual = {
            ...ruta,
            puntos:ruta.puntos.map(e=>{return {...e, fecha:moment.utc(e.fecha)}}),
            marcadores:ruta.marcadores.map(e=>{return {...e, fecha:moment.utc(e.fecha)}}),
            stops:ruta.stops.map(e=>{return {...e, fecha:moment.utc(e.fecha)}}),
        }

        setRutaActual(rutaActual)
        if (!ruta.rangoExtendido) {
            setHoras(defaultHoras)
            filtrarPorHoras(defaultHoras, rutaActual.puntos, rutaActual.marcadores, rutaActual.stops)
        }

    }, [ruta])

    const obtenerHoras = (valor) =>{
        if (valor === 24)
            return 48
        if (valor  === 25)
            return 72

        return valor
    }

    const filtrarPorHoras = (horas, puntos, marcadores, stops)=>{
        const utc =moment().utc();

        const de = obtenerHoras(horas[0])
        const hasta = obtenerHoras(horas[1])

        setPuntosRuta(puntos.filter(e=> utc.diff(e.fecha, 'hours') <= hasta && utc.diff(e.fecha, 'hours') >= de  ))
        setMarcadores(marcadores.filter(e=> utc.diff(e.fecha, 'hours') <= hasta && utc.diff(e.fecha, 'hours') >= de ))
        setStops(stops.filter(e=> utc.diff(e.fecha, 'hours') <= hasta && utc.diff(e.fecha, 'hours') >= de ))
    }

    const onChangeHoras = (nuevoValor)=>{
        setHoras(nuevoValor)
        filtrarPorHoras(nuevoValor, rutaActual.puntos, rutaActual.marcadores, rutaActual.stops)

    }

    const mostrandoRuta =()=>{
        return rutaActual?.puntos?.length > 0
    }

    return {puntosRuta, marcadores, stops, horas, onChangeHoras, mostrandoRuta, setHoras}
}

export default useRuta