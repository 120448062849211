/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import ExGrid from "../../../shared/Grid/ExGrid";
import ExColumn from "../../../shared/Grid/ExColumn";
import FormInputNumber from "../../../shared/forms/FormFields/FormInputNumber";
import * as numeral from "numeral";
import DeleteButton from "../../../shared/Grid/Buttons/DeleteButton";
import _ from "lodash";
import {updateArrayAtIndex} from "../../../shared/arrayUtility";
import {estatus} from "../CajaConstantes";
import FormClipboardImage from "../../../shared/forms/FormFields/FormClipboardImage";
import FormTextArea from "../../../shared/forms/FormFields/FormTextArea";


const GastosPorComprobar = ({value:gastos, onChange, onValidate, formDisabled, ...props}) => {

    const handleComprobantesChange = (comprobantes, editIndex) => {
        onChange(gastos.map((gasto, index) => {
            //para el editing index regresa los nuevos comprobantes
            return editIndex === index ? {
                ...gasto,
                montoComprobado: _.sumBy(comprobantes, 'monto'),
                comprobantes
            } : gasto;
        }))
    }

    const onToggle = (gasto, index) => {
        onChange(
            updateArrayAtIndex(gastos,index, {...gasto, collapsed: !gasto.collapsed} )
        );
    }

    return (
        <>
            <div className={'gastos'}>
                <div className="grid-header">
                    GASTO
                </div>
                <div className="grid-header">
                    MONTO A COMPROBAR
                </div>
                <div className="grid-header"/>
                <div className="grid-header"/>
                {gastos?.map((gasto, index) => (
                    <React.Fragment key={index}>
                        <div className="cell-gasto">
                            {gasto?.comprobantes?.length > 0 ?
                                <i className={`pi pi-chevron-circle-${gasto.collapsed ? 'right' : 'up'}`}
                                   style={{cursor: 'pointer'}}
                                   onClick={() => onToggle(gasto, index)}/> :
                                <i className={`pi pi-circle`}
                                   style={{cursor: 'pointer'}}/>
                            }
                            <span className="bold">
                                  {gasto.nombre}
                            </span>
                        </div>
                        <div className="cell-gasto">
                            <span>
                                <span>{numeral(gasto.montoSolicitado).format('$0,0.00')}</span> /
                                <span className="bold">{numeral(gasto.montoComprobado).format('$0,0.00')}</span>
                            </span>
                        </div>
                        <div className="cell-gasto"/>
                        <div className="cell-gasto">

                                <ButtonIcon title={'Agregar'} iconName={'SiAddthis'} className={ (gasto.estatus!==estatus.porComprobar || formDisabled) ? 'oculto' : '' }
                                            onClick={() => handleComprobantesChange([...gasto.comprobantes, {}], index)}
                                />

                        </div>
                        <div style={{gridColumnEnd: 'span 4'}} className={`cell-gasto ${gasto.collapsed ? 'collapsed' : ''}`}>
                            <ExGrid visibleHeader={false}
                                    data={gasto?.comprobantes}
                                    onChange={(comprobante) => handleComprobantesChange(comprobante, index)}
                                    getEntityId={(row) => row.id ?? row._uniqueUid}
                                    onValidate={onValidate}
                                    formDisabled={formDisabled}
                            >
                                <ExColumn field="descripcion" width={"4fr"}  maxLength={4000} rows={3} required={true}
                                          cellRenderer={(props)=> <FormTextArea {...props} width={'100%'}/>}
                                />
                                <ExColumn field="monto" width={"2fr"} cellRenderer={FormInputNumber} maxFractionDigits={2} required={true}/>
                                <ExColumn field="comprobante" width={"100px"} cellRenderer={(props)=>
                                    <FormClipboardImage {...props} required={gasto.comprobanteRequerido} />
                                }/>
                                <ExColumn field="eliminar" width={"40px"} cellRenderer={DeleteButton}/>
                            </ExGrid>
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </>
    )
}

export default GastosPorComprobar;
