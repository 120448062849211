export class RequiredValidator {
    validate (value,field){
        let error = value == null;
        if (!error &&  (typeof value === 'string' || value instanceof String)  ){
            error =  value.trim().length === 0;
        }
        return {error, errorType: 'required', message: `${field} es requerido`};
    }
}


export class MinLengthValidator {
    constructor(min) {
        this.min = min;
    }

    validate (value,field){
        if (value == null)
            return {error:false}
        const error = value.length < this.min;
        return {error, errorType: 'minLengthValidator', message: `el campo ${field} debe tener al menos ${this.min} caracteres`};
    }
}

export class RequiredInputValidator {
    validate (value,field){
        let error = value == null || value.isEmpty === true;
        return {error, errorType: 'required', message: `${field} es requerido`};
    }
}


