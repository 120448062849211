/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable-next-line */
import React, {useEffect, useState} from "react";
import useApiViaje from "../../../api/operaciones/useApiViajes";
import {Accordion, AccordionTab} from "primereact/accordion";
import numeral from "numeral";
import {Button} from "primereact/button";
import alerts from "../../../shared/alerts";
import FieldTextArea from "../../../shared/controls/fieldTextArea";
import moment from "moment";
import {validacionTimbrarExpo, validacionTimbrarImpo} from "./viajeValidacionEsquema";
import constantes from "../../../catalogos/constantes";

const FacturacionLear = ({viajeId, soloLectura, viaje, onChange, onErrorFuel}) => {
    const api = useApiViaje();
    const [facturacion, setFacturacion] = useState([]);
    const [active] = useState(null);

    const cargar = async () => {
        if ((viaje?.comentarioMultifactura ?? '').length === 0) {
            const comentario = `Ruta: ${viaje?.codigoRuta} Tipo de Servicio: ${viaje?.esViajeRedondo ? 'Redondo' : 'Sencillo'} Origen y Destino: ${viaje?.ruta?.nombre} Fecha de Servicio:	${moment(viaje?.fecha).format('DD/MM/YYYY')} Carta Porte: ${viaje?.cartaPorte} Num. Load Tender: ${viaje?.idViaje} Hyp Code: Origen y Destino: ${viaje?.convenio?.nombre} Numero de Caja: ${viaje?.caja} FACTURAS QUE CORRESPONDAN`;
            onChange(comentario, 'comentarioMultifactura');
        }
        const result = await api.facturas(viajeId);
        setFacturacion(result.Value);
    };

    useEffect(() => {
        if ((viajeId ?? 0) > 0) {
            cargar().then();
        }
    }, []);

    const validaAddenda = (addenda) => {
        if (viaje.tieneAddenda) {
            var resultado = false;
            if (viaje.addendaId === constantes.addendas.soriana) {
                if (addenda.numeroProveedor === ""
                    || addenda.cantidadUnidadCompra === ""
                    || addenda.folioPedido === ""
                    || addenda.fechaEntregaMercancia === ""
                    || addenda.tienda === ""
                    || addenda.numeroEntregaMercancia === ""
                    || addenda.folioNotaEntrada === "") {
                    resultado = true;
                }
            } else if (viaje.addendaId === constantes.addendas.mabe) {
                if (addenda.ordenCompra === ""
                    || addenda.plantaEntrega === ""
                    || addenda.plantaEntregaCP === ""
                    || addenda.plantaEntregaCalle === ""
                    || addenda.plantaEntregaNoExterior === ""
                    || addenda.plantaEntregaNoInterior === ""
                    || addenda.claveDeRuta === "") {
                    resultado = true;
                }
            }
            return resultado;
        } else {
            return false;
        }
    }

    const facturar = async () => {
        if (viaje?.internacional) {
            var validaTimbrar = viaje?.impoExpo ? await validacionTimbrarExpo.isValid(viaje) : validacionTimbrarImpo.isValid(viaje);
            if (!validaTimbrar) {
                alerts.informacion('Es necesario capturar la fraccion arancelaria y el pedimento de los articulos en viajes Impo/Expo.');
                return;
            }
        }

        onErrorFuel(false);
        if (viaje.aplicaFuel) {
            if (viaje.fuel <= 0) {
                onErrorFuel(true);
                alerts.informacion('El campo Combustible(Fuel) es requerido para timbrar con el convenio seleccionado.');
                return;
            }
        }

        if (validaAddenda(viaje.addenda)) {
            alerts.aviso("Favor de capturar los valores de la Addenda.");
            return;
        }
        let sinExistencia = viaje.articulos.filter(function (a) {
            return !a.enSAT;
        });
        if (sinExistencia.length > 0) {
            alerts.informacion('Algunos articulos no estan registrados en el catalogo del SAT. No es posible timbrar. Favor de validar la información.');
            return;
        }

        if ((viaje?.comentarioMultifactura ?? '') === '') {
            alerts.aviso('Favor de capturar el comentario.');
            return;
        }

        await api.facturarMultiple(viajeId, viaje);
        await cargar().then();
    };

    const actualizar = (value, name) => {
        onChange(value, name);
    };

    return (
        <>
            {facturacion?.map((item, index) => {
                return (
                    <Accordion multiple activeIndex={active} key={index} style={{marginTop: "1%"}}>
                        <AccordionTab header={
                            <>
                                <span>{item?.razonSocial}</span>
                                <div style={{width: "-60px", float: "right"}} className="d-flex">
                                    <label
                                        style={{width: '200px'}}>{`Loaded Const: ${numeral((item.loadedCost)).format('$ 0,0.00')}`}</label>
                                    <label
                                        style={{width: '150px'}}>{`Stop Charges: ${numeral((item.stopCharges)).format('$ 0,0.00')}`}</label>
                                </div>
                            </>
                        }>
                            <table className="table table-borderless">
                                <thead className="thead-light">
                                <tr>
                                    <th className="center">NO. FACTURA</th>
                                    <th className="center">LOADED COST</th>
                                    <th className="center">STOP CHARGES</th>
                                    <th className="center" colSpan={4}>MENSAJE</th>
                                </tr>
                                <tr>
                                    <td className="input center">
                                        {item.folioFactura}
                                    </td>
                                    <td className="input right">
                                        {numeral((item.loadedCost)).format('$ 0,0.00')}
                                    </td>
                                    <td className="input right">
                                        {numeral((item.stopCharges)).format('$ 0,0.00')}
                                    </td>
                                    <td className="input" colSpan={4}>
                                        {item.errorFactura}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={7}>&nbsp;</td>
                                </tr>
                                <tr>
                                    <th className="center">CODIGO RUTA</th>
                                    <th className="center">HYP</th>
                                    <th className="center">LOC</th>
                                    <th className="center">PLANTA</th>
                                    <th className="center">ALLOCATION</th>
                                    <th className="center">LOADED COST</th>
                                    <th className="center">STOP CHARGES</th>
                                </tr>
                                </thead>
                                <tbody>
                                {item?.detalle?.map((row, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td className="input center">
                                                {row.codigoRuta}
                                            </td>
                                            <td className="input center">
                                                {row.hyp}
                                            </td>
                                            <td className="input center">
                                                {row.loc}
                                            </td>
                                            <td className="input">
                                                {row.planta}
                                            </td>
                                            <td className="input right">
                                                {numeral((row.allocation)).format('0,0.00 %')}
                                            </td>
                                            <td className="input right">
                                                {numeral((row.loadedCost)).format('$ 0,0.00')}
                                            </td>
                                            <td className="input right">
                                                {numeral((row.stopCharges)).format('$ 0,0.00')}
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </AccordionTab>
                    </Accordion>
                )
            })}
            {
                facturacion?.length > 0 &&
                <FieldTextArea name="comentarioMultifactura" label="Descripción Complemento Multifactura"
                               value={viaje?.comentarioMultifactura} colMd={12} disabled={soloLectura}
                               rows={10} className="mb-0" maxLength={1000} onChange={actualizar}/>
            }
            {
                !soloLectura && facturacion?.length > 0 &&
                <div className="form-footer">
                    <Button label="Facturar" type="button" onClick={facturar}/>
                </div>
            }
        </>
    );
};

export default FacturacionLear;
