/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import {Calendar} from "primereact/calendar";
import moment from "moment";
import {useReadOnly} from "../forms/ReadOnlyProvider";

const FieldCalendar = ({colMd, colSm, label, name, onChange, onBlur, error, errors, disabled, value, className,
                           showButtonBar, required,showTime, minDate, appendTo }) => {

    const {readOnly} = useReadOnly()??{};
    disabled = disabled ?? readOnly;

    const [selectedValue, setSelectedValue] = useState(null);
    const [visible, setVisible] = useState(true);

    function onValueChange(event) {
        setSelectedValue(event.value);
        if (onChange != null)
            onChange(event.value, name);
    }

    useEffect(() => {
        if (value !== selectedValue){
            setVisible(false);
            setTimeout(() => {
                setVisible(true);
                setSelectedValue(value==null? value : moment(value).toDate());
            },0);
        }
    }, [value]);



    return (
        <>
            {!visible &&
            <input type="text" className="form-control" id={name} />
            }
            {label && visible && showTime &&
            <div className={`form-group col-md-${colMd} col-sm-${colSm != null ? colSm : '12'}`}>
                {required ? '*':''}<label htmlFor={name}>{label}</label>
                <Calendar id={name}
                          value={selectedValue}
                          locale={'es'}
                          dateFormat="dd/M/yy"
                          monthNavigator
                          yearNavigator
                          yearRange="1930:2100"
                          showIcon
                          showTime ={showTime}
                          minDate={minDate}
                          className={`prime-form-control ${className??''} ${ (error || errors?.[name]) ? 'error' : ''}`}
                          onChange={onValueChange}
                          showButtonBar ={showButtonBar??true}
                          disabled={disabled }
                          onBlur={onBlur}/>
            </div>
            }
            {!label && visible  && showTime &&
            <Calendar id={name}
                      value={selectedValue}
                      locale={'es'}
                      dateFormat="dd/M/yy"
                      monthNavigator
                      yearNavigator
                      showButtonBar ={showButtonBar??true}
                      yearRange="1930:2100"
                      showIcon
                      minDate={minDate}
                      showTime ={showTime}
                      className={`prime-form-control ${className??''} ${ (error || errors?.[name]) ? 'error' : ''}`}
                      onChange={onValueChange}
                      disabled={disabled }
                      onBlur={onBlur}/>
            }

           {!visible &&
            <input type="text" className="form-control" id={name} />
            }
            {label && visible && !showTime &&
            <div className={`form-group col-md-${colMd} col-sm-${colSm != null ? colSm : '12'}`}>
                {required ? '*':''}<label htmlFor={name}>{label}</label>
                <Calendar id={name}
                          value={selectedValue}
                          locale={'es'}
                          dateFormat="dd/mm/yy"
                          showOnFocus = {false}
                          monthNavigator
                          yearNavigator
                          yearRange="1930:2100"
                          showIcon
                          showTime ={showTime} 
                          className={`prime-form-control ${className??''} ${ (error || errors?.[name]) ? 'error' : ''}`}
                          onChange={onValueChange}
                          showButtonBar ={showButtonBar??true}
                          disabled={disabled }
                          minDate={minDate}
                          appendTo={appendTo}
                          onBlur={onBlur}/>
            </div>
            }
            {!label && visible  && !showTime &&
            <Calendar id={name}
                      value={selectedValue}
                      locale={'es'}
                      showOnFocus = {false}
                      dateFormat="dd/mm/yy"
                      monthNavigator
                      yearNavigator
                      showButtonBar ={showButtonBar??true}
                      yearRange="1930:2100"
                      showIcon
                      minDate={minDate}
                      showTime ={showTime}
                      className={`prime-form-control ${className??''} ${ (error || errors?.[name]) ? 'error' : ''}`}
                      onChange={onValueChange}
                      disabled={disabled }
                      onBlur={onBlur}/>
            }
        </>
    )
};

export default FieldCalendar;
