import React, {useState} from "react"
import {nombreColorIndicador, nombreIndicador} from "../SeguimentoConstantes";
import {getColorIndicadores, getContrast} from "../SeguimientoMonitoreutility";
import SeguimientoIcon from "../../../../icons/SeguimientoYMonitoreo/SeguimientoIcons";
import {utcToString} from "../../../../shared/format";
import AtenderIncidenciaModal from "./AtenderIncidenciaModal";
import {useControlMap} from "../ControlMapProvider";

const Notificaciones = ({viajeId, bitacora, onChangeBitacora}) => {

    const [incidenciaActual, setIncidenciaActual] = useState(null)
    const {setNotificacionSeleccionada} = useControlMap()

    const handleAtender = async (incidencia) => {
        setIncidenciaActual(incidencia)
    }

    const handleSeleccion = async (item) => {
        setNotificacionSeleccionada(
            {
                lat: item.posicionGps.latitud,
                lng: item.posicionGps.longitud
            }
        )
    }

    return (
        <>
            <div className={'seg-info-viaje'}>
                <div className={'seg-notificaciones'}>
                    {bitacora && bitacora.map((item, index) =>
                        <React.Fragment key={index}>
                            <div>
                                <span className={getColorIndicadores({color: item.color})}>
                                    <SeguimientoIcon
                                        iconName={`${nombreIndicador[item.indicador]}${getContrast({color: item.color})}`}
                                        onClick={() => handleSeleccion(item)}
                                    />
                                </span>
                            </div>
                            <div>
                                    <small
                                        className="p-text-secondary"><b>{utcToString(item.fechaUtc, 'DD-MMM HH:mm')}</b></small>
                                    <small className="p-text-secondary"> {item.numeroEconomico} </small>
                            </div>
                            <div>
                                {!item.atendido && item.color !== nombreColorIndicador.verde && item.color !== nombreColorIndicador.gris &&
                                    <small className="p-text-secondary seg-seguimiento-boton"
                                           onClick={() => handleAtender(item)}
                                    >
                                        Atender
                                    </small>
                                }
                                {item.atendido &&
                                    <div>
                                        <div className="seg-indicador-atendido">
                                            <small className={'bold'}> Revisó: </small>
                                            <small className="p-text-secondary">
                                                {item.usuarioAtiende}
                                            </small>
                                            <small className="p-text-secondary seg-seguimiento-boton"
                                                   onClick={() => handleAtender(item)}
                                            >
                                                Ver
                                            </small>
                                        </div>
                                        <div className="seg-indicador-atendido">
                                            <small className={'bold'}> Fecha: </small>
                                            <small className="p-text-secondary">
                                                {utcToString(item.fechaAtiende)}
                                            </small>
                                        </div>
                                    </div>
                                }
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
            <AtenderIncidenciaModal setIncidenciaActual={setIncidenciaActual}
                                    incidenciaActual={incidenciaActual}
                                    viajeId={viajeId}
                                    onChangeBitacora={onChangeBitacora}/>
        </>
    )
}
export default Notificaciones;