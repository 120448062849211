/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {isTablet} from "react-device-detect";
import Header from "./header";
import useApiSource from "../api/source";
import useApiSeguridad from "../api/seguridad";
import {useEmpresa} from "./EmpresaProvider";
import AppRoutes from "./AppRoutes";
import {useAuth} from "../Seguridad/AuthProvider";
import SesionExpired from "../Seguridad/SesionExpired";
import { useLocation } from 'react-router-dom';
import {useApplication} from "./ApplicationProvider";

const MainBody = () => {
    const {isAuthenticated,loggedOut } = useAuth()
    const apiSource = useApiSource();
    const apiSeguridad= useApiSeguridad();
    const {enable:enableEmpresa, onChangeEmpresa} = useEmpresa();
    const {visibleHeader} =useApplication()

    const location = useLocation();

    useEffect(() => {
        enableEmpresa()
    }, [location.pathname]);


    const [empresaChanging, setEmpresaChanging] = useState(false);
    const {updateToken, refreshToken} = useAuth();

    const empresaChangingHandler = async (empresaId, empresa) => {
        setEmpresaChanging(true);
        apiSource.limpiar();
        const token = await apiSeguridad.cambiarEmpresa(empresaId);
        onChangeEmpresa(empresa);
        updateToken(token);
        setEmpresaChanging(false);
    }

    return (

        <main className={`main ${isTablet ? 'is-tablet' : ''}`} onMouseMove={refreshToken} onMouseDown={refreshToken} onKeyDown={refreshToken}>
            {isAuthenticated() && visibleHeader &&
                <Header onEmpresaChanged={empresaChangingHandler}/>
            }
            {!empresaChanging && !loggedOut &&
                <article>
                    <AppRoutes/>
                </article>
            }
            <SesionExpired  >
            </SesionExpired>
        </main>
    )
}

export default MainBody;
