/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import React, { useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TabPanel, TabView } from "primereact/tabview";
import useSessionState from "../../../hooks/useSessionState";
import CheckListEstatusUnidad from "./checklistEstatusUnidad";
import formReducer from "../../../shared/forms/formReducer";
import CheckUnidadValidacionEsquema from "./checkUnidadValidacionEsquema";
import FormHandler from "../../../shared/forms/formHandler";
import useApiCheckUnidad from "../../../api/operaciones/useApiCheckUnidad";
import InspeccionLlanta from "../secciones/inspeccionLlanta";
import produce from "immer";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import HeaderCheckListUnidad from "./headerCheckListUnidad"
import alerts from "../../../shared/alerts";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import CanvasDraw from "react-canvas-draw";
import { useRef } from "react";
import { Button } from "primereact/button";
import {useEmpresa} from "../../../layout/EmpresaProvider";

const CheckListDetalle = () => {
    let { id, esTemporal } = useParams();
    id = parseInt(id);

    const history = useNavigate();

    const api = useApiCheckUnidad();
    const [disabled, setDisabled] = useSessionState(false);
    const [firmar, setFirmar] = useState(false);
    const {disable:disableEmpresa} = useEmpresa();

    const firma = useRef(null);

    const defaultEntity = () => (
        {
            entity: { id: id, checkUnidad: { llantasUnidad: [] } }
        }
    );

    const [{ entity, errors }, dispatch] = useReducer(
        formReducer, {}, defaultEntity
    );

    useEffect(() => {
        cargarOrden();
        disableEmpresa();
    }, []);

    async function cargarOrden() {
        setDisabled(false);
        //Si no es temporal significa que ya fue llenado
        if (id !== 0 && esTemporal == null) {
            let res = { entity: await api.obtener(id) };
            dispatch({ type: "reset", getDefault: () => res ?? defaultEntity });
            setDisabled(true);
        }
    }

    const formHandler = new FormHandler({
        validationSchema: CheckUnidadValidacionEsquema.guardar,
        api: api,
        dispatch,
    });

    const actualizar = (value, propertyName) => {
        dispatch({ type: 'update', value: value, name: propertyName });

        if (propertyName === "llantasUnidad" || propertyName === "operador" && value != null) {

            const nuevo = produce(entity.checkUnidad ?? {}, draft => {
                draft[propertyName] = value
            });

            dispatch({ type: 'update', value: nuevo, name: "checkUnidad" });
        }
    };

    const submit = async () => {
        var validar = await formHandler.validate(entity);
        if (!validar?.result)
            return;

        // Quitamos las imagenes subidas previamente para no enviar tanta informacion.
        var newEntity = produce(entity, (draft) => {
            draft?.checkUnidad?.checkList.map(ch => (
                ch?.checkList?.map(chd => (
                    chd.fotoActual = null
                ))
            ));
        });

        const firmaBase64 = await firma.current.canvasContainer.childNodes[1].toDataURL();
        const firmaImagen = {
            file: firmaBase64,
            isBase64: true,
            url: firmaBase64,
            extension: 'png'
        };

        await api.guardarFirma(id, firmaImagen);

        await formHandler.save(newEntity);

        if (formHandler.valdation.result === true) {

            history("/controlDePatios");
        }
    };

    async function cargarInformacion(unidad) {
        if (unidad == null)
            return;

        const entityinformacion = await api.obtenerInformacion(unidad.id);
        entityinformacion.checkUnidad.unidad = unidad;
        actualizar(entityinformacion.checkUnidad, "checkUnidad");
    }
    async function back() {
        if (disabled) {
            history('/controlDePatios');
        }
        else {
            if (await alerts.preguntarSiDeseaSalirSinGuardar()) {
                history('/controlDePatios');
            }
        }
    };

    const solicitarFirma = async () => {
        var response = await formHandler.validate(entity);
        if (!response.result)
            return;

        setFirmar(true);
    };

    const renderFooterModal = () => {
        return (
            <div>
                <Button label="Borrar Firma" onClick={() => firma.current.clear()} />
                <Button label="Cancelar" onClick={() => setFirmar(false)} className="p-button-outlined" />
                <Button label="Guardar" onClick={submit} autoFocus />
            </div>
        );
    };

    return (
        <>
            <div style={{ marginTop: "30px" }} className="form-filtro form-row">
                <HeaderCheckListUnidad errors={errors?.checkUnidad} disabled={disabled}
                    cargarInformacion={cargarInformacion} entity={entity.checkUnidad}
                    onChange={actualizar} />
                <div style={{ marginTop: "-20px" }} className="field-iconos btn-group">
                    <div className="form-row">
                        <div className="col-6">
                            <ButtonIcon title='Regresar' iconName={'BiArrowBack'} onClick={back} />
                        </div>
                        <div className="col-6">
                            <ButtonIcon title='Guardar' disabled={disabled} iconName={'AiOutlineSave'} onClick={solicitarFirma} />
                        </div>
                    </div>
                </div>
            </div>

            <TabView activeIndex={0} className="main-tab-view">
                <TabPanel header="CHECKLIST ESTATUS DE UNIDAD">
                    <CheckListEstatusUnidad errors={errors?.checkUnidad} disable={disabled} onSubmit={submit}
                        onChange={actualizar} checkUnidad={entity.checkUnidad} checkId={id} urlFirma={entity?.checkUnidad?.urlFirma} />
                </TabPanel>
                <TabPanel header="INSPECCIÓN DE LLANTAS">
                    <InspeccionLlanta editable={!disabled} entity={entity.checkUnidad} actualizar={actualizar}
                        errors={errors?.checkUnidad}
                        tipoUnidadId={entity.unidad?.tipoUnidadId} />
                </TabPanel>

            </TabView>
            <Dialog header="Firma del operador" visible={firmar} style={{ with: '60vw' }}
                onHide={() => setFirmar(false)}
                footer={renderFooterModal()}>
                <div className="form-row">
                    <CanvasDraw ref={firma} brushRadius={1} hideGrid={true} />
                </div>
            </Dialog>
        </>
    )
};

export default CheckListDetalle;
