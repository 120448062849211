/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {Dialog} from "primereact/dialog";
import useApiDirecciones from "../api/catalogos/useApiDirecciones";

const DireccionModal = ({codigoPostal, visible, onHide}) => {
    const [localidades, setLocalidades] = useState([]);
    const api = useApiDirecciones();

    const cargar = async () => {
        const result = await api.localidaddes({codigo: codigoPostal});
        setLocalidades(result);
    };

    useEffect(() => {
        if (codigoPostal) {
            cargar().then();
        }
    }, [codigoPostal])

    return (
        <Dialog header={`Localidades CP: ${codigoPostal}`}
                visible={visible} onHide={onHide} style={{width: '50vw'}}>
            <table className="table table-borderless">
                <thead className="thead-light">
                <tr>
                    <th>Pais</th>
                    <th>Estado</th>
                    <th>Municipio</th>
                    <th>Localidad</th>
                </tr>
                </thead>
                <tbody>
                {localidades?.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item?.pais}</td>
                            <td>{item?.estado}</td>
                            <td>{item?.municipio}</td>
                            <td>{item?.localidad}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </Dialog>
    );
};
export default DireccionModal;
