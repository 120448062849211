import React from "react";
import {dateToString} from "../../../../shared/format";
import useServiceFactory from "../../../../api/serviceFactory";

const InformacionOperador = ({viaje}) => {
    const empresaId = viaje.empresaId;
    const operador = viaje.operador ?? {};
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URL});

    const download = async (e) => {
        e.preventDefault();
        const file = operador.licenciaFile
        let url = file.downloadUrl === undefined ? file.url : file.downloadUrl;
        await service.get(`archivos/descargar/${operador.empresaId}?url=${url}`,
            {
                showLoading: true,
                download: true,
                fileName: file.nombre
            })
    }

    return (
        <div className={'seg-info-viaje'}>
            <img
                src={`${process.env.REACT_APP_API_URL}/archivos/imagen/${empresaId}?url=${
                    operador.foto ?? ""
                }`}
                alt=""
                className="img-conductor"
                style={{alignSelf: 'center', marginTop: '5px', width:'70px'}}
            />
            <span className={'form-value'} style={{alignSelf: 'center'}}>
                {operador.nombre}
            </span>
            <span className={'gray-label'}>
                ANTIGUEDAD
            </span>
            <span className={'form-value'}>
                {operador.antiguedad} (fecha ingreso: {dateToString(operador.fechaIngreso)})
            </span>
            <span className={'gray-label'}>
                DIRECCION
            </span>
            <span className={'form-value'}>
                {operador.direccion}
            </span>
            <div className={'info-grid'}>
                <span className={'gray-label'}>
                    TELÉFONO
                </span>
                <span className={'gray-label'}>
                    IMMS
                </span>
                <span className={'gray-label'}>
                    LICENCIA
                </span>
                <span className={'form-value'}>
                    {operador.telefono}
                </span>
                <span className={'form-value'}>
                    {operador.imms}
                </span>
                <span className={'form-value'}>
                    <span className={'link'} onClick={download} >
                        {operador.licencia}
                    </span>
                </span>
            </div>
            <span className={'gray-label'}>
                CORREO
            </span>
            <span className={'form-value'}>
                {operador.email}
            </span>
            <span className={'gray-label'}>
                NOTAS DEL OPERADOR
            </span>
            <div className={'seg-notas-operador'}>
                <span className={'form-value'}>
                    Fecha y Usuario
                </span>
                <span className={'form-value seg-header'}>
                    Nota
                </span>
                {operador.notas && operador.notas.map((nota, index) =>
                    <React.Fragment key={index}>
                        <div className={'seg-notas'}>
                            <span className={'form-value'}>{nota.usuario}</span>
                            <span>{nota.fecha}</span>
                        </div>
                        <div className={'seg-notas'}>
                            {nota.nota}
                        </div>
                    </React.Fragment>
                )}
            </div>
        </div>
    )
}
export default InformacionOperador;