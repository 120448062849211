import React, { useState, useEffect } from "react";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import { useReadOnly } from "../forms/ReadOnlyProvider";


const FieldCheckBox = ({
    colMd, colSm, label, name, options, value, onChange, className, disabled, isRadioButton, errors,
    yesOption, noOption, flexDirection, width, index, floatposition, flexflow
}) => {
    const [checked, setChecked] = useState(value);
    const checkBoxId = name + (index != null ? index : '');

    const { readOnly } = useReadOnly() ?? {};
    disabled = disabled ?? readOnly;

    function onSelected(newValue) {
        setChecked(newValue);
        if (onChange != null) {
            onChange(newValue, name)
        }

    }

    useEffect(() => {
        setChecked(value);
    }, [value]);


    return (
        isRadioButton === true ?
            ///muestra el check box como radiobutton
            <div className={`field-radiobutton-list ${className ?? ''} ${(errors?.[name]) ? 'error' : ''}`}
                style={{ flexDirection: flexDirection }}>
                <div className={`p-field-radiobutton`}>
                    <RadioButton inputId={`${name}_yes`} name={yesOption}
                        disabled={disabled}
                        onChange={() => onSelected(true)}
                        checked={checked === true} />
                    <label htmlFor={`${name}_yes`}>{yesOption}</label>
                </div>
                <div className={`p-field-radiobutton`}>
                    <RadioButton inputId={`${name}_no`} name={noOption}
                        disabled={disabled}
                        onChange={() => onSelected(false)}
                        checked={checked === false} />
                    <label htmlFor={`${name}_no`}>{noOption}</label>
                </div>
            </div> :
            ///muestra el check box como check box
            <div style={{ flexDirection: flexDirection, width: width, float: floatposition, flexFlow: flexflow }}
                className={`field-checkbox ${className} col-md-${colMd ?? '4'} col-sm-${colSm != null ? colSm : '12'}`}>
                <div className={`p-field-radiobutton`} style={{ marginBottom: "0rem" }}>
                    <Checkbox inputId={checkBoxId} name={name} onChange={e => onSelected(e.checked)} checked={checked}
                        disabled={disabled} />
                    <label htmlFor={checkBoxId} className="p-checkbox-label">{label}</label>
                </div>
            </div>
    )
};

export default FieldCheckBox;

