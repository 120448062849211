import React from "react";
import useServiceFactory from "../../../../api/serviceFactory";

const InformacionUnidad = ({viaje}) => {
    const unidad = viaje.unidad ?? {};
    const caja = viaje.caja ?? {};
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URL});

    const download = async (file) => {
        // e.preventDefault();
        // const file = unidad.tarjetaCirculacionFile
        let url = file.url;
        await service.get(`archivos/descargar/${unidad.empresaId}?url=${url}`,
            {
                showLoading: true,
                download: true,
                fileName: file.nombre
            })
    }

    return (
        <div className={'seg-info-viaje'}>
            <span className={'gray-label'}>UNIDAD</span>
            <span className={'form-value'}>Marca: {unidad.marca}</span>
            <span className={'form-value'}>Modelo: {unidad.modelo}</span>
            <span className={'form-value'}>Año: {unidad.anio}</span>
            <span className={'form-value'}>Placas: {unidad.placas}</span>
            <span className={'form-value'}>Gps: {unidad.gps}</span>
            <span className={'form-value'}>Poliza:
                {unidad.polizaFile &&
                    <span className={'link'} onClick={()=>download(unidad.polizaFile)}>
                        {` ${unidad.poliza}`}
                     </span>
                }
            </span>
            <span className={'form-value'}>
                {unidad.tarjetaCirculacionFile &&
                    <span className={'link'} onClick={()=>download(unidad.tarjetaCirculacionFile)}>
                        Tarjeta Circulacion
                     </span>
                }
            </span>
            {(unidad.posicion?.latitud ?? 0) !== 0 &&
                <>
                    <span className={'form-value'}>
                        Coordenadas: {`${unidad.posicion.latitud},${unidad.posicion.longitud}`}
                    </span>
                    <a href={`https://www.google.com/maps?q=${unidad.posicion.latitud},${unidad.posicion.longitud}`}
                       target={'_blank'} rel="noreferrer">
                        Localizar en Google Maps
                    </a>
                </>
            }

            <span className={'gray-label'} style={{marginTop: '25px'}}>CAJA</span>
            <span className={'form-value'}>Marca: {caja.marca}</span>
            <span className={'form-value'}>Año: {caja.anio}</span>
            <span className={'form-value'}>Placas: {caja.placas}</span>
            <span className={'form-value'}>Gps: {caja.gps}</span>
            <span className={'form-value'}>Poliza: {caja.poliza}</span>
            <span className={'form-value'}>
                {caja.tarjetaCirculacionFile &&
                 <span className={'link'} onClick={()=>download(caja)}>
                    Tarjeta Circulacion
                 </span>
                }
            </span>
            {(caja.posicion?.latitud ?? 0) !== 0 &&
                <>
                    <span className={'form-value'}>
                        Coordenadas: {`${caja.posicion.latitud},${caja.posicion.longitud}`}
                    </span>
                    <a href={`https://www.google.com/maps?q=${caja.posicion.latitud},${caja.posicion.longitud}`}
                       target={'_blank'} rel="noreferrer">
                        Localizar en Google Maps
                    </a>
                </>
            }
        </div>
    )
}
export default InformacionUnidad;