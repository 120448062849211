import React, { useEffect, useState } from "react";
import './comercial.css';
import { TabPanel, TabView } from "primereact/tabview";
import Cotizaciones from "./cotizaciones/cotizaciones";
import Convenios from "./convenios/convenios";
import Expeditado from "./expeditado/expeditado";
import useSessionState from "../../hooks/useSessionState";
import constantes from "../../catalogos/constantes";
import useSeguridad from "../../Seguridad/useSeguridad";

const Comercial = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.comercial);
    const [activeIndex, setActiveIndex] = useSessionState('tabComercial', 0);
    const [permisos, setPermisos] = useState([]);

    useEffect(() => {
        async function cargarPermisos() {
            let permisos = JSON.parse(localStorage.getItem('pestañas')).map(function (obj) {
                return obj
            });

            setPermisos(permisos.filter(e => e.padreId === constantes.menus.comercial));

        }

        cargarPermisos();
    }, []);


    return (
        <>
            {(permisos.length > 0 || soloLectura) &&

                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="main-tab-view">
                    <TabPanel headerStyle={permisos.some(e => e.id === constantes.menus.cotizaciones) || soloLectura ? { display: "inline" } : { display: "none" }} header="Cotizaciones">
                        <Cotizaciones soloLectura={soloLectura} />
                    </TabPanel>
                    <TabPanel headerStyle={permisos.some(e => e.id === constantes.menus.convenios) || soloLectura ? { display: "inline" } : { display: "none" }} header="Convenios">
                        <Convenios soloLectura={soloLectura} />
                    </TabPanel>
                    <TabPanel headerStyle={permisos.some(e => e.id === constantes.menus.expeditados) || soloLectura ? { display: "inline" } : { display: "none" }} header="Expeditado">
                        <Expeditado soloLectura={soloLectura} />
                    </TabPanel>
                </TabView>
            }
        </>
    )
};

export default Comercial;