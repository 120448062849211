/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import { Button } from "primereact/button";
import Viajes from "./secciones/viajes";
import Movimientos from "./secciones/movimientos";
import Anticipos from "./secciones/anticipos";
import Vacaciones from "./secciones/vacaciones";
import Impuestos from "./secciones/impuestos";
import * as numeral from "numeral";
import FieldCheckBox from "../../shared/controls/fieldCheckBox";
import moment from "moment";
import FieldTextArea from "../../shared/controls/fieldTextArea";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import useApiLiquidacionReporte from "../../api/administracion/useApiLiquidacionReporte";
import Rendimiento from "./secciones/rendimiento";
import alerts from "../../shared/alerts";
import Gastos from "./secciones/gastos";
import Compensaciones from "./secciones/compensaciones";
import Descuentos from "./secciones/descuentos";
import ReadOnlyProvider from "../../shared/forms/ReadOnlyProvider";
import CajaAhorro from "./secciones/cajaahorro";
import PrestamoCajaAhorro from "./secciones/prestamoCajaAhorro";
import PensionAlimenticia from "./secciones/pensionAlimenticia"
const LiquidacionReporteDetalle = ({ liquidacionActual, esOperador, onClose, soloLectura }) => {
    const [liquidacion, setLiquidacion] = useState({});

    const api = useApiLiquidacionReporte();

    const errors = null;

    useEffect(() => {
        setLiquidacion(liquidacionActual);
    }, [liquidacionActual]);

    async function rollbackLiquidacion(liquidacionId) {
        if (liquidacion.permiteCancelar) {
            if (await alerts.preguntarSiNoAdvertencia('¿Desea cancelar la liquidación?')) {
                api.cancelarliquidacion(liquidacionId);
                onClose();
            }
        }
    }

    return (
        <>
            <div className="form-container full-height">
                <div className="adm-liquidacion-detalle">
                    <div className="form-row">
                        <h6 className="col-8"><b>Empleado: </b> {liquidacion?.empleado?.nombre}</h6>
                        <p><b>Fecha Ultima Liquidación: </b>
                            {moment(liquidacion?.empleado?.ultimaFechaLiquidacion).format('DD/MM/YYYY')}
                        </p>
                    </div>
                    <ReadOnlyProvider defaultReadOnly={soloLectura}>
                        <TabView activeIndex={0}>
                            <TabPanel header="VIAJES" headerClassName="custom-tab-header"
                                headerStyle={esOperador ? { display: "inline" } : { display: "none" }}
                                rightIcon={(errors?.datosGenerales || errors?.asignacion) ? 'pi pi-exclamation-circle' : ''}>
                                <Viajes viajes={liquidacion?.viajes}
                                    soloConsulta={true} />
                            </TabPanel>
                            <TabPanel header="RENDIMIENTO"  headerClassName="custom-tab-small-header"
                                headerStyle={esOperador ? { display: "inline" } : { display: "none" }}
                                rightIcon={(errors?.datosPersonales || errors?.familiares) ? 'pi pi-exclamation-circle' : ''}>
                                <Rendimiento liquidacion={liquidacion} />
                            </TabPanel>
                            <TabPanel header="MOVIMIENTOS" headerClassName="custom-tab-large-header">
                                <Movimientos liquidacion={liquidacion} />
                            </TabPanel>
                            <TabPanel header="COMPENSACIONES" headerClassName="custom-tab-large-header">
                                <Compensaciones liquidacion={liquidacion} compensaciones={liquidacion?.compensaciones} esOperador={liquidacion?.empleado?.esOperador} />
                            </TabPanel>
                            <TabPanel header="DESCUENTOS" headerClassName="custom-tab-small-header">
                                <Descuentos liquidacion={liquidacion} descuentos={liquidacion?.descuentos} esOperador={liquidacion?.empleado?.esOperador} />
                            </TabPanel>
                            <TabPanel header="GASTOS"  headerClassName="custom-tab-header">
                                <Gastos liquidacion={liquidacion} gastos={liquidacion?.gastos} gastosCajaChica={liquidacion?.gastosCajaChica} esOperador={liquidacion?.empleado?.esOperador} />
                            </TabPanel>
                            <TabPanel header="ANTICIPOS"  headerClassName="custom-tab-small-header"
                                headerStyle={esOperador ? { display: "inline" } : { display: "none" }}
                                rightIcon={errors?.seguridadSocial ? 'pi pi-exclamation-circle' : ''}>
                                <Anticipos anticipos={liquidacion?.anticipos}
                                    soloConsulta={true} />
                            </TabPanel>
                            <TabPanel header="VACACIONES" headerClassName="custom-tab-small-header">
                                <Vacaciones vacaciones={liquidacion?.vacaciones} />
                            </TabPanel>
                            <TabPanel header="IMPUESTOS"
                                rightIcon={errors?.antidoping || errors?.actasAdministrativas ? 'pi pi-exclamation-circle' : ''}>
                                <Impuestos impuestos={liquidacion?.impuestos} semanaImpuestos={liquidacion?.semanaImpuestos}
                                    soloConsulta={true} />
                            </TabPanel>
                            <TabPanel header="CAJA AHORRO"  headerClassName="custom-tab-large-header">
                                <CajaAhorro liquidacion={liquidacion} ahorros={liquidacion?.CajaAhorro} esOperador={liquidacion?.empleado?.esOperador} />
                            </TabPanel>
                            <TabPanel header="PRÉSTAMO AHORRO"  headerClassName="custom-tab-large-header">
                                <PrestamoCajaAhorro liquidacion={liquidacion} descuentos={liquidacion?.descuentosPrestamoCajaAhorro}  esOperador={liquidacion?.empleado?.esOperador} />
                            </TabPanel>
                            <TabPanel header="PENSIÓNALIMENTICIA" headerClassName="custom-tab-large-header">
                                <PensionAlimenticia
                                    liquidacion={liquidacion}
                                    pensionAlimenticia={liquidacion?.pensionAlimenticia}                                   
                                    esOperador={liquidacion?.empleado?.esOperador}
                                />
                            </TabPanel>
                        </TabView>
                        <div className="form-row adm-resumen-viajes">
                            <div className="complementos">
                                {esOperador && <table>
                                    <tr>
                                        <td>
                                            <FieldCheckBox name="complementarSueldoSemanal"
                                                value={liquidacion.complementarSueldoSemanal}
                                                disabled={true} />
                                        </td>
                                        <td><span>Complementar Sueldo Semanal</span></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <FieldCheckBox name="sumarSueldoSemanalTransfer"
                                                value={liquidacion.sumarSueldoSemanalTransfer}
                                                disabled={true} />
                                        </td>
                                        <td><span>Sumar Sueldo Semanal Transfer</span></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <FieldCheckBox name="comentarioObligatorio"
                                                value={liquidacion.comentarioObligatorio}
                                                disabled={true} />
                                        </td>
                                        <td><span>Liquidar sin rendimiento</span></td>
                                    </tr>
                                </table>
                                }
                                <br />
                                {esOperador && liquidacion.comentarioObligatorio &&
                                    <>
                                        <span> <b> Comentarios: </b> </span>
                                        <FieldTextArea name="comentarios" value={liquidacion.comentarios} rows={2}
                                            disabled={true} />
                                        <span> <b> Usuario: </b> </span>
                                    </>
                                }
                                <FieldTextBox name="usuarioNombre" colMd={12}
                                    value={liquidacion.usuarioNombre}
                                    disabled={true}
                                />
                            </div>
                            <div className="totales">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Sueldo Base</td>
                                            <td>{numeral(liquidacion.sueldoBase).format('$0,0.00')}</td>
                                        </tr>
                                        {esOperador && <tr>
                                            <td>Comisión</td>
                                            <td>{numeral(liquidacion.totalComision).format('$0,0.00')}</td>
                                        </tr>}
                                        <tr>
                                            <td>Préstamos</td>
                                            <td>{numeral(liquidacion.totalPrestamos).format('$0,0.00')}</td>
                                        </tr>
                                        <tr>
                                            <td>Caja Ahorro</td>
                                            <td>{numeral(liquidacion.totalCajaAhorro).format('$0,0.00')}</td>
                                        </tr>
                                        <tr>
                                            <td>Préstamo Caja Ahorro</td>
                                            <td>{numeral(liquidacion.totalPrestamoCajaAhorro).format('$0,0.00')}</td>
                                        </tr>
                                        {esOperador && <tr>
                                            <td>Anticipos</td>
                                            <td>{numeral(liquidacion.totalAnticipos).format('$0,0.00')}</td>
                                        </tr>}
                                        <tr>
                                            <td>Compensaciones</td>
                                            <td>{numeral(liquidacion.totalCompensaciones).format('$0,0.00')}</td>
                                        </tr>
                                        <tr>
                                            <td>Descuentos</td>
                                            <td>{numeral(liquidacion.totalDescuentos).format('$0,0.00')}</td>
                                        </tr>
                                        <tr>
                                            <td>Gastos</td>
                                            <td>{numeral(liquidacion.totalGastos + liquidacion.totalGastosCajaChica).format('$0,0.00')}</td>
                                        </tr>
                                        {esOperador && <tr>
                                            <td>Ajuste</td>
                                            <td>{numeral(liquidacion.totalAjuste).format('$0,0.00')}</td>
                                        </tr>}
                                        <tr>
                                            <td>Bonos</td>
                                            <td>{numeral(liquidacion.totalBonos).format('$0,0.00')}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Vacaciones</td>
                                            <td>{numeral(liquidacion.totalVacaciones).format("$0,0.00")}</td>
                                        </tr>
                                        <tr>
                                            <td>Vacaciones Trabajadas</td>
                                            <td>{numeral(liquidacion.totalVacacionesTrabajadas).format("$0,0.00")}</td>
                                        </tr>
                                        <tr>
                                            <td>Vacaciones No Trabajadas</td>
                                            <td>{numeral(liquidacion.totalVacacionesNoTrabajadas).format("$0,0.00")}</td>
                                        </tr>                                       
                                        <tr>
                                            <td>Prima Vacacional</td>
                                            <td>{numeral(liquidacion.totalPrimaVacacional).format("$0,0.00")}</td>
                                        </tr>                                        
                                        <tr>
                                            <td>Otros</td>
                                            <td>{numeral(liquidacion.totalOtros).format('$0,0.00')}</td>
                                        </tr>
                                        <tr>
                                            <td>Tiempo Extra</td>
                                            <td>{numeral(liquidacion.totalTiempoExtra).format('$0,0.00')}</td>
                                        </tr>
                                        <tr>
                                            <td>Impuestos</td>
                                            <td>{numeral(liquidacion.totalImpuestos).format('$0,0.00')}</td>
                                        </tr>
                                        {esOperador &&
                                            <tr>
                                                <td>Pension Alimenticia</td>
                                                <td>{numeral(liquidacion.totalPensionAlimenticia).format('$0,0.00')}</td>
                                            </tr>
                                        }
                                        <tr>
                                            <td>TOTAL</td>
                                            <td>{numeral(liquidacion.total).format('$0,0.00')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ReadOnlyProvider>
                </div>
            </div>
            <div className="form-footer">
                <Button label="Salir" type="button" className="p-button-outlined" onClick={onClose} />
                {liquidacion.permiteCancelar && !liquidacion.cerrada && !soloLectura &&
                    <Button label="Cancelar Liquidación" className="p-button-outlined p-button-danger" type="button"
                        onClick={() => rollbackLiquidacion(liquidacion.id)} />
                }
            </div>
        </>
    )
};

export default LiquidacionReporteDetalle;
