/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'

const ExCell = ({
                    cellRenderer,
                    field,
                    row,
                    onRowChanged,
                    data,
                    rowIndex,
                    onChange,
                    formDisabled,
                    onValidate,
                    visible,
                    ...props
                }) => {

    const onCellChanged = (value) => {
        onChange(field, value, row, rowIndex);
    }

    const handleRowChanged = (newValues) => {
        onRowChanged(newValues, row, rowIndex);
    }

    return (
        <>
            {visible !== false &&
                <>{
                    cellRenderer == null ?
                        row[field] :
                        cellRenderer({
                            ...props,
                            colDef: props,
                            row,
                            value: row[field],
                            field,
                            formDisabled: formDisabled,
                            onChange: (value) => onCellChanged(value),
                            onRowChanged: handleRowChanged,
                            onValidate: (error) => onValidate(field, error)
                        })
                }
                </>
            }
        </>
    )
}

export default ExCell;
