import useServiceFactory from "../serviceFactory";

const apiUrl = `/cargas`;

const useApiDieselCarga = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_DIESEL_API_URL });
    const serviceCombustion = useServiceFactory({ uri: process.env.REACT_APP_API_URL });

    return {
        filtrar: async (params) => service.get(`${apiUrl}`, { params, showLoading: true }),
        ultimaCarga: async (unidadId) => service.get(`${apiUrl}/ultima/${unidadId}`, {}),
        guardar: async (params) => service.post(`${apiUrl}`, params),
        determinaPrecio: async (params) => service.get(`${apiUrl}/determinaPrecio`, { params }),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, { showLoading: true }),
        eliminar: async (id) => service.delete(`${apiUrl}/${id}`),
        excel: async (params) =>
            service.get(`${apiUrl}/excel`, {
                params,
                showLoading: true,
                download: true,
                fileName: `Cargas.xlsx`,
            }),
        getFuel: async (id) => service.get(`${apiUrl}/unidad/${id}`),
        obtenerTanques: async (combustibleId, empresaId, ubicacionId) =>
            serviceCombustion.get(
                `/source/diesel/tanques?combustibleId=${combustibleId}&empresaId=${empresaId}&ubicacionId=${ubicacionId}`,
                {}
            ),
        guardarCargasPorArchivo: async (params) => service.post(`${apiUrl}/guardarCargasPorArchivo`, params),
    };
};

export default useApiDieselCarga;
