import * as yup from "yup";

const parametrosValidacionEsquema = yup.object().shape({  
    parametrosPorkm: yup.array()
    .of(
        yup.object().shape({
          
            nomina: yup.mixed().required(), 
            precioKm: yup.mixed().required(),              
            mantenimiento: yup.mixed().required(),              
            gastosAdmin: yup.mixed().required(),              
                       
        })
    ),
    parametrosPorDia: yup.array()
    .of(
        yup.object().shape({          
            costoDiario: yup.mixed().required(), 
            seguroDiario: yup.mixed().required(),                                                           
        })
    ),
    caja48Ps: yup.object({
        externaLargo: yup.string().required(),      
        externaAncho: yup.string().required(),
        externaAlto: yup.string().required(),
        internaLargo: yup.string().required(),
        internaAncho: yup.string().required(),
        internaAlto: yup.string().required(),
    }),
    caja53Ps: yup.object({
        externaLargo: yup.string().required(),      
        externaAncho: yup.string().required(),
        externaAlto: yup.string().required(),
        internaLargo: yup.string().required(),
        internaAncho: yup.string().required(),
        internaAlto: yup.string().required(),
    }),
    rendimientoDieselPorKmDefault: yup.mixed().required(),
    rendimientoDieselPorKm: yup.mixed().required(),
    costoDiesel: yup.mixed().required(),
    precioPorKilometro: yup.mixed().required(),

    
});

export default parametrosValidacionEsquema;
