/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import ExColumn from "../../../shared/Grid/ExColumn";
import ExGrid from "../../../shared/Grid/ExGrid";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import { AgCurrencyFormatter, AgDateFormatter, AgNumberFormatter } from "../../../shared/ag-grid-helper/GridFormatters";
import FormCheckBox from "../../../shared/forms/FormFields/FormCheckBox";

const Cargas = ({ parent: rendimiento, actualizarlitros }) => {
    const op = useRef(null);
    const [cargas, SetCargas] = useState(false);

    useEffect(() => {
        SetCargas([]);
    }, []);

    useEffect(() => {
        SetCargas(rendimiento?.cargas ?? []);
    }, [rendimiento?.cargas]);

    const seleccionCarga = (newValues, row, index) => {
        const newRow = { ...row, ...newValues };
        const newData = cargas.map((e, rowIndex) => {
            return e.id === row.id ? newRow : e;
        });
        actualizarlitros(newData);
        SetCargas(newData);
    };

    return (
        <>
            <span>Litros Consumidos</span>
            <Button label="Ver" className="p-button-link" onClick={(e) => op.current.toggle(e)} />

            <OverlayPanel
                ref={op}
                showCloseIcon
                id="overlay_panel"
                style={{ width: "800px" }}
                className="overlaypanel-demo"
            >
                <ExGrid data={cargas}>
                    <ExColumn
                        field={"seleccionado"}
                        cellRenderer={({ row, onRowChanged, ...props }) => (
                            <FormCheckBox
                                {...props}
                                disabled={row.id === 0}
                                onChange={(e) =>
                                    seleccionCarga({ seleccionado: row.seleccionado ? false : true }, row, e)
                                }
                            />
                        )}
                    />

                    <ExColumn title={"Fecha"} field="fechaRegistro" width={"2fr"} cellRenderer={AgDateFormatter} />

                    <ExColumn title={"Folio"} field="folio" width={"1fr"} />
                    <ExColumn title={"Proveedor"} field="proveedor" width={"2fr"} />
                    <ExColumn title={"Operador"} field="operador" width={"3fr"} />
                    <ExColumn title={"Litros"} field="litros" width={"1fr"} cellRenderer={AgNumberFormatter} />
                    <ExColumn
                        title={"Precio"}
                        field="precio"
                        width={"2fr"}
                        precision={4}
                        cellRenderer={AgCurrencyFormatter}
                    />
                </ExGrid>
            </OverlayPanel>
        </>
    );
};

export default Cargas;
