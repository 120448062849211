import React from "react";
import { TabPanel, TabView } from "primereact/tabview";
import useSessionState from "../../hooks/useSessionState";
import constantes from "../../catalogos/constantes";
import LiquidacionReporte from "./liquidacionReporte";
import useSeguridad from "../../Seguridad/useSeguridad";

const LiquidacionReportesSecciones = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.reporteliquidacion);
    const [activeIndex, setActiveIndex] = useSessionState('tabLiquidacionReporte', 0);

    return (
        <>
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="main-tab-view">
                <TabPanel headerStyle={(seguridad.tieneAcceso(constantes.menus.liquidacionOperativa) || soloLectura) ? { display: "inline" } : { display: "none" }} header="Liquidación Operativa">
                    <LiquidacionReporte esquemaPago={constantes.esquemaPago.liquidacion} soloLectura={soloLectura} consulta={'empleadosOperativos'} />
                </TabPanel>
                <TabPanel headerStyle={seguridad.tieneAcceso(constantes.menus.liquidacionOficina) ? { display: "inline" } : { display: "none" }} header="Liquidación Staff">
                    <LiquidacionReporte esquemaPago={constantes.esquemaPago.staff} soloLectura={soloLectura} consulta={'empleadosStaff'} />
                </TabPanel>
                <TabPanel headerStyle={seguridad.tieneAcceso(constantes.menus.liquidacionAdministrativa) ? { display: "inline" } : { display: "none" }} header="Liquidación Oficina">
                    <LiquidacionReporte esquemaPago={constantes.esquemaPago.oficina} soloLectura={soloLectura}  consulta={'empleadosAdministrativos'}/>
                </TabPanel>
            </TabView>
        </>
    )
};

export default LiquidacionReportesSecciones;
