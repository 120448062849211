import React, { useEffect, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import Seguimiento from "./seguimiento/seguimiento";
import useSessionState from "../../../hooks/useSessionState";
// import Monitoreo from "./monitoreo/monitoreo";
import constantes from "../../../catalogos/constantes";
import useSeguridad from "../../../Seguridad/useSeguridad";

const SeguiminetoMonitoreo = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.seguimientomonitoreo);
    const [activeIndex, setActiveIndex] = useSessionState("tabSeguimiento", 0);
    const [, setPermisos] = useState([]);

    useEffect(() => {
        async function cargarPermisos() {
            let permisos = JSON.parse(localStorage.getItem("pestañas")).map(function (obj) {
                return obj;
            });
            setPermisos(permisos.filter((e) => e.padreId === constantes.menus.seguimientomonitoreo));
        }
        cargarPermisos();
    }, []);

    return (
        <>
            {true > 0 && (
                <TabView
                    activeIndex={activeIndex}
                    onTabChange={(e) => setActiveIndex(e.index)}
                    className="main-tab-view"
                >
                    <TabPanel header="">
                        <Seguimiento soloLectura={soloLectura} />
                    </TabPanel>
                    {/* <TabPanel header="Monitoreo"
                    >
                        <Monitoreo soloLectura={soloLectura} />
                    </TabPanel> */}
                </TabView>
            )}
        </>
    );
};

export default SeguiminetoMonitoreo;