/* eslint-disable react-hooks/exhaustive-deps */
import React  from 'react'
import {InputText} from "primereact/inputtext";
import useInputValidation from "./useInputValidation";

const FormTime = ({value, parent, onChange, field, keyFilter, formDisabled, disabled, maxLength, inputWidth, required, validators, onValidate}) => {

    const {validationErrorCss} = useInputValidation({
            value,
            field,
            required,
            validators,
            onValidate,
            parent
        });

    return (
        <>
            <InputText
                id={field}
                keyfilter={keyFilter}
                style={{width: inputWidth ?? '100%'}}
                className={`${validationErrorCss}`}
                onChange={(event) => onChange(event.target.value)}
                value={value ?? ''}
                disabled={disabled ?? formDisabled}
                maxLength={maxLength ?? 50}
                type='time'
            />
        </>
    )
}

export default FormTime;
