import {NuevaVentanaIcon} from "../../../../icons/SeguimientoYMonitoreo/SeguimientoIcons";
import React from "react";
import {useSeguimientoMonitoreo} from "../SeguimientoMonitoreoProvider";
import {useIndicadores} from "./IndicadoresProvider";

const MenuLocalizarUnidades = () => {

    const {
        overlayClickRef,
        viajeActual
    } = useIndicadores()

    const {agregarViaje, getUnidadesVisibles} = useSeguimientoMonitoreo();

    const handleAgregarViaje = () => {
        agregarViaje(viajeActual)
        overlayClickRef.current.hide()
    }

    const handleAbrirViajesSeleccionados = () => {
        const unidades = getUnidadesVisibles()
        window.open("/seguimiento_y_monitoreo_viajes?unidades=" + unidades.join(), "_blank", `resizable=yes,width=${window.screen.availWidth},height=${window.screen.availHeight}`);
    }

    return (
        <div className={'seg-localizar-mapa'}>
            <span className={'bold'}>
                Seguir Unidad en Mapa
            </span>
            <span className={'seg-link'} onClick={handleAgregarViaje}>
                <NuevaVentanaIcon/> Abrir en nueva ventana
            </span>
            <span className={'seg-link'} onClick={handleAbrirViajesSeleccionados}>
                <NuevaVentanaIcon/> Abrir seleccionados en nueva ventana
            </span>
        </div>
    )
}
export default MenuLocalizarUnidades;