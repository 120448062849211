import React from "react"; 
import FieldCalendarRange from "../../shared/controls/fieldCalendarRange";   

const FiltroTipoDeCambio = ({filtro, onChange}) => {  
    return ( 
        <> 
            <FieldCalendarRange name="fechas" label="Fechas" colMd={2}  
                                value={filtro.fechas} onChange={onChange}/>  
        </>
    ) 
};

export default FiltroTipoDeCambio;