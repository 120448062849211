/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import moment from "moment";
import {Button} from "primereact/button";

const DIRECTIONS = {
    left: 1,
    right: 2
}

const WeekPicker = ({date, maxDate, onMoveDays, weeks, inverted, showGoToday, onClickToday}) => {
    const disabledLeft = maxDate != null && moment(date).isSameOrAfter(maxDate, "days");
    const multiplier = (inverted ? -1 : 1) * (weeks ?? 1);

    const handleMoveDays = (days, direction) => {
        if (disabledLeft && direction === DIRECTIONS.left)
            return;

        onMoveDays(days);
    }

    return (
        <div style={{display:"flex", alignItems:"center", justifyContent:'space-between', width: showGoToday ? '215px' :  '180px'}}>
            <i className="pi pi-angle-double-left" style={{cursor: disabledLeft ? '' : 'pointer'}} onClick={()=>handleMoveDays(-6 * multiplier, DIRECTIONS.left)} />
            <i className="pi pi-angle-left" style={{cursor: disabledLeft ? '' : 'pointer'}} onClick={()=>handleMoveDays(-1 * multiplier, DIRECTIONS.left)} />
            <span>
                {`${moment(date).format('DD MMM')} - ${moment(date).add(6  * multiplier ,'days').format('DD MMM')}`}
            </span>
            {showGoToday &&
                <span>
                     <Button label="Hoy" className="p-button-link" onClick={onClickToday} />
                </span>
            }

            <i className="pi pi-angle-right" style={{cursor:'pointer'}} onClick={()=>handleMoveDays(multiplier, DIRECTIONS.right)}/>
            <i className="pi pi-angle-double-right" style={{cursor: 'pointer'}} onClick={()=>handleMoveDays(6 * multiplier, DIRECTIONS.right)} />

        </div>
    )
}

export default WeekPicker;
