import useServiceFactory from "../serviceFactory";
const apiUrl = `/catalogos/empresas`;

const useApiEmpresas = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async () => service.get(`${apiUrl}`, {}),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, {showLoading: true}),
        guardar: async (params) => service.post(`${apiUrl}`, params)
    }
};

export default useApiEmpresas;
