import {Button} from "primereact/button";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import React from "react";
import FieldTextArea from "../../../shared/controls/fieldTextArea";

const SalidasDetalleCancelacion = ({close, entity}) => {
    return (
        <>
            <div className="form-container full-height">
                <div className="form-row">
                    <h5 className="col-12">Pase de salida cancelado</h5>
                    <div className="form-row">     
                        
                    <div className="form-group col-md-12 mt-2">
                            <FieldTextBox name="usuario" label="Usuario:" colLg={1} colMd={52} width="15vw"
                                    value={entity.nombreUsuario} disabled/>
                    </div>
                    <div className="form-group col-md-12 mt-2">
                            <FieldTextArea name="comentarios" label="Motivo de cancelación:" colMd={52} rows={11} 
                                    value={entity.comentarios} disabled/> 
                    </div></div>
                </div> 
            </div>
            <div className="form-footer">
                <Button label='Salir' className="p-button-outlined" type="button" onClick={close}/> 
            </div>

        </>
    );
};


export default SalidasDetalleCancelacion;
