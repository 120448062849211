import React, { useEffect, useState, useReducer } from "react";
import FieldTextArea from "../../../shared/controls/fieldTextArea";
import FieldCalendar from "../../../shared/controls/fieldCalendar";
import { Button } from "primereact/button";
import OrdenTrabajoValidacionEsquema from "./ordenTrabajoValidacionEsquema";
import { Dialog } from "primereact/dialog";
import YupValidator from "../../../shared/YupValidator";
import moment from "moment";
import formReducer from "../../../shared/forms/formReducer";
import UseApiOrdenServicio from "../../../api/taller/useApiOrdenServicio";
import { useNavigate } from "react-router-dom";
import FieldDropDown from "../../../shared/controls/fieldDropDown";



const CancelacionOrden = ({ esVisibleModal,ordenTrabajoId,setVisible}) => {
   
  const [errorsCancelacion, setErrorsCancelacion] = useState({});
  const validador = new YupValidator(OrdenTrabajoValidacionEsquema.cancelacion);
  const history = useNavigate();
  const apiOrdenServicio = UseApiOrdenServicio();


  useEffect(() => {
    setErrorsCancelacion(false);
  }, []);


  const defaultCancelacion = () => ({
    entity: {     
      id: 0,
      fechaCancelacion :moment().startOf('day').toDate()
    },
  });

  const [{ entity }, dispatch] = useReducer(
    formReducer,
    {},
    defaultCancelacion
  );


  const cerrarModal = ()=>{
    setVisible();
  }

  

  const guardarCancelacion = async () => {
    

     if ((await validador.validate(entity)) === false) {
        setErrorsCancelacion(validador.errors);
        return;
      }

       await apiOrdenServicio.cancelar(ordenTrabajoId,entity);
       history("/taller/mantenimiento");
    }

 

  async function actualizar(value, propertyName) {
    dispatch({ type: "update", value: value, name: propertyName });
  }


    return (
        <Dialog
        header="¿Deseas cancelar la solicitud de servicio?"
        visible={esVisibleModal}
        style={{ width: "30vw" }}
        onHide={() => cerrarModal()}
      >
        <div className="form-row">
        <FieldCalendar
                    name="fechaCancelacion"
                    label="Fecha"
                    className="small"
                    required
                    showButtonBar={false}
                    disabled={true}
                    value={entity.fechaCancelacion}
                    colMd={12}
                    errors={errorsCancelacion}
                    showTime={true}
                    onChange={actualizar}
            />

        <FieldDropDown
            name="motivoCancelacion"
            label="Motivo de Cancelacion"
            colMd={12}
            required
            source="general/MotivoCancelacion"
            errors={errorsCancelacion}
            scrollHeight={150}
            filter
            value={entity.motivoCancelacion}
            onChange={actualizar}
          />
          <FieldTextArea
           required
            name="comentarios"
            label="Comentarios"
            colMd={12}
            rows={6}
            errors={errorsCancelacion}
            value={entity.comentarios}
            onChange={actualizar}
          />
        </div>
        <div className="">
          <Button
            label="Confirmar"
            className={"float-right"}
            type="button"
            onClick={guardarCancelacion}
          />
        </div>
      </Dialog>
    )
};

export default CancelacionOrden;
