/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import ButtonIcon from "../../controls/ButtonIcon";




const Buttons = ({filter, style, clear, onFilter, onClear, children}) => {

    const allChildren = React.Children.toArray(children);

    return (
        <div className="iconos" style={{
            position: 'absolute',
            top: 0,
            right: 0,
            display:'flex',
            ...style
        }}>
            <>
                {allChildren.map((button, index) => (
                    <React.Fragment key={index}>
                        {React.cloneElement(button, {})}
                    </React.Fragment>
                ))}
                {clear &&
                    <ButtonIcon title={"Borrar Filtros"} onClick={onClear} iconName={'FcClearFilters'}/>
                }
                {filter &&
                    <ButtonIcon title="Buscar" onClick={onFilter} className={'action'} iconName={'BsSearch'}/>
                }
            </>
        </div>
    )
}

export default Buttons;
