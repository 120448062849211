/* eslint-disable react-hooks/exhaustive-deps */
import {Button} from "primereact/button";
import React, {useEffect, useState} from "react";
import _ from "lodash";
import {pendientes} from "./OrderToCashConstants";
import {Dialog} from "primereact/dialog";
import useApiOrderToCash from "../../api/operaciones/useApiOrderToCash";
import ExGrid from "../../shared/Grid/ExGrid";
import ExColumn from "../../shared/Grid/ExColumn";
import FormTextBox from "../../shared/forms/FormFields/FormTextBox";
import {useLoadingDispatch} from "../../layout/show-loading-context";

const LigarSaas = ({viajes, facturaViajeaLigar, onHide, onRegistered}) => {
    const [viajesSeleccionados, setViajesSeleccionados] =  useState([]);
    const [form, setForm] = useState({submitted:false, valid:false});
    const mostrarViajes = facturaViajeaLigar != null;
    const api= useApiOrderToCash();
    const dispatch = useLoadingDispatch();
    useEffect(()=>{
        if (mostrarViajes) {
            viajesSeleccionado();
        }
        else {
            setViajesSeleccionados([]);
        }
    },[mostrarViajes, viajes])

    const viajesSeleccionado = () => {
        const pendiente =  facturaViajeaLigar.pendiente ?? (viajes.length > 0 ? viajes[0].pendiente : null);
        const orden = pendiente === pendientes.timbradoCartaPorte ? 'orden' : 'cartaPorte';
        let nuevosViajes = _.sortBy(viajes, [orden]);
        setViajesSeleccionados(nuevosViajes);
    };

    const ligarSaas = async () => {
        if (!form.valid){
            dispatch({type: 'toast', message:'Debe agregar los datos correspondientes para ligar la factura.', severity:'error'});
            return;
        }
        await api.ligarFacturas(viajesSeleccionados);
        onRegistered();
    };

    const renderFooterFacturaSaas = () => {
        return (
            <div>
                <Button label="Registrar" icon="pi pi-check" onClick={ligarSaas} autoFocus/>
            </div>
        );
    };

    const onValidate = (error)=>{
        setForm({submitted: true, valid:!error});
    }

    return (
        <>
            <Dialog header="Ligar Factura Saas" visible={mostrarViajes} style={{width: '800px'}}
                    footer={renderFooterFacturaSaas('displayBasic')} onHide={onHide}>
                <ExGrid
                    headerClass="header-class"
                    data={viajesSeleccionados}
                    onChange={setViajesSeleccionados}
                    getEntityId={(v)=>v.viajeId}
                    style={{maxHeight:'50vh'}}
                    onValidate={(valid)=> onValidate(valid)}
                >
                    <ExColumn field={'cliente'} title={'CLIENTE'} width={'3fr'}></ExColumn>
                    <ExColumn field={'cartaPorte'} title={'CARTA PORTE'} width={'1fr'}></ExColumn>
                    <ExColumn  field={'facturaId'} title={'No. PEDIDO'} 
                               required cellRenderer={FormTextBox} ></ExColumn>
                     <ExColumn  field={'numeroFactura'} title={'NO. FACTURA'} 
                               required cellRenderer={FormTextBox} ></ExColumn>
                </ExGrid>
             
            </Dialog>
        </>
    )
}

export default LigarSaas;
