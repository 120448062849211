import React, {useState} from "react";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import produce from "immer";
import FieldTextArea from "../../../shared/controls/fieldTextArea";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import FieldCamera from "../../../shared/controls/fieldCamera";
import {Button} from "primereact/button";
import YupValidator from "../../../shared/YupValidator";
import detalleDanioValidacionEsquema from "./detalleDanioValidacionSquema";

const DetalleDanio = ({onSave, close, averia}) => {
    const [errors, setErrors] = useState(null);
    const [value, setValue] = useState(averia);
    const validator = new YupValidator(detalleDanioValidacionEsquema);

    async function actualizar(itemValue, itemName) {
        const nuevoValor = produce(value ?? {}, (draft) => {
            draft[itemName] = itemValue;
        });
        setValue(nuevoValor);
        if (errors != null) {
            await validator.validate(nuevoValor);
            setErrors(validator.errors);
        }
    }

    async function guardar() {
        if (await validator.validate(value) === true) {
            onSave({
                ...value,
                fecha: new Date()
            });
        }
        setErrors(validator.errors);
    }

    return (
        <>
            <div className="form-container full-height">
                <div className="form-row">
                    <FieldTextBox name="consecutivo" label="ID" colMd={12} errors={errors} disabled={true}
                                  value={value.consecutivo} onChange={actualizar}/>
                    <FieldDropDown required name="tipoAveria" label="Tipo Daño" source="general/TipoAveriaUnidad" disabled={!value.editable}
                                   colMd={12} errors={errors}
                                   value={value.tipoAveria} onChange={actualizar}/>
                    <FieldTextArea name="descripcion" label="Descripción" colMd={12} errors={errors} rows={5} required disabled={!value.editable}
                                   value={value.descripcion} onChange={actualizar}/>
                </div>
                <FieldCamera value={value.foto} name="foto" useCamera={true} onChange={actualizar} errors={errors} disabled={!value.editable}/>
            </div>
            <div className="form-footer">
                <Button label='Cancelar' className="p-button-outlined" type="button" onClick={close}/>
                {value.editable === true &&
                < Button label='Guardar' type="button" onClick={guardar}/>
                }
            </div>
        </>

    );
};

export default DetalleDanio;
