import useServiceFactory from "../serviceFactory";

const apiUrl = `/reportes/FacturacionPorUnidad`;

const useVentasPorUnidadApi = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filter: async (params) => service.get(`${apiUrl}`, { params, showLoading:true }),
        export: async (params) => service.get(`${apiUrl}/excel`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `FacturaciónPorUnidad.xlsx`
            })
    }
};

export default useVentasPorUnidadApi;
