/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import './layout/layout.css';
import './shared/component-overloads.css'
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'primeflex/primeflex.css';
import Navigation from "./layout/navigation";
import {
    BrowserRouter as Router
} from "react-router-dom";
import IntlWrapper from "./shared/IntlWrapper";

import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faArrowCircleLeft, faArrowCircleRight, faCogs, faTools, faChartLine, faBookReader, faDatabase, faTruck
} from '@fortawesome/free-solid-svg-icons';
import Loading from "./shared/loading";
import {MainLoadingProvider} from "./layout/show-loading-context";
import 'moment/locale/es'  // without this line it didn't work
import moment from "moment";
import {addLocale} from 'primereact/api';
import datePickerConfig from "./shared/controls/calendarConfig.es";
import MainBody from "./layout/MainBody";
import EmpresaProvider from "./layout/EmpresaProvider";
import {createSignalRContext} from "react-signalr/signalr";
import {SnackbarProvider} from "notistack";
import {HttpTransportType} from "@microsoft/signalr";
import {closeSnackbar} from 'notistack'
import ButtonIcon from "./shared/controls/ButtonIcon";
import {OfflineApiProvider} from "./shared/offlineApi/OfflineApiProvider";
import AuthProvider, {useAuth} from "./Seguridad/AuthProvider";
import ApplicationProvider from "./layout/ApplicationProvider";

const hubUrl = process.env.REACT_APP_HUB_URI;
export const SignalRContext = createSignalRContext();

library.add(faArrowCircleLeft, faArrowCircleRight, faCogs, faTools, faChartLine, faBookReader, faDatabase, faTruck);
moment.locale('es');
addLocale('es', datePickerConfig);

function App() {
    const {user: usuario} = useAuth();
    const enMantenimiento = false;


    const subscribeSingalr = () => {
        SignalRContext.invoke("Join", usuario.username).then(e => console.log(e)).catch(e => console.log(e))
    }

    const onOpen = () => {
        subscribeSingalr()
    }

    const onReconnect = () => {
        subscribeSingalr()
    }

    const onClosed = () => {
        console.log('closed')
    }

    const onError = () => {
        console.log('error')
    }

    return (
        <>
            {enMantenimiento === false &&
                <>
                    <IntlWrapper>
                        <SnackbarProvider
                            maxSnack={1}
                            action={(snackbarId) => (
                                <ButtonIcon color={'white'} iconName={"IoClose"} onClick={() => {
                                    closeSnackbar(snackbarId)
                                }}>

                                </ButtonIcon>
                            )}
                        >
                            <MainLoadingProvider>
                                <Loading/>
                                <div className="layout">
                                    <SignalRContext.Provider
                                        url={hubUrl}
                                        onOpen={onOpen}
                                        onReconnect={onReconnect}
                                        onClosed={onClosed}
                                        onError={onError}
                                        automaticReconnect={true}
                                        skipNegotiation={true}
                                        transport={HttpTransportType.WebSockets}
                                    >
                                        <ApplicationProvider>
                                            <OfflineApiProvider>
                                                <EmpresaProvider>
                                                    <Navigation/>
                                                    <MainBody/>
                                                </EmpresaProvider>
                                            </OfflineApiProvider>
                                        </ApplicationProvider>
                                    </SignalRContext.Provider>
                                </div>
                            </MainLoadingProvider>
                        </SnackbarProvider>
                    </IntlWrapper>
                </>
            }
            {enMantenimiento === true &&
                <div>
                    <h2>
                        La aplicación estará fuera de linea por mantenimiento hasta las 3:30 P.M aproximadamente.
                    </h2>
                </div>
            }
        </>
    );
}

const withAppAuthProvider = () => (
    <Router>
        <AuthProvider>
            <App/>
        </AuthProvider>
    </Router>
);

// export const withAuthProvider

export default withAppAuthProvider;
