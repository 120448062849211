import React from "react";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import FieldDropDown from "../../shared/controls/fieldDropDown";

const FiltroGeocercas = ({filtro, onChange}) => {
    const estatus = [{id: 1, nombre: "Activa"}, {id: 2, nombre: "Inactiva"}];    
    return(
        <>
        <FieldTextBox name="nombre" label="Geocerca" colMd={2} value={filtro.nombre} onChange={onChange}/>
        <FieldDropDown name="estatus" options={estatus} label="Estatus" colMd={2} valueById={true}
                           value={filtro.estatus} onChange={onChange}/>
        </>
    )
};

export default FiltroGeocercas;