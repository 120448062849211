import useServiceFactory from "../serviceFactory";

const apiUrl = `/operaciones/prefacturacion`;

const useApiPrefacturacion = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URL});
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, {params, showLoading: true}),
        actualizar: async (id,params) => service.post(`${apiUrl}/${id}`, params,{showLoading: false}),
        actualizarDetalle: async (id,params) => service.put(`${apiUrl}/${id}/actualizarDetalle`, params,{showLoading: false}),
        enviadoFacturar: async (id,params) => service.put(`${apiUrl}/${id}/enviadoFacturar`, params,{showLoading: true}),
        marcarFacturado: async (id,params) => service.put(`${apiUrl}/${id}/marcarFacturado`, params,{showSuccess: true, showLoading: true}),
        agruparPrefacturacion: async (ids) => service.post(`${apiUrl}/${ids}/agrupacion`),
        desligarFactura: async (id) => service.post(`${apiUrl}/${id}/desligarFactura`,{},{showSuccess: true}),
        eliminarAgrupacion: async (id) => service.delete(`${apiUrl}/${id}/eliminarAgrupacion`),
        reenviaraPrefacturacion: async (cartaporte) => service.post(`${apiUrl}/reenviarFactura/${cartaporte}`,{},{showSuccess: true, showLoading: true}),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, {showLoading: true}),
        excel: async (params) => service.get(`${apiUrl}/excel`,
        {
            params,
            showLoading: true,
            download: true,
            fileName: `Prefacturacion.xlsx`
        })

    }
};

export default useApiPrefacturacion;
