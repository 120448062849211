import './login.css';
import background from '../img/Background.png';
import React, {useCallback, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import logoXimple from "../img/LogoXimpleLogin.png"
import windowLogo from "../img/window-logo.png"
import FieldTextBox from "../shared/controls/fieldTextBox";
import {ProgressSpinner} from "primereact/progressspinner";
import useFormLogin from "./useFormLogin";

const loginStyle = {
    flexGrow: 1,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: `url(${background})`
};

const LoginAzure = () => {

    const {
        onFormKeyPres,
        actualizar,
        validarUsuario,
        submitted,
        isSubmitting,
        login,
        handleLoginAzure
    } = useFormLogin();

    const navigate = useNavigate()


    const onStorageUpdate = useCallback((e) => {
        const {key} = e
        if (key === 'authenticando') {
            const authenticando = localStorage.getItem('authenticando')
            if (authenticando === 'false') {
                localStorage.setItem('authenticando', false)
                setTimeout(() => {
                    navigate('/home')
                }, 0)
                navigate('/home')
            }
        }
    }, [navigate])

    useEffect(() => {
        window.addEventListener('storage', onStorageUpdate)
        return () => {
            window.removeEventListener('storage', onStorageUpdate)
        }
    }, [onStorageUpdate])

    return (
        <div className="login">
            <div style={loginStyle}>
            </div>
            <div className={"sidebar"}>
                <div style={{marginTop: '15vh'}}>
                    <img alt="logo" src={logoXimple} />
                </div>
                <h1 className={"iniciar-sesion"}>
                    Inicia sesión
                </h1>
                <div className={"form-login"} onKeyDown={onFormKeyPres} >
                    <FieldTextBox label="Usuario"
                                  colMd={12}
                                  name="username"
                                  error={login.username === '' && submitted}
                                  onChange={actualizar}
                    />
                    <FieldTextBox label="Contraseña"
                                  colMd={12}
                                  name="password"
                                  error={login.password === '' && submitted}
                                  type={"password"}
                                  onChange={actualizar}
                    />
                    <div className="form-group col-12">
                        {!isSubmitting && <button type="button" className="btn btn-primary form-control"
                                                  onClick={validarUsuario}>Entrar</button>}
                        {isSubmitting && <ProgressSpinner style={{height: 50}}/>}
                    </div>
                </div>
                <div className={'microsoft-login'}>
                    <div className="form-group col-12">
                        <button className="btn btn-login form-control" onClick={handleLoginAzure}>
                            <img src={windowLogo} alt={'window'} width={'22px'}/>
                            <span className="p-px-3">Continuar con  Microsoft</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginAzure;

