import React from "react";
import FieldRadioButtonList from "../../../shared/controls/fieldRadioButtonList";
import Medidas from "./medidas";
import CheckList from "../../../shared/componentes/checkList";

const CTPAT = ({ actualizar, entity, errors }) => {
    return (
        <div className={`section-container`}>
            <div className="flex-col-6">
                <h6>Inspección 17 Puntos C TPAT</h6>
                <img style={{ width: "90%" }} src={`../../../img/CTPAT/unidad_tipo_3.png`} alt="posicion" />
                <CheckList
                    value={entity.inspeccionUnidad}
                    name={"inspeccionUnidad"}
                    disabled={!entity.editable}
                    errors={errors}
                    onChange={(value, name) => actualizar(value, name)}
                />
            </div>
            {entity.conCaja === true && (
                <div className="flex-col-6">
                    <h6>Inspección 8 Puntos Remolques</h6>
                    <img style={{ width: "90%" }} src={`../../../img/CTPAT/unidad_tipo_1.png`} alt="posicion" />

                    <CheckList
                        value={entity.inspeccionRemolque}
                        name={"inspeccionRemolque"}
                        disabled={!entity.editable}
                        errors={errors}
                        onChange={(value, name) => actualizar(value, name)}
                    />
                    <table className="table">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col" style={{ width: "40%" }}>
                                    Revisión de remolque
                                </th>
                                <th scope="col" className="center" style={{ width: "25%" }} />
                                <th scope="col" className="center" style={{ width: "25%" }} />
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ fontWeight: "bold" }}>Tamaño remolque</td>
                                <td className="input center">
                                    <FieldRadioButtonList
                                        name="tamanioRemolque"
                                        source="general/Tamaño Remolque"
                                        value={entity.tamanioRemolque}
                                        onChange={actualizar}
                                        disabled={!entity.editable}
                                        errors={errors}
                                    />
                                </td>
                                <td />
                            </tr>
                            <Medidas
                                name="medidasInternas"
                                onChange={actualizar}
                                titulo={"Medidas Internas"}
                                value={entity.medidasInternas}
                                errors={errors?.medidasInternas}
                            />
                            <Medidas
                                name="medidasExternas"
                                onChange={actualizar}
                                titulo={"Medidas Externas"}
                                value={entity.medidasExternas}
                                errors={errors?.medidasExternas}
                            />
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default CTPAT;
