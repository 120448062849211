import React from "react";
import { useState } from "react";
import agGridHelper from "../../shared/ag-grid-helper";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import { Sidebar } from "primereact/sidebar";
import useApiEstaciones from "../../api/catalogos/useApiEstaciones";
import FiltroEstaciones from "./filtroEstaciones";
import { getSessionState } from "../../hooks/useSessionState";
import DetalleEstaciones from "./detalleEstaciones";
import Filtro from "../../shared/componentes/filtro";
import constantes from "../constantes";
import useSeguridad from "../../Seguridad/useSeguridad";
import { ReadOnlyCheckBoxRenderer } from "../../shared/ag-grid-helper/GridRenderers";

const ConfiguracionEstaciones = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.estaciones);
    const filtroKey = "FiltroEstaciones";
    const [datos, setDatos] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [estacionId, setEstacionesId] = useState(false);
    const api = useApiEstaciones();

    async function buscar(filtro, paramsApi) {
        agGridHelper.showLoad(gridApi, paramsApi, true);
        setDatos(await api.filtrar(filtro ?? getSessionState(filtroKey)));
        agGridHelper.hideLoad(gridApi, paramsApi, false);
    }

    const onGridReady = async (params) => {
        setGridApi(params.api);
    };

    async function nuevo() {
        setSidebarVisible(true);
        setEstacionesId(0);
    }

    async function onSave() {
        setSidebarVisible(false);
        await buscar();
    }

    function exportar() {
        api.excel(getSessionState(filtroKey));
    }

    const ButtonsHeader = () => {
        return (
            <div className={"ag-grid header-icons"}>
                <ButtonIcon title={"Descargar"} onClick={exportar} iconName={"BiDownload"} />
                {!soloLectura && (
                    <ButtonIcon title="Agregar" className={"grid-action"} iconName={"MdAddBox"} onClick={nuevo} />
                )}
            </div>
        );
    };

    const editEstaciones = (id) => {
        setSidebarVisible(true);
        setEstacionesId(id);
    };

    const RowButtons = ({ data,   context }) => (
        <div className={"ag-grid row-icons"}>
            <ButtonIcon title={"Editar"} iconName={"FaRegEdit"} onClick={() => context.editEstaciones(data.id)} />
        </div>
    );

    return (
        <>
            <Sidebar
                visible={sidebarVisible}
                position="right"
                dismissable={false}
                className="p-sidebar-md"
                onHide={() => setSidebarVisible(false)}
            >
                <DetalleEstaciones
                    estacionId={estacionId}
                    onSave={onSave}
                    soloLectura={soloLectura}
                    close={() => setSidebarVisible(false)}
                />
            </Sidebar>

            <Filtro filtroKey={filtroKey} handleSubmit={buscar} classButtons="field-iconos btn-group" gridApi={gridApi}>
                <FiltroEstaciones />
            </Filtro>

            <div className="ag-theme-balham grid-principal com-usuarioes">
                <AgGridReact
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    className="left"
                    frameworkComponents={{
                        rowButtons: RowButtons,
                        goToDetail: agGridHelper.GoToDetailFormatter,
                        buttonsHeader: ButtonsHeader,
                        dateFormatter: agGridHelper.DateFormatter,
                        imageFormatter: agGridHelper.ImageFormatter,
                        readOnlyCheckBoxRenderer: ReadOnlyCheckBoxRenderer,
                    }}
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    rowHeight={35}
                    context={{
                        editEstaciones: editEstaciones,
                    }}
                    getRowClass={(params) => params.data.nombreColor}
                >
                    <AgGridColumn field="nombre" headerName="NOMBRE" text cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn
                        field="activo"
                        cellRenderer={"readOnlyCheckBoxRenderer"}
                        headerName="ACTIVO"
                        text
                        cellStyle={{ textAlign: "left" }}
                    />

                    <AgGridColumn
                        maxWidth={80}
                        headerName="VER"
                        cellRenderer="rowButtons"
                        pinned={"right"}
                        cellClass={"left"}
                        headerComponent="buttonsHeader"
                    />
                </AgGridReact>
            </div>
        </>
    );
};

export default ConfiguracionEstaciones;
