/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";
import React from "react";
import moment from "moment";
import {FormattedDate} from "react-intl";

const Calendario = ({ItemTitulo, ItemTemplate, titulo, numeroDeDias, fechaInicial, datos, onFechaInicialCambio, ...props}) => {
    const intervaloHoras = 2;
    const intervaloHorasDia = 24 / intervaloHoras;
    const tituloWidth = 150;
    const heightCeldas = props.height ?? '50px';
    let fechaFinal = moment(fechaInicial);
    fechaFinal.add(numeroDeDias, 'd');
    const rangoMinutos = moment.duration(fechaFinal.diff(fechaInicial)).asMinutes();
    const [dias, horas] = obtenerDias();
    const [datosCalendario, setDatosCalendario] = useState([]);
    const currentDate = moment(new Date());

    useEffect(() => {
        if (datos != null) {
            const newDatos = JSON.parse(JSON.stringify(datos));


            newDatos.forEach((dato, mainIndex) => {
                dato.eventos.forEach((evento, indexEvento) => {
                    getPosition(evento, mainIndex, indexEvento)
                })
            });

            setDatosCalendario(newDatos);
        }
    }, [datos, fechaInicial]);

    function obtenerDias() {
        let dias = [];
        let horas = [];
        const dia = moment(fechaInicial);
        for (let i = 0; i < numeroDeDias; i++) {
            dias.push(dia.clone());
            dia.add(1, 'd');
            for (let j = 0; j < intervaloHorasDia; j++)
                horas.push(j * intervaloHoras + ':00');
        }
        return [dias, horas];
    }

    const getPosition = (evento) => {
        const minutosInicio = moment.duration(moment(evento.fechaInicio).diff(fechaInicial)).asMinutes();
        const minutosFin = moment.duration(moment(evento.fechaFin).diff(fechaInicial)).asMinutes();

        evento.inicioEnRango = minutosInicio >= 0;
        evento.finEnRango = minutosFin <= rangoMinutos;
        evento.style = {display: 'none'};

        if (minutosInicio < rangoMinutos && minutosFin > 0) {
            //El span debe empezar en el 3, los span 1 y 2 corresponden al titulo lateral y al espacio en blanco
            const spanInicial = 3;
            const posicionInicio = evento.inicioEnRango ? Math.floor(minutosInicio / 3) + spanInicial : spanInicial;
            const posicionFin = evento.finEnRango ?
                Math.floor(minutosFin / 3) + spanInicial
                : rangoMinutos / 3 + spanInicial;

            evento.numeroSpans = posicionFin - posicionInicio;
            evento.style = {gridColumn: `${posicionInicio}/${posicionFin}`};
        }
    };

    const getGridStyle = (esHeader, esEvento = false) => {
        const rowProperty = `auto-flow ${esHeader ? 'min-content' : heightCeldas}`;
        const totalSpans = intervaloHorasDia * numeroDeDias * (esEvento ? 20 * intervaloHoras : 1);

        return {grid: `${rowProperty}/${tituloWidth}px 10px repeat(${totalSpans}, 1fr)`}
    };

    const changeDay = (daysToAdd) => {
        const nuevaFecha = moment(fechaInicial);
        nuevaFecha.add(daysToAdd, 'd');

        onFechaInicialCambio(nuevaFecha);
    };

    const getBorderCurrentHour = (index) => {
        const minutosInicio = moment.duration(moment(currentDate).diff(fechaInicial)).asMinutes();
        let style = {};

        if (minutosInicio >= 0 && minutosInicio <= rangoMinutos) {
            const posicionConDecimal = minutosInicio / (60 * intervaloHoras);
            const posicion = Math.floor(posicionConDecimal);

            if (index === posicion) {
                const decimal = posicionConDecimal - posicion;
                const porcentajeHora = decimal > 0? (decimal) * 100: 1;
                style = {height: '100%', borderRight: '2px solid #0062cc', width: `${porcentajeHora}%`};
            }
        }

        return style;
    };

    return (
        <>
            {/************HEADER*************/}
            <div className="calendario-header" style={getGridStyle(true)}>
                {/****************FILA DE DIAS*******************/}
                <div className="titulo text-center" style={{gridRow: '1/3', alignSelf: 'center'}}>
                    <b>{titulo}</b>
                </div>
                <div className="blank-space"/>

                {dias.map((dia, index) => {
                    return (
                        <div style={{gridColumnEnd: `span ${intervaloHorasDia}`}}
                             key={index} className="dia text-center">
                            <b className="text-uppercase">
                                <FormattedDate value={dia.toDate()} weekday="long" day="numeric" month="long"
                                               year="numeric"/>
                            </b>
                        </div>
                    )
                })}
                {/****************FILA DE HORAS*******************/}
                <div className="blank-space"/>

                {horas.map((hora, index) => {
                    return (
                        <div key={index} className="hora">
                            <div>
                                {hora}
                            </div>
                        </div>
                    )
                })}

                {/************BOTONES ATRAS, SIGUIENTE*************/}
                <div className="calendario-botones">
                    <div className="d-flex" style={{height: '20px'}}>
                        <div className="flex-fill" style={{paddingLeft: `${tituloWidth + 10}px`}}>
                            <i className="pi pi-chevron-circle-left cursor-pointer" style={{fontSize: '1.2rem'}}
                               onClick={() => changeDay(-1)}/>
                        </div>
                        <div className="pr-2">
                            <i className="pi pi-chevron-circle-right cursor-pointer" style={{fontSize: '1.2rem'}}
                               onClick={() => changeDay(1)}/>
                        </div>
                    </div>
                    <div style={{height: '20px'}}/>
                </div>
            </div>

            {/************CONTENIDO*************/}
            <div className="calendario">
                {/****************CONTENEDOR DE LOS DATOS*******************/}
                <div className="calendario-contenedor" style={getGridStyle(false)}>
                    {datosCalendario?.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className="titulo items">
                                    <ItemTitulo item={item}/>
                                </div>

                                <div className="blank-space"/>

                                {horas.map((hora, index) => {
                                    return (
                                        <div key={index} className="hora detalle">
                                            <div style={getBorderCurrentHour(index)}/>
                                        </div>
                                    )
                                })}
                            </React.Fragment>
                        );
                    })}
                </div>

                {/****************DATOS*******************/}
                <div className="contenedor-items">
                    {datosCalendario?.map((item) => {
                        return (
                            <React.Fragment key={item.id}>
                                <div className="calendario-eventos" style={getGridStyle(false, true)}>
                                    {/*Divs de Título y espacio vacío, corresponden a los spans 1 y 2*/}
                                    <div/>
                                    <div/>
                                    {/****************ELEMENTOS*******************/}
                                    {item.eventos.map((evento) => {
                                        return (
                                            <div
                                                className={`item ${evento.inicioEnRango ? '' : 'no-border-left'} ${evento.finEnRango ? '' : 'no-border-right'}`}
                                                style={evento.style}>
                                                <ItemTemplate evento={evento}/>
                                            </div>
                                        )
                                    })}
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        </>
    )
};

export default Calendario;
