/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import _ from "lodash";
import Field from "../../shared/forms/Field";
import ExGrid from "../../shared/Grid/ExGrid";
import ExColumn from "../../shared/Grid/ExColumn";
import FormDropDown from "../../shared/forms/FormFields/FormDropDown";
import FormInputNumber from "../../shared/forms/FormFields/FormInputNumber";
import Form from "../../shared/forms/Form";
const GastosMovimientos = ({ value, onChange, name, onValidate }) => {
    return (
        <>
            <Form
                columns={2}
                values={value}
                onChange={onChange}
                onValidate={onValidate}
                formDisabled="true">
                <Field field={"gastos"} cols={2} render={({ value, onParentChange, ...props }) =>
                    <ExGrid {...props} data={value}
                        onChange={(data) => onParentChange({ gastos: data, monto: _.sumBy(data, 'monto') })}
                        getEntityId={(row) => row._uniqueUid}
                    >
                        <ExColumn field="tipoGasto" title="Tipo" source="cajachica/tipoGastos" required={true}
                            cellRenderer={({ row, onRowChanged, ...props }) =>
                                <FormDropDown {...props} onChange={(id) => onRowChanged({ tipoGastoId: id, conceptoId: null })} />
                            } />

                        <ExColumn field="conceptoId" title="Concepto" required={true}
                            cellRenderer={({ row, onRowChanged, ...props }) =>
                                <FormDropDown {...props} params={{ tipoGasto: row.tipoGasto }} source="cajachica/conceptos"
                                    onChange={(id, concepto) => {
                                        onRowChanged({ conceptoId: id, tipoGasto: concepto.tipoGasto })
                                    }}
                                />
                            } />

                        <ExColumn field="monto" title="Monto" mode="decimal" minFractionDigits={2} maxFractionDigits={2} required={true}
                            cellRenderer={FormInputNumber} />
                    </ExGrid>
                } />
            </Form>
        </>
    )
}
export default GastosMovimientos;