import constantes from "../../../catalogos/constantes";

export class ImagenUnidad {
    constructor(props) {
        this.gridColumnEnd = props.gridColumnEnd;
        this.gridRowEnd = props.gridRowEnd;
        this.width = props.width;
        this.title = props.title;
        this.ubicacion = props.ubicacion;
        this.imagen = props.imagen;
        this.tipoUnidadId = props.tipoUnidadId;
        this.esUnidad = props.esUnidad;
    }

    get src() {
        return `../../../img/inspeccion/tipo${this.tipoUnidadId}_${this.imagen}`
    }

    get srcLlanta() {
        return `../../../img/llantas/tipo${this.tipoUnidadId}_${this.imagen}`
    }
}

const motriz = [
    new ImagenUnidad({
        gridColumnEnd: 'span 2',
        title: 'LADO IZQUIERDO',
        imagen: `lado_izquierdo.jpg`,
        ubicacion: constantes.ubicaciones.ladoIzquierdo,
        tipoUnidadId: 3
    })
    ,
    new ImagenUnidad({
        gridColumnEnd: 'span 2',
        title: 'FRENTE',
        imagen: `frente.jpg`,
        ubicacion: constantes.ubicaciones.frente,
        tipoUnidadId: 3
    })
    ,
    new ImagenUnidad({
        gridColumnEnd: 'span 2',
        title: 'LADO DERECHO',
        imagen: `lado_derecho.jpg`,
        ubicacion: constantes.ubicaciones.ladoDerecho,
        tipoUnidadId: 3
    })
    ,
    new ImagenUnidad({
        gridColumnEnd: 'span 2',
        title: 'ATRAS',
        imagen: `atras.jpg`,
        ubicacion: constantes.ubicaciones.atras,
        tipoUnidadId: 3
    })
];

const arrastre = [
    new ImagenUnidad({
        gridColumnEnd: 'span 2',
        title: 'LADO IZQUIERDO',
        imagen: `lado_izquierdo.jpg`,
        ubicacion: constantes.ubicaciones.ladoIzquierdo,
        tipoUnidadId: 1
    })
    ,
    new ImagenUnidad({
        gridColumnEnd: 'span 2',
        title: 'LADO DERECHO',
        imagen: `lado_derecho.jpg`,
        ubicacion: constantes.ubicaciones.ladoDerecho,
        tipoUnidadId: 1
    })
    ,

    new ImagenUnidad({
        gridRowEnd: 'span 2',
        title: 'TECHO',
        imagen: `techo.jpg`,
        ubicacion: constantes.ubicaciones.techo,
        tipoUnidadId: 1
    })
    ,

    new ImagenUnidad({
        gridRowEnd: 'span 2',
        title: 'PISO',
        imagen: `piso.jpg`,
        ubicacion: constantes.ubicaciones.piso,
        tipoUnidadId: 1
    })
    ,
    new ImagenUnidad({
        width: '15%',
        title: 'FRENTE',
        imagen: `frente.jpg`,
        ubicacion: constantes.ubicaciones.frente,
        tipoUnidadId: 1

    })
    ,
    new ImagenUnidad({
        width: '15%',
        title: 'ATRAS',
        imagen: `atras.jpg`,
        ubicacion: constantes.ubicaciones.atras,
        tipoUnidadId: 1
    }),
    new ImagenUnidad({
        width: '15%',
        title: 'PATINES',
        imagen: `patines.jpg`,
        ubicacion: constantes.ubicaciones.patines,
        tipoUnidadId: 1

    })
];

const llantas = [
    new ImagenUnidad({
        esUnidad: true,
        title: 'Tractor',
        imagen: `tractor.png`,
        tipoUnidadId: 3
    }),
    new ImagenUnidad({
        esUnidad: false,
        title: 'Tractor',
        imagen: `remolque_tractor.png`,
        tipoUnidadId: 3
    }),
    new ImagenUnidad({
        esUnidad: true,
        title: 'Rabon',
        imagen: `rabon.png`,
        tipoUnidadId: 4
    }),
    new ImagenUnidad({
        esUnidad: true,
        title: 'TresToneladas',
        imagen: `tres_toneladas.png`,
        tipoUnidadId: 5
    }),
    new ImagenUnidad({
        esUnidad: true,
        title: 'utilitario',
        imagen: `utilitario.png`,
        tipoUnidadId: 6
    }), 
    new ImagenUnidad({
        esUnidad: false,
        title: 'Pipa',
        imagen: `remolque_pipa.png`,
        tipoUnidadId: 2
    }),
    new ImagenUnidad({
        esUnidad: false,
        title: 'Caja',
        imagen: `remolque_caja.png`,
        tipoUnidadId: 1
    })
];

const imagenesUnidades = {
    motriz,
    arrastre,
    llantas
};

export default imagenesUnidades;
