import {Dialog} from "primereact/dialog";
import React, {useEffect, useState} from "react";
import {GoogleMap, Marker} from "@react-google-maps/api";

import {useSeguimientoMonitoreo} from "../SeguimientoMonitoreoProvider";
import {containerStyle, getIcon, getUnidades} from "../SeguimientoMonitoreutility";

const posicionesModal = ["top-right", "right", "bottom-right","top","center","bottom"]
let index = 0;

const mapOptions = {
    fullscreenControl: false,
    zoomControl: true, // Mostrar control de zoom
    mapTypeControl: false,
};

const SeguimientoViaje = ({viaje}) => {

    const [posicionModalActual, setPosicionModalActual] = useState(0)
    const [posicionesViaje, setPosicionesViaje] = useState([])
    const [center, setCenter] = useState({lat: 25.686, lng: -100.316})
    const {suspenderSeguimiento, posiciones} = useSeguimientoMonitoreo();

    useEffect(()=>{
        setPosicionModalActual(index++)
        if (index > 5)
            index = 0
    },[])

    useEffect(() => {
        const unidades = getUnidades(viaje);
        const nuevasPosiciones = posiciones.filter(e=> unidades.indexOf(e.unidadId) >= 0);
        setPosicionesViaje(nuevasPosiciones)
        setCenter(nuevasPosiciones[0])
    }, [posiciones, viaje]);

    const handleHide = ()=>{
        suspenderSeguimiento(viaje)
    }

    return (
        <Dialog className={'seg-map-modal'} position={posicionesModal[posicionModalActual]} header={`${viaje.unidad} / ${viaje.caja}` }  visible={true} modal={false} style={{ width: '300px', height:'300px' }} onHide={handleHide}>
                <GoogleMap
                    options={mapOptions}
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={14}
                >
                    {
                        posicionesViaje.map((item, index) => (
                            <React.Fragment key={index}>
                                <Marker
                                    key={index}
                                    icon={getIcon(item)}
                                    title={`${item.esCaja ? 'Caja' : 'Unidad'}: ${item.numeroEconomico}`}
                                    position={item}
                                />
                            </React.Fragment>
                        ))
                    }
                </GoogleMap>
        </Dialog>
    )
}
export default SeguimientoViaje;