/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import moment from "moment";
import numeral from "numeral";
import { Tooltip } from "primereact/tooltip";
import { ESTATUS_DIARIO } from "./StatusButton";
import Viajes from "./Viajes";
import { IoPersonSharp } from "react-icons/io5";

const UnidadDia = ({ datos, dia, unidadId, unidad }) => {
    return (
        <>
            {datos.estatusId === ESTATUS_DIARIO.operando || datos.estatusId === ESTATUS_DIARIO.disponible ? (
                <>
                    <div className="info facturacion">{numeral(datos.facturacion ?? 0).format("$0,0")}</div>
                    <div className="info titulo">{datos.cliente}</div>
                    <div className="info ruta">{datos.ruta}</div>
                </>
            ) : (
                <div className="con-comentarios" style={{ gridRowEnd: "span 3" }}>
                    {(datos.facturacion ?? 0) !== 0 && (
                        <div className="info facturacion">{numeral(datos.facturacion).format("$0,0")}</div>
                    )}
                    <div className="subtitulo center">
                        <span className="bold">
                            {datos.fechaCompromiso != null
                                ? moment(datos.fechaCompromiso).format("dd DD/MMM")
                                : ""}{" "}
                        </span>
                    </div>
                    <div className="titulo center">{datos.motivo?.nombre}</div>
                    <div className="titulo center">{datos.comentarios}</div>
                </div>
            )}
            <div style={{ fontWeight: "bold", position: "absolute", bottom: "2px", left: "10px" }}>{datos.enRenta}</div>

            <Viajes
                facturacion={datos.facturacion}
                otraEmpresa={datos.otraEmpresa}
                dia={dia}
                unidadId={unidadId}
                coordinador={datos.coordinador}
                coordinadorNombreCompleto={datos.coordinadorNombreCompleto}
            />

            {(datos.comentarios ?? "") !== "" && (
                <div className="img-commentarios">
                    <Tooltip target={`.com-${unidadId}-${dia.fecha}`} style={{ maxWidth: "200px" }} hideDelay={100}>
                        <div className="tooltip-viajes-comentarios">{datos.comentarios}</div>
                    </Tooltip>
                    <img
                        style={{ width: "30px", height: "30px" }}
                        className={`com-${unidadId}-${dia.fecha}`}
                        src="/img/comments.png"
                        alt="comentarios"
                    />
                </div>
            )}

            {datos.sinOperador && (
                <div className={"sin-operador-card"}>
                    <IoPersonSharp fill={"red"} />
                </div>
            )}
        </>
    );
};

export default UnidadDia;
