import React, {useEffect, useRef, useState} from "react";
import {ProgressSpinner} from "primereact/progressspinner";
import {useLoadingState} from "../layout/show-loading-context";
import {Toast} from "primereact/toast";



const Loading = () => {
    const {show, toast} = useLoadingState();
    const [zindex,setZindex] = useState(1000);
    const toastBR = useRef(null);

    useEffect(()=>{
        if (show > 0){
            setZindex(findHighestZIndex());
        }
    },[show]);


    useEffect(()=>{
        if (toast.counter > 0){
            toastBR.current.show({severity:toast.severity??'success',  detail:toast.message, life: toast.life??2500 });
        }
    },[toast]);

    const findHighestZIndex =  () =>
    {
        const elems = document.getElementsByTagName('div');
        let highest = Number.MIN_SAFE_INTEGER || -(Math.pow(2, 53) - 1);
        for (let i = 0; i < elems.length; i++)
        {
            const zindex = Number.parseInt(
                document.defaultView.getComputedStyle(elems[i], null).getPropertyValue("z-index"),
                10
            );
            if (zindex > highest)
            {
                highest = zindex;
            }
        }
        return highest;
    };

    return (
        <>
            {(show > 0) &&
            <div id="main-overlay-panel" style={{zIndex:zindex}}>
                <div className="loading-overlay" style={{zIndex:zindex+1}}>

                </div>
                <div id="main-loading-container" className="loading-container" style={{zIndex:zindex+1}}>
                    <div>
                        <ProgressSpinner/>
                    </div>
                    <h2>Cargando...</h2>
                </div>
            </div>
            }
            <Toast ref={toastBR} position="bottom-right" />
        </>
    )
};


export default Loading;

