import React, { useState, useReducer } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import agGridHelper from "../../../shared/ag-grid-helper";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import { Sidebar } from "primereact/sidebar";
import Filtro from "../../../shared/componentes/filtro";
import { getSessionState } from "../../../hooks/useSessionState";
import FiltroAlmacen from "./filtroAlmacen";
import DetalleAlmacen from "./detalleAlmacen";
import useApiAlmacen from "../../../api/taller/useApiAlmacen";
import alerts from "../../../shared/alerts";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import FieldTextArea from "../../../shared/controls/fieldTextArea";
import formReducer from "../../../shared/forms/formReducer";

const Almacen = ({ soloLectura }) => {
    const filtroKey = "filtroAlmacen";
    const [datos, setDatos] = useState([]);
    const [gridApi,] = useState(null);
    const [solicitudAlmacenId, setsolicitudAlmacenId] = useState(false);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const apiAlmacen = useApiAlmacen();
    const [modalCancelar, setModalCancelar] = useState(false);
    const defaultFilter = {

    };
    const defaultEntity = () => (
        {
            entity: { id: 0, ordenTrabajoId: 0, comentarios: "", motivo: null }
        }
    );
    const [{ entity }, dispatch] = useReducer(
        formReducer, {}, defaultEntity
    );
    const actualizar = (value, propertyName) => {
        dispatch({ type: 'update', value: value, name: propertyName });
    };

    async function buscar(filtro, paramsApi) {
        closeSolicitud();
        agGridHelper.showLoad(gridApi, paramsApi, true);
        setDatos(await apiAlmacen.filtrarAlmacen(getSessionState(filtroKey)));
        agGridHelper.hideLoad(gridApi, paramsApi, false);
    }

    const onGridReady = async (params) => {
        //  setGridApi(params.api);
    };

    //Agregar refacción a OT
    async function nuevo() {
        setSidebarVisible(true);
        setsolicitudAlmacenId(0);
    }

    function onUpdateStatus() {
        //  setSidebarVisible(false);
        //  buscar();
    }

    const editSolicitud = (id) => {
        //  setSidebarVisible(true);
        //  setsolicitudAlmacenId(id);
    };

    const closeSolicitud = () => {
        setSidebarVisible(false);
    };

    function exportar() {
        apiAlmacen.excel(getSessionState(filtroKey));
    }

    const ButtonsHeader = ({ api }) => {
        return (
            <div className={'ag-grid header-icons'}>
                <ButtonIcon title={'Descargar'} iconName={'BiDownload'} onClick={exportar} />
                {!soloLectura &&
                    <ButtonIcon title="Agregar" className={'grid-action'} iconName={'MdAddBox'} onClick={nuevo} />
                }
            </div>
        );
    };

    async function eliminar(data) {
        if (data.puedeEliminar === 0) {
            alerts.aviso('El estatus de la Orden de Trabajo, no permite dar de baja este registro.');
            return;
        }
        actualizar(data.id, "id");
        actualizar(data.ordenTrabajoId, "ordenTrabajoId");
        actualizar("", "comentarios");
        actualizar(null, "motivo");
        setModalCancelar(true)
    }

    function getWidth() {
        if (window.innerWidth < 768)
            return '75vw';
        if (window.innerWidth < 1000)
            return '55vw';
        return '35vw';
    }

    const RowButtons = ({ data }) => (
        <div className={'ag-grid row-icons'}>
            {data.puedeEliminar === 1 && !soloLectura &&
                <ButtonIcon title={'Eliminar'} iconName={'RiDeleteBinLine'} onClick={() => eliminar(data)} />
            }
        </div>
    );

    const footer = (
        <div className="mt-2">
            <Button label="Cancelar" className="p-button-outlined" onClick={() => setModalCancelar(false)}></Button>
            <Button label="Aceptar" onClick={cancelarSalida}></Button>
        </div>
    );

    async function cancelarSalida() {

        if (entity?.comentarios === "" || entity?.motivo === null || entity?.id === 0) {
            alerts.aviso('Debe ingresar el motivo y comentarios de la salida del artículo.');
            return;
        }
        setModalCancelar(false);
        if (await alerts.preguntarSiNoAdvertencia('Esta acción modifica el inventario, ¿Desea continuar?')) {
            await apiAlmacen.cancelarSalidaDeArticulo(entity);
            buscar(getSessionState(filtroKey)).then();
        }
    };

    return (
        <>
            <Sidebar visible={sidebarVisible} position="right" dismissable={false} className="p-sidebar-bg" onHide={() => setSidebarVisible(false)}>
                <DetalleAlmacen
                    solicitudAlmacenId={solicitudAlmacenId} onSave={buscar} onUpdateStatus={onUpdateStatus} closePanel={closeSolicitud}
                >
                </DetalleAlmacen>
            </Sidebar>

            <Filtro filtroKey={filtroKey} handleSubmit={buscar} defaultValue={defaultFilter} gridApi={gridApi}>
                <FiltroAlmacen />
            </Filtro>

            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    rowSelection={'single'}
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    frameworkComponents={
                        {
                            buttonsHeader: ButtonsHeader,
                            dateFormatter: agGridHelper.DateFormatter,
                            rowButtons: RowButtons
                        }
                    }
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    context={{
                        editSolicitud: editSolicitud
                    }}
                >
                    <AgGridColumn field="codigoArticulo" headerName="CÓDIGO DE ARTÍCULO" cellClass={'center'} minWidth={150} />
                    <AgGridColumn field="descripcion" headerName="DESCRIPCIÓN" cellClass={'left'} minWidth={300} />
                    <AgGridColumn field="cantidad" headerName="CANTIDAD" headerClass={'center'} minWidth={100} />
                    <AgGridColumn field="unidadDeMedida" headerName="U.MEDIDA" minWidth={100} />
                    <AgGridColumn field="precio" headerName="PRECIO" headerClass={'center'} minWidth={100}
                        valueFormatter={agGridHelper.currencyFormatter} cellClass={'right'} />
                    <AgGridColumn field="importe" headerName="IMPORTE" headerClass={'center'} minWidth={100}
                        valueFormatter={agGridHelper.currencyFormatter} cellClass={'right'} />                    
                    <AgGridColumn field="noEconomico" headerName="NO. ECONÓMICO" minWidth={150} />
                    <AgGridColumn field="fecha" cellRenderer="dateFormatter" headerName="FECHA" minWidth={100} />
                    <AgGridColumn field="empleado" headerName="EMPLEADO" minWidth={150} />
                    <AgGridColumn field="ordenDeTrabajo" headerName="ORDEN DE TRABAJO" minWidth={100} />

                    <AgGridColumn maxWidth={80}
                        headerName="VER"
                        cellRenderer="rowButtons"
                        pinned={'right'}
                        cellClass={'left'}
                        headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>

            {/*Modal para cancelar la salida*/}
            {modalCancelar &&
                <Dialog header={"¿Desea cancelar salida de artículo?"} footer={footer} visible={modalCancelar} style={{ width: getWidth() }} modal onHide={() => setModalCancelar(false)}>
                    <div className="form-row">

                        <FieldDropDown name="motivo" label="Motivo" source="general/Motivos Refacciones"
                            value={entity.motivo} onChange={actualizar} colMd={6} />

                        <FieldTextArea name="comentarios" label="Comentario:" colMd={52} rows={11} maxLength={500}
                            value={entity.comentarios} onChange={actualizar} />
                    </div>
                </Dialog>
            }
        </>
    )
};

export default Almacen;
