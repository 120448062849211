/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import constantes from "../catalogos/constantes";
import {ReactComponent as WrenchSvg} from "../icons/wrench-solid.svg";
import numeral from "numeral";

const IconMatenimiento = ({unidad, height}) => {
    if (height == null)
        height = '15px';
    return (
        <>
            {unidad?.estatusMantenimiento === constantes.estatusProximoMantenimiento?.Vencido && (
                <span style={{fill: "#ff4b4b", float: "right"}}
                      title={`Odometro: ${numeral(unidad?.odometro).format('0,0')}\nProximo mantenimiento: ${numeral(unidad?.proximoMantenimiento).format('0,0')}`}>
                        <WrenchSvg style={{height: height}}/>
                    </span>
            )}
            {unidad?.estatusMantenimiento === constantes.estatusProximoMantenimiento?.Proximo && (
                <span style={{fill: "#FFCC0F", float: "right"}}
                      title={`Odometro: ${numeral(unidad?.odometro).format('0,0')}\nProximo mantenimiento: ${numeral(unidad?.proximoMantenimiento).format('0,0')}`}>
                        <WrenchSvg style={{height: height}}/>
                    </span>
            )}
            {unidad?.estatusMantenimiento === constantes.estatusProximoMantenimiento?.Ok && (
                <span style={{fill: "#00d661", float: "right"}}
                      title={`Odometro: ${numeral(unidad?.odometro).format('0,0')}\nProximo mantenimiento: ${numeral(unidad?.proximoMantenimiento).format('0,0')}`}>
                        <WrenchSvg style={{height: height}}/>
                    </span>
            )}
            {unidad?.estatusMantenimiento === constantes.estatusProximoMantenimiento?.SinMtto && (
                <span style={{fill: "#C0c0c0", float: "right"}}
                      title={`Odometro: ${numeral(unidad?.odometro).format('0,0')}\nProximo mantenimiento: ${numeral(unidad?.proximoMantenimiento).format('0,0')}`}>
                        <WrenchSvg style={{height: height}}/>
                    </span>
            )}
        </>
    );
};

export default IconMatenimiento;
