
import React from "react";
import FieldInputMask from "../fieldInputMask";


const FieldInputModelo = ({colMd, colSm, label, name, onChange, onBlur, value, keyFilter, rows, disabled,formatType,error, errors,required}) => {

    return (
        <>
        
        {label   &&
           <FieldInputMask maskVariable="9999" name={name} label={label} colMd={colMd} errors={errors} required={required}
            value={ value }  onChange={onChange}/>
          }

           {!label  &&
            <FieldInputMask  maskVariable='9999' name={name} colMd={colMd} errors={errors}
             value={ value }  onChange={onChange}/>            
            }
            
          
        </>
    )
};


export default FieldInputModelo;