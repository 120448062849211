const ValidacionNoTarjeta = (value) => {
    value = value.replace(/\s+/g, '').replace(/^\(|\)$/g, "");
    console.log("Valor limpio:", value);
    if (/^\d+$/.test(value)) {
        if (value.length >= 13 && value.length <= 19) {
            console.log("Longitud correcta de la tarjeta:", value.length);
            if (isValidCardNumber(value)) {
                return { isValid: true };
            } else {
                return { isValid: false, error: 'Número de tarjeta no válido.' };
            }
        } else {
            return { isValid: false, error: 'La longitud del número es incorrecta.' };
        }
    } else {
        return { isValid: false, error: 'El número debe contener solo dígitos.' };
    }
};

function isValidCardNumber(cardNumber) {
    let sum = 0;
    let alternate = false;
    for (let i = cardNumber.length - 1; i >= 0; i--) {
        let n = parseInt(cardNumber[i], 10);
        
        if (alternate) {
            n *= 2;
            if (n > 9) n -= 9; 
        }
        sum += n;
        alternate = !alternate;
    }
    return sum % 10 === 0;
}

export default ValidacionNoTarjeta;
