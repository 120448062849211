/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import * as numeral from "numeral";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import produce from "immer";
import moment from "moment";

const Compensaciones = ({ liquidacion, compensaciones, onChange, esOperador, soloConsulta }) => {

    const actualizar = (value, propertyName, index) => {
        const newValue = produce(compensaciones, (draft) => {
            draft[index][propertyName] = value;
        });

        onChange(newValue, 'compensaciones');
    };

    return (
        <>
            <div className="form-row">
                <table className="table table-borderless">
                    <thead className="thead-light">
                        <tr>
                            {!soloConsulta && esOperador && liquidacion.aplazarMovimientos && <th />}
                            <th style={{ width: '20%' }}>TIPO</th>
                            <th style={{ width: '20%' }}>CONCEPTO</th>
                            <th style={{ width: '20%' }}>NOTA</th>
                            <th style={{ width: '30%' }}>DETALLE</th>
                            <th style={{ width: '10%' }}>MONTO</th>
                        </tr>
                    </thead>
                    <tbody>
                        {liquidacion.compensaciones?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    {!soloConsulta && liquidacion.aplazarMovimientos && esOperador &&
                                        <td>
                                            <FieldCheckBox name="seleccionado" value={item.seleccionado}
                                                onChange={(value, pname) => actualizar(value, pname, index)}
                                            />
                                        </td>
                                    }
                                    <td>{item.tipoNombre}</td>
                                    <td>{item.concepto}</td>
                                    <td>{item.nota}</td>
                                    <td className="p-d-flex p-flex-column">
                                        <span>Fecha de Solicitud: {moment(item.fecha).format('DD/MM/YYYY hh:mm a')}</span>
                                        <div>
                                            Solicitante: <span className="bold">{item.solicitante}</span>
                                        </div>
                                    </td>
                                    <td>{numeral(item.monto).format('$0,0.00')}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
};


export default Compensaciones;
