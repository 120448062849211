import "./StyleIcons.css"

import {ReactComponent as VelocidadSvg} from './Velocidad.svg';
import {ReactComponent as VelocidadContrasteSvg} from './VelocidadContraste.svg';
import {ReactComponent as GeocercaProhibidaSvg} from './GeocercaProhibida.svg';
import {ReactComponent as GeocercaProhibidaContrasteSvg} from './GeocercaProhibidaContraste.svg';
import {ReactComponent as FollowInMapSvg} from './FollowInMap.svg';
import {ReactComponent as LastPositionSvg} from './LastPosition.svg';
import {ReactComponent as MotorSvg} from './Motor.svg';
import {ReactComponent as MotorContrasteSvg} from './MotorContraste.svg';
import {ReactComponent as ShowRouteSvg} from './ShowRoute.svg';
import {ReactComponent as SignalSvg} from './Signal.svg';
import {ReactComponent as SignalContrasteSvg} from './SignalContraste.svg';
import {ReactComponent as SeparadorPuntoSvg} from './SeparadorPunto.svg';
import {ReactComponent as CriticalSvg} from './Critical.svg';
import {ReactComponent as CloseSvg} from './Close.svg';
import {ReactComponent as TruckSvg} from './Truck.svg';
import {ReactComponent as ShareLinkSvg} from './ShareLink.svg';
import {ReactComponent as NuevaVentanaSvg} from './NuevaVentana.svg';
import {ReactComponent as DieselSvg} from './Diesel.svg';
import {ReactComponent as DieselContrasteSvg} from './DieselContraste.svg';
import {ReactComponent as MostrarRutaSvg} from './MostrarRuta.svg';
import {ReactComponent as SeguirEnMapSvg} from './SeguirEnMap.svg';
import {ReactComponent as BorrarFiltrosSvg} from './BorrarFiltros.svg';
import {ReactComponent as CopiarViajesSvg} from './CopiarViajes.svg';
import {ReactComponent as LastPositionContrasteSvg} from './LastPositionContraste.svg';
import {ReactComponent as ScheduleSvg} from './schedule.svg';
import {ReactComponent as BrechaSvg} from './brecha.svg';
import {ReactComponent as BrechaContrasteSvg} from './brechaContraste.svg';
import {ReactComponent as SinViajeSvg} from './SinViaje.svg';
import {ReactComponent as SinViajeContrasteSvg} from './SinViajeContraste.svg';
import {ReactComponent as WorksheetSvg} from './worksheet.svg';
import {ReactComponent as HorasManejoSvg} from './HorasManejo.svg';
import {ReactComponent as HorasManejoContrasteSvg} from './HorasManejoContraste.svg';
import {ReactComponent as NeutralSvg} from './Neutral.svg';
import {ReactComponent as NeutralContrasteSvg} from './NeutralContraste.svg';

import React from "react";

export const SeparadorPuntoIcon = () => {
    return (<SeparadorPuntoSvg/>)
}

export const ScheduleIcon = ({onClick, tooltip, selected}) => {
    return (<span class={`seg-filter-icon ${selected ? 'selected':''}`} onClick={onClick} title={tooltip}> <ScheduleSvg/> </span> )
        }

        export const CloseIcon = () => {
            return (<CloseSvg/>)
}

export const ShareLinkIcon = ({onClick}) => {
    return (<span class={'seg-filter-icon'} onClick={onClick}><ShareLinkSvg/></span>)
}

export const WorksheetIcon = ({onClick, className}) => {
    return (<span class={`seg-filter-icon ${className}`} onClick={onClick}><WorksheetSvg/></span>)
}

export const NuevaVentanaIcon = () => {
    return (<NuevaVentanaSvg/>)
}


export const ShowRouteIcon = () => {
    return (<ShowRouteSvg/>)
}

export const SeguirEnMapaIcon = () => {
    return (<SeguirEnMapSvg/>)
}

export const MostrarRutaIcon = () => {
    return (<MostrarRutaSvg/>)
}

export const BorrarFiltrosIcon = ({onClick}) => {
    return (<span class={'seg-filter-icon'} onClick={onClick}> <BorrarFiltrosSvg/></span>)
}


export const CopiarViajesIcon = ({onClick}) => {
    return (<span class={'seg-filter-icon'} onClick={onClick}> <CopiarViajesSvg/></span>)
}


const SeguimientoIcon = ({iconName, onMouseEnter, onMouseLeave, onClick, continuePropagation}) => {

    const handleClick = (event) => {
        if (onClick != null) {
            onClick(event);
        }
        if (!continuePropagation)
            event.stopPropagation();
    }

    const icons = {
        Velocidad: <VelocidadSvg/>,
        VelocidadContraste: <VelocidadContrasteSvg/>,
        GeocercaProhibida: <GeocercaProhibidaSvg/>,
        GeocercaProhibidaContraste: <GeocercaProhibidaContrasteSvg/>,
        FollowInMap: <FollowInMapSvg/>,
        LastPosition: <LastPositionSvg/>,
        LastPositionContraste: <LastPositionContrasteSvg/>,
        Motor: <MotorSvg/>,
        MotorContraste: <MotorContrasteSvg/>,
        ShowRoute: <ShowRouteSvg/>,
        Gps: <SignalSvg/>,
        GpsContraste: <SignalContrasteSvg/>,
        Critical: <CriticalSvg/>,
        Close: <CloseSvg/>,
        Truck: <TruckSvg/>,
        Diesel: <DieselSvg/>,
        DieselContraste: <DieselContrasteSvg/>,
        Brecha: <BrechaSvg/>,
        BrechaContraste: <BrechaContrasteSvg/>,
        SinViaje: <SinViajeSvg/>,
        SinViajeContraste: <SinViajeContrasteSvg/>,
        HorasManejo: <HorasManejoSvg/>,
        HorasManejoContraste: <HorasManejoContrasteSvg/>,
        Neutral: <NeutralSvg/>,
        NeutralContraste: <NeutralContrasteSvg/>,
    };
    return (
        <>
            {icons[iconName] ?
                <span onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={handleClick}
                      className={`${iconName}`}>{icons[iconName]}</span> :
                <div style={{fontSize: 9}}>{iconName} no icon</div>
            }</>
    );
}


export default SeguimientoIcon;



