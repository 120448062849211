import * as yup from "yup";
import constantes from "../../../catalogos/constantes";


const guardar = yup.object().shape({

    checkUnidad: yup.object({
        unidad: yup.mixed().required(),
        operador: yup.mixed().required(),
        llantasUnidad: yup.array()
            .of(
                yup.object().shape({
                    idCautin: yup.mixed().when(["requerido", "idCautinOriginal"], {
                        is: (a, b) => a === true & b !== '',
                        then: yup.string().required()
                    }),
                    marcaLlanta: yup.mixed().when(["requerido", "marcaLlantaOriginal"], {
                        is: (a, b) => a === true && b !== null,
                        then: yup.mixed().required()
                    }),

                })
            ),
        checkList: yup.array()
            .of(
                yup.object().shape({
                    checkList: yup.array().of(
                        yup.object().shape({
                            comentarios: yup.mixed().when(['esIncidencia', 'puntoInspeccion.id'], {
                                is: (a, b) => a && b !== constantes.puntosInscepccion.extintor,
                                then: yup.string().required()
                            }),
                            fotoActualUrl: yup.string().when(['puntoInspeccion.id'], {
                                is: (a) => a !== constantes.puntosInscepccion.extintor,
                                then: yup.string().required()
                            }),
                        }
                        ),
                    ),


                })

            ),
    })

});






const CheckUnidadValidacionEsquema = {
    guardar

};

export default CheckUnidadValidacionEsquema;
