import React from "react";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import FieldCalendar from "../../../shared/controls/fieldCalendar";

import produce from "immer";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import FieldInputCodigoPostal from "../../../shared/controls/customInputs/fieldInputCodigoPostal";
import InputFile from "../../../shared/controls/inputFile";

const DatosPersonales = ({ datosPersonales, familiares, name, onChange, errors, soloLectura }) => {

    function actualizar(value, propertyName) {
        const nuevo = produce(datosPersonales ?? {}, draft => {
            draft[propertyName] = value
            draft.localidad = (propertyName === 'estados') ? null : draft.localidad;
            draft.municipios = (propertyName === 'estados') ? null : draft.municipios;
        });
        onChange(nuevo, name);
    }

    function actualizarFamiliar(index, value, propertyName) {
        const nuevo = produce(familiares, draft => {
            draft[index][propertyName] = value;
        }
        );

        onChange(nuevo, 'familiares');
    }

    function agregarFamiliar() {
        const nuevo = produce(familiares, draft => {
            draft.push({});
        }
        );
        onChange(nuevo, 'familiares');
    }

    function eliminarFamiliar(index) {
        const nuevo = produce(familiares, draft => {
            draft.splice(index, 1);
        }
        );

        onChange(nuevo, 'familiares');

    }

    return (
        <>
            <div className="form-row">
                <h5 className="col-12">Dirección</h5>
                <FieldTextBox required name="calle" label="Calle" colMd={12} errors={errors?.datosPersonales}
                    value={datosPersonales?.calle} onChange={actualizar} />
                <FieldTextBox required name="numeroExterior" label="Número Exterior" colMd={6} errors={errors?.datosPersonales}
                    value={datosPersonales?.numeroExterior} onChange={actualizar} />
                <FieldTextBox name="numeroInterior" label="Número Interior" colMd={6} errors={errors?.datosPersonales}
                    value={datosPersonales?.numeroInterior} onChange={actualizar} />
                <FieldTextBox required name="colonia" label="Colonia" colMd={12} errors={errors?.datosPersonales}
                    value={datosPersonales?.colonia} onChange={actualizar} />
                <FieldInputCodigoPostal required name="codigoPostal" label="Código Postal" colMd={12} errors={errors?.datosPersonales}
                    value={datosPersonales?.codigoPostal} onChange={actualizar} />
                <FieldDropDown required name="estados" label="Estado" colMd={6} source="estados" filter errors={errors?.datosPersonales} showClear={false}
                    value={datosPersonales?.estados} onChange={actualizar} />
                <FieldDropDown required name="municipios" label="Municipio" colMd={6} source="municipios" filter errors={errors?.datosPersonales} showClear={false}
                    value={datosPersonales?.municipios} params={datosPersonales?.estados} onChange={actualizar} />
                <FieldDropDown required name="localidad" label="Localidad" colMd={6} source="localidades" filter errors={errors?.datosPersonales} showClear={false}
                    value={datosPersonales?.localidad} params={datosPersonales?.estados} onChange={actualizar} />
                <FieldDropDown required name="colonias" label="Colonia" colMd={6} source="colonias" filter errors={errors?.datosPersonales} showClear={false}
                    value={datosPersonales?.colonias} params={{ id: 0, nombre: datosPersonales.codigoPostal }} onChange={actualizar} />
            </div>
            <table className="table">
                <thead className="thead-light">
                    <tr>
                        <th scope="col" style={{ width: '30%' }}>RELACIÓN</th>
                        <th scope="col" style={{ width: '30%' }}>NOMBRE</th>
                        <th scope="col" style={{ width: '30%' }}>FECHA DE NACIMIENTO</th>
                        <th scope="col" className="center" style={{ width: '15%' }}>ANEXO</th>
                        <th className={'ag-grid header-icons'} style={{ width: '10%' }}>
                            {!soloLectura &&
                                <ButtonIcon title={'Agregar Ruta'} iconName={'SiAddthis'}
                                    onClick={agregarFamiliar} className="grid-action" />
                            }
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {familiares?.map((familiar, index) => {
                        return (
                            <tr key={index}>
                                <td className="input">
                                    <FieldDropDown name="relacionFamilia" source="relacionesFamilia" errors={errors[`familiares[${index}]`]}
                                        value={familiar.relacionFamilia} onChange={(value, pName) => actualizarFamiliar(index, value, pName)} />
                                </td>
                                <td className="input">
                                    <FieldTextBox name="nombre" errors={errors[`familiares[${index}]`]}
                                        value={familiar.nombre} onChange={(value, pName) => actualizarFamiliar(index, value, pName)} />
                                </td>
                                <td className="input">
                                    <FieldCalendar name="fechaNacimiento" errors={errors[`familiares[${index}]`]} className="small" showButtonBar={false}
                                        value={familiar.fechaNacimiento} onChange={(value, pName) => actualizarFamiliar(index, value, pName)} />
                                </td>
                                <td className="input center">
                                    <InputFile name="acta" value={familiar.acta} errors={errors[`familiares[${index}]`]}
                                        onChange={(value, pName) => actualizarFamiliar(index, value, pName)} />
                                </td>
                                <td className="input">
                                    {!soloLectura &&
                                        <ButtonIcon title={'Eliminar'}
                                            onClick={() => eliminarFamiliar(index)}
                                            iconName={'RiDeleteBinLine'} />
                                    }
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
};

export default DatosPersonales;