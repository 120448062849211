import * as yup from "yup";

const vacacionesValidacionEsquema = yup.object().shape({
    empleado: yup.mixed().required(),
    dias: yup.number().required(),
    fechaPago: yup.date().required(),
    fechaInicio: yup.date().required(),
    fechaRegreso: yup.date().required(),
    nota: yup.string().required()
});

export default vacacionesValidacionEsquema;
