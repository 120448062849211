import useServiceFactory from "../serviceFactory";

const apiUrl = `/operaciones/viajes/UnidadNotificaciones`;

const useApiUnidadNotificacion = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        get: async (viajeId, showLoading) => service.get(`${apiUrl}/${viajeId}`, { showLoading: showLoading ?? true }),
        save: async (data) => service.post(`${apiUrl}`, data),
        delete: async (id) => service.delete(`${apiUrl}/${id}`),
        getComentarios: async (viajeId, showLoading) =>
            service.get(`${apiUrl}/monitoreo/${viajeId}`, { showLoading: showLoading ?? true }),
    };
};

export default useApiUnidadNotificacion;
