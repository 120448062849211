import moment from "moment";
import _ from "lodash"

const tiposFecha = {
    fechaCompromiso: 571,
    fechaBaja: 572,
    cambioCompromiso: 573
}

const generarRangoFechas = (fechaInicio, fechaFin) => {
    let fechas = [];

    // Iterar sobre cada día dentro del rango
    let fechaActual = new Date(fechaInicio);
    while (fechaActual <= fechaFin) {
        fechas.push(new Date(fechaActual).toISOString().slice(0, 10));
        fechaActual.setDate(fechaActual.getDate() + 1);
    }

    return fechas;
}

const obtenerDataSet = (fechas, allData, label, color, datalabels) => {
    const data = []
    for (let fechaActual of fechas) {
        const fecha = moment(fechaActual)
        data.push(allData.find(e => fecha.isSame(e.fecha, 'day'))?.cantidad ?? 0);
    }

    return {
        type: 'bar',
        label: label,
        backgroundColor: color,
        data,
        datalabels
    }
}


export const sinOperadorDatosParse = (fechaDe, fechaHasta, datos, onClick) => {
    const fechas = generarRangoFechas(fechaDe, fechaHasta);
    let stackedData = {
        labels: fechas,
        datasets: []
    }

    stackedData.datasets.push(obtenerDataSet(
        fechas,
        datos.filter(e => e.tipo === tiposFecha.fechaBaja),
        'Bajas',
        '#CD6155',
        {
            formatter: function (value) {
                return value === 0 ? '' : value;
            }
        }
    ))

    const sinOperadorArray = datos.filter(e => e.tipo == null);

    stackedData.datasets.push(
        obtenerDataSet(
            fechas,
            sinOperadorArray,
            'Sin Operador',
            '#ABB2B9',
            {
                color: 'black'
            }
        )
    )

    stackedData.datasets.push(obtenerDataSet(
        fechas,
        datos.filter(e => e.tipo === tiposFecha.fechaCompromiso),
        'Compromiso',
        '#7DCEA0'
    ))

    stackedData.datasets.push(obtenerDataSet(
        fechas,
        datos.filter(e => e.tipo === tiposFecha.cambioCompromiso),
        'Cambio Compromiso',
        '#DBA901'
    ))

    stackedData.datasets.push(
        obtenerDataSet(
            fechas,
            [],
            '',
            '#F3F5FB',
            {
                align: 'end',
                anchor: 'end',
                color: 'red',
                formatter: function (value, context) {
                    const fecha = moment(fechas[context.dataIndex])
                    const datosFecha = datos.filter(e => fecha.isSame(e.fecha, 'day'));
                    const total = _.sumBy(datosFecha, 'cantidad');
                    return total === 0 ? '' : `${total}`;
                },
                font: {
                    size: 18,
                    weight: 'bold'
                },
                listeners: {
                    click: function (context) {
                        const fecha = moment(fechas[context.dataIndex])
                        onClick(fecha._d)
                    }
                }
            }
        )
    )

    return stackedData;
}

