/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'

const SimplePager = ({index, length, onChangeIndex}) => {

    const onNext = () => {
        if (index > 0)
            onChangeIndex(index -1 );
    }

    const onPrev = () => {
      if (index < length - 1)
          onChangeIndex(index + 1);
    }

    return (
        <>
            <div style={{display: "flex", alignItems: "center", justifyContent: 'space-between', width: '85px'}}>
                <i className="pi pi-angle-left" style={{cursor: index === 0 ? '' : 'pointer'}}
                   onClick={onNext}/>
                <span>
                {`${index + 1} / ${length}`}
            </span>
                <i className="pi pi-angle-right"  style={{cursor: index < length ? 'pointer' : ''}}
                   onClick={onPrev}/>
            </div>
        </>
    )
}

export default SimplePager;
