import "./IconTabs.css"

import {createContext, useState} from 'react';

export const IconTabContext = createContext({});

const useTabIcons = () => {
    const [currentTab, setCurrentTab] = useState(0)

    const onChangeTab = (newTabId)=>{
        setCurrentTab(newTabId)
    }

    const active = (tabId)=>{
        return  tabId === currentTab
    }

    return{
        active,
        onChangeTab,
    }
}

const IconTabs = ({children}) => {
    const tabs = useTabIcons();

    const activeTab = children.find(e=> tabs.active(e.props.tabId))

    return (
        <IconTabContext.Provider value={tabs}>
            <div className={'icon-tabs'}>
                {children}
            </div>
            <div className={'icon-tab-container'}>
                {activeTab.props.children}
            </div>
        </IconTabContext.Provider>
    )
}
export default IconTabs;