/* eslint-disable react-hooks/exhaustive-deps */
import produce from "immer";
import {Button} from "primereact/button";
import {Sidebar} from "primereact/sidebar";
import {useState} from "react";
import {useEffect, useReducer} from "react";
import useApiConvenios from "../../../api/comercial/convenios";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import InputFile from "../../../shared/controls/inputFile";
import formReducer from "../../../shared/forms/formReducer";

const DocumentacionFisica = ({convenioId, paradaId, clienteId, visible, hide, onSave, subClienteId}) => {
    const api = useApiConvenios();
    const [documentos, setDocumentos] = useState([]);
    const defaultEntity = () => ({
        entity: {
            id: 0,
            documentos: []
        }
    });

    const [{entity}, dispatch] = useReducer(formReducer, {}, defaultEntity);

    useEffect(() => {
        async function cargar() {
            const result = await api.paradaDocumentos(paradaId);
            dispatch({type: 'update', value: result});
            let docs = await api.documentos(clienteId, subClienteId);
            docs?.forEach(d => {
                d.seleccionado = result.documentos.some(e => e.documentacionId === d.id);
            });
            setDocumentos(docs);
        };

        if ((paradaId ?? 0) > 0) {
            cargar();
        }
    }, []);

    const actualizar = (value, propertyName, index) => {
        const newValue = produce(documentos, (draft) => {
            draft[index][propertyName] = value;
        });

        setDocumentos(newValue);
        const newList = newValue.filter(d => d.seleccionado);
        dispatch({type: 'update', value: newList, name: 'documentos'});
    };

    const close = () => {
        clean();
        hide();
    };

    const clean = () => {
        dispatch({
            type: 'update',
            value: {...defaultEntity}
        });
    };

    const save = async () => {
        await api.actualizarDocumentos(convenioId, paradaId, entity.documentos);
        close();
        onSave();
    };

    return (
        <Sidebar visible={visible} position={'right'} dismissable={false} onHide={hide}
                 className={'p-sidebar-md'}>
            <div className="form-container full-height">
                <h5>Documentos</h5>
                <table className="table table-borderless">
                    <thead className="thead-light">
                    <tr>
                        <th scope="col" style={{width: '10%'}}></th>
                        <th scope="col" style={{width: '20%'}}>DOCUMENTO</th>
                        <th scope="col" style={{width: '20%'}}>NOMBRE</th>
                        <th scope="col" style={{width: '30%'}}>ESPECIFICACIONES</th>
                        <th scope="col" style={{width: '20%'}}>ANEXO</th>
                    </tr>
                    </thead>
                    <tbody>
                    {documentos?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <FieldCheckBox name="seleccionado" value={item.seleccionado}
                                                   onChange={(value, pname) => actualizar(value, pname, index)}/>
                                </td>
                                <td>{item?.documento}</td>
                                <td>{item?.descripcion}</td>
                                <td>
                                    {item?.especificaciones?.map(((row, idx) => {
                                        return (
                                            <span key={idx}>{idx > 0 ? ', ' : ''}{row.nombre}</span>
                                        );
                                    }))}
                                </td>
                                <td>
                                    <InputFile name="anexo" value={item?.anexo} disabled={true}/>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            <div className="form-footer">
                <Button label="Cancelar" className="btn btn-outline-primary" type="button" onClick={close}/>
                <Button label="Guardar" type="button" onClick={save}/>
            </div>
        </Sidebar>
    );
};

export default DocumentacionFisica;
