/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
const EquiposEmpleado = ({equipos, onChange, soloConsulta}) => {
    return (
        <>
            <div className="form-row">
                <table className="table table-borderless">
                    <thead className="thead-light">
                    <tr>                   
                        <th style={{width: '25%'}}>CATEGORIA</th>
                        <th style={{width: '25%'}}>MARCA</th>
                        <th style={{width: '35%'}}>MODELO</th>           
                    </tr>
                    </thead>
                    <tbody>
                    { equipos?.map((item, index) => {
                        return (
                            <tr key={index}>                                                            
                                <td>{item.equipoAsignacion?.categoria?.nombre}</td>                            
                                <td>{item.equipoAsignacion?.marcaEquipo?.nombre} {item.equipoAsignacion?.lineaAbc}</td> 
                                <td>{item.equipoAsignacion?.modeloEquipo?.nombre} {item.equipoAsignacion?.identificador}</td> 
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </>
    )
};


export default EquiposEmpleado;
