import React from "react";
import FieldTextBox from "../../shared/controls/fieldTextBox";

const FiltroConceptosCobro = ({filtro, onChange}) => {
    return(
        <>
        <FieldTextBox name="nombre" label="Nombre" colMd={2} value={filtro.nombre} onChange={onChange}/>        
        <FieldTextBox name="claveSAT" label="Clave SAT" colMd={2} value={filtro.claveSAT} onChange={onChange}/>
        </>
    )
};

export default FiltroConceptosCobro;