import React, {useState} from "react";
import FieldTextBox from "../../../../shared/controls/fieldTextBox";
import {Button} from "primereact/button";
import FieldInputNumber from "../../../../shared/controls/fieldInputNumber";
import * as numeral from "numeral";
import ButtonIcon from "../../../../shared/controls/ButtonIcon";
import Casetas from "./casetas";



const FormaCostos = ({costo, name, onChange, errors, unidadDeMedida, viaje, editable}) => {
    const [visibleAutopistas, setVisibleAutopistas] = useState(false);

    function actualizarCostoCasetas(costoCasetas, casetasExcluidas) {
        const nuevoCosto =  costo.actualizarCuota(costoCasetas * (viaje.esViajeRedondo ? 2 : 1), casetasExcluidas);
        onChange(nuevoCosto, name);
    }

    return (
        <>
            <div className="form-row">
                <FieldInputNumber name="longitud" label={unidadDeMedida.nombre} colMd={3} error={errors?.longitud} disabled={!editable}
                                  value={costo.longitud}
                                  onChange={(valor, nombre) => onChange(costo.actualizar(valor, nombre), name)}/>
                <FieldInputNumber name="diasEnRuta" label="Días en Ruta" colMd={3} error={errors?.diasEnRuta} fractionDigits={2}
                                  value={costo.diasEnRuta} disabled={!editable}
                                  onChange={(valor, nombre) => onChange(costo.actualizar(valor, nombre), name)}/>
            </div>
            <div className="form-row">
                <FieldInputNumber name="rendimiento" label={`Rendimiento (${unidadDeMedida.abreviatura}/Lt)`} colMd={3}
                                  fractionDigits={2}
                                  error={errors?.rendimiento}
                                  value={costo.rendimiento}
                                  disabled={!editable}
                                  onChange={(valor, nombre) => onChange(costo.actualizar(valor, nombre), name)}/>
                <FieldInputNumber name="diesel" label="Diesel (Lt)" colMd={3} fractionDigits={2}
                                  value={costo.diesel} disabled>
                </FieldInputNumber>
                <FieldInputNumber name="costoDiesel" label="Costo Diesel ($/Lt)" colMd={3} fractionDigits={2}
                                  error={errors?.costoDiesel}
                                  value={costo.costoDiesel}
                                  disabled={!editable}
                                  onChange={(valor, nombre) => onChange(costo.actualizar(valor, nombre), name)}/>
                <table className="table table-borderless">
                    <thead>
                    <tr>
                        <th scope="col" style={{width: '30%'}}/>
                        <th scope="col" style={{width: '25%'}}>COSTO</th>
                        <th scope="col" style={{width: '25%'}}>COSTO
                            POR {unidadDeMedida.abreviatura?.toUpperCase()}</th>
                        <th style={{width: '10%'}}/>
                    </tr>
                    </thead>
                    <tbody>
                    {costo.gastos.map((gasto, index) => {
                        return (
                            <React.Fragment key={index}>
                                <tr>
                                    <td className={gasto.esOtroGasto ? 'input' : 'd-flex p-ai-center'} >
                                        {gasto.esOtroGasto ?
                                            <FieldTextBox value={gasto.tipoCostoNombre} disabled={!editable}
                                                          onChange={(valor) => onChange(costo.actualizarGasto(index, 'tipoCostoNombre', valor), name)}
                                            /> :
                                            <>
                                                {gasto.tipoCostoNombre}
                                                {gasto.esAutopista  &&
                                                <ButtonIcon title={'Expander'} iconName={visibleAutopistas ? 'MdExpandLess' : 'MdExpandMore'}
                                                            onClick={()=>setVisibleAutopistas(!visibleAutopistas)}/>
                                                }
                                            </>
                                        }
                                    </td>
                                    <td className="input">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">$</span>
                                            <FieldInputNumber disabled={gasto.esDiesel || !editable} fractionDigits={2}
                                                              value={gasto.costo}
                                                              onChange={(valor) => onChange(costo.actualizarGasto(index, 'costo', valor), name)}
                                            />
                                        </div>
                                    </td>
                                    <td className="right">
                                        {numeral(gasto.costoPorKilometro).format('$0,0.00')}
                                    </td>
                                    <td className="input right">
                                        {gasto.esOtroGasto && editable &&
                                        <ButtonIcon title={'Eliminar'} onClick={() => {
                                            onChange(costo.eliminarGasto(index), name)
                                        }} iconName={'RiDeleteBinLine'}/>
                                        }
                                    </td>
                                </tr>
                                {visibleAutopistas && gasto.esAutopista &&
                                <tr>
                                    <td colSpan={4}>
                                        <Casetas viaje={viaje} onSeleccionCaseta={actualizarCostoCasetas} casetasExcluidas={costo.casetasExcluidas}/>
                                    </td>
                                </tr>
                                }
                            </React.Fragment>
                        )
                    })}
                    <tr>
                        <td colSpan={4}>
                            {editable &&
                            <Button label="Agregar Otro Gasto" icon="pi pi-plus-circle" type="button"
                                    className="p-button-outlined"
                                    onClick={() => {
                                        onChange(costo.agregarGasto(), name)
                                    }}
                            />
                            }
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td>Costo por {unidadDeMedida.abreviatura}</td>
                        <td/>
                        <td className="right">{numeral(costo.costoPorKilometro).format('$0,0.00')}</td>
                    </tr>
                    <tr>
                        <td>Costo por Ruta</td>
                        <td/>
                        <td className="right">{numeral(costo.costoRuta).format('$0,0.00')} </td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </>
    );
};

export default FormaCostos;

