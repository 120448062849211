import useServiceFactory from "../serviceFactory";

const apiUrl = `/administracion/liquidacionReporte`;

const useApiLiquidacionReporte = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URL});
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, {params, showLoading: true}),
        obtener: async (empleadoId,id) => service.get(`${apiUrl}/empleado/${empleadoId}/${id}`, {showLoading: true}),
        cancelarliquidacion: async (liquidacionId) => service.post(`${apiUrl}/cancelarliquidacion/${liquidacionId}`, {showSuccess:false}),
        excel: async (params) => service.get(`${apiUrl}/excel`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `LiquidacionesReporte.xlsx`
            }),
        excelconDetalle: async (params)=>service.get(`${apiUrl}/excelconDetalle`,
        {
                params,
                showLoading:true,
                download:true,
                fileName:`LiquidacionesReporteconDetalle.xlsx`
        })
    }
};

export default useApiLiquidacionReporte;
