/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import useInputValidation from "./useInputValidation";
import ClipboardImage from "../../controls/clipboardImage/ClipboardImage";

const FormClipboardImage = ({value, parent, onChange, field, formDisabled, disabled, required, height, validators, onValidate}) => {

    const {validationErrorCss} = useInputValidation({
        value,
        field,
        required,
        validators,
        onValidate,
        parent
    });

    return (
        <>
            <ClipboardImage
                value={value}
                disabled={disabled ?? formDisabled}
                onChange={onChange}
                height={height}
                className={validationErrorCss}
            />
        </>
    )
}

export default FormClipboardImage;
