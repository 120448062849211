import useServiceFactory from "../serviceFactory";

const apiUrl = `/catalogos/direcciones`;
const apiGeocercasUrl = `/operaciones/gps`;

const useApiDirecciones = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URL});
    return {
        obtener: async (id) => service.get(`${apiUrl}/${id}`, {showLoading: true}),
        filtrar: async (params) => service.get(`${apiUrl}`, {params}),
        guardar: async (params) => service.post(`${apiUrl}`, params),
        actualizar: async (id, params) => service.put(`${apiUrl}/${id}`, params),
        actualizarGeocercas: async () => service.put(`${apiGeocercasUrl}/geocercas`, {showLoading: true}),
        validarDireccion: async (params) => service.get(`${apiUrl}/validarDireccion`, {params}),
        codigoPostal: async (params) => service.get(`${apiUrl}/codigoPostal`, {params}),
        localidaddes: async (params) => service.get(`${apiUrl}/localidaddes`, {params})
    }
};

export default useApiDirecciones;
