/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import agGridHelpers from "../../shared/ag-grid-helper";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { EditIconRenderer, GridEditHeader, ReadOnlyCheckBoxRenderer } from "../../shared/ag-grid-helper/GridRenderers";
import Field from "../../shared/forms/Field";
import FormMultiSelect from "../../shared/forms/FormFields/FormMultiSelect";
import FormTextBox from "../../shared/forms/FormFields/FormTextBox";
import Buttons from "../../shared/forms/FormFilter/Buttons";
import Filters from "../../shared/forms/FormFilter/Filters";
import FormFilter from "../../shared/forms/FormFilter/FormFilter";
import useForm from "../../shared/forms/useForm";
import useApiModelosEquipos from "../../api/catalogos/useApiModelosEquipos"
import SidebarEditForm from "../../shared/forms/SideBarEditForm";
import Form from "../../shared/forms/Form";
import FormCheckBox from "../../shared/forms/FormFields/FormCheckBox";
import constantes from "../constantes";
import useSeguridad from "../../Seguridad/useSeguridad";

const filtroInicial = {
    nombre: '', activo: true, esModeloCelular:false 
};

const ModelosEquipos = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.modelosEquipo);
    const { values: filter, onChange: onFilterChange, onClear } = useForm(filtroInicial);
    const estatus = [{ id: 1, nombre: "Activa" }, { id: 2, nombre: "Inactiva" }];
    const [datos, setDatos] = useState([]);
    const [currentId, setCurrentId] = useState({});
    const api = useApiModelosEquipos();

    const filtrar = async (filtro) => {
        const result = await api.filtrar(filtro);
        setDatos(result);
    };

    const gridConfig = {
        rowSelection: 'single',
        enableSorting: true,
        frameworkComponents: {
            readOnlyCheckBoxRenderer: ReadOnlyCheckBoxRenderer,
            editRenderer: EditIconRenderer,
            editHeader: GridEditHeader
        },
        defaultColDef: agGridHelpers.defaultColumns        
    };

    const sideBarCommon = {
        onSaved: () => {
            filtrar(filter).then();
            setCurrentId({});
        },
        onCancel: () => setCurrentId({})
    };

    return (
        <>
            <h5>Modelo Equipo</h5>
            <FormFilter columns={4} filter={filter} onChange={onFilterChange} onClear={onClear} onFilter={(filtro) => filtrar(filtro, null)}>
                <Filters>
                    <Field label={'Nombre'} field={'nombre'} render={FormTextBox} />
                    <Field label={'Estatus'} field={'activo'} options={estatus} render={FormMultiSelect} />
                </Filters>
                <Buttons filter={true} clear={true}></Buttons>
            </FormFilter>
            <div className="ag-theme-balham grid-principal">
                <AgGridReact {...gridConfig}
                    onGridReady={() => filtrar(filter).then()}
                    rowHeight={40}
                    rowData={datos}
                    context={{ onEdit: (id) => setCurrentId({ descuento: id }), onAdd: () => setCurrentId({ descuento: 0 }) }}>
                    <AgGridColumn field="nombre" headerName="Nombre" cellClass={'left'} />
                    <AgGridColumn field="activo" headerName="Activo" cellClass={'center'} cellRenderer={'readOnlyCheckBoxRenderer'} />
                    <AgGridColumn field="esModeloCelular" headerName="Es Modelo Celular " cellClass={'center'} cellRenderer={'readOnlyCheckBoxRenderer'} />
                    <AgGridColumn maxWidth={60} headerComponent={soloLectura ? '' : 'editHeader'} cellClass={'center'} cellRenderer="editRenderer" />
                </AgGridReact>
            </div>
            <SidebarEditForm id={currentId.descuento}
                initialValues={{ activo: true }}
                getByIdFunction={api.obtener}
                saveFunction={api.guardar}
                formDisabled={soloLectura}
                {...sideBarCommon}>
                <Form>
                    <h5>Modelos Equipo</h5>
                    <Field field={"nombre"} label={"Nombre"} required={true} render={({onChange, parent, ...props }) =>
                        <FormTextBox {...props} 
                        onChange={(nombre) =>
                            onChange(
                                nombre
                            )}
                        maxLength={200} 
                        />} />
                    <Field field={"activo"} label={"Activo"} render={FormCheckBox} />
                    <Field field={"esModeloCelular"} label={"Es Modelo Celular"} render={FormCheckBox} />
                </Form>
            </SidebarEditForm>
        </>
    )
};

export default ModelosEquipos;