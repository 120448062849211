import SeguimientoIcon, {} from "../../../../icons/SeguimientoYMonitoreo/SeguimientoIcons";
import {tipoIndicador} from "../SeguimentoConstantes";
import {getColorIndicadores, getContrast} from "../SeguimientoMonitoreutility";

const Indicadores = ({viaje, onMouseEnter, onMouseLeave}) => {

    const handleMouseEnter = (e, tipo) => {
        onMouseEnter(e, tipo, viaje)
    }


    const indicadores= viaje?.indicadores??{};

    const velocidad = indicadores[tipoIndicador.velocidad];
    const motor =  indicadores[tipoIndicador.motor];
    const geocercaProhibida =  indicadores[tipoIndicador.geocercaProhibida];
    const gps =  indicadores[tipoIndicador.gps];
    const lastPosition =  indicadores[tipoIndicador.lastPosition];
    const diesel =  indicadores[tipoIndicador.diesel];
    const brecha =  indicadores[tipoIndicador.brecha];
    const sinViaje =  indicadores[tipoIndicador.unidadSinViaje];
    const horasManejo =  indicadores[tipoIndicador.horasManejo];


    return (
        <>
            <span className={getColorIndicadores(velocidad)}>
                <SeguimientoIcon iconName={`Velocidad${getContrast(velocidad)}`}
                                 onMouseEnter={(e) => handleMouseEnter(e, tipoIndicador.velocidad)}
                                 onMouseLeave={onMouseLeave}
                />
            </span>
            <span className={getColorIndicadores(motor)}>
                <SeguimientoIcon iconName={`Motor${getContrast(motor)}`}
                                 onMouseEnter={(e) => handleMouseEnter(e, tipoIndicador.motor)}
                />
            </span>
            <span className={getColorIndicadores(geocercaProhibida)}>
                <SeguimientoIcon iconName={`GeocercaProhibida${getContrast(geocercaProhibida)}`}/>
            </span>
            <span className={getColorIndicadores(lastPosition)}>
                <SeguimientoIcon iconName={`LastPosition${getContrast(lastPosition)}`}
                                 onMouseEnter={(e) => handleMouseEnter(e, tipoIndicador.lastPosition)}
                                 onMouseLeave={onMouseLeave}
                />
            </span>
            <span className={getColorIndicadores(gps)}>
                <SeguimientoIcon iconName={`Gps${getContrast(gps)}`}
                                 onMouseEnter={(e) => handleMouseEnter(e, tipoIndicador.gps)}
                                 onMouseLeave={onMouseLeave}
                />
            </span>
            <span className={getColorIndicadores(diesel)}>
                <SeguimientoIcon iconName={`Diesel${getContrast(diesel)}`}
                                 onMouseEnter={(e) => handleMouseEnter(e, tipoIndicador.diesel)}
                                 onMouseLeave={onMouseLeave}
                />
            </span>
            <span className={getColorIndicadores(brecha)}>
                <SeguimientoIcon iconName={`Brecha${getContrast(brecha)}`}
                                 onMouseEnter={(e) => handleMouseEnter(e, tipoIndicador.brecha)}
                                 onMouseLeave={onMouseLeave}
                />
            </span>
            <span className={getColorIndicadores(sinViaje)}>
                <SeguimientoIcon iconName={`SinViaje${getContrast(sinViaje)}`}/>
            </span>
            <span className={getColorIndicadores(horasManejo)}>
                <SeguimientoIcon
                    onMouseEnter={(e) => handleMouseEnter(e, tipoIndicador.horasManejo)}
                    onMouseLeave={onMouseLeave}
                    iconName={`HorasManejo${getContrast(horasManejo)}`}/>
            </span>
        </>
    )
}
export default Indicadores;