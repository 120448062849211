import useServiceFactory from "../serviceFactory";
const apiUrl = `/catalogos/MarcaLlanta`;

const useApiMarcaLlanta = () => {
    const service = useServiceFactory({url: process.env.REACT_APP_API_URL});
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, {params}),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, {showLoading: true}),
        guardar: async (params) => service.post(`${apiUrl}`, params),
        actualizar: async (id,params) => service.put(`${apiUrl}/${id}`, params)  
    }    
};

export default useApiMarcaLlanta;