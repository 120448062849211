import React, {useEffect, useState} from "react";
import ButtonIcon from "./ButtonIcon";
import useInputFile from "./useInputFile";

const ImageFile = ({name, value, onChange}) => {

    const [visibleEliminar, setVisibleEliminar] = useState(false);

    const {
        file,
        updateImage,
        uniqueName,
        download,
        handleUpload
    } = useInputFile(onChange, name);

    useEffect(()=>{
        updateImage(value);
    },[value, updateImage]);

    const eliminarImagen = () => {
        onChange(null, name);
        setVisibleEliminar(false);
    }

    return (
        <>
            <div className="image-upload">
                {file.isEmpty &&
                <>
                    <label htmlFor={uniqueName}>
                        <img  alt="Buscar imagen" src={process.env.PUBLIC_URL + '/img/Persona.png'}  width="100" height="100" />
                    </label>
                    <input type="file" id={uniqueName} accept="image/*" capture="environment" onChange={handleUpload}/>

                </>
                }
                {!file.isEmpty &&
                    <div className="image-container" onMouseOver={()=>setVisibleEliminar(true)} onMouseLeave={()=>setVisibleEliminar(false)} >
                        <img alt="Imagen" src={file.url}  width="100" height="100" />
                        {visibleEliminar && <ButtonIcon className="download-image" iconName="MdAttachFile" onClick={download}/> }
                        {visibleEliminar && <ButtonIcon className="clear" iconName="RiDeleteBinLine" onClick={eliminarImagen}/> }
                    </div>
                }
            </div>
        </>
    )
};

export default ImageFile;
