/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {Dialog} from "primereact/dialog";
import useApiLiberacionViaje from "../../api/administracion/useApiLiberacionViaje";
import ExGrid from "../../shared/Grid/ExGrid";
import ExColumn from "../../shared/Grid/ExColumn";
import FieldScanner from "../../shared/controls/fieldScanner";
import {Button} from "primereact/button";
import FieldTextArea from "../../shared/controls/fieldTextArea";
import constantes from "../../catalogos/constantes";

const Documentos = ({viajeId, onHide}) => {
    const visible = viajeId != null;
    const api = useApiLiberacionViaje();
    const [datos, setDatos] = useState([])
    const [comentarios, setComentarios] = useState(null);
    const [verComentario, setVerComentario] = useState(false);
    const [id, setId] = useState(0);

    useEffect(() => {
        load().then();
    }, [viajeId])

    const load = async () => {
        if (viajeId == null) {
            setDatos([]);
            return;
        }
        const datos = await api.obtenerDocumentos(viajeId);
        setDatos(datos);
    }

    const solicitarRevision = async () => {
        if (!comentarios)
            return;

        await api.solicitarRevision(viajeId, {id: id, comentarios: comentarios});
        setVerComentario(false);
        await load().then();
    };

    const mostrarSolicitud = (item) => {
        setId(item.id);
        setVerComentario(true);
    };

    const btnSolicitar = (item) => (
        <>
            {item?.archivo !== null && item.estatusId !== constantes.estatusDocumentos.REINTENTAR &&
                <Button label={'Solicitar Revisión'} className="p-button-outlined p-button-danger" type="button"
                        onClick={() => mostrarSolicitud(item)}/>
            }
        </>
    );

    const renderFooterModal = () => {
        return (
            <div>
                <Button label="Cerrar" onClick={() => setVerComentario(false)} className="p-button-outlined"/>
                <Button label="Solicitar" onClick={solicitarRevision} className="p-button-outlined"/>
            </div>
        );
    };

    return (
        <>
            <Dialog header={`Documentos`} visible={visible} style={{width: '55vw'}}
                    onHide={onHide}>
                <ExGrid data={datos}>
                    <ExColumn field={'documento'} title={'Documento'}/>
                    <ExColumn field={'comentarios'} title={'Comentarios'}/>
                    <ExColumn field={'segmento'} title={'Segmento'}/>
                    <ExColumn field={'archivo'} title={'Archivo'} cellRenderer={({value}) =>
                        <FieldScanner value={value}
                                      disabled={true}>
                        </FieldScanner>
                    }/>
                    <ExColumn title={''} cellRenderer={(value) =>
                        btnSolicitar(value.row)}/>
                </ExGrid>
            </Dialog>
            <Dialog header={"Solicitud de revision"} visible={verComentario} style={{width: '50vw'}}
                    onHide={() => setVerComentario(false)} footer={renderFooterModal}>
                <FieldTextArea name="comentarios" label="Comentario" value={comentarios} colMd={12}
                               rows={3} disabled={false} error={!comentarios}
                               onChange={(value) => setComentarios(value)}/>
            </Dialog>
        </>
    )
}

export default Documentos;
