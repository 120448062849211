import IconTabsIconos from "../../icons/IconTabs/IconTabsIconos";
import {useContext} from "react";
import {IconTabContext} from "./IconTabs";

const IconTab = ({tabId, icon, children, ...props}) => {

    const {active, onChangeTab} = useContext(IconTabContext)
    const isActive = active(tabId)
    const getStyle =()=>{
        return  isActive ?
            {
                background: '#0081FF',
            } : {

            }
    }

    return (
        <div style={{width:'100%'}}>
            <div className={'icon-tab'} style={getStyle()} onClick={()=>onChangeTab(tabId)}>
                <IconTabsIconos iconName={`${icon}${isActive ? 'Contraste' : ''}`}/>
            </div>
        </div>
    )
}
export default IconTab;