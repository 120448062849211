/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import agGridHelper from "../../shared/ag-grid-helper";
import useApiEmpresas from "../../api/catalogos/useApiEmpresas";
import { EditIconRenderer, GridEditHeader } from "../../shared/ag-grid-helper/GridRenderers";
import { Sidebar } from 'primereact/sidebar';
import DetalleEmpresa from './DetalleEmpresa';

const Empresas = () => {

    const api = useApiEmpresas();
    const [datos, setDatos] = useState([]);
    const [currentId, setCurrentId] = useState(null);

    useEffect(() => {
        load().then();
    }, []);


    const load = async () => {
        let empresas = await api.filtrar();
        // eslint-disable-next-line
        empresas.map((item) => {
            item.numeroEmpresa = item.saasConfig.company;
            item.ubicacion = item.saasConfig.location;
            item.selloDigital = item.saasConfig.selloDigitalId;
            item.serieDigital = item.saasConfig.serieDigital;
            item.tipoPermisoSCT = item.saasConfig.tipoPermisoSCT;
            item.numeroPermisoSCT = item.saasConfig.numeroPermisoSCT
        });
        setDatos(empresas);
    }


    return (
        <>
            <div className="ag-theme-balham grid-principal cell-flex">
                <AgGridReact
                    rowSelection={'single'}
                    enableSorting={true}
                    defaultColDef={agGridHelper.defaultColumns}
                    rowData={datos}
                    getRowId={e => e.data.id}

                    context={{
                        onEdit: (id) => setCurrentId(id),
                        onAdd: () => setCurrentId(0)
                    }}
                >
                    <AgGridColumn field="nombre" headerName="Nombre" cellClass={'center'} />
                    <AgGridColumn field="numeroEmpresa" headerName="Numero" cellClass={'center'} />
                    <AgGridColumn field="sistemaMetrico" headerName="Sistema Metrico" cellClass={'center'} />
                    <AgGridColumn field="moneda" headerName="Moneda" cellClass={'center'} />
                    <AgGridColumn field="ubicacion" headerName="Ubicacion" cellClass={'center'} />
                    <AgGridColumn field="selloDigital" headerName="Sello Digital" cellClass={'center'} />
                    <AgGridColumn field="serieDigital" headerName="Serie Digital" cellClass={'center'} />
                    <AgGridColumn field="tipoPermisoSCT" headerName="Tipo Permiso SCT" cellClass={'center'} />
                    <AgGridColumn field="numeroPermisoSCT" headerName="Permiso SCT" cellClass={'center'} />
                    <AgGridColumn maxWidth={60} headerComponent={GridEditHeader} cellClass={'center'}
                        cellRenderer={EditIconRenderer} />
                </AgGridReact>
                <Sidebar visible={currentId !== null} position="right" dismissable={false} className="p-sidebar-lg"
                    onHide={() => setCurrentId(null)}>
                    <DetalleEmpresa id={currentId} onClose={() => setCurrentId(null)} onSave={load} />
                </Sidebar>
            </div>
        </>
    )
}

export default Empresas;
