import ExGrid from "./Grid/ExGrid";
import ExColumn from "./Grid/ExColumn";
import moment from "moment";

const Log = ({data}) => {
    return (<>
        <ExGrid data={data} style={{maxHeight:'180px'}}>
            <ExColumn field={'fechaUtc'} title={'Fecha'} cellRenderer={({value})=> moment(value).format("YYYY-MM-DD hh:mm A")}/>
            <ExColumn field={'cambio'} title={'Cambio'}/>
            <ExColumn field={'comentarios'} title={'Comentarios'}/>
            <ExColumn field={'usuario'} title={'Usuario'}/>
        </ExGrid>
    </>)
}
export default Log;