import React, { useState } from "react";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import moment from "moment";
import { FormattedDate } from "react-intl";
import * as yup from "yup";
import YupValidator from "../../../shared/YupValidator";

const esquemaValidacion = yup.object().shape({
    comentario: yup.string().required(),
    estatus: yup.mixed().required(),
});

const NotificacionesIncidencias = ({ notificaciones, actualizar, eliminar, disabled, soloLectura }) => {
    const [notificacion, setNotificacion] = useState({ esSeguimiento: true });
    const [errors, setErrors] = useState({});
    const notificacionesParaMostrar = notificaciones?.filter((e) => !e.eliminado);
    //const idToDelete = notificaciones?.filter((e) => !e.eliminado && !e.esFecha && !e.esCreadoPorSistema)[0].id;

    const onChange = (value, propertyName) => {
        if (propertyName === "esSeguimiento" || propertyName === "esIncidencia") {
            const esSeguimiento = propertyName === "esSeguimiento";
            const esIncidencia = propertyName === "esIncidencia";

            setNotificacion({ ...notificacion, esSeguimiento, esIncidencia });
        } else {
            setNotificacion({ ...notificacion, [propertyName]: value });
        }

        setErrors({});
    };

    const agregar = async () => {
        const validator = new YupValidator(esquemaValidacion);
        const esValido = await validator.validate(notificacion);

        if (!esValido) {
            setErrors(validator.errors);
            return;
        }

        const notificacionesCopia = notificaciones != null ? notificaciones.slice(0) : [];
        const tieneNotificaciones = notificacionesCopia.length > 0;
        const ultimaFecha = tieneNotificaciones ? notificacionesCopia[1].fecha : null;
        let id = tieneNotificaciones ? Math.max(...notificacionesCopia.map((m) => m.id)) + 1 : 1;

        if (!tieneNotificaciones || (ultimaFecha != null && !moment(ultimaFecha).isSame(moment(new Date()), "day"))) {
            notificacionesCopia.splice(0, 0, { id, fecha: new Date(), esFecha: true });
            id++;
        }

        const fecha = new Date();
        notificacionesCopia.splice(1, 0, {
            ...notificacion,
            id,
            fecha,
            hora: moment(fecha).format("HH:mm"),
            esNueva: true,
        });

        actualizar(notificacionesCopia);
        setNotificacion({ esSeguimiento: notificacion.esSeguimiento, esIncidencia: notificacion.esIncidencia });
        setErrors({});
    };

    // const eliminarNotificacion = (notificacion) => {
    //     const notificacionesCopia = notificaciones.map((e) =>
    //         e.id !== notificacion.id
    //             ? e
    //             : {
    //                   ...notificacion,
    //                   eliminado: true,
    //               }
    //     );
    //     const notificacionesDelDia = notificacionesCopia.filter(
    //         (e) => !e.eliminado && moment(e.fecha).isSame(moment(notificacion.fecha), "day")
    //     );

    //     //Si solo queda una notificación se elimina ya que es la fila del día
    //     if (notificacionesDelDia.length === 1) {
    //         const index = notificacionesCopia.findIndex((e) => e.id === notificacionesDelDia[0].id);
    //         notificacionesCopia.splice(index, 1);
    //     }

    //     if (eliminar == null) {
    //         actualizar(notificacionesCopia);
    //     } else {
    //         eliminar(notificacion.id);
    //     }
    // };

    return (
        <>
            <div className="form-row px-2 mt-3">
                {(!disabled || !soloLectura) && (
                    <>
                        <FieldCheckBox
                            name="esSeguimiento"
                            label="Seguimiento"
                            value={notificacion.esSeguimiento}
                            colMd={3}
                            onChange={onChange}
                        />
                        <FieldCheckBox
                            name="esIncidencia"
                            label="Incidencia"
                            value={notificacion.esIncidencia}
                            colMd={6}
                            onChange={onChange}
                        />

                        <div className="col-sm-7 mt-1">
                            <FieldTextBox
                                name="comentario"
                                value={notificacion.comentario}
                                colSm={8}
                                onChange={onChange}
                                errors={errors}
                                maxLength={100}
                            />
                        </div>

                        <div
                            className="col-sm-4 mt-1"
                            style={{ display: notificacion.esSeguimiento ? "block" : "none" }}
                        >
                            <FieldDropDown
                                name="estatus"
                                colMd={4}
                                onChange={onChange}
                                source="estatusSeguimientoViaje"
                                value={notificacion.estatus}
                                filter={false}
                                errors={errors}
                                appendTo={document.body}
                            />
                        </div>

                        <div
                            className="col-sm-4 mt-1"
                            style={{ display: notificacion.esSeguimiento ? "none" : "block" }}
                        >
                            <FieldDropDown
                                name="estatus"
                                colMd={4}
                                onChange={onChange}
                                source="incidenciasViaje"
                                value={notificacion.estatus}
                                errors={errors}
                            />
                        </div>

                        <div className="col-sm-1 mt-1 header-icons">
                            <ButtonIcon
                                title="Agregar"
                                className="grid-action"
                                iconName={"SiAddthis"}
                                onClick={agregar}
                            />
                        </div>
                    </>
                )}

                <div className="col-12 mt-2">
                    <table className="table table-sm align-middle">
                        <tbody>
                            {notificacionesParaMostrar?.map((item, index) => {
                                return (
                                    <tr key={item.id} className={item.esFecha ? "table-light" : ""}>
                                        {item.esFecha ? (
                                            <td colSpan={4}>
                                                <FormattedDate
                                                    value={item.fecha}
                                                    weekday="long"
                                                    day="numeric"
                                                    month="long"
                                                    year="numeric"
                                                />
                                            </td>
                                        ) : (
                                            <>
                                                {!item.eliminado && (
                                                    <>
                                                        <td style={{ width: "15%" }}>
                                                            {moment(item.fecha).format("hh:mm A")}
                                                        </td>
                                                        <td style={{ width: "55%" }}>{item.comentario}</td>
                                                        <td style={{ width: "25%" }}>{item.estatus?.nombre}</td>
                                                        <td className="table-button px-1" style={{ width: "5%" }}>
                                                            {/* {item.id === idToDelete && (
                                                                <ButtonIcon
                                                                    title={"Eliminar"}
                                                                    iconName={"RiDeleteBinLine"}
                                                                    onClick={() => eliminarNotificacion(item)}
                                                                />
                                                            )} */}
                                                        </td>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default NotificacionesIncidencias;
