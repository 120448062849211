const articuloGenerico = {
  "id": 895260,
  "claveSat": "25174400",
  "descripcion": "SISTEMAS DEL INTERIOR DE VEHICULOS",
  "cantidad": 180,
  "unidadConClave": {
    "id": 605,
    "nombre": "H87-Pieza"
  },
  "peso": 0.85,
  "materialPeligroso": "No",
  "enSAT": true,
  "valorMercancia": 0,
  "fraccionValida": true
}

export default articuloGenerico