import { Button } from "primereact/button";
//import UploadMultiple from "../../shared/controls/UploadMultiple";
import React, { useState } from "react";
import useApiConfiguracionLear from "../../api/operaciones/useApiConfiguracionLear";
import produce from "immer";

const DetalleConfiguracionLear = ({ soloLectura, onSave, onChange }) => {
    const [fileList, setFileList] = useState(null);
    const api = useApiConfiguracionLear();
    const files = fileList ? [...fileList] : [];

    const actualizar = (e) => {
        setFileList(e.target.files);
    };

    const guardar = async () => {
        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }
        const result = await api.guardar(formData);
        onChange(result);
        onSave();
    };

    const quitar = async (index) => {
        const newList = produce(files, (draft) => {
            draft.splice(index, 1);
        });

        setFileList(newList);
    };

    return (
        <form>
            <div className="form-container full-height">
                <h5>Facturación Lear</h5>
                <span className="control-fileupload">
                    <input type="file" onChange={actualizar} multiple />
                </span>
                <hr />
                <ul className="list-group">
                    {files.map((file, index) => (
                        <li key={index} className="list-group-item">
                            {file.name} <span className="badge cursor-pointer text-danger" onClick={quitar}>Quitar</span>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="form-footer">
                {!soloLectura && fileList?.length > 0 &&
                    <Button label="Guardar" type="button" onClick={guardar} />
                }
            </div>
        </form>
    )
};

export default DetalleConfiguracionLear;