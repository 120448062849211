
import alerts from "../../alerts";
import {useEmpresa} from "../../../layout/EmpresaProvider";

const uri = process.env.REACT_APP_API_URL;

const useClipboardImage = (value, onChange) => {

    const {empresaId} = useEmpresa();

    const onDelete = async () => {
        const result = await alerts.preguntarSiNo('¿Desea eliminar la imagen?');
        if (result === false)
            return;

        if (onChange != null) {
            onChange({isEmpty: true});
        }
    }

    const getUrl = (value) =>{
        return  value?.file == null ? `${uri}/Archivos/imagen/${empresaId}?url=${value?.url}` : value.url;
    }

    return {
        getUrl,
        onDelete
    };
};

export default useClipboardImage;
