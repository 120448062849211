/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useState } from "react";
import "../administracion.css";
import { useEffect } from "react";
import { Button } from "primereact/button";
import formReducer from "../../shared/forms/formReducer";
import FormHandler from "../../shared/forms/formHandler";
import ajusteValidacionEsquema from "./ajusteValidacionEsquema";
import FieldDropDown from "../../shared/controls/fieldDropDown";
import FieldInputNumber from "../../shared/controls/fieldInputNumber";
import FieldCalendar from "../../shared/controls/fieldCalendar";
import FieldTextArea from "../../shared/controls/fieldTextArea";
import useApiAjusteDiesel from "../../api/administracion/useApiAjusteDiesel";
import * as numeral from "numeral";
import constantes from "../../catalogos/constantes";
import FieldTextBox from "../../shared/controls/fieldTextBox";

import useSeguridad from "../../Seguridad/useSeguridad";

const AjusteDieselDetalle = ({ movimientoId, onSave, close, soloLectura }) => {
    const api = useApiAjusteDiesel();
    const seguridad = useSeguridad();
    const defaultEntity = () => ({ entity: { id: 0, editable: true } });
    const [{ entity, errors }, dispatch] = useReducer(formReducer, {}, defaultEntity);
    const [permisoAjusteDiesel, setPermisoAjusteDiesel] = useState(false);
    let permisoEditarFechaRendimiento = seguridad.tieneAcceso(constantes.opciones.editarFechaRegistro);

    const formHandler = new FormHandler({
        validationSchema: ajusteValidacionEsquema,
        api,
        dispatch,
    });

    useEffect(() => {
        async function cargar() {
            let permisoAjusteDiesel = seguridad.tieneAcceso(constantes.opciones.EditarAjusteDiesel);
            setPermisoAjusteDiesel(permisoAjusteDiesel);
            const movimiento = await api.obtener(movimientoId);
            if (movimientoId === 0) {
                movimiento.editable = true;
            }
            dispatch({ type: "update", value: movimiento });
        }

        cargar().then();
    }, []);

    const actualizar = (value, propertyName) => {
        if (propertyName === "motivoDescuento" && value.id === constantes.motivoDescuentos.ajusteParaLiquidar) {
            dispatch({ type: "update", value: null, name: "unidad" });
            dispatch({ type: "update", value: null, name: "ubicacion" });
        }
        dispatch({ type: "update", value: value, name: propertyName });
        obtenerDescuentoSemanal(value, propertyName);
    };

    const deshabilitadoAjuste = (entity) => {
        return !entity.editable && (!permisoAjusteDiesel || entity.liquidado);
    };   

    const obtenerDescuentoSemanal = (value, propertyName) => {
        if (propertyName !== "ajuste" && propertyName !== "exhibiciones") return;

        const monto = propertyName === "ajuste" ? value : entity.ajuste ?? 0;
        const exhibiciones = propertyName === "exhibiciones" ? value : entity.exhibiciones ?? 0;

        if (exhibiciones > 0) {
            const montoParcial = +(monto / exhibiciones).toFixed(2);
            dispatch({ type: "update", value: montoParcial, name: "montoParcial" });
        }
    };

    const guardar = async () => {
        await formHandler.save(entity);

        if (formHandler.valdation.result) {
            onSave();
        }
    };

    return (
        <>
            <div className="form-container full-height" style={{ paddingLeft: "1px" }}>
                <div className="form-row">
                    <h5 className="col-12">Ajuste</h5>

                    <FieldDropDown
                        name="motivoDescuento"
                        source="motivoDescuentoDiesel"
                        errors={errors}
                        label="Motivo Descuento"
                        colMd={6}
                        filter={true}
                        required={true}
                        value={entity.motivoDescuento}
                        onChange={actualizar}
                    />
                    <FieldDropDown
                        name="empleado"
                        source="empleados"
                        errors={errors}
                        label="Empleado"
                        colMd={12}
                        params={{ puestoIds: [constantes.puestos.operador] }}
                        filter={true}
                        value={entity.empleado}
                        onChange={actualizar}
                        disabled={entity.id > 0}
                    />
                </div>
                <div className="form-row">
                    {entity.motivoDescuento?.id !== constantes.motivoDescuentos.ajusteParaLiquidar && (
                        <>
                            <FieldDropDown
                                name="unidad"
                                source="unidades"
                                errors={errors}
                                params={{ todasLasEmpresas: true }}
                                label="Unidad"
                                colMd={6}
                                filter={true}
                                required={true}
                                value={entity.unidad}
                                onChange={actualizar}
                            />

                            <FieldTextBox
                                name="ubicacion"
                                label="Ubicacion"
                                value={entity.ubicacion}
                                colMd={6}
                                onChange={actualizar}
                                required={true}
                                errors={errors}
                            />
                        </>
                    )}
                </div>

                <div className="form-row">
                    <FieldCalendar
                        name="fechaRendimiento"
                        label="Fecha de rendimiento"
                        colSm={4}
                        value={entity.fechaRendimiento}
                        disabled={!permisoEditarFechaRendimiento}
                        className="xs"
                        showButtonBar={false}
                        errors={errors}
                        onChange={actualizar}
                    />
                    <FieldInputNumber
                        name="diferenciaLitros"
                        label="Diferencia (LT)"
                        value={entity.diferenciaLitros}
                        disabled={!entity.editable}
                        colSm={4}
                        errors={errors}
                        onChange={actualizar}
                    />
                    <FieldInputNumber
                        name="ajuste"
                        label="Ajuste ($)"
                        value={entity.ajuste}
                        colSm={4}
                        errors={errors}
                        fractionDigits={2}
                        onChange={actualizar}
                        disabled={deshabilitadoAjuste(entity)}
                    />
                </div>
                <div className="form-row">
                    <FieldInputNumber
                        name="exhibiciones"
                        label="# Exhibiciones"
                        value={entity.exhibiciones}
                        disabled={deshabilitadoAjuste(entity)}
                        colSm={4}
                        errors={errors}
                        onChange={actualizar}
                    />
                    <FieldCalendar
                        name="fechaADescontar"
                        label="Fecha comienza a descontar"
                        colSm={4}
                        value={entity.fechaADescontar}
                        disabled={deshabilitadoAjuste(entity)}
                        className="xs"
                        showButtonBar={false}
                        errors={errors}
                        onChange={actualizar}
                    />
                    <div className="col-sm-4 d-flex flex-column justify-content-center pl-2">
                        <div>
                            <b>Desc. Sem</b>
                        </div>
                        <div>{numeral(entity.montoParcial).format("$0,0.00")}</div>
                    </div>
                </div>
                <div className="form-row">
                    <FieldTextArea
                        name="nota"
                        label="Nota"
                        value={entity.nota}
                        colSm={12}
                        onChange={actualizar}
                        rows={4}
                        errors={errors}
                        disabled={deshabilitadoAjuste(entity)}
                    />
                </div>
            </div>

            <div className="form-footer">
                <Button label="Salir" className="p-button-outlined" onClick={close} />
                {!deshabilitadoAjuste(entity) && !soloLectura && (
                    <Button className="btn-primary" label="Guardar" type="button" onClick={guardar} />
                )}
            </div>
        </>
    );
};

export default AjusteDieselDetalle;
