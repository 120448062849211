/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import useApiEstatusDiarios from "../../api/reportes/useEstatusDiariosApi";
import IconMantenimiento from "../IconMantenimiento";
import {Checkbox} from "primereact/checkbox";
import produce from "immer";

const AgregarUnidadModal = ({visible, actualizar, onHide}) => {
    const api = useApiEstatusDiarios();

    const [unidadesDisponibles, setUnidadesDisponibles] = useState([]);

    useEffect(() => {
        async function cargar() {
            const unidades = await api.obtenerUnidadesDisponibles();
            setUnidadesDisponibles(unidades);
        }

        cargar().then();
    }, [visible]);

    const seleccionar = (e, item, index) => {
        const {name, checked} = e.target;
        const nuevoUnidadesDisponibles = produce(unidadesDisponibles, (draft) => {
            draft[index][name] = checked;
        });

        setUnidadesDisponibles(nuevoUnidadesDisponibles);
    };
    const onSave = async () => {
        const ids = unidadesDisponibles.filter((e) => e.check).map((e) => e.unidadId);

        await api.guardarUnidadesDisponibles({unidadIds: ids});
        onHide();
        actualizar();
    };

    const renderFooter = () => {
        return (
            <div>
                <Button
                    label={"Guardar"}
                    disabled={unidadesDisponibles.filter((e) => e.check).map((e) => e.unidadId).length === 0}
                    className="guardar"
                    icon="pi pi-check"
                    autoFocus
                    onClick={onSave}
                />
            </div>
        );
    };

    return (
        <Dialog
            header={`Seleccionar unidades`}
            visible={visible}
            position={"center"}
            modal
            style={{width: "70vw"}}
            footer={renderFooter("displayPosition")}
            onHide={onHide}
            className="p-dialog-content-Overflow"
            draggable={false}
            resizable={false}
        >
            <table className="table table-sm table-hover table-fixed">
                <thead className="thead-light">
                <tr>
                    <th className="center" style={{width: "20%"}} scope="col">
                        No Economico
                    </th>
                    <th className="center" style={{width: "20%"}} scope="col">
                        Nombre
                    </th>
                    <th style={{width: "60%", textAlign: "left"}} scope="col">
                        Ubicacion
                    </th>
                    <th className="center" style={{width: "20%"}} scope="col">
                        Mantenimiento
                    </th>
                    <th className="center" style={{width: "20%"}} scope="col"></th>
                </tr>
                </thead>

                <tbody style={{height: "calc(100vh - 300px)", overflowY: "auto"}}>
                {unidadesDisponibles?.map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            <tr key={index}>
                                <td className="input center">{item.noEconomico}</td>
                                <td className="input center">{item.nombre}</td>
                                <td className="text-left" style={{width: "45%"}}>
                                    {item.ubicacion}
                                </td>

                                <td className="text-center">
                                    <div style={{display: "flex", marginLeft: "40px"}}>
                                        {<IconMantenimiento unidad={item}/>}
                                    </div>
                                </td>
                                <td className="center option ok">
                                    <Checkbox
                                        name="check"
                                        onChange={(e) => seleccionar(e, item, index)}
                                        checked={item.check}
                                    />
                                </td>
                            </tr>
                        </React.Fragment>
                    );
                })}
                </tbody>
            </table>
        </Dialog>
    );
};

export default AgregarUnidadModal;
