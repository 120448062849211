import React, { useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import agGridHelper from "../../../shared/ag-grid-helper";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import useApiLlantas from "../../../api/taller/useApiLlantas";
import FiltroLlantas from "./filtroLlantas";
import { Sidebar } from "primereact/sidebar";
import DetalleLlanta from "./detalleLlanta";
import { getSessionState } from "../../../hooks/useSessionState";
import Filtro from "../../../shared/componentes/filtro";
import RotacionLlanta from "./RotacionLlanta";

const Llantas = ({ soloLectura }) => {
    const filtroKey = 'filtrosLlantas';
    const [datos, setDatos] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [id, setId] = useState(false);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const api = useApiLlantas();
    const [rotacionVisible, setRotacionVisible] = useState(false);

    async function buscar(filtro, paramsApi) {
        agGridHelper.showLoad(gridApi, paramsApi, true);
        setDatos(await api.filtrar(filtro));
        agGridHelper.hideLoad(gridApi, paramsApi, false);
    }

    async function nuevo() {
        setSidebarVisible(true);
        setId(0);
    }

    const onGridReady = async (params) => {
        setGridApi(params.api);
    };

    const editar = (id) => {
        setSidebarVisible(true);
        setId(id);
    };

    const onSave = async (cerrar) => {
        if (cerrar === true) {
            setSidebarVisible(false);
        }
        setRotacionVisible(false);
        buscar(getSessionState(filtroKey));
    };

    const RowButtons = ({ data, context }) => (
        <div className={'ag-grid row-icons'}>
            <ButtonIcon title={'Editar'} iconName={'FaRegEdit'} onClick={() => context.editar(data.id)} />
        </div>
    );

    function exportar() {
        const filtro = getSessionState(filtroKey);

        api.excel(filtro);
    }

    const rotarLlantas = () => {
        setRotacionVisible(true);
    };

    const ButtonsHeader = () => {
        return (
            <div className={'ag-grid header-icons'}>
                <ButtonIcon title={'Descargar'} onClick={exportar} iconName={'BiDownload'} />
                {!soloLectura &&
                    <>
                        <ButtonIcon title="Agregar" className={'grid-action'} iconName={'MdAddBox'} onClick={nuevo} />
                        <ButtonIcon title="Rotacion de llantas" className={'grid-action'} iconName={'BsArrowRepeat'} onClick={rotarLlantas} />
                    </>
                }
            </div>
        );
    };

    return (
        <>
            <Sidebar visible={sidebarVisible} position="right" dismissable={false} className="p-sidebar-bg" onHide={() => setSidebarVisible(false)}>
                <DetalleLlanta id={id} onSave={onSave} close={() => setSidebarVisible(false)} soloLectura={soloLectura} />
            </Sidebar>
            <Sidebar visible={rotacionVisible} position="right" dismissable={false} className="p-sidebar-xl" onHide={() => setRotacionVisible(false)}>
                <RotacionLlanta onSave={onSave} />
            </Sidebar>

            <Filtro filtroKey={filtroKey} handleSubmit={buscar} filtrarAlInicio={false} gridApi={gridApi} fechasRangoDias={15} fechasDiasInicio={15}>

                <FiltroLlantas />
            </Filtro>

            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    rowSelection={'single'}
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    frameworkComponents={
                        {
                            buttonsHeader: ButtonsHeader,
                            dateFormatter: agGridHelper.DateFormatter,
                            rowButtons: RowButtons
                        }
                    }
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    getRowClass={(params) => params.data.nombreColor}
                    context={{
                        editar: editar
                    }}
                >
                    <AgGridColumn field="idCautin" headerName="ID LLANTA" cellClass={'center'} flex={2} />
                    <AgGridColumn field="numeroEconomico" headerName="NO. ECONOMICO" headerClass={'center'} minWidth={150}
                        flex={2} />
                    <AgGridColumn field="tipoUnidad" headerName="TIPO UNIDAD" minWidth={150} headerClass={'center'}
                        flex={3} />
                    <AgGridColumn field="posicion" headerName="POSICIÓN" flex={3} />
                    <AgGridColumn field="marca" headerName="MARCA" flex={3} />
                    <AgGridColumn field="tamano" headerName="TAMAÑO" flex={3} />
                    <AgGridColumn field="fechaAsignacion" headerName="FECHA ASIGNADA" cellRenderer="dateFormatter"
                        minWidth={100} flex={3} />
                    <AgGridColumn field="estado" headerName="ESTADO LLANTA" minWidth={100} cellClass={'center'}
                        flex={3} />
                    <AgGridColumn field="kilometros" headerName="KMS. RECORRIDOS" minWidth={120}
                        headerClass={'center'} />
                    <AgGridColumn field="estatus" headerName="ESTATUS" cellClass={'center'} />
                    <AgGridColumn maxWidth={120}
                        headerName="VER"
                        cellRenderer="rowButtons"
                        pinned={'right'}
                        headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>
        </>
    )
};

export default Llantas;
