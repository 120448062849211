/* eslint-disable react-hooks/exhaustive-deps */
import useApiSource from "../../api/source";
import React, {useCallback, useEffect, useState} from "react";

const Departamentos = ({departamentoActivoId, onChange}) => {

    const [departamentos, setDepartamentos] = useState([{id: 0, nombre: 'Todos'}])
    const api = useApiSource()



    const cargar = useCallback(async () => {
        const departamentos = await api.obtener('departamentosIncidencia')
        setDepartamentos([{id: 0, nombre: 'Todos'}, ...departamentos])
    }, [])

    useEffect(() => {
        cargar().then()
    }, [cargar]);

    return (
        <div className="caja-tabs departamento">
            {departamentos.map((departamento, index) => (
                <div key={index} className={`caja-tab ${departamento.id === departamentoActivoId ? 'activa' : ''}`} onClick={() => {
                    onChange(
                        departamento.id
                    )
                }}>
                    <span>{departamento.nombre}</span>
                </div>
            ))}
        </div>
    )
}
export default Departamentos;