import React, { useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import agGridHelper from "../../shared/ag-grid-helper";
import FieldCalendarRange from "../../shared/controls/fieldCalendarRange";
import { getSessionState } from "../../hooks/useSessionState";
import Filtro from "../../shared/componentes/filtro";
import FieldMultiSelect from "../../shared/controls/fieldMultiSelect";
import { Sidebar } from "primereact/sidebar";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import useApiLiquidacionReporte from "../../api/administracion/useApiLiquidacionReporte";
import FieldCheckBox from "../../shared/controls/fieldCheckBox";
import LiquidacionReporteDetalle from "./liquidacionReporteDetalle";
import constantes from "../../catalogos/constantes";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import useSeguridad from "../../Seguridad/useSeguridad";

const LiquidacionReporte = ({ esquemaPago, soloLectura,consulta }) => {
    const filtroKey = 'FiltroLiquidacionReporte' +
        ((esquemaPago === constantes.esquemaPago.liquidacion) ? "Operativa" :
            ((esquemaPago === constantes.esquemaPago.staff) ? "Staff" :
                (esquemaPago === constantes.esquemaPago.oficina) ? "Oficina" : "")
        );
    const seguridad = useSeguridad();
    const permisoEmpleadosEnBaja = seguridad.tieneAcceso(constantes.opciones.LiquidacionEmpleadosBaja);
    const esOperador = esquemaPago === constantes.esquemaPago.liquidacion;
    const [datos, setDatos] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [liquidacion, setLiquidacion] = useState({});
    const apiLiquidacion = useApiLiquidacionReporte();
 
    const onGridReady = async (params) => {
        setGridApi(params.api);
    };

    function ObtenerFiltro(filtro) {
        filtro = filtro ?? getSessionState(filtroKey);
        return { ...filtro, esquemaPago: esquemaPago }
    }

    async function buscar(filtro, paramsApi) {
        agGridHelper.showLoad(gridApi, paramsApi, true);
        filtro = ObtenerFiltro(filtro)
        let datos = await apiLiquidacion.filtrar(filtro);
        setDatos(datos);
        agGridHelper.hideLoad(gridApi, paramsApi, false);
    }

    const verMovimiento = async (empleadoId, id) => {
        const liquidacionActual = await apiLiquidacion.obtener(empleadoId, id);
        setSidebarVisible(true);
        setLiquidacion(liquidacionActual);
    };

    const ButtonsHeader = () => {
        return (
            <div className="ag-grid header-icons">
                <ButtonIcon title="Descargar" onClick={exportar} iconName="BiDownload" />
                {esOperador && 
                    <ButtonIcon title="Descargar con Detalle" onClick={exportarconDetalle} iconName="BiDownload" />
                }

            </div>
            
            
        );
    };

    const exportar = async (filtro) => {
        filtro = ObtenerFiltro(filtro)
        await apiLiquidacion.excel(filtro).then();
    };
    const exportarconDetalle = async (filtro)=>{
        filtro = ObtenerFiltro(filtro)
        await apiLiquidacion.excelconDetalle(filtro).then();
    }

    const RowButtons = ({ data }) => (
        <div className="ag-grid row-icons">
            <button className="btn btn-link btn-sm" title={'Editar'} onClick={() => verMovimiento(data.empleadoId, data.id)}>
                Ver
            </button>
        </div>
    );

    return (
        <>
            <Filtro filtroKey={filtroKey} handleSubmit={buscar}  fechasRangoDias={1} fechasDiasInicio={1} filtrarAlInicio={false}>
                <Filtros esOperador={esOperador} esquemaPago={esquemaPago} consulta={consulta} permisoEmpleadosEnBaja={permisoEmpleadosEnBaja}/>
            </Filtro>

            <Sidebar visible={sidebarVisible} position="right" dismissable={false} className="p-sidebar-xl"
                onHide={() => setSidebarVisible(false)}>
                <LiquidacionReporteDetalle liquidacionActual={liquidacion} esOperador={esOperador}
                    onClose={() => setSidebarVisible(false)} soloLectura={soloLectura} />
            </Sidebar>

            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    frameworkComponents={
                        {
                            buttonsHeader: ButtonsHeader,
                            rowButtons: RowButtons,
                            dateFormatter: agGridHelper.DateFormatter,
                            imageFormatter: agGridHelper.ImageFormatter
                        }
                    }
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    rowHeight={35}
                >
                    <AgGridColumn field="empleado" flex={6} headerName="EMPLEADO" cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn field="fecha" headerName="FECHA" cellRenderer="dateFormatter" flex={3} />
                    <AgGridColumn field="comision" headerName="COMISIÓN" headerClass={'right'} cellClass={'right'}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="total" headerName="POR LIQUIDAR" headerClass={'right'} cellClass={'right'}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="dispersionId" headerName="FOLIO DISPERSION" headerClass={'right'} cellClass={'right'} />
                    <AgGridColumn field="estatus" headerName="ESTATUS" headerClass={'right'} cellClass={'right'} />
                    <AgGridColumn maxWidth={80}
                        headerName="VER"
                        cellRenderer="rowButtons"
                        pinned={'right'}
                        cellClass={'left'}
                        headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>
        </>
    )
};

const Filtros = ({ filtro, onChange, esOperador, esquemaPago, consulta,permisoEmpleadosEnBaja, }) => {
    const consultaParams = {
        operadores: esOperador,
        esquemaPago: esquemaPago,
        enBaja: filtro.enBaja 
    };

    return (
        <>             
            <FieldMultiSelect name="empleadosId"source={consulta} 
                params={{  ...consultaParams  }}
                filter={true}
                label="Empleado" colMd={2} colMob={3} value={filtro.empleadosId}
                onChange={onChange}
                showFilter
                valueAsId={true}
            />
            <FieldCalendarRange name="fechas" label="Fechas" colLg={2} colMd={3} value={filtro.fechas} 
                onChange={onChange} />
            <div className="form-group col-md-4 col-sm-12">
                <label>Rango Nómina</label>
                <div className="col-auto">
                    <FieldTextBox name="nominaDe" colLg={2} placeholder={'Desde'}
                        value={filtro.nominaDe} onChange={onChange} />
                    <FieldTextBox name="nominaHasta" colLg={2} placeholder={'Hasta'}
                        value={filtro.nominaHasta} onChange={onChange} />
                </div>
            </div>
           
            <div className="row">
                <div className="col-md-12 col-sm-12">
                    <div className="row">
                        <div className="col-auto">
                            <FieldCheckBox name="enDispersion" colLg={2} colMd={2} label="En Dispersión" value={filtro.enDispersion ?? false} onChange={onChange} />
                        </div>
                        {permisoEmpleadosEnBaja &&
                              <div className="col-auto">
                                  <FieldCheckBox name="enBaja" colLg={8} colMd={6}
                                      label="Empleado en Baja" 
                                      value={filtro.enBaja ?? false}   onChange={onChange}/>
                              </div>
                        }
                    </div>
                </div>
            </div>

           
        </>
    )
};

export default LiquidacionReporte;
