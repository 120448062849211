import React from 'react';

export function getSessionState(key) {
    return JSON.parse(sessionStorage.getItem(key))
}

export default function useSessionState(key, defaultValue, getItemInStorage = true) {
    const [state, setState] = React.useState(() => {
        const persistedState = sessionStorage.getItem(key);
        return persistedState && getItemInStorage ? JSON.parse(persistedState) : defaultValue;
    });
    React.useEffect(() => {
        window.sessionStorage.setItem(key, JSON.stringify(state));
    }, [state, key]);
    return [state, setState];
}
