import React, {useState} from "react";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import FieldTextArea from "../../../shared/controls/fieldTextArea";
import useApiEmpleados from "../../../api/catalogos/useApiEmpleados";
import FieldCalendar from "../../../shared/controls/fieldCalendar";
import FieldInputNumber from "../../../shared/controls/fieldInputNumber";
import moment from "moment";
import alerts from "../../../shared/alerts";
const Retirocajaahorro = ({empleado, onRetiro}) => {
    const [visibleModal, setVisibleModal] = useState(false);
    const [comentarios, setComentarios] = useState('');
    const [monto, setmonto] = useState(0)
    const apiEmpleado = useApiEmpleados();

    const retirocajaahorro = async () => {
            if (empleado.nomina.saldoahorro < monto * 2) {
            alerts.informacion('No puede retirar más de la mitad del ahorro.');
        } else {           
            await apiEmpleado.retiro(empleado.id, {
                comentarios,
                monto,
                fecha: moment().startOf('day').toDate(),
                fechaInicioAhorro: empleado.nomina?.fechaInicioAhorro
            });
            setVisibleModal(false);
            onRetiro();
        }        
        
    };
    
    const renderFooter = () => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => setVisibleModal(false)} className="p-button-text"/>
                <Button label="Confirmar" icon="pi pi-check" onClick={retirocajaahorro} autoFocus/>
            </div>
        );
    };

    return (
        <>
            <Dialog header="¿Desea retirar ahorro al empleado?" visible={visibleModal} style={{width: '30vw'}}
                    footer={renderFooter('displayBasic')} onHide={() => setVisibleModal(false)}>
                <div className="form-row" style={{overflow:'hidden'}}>
                    <FieldCalendar name="fechaInicioAhorro" label="Fecha inicio de ahorro" colMd={12} className="small"
                                   disabled={true}
                                   value={empleado.nomina?.fechaInicioAhorro}
                                  />
                                   
                     <FieldInputNumber name="ahorro" label="Ahorro" value={empleado.nomina?.ahorro} colMd={6} fractionDigits={2} disabled={true} 
                                    />
                     <FieldInputNumber name="saldoahorro" label="Saldo Ahorro"  value={empleado.nomina?.saldoahorro}  colMd={6} fractionDigits={2}  disabled={true} 
                                    />
                    <FieldInputNumber name="monto" label="Monto a retirar" values ={monto} onChange={setmonto} colMd={12} fractionDigits={2}   
                        />
                        
                    <FieldTextArea name="comentarios" label="Comentarios" colMd={12} rows={6}
                                   value={comentarios} onChange={setComentarios}/>

                </div>
            </Dialog>

            <Button label="Retiro"  type="button"
                    onClick={() => setVisibleModal(true)}/>
        </>
    )
};

export default Retirocajaahorro;
