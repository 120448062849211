import useServiceFactory from './serviceFactory'
const promises = new Map();

const useApiSource = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URL});

    return {
        obtener:(sourceName, params)=>{
            const stringParams = (params == null || typeof params === 'string' || params instanceof String) ?
                params ?? '' :
                JSON.stringify(params);
            const key = sourceName+'_' + stringParams;
            if (promises.has(key)){
                return promises.get(key);
            }

            const promise =  new Promise((resolve, reject) => {
                service.get(`/source/${sourceName}`, {params})
                    .then((data)=>{
                        resolve(data);}
                    ).catch((error)=>{
                        reject(error);
                })
            });
            promises.set(key,promise);
            return promise;
        },
        buscar: async (sourceName, searchQuery, params) => service.get(`/source/${sourceName}/${searchQuery}`, params),
        limpiar: ()=> promises.clear()
    }
};

export default useApiSource;
