/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import useForm from "./useForm";
import PropTypes from "prop-types";
import Form from "./Form";
import alerts from "../alerts";
import FormButtons from "./FormButtons";

const SidebarEditForm = ({
    id,
    onCancel,
    onSaved,
    initialValues,
    modifiedValues,
    getByIdFunction,
    saveFunction,
    deleteFunction,
    showCancelButton,
    deleteClosedFunction,
    saveText,
    showDeleteClosedButton,
    closedText,
    onChangeForm,
    formDisabled,
    ...props
}) => {
    const visible = id != null;
    const [submitted, setSubmitted] = useState(false);
    const children = React.Children.toArray(props.children);
    const form = children.find((e) => e.type === Form);
    const buttons = React.Children.toArray(children.find((e) => e.type === FormButtons)?.props?.children);

    if (form == null) {
        console.log("El componente sidebar form debe contener un elemento form");
    }

    useEffect(() => {
        setSubmitted(false);
        load(id).then();
    }, [id]);

    useEffect(() => {
        setValues(modifiedValues);
    }, [modifiedValues]);

    const { values, setValues, onChange, onValidate, isValid } = useForm(initialValues);

    const load = async (id) => {
        if (id === 0 || id == null) {
            setValues(initialValues);
            if (onChangeForm != null) onChangeForm(initialValues);
            return;
        }

        if (getByIdFunction == null) return;

        const entity = await getByIdFunction(id);
        setValues(entity);

        if (onChangeForm != null) onChangeForm(entity);
    };

    const onSave = async () => {
        setSubmitted(true);

        if (!isValid) {
            return;
        }

        await saveFunction(values);
        onSaved();
    };

    const handleChange = (values) => {
        if (onChangeForm != null) onChangeForm(values);
        onChange(values);
    };

    const onDelete = async () => {
        if ((await alerts.preguntarSiNo("¿Desea confirmar eliminar el registro?")) === true) {
            await deleteFunction(id);
            onSaved();
        }
    };

    const onDeleteClosed = async () => {
        if ((await alerts.preguntarSiNo("¿Desea confirmar eliminar el registro?")) === true) {
            await deleteClosedFunction(id);
            onSaved();
        }
    };

    return (
        <>
            <Sidebar
                visible={visible}
                position="right"
                dismissable={false}
                className="p-sidebar-bg caja"
                onHide={onCancel}
            >
                {React.cloneElement(form, {
                    ...form.props,
                    values,
                    onChange: handleChange,
                    onValidate,
                    formDisabled,
                    submitted,
                    className: `form-container full-height ${submitted ? "submitted" : ""} ${
                        form?.props?.className ?? ""
                    }`,
                })}
                <div className="form-footer">
                    {showCancelButton && (
                        <Button label="Cancelar" className="p-button-outlined" type="button" onClick={onCancel} />
                    )}
                    {buttons?.map((item, index) => (
                        <React.Fragment key={index}>{React.cloneElement(item, { ...item.props })}</React.Fragment>
                    ))}
                    {deleteFunction != null && !formDisabled && id > 0 && (
                        <Button
                            label="Eliminar"
                            className="p-button-outlined p-button-danger"
                            type="button"
                            onClick={onDelete}
                        />
                    )}
                    {saveFunction != null && !formDisabled && (
                        <Button label={saveText ?? "Guardar"} type="button" onClick={() => onSave(false)} />
                    )}
                    {(showDeleteClosedButton ?? true) && deleteClosedFunction != null && id > 0 && (
                        <Button
                            label={closedText ?? "Eliminar"}
                            className="p-button-outlined p-button-danger"
                            type="button"
                            onClick={() => onDeleteClosed()}
                        />
                    )}
                </div>
            </Sidebar>
        </>
    );
};

export default SidebarEditForm;

SidebarEditForm.propTypes = {
    id: PropTypes.number,
    onCancel: PropTypes.func.isRequired,
    onSaved: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    getByIdFunction: PropTypes.func.isRequired,
    saveFunction: PropTypes.func.isRequired,
    deleteFunction: PropTypes.func,
    showCancelButton: PropTypes.bool,
    formDisabled: PropTypes.bool,
    showDeleteButton: PropTypes.bool,
};
