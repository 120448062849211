/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import SubFormaViaje from "./formaViaje";
import SubFormaCostos from "./formaCostos";
import { Button } from "primereact/button";
import { Ruta } from "./ruta";
import FormDropDown from "../../../../shared/controls/fieldDropDown";
import FieldInputNumber from "../../../../shared/controls/fieldInputNumber";
import * as numeral from "numeral";
import FieldCheckBox from "../../../../shared/controls/fieldCheckBox";
import produce from "immer"
import FieldTextArea from "../../../../shared/controls/fieldTextArea";
import FormaViajesDeRegreso from "./formaViajesDeRegreso";
import ButtonIcon from "../../../../shared/controls/ButtonIcon";
import YupValidator from "../../../../shared/YupValidator";
import rutaValidacionEsquema from "./rutaValidacionEsquema";
import FieldTextBox from "../../../../shared/controls/fieldTextBox";
import useApiCotizaciones from "../../../../api/comercial/cotizaciones";
import alerts from "../../../../shared/alerts";
import useApiBanxico from "../../../../api/administracion/useApiBanxico";
import {useEmpresa} from "../../../../layout/EmpresaProvider";
import constantes from "../../../../catalogos/constantes";


const FormaRuta = ({ onSave, control, unidadDeMedida, editable, puedeModificarCosto, modificarComentarios }) => {
    const [ruta, setRuta] = useState(control?.item == null ? new Ruta() : control.item);
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const apiCotizaciones = useApiCotizaciones();
    const apiBanxico = useApiBanxico();
    const {empresa} = useEmpresa();

    const validador = new YupValidator(rutaValidacionEsquema);

    const editableTarifa = editable || puedeModificarCosto;

    async function actualizar(value, name, cambioDeTipoeViaje, cambioDePuntosIntermedios, cambioClasificacion) {
        let nuevaRuta = name != null ? produce(ruta, draft => {
            draft[name] = value;
            draft.unidadDeMedida = unidadDeMedida;
        }) : value;
        if (name === 'viaje' && !cambioClasificacion) {
            if (value.tipoUnidad != null && !cambioDeTipoeViaje) {
                let valoresIniciales = await apiCotizaciones.rutaDefault(unidadDeMedida.id, value.tipoUnidad.id);
                nuevaRuta = produce(nuevaRuta.setValoresPorDefault(valoresIniciales), draft => {
                    draft[name] = value;
                    draft.unidadDeMedida = unidadDeMedida;
                });
            }

            if (cambioDeTipoeViaje) {
                nuevaRuta = nuevaRuta.cambioDeTipoViaje();
            } else {
                const quitarCasetasExcluidas = cambioDePuntosIntermedios && ruta.costo.casetasExcluidas != null
                    && ruta.costo.casetasExcluidas !== "";
                nuevaRuta = await calcularRuta(nuevaRuta, quitarCasetasExcluidas);
            }
        }
        if (nuevaRuta.facturacionUsd && (name === 'facturacionUsd' || name === 'tipoDeCambio' || name === 'FacturacionMxn')) {
            var _tipo = await apiBanxico.tipocambio();
            if (name === 'tipoDeCambio') { _tipo = value; }
            nuevaRuta = produce(nuevaRuta, draft => {
                draft.tipoDeCambio = _tipo;
                draft.cambiarMontoFacturacionUsd()
            });
        } else if (nuevaRuta.facturacionUsd && name === 'montoFacturacionUsd') {
            nuevaRuta = produce(nuevaRuta, draft => {
                draft.cambiarMontoFacturacionMxn()
            });
        }

        setRuta(nuevaRuta);

        if (submitted === true)
            validador.validate(nuevaRuta).then(() => {
                setErrors(validador.errors)
            });

    }

    function agregarAccesorio() {
        setRuta(produce(ruta, draft => {
            draft.agregarAccesorio()
        }));
    }

    function eliminar(index) {
        setRuta(produce(ruta, draft => {
            draft.eliminarAccesorio(index)
        }));
    }

    useEffect(() => {
        if (ruta.viajeRegreso !== null && ruta.viajeRegreso.rutaSeleccionada?.rutaId == null) {
            const nuevaRuta = ruta.actualizarViajeRegreso();
            setRuta(nuevaRuta);
        }
    }, [ruta.viaje.origen, ruta.viaje.destino]);

    useEffect(() => {
        if (control?.isNew === true) {
            inicializar();
        }
    }, []);


    async function inicializar() {
        if (control?.isNew === true) {
            setSubmitted(false);
            let nuevaRuta = new Ruta();

            let valoresIniciales = {
                gastos: [{ costo: 0 }],
                tipoDeCambio: 1,
                costo: { costoDiesel: 0, rendimiento: 0 },
                PrecioSugeridoPorUnidad: 0,
                montoFacturacionUsd: 0
            };

            setRuta(nuevaRuta.setValoresPorDefault(valoresIniciales));
        }
    }

    async function guardar(agregarOtra) {
        //valida y si existen errores regresa true
        setSubmitted(true);
        if (await validador.validate(ruta) === false) {
            setErrors(validador.errors);
            return;
        }
        // if (ruta.porcentajeMargen < 0 && empresa.id !== constantes.empresa.SCF ) {
        //     alerts.aviso("No es posible crear una cotización con un margen menor a 0%");
        //     return;
        // }

        if (ruta.porcentajeMargen < 0.15) {
            let continuar =  await alerts.preguntarSiNoAdvertencia("El margen es menor al 15% ¿desea continuar?");
            if (!continuar){
                return;
            }
        }



        onSave(ruta, agregarOtra);
        inicializar();
    }

    async function calcularRuta(ruta, quitarCasetasExcluidas = false) {
        if (ruta.viaje.viajeValido === true) {
            try {
                const resumenRuta = await apiCotizaciones.resumenRuta({
                    ...ruta.viaje.ids,
                    unidadMedidaId: unidadDeMedida.id
                });

                if (quitarCasetasExcluidas) {
                    alerts.aviso("Se han vuelto a seleccionar todas las casetas, favor de volver a desmarcar las que no necesite");
                }

                // actualiza los costos y también actualiza los viajes de regreso
                return ruta.actualizarCosto(resumenRuta, quitarCasetasExcluidas);
            } catch (e) {
                return ruta;
            }
        }

        return ruta;
    }

    return (
        <form>
            <div className="form-container full-height">
                <Accordion multiple activeIndex={[0]}>
                    {/*Detalle del Viaje*/}
                    <AccordionTab header={
                        <>
                            <span>Detalle del Viaje </span>
                            <span
                                className={'accordion-description'}>{ruta.viaje.origen?.nombre}...{ruta.viaje.destino?.nombre}</span>
                            <span className={'accordion-description'}>{ruta.viaje.tipoViajeNombre}</span>
                            <span className={'accordion-description'}>{ruta.viaje.tipoTarifa?.nombre}</span>
                            {errors.viaje && <ButtonIcon type={'icon'} iconName={'RiErrorWarningLine'} />}
                        </>}
                    >
                        <SubFormaViaje name="viaje" value={ruta.viaje} onChange={actualizar}
                            editable={editable} puedeModificarCosto={puedeModificarCosto}
                            errors={errors.viaje} />
                    </AccordionTab>
                    {/*Viajes de regeso*/}
                    <AccordionTab
                        header={
                            <>
                                <span>Viajes de Regreso</span>
                                <span className={'accordion-description'}>Facturación: {
                                    numeral(ruta.viajeRegreso?.rutaSeleccionada?.facturacion).format('$0,0.00')
                                }</span>
                                <span className={'accordion-description'}>Utilidad: {
                                    numeral(ruta.viajeRegreso?.rutaSeleccionada?.utilidad).format('$0,0.00')
                                }</span>
                            </>}
                        headerStyle={{ display: ruta.viaje.esViajeRedondo ? 'none' : '' }}>
                        <FormaViajesDeRegreso viajeRegreso={ruta.viajeRegreso} name="viajeRegreso" onChange={actualizar} editable={editable}
                            ruta={ruta} />
                    </AccordionTab>
                    {/*Costos*/}
                    <AccordionTab header={
                        <>
                            <span>Cálculo Costos</span>
                            {/*<span*/}
                            {/*className={'accordion-description'}>{ruta.costo.kilometros}</span>*/}
                            <span
                                className={'accordion-description'}>Por {unidadDeMedida.abreviatura}: {numeral(ruta.costo.costoPorKilometro).format('$0,0.00')}</span>
                            <span
                                className={'accordion-description'}>Por Ruta: {numeral(ruta.costo.costoRuta).format('$0,0.00')}</span>
                            {errors.costo && <ButtonIcon type={'icon'} iconName={'RiErrorWarningLine'} />}
                        </>}
                    >
                        <SubFormaCostos name="costo" costo={ruta.costo} onChange={actualizar} errors={errors.costo} editable={editable || puedeModificarCosto}
                            unidadDeMedida={unidadDeMedida} viaje={ruta.viaje} />
                    </AccordionTab>
                    {/*Accesorios*/}
                    <AccordionTab header={
                        <>
                            <span>Accesorios</span>
                            <span
                                className={'accordion-description'}>Total Accesorios: {ruta.accesorios.length} </span>
                            <span
                                className={'accordion-description'}>Precio: {numeral(ruta.totalAccesorios).format('$0,0.00')}</span>
                            {errors.accesorios && <ButtonIcon type={'icon'} iconName={'RiErrorWarningLine'} />}
                        </>}
                    >
                        <table className="table table-borderless">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col" style={{ width: '40%' }}>ACCESORIO</th>
                                    <th scope="col" style={{ width: '25%' }}>CANTIDAD</th>
                                    <th scope="col" style={{ width: '25%' }}>PRECIO UNITARIO</th>
                                    <th className={'input'} style={{ width: '20%' }}>
                                        {editable &&
                                        <ButtonIcon title={'Agregar Ruta'} iconName={'SiAddthis'}
                                            onClick={agregarAccesorio} />
                                        }
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {ruta.accesorios.map((accesorio, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="input">
                                                <FormDropDown source="accesorios" value={accesorio.accesorioId} valueById
                                                    error={errors[`accesorios[${index}]`]?.accesorioId} disabled={!editable}
                                                    onChange={(valor) => actualizar(ruta.actualizarAccesorio(index, 'accesorioId', valor))}>
                                                </FormDropDown>
                                            </td>
                                            <td className="input">
                                                <FieldInputNumber value={accesorio.cantidad}
                                                    error={errors[`accesorios[${index}]`]?.cantidad} disabled={!editable}
                                                    onChange={(valor) => actualizar(ruta.actualizarAccesorio(index, 'cantidad', valor))}
                                                />

                                            </td>
                                            <td className="input">
                                                <FieldInputNumber value={accesorio.precio}
                                                    error={errors[`accesorios[${index}]`]?.precio}
                                                    fractionDigits={2} disabled={!editable}
                                                    onChange={(valor) => actualizar(ruta.actualizarAccesorio(index, 'precio', valor))}
                                                />
                                            </td>
                                            <td className="input">
                                                {editable &&
                                                <ButtonIcon title={'Eliminar'} onClick={() => eliminar(index)}
                                                    iconName={'RiDeleteBinLine'} />
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={2} />
                                    <td className="right">{numeral(ruta.totalAccesorios).format('$0,0.00')}</td>
                                </tr>
                            </tfoot>
                        </table>

                    </AccordionTab>
                    {/*Determinación el precio*/}
                    <AccordionTab header={
                        <>
                            <span>Determinación de Precio</span>
                            <span
                                className={'accordion-description'}>Facturación: {numeral(ruta.facturacionMxn).format('$0,0.00')}</span>
                            <span
                                className={'accordion-description'}>Utilidad: {numeral(ruta.utilidad).format('$0,0.00')}</span>
                            {errors.facturacionMxn && <ButtonIcon type={'icon'} iconName={'RiErrorWarningLine'} />}
                        </>}>
                        <div className="form-row">
                            <FieldInputNumber label="Accesorios" colMd={2} fractionDigits={2} disabled
                                value={ruta.totalAccesorios}
                            >
                            </FieldInputNumber>
                            <FieldInputNumber name="FacturacionMxn" label={`Facturación ${empresa.moneda?.codigo ?? "MXN"}`} colMd={3} fractionDigits={2}
                                              disabled={!editable}
                                value={ruta.FacturacionMxn} onChange={actualizar}
                            >
                            </FieldInputNumber>
                            <FieldInputNumber label="Utilidad" colMd={2} fractionDigits={2} disabled
                                value={ruta.utilidad}>
                            </FieldInputNumber>
                            <FieldTextBox label="Margen" colMd={3} fractionDigits={2} disabled
                                value={ruta.margen}>
                            </FieldTextBox>
                            <FieldInputNumber label={`Precio por ${unidadDeMedida.abreviatura}`} colMd={2}
                                fractionDigits={2} disabled
                                value={ruta.precioPorKilometro}>
                            </FieldInputNumber>
                        </div>
                        <div className="form-row">
                            <FieldInputNumber name="viajesPorSemana" label="Viajes por Sem" colMd={3} fractionDigits={2}
                                              disabled={!(editable || puedeModificarCosto)}
                                value={ruta.viajesPorSemana} onChange={actualizar}>
                            </FieldInputNumber>
                            <FieldInputNumber label="Utilidad por Sem." colMd={3} disabled fractionDigits={2}
                                value={ruta.utilidadPorSemana}>
                            </FieldInputNumber>
                            <FieldInputNumber name="viajesPorMes" label="Viajes por Mes" colMd={3} disabled
                                fractionDigits={2}
                                value={ruta.viajesPorMes}>
                            </FieldInputNumber>
                            <FieldInputNumber label="Utilidad por Mes" colMd={3} fractionDigits={2} disabled
                                value={ruta.utilidadPorMes}>
                            </FieldInputNumber>
                            {empresa.moneda.id === constantes.monedas.pesos &&
                                <FieldCheckBox name="facturacionUsd" label="Facturacion USD" disabled={!editable}
                                    value={ruta.facturacionUsd} onChange={actualizar} />
                            }
                        </div>
                        {ruta.facturacionUsd &&
                            <div className="form-row">
                                <FieldInputNumber name="tipoDeCambio" label="Tipo de cambio" colMd={3} fractionDigits={4} disabled={!editable}
                                    value={ruta.tipoDeCambio} onChange={actualizar}>
                                </FieldInputNumber>
                                <FieldInputNumber name="montoFacturacionUsd" label="Facturación USD" colMd={3} fractionDigits={2} disabled={!editable}
                                    value={ruta.montoFacturacionUsd} onChange={actualizar}>
                                </FieldInputNumber>
                            </div>
                        }
                        <FieldTextArea label={"Desglose de Precios (opcional)"}  name="desglosePrecios" rows={5} disabled={!editable && !modificarComentarios}
                                       value={ruta.desglosePrecios} onChange={actualizar} />
                    </AccordionTab>
                    {/*Comentarios*/}
                    <AccordionTab header="Comentarios">
                        <div className="form-row">
                            <FieldTextArea name="comentario" rows={5} disabled={!editable && !modificarComentarios}
                                value={ruta.comentario} onChange={actualizar} />
                        </div>
                    </AccordionTab>
                    {/*Notas*/}
                    <AccordionTab header="Notas">
                        <div className="form-row">
                            <FieldTextArea name="nota" rows={5} disabled={!editable && !modificarComentarios}
                                value={ruta.nota} onChange={actualizar} />
                        </div>
                    </AccordionTab>
                </Accordion>
                {/*<div className="form-row">*/}
                {/*<FieldTextArea rows={5} value={JSON.stringify(ruta.costo)}/>*/}
                {/*</div>*/}
                {/*<div className="form-row">*/}
                {/*<FieldTextArea rows={5} value={JSON.stringify(errors.accesorios)}/>*/}
                {/*</div>*/}
            </div>
            <div className="form-footer">
                {(editableTarifa || modificarComentarios) &&
                    <>
                        {control?.isNew &&
                            <Button label="Guardar Ruta y Agregar Otra" type="button" className="p-button-outlined"
                                onClick={() => guardar(true)} />
                        }
                        <Button label="Guardar Ruta" type="button" onClick={() => guardar(false)} />
                    </>
                }
            </div>
        </form>
    )
};


export default FormaRuta;



