import * as yup from "yup";

const salidasValidacionEsquema = yup.object().shape({
    operador: yup.mixed().when(["operadorExterno"], {
        is: (a) => !a,
        then: yup.mixed().required(),
    }),
    unidad: yup.mixed().when(["esPermisionario"], {
        is: (a) => !a,
        then: yup.mixed().required(),
    }),
    nombreUnidad: yup.string().when("esPermisionario", {
        is: true,
        then: yup.string().required(),
    }),
    nombreOperador: yup.string().when("operadorExterno", {
        is: true,
        then: yup.string().required(),
    }),
    caja: yup.mixed().when(["conCaja", "esExterno"], {
        is: (a, b) => a && !b,
        then: yup.mixed().required(),
    }),
    nombreCaja: yup.string().when(["conCaja", "esExterno"], {
        is: (a, b) => a && b,
        then: yup.string().required(),
    }),
    planta: yup.mixed().when("otro", {
        is: false,
        then: yup.mixed().required(),
    }),
    destino: yup.string().when("otro", {
        is: true,
        then: yup.string().required(),
    }),
    cliente: yup.mixed().required(),
});

export default salidasValidacionEsquema;
