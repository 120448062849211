/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import moment from "moment";
import { FormattedDate } from "react-intl";
import { InputSwitch } from "primereact/inputswitch";
import { produce } from "immer"
import useApiViajeMonitoreo from "../../../api/operaciones/useApiViajesMonitoreo";
import useApiViajeSeguimiento from "../../../api/operaciones/useApiViajesSeguimiento";
import ApagarNotificacion from "./apagarNotificacion";

const Notificaciones = ({ unidadId, viajeId, width, filtro, mostrarComentarios, soloLectura }) => {
    const apiSeguimiento = useApiViajeSeguimiento();
    const apiMonitoreo = useApiViajeMonitoreo();

    const [notificaciones, setNotificaciones] = useState([]);
    const [notificacion, setNotificacion] = useState();
    const [apagarNotificacionVisible, setApagarNotificacionVisible] = useState(false);

    const cargarNotificaciones = async () => {
        const result = viajeId != null ? await apiSeguimiento.marcarNotificaciones(viajeId, filtro)
            : await apiMonitoreo.marcarNotificaciones(unidadId, filtro);

        setNotificaciones(result);
    };

    useEffect(() => {
        if ((unidadId != null || viajeId != null) && filtro != null) {
            cargarNotificaciones().then();
        }
    }, [filtro]);

    const toggleActivar = (notificacionSeleccionada, index, datosDesactivacion) => {
        //Si se desactiva y aun no trae los datos de desactivación abre la modal para desactivar
        if (notificacionSeleccionada.activo && !datosDesactivacion) {
            setNotificacion(notificacionSeleccionada);
            setApagarNotificacionVisible(true);
        } else {
            const newValue = produce(notificaciones, (draft) => {
                draft[index].activo = !notificacionSeleccionada.activo;
            });

            const datos = !datosDesactivacion ?
                {
                    activo: true,
                    tipoNotificacion: notificacionSeleccionada.tipoNotificacion
                }
                : {
                    ...datosDesactivacion,
                    tipoNotificacion: notificacionSeleccionada.tipoNotificacion,
                    activo: false,
                    viajeId
                };

            apiMonitoreo.activarTipoNotificacion(unidadId, datos);
            setNotificaciones(newValue);
        }
    };

    const onSave = function (datosDesactivacion) {
        const indexTipo = notificaciones.findIndex(e => e.tipoNotificacion === notificacion.tipoNotificacion);

        toggleActivar(notificacion, indexTipo, datosDesactivacion);
        setApagarNotificacionVisible(false);
    };

    const colapsar = (tipo, index) => {
        const newValue = produce(notificaciones, (draft) => {
            draft[index].colapsado = !tipo.colapsado;
        });

        setNotificaciones(newValue);
    };

    const actualizarNotificacion = (notificacion, indexTipo, indexDia, index, value) => {
        const newValue = produce(notificaciones, (draft) => {
            const copiaNotificacion = draft[indexTipo].notificacionesPorDia[indexDia].notificaciones[index];
            copiaNotificacion.nuevoComentario = value;
        });

        setNotificaciones(newValue);
    };

    const abrirComentarios = (notificacion, indexTipo, indexDia, index) => {
        const newValue = produce(notificaciones, (draft) => {
            const copiaNotificacion = draft[indexTipo].notificacionesPorDia[indexDia].notificaciones[index];
            copiaNotificacion.comentariosAbiertos = !notificacion.comentariosAbiertos;
            copiaNotificacion.comentarioInvalido = false;
        });

        setNotificaciones(newValue);
    };

    const enviarComentarios = async (notificacion, indexTipo, indexDia, index) => {
        const comentarioValido = !!notificacion.nuevoComentario;

        const newValue = produce(notificaciones, (draft) => {
            const copiaNotificacion = draft[indexTipo].notificacionesPorDia[indexDia].notificaciones[index];

            copiaNotificacion.comentarioInvalido = !comentarioValido;

            if (comentarioValido) {
                copiaNotificacion.comentariosAbiertos = false;
                copiaNotificacion.nuevoComentario = "";
            }
        });

        if (comentarioValido) {
            await apiMonitoreo.agregarComentario(notificacion.id, notificacion.nuevoComentario);

            //Se vuelven a cargar las notificaciones para que se actualice con los nuevos comentarios
            if (mostrarComentarios) {
                cargarNotificaciones().then();
            }
        }

        setNotificaciones(newValue);
    };

    return (
        <>
            <div style={{ width }}>
                {notificaciones?.map((tipo, indexTipo) => {
                    return (
                        <React.Fragment key={tipo.tipoNotificacion}>
                            <div className="d-flex bg-secondary text-white p-1 my-1 align-items-center">
                                <div className="flex-grow-1 cursor-pointer"
                                    onClick={() => colapsar(tipo, indexTipo)}>
                                    < i className={`pi pi-chevron-${tipo.colapsado ? 'right' : 'down'} px-1`}
                                        style={{ fontSize: "0.8rem" }} />

                                    <b>{tipo.nombre}</b>
                                </div>
                                <InputSwitch className="inputswitch-sm" checked={tipo.activo} disabled={soloLectura}
                                    onChange={() => toggleActivar(tipo, indexTipo)} />
                            </div>

                            {!tipo.colapsado &&
                                <>
                                    {/****Dias*****/}
                                    {tipo.notificacionesPorDia.map((grupoDia, indexDia) => {
                                        return (
                                            <React.Fragment key={indexDia}>
                                                <div className="bg-light p-1">
                                                    <FormattedDate value={grupoDia.dia} weekday="long" day="numeric"
                                                        month="long" year="numeric" />
                                                </div>

                                                <div className="p-1 pl-3 align-items-center"
                                                    style={{
                                                        display: 'grid',
                                                        grid: 'auto-flow / 3fr 10fr 1fr',
                                                        gridGap: '0.3rem'
                                                    }}>

                                                    {/****Comentarios agrupados por día*****/}
                                                    {grupoDia.notificaciones.map((item, index) => {
                                                        return (
                                                            <React.Fragment key={item.id}>
                                                                <div>{moment(item.fecha).format("hh:mm A")}</div>
                                                                <div>{item.comentario}</div>
                                                                <i className="pi pi-comment px-1 cursor-pointer"
                                                                    onClick={() => abrirComentarios(item, indexTipo, indexDia, index)} />

                                                                {item.comentariosAbiertos &&
                                                                    <>
                                                                        {/****Nuevo Comentario*****/}
                                                                        <div className="mb-1" />
                                                                        <FieldTextBox className="form-control-sm" colSm={10}
                                                                            name="comentario"
                                                                            value={item.nuevoComentario}
                                                                            error={item.comentarioInvalido}
                                                                            onChange={(value) => actualizarNotificacion(item, indexTipo, indexDia, index, value)}
                                                                            maxLength={100} />
                                                                        <i className="pi pi-send px-1 cursor-pointer"
                                                                            onClick={() => enviarComentarios(item, indexTipo, indexDia, index)} />
                                                                    </>}

                                                                {/*****Comentarios de la notificacion****/}
                                                                {mostrarComentarios && item.comentarios?.map(c =>
                                                                    <>
                                                                        <div className="text-primary">
                                                                            {moment(c.fecha).format("hh:mm A")}
                                                                        </div>
                                                                        <div className="text-primary">
                                                                            {c.comentario}
                                                                        </div>
                                                                        <div />
                                                                    </>)}
                                                            </React.Fragment>
                                                        )
                                                    }
                                                    )}
                                                </div>
                                            </React.Fragment>
                                        )
                                    })}
                                </>
                            }
                        </React.Fragment>
                    )
                })}
            </div>

            {/*Modal para apagar las notificaciones*/}
            <ApagarNotificacion notificacion={notificacion} visible={apagarNotificacionVisible} onSave={onSave}
                hide={() => setApagarNotificacionVisible(false)} />
        </>
    );
};


export default Notificaciones;
