/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import * as numeral from "numeral";
import FieldAutoComplete from "../../../../shared/controls/fieldAutoComplete";
import {produce} from "immer";
import FieldCheckBox from "../../../../shared/controls/fieldCheckBox";
import useApiCotizaciones from "../../../../api/comercial/cotizaciones";

const FormaViajesDeRegreso = ({viajeRegreso, name, onChange, ruta, editable}) => {
    const [viajes,setViajes] = useState([]);
    const apiCotizaciones = useApiCotizaciones();

    function actualizar(itemValue,itemName) {
        if (onChange != null) {
            onChange(produce(viajeRegreso, (d) => {d[itemName] = itemValue} ), name);
        }
    }


    useEffect(()=>{
        async function obtenerViajes() {
            if (viajeRegreso.origen?.id > 0 && viajeRegreso.destino?.id > 0) {
                setViajes(
                    await apiCotizaciones.viajesDeRegreso(
                        viajeRegreso.origen.nombre,
                        viajeRegreso.destino.nombre,
                        ruta.viaje?.tipoUnidad?.id,
                        viajeRegreso.rutaSeleccionada?.rutaId)
                );
            }
        }

        obtenerViajes();
    },[viajeRegreso.origen, viajeRegreso.destino]);

    function seleccionarViaje(ruta) {
        actualizar(ruta, 'rutaSeleccionada');
        setViajes(produce(viajes,
            (draft) => {
                    return draft.map(e=> e.rutaId === ruta.rutaId ? {...e,seleccionado:true} : {...e,seleccionado:false}  )
                }
            )
        );
    }

    return (
        <>
            <div className="form-row">
                <FieldAutoComplete  name="origen" label="Origen" source="destinos" colMd={6} freeText={true} disabled={!editable}
                                   value={viajeRegreso.origen} onChange={actualizar} />
                <FieldAutoComplete name="destino" label="Destino" source="destinos" colMd={6} freeText={true} disabled={!editable}
                                   value={viajeRegreso.destino} onChange={actualizar} />
            </div>
            <table className="table table-borderless">
                <thead className="thead-light">
                <tr>
                    <th scope="col" style={{width: '30%'}}>CLIENTE</th>
                    <th scope="col" className="right" style={{width: '20%'}}>COSTO</th>
                    <th scope="col" className="right" style={{width: '20%'}}>FACTURACION</th>
                    <th scope="col" className="right" style={{width: '20%'}}>UTILIDAD</th>
                    <th scope="col" className="right" style={{width: '10%'}}/>
                </tr>
                </thead>
                <tbody>
                {viajes.map((viaje, index) => {
                    return (
                        <tr key={index}>
                            <td >
                                {viaje.cliente}
                            </td>
                            <td className="right">
                                {numeral(viaje.costo).format('$0,0.00')}
                            </td>
                            <td className="right">
                                {numeral(viaje.facturacion).format('$0,0.00')}
                            </td>
                            <td className="right">
                                {numeral(viaje.utilidad).format('$0,0.00')}
                            </td>
                            <td className="center input">
                                <FieldCheckBox disabled={!editable} name="seleccionado" value={viaje.seleccionado} onChange={()=>seleccionarViaje(viaje)}/>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </>
    );
};

export default FormaViajesDeRegreso;

