import useServiceFactory from "../serviceFactory";
const apiUrl = `/taller/revisionSemaforeo`;
const useApiRevisionSemaforeo = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, { params }),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, { showLoading: false }),
        guardar: async (params) => service.post(`${apiUrl}`, params),
        llantas: async (id) => service.get(`${apiUrl}/llantas/${id}`, { showLoading: true })
    }
};

export default useApiRevisionSemaforeo;