/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer } from "react";
import useApiGeocercas from "../../api/catalogos/useApiGeocercas";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import FieldCheckBox from "../../shared/controls/fieldCheckBox";
import { Button } from "primereact/button";
import formReducer from "../../shared/forms/formReducer";
import ReadOnlyProvider from "../../shared/forms/ReadOnlyProvider";

const DetalleGeocercas = ({ geocercaId, onSave, soloLectura }) => {
    const api = useApiGeocercas();
    const defaultEntity = () => ({ entity: { id: 0, activo: true } });

    const [{ entity, errors }, dispatch] = useReducer(
        formReducer, {}, defaultEntity
    );

    useEffect(() => {
        async function cargar() {
            const geocerca = await api.obtener(geocercaId);
            dispatch({ type: "update", value: geocerca });
        }

        if (geocercaId > 0) {
            cargar().then();
        }
    }, []);

    const actualizar = (value, propertyName) => {
        dispatch({
            type: 'update',
            value: value,
            name: propertyName
        });
    };

    const guardar = async () => {
        await api.actualizar(geocercaId, entity);
        onSave();
    }

    return (
        <>
            <div className="form-container full-height">
                <h5>Geocerca</h5>
                <div className="form-row">
                    <FieldTextBox name="nombre" label="Nombre" colMd={12} required value={entity.nombre}
                        onChange={actualizar} errors={errors} disabled />
                    <FieldTextBox name="latitud" label="Latitud" colMd={6} required value={entity.latitud}
                        onChange={actualizar} errors={errors} disabled />
                    <FieldTextBox name="longitud" label="Longitud" colMd={6} required value={entity.longitud}
                        onChange={actualizar} errors={errors} disabled />
                    <FieldTextBox name="radio" label="Radio" colMd={6} required value={entity.radio}
                        onChange={actualizar} errors={errors} disabled />
                </div>
                <ReadOnlyProvider defaultReadOnly={soloLectura}>
                    <div className="form-row">
                        <FieldCheckBox name="geocercaProhibida" label="Geocerca Prohibida" value={entity.geocercaProhibida} onChange={actualizar} />
                    </div>
                    <div className="form-row">
                        <FieldCheckBox name="esPuntoInteres" label="Es Punto de Interes" value={entity.esPuntoInteres} onChange={actualizar} />
                    </div>
                    <div className="form-row">
                        <FieldCheckBox name="activa" label="Activa" value={entity.activa} onChange={actualizar} />
                    </div>
                </ReadOnlyProvider>
                <div className="form-footer">
                    {!soloLectura &&
                        <Button label="Guardar" type="button" onClick={guardar} />
                    }
                </div>
            </div>
        </>);
};

export default DetalleGeocercas;