import useServiceFactory from "../serviceFactory";

const apiUrl = `/operaciones/viajes/freightVerify`;

const useApiViajesFreightVerify = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URL});
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, {params, showLoading: true}),
        transmitir: async (id,dias) => service.post(`${apiUrl}/transmitir/${id}/${dias}`, {showLoading: true}),
        integrar: async (id,dias) => service.post(`${apiUrl}/integracionPosiciones`, {showLoading: true}),
        excel: async (params) => service.get(`${apiUrl}/excel`,
        {
            params,
            showLoading: true,
            download: true,
            fileName: `ViajesFreightVerify.xlsx`
        }),
    }
};

export default useApiViajesFreightVerify;
