import React, {useState} from "react";
import useApiCheckUnidad from "../../../api/operaciones/useApiCheckUnidad";

import agGridHelper from "../../../shared/ag-grid-helper";
import {useNavigate} from "react-router-dom";
import FiltroControlPatios from "../filtroControlPatios";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import constantes from "../../constantes";
import {getSessionState} from "../../../hooks/useSessionState";
import Filtro from "../../../shared/componentes/filtro";

const CheckListUnidades = () => {
    const filtroKey = 'filtroControlPatiosAccesos';
    const [datos, setDatos] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const history = useNavigate();
    const api = useApiCheckUnidad();
    const fechaInicio = new Date();
    fechaInicio.setDate(fechaInicio.getDate() - 7);
    const defaultFilter = {tipo: constantes.tipoControlPatios.acceso, fechas: [fechaInicio, new Date()]};

    async function buscar(filtro, paramsApi) {
        agGridHelper.showLoad(gridApi, paramsApi, true);
        setDatos(await api.filtrar(obtenerFiltro(filtro)));
        agGridHelper.hideLoad(gridApi, paramsApi, false);
    }

    async function nuevo() {
        const id = await api.guardar();
        history(`/checklist/nuevo/${id}/1`);
    }

    const onGridReady = async (params) => {
        setGridApi(params.api);
    };

    const ButtonsHeader = () => {
        return (
            <div className={'ag-grid header-icons'}>
                <ButtonIcon title="Agregar" className={'grid-action center'} iconName={'MdAddBox'} onClick={nuevo}/>
            </div>
        );
    };

    function obtenerFiltro(filtro) {
        filtro = filtro ?? getSessionState(filtroKey);
        return {...filtro, unidadId: filtro.unidad?.id};
    }

    return (
        <>
            <Filtro filtroKey={filtroKey} handleSubmit={buscar} defaultValue={defaultFilter} gridApi={gridApi}>
                <FiltroControlPatios tipo={constantes.tipoControlPatios.acceso}/>
            </Filtro>

            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    rowSelection={'single'}
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    frameworkComponents={
                        {
                            buttonsHeader: ButtonsHeader,
                            dateFormatter: agGridHelper.DateTimeFormatter,
                            goToDetail: agGridHelper.GoToDetailFormatter,
                        }
                    }
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                >
                    <AgGridColumn field="id" headerName="FOLIO" flex={2}/>
                    <AgGridColumn field="operador" headerName="OPERADOR"/>
                    <AgGridColumn field="nombreUnidad" headerName="NO ECONÓMICO"/>
                    <AgGridColumn field="fechaEntrada" headerName="FECHA" cellRenderer="dateFormatter" showTime
                                  minWidth={100} flex={3}/>
                    <AgGridColumn field="estadoUnidad" headerName="ESTADO UNIDAD" flex={2}/>
                    <AgGridColumn field="estadoLlantas" headerName="ESTADO LLANTAS" flex={2}/>
                    <AgGridColumn maxWidth={120} headerName="VER" cellRenderer="goToDetail" pinned={'right'}
                                  path='/checklist/editar' headerComponent="buttonsHeader"/>
                </AgGridReact>
            </div>
        </>
    )
};

export default CheckListUnidades;
