import {createContext, useCallback, useContext, useEffect, useState} from "react";
import useSeguridad from "../../Seguridad/useSeguridad";


const readOnlyContext = createContext(undefined);

const useProviderReadOnly = (menuId, defaultReadOnly) => {
    const [readOnly, setReadOnly] = useState(false);
    const {esEditable} = useSeguridad();

    const init = useCallback(()=>{
        if (defaultReadOnly != null) {
            setReadOnly(defaultReadOnly);
            return;
        }
        setReadOnly(esEditable(menuId));
    },[menuId, esEditable, defaultReadOnly])

    useEffect(()=>{
        init();
    },[init]);



    return {
        readOnly,
        setReadOnly
    };
}

const ReadOnlyProvider = ({menuId, defaultReadOnly, ...props}) => {
    const readOnly = useProviderReadOnly(menuId, defaultReadOnly);
    return (
        <readOnlyContext.Provider value={readOnly}>
            {props.children}
        </readOnlyContext.Provider>
    );
}

export default ReadOnlyProvider;

export const useReadOnly = () => {
    return useContext(readOnlyContext);
}

