import React from "react";
import "../comercial/comercial.css";
import IconLibrary from "../../shared/iconLibrary";
import {useNavigate} from "react-router-dom";

const ControlPatioIpad = () => {
    const history = useNavigate();

    async function nuevo() {
        history("/controlDePatios/nuevo/" + 0);
    }

    async function salida() {
        history("/controlDePatios/salida/" + 1 + "/" + 0);
    }

    return (
        <div className="ope-menu-patios">
            <div className="shadow ope-item-menu-patios accesos" onClick={nuevo}>
                <h1>Accesos</h1>
                <IconLibrary iconName="ImLocation2"/>
            </div>
            <div className="shadow ope-item-menu-patios salidas" onClick={salida}>
                <h1>Salidas</h1>
                <IconLibrary iconName="GoChecklist"/>
            </div>   
        </div>
    );
};

export default ControlPatioIpad;

