import React from "react";
import * as numeral from "numeral";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import moment from "moment";
import produce from "immer";
const CajaAhorro = ({liquidacion, ahorros, onChange, esOperador}) => {

    const actualizar = (value, propertyName, index) => {
        const newValue = produce(ahorros, (draft) => {
            draft[index][propertyName] = value;
        });

        onChange(newValue,'cajaAhorro');
    };

    return (
        <>
            <div className="form-row">
                <table className="table table-borderless">
                    <thead className="thead-light">
                    <tr>
                    {liquidacion.aplazarMovimientos && <th/>}
                    <th style={{width: '40%'}}></th>
                        <th style={{width: '40%'}}>FECHA</th>
                        <th style={{width: '40%'}}>AHORRO</th>
                        <th style={{width: '10%'}}>SEMANA</th>
                    </tr>
                    </thead>
                    <tbody>
                    {liquidacion.cajaAhorro?.map((item, index) => {
                        return (
                            <tr key={index}>
                                {liquidacion.aplazarMovimientos  &&
                                <td>
                                    <FieldCheckBox name="seleccionado" value={item.seleccionado}
                                                   onChange={(value, pname) => actualizar(value, pname, index)}
                                    />
                                </td>                            
                                }                                
                                <td>{moment(item.fecha).format('DD/MM/YYYY hh:mm a')}</td>
                                <td>{numeral(item.ahorro).format('$0,0.00')}</td>
                                <td>{moment(item.fecha).isoWeek()}</td>
                            
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </>
    )
};
export default CajaAhorro;