/* eslint-disable react-hooks/exhaustive-deps */
import { useState} from "react";
import useServiceFactory from "../../api/serviceFactory";
import alerts from "../alerts";
import FileDownload from "js-file-download";
import {useEmpresa} from "../../layout/EmpresaProvider";

const useInputFile = (onChange, name) => {

    const [file, setFile] = useState({isEmpty: true});
    const uniqueName = name + Date.now() + Math.random().toString().slice(2);
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URL});
    const [vistaPrevia, setVistaPrevia] = useState(false);

    const {empresaId} = useEmpresa();

    const updateFile = (value) =>{
            if (value == null) {
                setFile({isEmpty: true});
            } else {
                setFile({
                    ...value,
                    isEmpty: ((value.url == null || value.url === '' ) && value.file == null)
                });
            }
    }

    const updateImage = (value) =>{
        if (value == null) {
            setFile({isEmpty: true});
        }
        else{
            setFile({...value,
                url: value.file != null ?
                    URL.createObjectURL(value.file) :
                    value?.url == null ? '' :
                        `${process.env.REACT_APP_API_URL}/archivos/imagen/${empresaId}?url=${value.url}`,
                downloadUrl:value.url,
                isEmpty: (value.url == null && value.file==null)
            });
        }
    }



    const handleUpload = (event) => {
        const nombre = event.target.files[0].name;
        const extension = nombre.split('.').slice(-1)[0];

        const newValue = {
            ...file,
            file: event.target.files[0],
            isEmpty: false,
            nombre,
            extension
        };
        setFile(newValue);

        if (onChange != null) {
            onChange(newValue, name);
        }
    }

    const eliminar = async () => {
        const result = await alerts.preguntarSiNo('¿Desea eliminar el archivo adjunto?');
        if (result === false)
            return false;

        setFile({isEmpty: true});
        if (onChange != null) {
            onChange({isEmpty: true}, name);
        }

        return true;
    }

    const download = async () => {
        if (file.file == null) {
            let url = file.downloadUrl === undefined ? file.url : file.downloadUrl; 
            await service.get(`archivos/descargar/${empresaId}?url=${url}`,
                {
                    showLoading: true,
                    download: true,
                    fileName: file.nombre
                })
        } else {
            FileDownload(file.file, file.nombre);
        }
    }



    return {
        file,
        uniqueName,
        vistaPrevia,
        setVistaPrevia,
        eliminar,
        download,
        handleUpload,
        updateImage,
        updateFile
    };
};

export default useInputFile;
