import React from "react";
import FieldMultiSelect from "../../../shared/controls/fieldMultiSelect";
import FieldAutoComplete from "../../../shared/controls/fieldAutoComplete";
import FieldCalendarRange from "../../../shared/controls/fieldCalendarRange";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import Filtro from "../../../shared/componentes/filtro";
import constantes from "../../../catalogos/constantes";
import { getSessionState } from "../../../hooks/useSessionState";
import constantesOperacion from "../../../operaciones/constantes";

const FiltroSeguimiento = ({ filtroKey, buscar, setMapaVisible, mapaVisible }) => {
    const esMonitoreo = false;
    const fechaInicio = new Date();
    fechaInicio.setDate(fechaInicio.getDate() - 7);

    async function onHandleSubmit(filtro) {
        filtro = filtro ?? getSessionState(filtroKey);

        const nuevoFiltro = {
            ...filtro,
            rutaOrigenId: filtro.rutaOrigen?.id,
            rutaDestinoId: filtro.rutaDestino?.id,
            SubClienteId: filtro.subClientes?.id ?? 0,
            esMonitoreo,
        };

        buscar(nuevoFiltro);
    }

    const defaultFilter = {
        estatus: [
            constantesOperacion.estatusViajes.programado,
            constantesOperacion.estatusViajes.enRuta,
            constantesOperacion.estatusViajes.segmentado,
        ],
        fechas: [fechaInicio, new Date()],
    };

    const Filtros = ({ filtro, onChange }) => {
        return (
            <>
                <FieldMultiSelect
                    name="clientes"
                    source="clientesViajes"
                    label="Cliente"
                    colLg={2}
                    colMd={2}
                    value={filtro.clientes}
                    onChange={onChange}
                    showFilter
                    valueAsId={true}
                />

                <FieldMultiSelect
                    name="subClientesId"
                    label="SubClientes"
                    source="subClientes"
                    params={{ clientesIds: filtro.clientes }}
                    showClear={true}
                    colMd={2}
                    colLg={2}
                    value={filtro.subClientesId}
                    onChange={onChange}
                    valueAsId={true}
                />
                <div className="form-group col-md-3 col-lg-2">
                    <label>Ruta</label>
                    <div className="col-auto">
                        <FieldAutoComplete
                            name="rutaOrigen"
                            colMd={2}
                            freeText={true}
                            placeholder="Desde"
                            source="municipios"
                            value={filtro.rutaOrigen}
                            onChange={onChange}
                        />
                        <FieldAutoComplete
                            name="rutaDestino"
                            colMd={2}
                            freeText={true}
                            placeholder="Hasta"
                            source="municipios"
                            value={filtro.rutaDestino}
                            onChange={onChange}
                        />
                    </div>
                </div>

                <FieldCalendarRange
                    name="fechas"
                    label="Fechas"
                    colLg={1}
                    colMd={1}
                    value={filtro.fechas}
                    onChange={onChange}
                />
                <FieldTextBox
                    name="cartaPorte"
                    label="Carta Porte"
                    colLg={1}
                    colMd={3}
                    keyFilter="int"
                    value={filtro.cartaPorte}
                    onChange={onChange}
                />
                <FieldTextBox
                    name="idViaje"
                    label="ID Viaje"
                    colLg={1}
                    colMd={3}
                    value={filtro.idViaje}
                    onChange={onChange}
                />
                <FieldMultiSelect
                    name="estatus"
                    source="estatusViaje"
                    label="Estatus"
                    colLg={1}
                    colMd={3}
                    value={filtro.estatus}
                    onChange={onChange}
                    valueAsId={true}
                />
                <FieldMultiSelect
                    name="unidades"
                    label="Unidad"
                    colLg={1}
                    colMd={3}
                    value={filtro.unidades}
                    onChange={onChange}
                    source="unidadesCajas"
                    valueAsId={true}
                    showFilter
                    params={{ id: constantes.tiposEquipo.motriz, enProceso: esMonitoreo }}
                />
                <FieldMultiSelect
                    name="cajas"
                    label="Caja"
                    colLg={1}
                    colMd={3}
                    value={filtro.cajas}
                    onChange={onChange}
                    source="unidadesCajas"
                    valueAsId={true}
                    showFilter
                    params={{ id: constantes.tiposEquipo.arrastre }}
                />
            </>
        );
    };

    return (
        <>
            <Filtro
                filtroKey={filtroKey}
                handleSubmit={onHandleSubmit}
                showSaveFilterButton
                modulo={constantes.modulos.seguimiento}
                fechasRangoDias={esMonitoreo ? null : 2}
                fechasDiasInicio={esMonitoreo ? null : 1}
                defaultValue={defaultFilter}
            >
                <Filtros />
            </Filtro>
        </>
    );
};

export default FiltroSeguimiento;
