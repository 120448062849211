/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import Form from "../../../../shared/forms/Form";
import Field from "../../../../shared/forms/Field";
import FormTextBox from "../../../../shared/forms/FormFields/FormTextBox";
import useApiViajeSeguimientoMonitoreo from "../../../../api/operaciones/useApiSeguimientoMonitoreo";
import {Dialog} from "primereact/dialog";
import FormCalendar from "../../../../shared/forms/FormFields/FormCalendar";
import useForm from "../../../../shared/forms/useForm";
import FormTime from "../../../../shared/forms/FormFields/FormTime";
import {Button} from "primereact/button";
import {dateToString} from "../../../../shared/format";

const InformacionDetalle = ({viaje, onCancel, onSaved}) => {
    const visible = viaje != null;
    const [submitted] = useState(false)
    const api = useApiViajeSeguimientoMonitoreo();

    const {
        values,
        onChange,
        onValidate,
        setValues
    } = useForm();

    useEffect(() => {
        if (viaje != null) {

            const eta = viaje.seguimientoEta ?? viaje.eta?.eta;

            const [date, time] = eta == null ? [null, null] :
                eta.split('T');

            setValues({
                ubicacion: viaje.seguimientoUbicacion ?? viaje.ubicacion,
                eta: date,
                time: time?.length > 5 ? time.substring(0, 5) : time
            })
        }
    }, [viaje])

    const onSave = async ()=>{
        const time = values.time == null ? '00:00:00' : values.time;
        const eta = values.eta == null ? null : dateToString(values.eta, 'yyyy-MM-DD');
        console.log(eta)

        const datos = {
            ubicacion: values.ubicacion,
            viajeId: viaje.viajeId,
            eta: eta == null ? null : `${eta}T${time}`
        }

        await api.actualizarSeguimiento(datos)
        onSaved(datos)
    }



    return (
        <Dialog header="Actualizar Información" visible={visible} style={{width: '600px'}}
                onHide={onCancel}
                footer={() => (
                    <div className="p-1">
                        <Button label={'Actualizar'} onClick={onSave} type="button"/>
                    </div>
                )}
        >
            <Form columns={2} values={values} onChange={onChange} onValidate={onValidate} submitted={submitted}>
                <Field field={"ubicacion"} label={"Ubicación"} required={true} render={FormTextBox} cols={2} maxLength={255}/>
                <Field field={"eta"} label={"Eta (Fecha)"} required={true} render={FormCalendar}/>
                <Field field={"time"} label={"Eta (Hora)"} required={true} render={FormTime}/>
            </Form>
        </Dialog>
    )
}

export default InformacionDetalle;
