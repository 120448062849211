/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import {InputNumber} from "primereact/inputnumber";
import useInputValidation from "./useInputValidation";
import AwesomeDebouncePromise from 'awesome-debounce-promise';


const FormInputNumber = ({value, onChange, parent, field, disabled, inputWidth, required,
                             debounceTimeOut,onBlur, className,
                             formDisabled, validators, validationMethods, onValidate, max, min, useGrouping, ...props}) => {


    const {validationErrorCss} = useInputValidation({
        value,
        field,
        required,
        validators,
        onValidate,
        parent
    });

    const onChangeDebounced = AwesomeDebouncePromise(onChange, debounceTimeOut);

    const handleChange = (value)=>{
        if (debounceTimeOut == null){
            onChange(value);
        }else {
            onChangeDebounced(value);
        }
    }


    return (
        <>
            <InputNumber
                {...props}
                id={field}
                onBlur={onBlur}
                style={{width: inputWidth ?? '100%'}}
                locale="es-MX"
                inputStyle ={{width:'100%'}}
                inputClassName = {'right'}
                className={`right ${validationErrorCss} ${className}`}
                onChange={(event) => handleChange(event.value)}
                value={value}
                disabled={disabled??formDisabled}
                max={max??2147483647}
                min={min??-2147483648}
                useGrouping={useGrouping ?? true}
            />
        </>
    )
}

export default FormInputNumber;
