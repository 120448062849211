import React from "react";
import CheckListEpp from "../../../shared/componentes/checkListEpp";

const EPP = ({actualizar, entity, errors}) => {

    return (
        <div className={`section-container`}>
            <div className="flex-col-6">
                <h6>
                    Epp
                </h6>
               
                <CheckListEpp value={entity.epp} name={'epp'} disabled={!entity.editable} errors={errors}
                           onChange={(value, name) => actualizar(value, name)}
                />
            </div>
           
        </div>
    );
};

export default EPP;
