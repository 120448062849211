import React, {useEffect, useState} from "react";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import { useNavigate} from "react-router-dom";
import CotizacionPdfViewer from "./cotizacionPdfViewer";
import useApiCotizaciones from "../../../api/comercial/cotizaciones";
const uri = process.env.REACT_APP_API_URL;

const CrearConvenio = ({saveCotizacion, cotizacion, selectedRows, isDirty}) => {
    const [visible, setVisible] = useState(false);
    const [soloDescargar, setSoloDescargar] = useState(false);
    const [atencionA,setAtencionA] = useState('');
    const [submitted,setSubmitted] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(``);
    const history = useNavigate();
    const apiCotizaciones = useApiCotizaciones();

    useEffect(actualizarPdfUrl,[atencionA,selectedRows,cotizacion]);

    function onHide() {
        setVisible(false);
    }

    async function generarConvenio() {
        setSubmitted(true);
        if (atencionA.length > 0) {
            const params = {
                id: cotizacion.id,
                rutasAprobadasId: selectedRows.map(e=>e.data.id),
                atencionA: atencionA
            };
            await apiCotizaciones.crearConvenio(params);
            //window.location.replace(pdfUrl);
            await apiCotizaciones.descargar(cotizacion.id, cotizacion.codigoCotizacion);
            history('/comercial');
        }
    }

    async function descargarConvenio() {
        if (atencionA.length > 0) {
            await apiCotizaciones.descargarVistaPrevia(cotizacion.codigoCotizacion, pdfUrl);
            onHide();
        }
    }

    async function mostrarVistaPreviaConvenio(soloDescargar) {
        if (isDirty) {
            cotizacion = await saveCotizacion();
        }
        setSoloDescargar(soloDescargar??false);
        setVisible(true);
    }

    function actualizarPdfUrl(){
        const rutaIds = selectedRows.length > 0 ? selectedRows.map(e=>e.data.id).join(): '0';
        const paramAtencionA = (atencionA == null || atencionA.trim().length === 0) ? 'Atención A' : atencionA.trim();
        setPdfUrl(`${uri}/operaciones/comercial/Cotizaciones/${cotizacion.id}/pdf/${paramAtencionA}/${rutaIds}`);
    }

    function getWidth() {
        if (window.innerWidth < 768 )
            return '75vw';
        if (window.innerWidth < 1000 )
            return '55vw';
        return   '35vw';
    }

    function tituloGenerarConvenio() {
        if (cotizacion.id === 0 )
            return 'Debe guardar la cotización';
        if (cotizacion.cliente?.id === 0)
            return 'Debe asignar un nombre de cliente';
        if (selectedRows.length  === 0)
            return 'Debe seleccionar al menos una ruta';
        return 'Generar Cotización';
    }

    const footer = (
        <div>
            {soloDescargar === false &&
            <Button label="Generar" icon="pi pi-check" onClick={generarConvenio}/>
            }
            {soloDescargar === true &&
            <Button label="Descargar" icon="pi pi-check" onClick={descargarConvenio}/>
            }
            <Button label="Cerrar" className="p-button-secondary"  icon="pi pi-times" onClick={onHide}/>
        </div>
    );

    return (
        <>

            <ButtonIcon title="Descargar Cotización"
                        className={'secondary-icon'}
                        iconName={'AiOutlineFileDone'}
                        onClick={() => mostrarVistaPreviaConvenio(true)}
            />
            <ButtonIcon title={tituloGenerarConvenio()}
                        className={'secondary-icon secondary-action'}
                        iconName={'FaRegCheckCircle'}
                        disabled={selectedRows.length === 0 || cotizacion.cliente?.id === 0 || cotizacion.id ===0 }
                        onClick={mostrarVistaPreviaConvenio}
            />
            <Dialog header="Generar Cotización" footer={footer} visible={visible} style={{width: getWidth()}} modal
                    onHide={onHide}>
                <div className="form-row">
                    <FieldTextBox
                        name="cliente"
                        label="Con Atención A:"
                        colMd={12}
                        value={atencionA}
                        onChange={setAtencionA}
                        error={atencionA.length <= 0 && submitted  } />
                    {cotizacion.id > 0 && <CotizacionPdfViewer pdfUrl={pdfUrl} />}
                </div>
            </Dialog>
        </>
    );
};

export default CrearConvenio;

