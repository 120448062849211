/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Form from "../../../shared/forms/Form";
import Field from "../../../shared/forms/Field";
import FormClipboardImage from "../../../shared/forms/FormFields/FormClipboardImage";
import useApiIncidencia from "../../../api/administracion/useApiIncidencia";
import DeleteButton from "../../../shared/Grid/Buttons/DeleteButton";
import ExGrid from "../../../shared/Grid/ExGrid";
import ExColumn from "../../../shared/Grid/ExColumn";
import numeral from "numeral";
import _ from "lodash";
import alerts from "../../../shared/alerts";

const Cargos = ({ value, onChange, onValidate, puedeEditar, onRefresh }) => {
    const api = useApiIncidencia();

    const quitar = async (cargoId, eliminar) => {
        if ((await alerts.preguntarSiNo("¿Desea eliminar el cargo?")) === true) {
            eliminar();
            await api.quitarCargo(value?.id, cargoId);
            onRefresh();
        }
    };

    return (
        <Form columns={1} values={value} onChange={onChange} onValidate={onValidate}
            formDisabled={value?.id > 0 || !puedeEditar}>
            <Field field={'cargos'} cols={5}
                render={({ value, onParentChange, ...props }) =>
                    <ExGrid {...props} data={value} getEntityId={(row) => row._uniqueUid} formDisabled={true}
                        onChange={(data) => onParentChange({ cargos: data, costo: _.sumBy(data, 'costo') })}>
                        <ExColumn field="departamento" title={'Departamento'} width={'35%'} />
                        <ExColumn field="concepto" title={'Concepto'} width={'35%'} />
                        <ExColumn field="costo" title={'Costo'}
                            cellRenderer={({ row }) => <span>{numeral(row?.costo).format('$0,0.00')}</span>} width={'15%'} />
                        <ExColumn field="evidencia" title={'Evidencia'} cellRenderer={FormClipboardImage} width={'10%'}
                            disabled={true} />
                        <ExColumn field="command" width={'5%'}
                            cellRenderer={({ row, onDelete }) => { !row.cobrado && <DeleteButton onDelete={() => quitar(row.id, onDelete)} /> }} />
                    </ExGrid>}>
            </Field>
            <Field label="Costo Total" field={"costo"}
                render={({ value }) => <span className="bold">{numeral(value).format('$0,0.00')}</span>} />
        </Form>
    );
};

export default Cargos;
