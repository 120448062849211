import agGridHelper from "../../shared/ag-grid-helper";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import React, { useContext } from "react";
import { GridContext } from "./CajaChica";
import { tiposMovimientos } from "./CajaConstantes";
import useSeguridad, { opcionesSeguridad } from "../../Seguridad/useSeguridad";
import constantes from "../../catalogos/constantes";

export const ButtonHeaders = ({ api, context }) => {
    const { grid, setGrid } = useContext(GridContext);
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.cajaChica);

    const onNuevoAbono = () => {
        const data = agGridHelper.getData(api);
        setGrid({
            ...grid,
            editCajaId: grid.cajaId,
            solicitudesSeleccionadas: data.filter(e => e.abonado && e.puedeAbonar),
            movimientoId: 0
        })
    }

    return (
        <div className={'ag-grid header-icons'}>
            <ButtonIcon title={'Descargar'} onClick={context.onExport} iconName={'BiDownload'} />

            {grid.cajaId > 0 &&
                <>
                    {seguridad.tieneAcceso(opcionesSeguridad.puedeAbonar) && !soloLectura &&
                        <ButtonIcon title={'Abonar a Cuenta'} iconName={'CgFolderAdd'}
                            onClick={onNuevoAbono} />
                    }
                    {seguridad.tieneAcceso(opcionesSeguridad.puedeSolicitar) && !soloLectura &&
                        <ButtonIcon title="Solicitar" className={'grid-action'} iconName={'MdAddBox'}
                            onClick={() => setGrid(
                                {
                                    ...grid,
                                    idSeleccionado: 0,
                                    editCajaId: grid.cajaId
                                })} />
                    }
                </>
            }
        </div>
    );
};

export const ShowSideBarButton = ({ data }) => {
    const { grid, setGrid } = useContext(GridContext);
    return (
        <>{grid.cajaId > 0 &&
            <button className="btn btn-link btn-sm" title={'Ver'}
                onClick={() => setGrid({
                    ...grid,
                    editCajaId: data.cajaId,
                    idSeleccionado: data.tipoMovimiento === tiposMovimientos.cargo ? data.consecutivo : null,
                    movimientoId: data.tipoMovimiento === tiposMovimientos.cargo ? null : data.id,
                })}>
                Ver
            </button>
        }
        </>
    )
};
