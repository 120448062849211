/* eslint-disable react-hooks/exhaustive-deps */

import React, {useEffect, useState} from 'react';
import './layout.css'
import logo from '../img/logo.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useVisible from '../hooks/useVisible';
import {SubMenu} from "./submenu";
import {useAuth} from "../Seguridad/AuthProvider";
import {useApplication} from "./ApplicationProvider";

const Navigation = () => {
    const {isAuthenticated, menus:userMenus, user, loadMenu} = useAuth()
    const {visibleMenu} = useApplication()
    const [menus, setMenus] = useState([])

    const [expandido, setExpandido] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const {ref,isVisible, setIsVisible} = useVisible(false);

    useEffect(()=>{
        loadMenu().then()
    },[user])

    useEffect(() => {
        if (userMenus != null)
            setMenus( userMenus.map(e=>{return{...e, expandido: menus.findIndex(m=> m.expandido && m.id === e.id  ) >= 0 }}) )
    }, [userMenus]);

    useEffect(()=>{
        if (!isVisible)
            ocultarSubMenu()
    },[isVisible])

    function cambarEstado() {
        setExpandido(!expandido)
    }

    function direccion() {
        return expandido ? "left" : "right";
    }

    function onSeleccionarMenu() {
        ocultarSubMenu();
    }

    function ocultarSubMenu() {
        setMenus((userMenus??[]).map((e) => {
            return {...e, expandido: false}
        }));
        setIsVisible(false)
    }

    function mostrarSubMenu(menu, index) {
        setIsVisible(true);
        setMenus(menus.map((e, i) => i === index ? {...menu, expandido: true} : {...e, expandido: false}));
    }

    if (! visibleMenu)
        return null

    return (
        <div className="navigation-container">
            {isAuthenticated() &&
                <>
                    <nav className={`navigation ${expandido ? 'expandido' : ''}`}>
                        <div className="logo">
                            <div>
                                <img alt="logo" src={logo}/>
                            </div>
                        </div>
                        <div className={`menu`}>
                            {menus.map((menu, i) => (
                                <React.Fragment key={i}>
                                    <div
                                        className={`menu-item ${menu.expandido ? 'activo' : ''} ${expandido ? 'expandido' : ''}`}
                                        onClick={() => mostrarSubMenu(menu, i)}>
                                        <FontAwesomeIcon title={menu.nombre} icon={menu.icono} size={'lg'}/>
                                        <label className={`nombre ${expandido ? 'visible' : ''}`}>{menu.nombre}</label>
                                    </div>

                                    {menu.expandido &&
                                        <div className="submenu" ref={ref}>
                                            <SubMenu menus={menu.menus} onSeleccionarMenu={onSeleccionarMenu}/>
                                        </div>
                                    }
                                </React.Fragment>
                            ))}
                            <div className="cambiar">
                                <div onClick={() => cambarEstado()}>
                                    <FontAwesomeIcon icon={"arrow-circle-" + direccion()} inverse/>
                                </div>
                            </div>
                        </div>
                    </nav>
                </>
            }
        </div>
    );
};

export default Navigation;
