/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import _ from "lodash";
import Field from "../../shared/forms/Field";
import ExGrid from "../../shared/Grid/ExGrid";
import ExColumn from "../../shared/Grid/ExColumn";
import FormDropDown from "../../shared/forms/FormFields/FormDropDown";
import FormInputNumber from "../../shared/forms/FormFields/FormInputNumber";
import Form from "../../shared/forms/Form";

const DescuentoMovimiento = ({value,onChange,name, onValidate,cajaId,movimientoId}) => {
    return (
        <>
            <Form
                columns={3}
                values={value}
                onChange={onChange}
                onValidate={onValidate}
                formDisabled="true"

            >

             <Field field={"gastos"} cols={3} render={({value, onParentChange, ...props}) =>
                    <ExGrid {...props} data={value}
                            onChange={(data)=>onParentChange({gastos:data, monto: _.sumBy(data,'monto')})}
                            getEntityId={(row)=> row._uniqueUid}
                    >
                        <ExColumn field="tipoGasto" title="Tipo" source="descuentos" required={true}
                                  cellRenderer={({row, onRowChanged, ...props}) =>
                                      <FormDropDown {...props} onChange={(id) => onRowChanged({tipoGastoId:id, conceptoId: null}) } />
                        }/>

                        <ExColumn field="conceptoId" title="No. Exhibiciones"  mode="decimal" minFractionDigits={2} maxFractionDigits={2} required={true}
                                  cellRenderer={FormInputNumber}/>

                        <ExColumn field="monto" title="Monto"  mode="decimal" minFractionDigits={2} maxFractionDigits={2} required={true}
                                  cellRenderer={FormInputNumber}/>
                    </ExGrid>
                }/>
              </Form>
        </>
    )
}
export default DescuentoMovimiento;