import React from "react";
import './llanta.css';
import { TabPanel, TabView } from "primereact/tabview";
import useSessionState from "../../hooks/useSessionState";
import Llantas from "./llantas/llantas";
import constantes from "../../catalogos/constantes";
import Revision from "./Revision/Revision";
import ReparacionCompra from "./ReparacionCompra/ReparacionCompra";
import RecepcionRenovado from "./RecepcionRenovado/RecepcionRenovado";
import useSeguridad from "../../Seguridad/useSeguridad";

const Llanta = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.llanta);
    const [activeIndex, setActiveIndex] = useSessionState('tabLlanta', 0);

    return (
        <>
            {true &&
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e?.index)} className="main-tab-view">
                    <TabPanel header="Llantas / Movimientos"
                        headerStyle={(seguridad.tieneAcceso(constantes.menus.llantas) || soloLectura) ? { display: "inline" } : { display: "none" }}>
                        <Llantas soloLectura={soloLectura} />
                    </TabPanel>
                    <TabPanel header="Revisión Semaforeo"
                        headerStyle={seguridad.tieneAcceso(constantes.menus.revisionSemaforeo) ? { display: "inline" } : { display: "none" }}>
                        <Revision soloLectura={soloLectura} />
                    </TabPanel>
                    <TabPanel header="Reparacion y/o Compra"
                        headerStyle={seguridad.tieneAcceso(constantes.menus.reparacionCompra) ? { display: "inline" } : { display: "none" }}>
                        <ReparacionCompra soloLectura={soloLectura} />
                    </TabPanel>
                    <TabPanel header="Recepción de Renovado"
                        headerStyle={seguridad.tieneAcceso(constantes.menus.recepcionRenovado) ? { display: "inline" } : { display: "none" }}>
                        <RecepcionRenovado />
                    </TabPanel>
                </TabView>
            }
        </>
    )
};

export default Llanta;