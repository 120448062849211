import { AgGridReact, AgGridColumn } from "ag-grid-react";
import { Sidebar } from "primereact/sidebar";
import React from "react";
import { useState } from "react";
import useApiMarcaLlanta from "../../api/catalogos/useApiMarcaLlanta";
import Filtro from "../../shared/componentes/filtro";
import MarcaLlantaDetalle from "./MarcasLlantaDetalle";
import agGridHelper from "../../shared/ag-grid-helper";
import { getSessionState } from "../../hooks/useSessionState";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import FiltroMarcaLlanta from "./FiltroMarcaLlanta";
import constantes from "../constantes";
import useSeguridad from "../../Seguridad/useSeguridad";

const MarcaLlanta = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.marcaLlanta);
    const filtroKey = 'FiltroMarcaLlanta';
    const [datos, setDatos] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [marcaLlantaId, setMarcaLlantaId] = useState(0);
    const api = useApiMarcaLlanta();

    async function nuevo() {
        setSidebarVisible(true);
        setMarcaLlantaId(0);
    };

    async function buscar(filtro, paramsApi) {
        agGridHelper.showLoad(gridApi, paramsApi, true);
        setDatos(await api.filtrar(filtro ?? getSessionState(filtroKey)));
        agGridHelper.hideLoad(gridApi, paramsApi, false);
    };

    async function onSave() {
        setSidebarVisible(false);
        await buscar();
    }

    const onGridReady = async (params) => {
        setGridApi(params.api);
    };

    const editMarcaLlanta = (id) => {
        setSidebarVisible(true);
        setMarcaLlantaId(id);
    };

    const ButtonsHeader = () => {
        return (
            <div className="ag-grid header-icons">
                {!soloLectura &&
                    <ButtonIcon title="Agregar" className={'grid-action'} iconName={'MdAddBox'} onClick={nuevo} />
                }
            </div>
        );
    };

    const rowButtons = ({ data, rowIndex, api, context }) => (
        <div className="ag-grid row-icons">
            <ButtonIcon title={'Editar'} iconName={'FaRegEdit'} onClick={() => context.editMarcaLlanta(data.id)} />
        </div>
    );

    return (
        <>
            <Sidebar visible={sidebarVisible} position="right" dismissable={false} className="p-sidebar-md" onHide={() => setSidebarVisible(false)}>
                <MarcaLlantaDetalle marcaLlantaId={marcaLlantaId} onSave={onSave} soloLectura={soloLectura} />
            </Sidebar>
            <Filtro filtroKey={filtroKey} handleSubmit={buscar} defaultValue={{ nombre: '' }} gridApi={gridApi}
                classButtons="field-iconos btn-group">
                <FiltroMarcaLlanta />
            </Filtro>
            <div className="ag-theme-balham grid-principal com-conceptosCobro">
                <AgGridReact enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    className=""
                    frameworkComponents={{
                        rowButtons: rowButtons,
                        gotoDetail: agGridHelper.GoToDetailFormatter,
                        buttonsHeader: ButtonsHeader,
                        dateFormatter: agGridHelper.DateFormatter,
                        imageFormatter: agGridHelper.ImageFormatter
                    }}
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    rowHeight={35}
                    context={{
                        editMarcaLlanta: editMarcaLlanta
                    }}>
                    <AgGridColumn field="nombre" headerName="Nombre" text cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn field="marcaInterna" headerName="Es Marca Interna" text cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn maxWidth={80}
                        headerName="VER"
                        cellRenderer="rowButtons"
                        pinned={'right'}
                        cellClass={'left'}
                        headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>
        </>
    );
};

export default MarcaLlanta;