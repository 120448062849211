/* eslint-disable react-hooks/exhaustive-deps */
import {Dialog} from "primereact/dialog";
import React, {useEffect, useState} from "react";
import ExGrid from "../../shared/Grid/ExGrid";
import ExColumn from "../../shared/Grid/ExColumn";
import useEstatusDiariosApi from "../../api/reportes/useEstatusDiariosApi";
import {AgDateFormatter} from "../../shared/ag-grid-helper/GridFormatters";

const UnidadesSinOperadorModal = ({fecha, onHide}) => {
    const visible = fecha != null
    const [datos, setDatos] = useState([])
    const api = useEstatusDiariosApi()

    useEffect(() => {
        loadSinOperador(fecha).then();
    }, [fecha]);

    const loadSinOperador = async (fecha)=>{
        if (fecha == null) {
            setDatos([]);
            return;
        }
        const datos = await api.sinOperadoresDia({fecha})
        setDatos(datos)
    }

    return (<>
        <Dialog
            header={`Unidades sin Operador`}
            visible={visible}
            position={"center"}
            modal
            style={{width: "600px"}}
            // footer={renderFooter()}
            onHide={onHide}
            className="p-dialog-content-Overflow"
            draggable={false}
            resizable={false}
        >
            <ExGrid data={datos}>
                <ExColumn field={'unidad'} title={'Unidad'} />
                <ExColumn field={'fechaCompromiso'} title={'Fecha Compromiso'} cellRenderer={AgDateFormatter}/>
            </ExGrid>

        </Dialog>
    </>)
}
export default UnidadesSinOperadorModal;