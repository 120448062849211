import useServiceFactory from "../serviceFactory";
const apiUrl = `/catalogos/menu`;
const useApiMenu = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        obtenerMenus: async ()=> service.get(apiUrl)
    }
};

export default useApiMenu;
