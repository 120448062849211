/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import {AutoComplete} from 'primereact/autocomplete'
import * as qs from "qs";
import useApiSource from "../../../api/source";
import useInputValidation from "./useInputValidation";

const FieldAutoComplete = ({
                               value, onChange, field, parent, options, disabled, formDisabled, source, filter, orderBy,
                               required, validators, params, onValidate, width, freeText,
                               isString,
                               ...props
                           }) => {
    const [selectedValue, setSelectedValue] = useState(value);
    const [, setNotFound] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const apiSource = useApiSource();
    freeText = freeText == null ? false : freeText;
    let timer = null;

    const {validationErrorCss} = useInputValidation({
        value,
        field,
        required,
        validators,
        onValidate,
        parent
    });


    async function searchOption(event) {
        let query = `${event.query}${params == null ? '' : `?${qs.stringify(params)}`}`;
        const options = await apiSource.buscar(source, query);
        if (options != null) {
            setFilteredOptions(options);
        }
    }

    useEffect(() => {
        setNotFound(
            !(value?.id > 0)
        );
        setSelectedValue(
            isString === true ? {nombre: value ?? ''} :
                {...value, nombre: value?.nombre == null ? '' : value.nombre}
        );
    }, [value]);

    function fireChange(itemValue) {
        clearTimeout(timer);
        if (onChange != null && value !== itemValue) {
            onChange(isString === true ? itemValue.nombre : itemValue);
        }
    }

    function checkValueOnBlur() {
        if (selectedValue?.nombre !== "") {
            if (typeof selectedValue === 'string') {
                timer = setTimeout(() => {
                    const value = freeText === true ? {nombre: selectedValue} : null;
                    setSelectedValue(value);
                    fireChange(value);
                    setNotFound(true);
                }, 100);
            } else {
                setNotFound(false);
            }
        }
    }

    const onHandleChange = (e) => {
        if (e.value === "") {
            setSelectedValue(null);
            setNotFound(true);
            onChange(null);
        } else {
            setSelectedValue(e.value)
        }
    };

    return (
        <>
            <AutoComplete
                name={field}
                value={selectedValue}
                suggestions={filteredOptions}
                completeMethod={searchOption}
                className={`full-width-control ${validationErrorCss}`}
                field="nombre"
                onChange={onHandleChange}
                onSelect={(e) => fireChange(e.value)}
                onBlur={checkValueOnBlur}
                disabled={disabled??formDisabled}
            />
        </>
    )
};


export default FieldAutoComplete;

