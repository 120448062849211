/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getOauthToken } from './securityUtility'
import { useAuth } from './AuthProvider'
import jwtDecode from 'jwt-decode'
import useApiSecurity from "../api/useApiSecurity";

 const AuhtResult = {
    invalidUser: 1,
    waitingEmailCode: 2,
    authenticated: 3
}


const AuthCallback = () => {
    const apiSecurity = useApiSecurity()
    const navigate = useNavigate()
    const auth = useAuth()

    const validate = async () => {
        const params = {
            code: getOauthToken(),
            redirectUri: `${window.location.origin}/auth/callback`
        }

        const result = await apiSecurity.oauth(params)

        if (result.result === AuhtResult.invalidUser) {
            navigate(`/errorLogin?error=${result.errorMessage}`)
            return
        }
        const token = result.token
        const decoded = jwtDecode(token.id_token)

        auth.login({
            token: token.id_token,
            tokenExpiration: decoded.exp * 1000,
            refreshToken: token.refresh_token,
            scope: token.scope,
            email: decoded.preferred_username,
            nombre: result.nombre,
            username: result.userName
        })

        window.close()

        localStorage.setItem('authenticando', false)



    }

    React.useEffect(() => {
     validate().then()
    }, [])

    return (
        <div className="login-container"
            style={{ display:'flex' }}>
            Authenticando...
        </div>
    )
}

export default AuthCallback
