import React from 'react';
import ReactDOM from 'react-dom';

export const EventContent = ({event}) => {
    // extendedProps is used to access additional event properties.
    return (
        <div className={`event tipo-${event?.extendedProps.tipo}`}  >
            <div>{event?.extendedProps?.nombreCorto}-{event?.extendedProps?.codigo}</div>
            <div >
                {event?.extendedProps?.mecanico}
            </div>
        </div>
    )
};

/*
There is a major necessity to be able to render a React component within the React <App/>.
*/
const OrdenTrabajoEvento = ({event}) => {
    let italicEl = document.createElement('i');
    ReactDOM.render(<EventContent event={event} />, italicEl);
    let arrayOfDomNodes = [ italicEl ];
    return { domNodes: arrayOfDomNodes };
};

export default OrdenTrabajoEvento;

