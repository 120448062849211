/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer } from "react";
import useApiMantenimientoStp from "../../api/catalogos/useApiMantenimientoStp";
import { Button } from "primereact/button";
import FormHandler from "../../shared/forms/formHandler";
import FieldCalendar from "../../shared/controls/fieldCalendar";
import MantenimientoStpValidacionEsquema from "./MantenimientoStpValidacionEsquema";
import formReducer from "../../shared/forms/formReducer";
import FieldCheckBox from "../../shared/controls/fieldCheckBox";
import ReadOnlyProvider from "../../shared/forms/ReadOnlyProvider";
import moment from "moment";

const MantenimientoStpDetalle = ({ conceptoId, onSave, soloLectura }) => {

    const defaultEntity = () => ({ entity: { id: 0, activo: true } });

    const [{ entity, errors }, dispatch] = useReducer(
        formReducer, {}, defaultEntity
    );

    const api = useApiMantenimientoStp();

    const formHandler = new FormHandler({
       validationSchema: MantenimientoStpValidacionEsquema,
        api,
        dispatch
    });

    useEffect(() => {
        async function cargar() {
            const concepto = await api.obtener(conceptoId);

            dispatch({ type: "update", value: concepto });
        }

        if (conceptoId > 0) {
            cargar().then();
        }
    }, []);

    const actualizar = (value, propertyName) => {
        dispatch({
            type: 'update',
            value: value,
            name: propertyName
        });
    };

    const guardar = async () => {
        const entityToSave = {
            ...entity,
            fechaInicio: entity.fechaInicio != null  ? moment(entity.fechaInicio).format('YYYY-MM-DD HH:mm:00'):null,
            fechaFin: entity.fechaFin != null  ? moment(entity.fechaFin).format('YYYY-MM-DD HH:mm:00'):null,
        }    
        await formHandler.save(entityToSave);
        console.log(entityToSave)

        if (formHandler.valdation.result) {
            onSave();
        }
    };

    return (
        <>
            <div className="form-container full-height">
                <h5>Mantenimiento STP</h5>
                <ReadOnlyProvider defaultReadOnly={soloLectura}>
                    <div className="form-row">
                    <FieldCalendar name="fechaInicio" label="Fecha inicio" colSm={12} required
                            value={entity.fechaInicio} className="xs" showButtonBar={false} errors={errors} showTime={true} onChange={actualizar} />
                    

                     <FieldCalendar name="fechaFin" label="Fecha fin" colSm={12} required
                            value={entity.fechaFin} className="xs" showButtonBar={false} errors={errors} showTime={true}onChange={actualizar} />

                    </div>
                    <div className="form-row">
                        <FieldCheckBox name="finalizado" label="Finalizado" value={entity.finalizado}  errors={errors} onChange={actualizar} disabled={true} />
                    </div>                    
                </ReadOnlyProvider>
                </div>
                <div className="form-footer">
                    {!soloLectura &&
                        <Button label="Guardar" type="button" onClick={guardar} />
                    }
                </div>
           
        </>
    )
};

export default MantenimientoStpDetalle;