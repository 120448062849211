import {useState} from "react";
import {useControlMap} from "./ControlMapProvider";

const useOcultarMostrarViajes =(viajes, onMostrarOcultar)=>{
    const [viajesOcultos, setViajesOcultos] = useState(new Set())
    const [unidadesOcultas, setUnidadesOcultas] = useState(new Set())
    const {setNotificacionSeleccionada} = useControlMap()

    const viajeVisible = (viajeId)=>{
        return  !viajesOcultos.has(viajeId)
    }

    const mostrarOcultar = (viajeId)=>{
        const set = new Set(viajesOcultos)
        const viaje = viajes.find(e=>e.viajeId === viajeId);
        if (set.has(viajeId)) {
            set.delete(viajeId)
            unidadesOcultas.delete(viaje.unidadId)
            unidadesOcultas.delete(viaje.cajaId)
        }
        else {
            set.add(viajeId)
            unidadesOcultas.add(viaje.unidadId)
            unidadesOcultas.add(viaje.cajaId)
        }
        setViajesOcultos(set)
        setUnidadesOcultas(new Set(unidadesOcultas))

        onMostrarOcultar()
        setNotificacionSeleccionada(null)
    }

    const mostrarViajeEnMapa = (viaje)=>{
        setNotificacionSeleccionada(null)
        if (!viajesOcultos.has(viaje.viajeId))
            return;

        const set = new Set(viajesOcultos)
        set.delete(viaje.viajeId)
        unidadesOcultas.delete(viaje.unidadId)
        unidadesOcultas.delete(viaje.cajaId)
        setViajesOcultos(set)
        setUnidadesOcultas(new Set(unidadesOcultas))
    }

    const todosVisibles = ()=>{
        if (viajesOcultos.size === 0)
            return 'todos'
        if (viajesOcultos.size  < viajes.length)
            return 'algunos'
        return null
    }

    const cambiarTodosVisibles = (nuevoValor)=>{
        if (nuevoValor === 'todos' || nuevoValor === 'algunos'){
            setViajesOcultos(new Set())
            setUnidadesOcultas(new Set())
            return;
        }
        setViajesOcultos(new Set(viajes.map(e=>e.viajeId)))
        setUnidadesOcultas(new Set([...viajes.map(e=>e.unidadId), ...viajes.map(e=>e.cajaId) ]))

        onMostrarOcultar()
        setNotificacionSeleccionada(null)
    }

    return{
        mostrarOcultar,
        mostrarViajeEnMapa,
        viajeVisible,
        todosVisibles,
        cambiarTodosVisibles,
        unidadesOcultas,
        viajesOcultos
    }
}

export default useOcultarMostrarViajes;