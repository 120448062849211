import React from "react";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import produce from "immer";
import FieldCheckBox from "../../shared/controls/fieldCheckBox";

const ConfigEmailFacturacion = ({ config, onChange }) => {

    const actualizar = (value, name) => {
        const newValue = produce(config, draft => {
            draft[name] = value;
        });

        onChange(newValue, 'emailFacturacionSettings');
    }

    return (
        <div className="form-row">
            <FieldTextBox name="smtpServer" label="Servidor Smtp" value={config.smtpServer} maxLength={100} required={true}
                onChange={actualizar} />
            <FieldTextBox name="port" label="Puerto" value={config.port} maxLength={100} required={true}
                onChange={actualizar} keyFilter={'int'} />
            <FieldTextBox name="address" label="Correo de Salida" value={config.address} maxLength={100} required={true}
                onChange={actualizar} />
            <FieldTextBox name="password" label="Contraseña" value={config.password} maxLength={100} required={true}
                onChange={actualizar} />
            <FieldTextBox name="fromName" label="Remitente" value={config.fromName} maxLength={100} required={true}
                onChange={actualizar} />
            <FieldTextBox name="applicationUrl" label="Url de la aplicacion" value={config.applicationUrl} maxLength={100}
                onChange={actualizar} />
            <FieldCheckBox name="enableSsl" label="Activar Ssl" value={config.enableSsl}
                onChange={actualizar} />
        </div>
    );
};

export default ConfigEmailFacturacion;