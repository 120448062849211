import * as yup from "yup";
import constantes from "../../catalogos/constantes";

const ajusteValidacionEsquema = yup.object().shape({
    empleado: yup.mixed().required(),
    unidad: yup.mixed().when("motivoDescuento", {
        is: (motivo) => motivo.id !== constantes.motivoDescuentos.ajusteParaLiquidar,
        then: yup.mixed().required(),
    }),
    ubicacion: yup.mixed().when("motivoDescuento", {
        is: (motivo) => motivo.id !== constantes.motivoDescuentos.ajusteParaLiquidar,
        then: yup.string().required(),
    }),
    motivoDescuento: yup.mixed().required(),
    fechaRendimiento: yup.date().required(),
    diferenciaLitros: yup.number().required(),
    ajuste: yup.number().required(),
    nota: yup.string().required(),
    exhibiciones: yup.number().required(),
    fechaADescontar: yup.date().required(),
});

export default ajusteValidacionEsquema;
