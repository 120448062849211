import * as yup from "yup";

const asignacionValidacionEsquemaCajaExterna = yup.object().shape({
    placas: yup.string().required(),
    modelo:yup.number().required(),
    nombre:yup.string().required()  
    
});

export default asignacionValidacionEsquemaCajaExterna;
