/* eslint-disable react-hooks/exhaustive-deps */
import SideBarEditForm from "../../../shared/forms/SideBarEditForm";
import Form from "../../../shared/forms/Form";
import Field from "../../../shared/forms/Field";
import FormCalendar from "../../../shared/forms/FormFields/FormCalendar";
import FormTextBox from "../../../shared/forms/FormFields/FormTextBox";
import FormInputNumber from "../../../shared/forms/FormFields/FormInputNumber";
import FormDropDown from "../../../shared/forms/FormFields/FormDropDown";
import React, { useState, useEffect } from "react";
import useApiDieselRendimiento from "../../../api/taller/useApiDieselRendimiento";
import FormFieldLabel, { fieldLabelTypes } from "../../../shared/forms/FormFields/FormFieldLabel";
import FormTextArea from "../../../shared/forms/FormFields/FormTextArea";
import FormAutocomplete from "../../../shared/forms/FormFields/FormAutocomplete";
import { estatus, motivos } from "../DieselConstantes";
import Cargas from "./Cargas";
import useSeguridad, { opcionesSeguridad } from "../../../Seguridad/useSeguridad";
import { Button } from "primereact/button";
import FormButtons from "../../../shared/forms/FormButtons";
import FieldCalendar from "../../../shared/controls/fieldCalendar";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import alerts from "../../../shared/alerts";
import { useAuth } from "../../../Seguridad/AuthProvider";
import useInputFile from "../../../shared/controls/useInputFile";
import IconLibrary from "../../../shared/iconLibrary";
import InputFile from "../../../shared/controls/inputFile";
import { serialize } from "object-to-formdata";
const DetalleRendimientosDiesel = ({ rendimientoId, onSaved, onCancel }) => {
    const api = useApiDieselRendimiento();
    const [formDisabled, setFormDisabled] = useState(false);
    const [descuentoRequerido, setDescuentoRequerido] = useState(false);
    const [editFechaRegistro, setEditFechaRegistro] = useState(false);
    const [cambioEstatusRendimiento, setCambioEstatusRendimiento] = useState(false);
    const [visibleFechaModal, setVisibleFechaModal] = useState(false);
    const [fechaRegistro, setFechaRegistro] = useState(null);
    const [PdfRendimiento, setfilePDF] = useState(null);

    const [editable, setEditable] = useState(true);

    const [puedeEliminar, setPuedeEliminar] = useState(false);
    const { user: usuario } = useAuth();
    const seguridad = useSeguridad();
    const capturaDesactivada = rendimientoId > 0;
    const [ajusteId, setAjusteId] = useState(0);
    const { handleUpload, updateFile, file } = useInputFile(onUpload, "archivo");

    const [entidad, setEntidad] = useState({
        controlador: usuario.nombre
    });

    useEffect(() => {
        const getRendimiento = async () => {
            if (rendimientoId === 0)
                setEntidad({
                    controlador: usuario.nombre,
                });
            if (rendimientoId > 0) {
                setEntidad(await getById(rendimientoId));
            }
        };
        getRendimiento();
    }, [rendimientoId]);

    const onCambioUnidadOperador = async (
        operador,
        unidadId,
        onParentChange,
        unidad,
        fechaInicioRendimiento,
        fechaFinRendimiento
    ) => {
        let detalle = await obtenerLitrosConsumidos(operador, unidadId, fechaInicioRendimiento, fechaFinRendimiento);
        detalle.habilitadoOdometroAnterior = detalle.odometroAnterior === 0;

        if (unidad != null) detalle.rendimientoEsperado = unidad.rendimientoEsperado;

        handleCambioParametros(
            { operador, unidadId, fechaInicioRendimiento, fechaFinRendimiento, ...detalle },
            onParentChange
        );
    };

    const obtenerLitrosConsumidos = async (operador, unidadId, fechaInicioRendimiento, fechaFinRendimiento) => {
        if (operador == null || unidadId == null || fechaInicioRendimiento == null || fechaFinRendimiento == null)
            return {};

        return await api.obtenerDetalle({
            unidadId,
            operadorId: operador.id,
            fechaInicioRendimiento,
            fechaFinRendimiento,
        });
    };

    const handleCambioParametros = (values, onParentChange) => {
        //si falta alguno de los parámetros no se realizan calculos y solo actualiza la entidad
        if (values.operador == null || values.unidadId == null || (values.odometroActual ?? 0) === 0) {
            onParentChange(values);
            if (rendimientoId === 0) {
                setEntidad({ ...entidad, ...values, controlador: usuario.nombre });
            }

            return;
        }

        // const kmsRecorridos = values.odometroActual - values.odometroAnterior;
        const kmsRecorridos = file.isEmpty ? values.odometroActual - values.odometroAnterior : values.kmsRecorridos;

        //si falta el escaner solo actualiza los kilometros recorridos
        if ((values.rendimientoEscaner ?? 0) === 0) {
            onParentChange({ ...values, kmsRecorridos });
            if (rendimientoId === 0) {
                setEntidad({ ...entidad, ...values, kmsRecorridos, controlador: usuario.nombre });
            }

            return;
        }
        //values.kmsRecorridos = kmsRecorridos;
        const newValues = calcularRendimientos(values);

        onParentChange(newValues);

        onChangeForm(newValues);
        if (rendimientoId === 0) {
            setEntidad({ ...entidad, ...newValues, controlador: usuario.nombre });
        }
    };

    const calcularRendimientos = (values) => {
        const kmsRecorridos = values.kmsRecorridos;
        //si están todos los parámetros realiza todos los cálculos
        const rendimientoCalculado = values.litrosConsumidos === 0 ? 0 : kmsRecorridos / values.litrosConsumidos;
        const rendimientoEscanerConError = values.rendimientoEscaner * (1 - values.porcentajeError / 100);
        const litrosEsperados = kmsRecorridos / rendimientoEscanerConError;
        const diferenciaLitros = litrosEsperados - values.litrosConsumidos;
        const precioPorLitro = values.precioPorLitro;
        let importe = diferenciaLitros < 0 ? diferenciaLitros * -1 * precioPorLitro : 0;
        importe = importe < 0 ? 0 : importe;
        const descuentoSemanal = (values.numeroExhibiciones ?? 0) === 0 ? null : importe / values.numeroExhibiciones;
        return {
            ...values,
            kmsRecorridos,
            diferenciaLitros,
            precioPorLitro,
            importe,
            rendimientoCalculado,
            descuentoSemanal,
        };
    };

    const handleSave = async (params) => {

        if (rendimientoId === 0) {
           await api.guardar(params);  
           if (PdfRendimiento !=null){
             await onUploadPDF(0);  
            
           }        
          
        } else {
            await api.cerrar(rendimientoId, params);
        }
    };
    const getById = async (id) => {
        let rendimiento = await api.obtener(id);
        setfilePDF(rendimiento.pdfRendimientodfUrl)

        return calcularRendimientos(rendimiento);
    };

    const onChangeForm = (rendimiento) => {
        const formDisabled =
            rendimiento.estatus === estatus.cerrado ||
            (!seguridad.tieneAcceso(opcionesSeguridad.puedeEditarRendimiento) && capturaDesactivada);
        setFormDisabled(formDisabled);
        setEditable(!capturaDesactivada || !formDisabled);
        setDescuentoRequerido(
            rendimiento.importe > 0 && rendimiento.motivoId !== motivos.malFuncionamiento && !formDisabled
        );
        setPuedeEliminar(
            seguridad.tieneAcceso(opcionesSeguridad.puedeEliminarRendimiento) &&
                !rendimiento?.liquidado &&
                rendimiento?.estatus === estatus.cerrado
        );
        setEditFechaRegistro(seguridad.tieneAcceso(opcionesSeguridad.editarFechaRegistro) && rendimientoId !== 0);
        setCambioEstatusRendimiento(
            seguridad.tieneAcceso(opcionesSeguridad.cambioEstatusRendimiento) && rendimiento.estatus === estatus.cerrado
        );

        setAjusteId(rendimiento?.ajusteId);
        setFechaRegistro(rendimiento.fechaRegistro);
    };

    const cancelar = async (id) => {
        await api.cancelar(id, ajusteId);
    };

    const actualizarFechaRegistro = async () => {
        await api.actualizarFecha({ rendimientoId: rendimientoId, fechaRegistro: fechaRegistro });
        setVisibleFechaModal(false);
        onSaved();
    };

    const cambioEstatus = async () => {
        if ((await alerts.preguntarSiNo("¿Desea cambiar estatus del rendimiento?")) === true) {
            await api.cambiarEstatusaRegistado({ rendimientoId: rendimientoId, fechaRegistro: fechaRegistro });
            onSaved();
        }
    };

    const renderFooterModal = () => {
        return (
            <div className="p-1">
                <Button label="Cancelar" onClick={() => setVisibleFechaModal(false)} className="p-button-outlined" />
                <Button label="Guardar" onClick={actualizarFechaRegistro} type="button" />
            </div>
        );
    };

    async function onUpload(file, name) {
        if (file.isEmpty) return;

        const formData = new FormData();
        formData.append("file", file.file);
        let detalle = await api.guardarRendimientoPorArchivo(formData);
        if (detalle === null) {
            alerts.aviso("No se ha podido cargar este archivo");
            return;
        }
        var newEntidad = { ...entidad, ...detalle };
        setEntidad(newEntidad);
        updateFile(null);
    }
    async function onchangePDF(filepdf){
         if (filepdf.isEmpty) return;
         setfilePDF(filepdf)
      
    }
    async function onUploadPDF(id) {
         try {      
            const formData = serialize({
                id: id,
                PdfRendimiento:PdfRendimiento?.file          
            }, { indices: true, dotsForObjectNotation: true });

            let guardar = await api.guardarRendimientoPdf(id, formData);           
            var newEntidad = { ...entidad, ...guardar };
            setEntidad(newEntidad);
            setfilePDF(null);
        } catch (error) {
            console.error("Error al guardar el archivo PDF:", error);
            alerts.error("Error al guardar el archivo PDF");
        }
    }
    

    async function actualizarlitros(cargas) {
        const totalLitros = cargas
            .filter((e) => e.seleccionado)
            .map((j) => j.litros)
            .reduce((sum, litros) => sum + litros, 0);

        const cargasIds = cargas.filter((e) => e.seleccionado).map((j) => j.id);

        var newEntidad = { ...entidad, litrosConsumidos: totalLitros, cargasIds };

        setEntidad(calcularRendimientos(newEntidad));
    }
    return (
        <>
            <Dialog
                header="Actualizar Fecha Registro"
                visible={visibleFechaModal}
                style={{ width: "35vw" }}
                footer={renderFooterModal()}
                onHide={() => setVisibleFechaModal(false)}
            >
                <div className="form-row">
                    <FieldCalendar
                        name="fechaInicio"
                        value={fechaRegistro}
                        showButtonBar={false}
                        className="xs"
                        minDate={moment().add(-2, "months")._d}
                        onChange={(e) => setFechaRegistro(e)}
                    />
                </div>
            </Dialog>

            <SideBarEditForm
                id={rendimientoId}
                initialValues={{
                    controlador: usuario.nombre,
                }}
                modifiedValues={entidad}
                getByIdFunction={getById}
                saveFunction={handleSave}
                saveText={rendimientoId > 0 ? "Cerrar" : "Guardar"}
                onSaved={onSaved}
                onCancel={onCancel}
                deleteFunction={api.eliminar}
                showCancelButton={true}
                formDisabled={formDisabled}
                onChangeForm={onChangeForm}
                showDeleteClosedButton={puedeEliminar}
                deleteClosedFunction={cancelar}
            >
                <Form columns={3} className="rendimiento-diesel">
                <div className="form-field" style={{ gridColumnEnd: "span 3", width: "100%" }}>
                    <div className={`image-upload`} style={{ gridColumnEnd: "span 2", width: "fit-content" }}>
                        <h5 style={{ gridColumnEnd: "span 1" }}>Agregar Rendimiento</h5>
                        {rendimientoId === 0 && (
                             <>
                                <label htmlFor="file1" label="XML" style={{ left: "14px", top: "-6px", position: "relative" }}>                
                                XML
                                    <IconLibrary iconName="BsSearch" />
                                </label>

                                <input type="file" id="file1" onChange={handleUpload} accept="application/xml" />
                            </>
                        )}
                    </div>
                </div>
                 <Field field={"controlador"} label={"Controlador"} disabled={true} render={FormTextBox} />

                    {editFechaRegistro && (
                        <Field
                            field={"fechaRegistro"}
                            label={"Fecha Registro"}
                            disabled={true}
                            minDate={new Date()}
                            render={FormCalendar}
                        />
                    )}
                    <Field
                        field={"fechaInicioRendimiento"}
                        label={"Fecha Inicio "}
                        disabled={capturaDesactivada}
                        required={true}
                        minDate={moment().add(-2, "months")._d}
                        render={FormCalendar}
                    />
                    <Field
                        field={"fechaFinRendimiento"}
                        label={"Fecha Fin"}
                        disabled={capturaDesactivada}
                        required={true}
                        minDate={moment().add(-1, "months")._d}
                        render={FormCalendar}
                    />
                    <Field
                        field={"operador"}
                        label={"Operador"}
                        source="diesel/operadores"
                        required={true}
                        render={({ onParentChange, parent, ...props }) => (
                            <FormAutocomplete
                                {...props}
                                disabled={capturaDesactivada}
                                onChange={(operador) =>
                                    onCambioUnidadOperador(
                                        operador,
                                        parent.unidadId,
                                        onParentChange,
                                        null,
                                        parent.fechaInicioRendimiento,
                                        parent.fechaFinRendimiento
                                    )
                                }
                            />
                        )}
                    />

                    <Field
                        field={"unidadId"}
                        label={"Unidad"}
                        source="diesel/unidadesMotriz"
                        required={true}
                        render={({ onParentChange, parent, ...props }) => (
                            <FormDropDown
                                {...props}
                                disabled={capturaDesactivada}
                                onChange={(unidadId, unidad) =>
                                    onCambioUnidadOperador(
                                        parent.operador,
                                        unidadId,
                                        onParentChange,
                                        unidad,
                                        parent.fechaInicioRendimiento,
                                        parent.fechaFinRendimiento
                                    )
                                }
                            />
                        )}
                    />

                    <Field field={"marcaMotor"} label={"Motor"} disabled={true} render={FormTextBox} />
                    <Field
                        field={"softwareEscaner"}
                        label={"Software del Escaner"}
                        disabled={true}
                        render={FormTextBox}
                    />
                    <Field
                        field={"porcentajeError"}
                        label={"Porcentaje Error (%)"}
                        disabled={true}
                        render={FormTextBox}
                    />
                    <div className="form-field" style={{ gridColumnEnd: "span 3", width: "100%" }}>
                        <div className={`image-upload`} style={{ gridColumnEnd: "span 1", width: "fit-content" }}>
                            <div>
                                PDF 
                            </div>                         
                                <InputFile name="PdfRendimiento" value={PdfRendimiento}
                                        onChange={onchangePDF} />                                   
                               
                           
                        </div>
                    </div>

                    <h5 style={{ gridColumnEnd: "span 3" }}>Información del viaje</h5>
                    <Field
                        field={"tiempoViaje"}
                        label={"Tiempo de viaje (Hrs)"}
                        disabled={capturaDesactivada}
                        maxFractionDigits={2}
                        render={({ ...props }) => <FormInputNumber {...props} required={true} max={350} />}
                    />
                    <Field
                        field={"tiempoMarchaLenta"}
                        label={"Tiempo de marcha lenta (Hrs)"}
                        disabled={capturaDesactivada}
                        required={true}
                        maxFractionDigits={2}
                        render={({ ...props }) => <FormInputNumber {...props} required={true} max={350} />}
                    />
                    <Field
                        field={"velocidadMaxima"}
                        label={"Velocidad superior (Km/h)"}
                        disabled={capturaDesactivada}
                        required={true}
                        render={({ ...props }) => <FormInputNumber {...props} required={true} max={250} />}
                        maxFractionDigits={2}
                    />
                    <Field
                        field={"velocidadMaxima1"}
                        label={"Velocidad superior -1 (Km/h)"}
                        disabled={capturaDesactivada}
                        required={true}
                        render={({ ...props }) => <FormInputNumber {...props} required={true} max={250} />}
                        maxFractionDigits={2}
                    />
                    <Field
                        field={"porcentajeRelenti"}
                        label={"Porcentaje de relenti (%)"}
                        disabled={capturaDesactivada}
                        required={true}
                        render={({ ...props }) => <FormInputNumber {...props} required={true} max={100} />}
                        maxFractionDigits={2}
                    />
                    <Field
                        field={"litrosBaja"}
                        label={"Litros en baja"}
                        disabled={capturaDesactivada}
                        required={true}
                        render={({ ...props }) => <FormInputNumber {...props} required={true} />}
                        maxFractionDigits={2}
                    />

                    <Field
                        field={"demandaCarga"}
                        label={"Demanda de Carga"}
                        disabled={capturaDesactivada}
                        required={true}
                        render={({ ...props }) => <FormInputNumber {...props} required={true} max={250} />}
                        maxFractionDigits={2}
                    />

                    <Field
                        field={"controlCrucer"}
                        label={"Control Crucer"}
                        disabled={capturaDesactivada}
                        required={true}
                        render={({ ...props }) => <FormInputNumber {...props} required={true} max={250} />}
                        maxFractionDigits={2}
                    />
                    <Field
                        field={"velocidadMaximaAlcanzada"}
                        label={"Velocidad Maxima Alcanzada"}
                        disabled={capturaDesactivada}
                        required={true}
                        render={({ ...props }) => <FormInputNumber {...props} required={true} max={250} />}
                        maxFractionDigits={2}
                    />
                    <Field
                        field={"frenadas"}
                        label={"Cantidad de Frenadas"}
                        disabled={capturaDesactivada}
                        required={true}
                        render={({ ...props }) => <FormInputNumber {...props} required={true} />}
                    />
                    <Field
                        field={"rpm"}
                        label={"RPM"}
                        disabled={capturaDesactivada}
                        required={true}
                        render={({ ...props }) => <FormInputNumber {...props} required={true} />}
                    />
                    <h5 style={{ gridColumnEnd: "span 3" }}>Inventario Diesel</h5>
                    <Field
                        field={"dieselActual"}
                        label={"Actual"}
                        disabled={capturaDesactivada}
                        required={true}
                        maxFractionDigits={2}
                        render={FormInputNumber}
                    />
                    <Field
                        field={"dieselAnterior"}
                        label={"Anterior"}
                        disabled={true}
                        maxFractionDigits={2}
                        render={FormInputNumber}
                    />
                    <span />

                    <h5 style={{ gridColumnEnd: "span 3" }}>Odómetro</h5>
                    <Field
                        field={"odometroActual"}
                        label={"Actual"}
                        disabled={!editable || rendimientoId >= 1}
                        maxFractionDigits={2}
                        render={({ onParentChange, parent, ...props }) => (
                            <FormInputNumber
                                {...props}
                                required={true}
                                onChange={(odometroActual) =>
                                    handleCambioParametros(
                                        {
                                            ...parent,
                                            odometroActual,
                                        },
                                        onParentChange
                                    )
                                }
                            />
                        )}
                    />
                    <Field
                        field={"odometroAnterior"}
                        label={"Anterior"}
                        disabled={true}
                        maxFractionDigits={2}
                        render={({ parent, ...props }) => (
                            <FormInputNumber {...props} disabled={!parent.habilitadoOdometroAnterior} />
                        )}
                    />
                    <span />

                    <h5 style={{ gridColumnEnd: "span 3" }}>Consumos</h5>

                    <Field
                        field={"litrosConsumidos"}
                        label={
                            <Cargas
                                parent={entidad}
                                actualizarlitros={actualizarlitros}
                                rendimientoId={rendimientoId}
                            />
                        }
                        disabled={true}
                        render={({ onParentChange, parent, ...props }) => (
                            <FormInputNumber
                                {...props}
                                required={true}
                                onChange={(litrosConsumidos) =>
                                    handleCambioParametros(
                                        {
                                            ...parent,
                                            litrosConsumidos,
                                        },
                                        onParentChange
                                    )
                                }
                            />
                        )}
                    />

                    <Field field={"kmsRecorridos"} label={"Km Recorridos"} disabled={true} render={FormTextBox} />

                    <span />

                    <h5 style={{ gridColumnEnd: "span 3" }}>Rendimiento</h5>
                    <Field
                        field={"rendimientoEscaner"}
                        label={"Escaner"}
                        disabled={capturaDesactivada}
                        maxFractionDigits={4}
                        render={({ onParentChange, parent, ...props }) => (
                            <FormInputNumber
                                {...props}
                                required={true}
                                onChange={(rendimientoEscaner) =>
                                    handleCambioParametros(
                                        {
                                            ...parent,
                                            rendimientoEscaner,
                                        },
                                        onParentChange
                                    )
                                }
                            />
                        )}
                    />
                    <Field
                        field={"rendimientoCalculado"}
                        label={"Calculado (km/lt)"}
                        disabled={true}
                        maxFractionDigits={4}
                        render={FormInputNumber}
                    />
                    <Field
                        field={"rendimientoEsperado"}
                        label={"Esperado (km/lt)"}
                        disabled={true}
                        maxFractionDigits={4}
                        render={FormInputNumber}
                    />

                    <h5 style={{ gridColumnEnd: "span 3" }}>Descuentos {descuentoRequerido.toString()}</h5>
                    <Field
                        field={"diferenciaLitros"}
                        label={"Diferencia Litros"}
                        disabled={true}
                        maxFractionDigits={2}
                        render={FormInputNumber}
                    />

                    <Field
                        field={"precioPorLitro"}
                        label={"Precio por Litro ($/Lt)"}
                        // disabled={false}
                        disabled={capturaDesactivada}
                        maxFractionDigits={4}
                        render={({ onParentChange, parent, ...props }) => (
                            <FormInputNumber
                                {...props}
                                required={true}
                                onChange={(precioPorLitro) =>
                                    handleCambioParametros(
                                        {
                                            ...parent,
                                            precioPorLitro,
                                        },
                                        onParentChange
                                    )
                                }
                            />
                        )}
                    />
                    <Field
                        field={"importe"}
                        label={"Monto ($)"}
                        disabled={true}
                        maxFractionDigits={2}
                        render={FormInputNumber}
                    />
                    {rendimientoId > 0 && (
                        <>
                           
                            <Field
                                field={"motivoId"}
                                label={"Motivo Descuento"}
                                source={"diesel/motivos"}
                                render={FormDropDown}
                                cols={3}
                                disabled={!descuentoRequerido}
                            />
                            <Field
                                field={"fechaComienzaDescontar"}
                                label={"Fecha Comienza a Descontar"}
                                required={descuentoRequerido}
                                disabled={!descuentoRequerido}
                                minDate={new Date()}
                                render={FormCalendar}
                            />
                            <Field
                                field={"numeroExhibiciones"}
                                label={"# Exibiciones"}
                                required={descuentoRequerido}
                                disabled={!descuentoRequerido}
                                render={({ onParentChange, parent, ...props }) => (
                                    <FormInputNumber
                                        {...props}
                                        onChange={(numeroExhibiciones) =>
                                            handleCambioParametros(
                                                {
                                                    ...parent,
                                                    numeroExhibiciones,
                                                },
                                                onParentChange
                                            )
                                        }
                                    />
                                )}
                            />
                            <Field
                                field={"descuentoSemanal"}
                                label="Descuento Semanal"
                                render={FormFieldLabel}
                                type={fieldLabelTypes.currency}
                            />
                            <Field
                                field={"comentarios"}
                                label={"Comentarios"}
                                required={true}
                                rows={3}
                                cols={3}
                                render={FormTextArea}
                            />
                        </>
                    )}
                </Form>
                <FormButtons>
                    {editFechaRegistro && (
                        <Button
                            label="Actualizar Fecha Registro"
                            className="p-button-outlined"
                            onClick={() => setVisibleFechaModal(!visibleFechaModal)}
                            type="button"
                        />
                    )}

                    {cambioEstatusRendimiento && (
                        <Button label="Registrado" onClick={() => cambioEstatus()} type="button" />
                    )}
                </FormButtons>
            </SideBarEditForm>
        </>
    );
};

export default DetalleRendimientosDiesel;
