/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useReducer} from "react";
import * as numeral from "numeral";
import {produce} from "immer";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import FieldInputNumber from "../../../shared/controls/fieldInputNumber";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import FieldInputModelo from "../../../shared/controls/customInputs/fileInputModelo";
import FieldInputPlacas from "../../../shared/controls/customInputs/fileInputPlacas";
import useApiViaje from "../../../api/operaciones/useApiViajes";
import constantes from "../../../catalogos/constantes";
import constantesOperacion from "../../constantes";
import formReducer from "../../../shared/forms/formReducer";
import FormHandler from "../../../shared/forms/formHandler";
import ajusteValidacionEsquemaCajaExterna from "./asignacionValidacionCajaExterna";
import moment from "moment";
import _ from "lodash";
import alerts from "../../../shared/alerts";
import FieldDropDown from "../../../shared/controls/fieldDropDown";

export const PanelDropdown = ({name, options, onChange, selectedValue}) => {
    return (
        <div>
            {options.map(item => {
                return (
                    <div key={item.id}
                         className={`dropdown-custom-option ${item.id === selectedValue?.id ? 'highlight' : ''}`}
                         onClick={() => onChange(item)}>
                        {item.nombre}
                    </div>
                )
            })}
        </div>
    )
};


export const PanelConvenios = ({convenios, onChange, selectedValue}) => {

    const className = "border-bottom dropdown-custom-option";

    return (
        <>
            {convenios.map(item => {
                return (
                    <div key={item.id} className={`${className} ${item.id === selectedValue?.id ? 'highlight' : ''}`}
                         onClick={() => onChange(item)}>
                        <div>Facturación: {numeral(item.precioViaje).format('$0,0.00')}
                            {item?.precioViajeMxn > 0 &&
                                <span style={{paddingLeft: "50px"}}>
                                    Facturación USD: {numeral(item.precioViajeMxn).format('$0,0.00')}
                                </span>
                            }
                        </div>
                        <div><b>Convenio: </b><b>{item.numeroConvenio}</b></div>
                        <div className="d-flex">
                            {item.paradas.map((p, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <div key={`${item.id}-${p.id}`}
                                             style={{minWidth: '175px', width: '175px'}}>
                                            <b>{p.planta}</b>
                                            <b>{p.id}</b>
                                            <div className="d-flex" style={{fontSize: '0.8em'}}>
                                                <span>{p.direccionCompleta}</span>
                                                {i + 1 < item.paradas.length &&
                                                    <i className="pi pi-ellipsis-h m-2" style={{'fontSize': '1.1em'}}/>
                                                }
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </>
    )
};
const estilosCirculo = {
    1: 'success',
    2: 'primary',
    3: 'warning',
    4: 'primary',
    5: 'info'
};

const estilosVSeguridad = {
    true: 'success',
    false: 'warning'
};
const estatusViaje = [
    {id: 1, nombre: "Disponible"},
    {id: 2, nombre: "En Uso"},
    {id: 3, nombre: "En Mtto"},
    {id: 4, nombre: "Tentativo"},
    {id: 5, nombre: "Por Iniciar Mtto"}
];
// const validator = new YupValidator(asignacionValidacionEsquema);
//         const esValido = await validator.validate(item);
//         let newDatos;

//         if (esValido) {
export const PanelUnidades = ({
                                  viaje,
                                  viajes,
                                  unidades,
                                  esCaja,
                                  onChange,
                                  selectedValue,
                                  cambiarEmpresa,
                                  onHide,
                                  empresaId
                              }) => {
    const esCajaExterna = esCaja && selectedValue?.id === 0;
    const [filtro, setFiltro] = useState({
        tipoEquipoId: esCaja ? constantes.tiposEquipo.arrastre : constantes.tiposEquipo.motriz,
        empresaId: empresaId
    });
    const [unidadesFiltradas, setUnidadesFiltradas] = useState([]);
    const [datosCajaExterna, setDatosCajaExterna] = useState({
        seleccionada: esCajaExterna,
        nombre: esCajaExterna ? selectedValue?.nombre : "",
        placas: esCajaExterna ? selectedValue?.placas : "",
        modelo: esCajaExterna ? selectedValue?.modelo : ""
    });

    const api = useApiViaje();

    useEffect(() => {
        actualizarFiltro(empresaId, 'empresaId')
    }, []);

    useEffect(() => {
        buscar(filtro).then();
    }, []);

    const defaultEntity = () => ({entity: {id: 0, editable: true}});
    const [{entity, errors}, dispatch] = useReducer(formReducer, {}, defaultEntity);
    const formHandler = new FormHandler({
        validationSchema: ajusteValidacionEsquemaCajaExterna,
        api,
        dispatch,
    });

    async function buscar(filtroActualizado, soloTentativos = false) {
        let unidades = await api.obtenerUnidades(viaje.id, filtroActualizado);
        obtenerViajesTentativos(unidades);
        unidades = _.orderBy(unidades, ['estatusId']);

        if (soloTentativos) {
            unidades = unidades.filter(e => e.estatusId === constantesOperacion.estatusUnidadViaje.tentativo)
        }

        setUnidadesFiltradas(unidades);
    }

    function actualizarFiltro(value, name) {
        const nuevoFiltro = produce(filtro, draft => {
            draft[name] = value
        });

        setFiltro(nuevoFiltro);
    }

    function actualizarEstatus(value, name) {
        //let value = Number(value);
        const esTentativo = value === constantesOperacion.estatusUnidadViaje.tentativo;
        const estatusId = esTentativo ? constantesOperacion.estatusUnidadViaje.disponible : value;

        actualizarFiltro(value, name);
        buscar({...filtro, estatusId}, esTentativo).then();
    }

    function actualizarEmpresa(value, name) {
        let empresaId = Number(value);
        cambiarEmpresa(empresaId);
        actualizarFiltro(empresaId, name);
        buscar({...filtro, empresaId}).then();
    }

    function obtenerViajesTentativos(unidades) {
        const nombrePropiedad = esCaja ? "caja" : "unidad";

        unidades.forEach(u => {
            const viajeConUnidad = viajes.find(v => v.id !== viaje.id
                && v.viajePadreId == null
                && v[nombrePropiedad]?.id === u.id
                && v.estatusId === constantesOperacion.estatusViajes.porProgramar
            );

            if (viajeConUnidad != null) {
                const fechaInicio = moment(viaje.fechaInicio);
                const fechaDestino = moment(viajeConUnidad.fechaFin);

                if (fechaDestino.isAfter(fechaInicio)) {
                    u.estatusId = constantesOperacion.estatusUnidadViaje.tentativo;
                    u.estatus = "Tentativo";
                    u.direccionDestino = viajeConUnidad.direccionDestino;
                }
            }
        })
    }

    function actualizarDatosCajaExterna(value, name) {

        const nuevosDatos = produce(datosCajaExterna, draft => {
            draft[name] = value
        });

        setDatosCajaExterna(nuevosDatos)
    }

    function onEnterKeyPress(event) {
        if (event.charCode === 13) {
            buscar(filtro).then();
            event.preventDefault();
        }
    }

    const seleccionarCajaExterna = async (event) => {
        if (event.charCode === 13 && datosCajaExterna.seleccionada && !!datosCajaExterna.nombre && !!datosCajaExterna.placas && !!datosCajaExterna.modelo) {
            const existe = await api.validarCajaExterna(datosCajaExterna.nombre, datosCajaExterna.placas);
            if (existe) {
                alerts.aviso('La caja ' + datosCajaExterna.nombre + ' y/o placa ' + datosCajaExterna.placas + ' se encuentra registrada como caja de ABC, favor de revisar la información');
                return;
            } else {
                onChange({
                    id: 0,
                    nombre: datosCajaExterna.nombre,
                    placas: datosCajaExterna.placas,
                    modelo: datosCajaExterna.modelo
                }, true);
                onHide();
            }
        }
        if (event.charCode === 13) {
            await formHandler.save(entity);
            if (formHandler.valdation.result) {

            }
        }

        //setDatos(newDatos);
    }

    function seleccionarItem(item) {
        const disponible = constantesOperacion.estatusUnidadViaje.disponible;
        const enUso = constantesOperacion.estatusUnidadViaje.enUso;
        const porIniciarMtto = constantesOperacion.estatusUnidadViaje.porIniciarMtto;
        if (item.estatusId === disponible || item.estatusId === enUso || item.estatusId === porIniciarMtto) {
            onChange(item);
            onHide();
        }
    }

    return (
        <div className="table-custom table-in-panel pl-2">
            <table className="table table-sm table-striped table-hover table-fixed">
                <thead className="filter-panel">
                <tr>
                    <th style={{width: '25%'}}>
                        <FieldDropDown label={'Empresa'} name={'empresaId'} value={filtro.empresaId}
                                       source={'todasEmpresas'}
                                       onChange={actualizarEmpresa} valueById={true} colMd={12}/>
                    </th>
                    <th style={{width: '35%'}}/>
                    <th style={{width: esCaja ? '40%' : '15%'}}/>
                    {!esCaja &&
                        <th style={{width: '25%'}}/>
                    }
                </tr>
                <tr onKeyPress={onEnterKeyPress}>
                    <th style={{width: '25%'}}>
                        <FieldTextBox label={esCaja ? 'No. Caja' : 'No. Unidad'} name="unidad" value={filtro.unidad}
                                      onChange={actualizarFiltro} colMd={12}/>
                    </th>
                    <th style={{width: '35%'}}>
                        <FieldTextBox label={'Ubicación'} name="ubicacion" value={filtro.ubicacion}
                                      onChange={actualizarFiltro} colMd={12}/>
                    </th>
                    <th style={{width: esCaja ? '40%' : '15%'}}>
                        <FieldDropDown label={'Estatus'} name={'estatusId'} value={filtro.estatusId}
                                       options={estatusViaje}
                                       onChange={actualizarEstatus} valueById={true} colMd={esCaja ? 6 : 12}/>
                    </th>

                    {!esCaja &&
                        <th style={{width: '25%'}}>
                            <div className="input-group">
                                <FieldInputNumber label={'Km. Inicio'} name="kilometrosInicio"
                                                  value={filtro.kilometrosInicio}
                                                  onChange={actualizarFiltro} colMd={6} placeholder='a recorrer vacío'/>
                                <FieldInputNumber label={'Km. Fin'} name="kilometrosFin" value={filtro.kilometrosFin}
                                                  onChange={actualizarFiltro} colMd={6}/>
                            </div>
                        </th>
                    }
                </tr>
                </thead>

                <tbody>
                {esCaja &&
                    <tr>
                        <td colSpan={4}>
                            <div className="form-row" onKeyPress={seleccionarCajaExterna}>
                                <thead className="filter-panel">
                                <tr onKeyPress={onEnterKeyPress}>
                                    <th style={{width: '12%'}}>
                                        <FieldCheckBox name="seleccionada" label="Externa"
                                                       value={datosCajaExterna.seleccionada}
                                                       onChange={actualizarDatosCajaExterna} className="py-1"
                                                       colMd={2}/>
                                    </th>
                                    {datosCajaExterna.seleccionada &&

                                        <th style={{width: '15%'}}>
                                            <FieldTextBox required name="nombre" value={datosCajaExterna.nombre}
                                                          colMd={12} label="No. Caja" errors={errors}
                                                          className="form-control-sm"
                                                          onChange={actualizarDatosCajaExterna}/>
                                        </th>
                                    }
                                    {datosCajaExterna.seleccionada &&
                                        <th style={{width: '18%'}}>
                                            <FieldInputPlacas required name="placas" placeholder="Placas" label="Placas"
                                                              errors={errors} value={datosCajaExterna.placas}
                                                              onChange={actualizarDatosCajaExterna}/>
                                        </th>
                                    }
                                    {datosCajaExterna.seleccionada &&
                                        <th style={{width: '15%'}}>
                                            <FieldInputModelo required name="modelo" placeholder="Modelo" label="Modelo"
                                                              errors={errors} value={datosCajaExterna.modelo}
                                                              onChange={actualizarDatosCajaExterna}/>

                                        </th>
                                    }
                                </tr>
                                </thead>
                            </div>
                        </td>
                    </tr>
                }

                {unidadesFiltradas.map((item) => {
                    return (
                        <tr key={item.id} className={item.id === selectedValue?.id ? 'highlight' : ''}
                            onClick={() => seleccionarItem(item)}>
                            <td colSpan={2} style={{width: '25%'}}>
                                <div className="d-flex">
                                    <div className={`circle-status ${estilosCirculo[item.estatusId]}`}
                                         title={item.estatusId === constantesOperacion.estatusUnidadViaje.porIniciarMtto ? item?.estatusMantenimiento?.diasParaMantenimiento + " dias por iniciar mantenimiento" : ""}/>
                                    <span className="pl-2">{item.nombre}</span>
                                </div>
                            </td>
                            <td style={{width: '35%'}}>{item.direccionDestino}</td>
                            <td style={{width: esCaja ? '40%' : '15%'}}>{item.estatus}</td>

                            {!esCaja &&
                                <td className="text-right" style={{width: '25%'}}>{item.kilometros.toFixed(2)}</td>
                            }
                        </tr>
                    )
                })
                }
                </tbody>
            </table>
        </div>
    )
};


export const PanelOperadores = ({viaje, viajes, onChange, selectedValue, empresaId, onHide}) => {

    const [filtro, setFiltro] = useState({empresaId: empresaId});
    const [operadoresFiltrados, setOperadoresFiltrados] = useState([]);

    const api = useApiViaje();

    useEffect(() => {
        buscar(empresaId).then();
        setTimeout(() => {
            setFiltro({...filtro, coordinador: viaje.coordinador, empresaId})
        }, 400);
    }, []);

    async function buscar(empresaId) {
        let operadores = await api.obtenerOperadores({
            viajeId: viaje.id,
            nombreOperador: filtro.operador,
            empresaId: empresaId
        });
        obtenerViajesTentativos(operadores);
        operadores = _.orderBy(operadores, ['estatusId', 'nombre']);

        setOperadoresFiltrados(operadores);

    }

    function actualizarFiltro(value, name) {
        const nuevoFiltro = produce(filtro, draft => {
            draft[name] = value
        });

        setFiltro(nuevoFiltro);
    }

    async function actualizarEmpresa(value, name) {
        let empresaId = Number(value);
        actualizarFiltro(value, name);
        buscar(empresaId).then();
    }

    function onEnterKeyPress(event) {
        if (event.charCode === 13) {
            buscar(filtro.empresaId).then();
            event.preventDefault();
        }
    }

    function obtenerViajesTentativos(operadores) {
        operadores.forEach(o => {
            const viajeConOperador = viajes.find(v => v.id !== viaje.id && v.operador?.id === o.id
                && v.estatusId === constantesOperacion.estatusViajes.porProgramar
            );

            if (viajeConOperador != null) {
                const fechaInicio = moment(viaje.fechaInicio);
                const fechaDestino = moment(viajeConOperador.fechaFin);

                if (fechaDestino.isAfter(fechaInicio)) {
                    o.estatusId = constantesOperacion.estatusUnidadViaje.tentativo;
                    o.estatus = "Tentativo";
                }
            }
        })
    }

    async function seleccionarItem(item) {
        const disponible = constantesOperacion.estatusUnidadViaje.disponible;
        const enUso = constantesOperacion.estatusUnidadViaje.enUso;
        const estaVerificado = item.verificadoSeguridad;
        if (filtro.coordinador == null) {
            alerts.aviso('Favor de seleccionar el coordinador antes de seleccionar el operador');
            return;
        }

        if (!estaVerificado && viaje.esNLDO) {
            const confirmacion = await alerts.preguntarSiNoAdvertencia("¿Está seguro de seleccionar un operador que no está verificado a NLDO?");
            if (confirmacion) {
                if (item.estatusId === disponible || item.estatusId === enUso) {
                    onChange({operador: item, coordinador: filtro.coordinador});
                    onHide();
                }
            }
        }else{
            if (item.estatusId === disponible || item.estatusId === enUso) {
                onChange({operador: item, coordinador: filtro.coordinador});
                onHide();
            }
        }             
    }

    return (
        <div className="table-custom table-in-panel pl-2">
            <table className="table table-sm table-striped table-hover table-fixed">
                <thead className="filter-panel">
                <tr>
                    <th>
                        <FieldDropDown label={'Empresa'} name={'empresaId'} value={filtro.empresaId}
                                       source={'todasEmpresas'}
                                       onChange={actualizarEmpresa} valueById={true} colMd={12}/>
                    </th>
                    <th colSpan={3}>
                    </th>
                </tr>
                <th style={{width: '5%'}}/>
                <tr onKeyPress={onEnterKeyPress}>
                    <th colSpan={2}>
                        <FieldTextBox label={'Nombre Operador'} name="operador" value={filtro.operador}
                                      onChange={actualizarFiltro} colMd={12}/>
                    </th>
                    <th colSpan={2}>
                        <FieldDropDown label={'Coordinador'} name="coordinador" value={filtro.coordinador}
                                       source="empleados"

                                       params={{puestoIds: constantes.puestosDeTrafico, todasLasEmpresas: true}}
                                       onChange={actualizarFiltro} colMd={12}/>
                    </th>
                </tr>
                </thead>

                <tbody>
                {operadoresFiltrados.map((item) => {
                    return (                        
                        <tr key={item.id} className={item.id === selectedValue?.id ? 'highlight' : ''}
                            onClick={() => seleccionarItem(item)}>
                         {viaje.esNLDO  &&                             
                                <td colSpan={2} style={{width: '5%'}}>
                                    <div className="d-flex">
                                    <div className={`circle-status ${estilosVSeguridad[item.verificadoSeguridad]}`}
                                        title={item.verificadoSeguridad ? 'Verificado a NLDO' : 'No Verificado a NLDO'}/>                                   
                                    </div>
                                </td>
                             }
                            <td style={{width: '50%'}}>{item.nombre}</td>
                            <td style={{width: '30%'}}>{item.nombreUnidad}</td>
                            <td style={{width: '20%'}}>{item.estatus}</td>
                        </tr>
                    )
                })
                }
                </tbody>
            </table>
        </div>
    )
};
