import {Checkbox} from "primereact/checkbox";
import React from "react";

const CargadaCheckBoxRenderer = ({data, value}) => {

    return (
        <>
            {data.patio == null ?
                <></> :
                <Checkbox checked={value} className={'disabled'}/>
            }
        </>
    );
};

export default CargadaCheckBoxRenderer;