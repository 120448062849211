import { AgGridColumn, AgGridReact } from "ag-grid-react";
import moment from "moment";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import useApiReparacionCompraLlanta from "../../../api/taller/useApiReparacionCompraLlanta";
import { useState } from "react";
import ReparacionCompraDetalle from "./ReparacionCompraDetalle";
import useForm from "../../../shared/forms/useForm";
import FormFilter from "../../../shared/forms/FormFilter/FormFilter";
import Filters from "../../../shared/forms/FormFilter/Filters";
import Field from "../../../shared/forms/Field";
import FormTextBox from "../../../shared/forms/FormFields/FormTextBox";
import FormCalendarRange from "../../../shared/forms/FormFields/FormCalendarRange";
import Buttons from "../../../shared/forms/FormFilter/Buttons";
import { AgCurrencyFormatter, AgDateFormatter } from "../../../shared/ag-grid-helper/GridFormatters";
import { VerIconRenderer } from "../../../shared/ag-grid-helper/GridRenderers";
import agGridHelpers from "../../../shared/ag-grid-helper";
import FormMultiSelect from "../../../shared/forms/FormFields/FormMultiSelect";

const ReparacionCompra = ({ soloLectura }) => {
    const filtroInicial = {
        fechas: [moment().startOf('month')._d, moment().startOf('day')._d]
    };
    const api = useApiReparacionCompraLlanta();
    const [datos, setDatos] = useState([]);
    const [reparacionCompraId, setReparacionCompraId] = useState(null);
    const { values: filter, onChange: onFilterChange, onClear } = useForm(filtroInicial);

    const filtrar = async (filtro) => {
        const reparacionCompra = await api.filtrar(filtro);
        setDatos(reparacionCompra);
    };

    const ButtonsHeader = () => {
        return (
            <div className="ag-grid header-icons">
                {!soloLectura &&
                    <ButtonIcon className="grid-action" title={'Agregar'} iconName={'MdAddBox'}
                        onClick={() => setReparacionCompraId(0)} />
                }
            </div>
        )
    };

    return (
        <>
            <FormFilter columns={5} gridStyle={{ paddingTop: '0', marginTop: '0' }}
                filter={filter}
                onChange={onFilterChange}
                onClear={onClear}
                onFilter={(filtro) => filtrar(filtro)}>
                <Filters>
                    <Field field={"operadorIds"} label="Operador"
                        render={({ onParentChange, parent, ...props }) =>
                            <FormMultiSelect source="empleados" params={{ operadores: true }} {...props} />} />
                    <Field field={"unidadIds"} label="Número Economico" source="unidades" render={FormMultiSelect} />
                    <Field field={"folio"} label="Factura / Nota" render={FormTextBox} />
                    <Field field={"conceptoIds"} label="Reparacion / Compra" source="reparacionCompra" render={FormMultiSelect} />
                    <Field field={"fechas"} label="Fechas" render={FormCalendarRange} />
                </Filters>
                <Buttons filter={true} clear={true} style={{ top: '-50px' }}></Buttons>
            </FormFilter>
            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    rowSelection={true}
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    components={{
                        buttonsHeader: ButtonsHeader,
                        verIconRenderer: VerIconRenderer
                    }}
                    onGridReady={() => filtrar(filter).then()}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelpers.loadingOverlay}
                    defaultColDef={agGridHelpers.defaultColumns}
                    context={{ onEdit: (id) => setReparacionCompraId(id) }}>
                    <AgGridColumn field="id" headerName="ID Revision" cellClass={'center'} flex={2} />
                    <AgGridColumn field="operadorNombre" headerName="Operador" />
                    <AgGridColumn field="numeroEconomico" headerName="No. Economico" headerClass={'center'} minWidth={100} />
                    <AgGridColumn field="conceptoNombre" headerName="Concepto" headerClass={'center'} minWidth={100} />
                    <AgGridColumn field="fecha" headerName="Fecha Compra" cellClass={'left'} minWidth={100} valueFormatter={AgDateFormatter} />
                    <AgGridColumn field="folio" headerName="Factura / Nota" cellClass={'center'} minWidth={100} />
                    <AgGridColumn field="costo" headerName="Costo" precision={2} minWidth={100} valueFormatter={AgCurrencyFormatter} />
                    <AgGridColumn maxWidth={80} headerName="VER" pinned={'right'} cellClass={'left'}
                        cellRenderer="verIconRenderer"
                        headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>
            <ReparacionCompraDetalle reparacionCompraId={reparacionCompraId}
                onSaved={() => {
                    filtrar(filter).then();
                    setReparacionCompraId(null);
                }}
                onCancel={() => setReparacionCompraId(null)} />
        </>
    )
}

export default ReparacionCompra;