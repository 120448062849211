/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
import React, { useEffect, useRef, useState } from "react";
import Filtro from "../../../shared/componentes/filtro";
import FieldAutoComplete from "../../../shared/controls/fieldAutoComplete";
import FieldCalendarRange from "../../../shared/controls/fieldCalendarRange";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import FieldMultiSelect from "../../../shared/controls/fieldMultiSelect";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import constantes from "../../../catalogos/constantes";
import constantesOperacion from "../../constantes";
import { Checkbox } from "primereact/checkbox";
import produce from "immer";
import moment from "moment";
import { FormattedDate } from "react-intl";
import { getSessionState } from "../../../hooks/useSessionState";
import useApiViaje from "../../../api/operaciones/useApiViajes";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import { Sidebar } from "primereact/sidebar";
import DetalleViaje from "./detalleProgramacionViaje";
import { PanelOperadores, PanelUnidades } from "./paneles";
import YupValidator from "../../../shared/YupValidator";
import asignacionValidacionEsquema from "./asignacionValidacionEsquema";
import alerts from "../../../shared/alerts";
import IconLibrary from "../../../shared/iconLibrary";
import * as numeral from "numeral";
import ClonarViaje from "./clonarViaje";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import CalendarioUnidadViaje from "./calendarioUnidadViaje";
import _, { isUndefined } from "lodash";
import useSeguridad from "../../../Seguridad/useSeguridad";
import { useEmpresa } from "../../../layout/EmpresaProvider";
import { updateArrayAtIndex } from "../../../shared/arrayUtility";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import { Tooltip } from "primereact/tooltip";

const styleEspacioCelda = { backgroundColor: "#f3f5fb", width: "1%", borderTop: "none" };
const estatusPorProgamar = constantesOperacion.estatusViajes.porProgramar;
const estatusCancelado = constantesOperacion.estatusViajes.cancelado;
const estatusProgramado = constantesOperacion.estatusViajes.programado;
const estatusEnRuta = constantesOperacion.estatusViajes.enRuta;
const estatusSegmentado = constantesOperacion.estatusViajes.segmentado;

const ProgramacionViajes = ({ facturacionMultiempresa = false }) => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.programacionViajes) || facturacionMultiempresa;
    const filtroKey = "filtrosProgramacionViajes";
    const [datos, setDatos] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [todoSeleccionado, setTodoSeleccionado] = useState(false);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [viajeId, setViajeId] = useState(0);
    const [clonarVisible, setClonarVisible] = useState(false);
    const [crearViajeVacioVisible, setCrearViajeVacioVisible] = useState(false);
    const [viajeSeleccionado, setViajeSeleccionado] = useState({ rutas: [] });
    const [unidadSeleccionada, setUnidadSeleccionada] = useState({});
    const [esVistaCalendario, seEsVistaCalendario] = useState(false);
    const [permisoEditarRecursos, setPermisoEditarRecursos] = useState(false);
    const api = useApiViaje();
    const defaultFilter = {
        fechas: [new Date(), new Date()],
        estatus: [
            constantesOperacion.estatusViajes.porProgramar,
            constantesOperacion.estatusViajes.programado,
            constantesOperacion.estatusViajes.enRuta,
            constantesOperacion.estatusViajes.segmentado,
        ],
    };
    const { empresaId } = useEmpresa();
    const [empresa, setEmpresa] = useState(empresaId);
    const [verModalUnidades, setVerModalUnidades] = useState(false);
    const [itemViaje, setItemViaje] = useState(null);
    const [itemIndex, setItemIndex] = useState(0);
    const ref = useRef(null);
    const detalleRef = useRef();
    const [verModalCajas, setVerModalCajas] = useState(false);
    const [verModalOperadores, setVerModalOperadores] = useState(false);
    const [titulo, setTitulo] = useState(null);

    useEffect(() => {
        let permisoEditarRecursos = seguridad.tieneAcceso(constantes.opciones.EditarAsignacionRecursos);
        setPermisoEditarRecursos(permisoEditarRecursos);
    });

    function obtenerFiltro(filtro) {
        filtro = filtro ?? getSessionState(filtroKey);
        return {
            ...filtro,
            rutaOrigenId: filtro.rutaOrigen?.id,
            rutaDestinoId: filtro.rutaDestino?.id,
            esProgramacion: true,
            SubClienteId: filtro.subClientes?.id ?? 0,
        };
    }

    async function buscar(filtro) {
        const viajes = facturacionMultiempresa
            ? await api.filtrarMultiempresa(obtenerFiltro(filtro))
            : await api.filtrar(obtenerFiltro(filtro));
        obtenerUnidades(viajes);
        setDatos(viajes);

    }

    const obtenerUnidades = (viajes) => {
        let unidadesViaje = [];
        let unidadesAux = viajes.map((e) => e.unidad).filter((e) => e != null);
        unidadesAux = _.uniqBy(unidadesAux, (e) => e.id);

        unidadesAux.forEach((e) => {
            if (e != null) {
                let eventos = viajes.filter(
                    (v) => v.unidad?.id === e.id && v.estatusId !== constantesOperacion.estatusViajes.cancelado
                );
                eventos = _.orderBy(eventos, "fechaInicio");

                const nuevaUnidad = { ...e, eventos };
                obtenerOrdenUnidad(nuevaUnidad);
                unidadesViaje.push(nuevaUnidad);
            }
        });

        unidadesViaje = _.orderBy(unidadesViaje, ["ordenPorEstatus", "fechaParaOrden"]);
        setUnidades(unidadesViaje);
    };

    const obtenerOrdenUnidad = (nuevaUnidad) => {
        const ordenPorEstatus = {
            1: constantesOperacion.estatusViajes.enRuta,
            2: estatusProgramado,
            3: estatusPorProgamar,
            4: constantesOperacion.estatusViajes.terminado,
        };

        for (const orden in ordenPorEstatus) {
            if (nuevaUnidad.eventos.some((e) => e.estatusId === ordenPorEstatus[orden])) {
                //Se obtiene el primer viaje con el estatus encontrado, la fecha de este viaje será la fecha
                //de la unidad, que servirá para ordenar
                const primerViaje = nuevaUnidad.eventos.find((e) => e.estatusId === ordenPorEstatus[orden]);

                nuevaUnidad.ordenPorEstatus = orden;
                nuevaUnidad.fechaParaOrden = primerViaje.fechaInicio;
                break;
            }
        }
    };

    const seleccionarTodo = (e) => {
        const checked = e.target.checked;

        const newDatos = produce(datos, (draft) => {
            datos.forEach((n, index) => {
                draft[index].seleccionado = checked;
            });
        });

        setTodoSeleccionado(checked);
        setDatos(newDatos);
    };

    const seleccionar = (e, item, index) => {
        const checked = e.target.checked;

        const newDatos = produce(datos, (draft) => {
            draft[index].seleccionado = checked;
        });

        setDatos(newDatos);
        setTodoSeleccionado(newDatos.every((e) => e.seleccionado));
    };

    const actualizar = (value, name, index) => {
        let newDatos = produce(datos, (draft) => {
            draft[index][name] = value;
            draft[index].errors = {};

            if (name === "unidad" && !value?.puedeLlevarCaja) {
                draft[index].caja = null;
            }
        });

        obtenerUnidades(newDatos);
        setDatos(newDatos);
    };

    async function seleccionarUnidad(unidad, viaje, index) {
        const unidadAnterior = unidad != null ? await api.obtenerUnidadViajeAnterior(viaje.id, unidad.id) : null;
        if (unidadAnterior != null) {
            setUnidadSeleccionada(unidadAnterior);
            setViajeSeleccionado(viaje);
            setCrearViajeVacioVisible(true);
        } else {
            actualizar(unidad, "unidad", index);
        }
    }

    function abrirForma(viajeId = 0) {
        setSidebarVisible(true);
        setViajeId(viajeId);
    }

    async function onSave() {
        setSidebarVisible(false);
        await buscar();
    }

    async function limpiarRecursos(item) {
        if (await alerts.preguntarSiNoAdvertencia("¿Desea desasignar los recursos al segmento?")) {
            await api.limpiarRecursos(item.id, item);
            await buscar();
            await api.obtener(item.id);
        }
    }

    const editable = (item) => {
        return item.estatusId === estatusProgramado && !item.recursosEditables && !soloLectura;
    };

    const puedeGuardar = (item) => {
        return (item.estatusId === estatusPorProgamar || item.recursosEditables) && !soloLectura;
    };

    async function asignarRecursos(item, index) {
        const validator = new YupValidator(asignacionValidacionEsquema);
        const esValido = await validator.validate(item);
        let newDatos;

        if (esValido) {
            const result = await api.asignarRecursos(item.id, item);
            const cp = result.cartaPorte;

            newDatos = produce(datos, (draft) => {
                draft[index].estatusId = isUndefined(result.estatusId)
                    ? constantesOperacion.estatusViajes.programado
                    : result.estatusId;
                draft[index].estatus = isUndefined(result.estatus) ? "Programado" : result.estatus;
                draft[index].recursosEditables = false;

                if (item.esSegmento) {
                    const indexViajePadre = datos.findIndex((e) => e.id === item.viajePadreId);
                    draft[indexViajePadre].estatusId = result.estatusPadreId;
                    draft[indexViajePadre].estatus = result.estatusPadre;
                    draft[indexViajePadre].cartaPorte = cp; //result.cartaPorte;

                    if (item.esPrimerSegmento) {
                        datos
                            .filter((e) => e.id !== item.id && e.viajePadreId === item.viajePadreId)
                            .forEach((e) => {
                                const indexSegmento = datos.findIndex((s) => s.id === e.id);
                                draft[indexSegmento].caja = result.caja;
                                draft[indexSegmento].coordinador = item.coordinador;
                            });
                    }
                } else {
                    draft[index].cartaPorte = cp; //result.cartaPorte;
                }
            });

            newDatos = agregarQuitarViajeVacio(result, item, newDatos, index);
            obtenerUnidades(newDatos);
        } else {
            newDatos = produce(datos, (draft) => {
                draft[index].errors = validator.errors;
            });
        }

        setDatos(newDatos);
    }

    function agregarQuitarViajeVacio(result, viaje, viajes, index) {
        const newDatos = [...viajes];

        //Si el viaje tenía ligado un viaje vacío, se quita de la lista
        const indexViajeVacio = viajes.findIndex((e) => e.padreIdViajeVacio === viaje.id);

        if (indexViajeVacio !== -1) {
            newDatos.splice(indexViajeVacio, 1);
            index--;
        }

        // Si se generó un viaje vacio, se agrega a la lista
        if (result.viajeVacio != null) {
            //Si tiene viajePadreId es un viaje segmentado, el viaje vacío debe quedar arriba del viaje padre
            const indexPadre = viajes.findIndex((e) => e.id === viaje.viajePadreId);
            index = indexPadre !== -1 ? indexPadre : index;
            newDatos.splice(index, 0, { ...result.viajeVacio });
        }

        return newDatos;
    }

    function recursosDeshabilitados(item, esCaja = false) {
        const cajaDeshabilitada =
            esCaja && (!item.unidad?.puedeLlevarCaja || (item.esSegmento && !item.esPrimerSegmento));

        return (
            (item.estatusId !== estatusPorProgamar && !item.recursosEditables) ||
            cajaDeshabilitada ||
            facturacionMultiempresa
        );
    }

    async function eliminar() {
        const viajesSeleccionados = datos.filter((e) => e.seleccionado);

        if (viajesSeleccionados.length === 0) {
            alerts.aviso("Debe seleccionar al menos un viaje");
            return;
        }

        const viajesProgramados = viajesSeleccionados.some(
            (e) => e.estatusId !== estatusPorProgamar && e.estatusId !== estatusSegmentado
        );
        const viajesIdsConSegmentos = viajesSeleccionados.filter((e) => e.tieneSegmentos).map((e) => e.id);
        const segmentosProgramados = datos.some(
            (e) => viajesIdsConSegmentos.includes(e.viajePadreId) && e.estatusId !== estatusPorProgamar
        );

        if (viajesProgramados || segmentosProgramados) {
            alerts.aviso(
                'Solamente se pueden eliminar viajes con estatus "Por programar", revisa tu selección de viajes'
            );
        } else {
            const ids = viajesSeleccionados.map((e) => e.id).join(",");
            if (await alerts.preguntarSiNoAdvertencia("Se eliminarán los viajes seleccionados ¿Desea continuar?")) {
                await api.eliminar(ids);
                buscar().then();
            }
        }
    }

    async function abrirModalClonar() {
        if (!datos.some((e) => e.seleccionado)) {
            alerts.aviso("Debe seleccionar al menos un viaje");
        } else {
            setClonarVisible(true);
        }
    }

    async function clonado() {
        setClonarVisible(false);
        await buscar();
    }

    function iluminarFila(item, index, tipo) {
        if (!puedeGuardar(item)) return;

        setItemViaje(item);
        setItemIndex(index);

        const indexFilaPrevia = datos.findIndex((e) => (!item || e.id !== item.id) && e.iluminado);

        const newDatos = produce(datos, (draft) => {
            if (index != null) {
                draft[index].iluminado = true;
            }

            if (indexFilaPrevia >= 0) {
                draft[indexFilaPrevia].iluminado = false;
            }
        });

        setDatos(newDatos);

        if (tipo === "unidad") {
            setVerModalUnidades(true);
            setTitulo("Asginar Unidad");
        }

        if (tipo === "caja") {
            setVerModalCajas(true);
            setTitulo("Asignar Caja");
        }

        if (tipo === "operador") {
            setVerModalOperadores(true);
            setTitulo("Asignar Operador");
        }
    }

    const expandir = (item, index) => {
        const expandido = !item.expandido;

        const newDatos = produce(datos, (draft) => {
            draft[index].expandido = expandido;

            const segmentos = datos.filter((e) => e.viajePadreId === item.id);

            segmentos.forEach((s) => {
                const indexSegmento = datos.findIndex((e) => e.id === s.id);

                draft[indexSegmento].visible = expandido;
            });
        });

        setDatos(newDatos);
    };

    function obtenerEstiloFila(item) {
        const highlight = item.iluminado ? "highlight" : "";
        const esSegmento = item.esSegmento ? "viaje-segmento" : "";
        return `${highlight} ${esSegmento}`;
    }

    async function descargarCartaPorte(entity) {
        await api.descargar(entity.id, entity.cartaPorte);
    }

    function obtenerTitle(item, vencido) {
        const mensaje = vencido ? "Mantenimiento vencido hace" : "Próximo mantenimiento en";
        const estatus = item.unidad?.estatusMantenimiento;
        const kilometros = vencido ? estatus?.kilometrosVencidos : estatus?.kilometrosParaMantenimiento;
        return `${mensaje} ${numeral(kilometros).format("0,0")} km`;
    }

    function confirmarUnidad() {
        const index = datos.findIndex((e) => e.id === viajeSeleccionado.id);
        actualizar(unidadSeleccionada, "unidad", index);
        setCrearViajeVacioVisible(false);
    }

    function reporte() {
        if (facturacionMultiempresa) api.reporte({ ...obtenerFiltro(), soloMultiEmpresa: true });
        else api.reporte(obtenerFiltro());
    }
    function reporteProgramaPenske(){
        api.reporteProgramaPenske(obtenerFiltro())
    }
    function reporteCancealados() {
        if (facturacionMultiempresa)
            api.reporte({ ...obtenerFiltro(), estatus: [constantes.estatus.viaje.Cancelado], soloMultiEmpresa: true });
        else api.reporte({ ...obtenerFiltro(), estatus: [constantes.estatus.viaje.Cancelado] });
    }
    const renderFooterModalViajeVacio = () => {
        return (
            <div>
                <Button
                    label="Cancelar"
                    onClick={() => setCrearViajeVacioVisible(false)}
                    className="p-button-outlined"
                />
                <Button label="Confirmar" onClick={confirmarUnidad} autoFocus />
            </div>
        );
    };

    const actualizarUnidad = (unidad) => {
        seleccionarUnidad(unidad, itemViaje, itemIndex);
    };

    const actualizarCaja = (caja) => {
        actualizar(caja, "caja", itemIndex);
    };

    const actualizarOperador = (operador) => {
        setDatos(updateArrayAtIndex(datos, itemIndex, { ...datos[itemIndex], ...operador }));
    };
   
    async function solicitarDryRun(item) {
        if (await alerts.preguntarSiNoAdvertencia("¿Está seguro de generar el viaje como Dry Run?")) {
            await api.solicitamosDryRun(item.id);
            await buscar();
        }
    }
    return (
        <>
            <Filtro filtroKey={filtroKey} classButtons="field-iconos" styleForm={{ marginTop: "40px" }}
                handleSubmit={buscar} showFilterButtons modulo={constantes.modulos.administracionViajes}
                defaultValue={defaultFilter} filtrarAlInicio={false}>
                <>
                    <button
                        type="button"
                        onClick={() => seEsVistaCalendario(false)}
                        className={`btn btn-sm ${esVistaCalendario ? "btn-outline-primary" : "btn-primary"}`}
                    >
                        Ver Programación
                    </button>

                    <button
                        type="button"
                        onClick={() => seEsVistaCalendario(true)}
                        className={`btn btn-sm ${esVistaCalendario ? "btn-primary" : "btn-outline-primary"}`}
                    >
                        Ver Calendario
                    </button>
                </>

                <FiltrosProgramacion filtro={defaultFilter} />
                <FiltrosOcultos name="filtrosOcultos" />
            </Filtro>

            <Sidebar
                visible={sidebarVisible}
                position="right"
                dismissable={false}
                className="p-sidebar-xl"
                onHide={() => setSidebarVisible(false)}
                showCloseIcon={true}
            >
                <DetalleViaje
                    viajeId={viajeId}
                    onSave={onSave}
                    close={() => setSidebarVisible(false)}
                    ref={detalleRef}
                    soloLectura={soloLectura}
                    facturacionMultiempresa={facturacionMultiempresa}
                />
            </Sidebar>

            {esVistaCalendario ? (
                <CalendarioUnidadViaje unidades={unidades} />
            ) : (
                <div className="table-custom">
                    <table className="table table-sm table-striped table-fixed">
                        <thead>
                            <tr>
                                <th style={{ width: "2%" }}>
                                    <Checkbox onChange={seleccionarTodo} checked={todoSeleccionado} />
                                </th>
                                <th style={{ width: "1%" }} />
                                <th className="text-left" style={{ width: "8%" }}>
                                    FECHA
                                </th>
                                <th style={{ width: "29%" }}>VIAJE</th>
                                <th style={{ width: "8%" }}>CARTA PORTE</th>
                                <th className="text-left" style={{ width: "8%" }}>
                                    ESTATUS
                                </th>
                                <th style={{ width: "4%" }} />
                                <th style={styleEspacioCelda} />
                                <th style={{ width: '7%' }}>UNIDAD</th>
                                <th style={{ width: '7%' }}>CAJA</th>
                                <th className="text-left" style={{ width: '15%' }}>OPERADOR</th>
                                <th className="header-icons" style={{ width: '8%' }}>
                                    <div className="d-flex" style={{ float: 'right' }}>
                                        <ButtonIcon title="Descargar" onClick={reporte} iconName={'BiDownload'} />
                                        <ButtonIcon title="Programa Penske" onClick={reporteProgramaPenske} iconName={'BiDownload'} />
                                        <ButtonIcon title="Descargar Cancelados" onClick={reporteCancealados} iconName={'BiDownload'} />
                                        {!soloLectura &&
                                            <>
                                                <ButtonIcon
                                                    title="Eliminar"
                                                    iconName={"RiDeleteBinLine"}
                                                    onClick={eliminar}
                                                />
                                                <ButtonIcon
                                                    title="Clonar"
                                                    iconName={"IoDuplicateOutline"}
                                                    onClick={abrirModalClonar}
                                                />
                                                <ButtonIcon
                                                    title="Agregar"
                                                    className="grid-action"
                                                    iconName={"MdAddBox"}
                                                    onClick={abrirForma}
                                                />
                                            </>
                                        }
                                    </div>
                                </th>
                            </tr>
                        </thead>

                        <tbody ref={ref}>
                            {datos.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {(!item.esSegmento || item.visible) && (
                                            <tr key={item.id} className={obtenerEstiloFila(item)}>
                                                <td className="text-center" style={{ width: "2%" }}>
                                                    {!item.esSegmento && (
                                                        <Checkbox
                                                            checked={item.seleccionado}
                                                            onChange={(e) => seleccionar(e, item, index)}
                                                        />
                                                    )}
                                                </td>

                                                <td className="text-center p-0" style={{ width: "1%" }}>
                                                    {item.cliente?.esPreferente && !item.esSegmento && (
                                                        <i className="pi pi-star text-primary" />
                                                    )}
                                                </td>

                                                <td style={{ width: "8%" }}>
                                                    {!item.esSegmento && (
                                                        <>
                                                            <FormattedDate value={item.fechaInicio} weekday="short" />{" "}
                                                            &nbsp;
                                                            {moment(item.fechaInicio).format("DD/MM/YYYY")}
                                                        </>
                                                    )}
                                                </td>

                                                <td style={{ width: "29%" }}>
                                                    {!item.esSegmento && (
                                                        <>
                                                            <b>{item.cliente?.nombre || "VACÍO"}</b>
                                                            <span
                                                                className="pl-1 text-muted"
                                                                style={{ fontSize: "0.8em" }}
                                                            >
                                                                {item.idViaje != null &&
                                                                    item.idViaje != "" &&
                                                                    item.idViaje}
                                                                {item.idViajeRetorno != null &&
                                                                    item.idViajeRetorno != "" &&
                                                                    ` / ${item.idViajeRetorno} (Ret.)`}
                                                            </span>
                                                        </>
                                                    )}

                                                    <div
                                                        className="d-flex flex-wrap align-items-center"
                                                        style={{ fontSize: "0.8em" }}
                                                    >
                                                        {item.rutas.map((r, index) => {
                                                            return (
                                                                <React.Fragment key={r.id}>
                                                                    <div>{`${r.nombre} (${r.fechayHora})`}</div>
                                                                    {index < item.rutas.length - 1 && (
                                                                        <i className="pi pi-arrow-right px-1" />
                                                                    )}
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </div>
                                                </td>

                                                <td className="text-center" style={{ width: "8%" }}>
                                                    {item.esSegmento ? "" : item.cartaPorte}
                                                </td>

                                                <td style={{ width: "8%" }}>{item.estatus}</td>

                                                <td className="text-center" style={{ width: "4%" }}>
                                                    <div className="d-flex">
                                                        {!item.esSegmento && (
                                                            <button
                                                                type="button"
                                                                className="btn btn-link btn-sm p-0"
                                                                onClick={() => abrirForma(item.id)}
                                                            >
                                                                VER
                                                            </button>
                                                        )}

                                                        {item.tieneSegmentos && (
                                                            <div
                                                                className="cursor-pointer d-flex pl-1 align-items-center"
                                                                onClick={() => expandir(item, index)}
                                                                title="Ver Segmentos"
                                                                style={{ fontSize: "1rem" }}
                                                            >
                                                                <IconLibrary
                                                                    iconName={`IoIosArrowDrop${
                                                                        item.expandido ? "downCircle" : "rightCircle"
                                                                    }`}
                                                                />
                                                            </div>
                                                        )}
                                                        {item.esSegmento &&
                                                            item.ultimoSegmentoConRecursos &&
                                                            !item.liquidado &&
                                                            (item.estatusId == estatusProgramado ||
                                                                item.estatusId == estatusEnRuta) && (
                                                                <div className="d-flex align-items-center">
                                                                    <div className="table-button flex-grow-1">
                                                                        <ButtonIcon
                                                                            title="Limpiar Recursos"
                                                                            className="grid-action"
                                                                            iconName={"BiEraser"}
                                                                            onClick={() => limpiarRecursos(item)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </div>
                                                </td>

                                                <td style={styleEspacioCelda} />

                                                {item.tieneSegmentos && <td colSpan={3} style={{ width: "29%" }} />}

                                                {!item.tieneSegmentos && (
                                                    <>
                                                        <td style={{ width: "7%" }}>
                                                            <div className={`d-flex panel-sm}`}>
                                                                <div className="flex-grow-1">
                                                                    <div
                                                                        className={`position-relative custom-panel ${
                                                                            item?.unidad ? "" : "empty"
                                                                        } ${
                                                                            recursosDeshabilitados(item) ||
                                                                            item.padreIdViajeVacio != null
                                                                                ? "disabled"
                                                                                : ""
                                                                        }`}
                                                                    >
                                                                        <div
                                                                            className={`px-2 d-flex align-items-center dropdown-custom`}
                                                                            onClick={() =>
                                                                                iluminarFila(item, index, "unidad")
                                                                            }
                                                                        >
                                                                            <span className="flex-grow-1">
                                                                                {item?.unidad?.nombre}
                                                                            </span>
                                                                            <i className="pi pi-angle-down" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {item.unidad &&
                                                                    item.estatusId === estatusPorProgamar && (
                                                                        <i
                                                                            className="pi pi-times cursor-pointer align-self-center pl-1"
                                                                            onClick={() =>
                                                                                seleccionarUnidad(null, item, index)
                                                                            }
                                                                        />
                                                                    )}
                                                            </div>
                                                        </td>

                                                        <td style={{ width: "7%" }}>
                                                            <div className={`d-flex panel-sm}`}>
                                                                <div className="flex-grow-1">
                                                                    <div
                                                                        className={`position-relative custom-panel ${
                                                                            item?.caja ? "" : "empty"
                                                                        } ${
                                                                            recursosDeshabilitados(item, true)
                                                                                ? "disabled"
                                                                                : ""
                                                                        }`}
                                                                    >
                                                                        <div
                                                                            className={`px-2 d-flex align-items-center dropdown-custom`}
                                                                            onClick={() =>
                                                                                iluminarFila(item, index, "caja")
                                                                            }
                                                                        >
                                                                            <span className="flex-grow-1">
                                                                                {item?.caja?.nombre}
                                                                            </span>
                                                                            <i className="pi pi-angle-down" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {item.caja && item.estatusId === estatusPorProgamar && (
                                                                    <i
                                                                        className="pi pi-times cursor-pointer align-self-center pl-1"
                                                                        onClick={() => actualizar(null, "caja", index)}
                                                                    />
                                                                )}
                                                            </div>
                                                        </td>

                                                        <td style={{ width: "15%" }}>
                                                            <div className={`d-flex panel-sm}`}>
                                                                <div className="flex-grow-1">
                                                                    <div
                                                                        className={`position-relative custom-panel ${
                                                                            item?.operador ? "" : "empty"
                                                                        } ${
                                                                            recursosDeshabilitados(item, true)
                                                                                ? "disabled"
                                                                                : ""
                                                                        }`}
                                                                    >
                                                                        <div
                                                                            className={`px-2 d-flex align-items-center dropdown-custom`}
                                                                            onClick={() =>
                                                                                iluminarFila(item, index, "operador")
                                                                            }
                                                                        >
                                                                            <span className="flex-grow-1">
                                                                                {item?.operador?.nombre}
                                                                            </span>
                                                                            <i className="pi pi-angle-down" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {item.operador &&
                                                                    item.estatusId === estatusPorProgamar && (
                                                                        <i
                                                                            className="pi pi-times cursor-pointer align-self-center pl-1"
                                                                            onClick={() =>
                                                                                actualizar(null, "operador", index)
                                                                            }
                                                                        />
                                                                    )}

                                                                <div
                                                                    className={"d-flex align-items-center"}
                                                                    style={{ marginLeft: "15px" }}
                                                                >
                                                                    <span
                                                                        style={{ cursor: "pointer" }}
                                                                        className={`tooltip-${item.id}`}
                                                                    >
                                                                        <i
                                                                            className="fa fa-info-circle"
                                                                            style={{ marginLeft: "-5px" }}
                                                                        ></i>
                                                                        {item.coordinador?.iniciales}
                                                                    </span>
                                                                    <Tooltip
                                                                        style={{ fontSize: "10px" }}
                                                                        target={`.tooltip-${item.id}`}
                                                                        effect="solid"
                                                                    >
                                                                        {item.coordinador?.nombreCompleto}
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </>
                                                )}

                                                <td style={{ width: "8%" }}>
                                                    {item.tieneSegmentos ? (
                                                        <div className="d-flex align-items-center">
                                                            <div className="d-flex pr-2" style={{ fontSize: "1.1rem" }}>
                                                                <div className="text-primary" title="Segmentado">
                                                                    <IconLibrary iconName="MdCallSplit" />
                                                                </div>
                                                            </div>
                                                            {item.timbrado && (
                                                                <div
                                                                    className="d-flex pr-2"
                                                                    style={{ fontSize: "1.1rem" }}
                                                                >
                                                                    <div className="text-primary" title="Timbrado">
                                                                        <IconLibrary iconName="FaDollarSign" />
                                                                    </div>
                                                                    <div
                                                                        className="d-flex flex-wrap align-items-center"
                                                                        style={{ fontSize: "0.9em" }}
                                                                    >
                                                                        {item.numeroFactura != null && (
                                                                            <>
                                                                                <div
                                                                                    className="d-flex flex-wrap align-items-center"
                                                                                    style={{ fontSize: "0.9em" }}
                                                                                >
                                                                                    {item.numeroFactura !== null && (
                                                                                        <>
                                                                                            {" "}
                                                                                            {`(${item.numeroFactura})`}
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className="d-flex align-items-center">
                                                            <div className="table-button flex-grow-1">
                                                                {puedeGuardar(item) && (
                                                                    <ButtonIcon
                                                                        title="Guardar"
                                                                        className="grid-action"
                                                                        iconName={"AiOutlineSave"}
                                                                        onClick={() => asignarRecursos(item, index)}
                                                                    />
                                                                )}
                                                                {editable(item) && (
                                                                    <ButtonIcon
                                                                        title="Editar"
                                                                        className="grid-action"
                                                                        iconName={"FaRegEdit"}
                                                                        onClick={() =>
                                                                            actualizar(true, "recursosEditables", index)
                                                                        }
                                                                    />
                                                                )}
                                                                {item.estatusId === estatusEnRuta &&
                                                                    permisoEditarRecursos &&
                                                                    !item.recursosEditables &&
                                                                    !soloLectura && (
                                                                        <ButtonIcon
                                                                            title="Editar"
                                                                            className="grid-action"
                                                                            iconName={"FaRegEdit"}
                                                                            onClick={() =>
                                                                                actualizar(
                                                                                    true,
                                                                                    "recursosEditables",
                                                                                    index
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                    
                                                            </div>

                                                            {item.timbrado === true && (
                                                                <div
                                                                    className="d-flex pr-2"
                                                                    style={{ fontSize: "1.1rem" }}
                                                                >
                                                                    <div className="text-primary" title="Timbrado">
                                                                        <IconLibrary iconName="FaDollarSign" />
                                                                    </div>
                                                                    <div
                                                                        className="d-flex flex-wrap align-items-center"
                                                                        style={{ fontSize: "0.9em" }}
                                                                    >
                                                                        {item.numeroFactura != null && (
                                                                            <>
                                                                                <div
                                                                                    className="d-flex flex-wrap align-items-center"
                                                                                    style={{ fontSize: "0.9em" }}
                                                                                >
                                                                                    {item.numeroFactura !== null && (
                                                                                        <>
                                                                                            {" "}
                                                                                            {`(${item.numeroFactura})`}
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {item.esSegmento &&
                                                                item.estatusId != estatusCancelado &&
                                                                item.estatusId != estatusPorProgamar && (
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="table-button flex-grow-1">
                                                                            <ButtonIcon
                                                                                title="Descargar carta porte"
                                                                                className="grid-action"
                                                                                iconName={"IoDocumentTextOutline"}
                                                                                onClick={() =>
                                                                                    descargarCartaPorte(item)
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            {item.unidad !== null &&
                                                                item.unidad?.estatusMantenimiento.estatus !==
                                                                    constantes.estatusMantenimiento.ok && (
                                                                    <div
                                                                        className="d-flex pr-2"
                                                                        style={{ fontSize: "1.1rem" }}
                                                                    >
                                                                        {item.unidad?.estatusMantenimiento.estatus ===
                                                                            constantes.estatusMantenimiento.proximo && (
                                                                            <div
                                                                                className="text-warning"
                                                                                title={obtenerTitle(item, false)}
                                                                            >
                                                                                <IconLibrary iconName="VscTools" />
                                                                            </div>
                                                                        )}

                                                                        {item.unidad?.estatusMantenimiento.estatus ===
                                                                            constantes.estatusMantenimiento.vencido && (
                                                                            <div
                                                                                className="text-danger"
                                                                                title={obtenerTitle(item, true)}
                                                                            >
                                                                                <IconLibrary iconName="VscTools" />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            
                                                        </div>
                                                    )}
                                                </td>
                                                <td style={{ width: "10%" }}>
                                                {item.viajeEsSegmentado && item.cliente?.id === constantes.cliente.Penske &&
                                                (item.estatusId === estatusEnRuta || item.estatusId === estatusProgramado) &&
                                                (
                                                    <div className="d-flex align-items-center">
                                                        <div className="table-button flex-grow-1">
                                                            <button title="Dry Run" type="button" className="button-icon"
                                                                onClick={() => solicitarDryRun(item, index)}>
                                                                <i className="pi pi-times-circle text"/>
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                                }
                                            </td>

                                            </tr>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}

            <ClonarViaje
                viajes={datos}
                visible={clonarVisible}
                hide={() => setClonarVisible(false)}
                onSuccess={clonado}
            />

            <Dialog
                showHeader={false}
                visible={crearViajeVacioVisible}
                style={{ width: "40vw" }}
                footer={renderFooterModalViajeVacio()}
                onHide={() => setCrearViajeVacioVisible(false)}
            >
                <div className="form-row">
                    {unidadSeleccionada != null && (
                        <>
                            <div className="row mt-4">
                                <div className="col-12 text-center">
                                    <h5 className="mb-0">Es necesario agregar un viaje vacío,</h5>
                                </div>

                                <div className="col-12 text-center">
                                    <h5>{`¿Desea que se agregue para unidad ${unidadSeleccionada?.nombre}?`}</h5>
                                </div>

                                <div className="col-12 text-center mt-3">
                                    <span>Viaje Vacío: {unidadSeleccionada.direccionDestino}</span>
                                    <i className="pi pi-arrow-right px-2" style={{ fontSize: "0.6rem" }} />
                                    <span className="pr-1">{viajeSeleccionado.rutas[0]?.nombre}</span>
                                    <span>{unidadSeleccionada.kilometros} Km</span>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </Dialog>
            <Dialog
                header={titulo}
                visible={verModalUnidades}
                style={{ width: "75vw" }}
                onHide={() => setVerModalUnidades(false)}
            >
                <PanelUnidades
                    viaje={itemViaje}
                    viajes={datos}
                    cambiarEmpresa={setEmpresa}
                    onHide={() => setVerModalUnidades(false)}
                    onChange={actualizarUnidad}
                    empresaId={empresa}
                />
            </Dialog>
            <Dialog
                header={titulo}
                visible={verModalCajas}
                style={{ width: "75vw" }}
                onHide={() => setVerModalCajas(false)}
            >
                <PanelUnidades
                    viaje={itemViaje}
                    viajes={datos}
                    esCaja
                    cambiarEmpresa={setEmpresa}
                    onHide={() => setVerModalCajas(false)}
                    onChange={actualizarCaja}
                    empresaId={empresa}
                />
            </Dialog>
            <Dialog
                header={titulo}
                visible={verModalOperadores}
                style={{ width: "75vw" }}
                onHide={() => setVerModalOperadores(false)}
            >
                <PanelOperadores
                    viaje={itemViaje}
                    viajes={datos}
                    empresaId={empresa}
                    onHide={() => setVerModalOperadores(false)}
                    onChange={actualizarOperador}
                />
            </Dialog>
        </>
    );
};

export const FiltrosProgramacion = ({ filtro, onChange }) => {
    return (
        <>
            <FieldMultiSelect
                name="clientes"
                source="clientesViajes"
                label="Cliente"
                colLg={2}
                colMd={2}
                value={filtro.clientes}
                onChange={onChange}
                showFilter
                valueAsId={true}
            />
            <FieldDropDown
                name="subClientes"
                label="SubCliente"
                source="subClientes"
                params={{ clientesIds: filtro.clientes ?? 0 }}
                showClear={true}
                colMd={2}
                value={filtro.subClientes}
                onChange={onChange}
            />

            <FieldCalendarRange
                name="fechas"
                label="Fechas"
                colLg={2}
                colMd={3}
                value={filtro.fechas}
                onChange={onChange}
            />

            <FieldTextBox
                name="idViaje"
                label="ID Viaje"
                colLg={1}
                colMd={1}
                value={filtro.idViaje}
                onChange={onChange}
            />

            <FieldTextBox
                name="codigoRuta"
                label="Código Ruta"
                colLg={1}
                colMd={2}
                value={filtro.codigoRuta}
                onChange={onChange}
            />

            <FieldTextBox
                name="cartaPorte"
                label="Carta Porte"
                colLg={1}
                colMd={3}
                value={filtro.cartaPorte}
                onChange={onChange}
            />

            <FieldMultiSelect
                name="estatus"
                source="estatusViaje"
                label="Estatus"
                colLg={1}
                colMd={3}
                value={filtro.estatus}
                onChange={onChange}
                valueAsId={true}
            />

            <FieldTextBox name="unidad" label="Unidad" colLg={1} colMd={3} value={filtro.unidad} onChange={onChange} />

            <FieldTextBox name="caja" label="Caja" colLg={1} colMd={3} value={filtro.caja} onChange={onChange} />
            
            <div className="d-flex justify-content-start">
                <div className="me-2">
                    <FieldCheckBox
                        flexflow="row-reverse"
                        name="timbrado"
                        label="Timbrado"
                        colLg={2}
                        colMd={11}
                        value={filtro.timbrado}
                        onChange={onChange}
                    />
                </div>
                <div className="me-2">
                    <FieldCheckBox
                        flexflow="row-reverse"
                        name="filterDryRun"
                        label="DryRun"
                        colLg={2}
                        colMd={11}
                        value={filtro.filterDryRun}
                        onChange={onChange}
                    />
                </div>
            </div>
        </>
    );
};

const FiltrosOcultos = ({ filtro, onChange }) => {
    return (
        <>
            <div className="form-group col-md-4 col-sm-12">
                <label>Ruta</label>
                <div className="col-auto">
                    <FieldAutoComplete
                        name="rutaOrigen"
                        colMd={2}
                        freeText={true}
                        placeholder="Desde"
                        source="municipios"
                        value={filtro.rutaOrigen}
                        onChange={onChange}
                    />
                    <FieldAutoComplete
                        name="rutaDestino"
                        colMd={2}
                        freeText={true}
                        placeholder="Hasta"
                        source="municipios"
                        value={filtro.rutaDestino}
                        onChange={onChange}
                    />
                </div>
            </div>

            <FieldTextBox
                name="numeroFactura"
                label="N° Factura"
                colLg={1}
                colMd={3}
                value={filtro.factura}
                onChange={onChange}
            />
            <FieldMultiSelect
                name="tiposCliente"
                source="tiposCliente"
                label="Tipo Cliente"
                colLg={1}
                colMd={3}
                value={filtro.clientes}
                onChange={onChange}
                showFilter
                valueAsId={true}
            />
            <FieldTextBox
                name="numeroconvenioExpeditado"
                label="Convenio/Expeditado"
                colLg={2}
                colMd={1}
                value={filtro.numeroconvenioExpeditado}
                onChange={onChange}
            />
            <FieldMultiSelect
                name="operadores"
                source="empleados"
                label="Operador"
                colLg={2}
                colMd={3}
                value={filtro.operadores}
                onChange={onChange}
                showFilter
                valueAsId={true}
                params={{
                    puestoIds: [constantes.puestos.operador],
                    modulo: constantes.modulos.administracionViajes,
                }}
            />
            <FieldMultiSelect
                name="tipoViajeIds"
                source="tiposProgramacionViaje"
                label="Tipo Viaje"
                colLg={2}
                colMd={2}
                value={filtro.tipoViajeIds}
                onChange={onChange}
                valueAsId={true}
            />
            <div className="form-group d-flex flex-column col-lg-2 col-md-3 col-sm-12">
                <label htmlFor={"internacional"}>Impo/Expo</label>
                <Checkbox
                    inputId={"internacional"}
                    name={"internacional"}
                    onChange={(e) => onChange(e.checked, "internacional")}
                    checked={filtro.internacional}
                />
            </div>
        </>
    );
};

export default ProgramacionViajes;
