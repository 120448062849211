import React, {useEffect, useState} from "react";
import ImageMarker from "react-image-marker";

const CustomMarker = ({markerContainer, onAddMarker, src, gridColumnEnd, gridRowEnd, params, title, markerTemplate}) => {
    const [markers, setMarkers] = useState([]);

    useEffect(()=>{
        setMarkers( markerContainer.map(e=>
            {
                return {
                    ...e.marker,
                    entity:e
                };
            }));

    },[markerContainer]);


    return (
        <>
            <div style={{gridColumnEnd: gridColumnEnd, gridRowEnd: gridRowEnd, marginBottom:'24px'}}>
                <label>{title}</label>
                <ImageMarker
                    src={src}
                    markers={markers}
                    onAddMarker={ (marker)=> onAddMarker(marker, params)}
                    markerComponent={markerTemplate}
                />
            </div>
        </>
    );
};

export default CustomMarker;
