import React, {useState} from "react";
import {Dialog} from "primereact/dialog";
import {useEmpresa} from "../layout/EmpresaProvider";

const Thumbnail = ({value}) => {

    const {empresaId} = useEmpresa();

    value = {
        ...value,
        url: value?.file != null ? value.file :
            value?.url == null ? '' :
                `${process.env.REACT_APP_API_URL}/archivos/imagen/${empresaId}?url=${value.url}`
    };

    const [visible, setVisible] = useState(false);
    return (
        <>
            {visible === false &&
            <img alt="img" src={value.url} width={80} onClick={() => setVisible(true)}/>
            }
            <Dialog visible={visible} style={{width: '98vw'}}
                    onHide={() => setVisible(false)}>
                <div className="thumbnail-modal">
                        <div className="margenes"/>
                        <img alt="img" src={value.url} width={'70%'} onClick={() => setVisible(true)}/>
                        <div className="margenes"/>
                </div>
            </Dialog>

        </>
    );
};

export default Thumbnail;
