/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from "react";
import {isArray} from "lodash";
import useForm from "../shared/forms/useForm";
import {usePager} from "../shared/ag-grid-helper/PagerProvider";


const useGridFilter = ({api, initialFilter, filterAtLoad}) => {
    const gridRef = useRef(null);
    const [id, setId] = useState(null);
    const [data, setData] = useState([]);
    const filterForm = useForm(initialFilter);
    const [sortColumn, setSortColumn] = useState('');
    const [enableFilter, setEnableFilter] = useState(false);
    const [fireFilter, setFireFilter] = useState(filterAtLoad ? 1 : 0);
    const {page, pageSize, setTotalRows, reset:restePager} = usePager() ??{};
    const enabledPaging = pageSize != null;

    useEffect(() => {
        if (enableFilter || fireFilter)
            callFilter(filterForm.values).then();
        setEnableFilter(true);
    }, [page, sortColumn, fireFilter]);

    const callFilter = async (filter) => {

        const result = await api.filter(getPagedFilter(filter));
        const data = isArray(result) ? result : result.rows;

        if (enabledPaging)
            setTotalRows(result.rowCount);

        setData(data);
    };

    const getPagedFilter = (filter, sort) => {
        if (!enabledPaging)
            return filter;

        return {
            ...filter,
            pageSize,
            page,
            sortColumn: sort ?? sortColumn
        }
    }

    const onSaved = async () => {
        setId(null);
        await callFilter(filterForm.values);
    }

    const  onFilter = async ()=>{
        //if not enabled pagin fires directly filter function
        if (!enabledPaging) {
            await callFilter(filterForm.values);
            return;
        }

        //to reset page on page 1
        restePager();
        //update enabledFilter to fire useEffect and filter values
        setFireFilter(fireFilter + 1);
    }

    const onExport = async () => {
        await api.export(filterForm.values);
    }

    const onSortChanged = ({columnApi}) => {
        const sortColumn = columnApi.getColumnState().find(e => e.sort != null);
        const sortColumnText = sortColumn == null ? '' : `${sortColumn.colId} ${sortColumn.sort}`;
        setSortColumn(sortColumnText);
    }

    return {
        gridRef,
        data,
        setData,
        onFilter,
        filterForm,
        editForm: {
            id: id,
            onCancel: () => setId(null),
            onSaved: onSaved
        },
        addEditGridContext:
            {
                onEdit: (id) => setId(id),
                onAdd: () => setId(0),
                onExport: onExport
            },
        onExport,
        onSortChanged
    }
}

export default useGridFilter
