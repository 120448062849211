import React from "react";
import FieldMultiSelect from "../../shared/controls/fieldMultiSelect";
import FieldTextBox from "../../shared/controls/fieldTextBox";

const FiltroClientes = ({filtro, onChange}) => {

    return (
        <>
            <FieldTextBox name="cliente" label="Cliente" colMd={3} 
                              value={filtro.cliente} onChange={onChange}/>

            <FieldMultiSelect name="estatus" source="estatusClientes" label="Estatus" colMd={2} valueAsId={true}
                           value={filtro.estatus} onChange={onChange}/>
        </>
    )
};

export default FiltroClientes;
