import React from "react";
import FieldTextBox from "../../../shared/controls//fieldTextBox";
import FieldDropDown from "../../../shared/controls/fieldDropDown";

const cfdiRelacionados = ({viaje, errors, actualizar, soloLectura = false}) => {


    return (
        <>
            <div className="form-row">
                <FieldDropDown name="tipoRelacion" label="Tipo de Relación"
                               colMd={12} source="cfditiposRelacion"
                               disabled={viaje.timbrado || soloLectura}
                               value={viaje?.tipoRelacion} onChange={actualizar}/>

                <FieldTextBox name="uuid" value={viaje.uuid} onChange={actualizar}
                              label="UUID CDFI Relacionado" colMd={12} disabled={viaje?.timbrado || soloLectura}/>

            </div>


        </>
    );
}
export default cfdiRelacionados;
