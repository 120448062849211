import useServiceFactory from "../serviceFactory";

const apiUrl =  `/catalogos/empleados`;

const useApiRetiroAhorro = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
    
        obtenerRetiroAhorro: async (params) => service.get(`${apiUrl}/obtenerRetiroAhorro`, { params, showLoading: true }),
        retiroAhorroExcel: async (params) => service.get(`${apiUrl}/retiroAhorroexcel`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `RetiroAhorro.xlsx`
            }),
        }
};

export default useApiRetiroAhorro;