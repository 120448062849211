export const tiposCombustible = {
    diesel: 1,
    gasolina:2,
    urea:3,
    gas:4
}


export const estatus = {
    registrado:1,
    cerrado:2
}

export const tiposProveedor = {
    combustible:11,
    pipas:12
}

export const motivos = {
    malFuncionamiento : 3
}

