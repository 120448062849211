/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from "react";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import {getSessionState} from "../../hooks/useSessionState";
import useApiLiberacionViaje from "../../api/administracion/useApiLiberacionViaje";
import Filtro from "../../shared/componentes/filtro";
import FieldCalendarRange from "../../shared/controls/fieldCalendarRange";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import FieldMultiSelect from "../../shared/controls/fieldMultiSelect";
import constantes from "../../catalogos/constantes";
import constantesAdministracion from "../../administracion/constantes";
import {FormattedDate} from "react-intl";
import moment from "moment";
import {Sidebar} from "primereact/sidebar";
import DetalleLiberacionViaje from "./liberacionViajeDetalle";
import PdfViewer from "../../shared/componentes/pdfViewer";
import useSeguridad from "../../Seguridad/useSeguridad";
import numeral from "numeral";
import alerts from "../../shared/alerts";
import {Dialog} from "primereact/dialog";
import FieldTextArea from "../../shared/controls/fieldTextArea";
import {Button} from "primereact/button";
const filtroDefault = {
    estatus: [constantesAdministracion.estatusLiberacion.porLiberar, constantesAdministracion.estatusLiberacion.porGenerarRv]
};

const LiberacionViaje = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.liberacion);
    const filtroKey = 'FiltroLiberacionViaje';
    const [datos, setDatos] = useState([]);
    //const [gridApi, setGridApi] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [viajeId, setViajeId] = useState(false);
    const api = useApiLiberacionViaje();
    const [pdfReciboValorVisible, setPdfReciboValorVisible] = useState(false);
    const [comentario, setComentario]= useState("");
    const [verModal, setVerModal]= useState(false)
    const [liberacionId,setLiberacionId] =useState(0);
    const obtenerFiltro = (filtro) => {
        filtro = filtro ?? getSessionState(filtroKey);

        return {...filtro, rutaOrigenId: filtro.rutaOrigen?.id, rutaDestinoId: filtro.rutaDestino?.id};
    };
    const permisorechazarLiberacion = seguridad.tieneAcceso(constantes.opciones.rechazarLiberacion);
    async function buscar(filtro, paramsApi) {
        //agGridHelper.showLoad(gridApi, paramsApi, true);
        let datos = await api.filtrar(obtenerFiltro(filtro));
        setDatos(datos);
        //agGridHelper.hideLoad(gridApi, paramsApi, false);
    }

    async function onSave() {
        setSidebarVisible(false);
        await buscar();
    }

    const verViaje = (id) => {
        setSidebarVisible(true);
        setViajeId(id);
    };

    function exportar() {
        const filtro = obtenerFiltro(null);
        api.excel(filtro);
    }

    const verRecibo = (id) => {
        setViajeId(id);
        setPdfReciboValorVisible(true);
    };

    const descargarPdf = async () => {
        await api.pdf(viajeId);
        setPdfReciboValorVisible(false);
    };
    async function preguntaRechazar (id,viajeId){     
        if(await alerts.preguntarSiNo(`${"Rechazar liberación"}, ¿deseas continuar?`)){            
            setVerModal(true);
            setLiberacionId(id);
            setViajeId(viajeId);
        }        
    }    
    async function rechazar(){
        await api.rechazarLiberacion(liberacionId,{comentario: comentario});
        await buscar();
        setVerModal(false);
    }
    const renderFooterModal = () => {
        return (
            <div>
                <Button label="Cerrar" onClick={()=> setVerModal(false)} className="p-button-outlined"/>
                <Button label="Rechazar" onClick={rechazar} autoFocus/>
            </div>
        );
    };

    return (
        <>
            <Filtro filtroKey={filtroKey} handleSubmit={buscar} classButtons="field-iconos btn-group"
                    filtrarAlInicio={false} defaultValue={filtroDefault}
                    fechasDiasInicio={21} fechasRangoDias={21}>
                <FiltrosLiberacion/>
            </Filtro>

            <Sidebar visible={sidebarVisible} position="right" dismissable={false} className="p-sidebar-xl"
                     onHide={() => setSidebarVisible(false)}>
                <DetalleLiberacionViaje viajeId={viajeId} onSave={onSave} actualizarViajes={buscar}
                                        close={() => setSidebarVisible(false)} soloLectura={soloLectura}/>
            </Sidebar>

            <div className="table-custom">
                <table className="table table-sm table-striped table-fixed"
                       style={{height: '98%'}}>
                    <thead>
                    <tr>
                        <th style={{width: 100}}>Fecha</th>
                        <th style={{width: 200}}>Operador</th>
                        <th style={{width: 350}}>Viaje</th>
                        <th>Unidad</th>
                        <th>Caja</th>
                        <th>Estatus Viaje</th>
                        <th>Carta Porte</th>
                        <th style={{width: 100}}>Comisión</th>
                        <th style={{width: 100}}>Segmentos</th>
                        <th style={{width: 60}}>RV</th>
                        <th style={{width: 65}}>DOC</th>
                        <th>Estatus</th>
                        {(permisorechazarLiberacion) &&
                        <th>Rechazar</th>
                        }
                        <th style={{width: 80}}>
                            <div className={'d-flex justify-content-end'}>
                                <ButtonIcon title={'Descargar'} onClick={exportar} iconName={'BiDownload'}/>
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {datos?.map((item, index) => {
                        const rutas = item.ruta.split((" - "));
                        const arrayDoc = item.estatusDocumentos?.split(',');

                        return (
                            <tr key={index}>
                                <td className="text-center" style={{width: 100}}>
                                    <div>
                                        <FormattedDate value={item.fecha} weekday="short"/> &nbsp;
                                        {moment(item.fecha).format('DD/MM/YYYY')}
                                    </div>
                                </td>
                                <td style={{width: 200}}>{item.operador}</td>
                                <td style={{width: 350}}>
                                    <b>{item.cliente}</b>
                                    <span className="pl-1 text-muted"
                                          style={{fontSize: '0.8em'}}>{item.idViaje != null && item.idViaje !== "" && item.idViaje}
                                        {item.idViajeRetorno != null && item.idViajeRetorno !== "" &&
                                            ` / ${item.idViajeRetorno} (Ret.)`
                                        }
                                        </span>
                                    <div className="d-flex flex-wrap align-items-center"
                                         style={{fontSize: '0.8em'}}>
                                        {rutas.map((r, idx) => {
                                            return <React.Fragment key={idx}>
                                                {idx === 0 ?
                                                    <div>{r}</div>
                                                    : <>
                                                        <i className="pi pi-arrow-right px-1"
                                                           style={{fontSize: '0.5rem'}}/>
                                                        <div className="pr-1">{r}</div>
                                                        {item.esSegmento && item.tramo > 0 &&
                                                            <span>(Tramo {item.tramo})</span>}
                                                    </>
                                                }
                                            </React.Fragment>
                                        })}
                                    </div>
                                </td>
                                <td className="text-center">{item.unidad}</td>
                                <td className="text-center">{item.caja}</td>
                                <td className="text-center">{item.estatusViaje}</td>
                                <td className="text-center">{item.cartaPorte}</td>
                                <td className="p-text-right"
                                    style={{width: 100}}>{numeral(item.comision).format('$0,0.00')}</td>
                                <td className="text-center" style={{width: 100}}>{item.infoSegmento}</td>
                                <td style={{width: 60}}>
                                    <div className="d-flex justify-content-center">
                                        {item.estatusLiberacionId !== constantesAdministracion.estatusLiberacion.porGenerarRv &&
                                            <button className="btn btn-link" title={'Ver RV'}
                                                    onClick={() => verRecibo(item.id)}>
                                                <i className="pi pi-file text-primary"/>
                                            </button>
                                        }
                                    </div>
                                </td>
                                <td style={{width: 65}}>
                                    <div className="d-flex mb-1">
                                        <div className="d-flex align-items-center">
                                            {arrayDoc.some(id => parseInt(id) !== constantesAdministracion.estatusDocumentos.aproved) ?
                                                <>
                                                    {arrayDoc.some(id => parseInt(id) === constantesAdministracion.estatusDocumentos.awaiting) &&
                                                        <img src="../../../../img/doc_unchecked.png"
                                                             alt="{constantesAdministracion.estatusDocumentos.awaiting}"
                                                             width='15px' style={{marginLeft: '2px'}}/>
                                                    }
                                                    {arrayDoc.some(id => parseInt(id) === constantesAdministracion.estatusDocumentos.rejected) &&
                                                        <img src="../../../../img/doc_rejected.png"
                                                             alt="{constantesAdministracion.estatusDocumentos.rejected}"
                                                             width='15px' style={{marginLeft: '2px'}}/>
                                                    }
                                                    {arrayDoc.some(id => parseInt(id) === constantesAdministracion.estatusDocumentos.retry) &&
                                                        <img src="../../../../img/doc_retry.png"
                                                             alt="{constantesAdministracion.estatusDocumentos.retry}"
                                                             width='15px'/>
                                                    }
                                                    {arrayDoc.some(id => parseInt(id) === constantesAdministracion.estatusDocumentos.pending) &&
                                                        <>
                                                            <div className="position-relative pr-1">
                                                                <img src="../../../../img/doc_pending.png"
                                                                     alt="{constantesAdministracion.estatusDocumentos.pending}"
                                                                     width='15px' style={{marginLeft: '2px'}}/>
                                                                <span
                                                                    className="badge text-white rounded-pill bg-primary position-absolute">
                                                                        {arrayDoc.filter(id => parseInt(id) === constantesAdministracion.estatusDocumentos.pending).length}
                                                                    </span>
                                                            </div>
                                                        </>
                                                    }
                                                </>
                                                :
                                                <img src="../../../../img/doc_checked.png"
                                                     alt="{constantesAdministracion.estatusDocumentos.aproved}"
                                                     width='15px' style={{marginLeft: '2px'}}/>
                                            }

                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <span className="mr-2">{item.estatus}</span>
                                        {item.tieneComentarios &&
                                            <i className="pi pi-info-circle text-primary"
                                               style={{fontSize: "0.95rem"}}/>
                                        }
                                    </div>
                                </td>
                                {(permisorechazarLiberacion) &&
                                <td style={{width: 80}}>
                                <div className="d-flex align-items-center justify-content-center">
                                    {(item.estatusLiberacionId !== constantesAdministracion.estatusLiberacion.liberacionRechazada && item.estatusLiberacionId !== constantesAdministracion.estatusLiberacion.porGenerarRv) &&
                                        <>
                                            <button title="Rechazar" type="button" className="button-icon"
                                                   onClick={() => preguntaRechazar(item.id,item.viajeId)} >
                                                <i className="pi pi-times-circle text-danger"/>
                                            </button>

                                        </>
                                    }
                                </div>
                                </td>
                                 }
                                <td style={{width: 80}}>
                                    <div className="d-flex justify-content-center">
                                        <button type="button" className="btn btn-link" title={'Editar'}
                                                onClick={() => verViaje(item.id)}>
                                            VER
                                        </button>
                                    </div>
                                </td>
                                
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>

            {/*Modal con la vista previa de la carta porte*/}
            <PdfViewer header="RV" urlApi={`administracion/liberacion/${viajeId}/pdfReciboValor`}
                       descargar={descargarPdf} visible={pdfReciboValorVisible}
                       hide={() => setPdfReciboValorVisible(false)}/>

            {/* //  {Modal para rechazar liberación } */}
                <Dialog header={"Rechazar Liberación"} visible={verModal} footer={renderFooterModal}
                    style={{width: '60vw'}} onHide={() => setVerModal(false)}>
                    <FieldTextArea name={"comentarios"} label={"Comentarios"} colMd={12} rows={3}
                                value={comentario} onChange={(value) => setComentario(value)}/>
                </Dialog>        
        </>
  
    )
};

const FiltrosLiberacion = ({filtro, onChange}) => {
    return (
        <>
            <FieldMultiSelect name="clientes" source="clientes" label="Cliente" colLg={2} colMd={3}
                              value={filtro.clientes} onChange={onChange} showFilter valueAsId={true}/>
            <FieldMultiSelect name="tiposCliente" source="tiposCliente" label="Tipo Cliente" colLg={1} colMd={3}
                              value={filtro.clientes} onChange={onChange} showFilter valueAsId={true}/>
            <FieldMultiSelect name="operadores" source="empleados" label="Operador" colLg={2} colMd={3}
                              value={filtro.operadores} onChange={onChange} showFilter valueAsId={true}
                              params={{puestoIds: [constantes.puestos.operador]}}/>

            <FieldCalendarRange name="fechas" label="Fechas" colMd={1} value={filtro.fechas} onChange={onChange}/>

            {/*<div className="form-group col-md-2 col-sm-12">*/}
            {/*    <label>Ruta</label>*/}
            {/*    <div className="col-auto">*/}
            {/*        <FieldAutoComplete name="rutaOrigen" colMd={2} freeText={true} placeholder="Desde"*/}
            {/*            source="municipios" value={filtro.rutaOrigen} onChange={onChange} />*/}
            {/*        <FieldAutoComplete name="rutaDestino" colMd={2} freeText={true} placeholder="Hasta"*/}
            {/*            source="municipios" value={filtro.rutaDestino} onChange={onChange} />*/}
            {/*    </div>*/}
            {/*</div>*/}

            <FieldTextBox name="cartaPorte" label="Carta Porte" colLg={1} colMd={1} fractionDigits={0} keyFilter={'int'}
                          value={filtro.cartaPorte} onChange={onChange}/>
            <FieldTextBox name="idViaje" label="ID Viaje" colLg={1} colMd={1} value={filtro.idViaje} onChange={onChange} />
            <FieldTextBox name="unidad" label="Unidad" colLg={1} colMd={3}
                          value={filtro.unidad} onChange={onChange}/>
            <FieldTextBox name="caja" label="Caja" colLg={1} colMd={3}
                          value={filtro.caja} onChange={onChange}/>
            <FieldMultiSelect name="estatus" source="estatusLiberacionViaje" label="Estatus" colLg={1} colMd={3}
                              value={filtro.estatus} onChange={onChange} valueAsId={true}/>
        </>
    )
};

export default LiberacionViaje;
