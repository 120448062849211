import * as yup from "yup";
import constantes from "../constantes";
import direccionValidacionEsquema from "../direccionValidacionEsquema";

const guardar = yup.object().shape({
    razonSocial: yup.string().nullable().when("tipoPersona", {
        is: constantes.tiposPersona.moral,
        then: yup.string().required(),
    }),
    nombre: yup.string().nullable().when("tipoPersona", {
        is: constantes.tiposPersona.fisica,
        then: yup.string().required(),
    }),
    apellidoPaterno: yup.string().nullable().when("tipoPersona", {
        is: constantes.tiposPersona.fisica,
        then: yup.string().required(),
    }),
    direcciones: yup.array().of(
        yup.object().shape({
            direccionFiscal: direccionValidacionEsquema(false),
            emailFacturacion: yup.string().required(),
            telefono: yup.string().required(),
        })
    ),
    tipoCliente: yup.mixed().required(),
    documentacion: yup.array().of(
        yup.object().shape({
            documentoCategoriaId: yup.mixed().required(),
            //documentoEspecificacionesId: yup.mixed().required(),
            //notas: yup.string().required(),
            anexo: yup.mixed().required(),
        })
    ),
    clientesFacturadores: yup.array().of(
        yup.object().shape({
            hyp: yup.string().required(),
            loc: yup.string().required(),
            planta: yup.string().required(),
            clienteFacturadorId: yup.mixed().required(),
        })
    ),
    clientesSaas: yup.array().of(
        yup.object().shape({
            empresaId: yup.number().required(),
            externalId: yup.string().required(),
            email: yup.string().required(),
        })
    ),
    subClientes: yup.array().of(
        yup.object().shape({
            nombreSubCliente: yup.string().required(),
        })
    ),
    sftpEDI: yup.array().of(
        yup.object().shape({
            empresa: yup.mixed().required(),
            url: yup.string().required(),
            carpetaLectura: yup.string().required(),
            carpetaEscritura: yup.string().required(),
            usuario: yup.string().required(),
            password: yup.string().required()
        })
    )
});

const alta = yup.object().shape({
    rfc: yup.string().required(),
    usoCfdi: yup.mixed().required(),
    razonSocial: yup.string().nullable().when("tipoPersona", {
        is: constantes.tiposPersona.moral,
        then: yup.string().required(),
    }),
    nombre: yup.string().nullable().when("tipoPersona", {
        is: constantes.tiposPersona.fisica,
        then: yup.string().required(),
    }),
    apellidoPaterno: yup.string().nullable().when("tipoPersona", {
        is: constantes.tiposPersona.fisica,
        then: yup.string().required(),
    }),
    datosPago: yup.object({
        plazo: yup.mixed().required(),
        metodoPago: yup.mixed().required(),
        formaPago: yup.mixed().required(),
    }),
    apellidoMaterno: yup
        .string()
        .nullable()
        .when(["tipoPersona"], {
            is: (a) => a === constantes.tiposPersona.fisica,
            then: yup.string().required(),
        }),
    direcciones: yup.array().of(
        yup.object().shape({
            direccionFiscal: direccionValidacionEsquema(false),
            emailFacturacion: yup.string().required().email(),
            telefono: yup.string().required(),
        })
    ),
    tipoCliente: yup.mixed().required(),
    documentacion: yup.array().of(
        yup.object().shape({
            documentoCategoriaId: yup.mixed().required(),
            //documentoEspecificacionesId: yup.mixed().required(),
            //notas: yup.string().required(),
            anexo: yup.mixed().required(),
        })
    ),
    clientesFacturadores: yup.array().of(
        yup.object().shape({
            hyp: yup.string().required(),
            loc: yup.string().required(),
            planta: yup.string().required(),
            clienteFacturadorId: yup.mixed().required(),
        })
    ),
    clientesSaas: yup.array().of(
        yup.object().shape({
            empresaId: yup.number().required(),
            externalId: yup.string().required(),
        })
    ),
    sftpEDI: yup.array().of(
        yup.object().shape({
            empresa: yup.mixed().required(),
            url: yup.string().required(),
            carpetaLectura: yup.string().required(),
            carpetaEscritura: yup.string().required(),
            usuario: yup.string().required(),
            password: yup.string().required(),
            formatoEntrada: yup.number().required(),
            formatoSalida: yup.number().required()
        })
    )
});

const clienteValidacionEsquema = {
    alta,
    guardar,
};

export default clienteValidacionEsquema;
