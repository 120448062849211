/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {Chart} from 'primereact/chart';
import moment from "moment/moment";
import useEstatusDiariosApi from "../../api/reportes/useEstatusDiariosApi";
import {sinOperadorDatosParse} from "./sinOperadorDatosParse";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import UnidadesSinOperadorModal from "./UnidadesSinOperadorModal";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import { useNavigate } from 'react-router-dom'
import WeekPicker from "../../shared/controls/WeekPicker";

const fechaDeInicial = moment().add(-6, "day")._d;

const GraficaEstatusDiarios = () => {

    const api = useEstatusDiariosApi()
    const [fecha, setFecha] = useState(null)
    const [data, setData] = useState(moment().add(-6, "day")._d)
    const [fechaDe, setFechaDe] = useState(fechaDeInicial)
    const history = useNavigate();

    let stackedOptions = {
        maintainAspectRatio: false,
        aspectRatio: .8,
        plugins: {
            tooltip: {
                enabled: false

            },
            legend: {
                labels: {
                    color: '#495057'
                }
            },
            datalabels: {
                color: 'white',
                formatter: function (value) {
                    return value === 0 ? '' : value ;
                },
                font: {
                    size: 14
                },
            }
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    font:{
                        size: function (context) {
                            const hoy = moment().isSame(moment(context.tick.label),'days');
                            return hoy ? 18 : 12
                        },
                        weight:function (context) {
                            const hoy = moment().isSame(moment(context.tick.label),'days');
                            return hoy ? 'bold' : '12'
                        },
                    },
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                stacked: true,
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };

    useEffect(() => {
        filtrar(fechaDeInicial).then();
    }, [])

    const onClickSinOperador = (fecha) => {
        setFecha(fecha)
    }

    const filtrar = async (fechaDe) => {
        const fechaHasta = moment(fechaDe).add(15, 'days')._d;
        const datos = await api.obtenerSinOperadores({fechaDe, fechaHasta});
        setData(sinOperadorDatosParse(fechaDe, fechaHasta, datos, onClickSinOperador));
    };

    const handleChange = async (dias) => {
        const nuevaFecha = moment(fechaDe).add(dias, 'days')._d;
        setFechaDe(nuevaFecha);
        await filtrar(nuevaFecha)

    }

    const onResetDateFrom = async ()=>{
        const nuevaFecha = moment().add(-6, 'days')._d;
        setFechaDe(nuevaFecha);
        await filtrar(nuevaFecha)
    }

    return (
        <>
            <div style={{display: 'flex', width:'100%', justifyContent:'space-between'}}>
                <WeekPicker date={fechaDe} onMoveDays={handleChange}
                            showGoToday={true}
                            onClickToday={onResetDateFrom}
                            weeks={2.5}/>

                <ButtonIcon title={"Regresar"}
                            onClick={() => history(`/reportes/estatus_diario`)}
                            iconName={'BiArrowBack'}/>
            </div>

            <div className="ag-theme-balham grid-principal">
                <Chart height={'80%'}
                       type="bar"
                       data={data}
                       options={stackedOptions}
                       plugins={[ChartDataLabels]}/>
            </div>

            <UnidadesSinOperadorModal
                fecha={fecha}
                onHide={() => setFecha(null)}
            />
        </>
    )
}
export default GraficaEstatusDiarios;