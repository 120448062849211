import { Button } from "primereact/button";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import React, { useReducer } from "react";
import formReducer from "../../../shared/forms/formReducer";
import FormHandler from "../../../shared/forms/formHandler";
import FieldAutoComplete from "../../../shared/controls/fieldAutoComplete";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import useApiPatios from "../../../api/operaciones/useApiPatios";
import constantes from "../../../catalogos/constantes";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import salidasValidacionEsquema from "./salidasValidacionEsquema";
import { useAuth } from "../../../Seguridad/AuthProvider";

const SalidasDetalle = ({ onSave, close }) => {
    const api = useApiPatios();
    const { user: usuario } = useAuth();

    const defaultEntity = () => ({
        entity: { id: 0, conCaja: true, nombreSolicitante: usuario.username, otro: false },
    });

    const [{ entity, errors }, dispatch] = useReducer(formReducer, {}, defaultEntity);

    const actualizar = (value, propertyName) => {
        dispatch({ type: "update", value: value, name: propertyName });

        if (value?.puedeLlevarCaja === false && propertyName === "unidad") {
            actualizar(false, "conCaja");
        }

        //Limpia el sello fiscal si se selecciona esExterno
        if (propertyName === "esExterno" && value) {
            dispatch({ type: "update", value: "", name: "selloFiscal" });
        }
    };

    const actualizarCaja = async (caja, propertyName) => {
        let selloFiscal;
        actualizar(caja, propertyName);

        if (entity.esExterno) {
            selloFiscal = caja != null ? await api.obtenerSelloFiscalExterno(caja) : "";
        } else {
            selloFiscal = caja != null ? await api.obtenerSelloFiscal(caja.id) : "";
        }
        actualizar(selloFiscal, "selloFiscal");
    };

    const formHandler = new FormHandler({
        validationSchema: salidasValidacionEsquema,
        dispatch,
    });

    const guardar = async () => {
        await formHandler.save(entity, true, api.nuevoPaseSalida);

        if (formHandler.valdation.result) {
            onSave();
        }
    };

    return (
        <>
            <div className="form-container full-height">
                <div className="form-row">
                    <h5 className="col-12">Pase de Salida</h5>

                    {entity.operadorExterno ? (
                        <FieldTextBox
                            name="nombreOperador"
                            label="Nombre Operador"
                            maxLength={800}
                            upperCase
                            colMd={6}
                            required
                            value={entity.nombreOperador}
                            onChange={actualizar}
                            errors={errors}
                        />
                    ) : (
                        <FieldDropDown
                            name="operador"
                            source="empleadosPatios"
                            errors={errors}
                            filter={true}
                            params={{ puestoIds: [constantes.puestos.operador] }}
                            required
                            label="Operador"
                            colMd={6}
                            value={entity.operador}
                            onChange={actualizar}
                        />
                    )}

                    <div className="form-group col-md-6">
                        <label />
                        <FieldCheckBox
                            name="operadorExterno"
                            colMd={12}
                            label="Operador Externo"
                            value={entity.operadorExterno}
                            onChange={actualizar}
                        />
                    </div>

                    <div className="form-group col-md-12" style={{ marginTop: "-20px" }}>
                        <label />
                        <FieldCheckBox
                            name="conCaja"
                            isRadioButton={true}
                            yesOption={"Con remolque"}
                            noOption={"Sin remolque"}
                            disabled={entity.unidad?.puedeLlevarCaja === false}
                            value={entity.conCaja}
                            onChange={actualizar}
                        />
                    </div>

                    {entity.esPermisionario ? (
                        <FieldTextBox
                            name="nombreUnidad"
                            label="No. Económico"
                            colMd={6}
                            required
                            value={entity.nombreUnidad}
                            onChange={actualizar}
                            errors={errors}
                        />
                    ) : (
                        <FieldAutoComplete
                            name="unidad"
                            source="unidades"
                            label="No. Económico"
                            required
                            errors={errors}
                            params={{ id: constantes.tiposEquipo.motriz, todasLasEmpresas: true }}
                            colMd={6}
                            value={entity.unidad}
                            onChange={actualizar}
                        />
                    )}

                    <div className="form-group col-md-5">
                        <label />
                        <FieldCheckBox
                            name="esPermisionario"
                            label="Permisionario"
                            value={entity.esPermisionario}
                            onChange={actualizar}
                        />
                    </div>
                    {entity.conCaja && (
                        <>
                            {entity.esExterno ? (
                                <FieldTextBox
                                    name="nombreCaja"
                                    label="No. Remolque"
                                    colMd={6}
                                    required
                                    value={entity.nombreCaja}
                                    onChange={actualizarCaja}
                                    errors={errors}
                                />
                            ) : (
                                <FieldAutoComplete
                                    name="caja"
                                    source="unidades"
                                    label="No. Remolque"
                                    required
                                    errors={errors}
                                    params={{ id: constantes.tiposEquipo.arrastre, todasLasEmpresas: true }}
                                    colMd={6}
                                    value={entity.caja}
                                    onChange={actualizarCaja}
                                />
                            )}

                            <div className="form-group col-md-5">
                                <label />
                                <FieldCheckBox
                                    name="esExterno"
                                    label="Externo"
                                    value={entity.esExterno}
                                    errors={errors}
                                    onChange={actualizar}
                                />
                            </div>

                            <FieldTextBox label="Sello Fiscal" colMd={6} value={entity.selloFiscal} disabled />
                        </>
                    )}
                </div>

                <div className="form-row">
                    {entity.otro ? (
                        <FieldTextBox
                            name="destino"
                            label="Planta Destino"
                            colMd={6}
                            required
                            value={entity.destino}
                            onChange={actualizar}
                            errors={errors}
                        />
                    ) : (
                        <FieldAutoComplete
                            name="planta"
                            source={`direcciones/${constantes.modulos.paradas}`}
                            label="Planta Destino"
                            required
                            colMd={6}
                            value={entity.planta}
                            onChange={actualizar}
                            errors={errors}
                        />
                    )}

                    <div className="form-group col-md-5">
                        <label />
                        <FieldCheckBox name="otro" label="Otro" value={entity.otro} onChange={actualizar} />
                    </div>

                    <FieldAutoComplete
                        name="cliente"
                        source="clientes"
                        label="Cliente"
                        required
                        errors={errors}
                        colMd={6}
                        value={entity.cliente}
                        onChange={actualizar}
                    />

                    <FieldTextBox label="Solicitante" colMd={6} value={entity.nombreSolicitante} disabled />
                </div>
            </div>
            <div className="form-footer">
                <Button label="Cancelar" className="p-button-outlined" type="button" onClick={close} />
                <Button label="Guardar" type="button" onClick={guardar} />
            </div>
        </>
    );
};

export default SalidasDetalle;
