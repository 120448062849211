import React from 'react';

export function getWindowState(key) {
    return JSON.parse(localStorage.getItem(key))
}

export default function useWindowState(key, defaultValue) {
    const [state, setState] = React.useState(() => {
        const persistedState = window[key];
        return persistedState ? JSON.parse(persistedState) : defaultValue;
    });
    React.useEffect(() => {
        window[key] = JSON.stringify(state);
    }, [state, key]);
    return [state, setState];
}
