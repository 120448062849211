import usePersistedState from "../hooks/usePersistedState";

const useSeguridad = () => {
    const [opciones] = usePersistedState("pestañas", []);
    return {
        tieneAcceso: (opcionId) => opciones?.findIndex((e) => e.id === opcionId) >= 0,
        esEditable: (menuiId) => opciones?.findIndex((m) => m.id === menuiId && m.edicion) >= 0,
    };
};

export default useSeguridad;

export const opcionesSeguridad = {
    puedeSolicitar: 403,
    puedeAbonar: 404,
    puedeEliminarSolicitud: 405,
    puedeEditarRendimiento: 997,
    puedeEliminarCarga: 999,
    puedeEliminarRendimiento: 901,
    editarFechaRegistro: 1100,
    cambioEstatusRendimiento: 1101,
    escanear: 1014,
    editarConfiguracionEDI: 1018,
    editarConfiguracionQS3: 1019,
};
