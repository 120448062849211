/* eslint-disable react-hooks/exhaustive-deps */
import useApiCotizaciones from "../../../../api/comercial/cotizaciones";
import {useEffect, useState} from "react";
import {produce} from "immer";
import _ from "lodash";
import {ProgressSpinner} from "primereact/progressspinner";
import * as numeral from "numeral";
import React from "react";
import FieldCheckBox from "../../../../shared/controls/fieldCheckBox";

const Casetas = ({viaje, onSeleccionCaseta, casetasExcluidas}) => {
    const apiCotizaciones = useApiCotizaciones();
    const [loading, setLoading] = useState(false);
    const [casetas, setCasetas] = useState([]);

    async function calcularRuta() {
        if (viaje.viajeValido) {
            setLoading(true);

            const casetas = await apiCotizaciones.detalleCasetas(viaje.ids, casetasExcluidas ?? '');
            setLoading(false);
            setCasetas(casetas)
        }
    }

    function actualizarSeleccionCasetas(seleccionada, index) {
        const actualizada = produce(casetas, draft => {
            draft[index].seleccionada = seleccionada;
        });
        setCasetas(actualizada);
        const costo = _.sumBy(actualizada.filter(e => e.seleccionada === true)
            , (e) => {
                return e.costoCaseta + e.ejeExcedente
            });
        const filasExcluidas = [];
        actualizada.forEach((e, index) =>{ if(!e.seleccionada) filasExcluidas.push(index)});
        const excluidas = filasExcluidas.join('|');

        onSeleccionCaseta(costo, excluidas);
    }

    useEffect(() => {
        calcularRuta();
    }, [viaje]);

    return (
        <>
            <table className="table table-sm  table-borderless">
                <thead className="thead-light">
                <tr>
                    <th scope="col" style={{width: '40%'}}>CASETA</th>
                    <th scope="col" className="right" style={{width: '20%'}}>COSTO</th>
                    <th style={{width: '5%'}}>
                    </th>
                    <th className={'input'} style={{width: '10%'}}>
                    </th>
                </tr>
                </thead>
                <tbody>
                {loading ? <ProgressSpinner style={{height: 40}}/> :
                    casetas.map((caseta, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        {caseta.direccion}
                                    </td>
                                    <td className="right">
                                        {numeral(caseta.costoCaseta).format('$0,0.00')}
                                    </td>
                                    <td></td>
                                    <td className="input d-flex align-items-end">
                                        <FieldCheckBox name="seleccionada" value={caseta.seleccionada}
                                                       onChange={(e) => actualizarSeleccionCasetas(e, index)}/>
                                    </td>
                                </tr>
                            )
                        }
                    )}
                </tbody>
            </table>
        </>
    );
};

export default Casetas;
