import { useState } from "react";
import useApiRecepcionRenovado from "../../../api/taller/useApiRecepcionRenovadoLlanta";
import Field from "../../../shared/forms/Field";
import FormCalendarRange from "../../../shared/forms/FormFields/FormCalendarRange";
import FormMultiSelect from "../../../shared/forms/FormFields/FormMultiSelect";
import FormTextBox from "../../../shared/forms/FormFields/FormTextBox";
import FormFilter from "../../../shared/forms/FormFilter/FormFilter";
import moment from "moment";
import useForm from "../../../shared/forms/useForm";
import Filters from "../../../shared/forms/FormFilter/Filters";
import Buttons from "../../../shared/forms/FormFilter/Buttons";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { VerIconRenderer } from "../../../shared/ag-grid-helper/GridRenderers";
import agGridHelpers from "../../../shared/ag-grid-helper";
import { AgCurrencyFormatter, AgDateFormatter } from "../../../shared/ag-grid-helper/GridFormatters";
import RecepcionRenovadoDetalle from "./RecepcionRenovadoDetalle";

const RecepcionRenovado = ({ soloLectura }) => {
    const filtroInicial = {
        fechas: [moment().startOf('month')._d, moment().startOf('day')._d]
    };
    const api = useApiRecepcionRenovado();
    const [datos, setDatos] = useState([]);
    const [recepcionRenovadoId, setRecepcionRenovadoId] = useState(null);
    const { values: filter, onChange: onFilterChange, onClear } = useForm(filtroInicial);

    const filtrar = async (filtro) => {
        const recepcionRenovado = await api.filtrar(filtro);
        setDatos(recepcionRenovado);
    };

    return (
        <>
            <FormFilter columns={5} gridStyle={{ paddingTop: '0', marginTop: '0' }}
                filter={filter}
                onChange={onFilterChange}
                onClear={onClear}
                onFilter={(filtro) => filtrar(filtro)}>
                <Filters>
                    <Field field={"idCautin"} label={"Id (Cautín)"} render={FormTextBox} />
                    <Field field={"conceptoIds"} label={"Concepto"} source="renovadoLlanta" render={FormMultiSelect} />
                    <Field field={"proveedorIds"} label={"Proveedor"} source="proveedorRenovadoLlanta" render={FormMultiSelect} />
                    <Field field={"estatusIds"} label={"Estatus"} source="estatusRenovadoLlanta" render={FormMultiSelect} />
                    <Field field={"fechas"} label={"Fechas"} render={FormCalendarRange} />
                </Filters>
                <Buttons filter={true} clear={true} style={{ top: '-50px' }} />
            </FormFilter>
            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    rowSelection={true}
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    components={{
                        verIconRenderer: VerIconRenderer
                    }}
                    onGridReady={() => filtrar(filter).then()}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelpers.loadingOverlay}
                    defaultColDef={agGridHelpers.defaultColumns}
                    context={{ onEdit: (id) => setRecepcionRenovadoId(id) }}>
                    <AgGridColumn field="id" headerName="ID Renovado" cellClass={"center"} flex={2} />
                    <AgGridColumn field="idCautin" headerName="Id (Caoutín)" cellClass={"center"} minWidth={100} />
                    <AgGridColumn field="concepto" headerName="Concepto" minWidth={100} />
                    <AgGridColumn field="proveedor" headerName="Proveedor" minWidth={100} />
                    <AgGridColumn field="estatus" headerName="Estatus" minWidth={100} />
                    <AgGridColumn field="costo" headerName="Costo" minWidth={100} cellClass={'right'} valueFormatter={AgCurrencyFormatter} />
                    <AgGridColumn field="folio" headerName="Folio" minWidth={100} />
                    <AgGridColumn field="fechaEnvio" headerName="Fecha de Envio" minWidth={100} valueFormatter={AgDateFormatter} />
                    <AgGridColumn field="fechaRecepcion" headerName="Fecha de Recepcion" minWidth={100} valueFormatter={AgDateFormatter} />
                    <AgGridColumn maxWidth={80} headerName="VER" pinned={'right'} cellClass={'left'} cellRenderer="verIconRenderer" />
                </AgGridReact>
            </div>
            <RecepcionRenovadoDetalle recepcionRenovadoId={recepcionRenovadoId} soloLectura={soloLectura}
                onSaved={() => {
                    filtrar(filter).then();
                    setRecepcionRenovadoId(null);
                }}
                onCancel={() => setRecepcionRenovadoId(null)} />
        </>
    )
};

export default RecepcionRenovado;