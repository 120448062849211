import React, {useEffect, useReducer} from "react";
import useApiViaje from "../../../api/operaciones/useApiViajes";
import {Dialog} from "primereact/dialog";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import constantes from "../../../catalogos/constantes";
import FieldTextArea from "../../../shared/controls/fieldTextArea";
import formReducer from "../../../shared/forms/formReducer";
import {Button} from "primereact/button";
import {cancelarCPOtroValidacion, cancelarCPValidacion} from "./viajeValidacionEsquema";
import YupValidator from "../../../shared/YupValidator";

const CancelarCartaPorte = ({viajeId, visible, onHide, onSave}) => {
    const api = useApiViaje();
    const defaultEntity = () => (
        {entity: {}}
    );
    const [{entity, errors}, dispatch] = useReducer(formReducer, {}, defaultEntity);
    const validator = new YupValidator(cancelarCPValidacion);
    const validatorOtro = new YupValidator(cancelarCPOtroValidacion);
    const actualizar = (value, propertyName) => {
        dispatch({type: 'update', value: value, name: propertyName});
    };

    useEffect(()=> {
        dispatch({type: 'update', value: {...defaultEntity}});
    }, [visible])

    const cancelar = async () => {
        let esValido = false;
        if(entity.motivoCancelar?.id === constantes.motivoCancelacion.Otro){
            esValido = await validatorOtro.validate(entity);
        }
        else{
            esValido = await validator.validate(entity);
        }

        if(!esValido)
            return;

        await api.cancelarTimbrado(viajeId, entity);
        onSave();
    };

    const renderFooterModal = () => {
        return (
            <div>
                <Button label="Cerrar" onClick={onHide} className="p-button-outlined"/>
                <Button label="Confirmar" onClick={cancelar} autoFocus/>
            </div>
        );
    };

    return (
        <Dialog visible={visible} header={"Cancelar Carta Porte"} onHide={onHide} style={{width: '35vw'}}
                footer={renderFooterModal}>
            <div className={"form-row"}>
                <FieldDropDown name="motivoCancelar" source="motivoCancelarCP" value={entity.motivoCancelar}
                               label="Motivo Cancelación" colMd={12} error={errors?.motivoCancelar}
                               onChange={actualizar}/>
                {entity.motivoCancelar?.id === constantes.motivoCancelacion.Otro &&
                    <FieldTextArea name="comentario" label="Comentario" value={entity.comentario} colMd={12} rows={3}
                                   error={errors?.comentario} onChange={actualizar}/>
                }
            </div>
        </Dialog>
    );
};

export default CancelarCartaPorte;
