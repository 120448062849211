import qs from 'qs'

export function getMsLoginUrl () {
    const endpoint = `https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize/`

    const params = {
        client_id: process.env.REACT_APP_CLIENT_ID,
        response_type: 'code',
        redirect_uri: `${window.location.origin}/auth/callback`,
        response_mode: 'query',
        scope: `email profile openid ${process.env.REACT_APP_REQUIRED_SCOPE ?? ''}`,
        prompt: process.env.REACT_APP_LOGIN_PROMPT
    }

    const queryString = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&')

    return `${endpoint}?${queryString}`
}

export function getOauthToken () {
    const search = window.location.search
    const query = qs.parse(search.substring(1))

    if (query.error === 'access_denied') {
        throw query.error
    }

    if (query.error_description) {
        throw query.error_description
    }

    return query.code
}
