import React from "react";
import FieldCamera from "../../../shared/controls/fieldCamera";
import {Dialog} from "primereact/dialog";
import {isTablet} from 'react-device-detect';
import Compress from "compress.js";

const VisorFotografia = ({checklist, index, visibleModal, onChange, setVisibleModal, nameSeccion, esFotoAcual}) => {
    //const Compress = require('compress.js');
    const comprimir = new Compress();
    async function actualizar(itemValue, itemName) {
        var file = itemValue;

        if (file !== null)
            file = await resizeImageFn(file);

        onChange(checklist, index, file, itemName, nameSeccion);

        close();
    }

    async function resizeImageFn(file) {
        var imageData = file.file.toString();
        var byteCharacters = atob(imageData.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], {
            type: 'image/png'
        });

        const resizedImage = await comprimir.compress([blob], {
            size: 2, // the max size in MB, defaults to 2MB
            quality: 0.6, // the quality of the image, max is 1,
            maxWidth: 500, // the max width of the output image, defaults to 1920px
            maxHeight: 300, // the max height of the output image, defaults to 1920px
            resize: true // defaults to true, set false if you do not want to resize the image width and height
        })
        const img = resizedImage[0];

        var data = "data:image/png;base64," + img.data.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');

        const newFile = {
            url: data,
            isEmpty: false,
            name: 'foto.jpg',
            isBase64: true
        };

        return newFile;
    }

    async function close() {
        setVisibleModal(false);
    }

    return (
        <>
            <Dialog
                header="FOTO"
                visible={visibleModal}
                style={{width: !isTablet ? "30vw" : "40vw"}}
                onHide={() => close()}
            >
                {esFotoAcual &&
                <div className="form-row">
                    <FieldCamera value={checklist[index]?.fotoActual} name="fotoActual" useCamera={true}
                                 onChange={actualizar}/>
                </div>
                }

                {!esFotoAcual && checklist[index]?.fotoAnterior !== null &&
                <div className="form-row">
                    <FieldCamera value={checklist[index]?.fotoAnterior} name="fotoAnterior" disabled={true}/>
                </div>
                }
            </Dialog>
        </>
    )
};
export default VisorFotografia;
