const constantes = {
    usuarioStorage: "usuarioXimple",
    opciones: {
        //verSueldoBase : 801,
        LiberarViaje: 803,
        SueldoBaseLiquidacion: 806,
        SueldoBaseStaff: 807,
        SueldoBaseOficina: 808,
        EditarAsignacionRecursos: 809,
        EditarComisiones: 810,
        DesligarFactura: 811,
        EditarAjusteDiesel: 812,
        ReenviarFactura: 813,
        LimpiarCapturaEdi: 814,
        HistorialEmpleados: 818,
        EmpleadosQR: 820,
        SuperUserRegresarAProgramado: 1000,
        SuperUserGuardado: 1001,
        BusquedaMovsOficina: 1003,
        BusquedaMovsStaff: 1004,
        BusquedaMovsOperativos: 1002,
        PermisoInfonavit: 1005,
        PermisoPrestamoCajaAhorro: 1006,
        PermisoCajaAhorro: 1007,
        permisoHistorial: 1008,
        permisosActasAdm: 1009,
        permisoGuardarAgenda: 1010,
        permisoCambioTarifa: 1011,
        SuperUserRegresarAPorProgramar: 1012,
        LiquidacionEmpleadosBaja: 1016,
        permisoUpdateInventario: 1017,
        editarFechaRegistro: 1100,
        rechazarLiberacion:1021,
        permisoGastoUrgente:1024,
        permisoGastoOperaciones:1025,
        permisoGastoMantenimiento:1026,
        permisoGastoSeguridad:1027,
        permisoGastoUrgenteStp:1028
    },
    tiposPersona: {
        moral: 31,
        fisica: 32,
    },
    tiposCliente: {
        nacional: 11,
        extrangero: 12,
    },
    paises: {
        mexico: 1,
        eua: 2
    },
    modulos: {
        paradas: 5,
        clientes: 6,
        administracionViajes: 7,
        seguimiento: 9,
        monitoreo: 10,
        direcciones: 11,
        prefacturacion: 12,
        freightVerify: 13,
        viajes: 15,
        unidades: 16,
    },
    monedas: {
        pesos: 1,
        dolares: 2,
    },
    estatus: {
        empleados: {
            enProceso: 41,
            alta: 42,
            baja: 43,
        },
        unidades: {
            enProceso: 51,
            alta: 52,
            baja: 53,
        },
        clientes: {
            enProceso: 111,
            vigente: 112,
            suspendido: 113,
        },
        monitoreo: {
            ConViaje: 174,
            SinViaje: 175,
        },
        EstatusDiarios: {
            SinEstatus: 200,
            Operando: 201,
            ConProblemas: 202,
            EnManteimiento: 203,
            Disponible: 204,
        },
        viaje: {
            Cancelado: 106
        }
    },
    resultado: {
        positivo: 131,
        negativo: 132,
    },
    tiposDocumento: {
        licencia: 2,
        permisoDeCruce: 114,
    },
    puestos: {
        mecanicoA: 15,
        mecanicoB: 15,
        operador: 33,
        guardiaSeguridad: 31,
        coordinadorTrafico: 66,
        auxiliarTrafico: 64,
        gestorOperadores: 54
    },
    puestosDeTrafico: [66, 64], //coordinador y auxiliar de tráfio
    tiposEmpleado: {
        utilitario: 10,
    },
    tiposEquipo: {
        arrastre: 1,
        motriz: 2,
    },
    nacionalCruce: {
        cruce: 232,
        nacional: 231,
    },
    ubicaciones: {
        ladoIzquierdo: {id: 321, nombre: "Lado Izquierdo"},
        ladoDerecho: {id: 322, nombre: "Lado Derecho"},
        frente: {id: 323, nombre: "Frente"},
        atras: {id: 324, nombre: "Atras"},
        techo: {id: 325, nombre: "Techo"},
        piso: {id: 326, nombre: "Piso"},
        patines: {id: 327, nombre: "Patines"},
    },
    tarjetaCruce: {
        tagAnzalduas: 5,
        tagPharr: 6,
    },
    tiposUnidad: {
        Utilitario: 6,
    },
    tipoRuta: {
        origen: 0,
        destino: 100,
        retorno: 101,
    },
    resultadoInspeccion: {
        noNecesitaServicio: 0,
        necesitaServicio: 1,
        na: 2,
    },
    estatusMantenimiento: {
        ok: 1,
        proximo: 2,
        vencido: 3,
    },
    estatusPrefacturacion: {
        facturado: 131,
        enviadoAFacturacion: 132,
        porfacturar: 133,
    },
    puntosInscepccion: {
        extintor: 167,
    },
    moneda: {
        peso: 1,
        dolar: 2,
    },
    esquemaPago: {
        liquidacion: 151,
        staff: 152,
        oficina: 153,
    },
    tipoSueldoInstructores: {
        SueldoTractorInstructorABC: 16,
        SueldoTractorInstructorTBX: 26,
        SueldoTractorInstructorREX: 47,
    },
    tipoCargaDiesel: {
        diesel: 619,
        urea: 620,
    },
    proveedorCargaDiesel: {
        ABCAutolineas: 612,
    },
    menus: {
        solicitudServicio: 604,
        ordenesTrabajo: 605,
        llantas: 606,
        almacen: 608,
        posicionesLlantas: 607,
        mantenimiento: 201,
        llanta: 202,
        comercial: 101,
        cotizaciones: 601,
        convenios: 602,
        expeditados: 603,
        controlPatios: 104,
        accesosSalidas: 109,
        ipad: 110,
        checkUnidades: 111,
        seguimientomonitoreo: 106,
        seguimiento: 112,
        monitoreo: 113,
        liquidacion: 704,
        nominaOperativa: 706,
        nominaOficina: 707,
        nominaAdministrativa: 708,
        dispersionOperativa: 709,
        dispersionOficina: 710,
        dispersionAdministrativa: 711,
        movimientos: 702,
        movimientosSeccion: 712,
        movimientosPorAutorizar: 713,
        vacaciones: 703,
        vacacionesSeccion: 714,
        vacacionesporAutorizar: 715,
        reporteliquidacion: 716,
        liquidacionOperativa: 717,
        liquidacionOficina: 718,
        liquidacionAdministrativa: 719,
        tipoDeCambio: 720,
        diesel: 992,
        cargasDiesel: 993,
        rendimientosDiesel: 994,
        tanqueDiesel: 995,
        tanqueUrea: 996,
        revisionSemaforeo: 609,
        reparacionCompra: 610,
        recepcionRenovado: 611,
        administracionPersonal: 102,
        gestionFlotillas: 103,
        programacionViajes: 105,
        orderToCash: 115,
        ajusteDiesel: 705,
        liberacion: 701,
        cajaChica: 402,
        inventariosCaja:203,
        clientes: 504,
        conceptosCobro: 506,
        direcciones: 505,
        parametros: 503,
        configuracionBono: 511,
        usuarios: 502,
        proveedoresDiesel: 508,
        geocercas: 509,
        descuentos: 512,
        marcaLlanta: 510,
        perfiles: 501,
        equipos: 517,
        marcasEquipo: 518,
        modelosEquipo: 519,
        configuracionLear: 117,
        incidencias: 721,
        estaciones: 522,
        estatusDiarios: 401,
        puestos: 516,
        modificarCostoRutas: 1015,
        modificarComentariosCotizacion: 1021
    },
    addendas: {
        soriana: 1,
        mabe: 2,
    },
    tipoMenus: {
        pagina: 2,
        opcion: 3,
    },
    estatusProximoMantenimiento: {
        Ok: 81,
        Proximo: 82,
        Vencido: 83,
        SinMtto: 84,
    },
    tipoDocumento: {
        antecedentes: 10,
    },
    estatusIncidencias: {
        Creada: 211,
        EnProceso: 212,
        Cerrada: 213,
        Cancelada: 214,
    },
    nivelAlerta: {
        enCurso: 1,
        programado: 2,
        alertaPendiente: 3,
    },
    tiposNotificacion: {
        proximoinicioViaje: 1,
        inicioViaje: 2,
        horasDescansoBajo: 3,
        horasDescansoMedio: 4,
        horasDescansoAlto: 5,
        excesoVelocidad: 6,
        finalizarViaje: 7,
        SeparacionUnidadCaja: 8,
        ReprogramarViaje: 9,
        PerdidaGps: 10,
        ActualizacionRecursos: 11,
        DesconexionGps: 12,
        BotonPanico: 13,
        actualizacionDocumentosViaje: 14,
    },
    incidenciaViaje: {
        fallaenGPS: 438,
        excesoVelocidad: 1700,
        riesgoHorasConduccion: 1701,
    },
    modulosAlertas: {
        viaje: 15,
        liberacion: 17,
    },
    estatusNotificaciones: {
        porLeer: 221,
        leida: 222,
        borrada: 223,
    },
    estatusDocumentos: {
        ENESPERA: 403,
        PENDIENTE: 404,
        APROBADO: 405,
        RECHAZADO: 406,
        REINTENTAR: 407,
    },
    extensionImagenes: ["jpg", "jpeg", "png", "gif"],
    empresa: {
        ABC: 1,
        TBX: 2,
        REX: 3,
        SCF: 4,
    },
    motivoCancelacion: {
        Otro: 1806,
    },
    departamento: {
        Operaciones: 2,
        Trafico: 3,
    },
    motivoEstatusDiarios: {
        sinOperador: 1,
        disponibleSinOperador: 12,
        disponibleConOperador: 13,
    },
    motivoDescuentos: {
        ajusteParaLiquidar: 1850,
    },
    cliente: {
        Penske:47  
    },
    cargadaVaciaOptions : [{id: 'cargada', nombre: 'Cargada'}, {id: 'vacia', nombre: 'Vacia'}],
    tiposDepositoStp:{
        CuentaXimple:3,
        Provisional:1,
        Externo:2
    },
    concepto:{
        anticipoliquidaciones:12
    }
};
export default constantes;
