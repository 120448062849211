import {OverlayPanel} from "primereact/overlaypanel";
import React from "react";

const InfoViaje = ({refViaje, viaje}) => {
    return (<OverlayPanel
        ref={refViaje}
        id="overlay_panel"
        style={{width: "440px"}}
        className="overlaypanel-custom"
    >
        <div className={'estacion-info-viaje'}>
            <span className={'text-label'}>
                Unidad
            </span>
            <span className={'text-value'}>
                {viaje.unidad}
            </span>
            <span className={'text-label'}>
                Caja
            </span>
            <span className={'text-value'}>
                {viaje.caja}
            </span>
            <span className={'text-label'}>
                Carta Porte
            </span>
            <span className={'text-value'}>
                {viaje.cartaPorte}
            </span>
            <span className={'text-label'}>
                Operador
            </span>
            <span className={'text-value'}>
                {viaje.operador}
            </span>
            <span className={'text-label'}>
                Cliente
            </span>
            <span className={'text-value'}>
                {viaje.cliente}
            </span>
            {viaje.subCliente != null &&
                <>
                <span className={'text-label'}>
                    Sub Cliente
                </span>

                    <span className={'text-value'}>
            {viaje.subCliente}
            </span>
                </>}
            <span className={'text-label'}>
                Ruta
            </span>
            <span className={'text-value'}>
                {viaje.ruta}
            </span>
        </div>
    </OverlayPanel>)
}
export default InfoViaje;