/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import * as numeral from "numeral";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import moment from "moment";
import produce from "immer";

const Impuestos = ({impuestos, semanaImpuestos,onChange, soloConsulta}) => {

    const actualizar = (value, propertyName, index) => {
        const newValue = produce(semanaImpuestos, (draft) => {
            draft[index][propertyName] = value;
        });

        onChange(newValue,'semanaImpuestos');
    };

    return (
        <>
            {impuestos && impuestos.length > 0 &&
            <div className="form-row">
                <table className="table table-borderless">
                    <thead className="thead-light">
                    <tr>
                        <th style={{width: '70%'}}/>
                        <th className="right" style={{width: '30%'}}>MONTO</th>
                    </tr>
                    </thead>
                    <tbody>
                    {impuestos?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.impuesto}</td>
                                <td className="right">{numeral(item.monto).format('$0,0.00')}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            }
            {semanaImpuestos && semanaImpuestos.length > 0 &&
            <div className="form-row">
                <table className="table table-borderless">
                    <thead className="thead-light">
                    <tr>
                        {!soloConsulta && <th/>}
                        <th style={{width: '20%'}}>INICIO</th>
                        <th style={{width: '20%'}}>FIN</th>
                        <th style={{width: '12%'}}>IMMS</th>
                        <th style={{width: '12%'}}>ISPT</th>
                        <th style={{width: '12%'}}>INFONAVIT</th>
                        <th style={{width: '12%'}}>FONACOT</th>
                        <th style={{width: '12%'}}>TOTAL</th>
                    </tr>
                    </thead>
                    <tbody>
                    {semanaImpuestos?.map((item, index) => {
                        return (
                            <tr key={item.viajeId}>
                                {!soloConsulta &&
                                <td>
                                    <FieldCheckBox name="seleccionado" value={item.seleccionado}
                                                   onChange={(value, pname) => actualizar(value, pname, index)}
                                    />
                                </td>                            
                                }
                                <td>{moment(item.inicio).format('DD/MM/YYYY hh:mm a')}</td>
                                <td>{moment(item.termino).format('DD/MM/YYYY hh:mm a')}</td>
                                <td>{numeral(item.montoIMSS).format('$0,0.00')}</td>
                                <td>{numeral(item.montoISPT).format('$0,0.00')}</td>
                                <td>{numeral(item.montoInfonavit).format('$0,0.00')}</td>
                                <td>{numeral(item.montoFonacot).format('$0,0.00')}</td>
                                <td>{numeral(item.totalSemana).format('$0,0.00')}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            }
        </>
    )
};


export default Impuestos;
