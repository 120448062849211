import React from "react";
import FieldTextBox from "../../shared/controls/fieldTextBox";

const FiltroPerfil = ({filtro, onChange}) => {
    return (
        <FieldTextBox name="nombre" label="Perfil" colMd={2} value={filtro.nombre} onChange={onChange}/>
    )
};

export default FiltroPerfil;
