import React from "react";
import {RadioButton} from "primereact/radiobutton";
import produce from "immer";
import constantes from "../../catalogos/constantes";

const CheckList = ({value, titulo, onChange, name, errors, disabled}) => {
    errors = errors ?? {};
    function actualizar( index, itemValue ) {
        const newCheckList = produce(value, (draft) => {
            draft[index].necesitaServicio = itemValue;
        });

        onChange(newCheckList,name);
    }

    return (
        <table className="table">
            <thead className="thead-light">
            <tr>
                <th scope="col" style={{width: '30%'}}>{titulo}</th>
                <th scope="col" className="center" style={{width: '20%'}}>OK</th>
                <th scope="col" className="center" style={{width: '20%'}}>NECESITA SERVICIO</th>
                <th scope="col" className="center" style={{width: '20%'}}>N/A</th>
            </tr>
            </thead>
            <tbody>
            {value?.map((item, index) => {
                return (
                    <tr key={index} className={errors[`${name}[${index}]`] ? 'checklist-error' : ''}>
                        <td className="input">
                            {item.puntoInspeccion.nombre}
                        </td>
                        <td className="input center option ok">
                            <RadioButton
                                name={`${name}_checkist_${index}`}  value={true}  id={`${name}_${index}_ok`}
                                inputId={`input_${name}_${index}_ok`}
                                onChange={() => actualizar(index, constantes.resultadoInspeccion.noNecesitaServicio)}
                                checked={ item.necesitaServicio === constantes.resultadoInspeccion.noNecesitaServicio  }
                                disabled={disabled}
                            />
                        </td>
                        <td className="input center option no-ok">
                            <RadioButton
                                name={`${name}_checkist_${index}`} value={true}  id={`${name}_${index}_necesitaServicio`}
                                inputId={`input_${name}_${index}_necesitaServicio`}
                                onChange={() => actualizar(index, constantes.resultadoInspeccion.necesitaServicio)}
                                checked={ item.necesitaServicio === constantes.resultadoInspeccion.necesitaServicio }
                                disabled={disabled}
                            />
                        </td>
                        <td className="input center option no-ok">
                            <RadioButton
                                name={`${name}_checkist_${index}`} value={true}  id={`${name}_${index}_necesitaServicio`}
                                inputId={`input_${name}_${index}_na`}
                                onChange={() => actualizar(index, constantes.resultadoInspeccion.na)}
                                checked={ item.necesitaServicio === constantes.resultadoInspeccion.na }
                                disabled={disabled}
                            />
                        </td>
                    </tr>
                )
            })}
            </tbody>
        </table>
    );
};

export default CheckList;
