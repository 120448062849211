
export const pendientes = {
    impresionCartaPorte : 1,
    timbradoCartaPorte : 2,
    inicioViaje : 3,
    finViaje : 4,
    recepcionDocumentos : 5,
    liberacionDocumentos : 6,
    recepcionAdministracion  : 7,
    facturacionManual : 8,
    envioCliente : 9,
    pago : 10,
    finalizado : 11
}


export const pendientesPorSource =[
    {id:pendientes.timbradoCartaPorte, nombre:'Timbrado carta porte', mostrarEnFiltro : true},
    {id:pendientes.finViaje, nombre:'Fin Viaje', mostrarEnFiltro : true},
    {id:pendientes.recepcionDocumentos, nombre:'Recepción documentos', mostrarEnFiltro : true},
    {id:pendientes.liberacionDocumentos, nombre:'Liberación Documentos', mostrarEnFiltro : true},
    {id:pendientes.recepcionAdministracion, nombre:'Recepción Administración', mostrarEnFiltro : true},
    {id:pendientes.facturacionManual, nombre:'Facturación Manual', mostrarEnFiltro : true},
    {id:pendientes.envioCliente, nombre:'Envío Cliente', mostrarEnFiltro : true},
    {id:pendientes.pago, nombre:'Pago', mostrarEnFiltro : true},
];
