import * as yup from "yup";

export const agregarDescuento = yup.object().shape({
    operador: yup.mixed().required(),
    descuento: yup.mixed().required(),
    // exhibiciones: yup.number().required(),
    // fechaInicio: yup.date().required(),
    // monto: yup.number().required(),
    comentario: yup.string().required()
});

export const agregarGasto = yup.object().shape({
    operador: yup.mixed().required(),
    tipoGasto: yup.mixed().required(),
    conceptoCajaChica: yup.mixed().required(),
    //monto: yup.number().required(),
    comentario: yup.string().required()
});