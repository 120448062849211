import React, { useState} from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useReducer } from 'react';
import useApiViaje from '../../../api/operaciones/useApiViajes';
import FieldDropDown from '../../../shared/controls/fieldDropDown';
import FieldInputNumber from '../../../shared/controls/fieldInputNumber';
import FieldTextArea from "../../../shared/controls/fieldTextArea";
import formReducer from '../../../shared/forms/formReducer';
import FormHandler from "../../../shared/forms/formHandler";
import { agregarAnticipo } from "./viajeValidacionEsquema";
import FieldCheckBox from '../../../shared/controls/fieldCheckBox';
import FieldRadioButtonList from "../../../shared/controls/fieldRadioButtonList";
import alerts from "../../../shared/alerts";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import swal from "sweetalert2";
import useSeguridad from "../../../Seguridad/useSeguridad";
import constantes from "../../../catalogos/constantes";

const AgregarAnticipo = ({ viajeId, onSave, visible, hide , categoriaTipo}) => {
    const api = useApiViaje();
    const [cuentasOperador, setcuentasOperador] = useState([]);
    const seguridad = useSeguridad();
    const externals = [  
        { id: 3, nombre: "Cuenta Ximple" },
        { id: 1, nombre: "Provisional" },
        { id: 2, nombre: "Externo" },
    ];
    const defaultEntity = () => ({ entity: { 
        operador: null,
        concepto: null,
        monto: null,
        comentario: null,
        urgente: false,
        transferenciaStp: false,
        extProv: constantes.tiposDepositoStp.CuentaXimple, 
        cuentas: null  } });

    const [{ entity, errors }, dispatch] = useReducer(formReducer, {}, defaultEntity);
    const formHandler = new FormHandler({
        validationSchema: agregarAnticipo,
        api,
        dispatch
    });
    let permisoGastoUrgente = seguridad.tieneAcceso(constantes.opciones.permisoGastoUrgente); 
    let permisoTransferenciaStp = seguridad.tieneAcceso(constantes.opciones.permisoGastoUrgenteStp); 
    const consultarCuentas = async (id) => {
        if (!id) return;
        const result = await api.cuentasBancarias(id);
        setcuentasOperador(result)
    };
    
    const consultarReglaAnticipo = async (viajeId, conceptoId, operadorId) => {
        if (!viajeId) return;
        const nuevo = await api.reglaAnticipo(operadorId, { conceptoCajaChicaId:conceptoId});
        dispatch({ type: 'update', value: nuevo, name: "monto" });
    };
    
    const actualizar = async (value,propertyName) => {  
        if (propertyName ==="operador" && (entity.extProv === constantes.tiposDepositoStp.CuentaXimple) ){
            consultarCuentas(value.id);     
                    
        }
        
        if(propertyName==="conceptoCajaChica" && (entity.extProv !== constantes.tiposDepositoStp.Externo) && value.id  === constantes.concepto.anticipoliquidaciones){
            consultarReglaAnticipo(viajeId, value.id  ,entity.operador.id);        
        }
        if (propertyName === "extProv") {
            
            if (propertyName === "extProv") {
                dispatch({
                    type: 'update',
                    value: null,
                    name: 'operador' 
                });
                
                dispatch({
                    type: 'update',
                    value: null,
                    name: 'cuentas'
                });
        
                dispatch({
                    type: 'update',
                    value: null,
                    name: 'nombreexterno'
                });
        
                dispatch({
                    type: 'update',
                    value: null,
                    name: 'banco'
                });
        
                dispatch({
                    type: 'update',
                    value: null,
                    name: 'clabeAnticipo'
                });
        
                dispatch({
                    type: 'update',
                    value: null,
                    name: 'tipoGasto'
                });
        
                dispatch({
                    type: 'update',
                    value: null,
                    name: 'conceptoCajaChica' 
                });
        
                dispatch({
                    type: 'update',
                    value: null,
                    name: 'monto' 
                });
        
                dispatch({
                    type: 'update',
                    value: null,
                    name: 'comentario' 
                });
            } else {
 
                dispatch({ type: 'update', value, name: propertyName });
            }


        } else {
            dispatch({ type: 'update', value, name: propertyName });
        }
      
        dispatch({ type: 'update', value: value, name: propertyName });

    };  

    const validarClabe = async (value) => {
           //tarjeta
           if (value.length ===16) {
            if (/^\d+$/.test(value)) {
                if (value.length >= 13 && value.length <= 19) {
                    console.log("Longitud correcta de la tarjeta:", value.length);
                    if (isValidCardNumber(value)) {
                        return { isValid: true };
                    } else {
                        await swal.fire({
                            titleText: 'Atención.',
                            text: 'Número de tarjeta no válido.',
                            icon: 'warning',
                          });
                    }
                } else {
                    await swal.fire({
                        titleText: 'Atención.',
                        text: 'La longitud del número es incorrecta.',
                        icon: 'warning',
                      });                  
                }
            } else {
                await swal.fire({
                    titleText: 'Atención.',
                    text: 'El número debe contener solo dígitos.',
                    icon: 'warning',
                  });
            }
           }
           if (value.length ===18) {
            if (/^\d+$/.test(value)) {
                if (value.length >= 1) {               
                 if (isValidClabe(value)) {
                     return { isValid: true }; 
                 } else {
                    await swal.fire({
                        titleText: 'Atención.',
                        text: 'CLABE no válida.',
                        icon: 'warning',
                      });   
                 }
               }
               else if (value.length >= 1) {
                await swal.fire({
                    titleText: 'Atención.',
                    text: 'La longitud del número es incorrecta.',
                    icon: 'warning',
                  });

               }
           }
        }
    }   
    function isValidCardNumber(cardNumber) {
        let sum = 0;
        let alternate = false;
     
        for (let i = cardNumber.length - 1; i >= 0; i--) {
            let n = parseInt(cardNumber[i], 10);
            if (alternate) {
                n *= 2;
                if (n > 9) n -= 9;
            }
            sum += n;
            alternate = !alternate;
        }
     
        return sum % 10 === 0;
    }
     
    function isValidClabe(clabe) {
        if (clabe.length !== 18) return false;
     
        const factors = [3, 7, 1];
        let sum = 0;
     
        for (let i = 0; i < 17; i++) {
            const n = parseInt(clabe[i], 10);
            sum += n * factors[i % 3];
        }
     
        let checkDigit = 10 - (sum % 10);
        if (checkDigit === 10) checkDigit = 0;
     
        return checkDigit === parseInt(clabe[17], 10);
    }
     
    const guardar = async () => {
        const seguir = await formHandler.validate(entity);
        if(entity.transferenciaStp){
            if (seguir.result) {
                if ((await alerts.preguntarSiNo("¿Está seguro de realizar la transferencia?")) === true) {
                    if (seguir.result) {
                        console.log(seguir)
                        let clabeValidar = entity.clabeAnticipo ==null ? entity.cuentas.id :entity.clabeAnticipo;
                        const valida = await validarClabe(clabeValidar);
                        if (valida.isValid) {
                            const nuevo = await api.agregarAnticipo(viajeId, entity);
                                if (!nuevo.success){ //enviar a caja chica normal 
                                if ((await alerts.preguntarSiNo(`${nuevo.message} Desea enviar la solicitud a Caja Chica Operativa?`)) === true) {
                                    const updatedEntity = {...entity};                                
                                     updatedEntity.transferenciaStp = false;
                                    const nuevoCajachica = await api.agregarAnticipo(viajeId, updatedEntity);
                                    nuevoCajachica.message.categoriaActual = categoriaTipo;
                                    onSave(nuevoCajachica.message);
                                    dispatch({
                                        type: "update",
                                        value: { 
                                            ...defaultEntity(), 
                                            cuentas: null,
                                            extProv:constantes.tiposDepositoStp.CuentaXimple,
                                            transferenciaStp:false
                                        }
                                    });    
                                }else{
                                    nuevo.message.categoriaActual = categoriaTipo;
                                    onSave( nuevo.message);
                                    dispatch({
                                        type: "update",
                                        value: { 
                                            ...defaultEntity(), 
                                            cuentas: null,
                                            extProv:constantes.tiposDepositoStp.CuentaXimple,
                                            transferenciaStp:false
                                        }
                                    });    
                                     
                                }
                            }else{
                                nuevo.message.categoriaActual = categoriaTipo;
                                onSave( nuevo.message);
                                dispatch({
                                    type: "update",
                                    value: { 
                                        ...defaultEntity(), 
                                        cuentas: null,
                                        extProv:constantes.tiposDepositoStp.CuentaXimple,
                                        transferenciaStp:false
                                    }
                                });    
                                
                            }
                                                   
                        }
                 
                    }
                }   
         }         
        }
        else{
            if (seguir.result) {
                const nuevo = await api.agregarAnticipo(viajeId, entity);
                nuevo.message.categoriaActual = categoriaTipo;
                onSave(nuevo.message);
                dispatch({
                    type: "update",
                    value: { 
                        ...defaultEntity(), 
                        cuentas: null,
                        extProv:constantes.tiposDepositoStp.CuentaXimple,
                        transferenciaStp:false
                    }
                });
            } 
        }             
    };

    const close = () => {       
        setcuentasOperador([])
        dispatch({
            type: "update",
            value: { 
                ...defaultEntity(), 
                cuentas: null,
                extProv:constantes.tiposDepositoStp.CuentaXimple,
                transferenciaStp:false
            }
        });  
        hide();
    };

    const renderFooterModal = () => {
        return (
            <div>
                <Button label="Cancelar" onClick={close} className="p-button-outlined" />
                <Button label={entity.transferenciaStp ? "Transferir" : "Guardar"}  onClick={guardar} autoFocus />
            </div>
        );
    }

    return (
        <Dialog header="Agregar gasto" visible={visible} style={{ width: '60vw' }} footer={renderFooterModal()} onHide={close}>       
            {(permisoGastoUrgente) &&
               <div className="form-row">   
                <FieldCheckBox 
                        name="urgente" 
                        label="Urgente" 
                        value={entity.urgente} 
                        className="py-1" 
                        flexflow="row-reverse" 
                        colMd={12}
                        onChange={actualizar} 
                    />
                </div>  
                }  
              {(permisoTransferenciaStp) &&
               <div className="form-row">   
                <FieldCheckBox 
                        name="transferenciaStp" 
                        label="Transferencia Stp" 
                        value={entity.transferenciaStp} 
                        className="py-1" 
                        flexflow="row-reverse" 
                        colMd={12}
                        onChange={actualizar} 
                    />
                </div>  
                }  
                {entity.transferenciaStp && (
                      <div className="d-flex justify-content-center">
                        <div className={`form-group col-md-6 col-sm-12}`}>                
                            <FieldRadioButtonList
                                name="extProv"
                                colMd={12}
                                options={externals}
                                value={entity.extProv}
                                onChange={actualizar}
                            />    
                        </div> 
                    </div> 
                )}   
                <div className="form-row">
                { (entity.extProv === constantes.tiposDepositoStp.CuentaXimple  || entity.extProv === constantes.tiposDepositoStp.Provisional)&&(
                    <>
                           <FieldDropDown 
                            name="operador"                            
                            value={entity.operador} 
                            source="operadoresSegmento" 
                            label="Operador"
                            required 
                            colMd={12} 
                            error={errors?.operador}
                            params={{ viajeid: viajeId }} 
                            onChange={actualizar} 
                        />
                           </>
                    )}
                    <>
                    {entity.operador && entity.transferenciaStp && (entity.extProv === constantes.tiposDepositoStp.CuentaXimple) && cuentasOperador.length > 0 && (
                            <FieldDropDown
                                name="cuentas"       
                                options={cuentasOperador}                  
                                error={errors?.cuentas}
                                label="Cuentas Operador"
                                colMd={12}
                                filter={true}
                                required={true}
                                value={entity.cuentas || []} 
                                onChange={actualizar}
                            />
                        )}
                    </>
                
                {entity.extProv===2 && (
                    <>
                        <FieldTextBox 
                            name="nombreexterno" 
                            value={entity.nombreexterno} 
                            label="Nombre"
                            required 
                            error={errors?.nombreexterno}
                            colMd={12}
                            onChange={actualizar} 
                        />
                    </>
                  )}
                {entity.transferenciaStp && (entity.extProv ===constantes.tiposDepositoStp.Externo || entity.extProv===constantes.tiposDepositoStp.Provisional) && (
                    <>
                        <FieldDropDown 
                            name="banco" 
                            source="bancos" 
                            value={entity.bancos} 
                            label="Banco" 
                            filter={true}
                            colMd={6} 
                            required 
                            error={errors?.banco}
                            onChange={actualizar} 
                        />
                        <FieldTextBox 
                            name="clabeAnticipo" 
                            value={entity.clabeAnticipo} 
                            label="Tarjeta o CLABE" 
                            colMd={6}
                            required 
                            error={errors?.clabeAnticipo}
                            onBlur={(event) => validarClabe(event.target.value)}
                            onChange={actualizar} 
                        />
                    </>
                )}
                
                
                <FieldDropDown name="tipoGasto" source="cajachica/tipoGastos" value={entity?.tipoGasto} label="Tipo de Gasto" 
                                     params={{ categoriaTipo: categoriaTipo }}   filter={true}
                    required colMd={6} error={errors?.tipoGasto } onChange={actualizar} />
                <FieldDropDown name="conceptoCajaChica" source="cajachica/conceptos" value={entity?.conceptoCajaChica} label="Concepto"  filter={true}
                    required colMd={6} error={errors?.conceptoCajaChica} params={{ tipoGastoId: entity?.tipoGasto?.id }}
                    onChange={actualizar} />
                <FieldInputNumber name="monto" value={entity.monto} fractionDigits={2} label="Monto"
                    required colMd={6} error={errors?.monto}
                    onChange={actualizar} />
                <FieldTextArea name="comentario"  label="Comentario" value={entity.comentario} colMd={12} rows={3}
                                         error={errors?.comentario} onChange={actualizar} />
            </div>

        </Dialog>
    )
}

export default AgregarAnticipo;
