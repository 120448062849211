/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import moment from "moment";
const HistorialRetiroCajaAhorro = ({historial, onChange}) => {
    return (
        <>
            <div className="form-row">
                <table className="table table-borderless">
                    <thead className="thead-light">
                    <tr>                   
                        <th style={{width: '25%'}}>FECHA</th>
                        <th style={{width: '25%'}}>MONTO</th>
                        <th style={{width: '35%'}}>USUARIO RETIRO</th>         
                        <th style={{width: '35%'}}>COMENTARIOS</th>        
                    </tr>
                    </thead>
                    <tbody>
                    { historial?.map((item, index) => {
                        return (
                            <tr key={index}>                                                            
                                <td>{moment(item.fecha).format('DD/MM/YYYY hh:mm a')}</td>                          
                                <td>{item.monto}</td> 
                                <td>{item.usuario}</td> 
                                <td>{item.comentarios}</td> 
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </>
    )
};


export default HistorialRetiroCajaAhorro;
