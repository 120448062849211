/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import useSessionState from "../../hooks/useSessionState";
import Liquidacion from "./liquidacion";
import Dispersion from "./dispersion";
import constantes from "../../catalogos/constantes";
import useApiLiquidaciones from "../../api/administracion/useApiLiquidaciones";
import useSeguridad from "../../Seguridad/useSeguridad";

const LiquidacionSecciones = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.liquidacion);
    const [activeIndex, setActiveIndex] = useSessionState('tabSeguimiento', 0);
    const [semanaLaboral, setSemanaLaboral] = useState([]);
    const apiLiquidacion = useApiLiquidaciones();

    useEffect(() => {
        async function cargarSemana() {
            let semana = await apiLiquidacion.semanaLaboral();
            setSemanaLaboral(semana);
        }
        cargarSemana();
    }, []);

    return (
        <>
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="main-tab-view">
                    <TabPanel headerStyle={(seguridad.tieneAcceso(constantes.menus.nominaOperativa) || soloLectura) ? { display: "inline" } : { display: "none" }} header="Nómina Operativa">
                        <Liquidacion esquemaPago={constantes.esquemaPago.liquidacion} semanaLaboral={semanaLaboral}
                           consulta={'empleadosOperativos'} soloLectura={soloLectura} />
                    </TabPanel>
                    <TabPanel headerStyle={seguridad.tieneAcceso(constantes.menus.nominaOficina) ? { display: "inline" } : { display: "none" }} header="Nómina Staff">
                        <Liquidacion esquemaPago={constantes.esquemaPago.staff} semanaLaboral={semanaLaboral}
                            consulta={'empleadosStaff'}  soloLectura={soloLectura} />
                    </TabPanel>
                    <TabPanel headerStyle={seguridad.tieneAcceso(constantes.menus.nominaAdministrativa) ? { display: "inline" } : { display: "none" }} header="Nómina Oficina">
                        <Liquidacion esquemaPago={constantes.esquemaPago.oficina} semanaLaboral={semanaLaboral}
                            consulta={'empleadosAdministrativos'} soloLectura={soloLectura} />
                    </TabPanel>
                    <TabPanel headerStyle={seguridad.tieneAcceso(constantes.menus.dispersionOperativa) ? { display: "inline" } : { display: "none" }} header="Dispersión Operativa">
                        <Dispersion esquemaPago={constantes.esquemaPago.liquidacion} semanaLaboral={semanaLaboral}
                            soloLectura={soloLectura} />
                    </TabPanel>
                    <TabPanel headerStyle={seguridad.tieneAcceso(constantes.menus.dispersionOficina) ? { display: "inline" } : { display: "none" }} header="Dispersión Staff">
                        <Dispersion esquemaPago={constantes.esquemaPago.staff} semanaLaboral={semanaLaboral}
                            soloLectura={soloLectura} />
                    </TabPanel>
                    <TabPanel headerStyle={seguridad.tieneAcceso(constantes.menus.dispersionAdministrativa) ? { display: "inline" } : { display: "none" }} header="Dispersión Oficina">
                        <Dispersion esquemaPago={constantes.esquemaPago.oficina} semanaLaboral={semanaLaboral}
                            soloLectura={soloLectura} />
                    </TabPanel>
                </TabView>
        </>
    )
};

export default LiquidacionSecciones;
