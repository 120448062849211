import React, {useState} from "react";
import produce from "immer";
import FieldTextBox from "../../../shared/controls/fieldTextBox";

const Medidas = ({value, onChange, name, errors, titulo}) => {
    const [expandido, setExpandido] = useState(true);

    function actualizar(itemValue, itemName) {
        const newValue = produce(value??{}, (draft) => {
            draft[itemName] = itemValue;
        });

        onChange(newValue, name);
    }

    return (
        <>
            <tr className="tr-expand-collapse">
                <td colSpan={3} className="input">
                    <i className={`pi ${expandido ? 'pi-angle-up' : 'pi-angle-down'}`}
                       onClick={() => setExpandido(!expandido)}/>
                    <label  onClick={() => setExpandido(!expandido)}>
                        {titulo}
                    </label>
                </td>
            </tr>
            {expandido &&
            <>
                <tr className="medida alto">
                    < td> Alto</td>
                    <td className="input">
                        <FieldTextBox name="alto" onChange={actualizar} keyFilter={'num'}
                                      value={value?.alto} errors={errors}/>
                    </td>
                </tr>
                < tr className="medida">
                    <td> Ancho</td>
                    <td className="input">
                        <FieldTextBox name="ancho" onChange={actualizar} keyFilter={'num'}
                                      value={value?.ancho} errors={errors}/>
                    </td>
                </tr>
                < tr className="medida">
                    <td> Largo</td>
                    <td className="input">
                        <FieldTextBox name="largo" onChange={actualizar} keyFilter={'num'}
                                      value={value?.largo} errors={errors}/>
                    </td>
                </tr>
            </>
            }
        </>
    );
};

export default Medidas;
