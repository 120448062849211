/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {Calendar} from "primereact/calendar";
import useInputValidation from "./useInputValidation";
import moment from "moment";

const FormCalendar = ({value, parent, onChange, field, disabled, formDisabled, required, validators, onValidate, ...props}) => {
    const [dateValue, setDateValue] = useState(null);



    useEffect(() => {
        if (typeof value === 'string' || value instanceof String){
            setDateValue(moment(value)._d);
        }else {
            setDateValue(value);
        }
    }, [value]);



    const {validationErrorCss} = useInputValidation({
        value,
        field,
        required,
        validators,
        onValidate,
        parent
    });

    const handleChange = (newDates)=>{
        onChange(newDates);
    }

    return (
        <>
            <Calendar
                id={field}
                locale={'es'}
                dateFormat="dd/M/yy"
                className={validationErrorCss}
                value={dateValue}
                style={{width:'100%'}}
                onChange={(e) => handleChange(e.value)}
                readOnlyInput
                disabled={disabled??formDisabled}
                {...props}
            />
        </>
    )
}

export default FormCalendar;
