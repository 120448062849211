/* eslint-disable react-hooks/exhaustive-deps */
import useApiSource from "../../api/source";
import {useEffect, useState} from "react";

const UnidadesNegocio = ({unidadNegocioId, onChange}) => {

    const api = useApiSource()

    const [unidades, setUnidades] = useState([])

    const load = async () => {
        const data = await api.obtener('unidadesNegocio')
        setUnidades(data)
    }

    useEffect(() => {
        load().then()
    }, [])

    return (<div className={'unidades-negocio'}>
        {unidades.map((item) =>
            <span key={item.id}
                  className={`link ${unidadNegocioId === item.id ? 'selected' : ''}`}
                  onClick={() => onChange( unidadNegocioId === item.id ? 0 : item.id )}>
                {item.nombreCorto}
            </span>
        )}
    </div>)
}
export default UnidadesNegocio;