import useServiceFactory from "../serviceFactory";
const apiUrl = `/taller/mantenimiento/SolicitudesServicio`;
const useApiEmpleados = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, {params}),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, {}),
        guardar: async (params) => service.post(`${apiUrl}`, params),
        actualizar: async (id,params) => service.put(`${apiUrl}/${id}`, params),      
        excel: async (params) => service.get(`${apiUrl}/excel`,
        {
            ...params,
            showLoading: true,
            download: true,
            fileName: `Solicitud.xlsx`
        })
    }
};

export default useApiEmpleados;