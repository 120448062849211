/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from "react";
import {useApplication} from "../../../../layout/ApplicationProvider";
import useApiViajeSeguimientoMonitoreo from "../../../../api/operaciones/useApiSeguimientoMonitoreo";
import {useSearchParams} from "react-router-dom";
import Mapa from "./Mapa";
import {useLoadingDispatch} from "../../../../layout/show-loading-context";

const SeguimientoMultipleViajes = () => {

    const [searchParams] = useSearchParams();
    const dispatch = useLoadingDispatch();

    const unidadIds =  searchParams.get("unidades").split(',')

    const api = useApiViajeSeguimientoMonitoreo();


    const [unidades] = useState(unidadIds)
    const [posiciones, setPosiciones] = useState([])
    const [intervals, setIntervals] = useState({posiciones})
    const [firstLoad, setFirstLoad] = useState(false)

    const intervalPosicion =(unidades)=>{
        clearInterval(intervals.posiciones)
        const intervalId = window.setInterval(async function(){
            await actualizarPosicion(unidades)
        }, 2000);
        setIntervals({posiciones: intervalId});
    }

    const actualizarPosicion = useCallback(async (unidades)=>{
        const posiciones = await api.getPosiciones({unidadIds:[...unidades]})
        setPosiciones(posiciones)
        if (!firstLoad) {
            setFirstLoad(true)
            dispatch({type: 'hide'});
        }
    },[])

    useEffect(() => {
        intervalPosicion(unidades)
        return ()=>{
            clearInterval(intervals.posiciones)
        }
    }, [unidades]);

    const {hideMenuAndHeader} = useApplication()
    useEffect(()=>hideMenuAndHeader(),[])

    useEffect(()=>{
        dispatch({type: 'show'});
    },[])

    return (
        <Mapa posiciones={posiciones} firstLoad={firstLoad}/>
    )
}
export default SeguimientoMultipleViajes;