/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import useDownloadFile from "./useDownloadFile";
import printJS from "print-js";
import {useEmpresa} from "../../layout/EmpresaProvider";
import alerts from "../alerts";

const ImageViewer = ({header, value, hide, visible, showApprove, onApprove, onReject, empresa}) => {
    const [file, setFile] = useState({isEmpty: true});
    const [fileDownload, setFileDownload] = useState({isEmpty: true});

    const downloadFile = useDownloadFile();
    const {empresaId} = useEmpresa();

    useEffect(() => {
        if (value == null) {
            setFile({isEmpty: true});
        } else {
            setFile({
                ...value,
                url:
                    value.file != null
                        ? URL.createObjectURL(value.file)
                        : value?.url == null
                            ? ""
                            : `${process.env.REACT_APP_API_URL}/archivos/imagen/${empresa ?? empresaId}?url=${value.url}`,
                downloadUrl: value.url,
                isEmpty: value.url == null && value.file == null,
            });

            setFileDownload({
                ...value,
                url: value.file != null ? URL.createObjectURL(value.file) : value?.url == null ? "" : `${value.url}`,
                downloadUrl: value.url,
                isEmpty: value.url == null && value.file == null,
            });
        }
    }, [value]);

    const approveHandler = async () => {
        if ((await alerts.preguntarSiNo("¿Desea aprobar el documento?")) === true) {
            onApprove();
        }
    }

    const rejectHandler = async () => {
        if ((await alerts.preguntarSiNo("¿Desea rechazar el documento?")) === true) {
            onReject()
        }
    }


    function getWidth() {
        if (window.innerWidth < 768) return "75vw";
        if (window.innerWidth < 1000) return "55vw";
        return "35vw";
    }

    const footer = (
        <div className="mt-2">
            <Button label="Cerrar" className="p-button-outlined" onClick={hide}/>
            <Button label="Descargar" onClick={() => downloadFile(fileDownload)}/>
            {file.url != null && (
                <Button label="Imprimir" onClick={() => printJS({printable: file.url, type: "image"})}/>
            )}
            {showApprove &&
                <>
                    <Button label="Aprobar" className="p-button-outlined p-button-success" onClick={approveHandler}/>
                    <Button label="Rechazar" className="p-button-outlined p-button-danger" onClick={rejectHandler}/>
                </>
            }
        </div>
    );

    return (
        <>
            <Dialog header={header} footer={footer} visible={visible} style={{width: getWidth()}} modal onHide={hide}>
                <div className="form-row">
                    <div className="image-upload">
                        {!file.isEmpty && (
                            <div className="image-container">
                                <img alt="Imagen" src={file.url}/>
                            </div>
                        )}
                    </div>
                </div>
            </Dialog>
        </>
    );
};
export default ImageViewer;
