/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import useSourceUtils from "../../useSouceUtils";
import useInputValidation from "./useInputValidation";
import {Checkbox} from "primereact/checkbox";


const FormCheckBoxList = ({value,onChange,parent, field, options,  source, orderBy,required, validators, onValidate, params ,disabled, fluid, formDisabled}) => {
    const {
        currentOptions,
        setSelectedId,
        getSelectedId,
        toggleSelection
    } = useSourceUtils(value, options, source, params, true, true,  orderBy);

    const {validationErrorCss} = useInputValidation({
        value,
        field,
        required,
        validators,
        onValidate,
        parent
    });


    const onSelected =(option) => {
        const ids =  toggleSelection(option.value)
        setSelectedId(ids)
        onChange(ids);
    }

    const getFluidStyle = ()=>{
        return fluid ? {
            width: '100%',
            justifyContent: 'space-between'
        } : {}
    }

    return (
        <div className={`form-checkbox-list ${validationErrorCss}`}
             style={{
                    display:"flex",alignItems:'center', ...getFluidStyle()
                }}>
            {currentOptions.map((option, index) => {
                return (
                    <React.Fragment key={index}>
                        <div className={`p-field-checkbox`} style={{marginRight:'10px', display:'flex',alignItems:'center'}}>
                                <Checkbox inputId={`${field}_${index}`} name={option.nombre} value={option.id}
                                             disabled={disabled ?? formDisabled}
                                             onChange={onSelected}
                                             checked={getSelectedId().indexOf(option.id) >= 0 }/>
                            <label htmlFor={`${field}_${index}`}>{option.nombre}</label>
                        </div>
                    </React.Fragment>
                )
            })}
        </div>
    )
};

export default FormCheckBoxList;

