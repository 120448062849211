import React from "react";
import produce from "immer";
import InputFile from "../../../shared/controls/inputFile";
import FieldCalendar from "../../../shared/controls/fieldCalendar";
import constantes from "../../../catalogos/constantes";

const Documentacion = ({documentacion, name, errors, onChange}) => {
    function updateArray(index, value, propertyName) {
        const newValue = produce(documentacion, draft => {
                draft[index][propertyName] = value;
            }
        );
        onChange(newValue, name);
    }

    return (
        <>
            <div className="form-row">
                <h5 className="col-12">Anexos</h5>
                <table className="table">
                    <thead className="thead-light">
                    <tr>
                        <th scope="col" style={{width: '50%'}}>TIPO DOCUMENTO</th>
                        <th scope="col" style={{width: '30%'}}>FECHA VENCIMIENTO</th>
                        <th scope="col" style={{width: '20%'}}/>
                    </tr>
                    </thead>
                    <tbody>
                    {documentacion.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td >
                                    {item.tipoDocumento.requerido ? '*' : ''}
                                    {item.tipoDocumento.nombre}
                                </td>
                                <td className = "input">
                                    {item.tipoDocumento.id !== constantes.tiposDocumento.permisoDeCruce &&
                                    <FieldCalendar name="vigencia"
                                                   className="small"
                                                   value={item.vigencia}
                                                   showButtonBar={false}
                                                   errors={errors[`documentacion[${index}]`]}
                                                   onChange={(value, pName) => updateArray(index, value, pName)}/>
                                    }
                                </td>
                                <td className="input center">
                                    <InputFile name="archivo" value={item.archivo}
                                               errors={errors[`documentacion[${index}]`]}
                                               onChange={(value, propertyName) => updateArray(index, value, propertyName)}
                                    />
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </>
    )
};

export default Documentacion;
