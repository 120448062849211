import useServiceFactory from "../serviceFactory";
const apiUrl = `/taller/recepcionRenovado`;
const useApiRecepcionRenovado = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, { params }),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, { showLoading: false }),
        guardar: async (params) => service.post(`${apiUrl}`, params),
        actualizar: async (id, params) => service.put(`${apiUrl}/${id}`, params, { showSuccess: true }),
        rechazar: async (id) => service.delete(`${apiUrl}/rechazar/${id}`),
        eliminar: async (id) => service.delete(`${apiUrl}/${id}`)
    }
};

export default useApiRecepcionRenovado;