import {useState} from "react";

const useFiltro = () => {
    const [value, setValue] = useState({texto:''})
    const [modalVisible, setModalVisible] = useState(false)

    const borrarFiltro = (filtroDefault) => {
        setValue(filtroDefault)
    }

    return {
        value,
        setValue,
        modalVisible,
        borrarFiltro,
        mostrarFiltro: ()=> setModalVisible(true),
        ocultarFiltro: ()=> setModalVisible(false)
    }
}

export default useFiltro