import React from "react";
import FieldAutoComplete from "../../../shared/controls/fieldAutoComplete";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import constantes from "../../../catalogos/constantes";

const HeaderCheckListUnidad = ({ entity, onChange, cargarInformacion, disabled, errors }) => {

    return (
        <>
            <FieldAutoComplete name="unidad" disabled={disabled} errors={errors} source="unidades" label="No. Económico" colMd={3}
                params={{ todasLasEmpresas: true }} value={entity?.unidad} onChange={(value) => cargarInformacion(value)} />
            <FieldTextBox name="tipoUnidad" disabled={true} label="Tipo Unidad" colMd={2}
                value={entity?.unidad?.tipoUnidad} onChange={onChange} />
            <FieldDropDown disabled={true} name="operadorAnterior" label="Asignacion Anterior" colMd={2}
                source="empleados" params={{ puestoIds: [constantes.puestos.operador] }} value={entity?.operadorAnterior} />


            <FieldDropDown disabled={disabled} name="operador" source="empleados" errors={errors}
                params={{ puestoIds: [constantes.puestos.operador] }}
                filter={true}
                required label="Operador" colMd={3} colMob={4} value={entity?.operador}
                onChange={onChange}
            />

            <FieldTextBox name="usuario" disabled={true} label="Usuario" colMd={2}
                value={entity?.usuario} onChange={onChange} />



        </>
    )
};
export default HeaderCheckListUnidad;
