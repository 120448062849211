/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import produce from "immer";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import alerts from "../../../shared/alerts";
import FieldAutoComplete from "../../../shared/controls/fieldAutoComplete";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import {Ruta} from "../cotizaciones/Rutas/ruta";
import * as numeral from "numeral";
import FieldTextArea from "../../../shared/controls/fieldTextArea";
import constantes from "../../constantes";
import DetalleRutaGrid from "../cotizaciones/Rutas/detalleRuta";
import Cliente from "../../../catalogos/clientes/cliente";
import Parada from "../paradas/parada";
import useApiConvenios from "../../../api/comercial/convenios";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import {FormatMoneda} from "../../../shared/utility";
import {useLoadingDispatch} from "../../../layout/show-loading-context";
import FieldMultiSelect from "../../../shared/controls/fieldMultiSelect";
import FieldRadioButtonList from "../../../shared/controls/fieldRadioButtonList";
import FieldInputNumber from "../../../shared/controls/fieldInputNumber";
import AgregarOrdenDeCompra from "./OrdenCompra";
import useSeguridad from "../../../Seguridad/useSeguridad";
import {default as constantesCatalogos} from "../../../catalogos/constantes";
import {useEmpresa} from "../../../layout/EmpresaProvider";
import Log from "../../../shared/Log";
import ModalConvenio from "./modalConvenio";

const DetalleConvenio = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantesCatalogos.menus.convenios);
    const soloLecturaComercial = !seguridad.esEditable(constantesCatalogos.menus.comercial);
    const dispatchRoot = useLoadingDispatch();
    const [convenio, setConvenio] = useState({ruta: new Ruta(), moneda: constantes.monedaPeso, paradas: []});
    const [submitted, setSubmitted] = useState(false);
    const [detalleExpandido, setDetalleExpandido] = useState(false);
    const [editable, setEditable] = useState(false);
    const [puedeSuspender, setPuedeSuspender] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const apiConvenios = useApiConvenios();
    const history = useNavigate();
    const [conceptos, setConcepto] = useState(false);
    const [vigente, setVigente] = useState(false);
    const [mostrarOrdenCompra, setMostrarOrdenCompra] = useState();
    const [viajes, setViajes] = useState([]);
    const [impoExpo] = useState([
        {id: false, nombre: "Impo"},
        {id: true, nombre: "Expo"},
    ]);
    const [verModal, setVerModal] = useState(false);

    let {id} = useParams();
    const [errorSubCliente, setErrorSubCliente] = useState(false);
    const {disable: disableEmpresa, empresa} = useEmpresa();
    useEffect(() => {
        cargarConvenio();
        disableEmpresa();
    }, []);

    async function cargarConvenio() {
        const convenio = await apiConvenios.obtener(id);
        setConvenio({...convenio, ruta: new Ruta(convenio.ruta)});
        setEditable(
            convenio.estatus.id === constantes.estatusConvenios.altaPendiente ||
            convenio.estatus.id === constantes.estatusConvenios.vigente
        );
        setPuedeSuspender(convenio.estatus.id === constantes.estatusConvenios.vigente);
        dispatchRoot({type: "setDatosPath", datosPath: convenio?.numeroConvenio});
        setConcepto(convenio.conceptosCobro);
        setVigente(convenio.estatus.id === constantes.estatusConvenios.vigente);
        setViajes(await apiConvenios.obtenerViajes(id));
    }

    async function actualizar(value, propertyName) {
        if (propertyName === "internacional") {
            var viajes = await apiConvenios.validarImpoExpo(id, value);
            if (viajes.length > 0) {
                if (await alerts.preguntarSiNo(`Existen ${viajes.length} viajes pendientes que se veran afectados, ¿deseas continuar?`)) {
                    setConvenio(produce(convenio, (draft) => {
                        draft[propertyName] = value;
                    }));
                }
            } else {
                setConvenio(produce(convenio, (draft) => {
                    draft[propertyName] = value;
                }));
            }
        } else {
            if (propertyName === "conceptosCobro") {
                let list = [];
                value.forEach(function (c) {
                    let concepto = conceptos.filter(function (cc) {
                        return cc.id === c.id;
                    });
                    let monto = 0;
                    if (concepto !== null && concepto.length > 0) {
                        monto = concepto[0].monto;
                    }

                    list.push({id: c.id, nombre: c.nombre, monto: monto});
                });

                setConvenio(
                    produce(convenio, (draft) => {
                        draft[propertyName] = list;
                    })
                );

                if (conceptos.length < list.length) {
                    setConcepto(list);
                }
            } else {
                if (propertyName === "cliente") {
                    var convenionuevo = produce(convenio, (draft) => {
                        draft[propertyName] = value;
                        draft["subCliente"] = {id: 0};
                    });
                    setConvenio(convenionuevo);
                } else {
                    setConvenio(
                        produce(convenio, (draft) => {
                            draft[propertyName] = value;
                        })
                    );
                }
                const nuevoConvenio = produce(convenio, draft => {
                    draft[propertyName] = value
                });

                setConvenio(nuevoConvenio);
                if (propertyName === "subCliente" && nuevoConvenio.subCliente?.id != null && nuevoConvenio.subCliente?.id > 0) {
                    let subCliente = await apiConvenios.obtenerSubcliente(nuevoConvenio.subCliente?.id);
                    const nuevoConvenioActualizado = {
                        ...nuevoConvenio,
                        aplicaFrightVerify: subCliente?.aplicaFrightVerify,
                        aplicaQS3: subCliente?.aplicaQS3
                    };
                    setConvenio(nuevoConvenioActualizado);
                }
            }


        }
        setIsDirty(true);

    }

    function actualizarParada(parada, index) {
        setConvenio(
            produce(convenio, (draft) => {
                draft.paradas[index] = parada;
            })
        );

        cargarConvenio();
    }

    async function back() {
        if (isDirty === false || (await alerts.preguntarSiDeseaSalirSinGuardar())) {
            history("/comercial");
        }
    }

    async function guardar() {
        setSubmitted(true);
        setErrorSubCliente(false);

        if (convenio.aplicaFrightVerify === true && codigoRutaRequerida()) {
            return;
        }
        if (convenio.aplicaEdiAODMEX === true && codigoRutaRequerida()) {
            return;
        }
        if (convenio.cliente?.esLogistico && (convenio.subCliente === null || convenio.subCliente === undefined)) {
            setErrorSubCliente(true);
            return;
        }

        if (convenio?.internacional) {

            if (convenio.paradas.some(p => p.esOrigen && !p.direccion?.rfc)) {
                await alerts.aviso("Capturar un RFC valido para la dirección origen.");
                return;
            }

            if (convenio.paradas.some(p => p.esDestino && !p.direccion?.rfc)) {
                await alerts.aviso("Capturar un RFC valido para la dirección destino.");
                return;
            }

            if (convenio?.impoExpo) {
                if (convenio?.paradas?.some(p => p.esOrigen && p.direccion?.pais?.id !== constantesCatalogos.paises.mexico && convenio.tipoViajeId !== constantes.tipoViaje.redondo)) {
                    await alerts.aviso("El origen no es valido para exportación.");
                    return;
                }

                if (convenio?.paradas?.some(p => p.esDestino && p.direccion?.pais?.id === constantesCatalogos.paises.mexico && convenio.tipoViajeId  !== constantes.tipoViaje.redondo)) {
                    await alerts.aviso("El destino no es valido para exportación.");
                    return;
                }
            } else {
                if (convenio?.paradas?.some(p => p.esOrigen && p.direccion?.pais?.id === constantesCatalogos.paises.mexico)) {
                    await alerts.aviso("El origen no es valido para importación.");
                    return;
                }

                if (convenio?.paradas?.some(p => p.esDestino && p.direccion?.pais?.id !== constantesCatalogos.paises.mexico && convenio.tipoViajeId !== constantes.tipoViaje.redondo)) {
                    await alerts.aviso("El destino no es valido para exportación.");
                    return;
                }
            }
        }

        await apiConvenios.actualizar(id, {...convenio, estatus: convenio.estatus.id});
        history("/comercial");
    }

    async function registrar() {
        setSubmitted(true);
        setErrorSubCliente(false);
        const paradasConError = convenio.paradas.filter((e) => e.direccion == null).length > 0;
        //!!aqui hace la validación, si se agregan mas campos requeridos, mejor cambiarlo a esquema de validación!!
        if (clienteNoEncontrado() || paradasConError === true) {
            return;
        }
        if (convenio.aplicaFrightVerify === true && codigoRutaRequerida()) {
            return;
        }
        if (convenio.aplicaEdiAODMEX === true && codigoRutaRequerida()) {
            return;
        }
        if (convenio.cliente?.esLogistico && (convenio.subCliente === null || convenio.subCliente === undefined)) {
            setErrorSubCliente(true);
            return;
        }
        await alerts.preguntarSiNo("¿Desea registrar el convenio?");
        await apiConvenios.setVigente(id, {...convenio, estatus: convenio.estatus.id});
        history("/comercial");
    }

    async function suspender() {
        if ((await alerts.preguntarSiNo("¿Desea suspender el Convenio?")) === false) {
            return;
        }
        await apiConvenios.suspender(id);
        history("/comercial");
    }

    function clienteNoEncontrado() {
        return !((convenio.cliente?.id ?? 0) > 0);
    }

    function ProveedorRequerido() {
        return (convenio.proveedor?.length ?? 0) === 0 && (convenio.aplicaFrightVerify || convenio.aplicaEdiAODMEX);
    }

    function codigoRutaRequerida() {
        return (convenio.proveedor?.length ?? 0) === 0 && (convenio.aplicaFrightVerify || convenio.aplicaEdiAODMEX);
    }

    function actualizarConceptos(concepto, index) {
        setConvenio(
            produce(convenio, (draft) => {
                draft.conceptosCobro[index].monto = concepto;
            })
        );
    }

    function actualizarViajes(newViajes) {
        setViajes(newViajes);
    }

    async function actualizarOC(oc) {
        setConvenio(
            produce(convenio, (draft) => {
                draft.ordenesCompra.push(oc);
            })
        );

        setViajes(await apiConvenios.obtenerViajes(id));
    }

    const onUpdateFreightVerify = (checked) => {
        setConvenio({...convenio, aplicaFrightVerify: checked, aplicaQS3: checked ? false : convenio.aplicaQS3})
        setIsDirty(true);
    }

    const onUpdateQs3 = (checked) => {
        setConvenio({
            ...convenio,
            aplicaQS3: checked,
            aplicaFrightVerify: checked ? false : convenio.aplicaFrightVerify
        })
        setIsDirty(true);
    }

    const quitarConvenio = async () => {
        if (await alerts.preguntarSiNo("¿Quitar convenio relacionado?")) {
            await apiConvenios.guardarRelacionado(id, 0);
            cargarConvenio();
        }
    };
    return (
        <>
            <div className="form-container">
                <div className={`form-filtro form-row`}>
                    <div className={`form-group col-md-4 col-sm-6`}>
                        <label htmlFor={"cliente"}>Cliente</label>
                        {clienteNoEncontrado() && (
                            <label className="autocomplete-not-found-item" style={{right: 150}}>
                                {" "}
                                No encontrado{" "}
                            </label>
                        )}
                        <div className="col-auto">
                            <FieldAutoComplete
                                name="cliente"
                                disabled={!editable}
                                source="clientes"
                                error={clienteNoEncontrado() === true && submitted}
                                freeText
                                showNotFound={true}
                                value={convenio.cliente}
                                onChange={actualizar}
                            />
                            {editable && (
                                <Cliente
                                    onSave={(nuevoCliente) => actualizar(nuevoCliente, "cliente")}
                                    soloLectura={soloLecturaComercial}
                                />
                            )}
                        </div>
                    </div>
                    <FieldCheckBox
                        label="Freight Verify"
                        name="aplicaFrightVerify"
                        disabled={!editable}
                        colMd={1}
                        colSm={2}
                        value={convenio.aplicaFrightVerify}
                        onChange={(e) => onUpdateFreightVerify(e)}
                    />
                    <FieldCheckBox
                        label="QS3"
                        name="aplicaQS3"
                        disabled={!editable}
                        colMd={1}
                        colSm={2}
                        value={convenio.aplicaQS3}
                        onChange={(e) => onUpdateQs3(e)}
                    />
                    <FieldCheckBox
                        label="Aplica Edi AODMEX"
                        name="aplicaEdiAODMEX"
                        disabled={!editable}
                        colMd={1}
                        colSm={2}
                        value={convenio.aplicaEdiAODMEX}
                        onChange={actualizar}
                    />
                    <FieldDropDown
                        name="estatus"
                        label="Estatus"
                        source="estatusConvenio"
                        showClear={false}
                        colMd={2}
                        disabled={true}
                        value={convenio.estatus}
                        onChange={actualizar}
                    />
                    <FieldCheckBox
                        label="Fuel"
                        name="aplicaFuel"
                        disabled={!editable}
                        colMd={1}
                        colSm={2}
                        value={convenio.aplicaFuel}
                        onChange={actualizar}
                    />
                    <FieldCheckBox
                        label="Internacional"
                        name={"internacional"}
                        disabled={!editable}
                        colMd={1}
                        colSm={2}
                        value={convenio?.internacional}
                        onChange={actualizar}/>
                    {convenio.internacional && (
                        <>
                            <div className={`form-group col-md-2 col-sm-4`}>
                                <FieldRadioButtonList
                                    options={impoExpo}
                                    name="impoExpo"
                                    colMd={2}
                                    disabled={!editable}
                                    value={convenio?.impoExpo}
                                    onChange={actualizar}/>
                            </div>
                        </>
                    )}

                    <div className="field-iconos btn-group">
                        <ButtonIcon title="Regresar" iconName={"BiArrowBack"} onClick={back}/>
                        {!soloLecturaComercial && (
                            <>
                                {puedeSuspender && (
                                    <ButtonIcon title="Cancelar" iconName={"FcCancel"} onClick={suspender}/>
                                )}
                                {editable && (
                                    <>
                                        <ButtonIcon title="Guardar" iconName={"AiOutlineSave"} onClick={guardar}/>
                                        <ButtonIcon
                                            title="Alta de Convenio"
                                            className="action"
                                            iconName={"FaCheckCircle"}
                                            onClick={registrar}
                                            disabled={vigente}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div style={{marginTop: "-15px"}} className={`form-filtro form-row`}>
                    {convenio.cliente?.esLogistico && (

                        <FieldDropDown
                            name="subCliente"
                            label="SubCliente"
                            source="subClientes"
                            required={true}
                            error={errorSubCliente}
                            params={{clienteId: convenio.cliente?.id}}
                            showClear={true}
                            colMd={2}
                            value={convenio.subCliente}
                            onChange={actualizar}
                        />
                    )}
                    <FieldTextBox name={"cotizacion"} label={'Cotizacion'} value={convenio.cotizacion} colMd={1}
                                  disabled={true}></FieldTextBox>
                    <div style={{marginLeft: '50px', display: 'flex', alignItems: 'center'}}>
                        <FieldRadioButtonList source="clasificacionesCarga" name="clasificacionCargaId"
                                              disabled={true}
                                              value={convenio.clasificacionCarga}>
                        </FieldRadioButtonList>
                    </div>
                    <div style={{marginLeft: '50px', display: 'flex', alignItems: 'center'}}>
                        <FieldRadioButtonList source="clasificacionesRuta" name="clasificacionRuta"
                                              disabled={true}
                                              value={convenio.clasificacionRuta}>
                        </FieldRadioButtonList>
                    </div>
                </div>
                <div className={`section-container ${submitted === true ? "submitted" : ""}`}>
                    <div className="flex-col-12 ">
                        <h5>Ruta</h5>
                        <table className="table table-borderless">
                            <thead>
                            <tr>
                                <th style={{width: 20}}/>
                                <th>ORIGEN</th>
                                <th>DESTINO</th>
                                <th>TIPO VIAJE</th>
                                <th>TIPO UNIDAD</th>
                                <th>{convenio?.ruta?.unidadDeMedida?.abreviatura}</th>
                                <th>COSTO RUTA</th>
                                <th>FACTURACIÓN</th>
                                <th>PRECIO POR {convenio?.ruta?.unidadDeMedida?.abreviatura}</th>
                                <th>UTILIDAD</th>
                                <th>MARGEN</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="input">
                                    {detalleExpandido !== true && (
                                        <ButtonIcon
                                            title={"Expander"}
                                            iconName={"MdExpandMore"}
                                            onClick={() => setDetalleExpandido(true)}
                                        />
                                    )}
                                    {detalleExpandido === true && (
                                        <ButtonIcon
                                            title={"Contraer"}
                                            iconName={"MdExpandLess"}
                                            onClick={() => setDetalleExpandido(false)}
                                        />
                                    )}
                                </td>
                                <td>{convenio.ruta.viaje.origen?.nombre}</td>
                                <td>{convenio.ruta.viaje.destino?.nombre}</td>
                                <td>{convenio.ruta.viaje.tipoViajeNombre}</td>
                                <td>{convenio.ruta.viaje.tipoUnidad?.nombre}</td>
                                <td>{numeral(convenio.ruta.costo.longitud).format("0,0.00")}</td>
                                <td>{numeral(convenio.ruta.costo.costoRuta).format("$0,0.00")}</td>
                                <td>
                                    <div>{numeral(convenio.ruta.facturacionMxn).format("$0,0.00")}</div>
                                    {convenio.ruta.montoFacturacionUsd && empresa.moneda.id !== constantes.monedaUsd.id && (
                                        <div>
                                            {new FormatMoneda(true, convenio.ruta.tipoDeCambio).format(
                                                convenio.ruta.facturacionMxn
                                            )}
                                        </div>
                                    )}
                                </td>
                                <td>{numeral(convenio.ruta.precioPorKilometro).format("$0,0.00")}</td>
                                <td>{numeral(convenio.ruta.utilidad).format("$0,0.00")}</td>
                                <td>{convenio.ruta.margen}</td>
                            </tr>
                            <tr>
                                {detalleExpandido === true && (
                                    <td colSpan={11}>
                                        <DetalleRutaGrid data={convenio.ruta}/>
                                    </td>
                                )}
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex-col-6">
                        <h5>Conceptos de cobro</h5>
                        <div className={`form-filtro form-row`}>
                            <div className="form-group col-md-6 col-sm-6 text-right">
                                <label>Seleccionar Concepto de Cobro</label>
                            </div>
                            <div className="form-group col-md-6 col-sm-6">
                                <FieldMultiSelect
                                    showFilter={false}
                                    maxSelectedLabels={12}
                                    disabled={!editable}
                                    name="conceptosCobro"
                                    source="conceptosCobro"
                                    colMd={4}
                                    value={convenio.conceptosCobro}
                                    onChange={actualizar}
                                />
                            </div>
                        </div>
                        <div className={`form-filtro form-row`}>
                            {convenio?.conceptosCobro?.map((item, index) => (
                                <React.Fragment key={index}>
                                    <FieldInputNumber
                                        name="concepto"
                                        value={item.monto}
                                        required
                                        label={`${item.nombre}`}
                                        colMd={3}
                                        onChange={(e) => actualizarConceptos(e, index)}
                                        fractionDigits={2}
                                        maxLength={6}
                                    />
                                </React.Fragment>
                            ))}
                        </div>
                        <h5>Adicionales</h5>
                        <table className="table table-borderless">
                            <thead className="thead-light">
                            <tr>
                                <th scope="col" style={{width: "40%"}}>
                                    ACCESORIO
                                </th>
                                <th scope="col" style={{width: "20%"}}>
                                    CANTIDAD
                                </th>
                                <th scope="col" style={{width: "20%"}} className="right">
                                    PRECIO UNITARIO
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {convenio.ruta.accesorios.map((accesorio, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{accesorio.accesorioNombre}</td>
                                        <td>{accesorio.cantidad}</td>
                                        <td className="right">{numeral(accesorio.precio).format("$0,0.00")}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td/>
                                <td colSpan={2} className="right">
                                    Total: {numeral(convenio.ruta.totalAccesorios).format("$0,0.00")}
                                </td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div className="flex-col-6">
                        <h5>Generales</h5>
                        <div className={`form-filtro form-row`}>
                            <FieldTextBox
                                name="proveedor"
                                label="No. Proveedor / Transportista"
                                colMd={4}
                                disabled={!editable}
                                error={ProveedorRequerido() === true && submitted}
                                value={convenio.proveedor}
                                onChange={actualizar}
                            />
                            <FieldTextBox
                                name="codigoRuta"
                                label="Código Ruta"
                                colMd={4}
                                disabled={!editable}
                                error={codigoRutaRequerida() === true && submitted}
                                value={convenio.codigoRuta}
                                onChange={actualizar}
                            />
                            <div className={`form-group col-md-2 col-sm-6}`}>
                                <label>&nbsp;</label>
                                <FieldRadioButtonList
                                    name="moneda"
                                    source="monedas"
                                    valueIsObject={true}
                                    disabled={!convenio.editarMoneda}
                                    value={convenio.moneda}
                                    onChange={actualizar}
                                />
                            </div>
                        </div>
                        <h6>Comentarios</h6>
                        <FieldTextArea value={convenio.ruta.comentario} rows={2} disabled/>
                        <h6>Notas</h6>
                        <FieldTextArea value={convenio.ruta.nota} rows={2} disabled/>
                    </div>
                    <div className="flex-col-12" style={{overflow: "auto"}}>
                        <h5>Origen . . . Destino</h5>
                        <div style={{width: "100px"}} className="com-paradas">
                            <Parada
                                esSeparador={true}
                                labelClassName="com-parada-ruta-label"
                                textoBody="RUTA"
                                clienteId={0}
                                parada={{id: 0}}
                                subClienteId={0}
                                convenioId={id}
                            />
                            {convenio.paradas.map((parada, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <Parada
                                            parada={parada}
                                            entidadId={id}
                                            api={apiConvenios}
                                            onChange={(e) => actualizarParada(e, index)}
                                            readOnly={soloLectura}
                                            clienteId={convenio?.cliente?.id ?? 0}
                                            onSave={cargarConvenio}
                                            subClienteId={convenio?.subCliente?.id ?? 0}
                                            convenioId={id}
                                        />
                                        {index < convenio.paradas.length - 1 && (
                                            <Parada
                                                labelClassName="com-parada-puntos"
                                                textoHeader="..."
                                                textoBody="..."
                                                esSeparador={true}
                                                clienteId={0}
                                                parada={{id: 0}}
                                                subClienteId={convenio?.subCliente?.id ?? 0}
                                                convenioId={id}
                                            />
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>
                    <div className="flex-col-6">
                        <h5>Puntos de Interes</h5>
                        <FieldMultiSelect
                            showFilter={true}
                            maxSelectedLabels={12}
                            disabled={!editable}
                            name="puntosInteres"
                            source="puntosInteres"
                            colMd={12}
                            value={convenio.puntosInteres}
                            onChange={actualizar}
                        />
                    </div>
                    <div className={"flex-col-6"}>
                        <h5>Convenio Relacionado</h5>
                        <div className="d-flex align-items-center">
                            {!soloLecturaComercial &&
                                <>
                                    <div>
                                        {convenio.convenioRelacionado}
                                    </div>
                                    <div className="table-button flex-grow-1">
                                        {convenio?.convenioRelacionado?.length > 0 ?
                                            <ButtonIcon title="Quitar Conveio" className="grid-action"
                                                        iconName={'BiEraser'}
                                                        onClick={quitarConvenio}/>
                                            :
                                            <ButtonIcon title="Guardar" className="grid-action"
                                                        iconName={'FaRegEdit'}
                                                        onClick={() => setVerModal(true)}/>
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    <div className="flex-col-6">
                        {convenio?.requiereOrdenCompra && (
                            <>
                                <div className="form-row mt-2">
                                    <div className="col-11">
                                        <h5>Ordenes de compra</h5>
                                    </div>
                                    <div className="col-1 align-items-end header-icons">
                                        {!soloLectura && (
                                            <div className="d-flex" style={{float: "right"}}>
                                                <ButtonIcon
                                                    title="Agregar orden de compra"
                                                    className={"grid-action"}
                                                    iconName={"MdAddBox"}
                                                    onClick={() => setMostrarOrdenCompra(true)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <table className="table table-borderless">
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col" style={{width: "30%"}} className="center">
                                            ORDEN DE COMPRA
                                        </th>
                                        <th scope="col" style={{width: "20%"}} className="center">
                                            VIAJES TOTALES
                                        </th>
                                        <th scope="col" style={{width: "20%"}} className="center">
                                            VIAJES REALIZADOS
                                        </th>
                                        <th scope="col" style={{width: "10%"}} className="center">
                                            ACTIVA
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {convenio?.ordenesCompra?.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="center">{item.ordenCompra}</td>
                                                <td className="center">{item.viajesTotales}</td>
                                                <td className="center">{item.viajesRealizados}</td>
                                                <td className="center">{item.actual}</td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </>
                        )}
                    </div>
                    <div className={'flex-col-6'}>
                        <div>
                            <Log data={convenio.logs ?? []}></Log>
                        </div>
                    </div>
                    <div className={'flex-col-12'}>
                        <h5>Ruta Asignada</h5>
                        <div>
                                <FieldTextBox
                                name="linkruta"
                                label="Link Ruta Maps"
                                colMd={12}
                                disabled={!editable}                            
                                value={convenio.linkRuta}
                                onChange={actualizar}
                                rows={4} 
                                title={convenio.linkRuta}  
                            />
                        </div>
                    </div>
                </div>
            </div>
            <AgregarOrdenDeCompra
                convenioId={id}
                viajes={viajes}
                visible={mostrarOrdenCompra}
                hide={() => setMostrarOrdenCompra(false)}
                onChange={actualizarViajes}
                update={actualizarOC}
            />
            <ModalConvenio visible={verModal} hide={() => setVerModal(false)} id={id} onChange={actualizar} onSave={cargarConvenio}/>
        </>
    );
};

export default DetalleConvenio;
