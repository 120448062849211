/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from "react";
import formReducer from "../../shared/forms/formReducer";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import produce from "immer";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import useApiPerfiles from "../../api/catalogos/useApiPerfiles";
import alerts from "../../shared/alerts";
import FormHandler from "../../shared/forms/formHandler";
import perfilesValidacionEsquema from "./perfilesValidacionEsquema";
import constantes from "../constantes";
import ReadOnlyProvider from "../../shared/forms/ReadOnlyProvider";

const DetallePerfil = ({ perfilId, listaMenus, onSave, close, soloLectura }) => {
    const defaultEntity = () => ({ entity: { id: 0 } });
    const [{ entity, errors }, dispatch] = useReducer(
        formReducer, {}, defaultEntity
    );
    const [nodes, setNodes] = useState(listaMenus);
    const [consulta, setConsulta] = useState(false);
    const [edicion, setEdicion] = useState(false);
    const api = useApiPerfiles();

    const formHandler = new FormHandler({
        validationSchema: perfilesValidacionEsquema,
        api,
        dispatch,
    });

    const seleccionarMenus = (menus) => {
        const newNodes = produce(nodes, (draft) => {
            menus.forEach(m => {
                const index = nodes.findIndex(n => n.id === m.id);
                if (index > 0) {
                    draft[index].consulta = m.consulta;
                    draft[index].edicion = m.edicion;
                }
            });
        });

        setConsulta(newNodes.every(n => n.consulta));
        setEdicion(newNodes.every(n => n.edicion));
        setNodes(newNodes);
    };

    useEffect(() => {
        async function cargar() {
            const perfil = await api.obtener(perfilId);
            seleccionarMenus(perfil.menus);

            dispatch({ type: "update", value: perfil });
        }

        if (perfilId > 0) {
            cargar().then();
        }
    }, []);

    const actualizar = (value, propertyName) => {
        dispatch({ type: 'update', value: value, name: propertyName });
    };

    const obtenerPadding = (item) => {
        let padding = 0;
        if (item.level > 0) {
            padding = 16 * item.level;
            padding = item.isLeaf ? padding + 20 : padding;
        } else if (item.isLeaf) {
            padding = 20;
        }

        return padding + "px";
    };

    const ocultarFilas = (padreExpandido, filas, draft) => {
        filas.forEach(f => {
            const index = nodes.findIndex(n => n.id === f.id);
            draft[index].visible = padreExpandido;

            const filaHija = nodes[index];

            if (filaHija.expandido || !padreExpandido) {
                const filasVisibles = padreExpandido && filaHija.expandido;
                const filasHijas = nodes.filter(n => n.padreId === filaHija.id);
                ocultarFilas(filasVisibles, filasHijas, draft);
            }
        })
    };

    const expandir = (item, index) => {
        const expandido = !item.expandido;

        const newNodes = produce(nodes, (draft) => {
            draft[index].expandido = expandido;

            const filasHijas = nodes.filter(n => n.padreId === item.id);
            ocultarFilas(expandido, filasHijas, draft);
        });

        setNodes(newNodes);
    };

    const seleccionarHijos = (filas, name, seleccionado, draft) => {
        filas.forEach(f => {
            const index = nodes.findIndex(n => n.id === f.id);
            draft[index][name] = seleccionado;

            const filasHijas = nodes.filter(n => n.padreId === f.id);
            seleccionarHijos(filasHijas, name, seleccionado, draft)
        });
    };

    const seleccionaPadre = (item, name, seleccionado, draft) => {
        const indexFilaPadre = nodes.findIndex(n => n.id === item.padreId);

        if (indexFilaPadre >= 0) {
            const filaPadre = nodes[indexFilaPadre];
            const filasHijas = nodes.filter(n => item.id !== n.id && n.padreId === filaPadre.id);
            const padreSeleccionado = seleccionado || filasHijas.some(f => f[name]);

            draft[indexFilaPadre][name] = padreSeleccionado;
            seleccionaPadre(filaPadre, name, padreSeleccionado, draft);
        }
    };

    const seleccionar = (e, item, index) => {
        const { name, checked } = e.target;

        const newNodes = produce(nodes, (draft) => {
            draft[index][name] = checked;

            const filasHijas = nodes.filter(n => n.padreId === item.id);
            seleccionarHijos(filasHijas, name, checked, draft);
            seleccionaPadre(item, name, checked, draft);
        });

        if (name === "consulta") {
            setConsulta(newNodes.every(n => n.consulta))
        } else {
            setEdicion(newNodes.every(n => n.edicion))
        }

        setNodes(newNodes);

        const menusSeleccionados = newNodes.filter(n => n.consulta || n.edicion);
        actualizar(menusSeleccionados, "menus")
    };

    const seleccionarTodo = (e) => {
        const { name, checked } = e.target;

        const newNodes = produce(nodes, (draft) => {
            nodes.forEach((n, index) => {
                draft[index][name] = checked;
            })
        });

        if (name === "consulta") setConsulta(checked);
        else setEdicion(checked);

        setNodes(newNodes);

        const menusSeleccionados = newNodes.filter(n => n.consulta || n.edicion);
        actualizar(menusSeleccionados, "menus")
    };

    const guardar = async () => {
        await formHandler.save(entity);

        if (formHandler.valdation.result) {
            onSave();
        }
    };

    const eliminar = async () => {
        if (await alerts.preguntarSiNoAdvertencia('¿Desea eliminar el perfil?')) {
            await api.eliminar(perfilId);
            onSave();
        }
    };

    return (
        <>
            <div className="form-container full-height">
                <ReadOnlyProvider defaultReadOnly={soloLectura}>
                    <div className="form-row">

                        <h5 className="col-12">Perfil</h5>

                        <FieldTextBox name="nombre" label="Nombre" colMd={6} required value={entity.nombre}
                            onChange={actualizar} errors={errors} />
                    </div>

                    <div className="form-row">
                        <h5 className="col-12">Permisos</h5>
                        {errors?.hasOwnProperty("menus") &&
                            <label className="text-danger col-12">Debe seleccionar por lo menos una opción</label>
                        }
                    </div>

                    <table className="table table-sm">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col" style={{ width: '40%' }}>MÓDULO</th>
                                <th scope="col" className="center" style={{ width: '25%' }}>
                                    <label className="pr-2 mb-0" htmlFor="consulta">CONSULTA</label>
                                    <Checkbox inputId="consulta" name="consulta" onChange={seleccionarTodo} checked={consulta} disabled={soloLectura} />
                                </th>
                                <th scope="col" className="center" style={{ width: '25%' }}>
                                    <label className="pr-2 mb-0" htmlFor="edicion">EDICIÓN</label>
                                    <Checkbox inputId="edicion" name="edicion" onChange={seleccionarTodo} checked={edicion} disabled={soloLectura} />
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {nodes?.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {(item.level === 0 || item.visible) &&
                                            <tr key={index}>
                                                <td>
                                                    <div style={{ paddingLeft: obtenerPadding(item) }}>
                                                        {!item.isLeaf &&
                                                            <i className={`cursor-pointer pi pi-angle-${item.expandido ? 'down' : 'right'}`}
                                                                onClick={() => expandir(item, index)} />
                                                        }
                                                        {item.nombre}
                                                    </div>
                                                </td>
                                                <td className="center option ok">
                                                    {item.tipo !== constantes.tipoMenus.opcion &&
                                                        <Checkbox name="consulta" disabled={soloLectura}
                                                            onChange={(e) => seleccionar(e, item, index)}
                                                            checked={item.consulta} />
                                                    }
                                                </td>
                                                <td className="center option no-ok">
                                                    <Checkbox name="edicion" onChange={(e) => seleccionar(e, item, index)}
                                                        checked={item.edicion} disabled={soloLectura} />
                                                </td>
                                            </tr>
                                        }
                                    </React.Fragment>
                                )
                            })}
                        </tbody>
                    </table>
                </ReadOnlyProvider>
            </div>

            <div className="form-footer">
                <Button label='Cancelar' className="p-button-outlined" type="button" onClick={close} />
                {!soloLectura &&
                    <>
                        {perfilId > 0 &&
                            <Button label="Eliminar" className="p-button-outlined p-button-danger" type="button" onClick={eliminar} />
                        }
                        <Button label="Guardar" type="button" onClick={guardar} />
                    </>
                }
            </div>
        </>
    )
};

export default DetallePerfil;
