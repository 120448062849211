import * as yup from "yup";
import direccionValidacionEsquema from "../../catalogos/direccionValidacionEsquema";



const direccionesValidacionEsquema = yup.object().shape({
    direccion: direccionValidacionEsquema(true,true),
  
});

export default direccionesValidacionEsquema;