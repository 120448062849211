import * as yup from "yup";

const EmpredaValidacionEsquema = yup.object().shape({
    nombre: yup.string().required(),
    nombreCorto: yup.string().required(),
    timeZone: yup.string().required(),
    contenedorDocumentos: yup.string().required(),
    correo: yup.string().required(),
    rfc: yup.string().required(),
    saasConfig: yup.object({
        url: yup.string().required(),
        usuario: yup.string().required(),
        password: yup.string().required(),
        company: yup.string().required(),
        location: yup.string().required(),
        selloDigitalId: yup.string().required(),
        serieDigital: yup.string().required(),
        satUsoCfdi: yup.string().required(),
        tipoPermisoSCT: yup.string().required(),
        numeroPermisoSCT: yup.string().required(),
    }),
    ediConfig: yup.object({
        url: yup.string().required(),
        usuario: yup.string().required(),
        password: yup.string().required(),
        carpetaLectura: yup.string().required(),
        carpetaEscritura: yup.string().required(),
    }),
    freightVerifyConfig: yup.object({
        scac: yup.string().required(),
        fvCarrierId: yup.string().required(),
        token: yup.string().required(),
    }),
    emailFacturacionSettings: yup.object({
        smtpServer: yup.string().required(),
        port: yup.number().required(),
        address: yup.string().required(),
        password: yup.string().required(),
        fromName: yup.string().required(),
    }),
});

export default EmpredaValidacionEsquema;
