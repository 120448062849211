import {Marker, Polyline} from "@react-google-maps/api";
import React, {memo} from "react";

const RutaUnidadEnMapa =  memo(({puntosRuta, marcadores, visbleMarcadores}) => {
    const getArrowIcon = (item) => {
        return {
            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            scale: 1.5,
            anchor : new window.google.maps.Point(0, 2),
            rotation: item.ori,
        }
    };

    return (<>
        <Polyline
            path={puntosRuta}
            strokeColor={'blue'}
            opacity={0.5}
        />
        { visbleMarcadores &&
            marcadores.map((item, index) => (
                <React.Fragment key={index}>
                    <Marker
                        key={index}
                        icon={getArrowIcon(item)}
                        position={item}
                    />
                </React.Fragment>
            ))
        }
    </>)
})
export default RutaUnidadEnMapa;