/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef, useState} from "react";
import {useOnClickOutside} from "../../hooks/useOnClickOutside";
import useWindowDimensions from "../../hooks/windowsDimensions";

const FieldOverlayPanel = ({value, name, label, onChange, width, error, errors, position, esMultiple = false, source = [{}], selectAll, ...props}) => {
    const ref = useRef(null);
    const [isPanelOpened, setIsPanelOpened] = useState(false);
    const windowDimensions = useWindowDimensions();
    const [nombreMultiple, setNombreMultiple] = useState('');
    const height = props.height || 260;

    const [style, setSetyle] = useState({
        position: 'absolute',
        width: width == null && esMultiple === true ? '170%' : width != null ? width : '98%',
        zIndex: 1000,
        overflow: 'auto',
        maxHeight: height + 'px',
        right: esMultiple === true ? '0px' : ''
    });

    React.useEffect(() => {
        if (ref.current) {
            const elementBottom = ref.current.getBoundingClientRect().bottom;
            const topPosition = elementBottom + height >= windowDimensions.height ? "-115%" : "0%";

            if (position === "center") {
                setSetyle({...style, left: '50%', transform: `translate(-50%, ${topPosition})`});
            } else if (position === "right") {
                setSetyle({...style, right: '0', transform: `translate(0%, ${topPosition})`});
            }

            if (esMultiple)
                actualizarNombreMultiple(value, source, selectAll);

        }
    }, []);

    useOnClickOutside(ref, () => setIsPanelOpened(false));

    const openPanel = () => {
        if (!props.disabled) {
            setIsPanelOpened(!isPanelOpened);
        }

        if (props.onClick != null) {
            props.onClick();
        }
    };

    const handleChange = (itemSelected, forzarCambio = false, index = 0) => {
        if (esMultiple) {


            const seleccionados = itemSelected.filter(element => element.esSeleccionado);
            actualizarNombreMultiple(seleccionados, itemSelected, forzarCambio);

            onChange(seleccionados, index);
            onChange(forzarCambio, index);


        }

        if (!esMultiple) {
            if (value?.id !== itemSelected.id || forzarCambio) {
                onChange(itemSelected, name);
            }
            setIsPanelOpened(false);
        }
    };


    const actualizarNombreMultiple = (lista, listaCompleta, todasRutas) => {

        if (todasRutas)
            setNombreMultiple("Todas las Rutas");
        else
            setNombreMultiple("");

        if (lista !== undefined  && lista !== null  && lista.length > 0) {
            var elemento = lista.map((item) => {
                return item.nombre;
            });


            var nombreMultiple = elemento.join(" , ");

            if (lista.length === listaCompleta.length)
                nombreMultiple = "Todas las Rutas";

            setNombreMultiple(nombreMultiple.substring(0, 35) + (nombreMultiple.length > 35 ? '...' : ''));
        }


    };

    const clearValue = () => {
        onChange(null, name);
    };

    return (
        <>
            <div className={`d-flex ${props.className ?? ''}`}>
                <div className="flex-grow-1">
                    {!!label && <label>{label}</label>}
                    <div
                        className={`position-relative custom-panel ${value ? '' : 'empty'} ${props.disabled ? 'disabled' : ''}`}
                        ref={ref}>
                        <div
                            className={`px-2 d-flex align-items-center dropdown-custom ${(error || errors?.[name]) ? 'error' : ''}`}
                            onClick={openPanel}>
                            {!esMultiple &&
                            <span className="flex-grow-1">{value?.nombre}</span>
                            }
                            {esMultiple &&
                            <span className="flex-grow-1"> {nombreMultiple}</span>
                            }
                            <i className="pi pi-angle-down"/>
                        </div>

                        {isPanelOpened &&
                        <div className="p-dropdown-panel py-2" style={style}>
                            {React.cloneElement(props.children, {selectedValue: value, onChange: handleChange})}
                        </div>
                        }
                    </div>
                </div>

                {!props.disabled && props.showClear && value != null &&
                < i className="pi pi-times cursor-pointer align-self-center pl-1" onClick={clearValue}/>
                }
            </div>
        </>
    );
};


export default FieldOverlayPanel;
