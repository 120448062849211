import React,{useEffect,useState} from "react";
import {TabPanel, TabView} from "primereact/tabview";
import useSessionState from "../../hooks/useSessionState";
import '../operaciones.css'
import Salidas from "./salidas/salidas";
import ControlPatioIpad from "./controlPatioIpad";
// import CheckListUnidades from "../ControlPatios/checkListUnidades/checklist";
import CheckListUnidades from "../ControlPatios/checkListUnidades/checklist";
import constantes from "../../catalogos/constantes";


const ControlPatios = () => {
    const [activeIndex,setActiveIndex] = useSessionState('tabControlPatios', 0);
    const [permisos,setPermisos] = useState([]);

    useEffect(() => {
        async function cargarPermisos() {
            let permisos = JSON.parse(localStorage.getItem('pestañas')).map(function(obj) {
                return obj          
            });


            setPermisos(permisos.filter(e=>e.padreId === constantes.menus.controlPatios));

        }
    
        cargarPermisos();
      }, []);


    return (
        <>
        {permisos.length >  0 && 
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="main-tab-view">         
                <TabPanel headerStyle ={ permisos.some(e=>e.id === constantes.menus.accesosSalidas)?  {display:"inline"} : {display:"none"} } header="Accesos &#38; Salidas">
                    <Salidas/>
                </TabPanel>
                <TabPanel headerStyle ={ permisos.some(e=>e.id === constantes.menus.ipad)?  {display:"inline"} : {display:"none"} }  header="IPAD">
                    <ControlPatioIpad/>
                </TabPanel>
                <TabPanel headerStyle ={ permisos.some(e=>e.id === constantes.menus.checkUnidades)?  {display:"inline"} : {display:"none"} }   header="Checklist Unidades">
                    <CheckListUnidades/>
                </TabPanel>
            </TabView>
         }
         </>
    )
};

export default ControlPatios;
