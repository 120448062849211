import useServiceFactory from "../serviceFactory";

const apiUrl = `/operaciones/checkUnidad`;

const useApiCheckUnidad = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, { params }),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, { showLoading: true }),
        guardar: async () => service.post(`${apiUrl}`, {}, { showSuccess: false }),
        guardarFoto: async (id, params) => service.put(`${apiUrl}/${id}/checkListGuardarFoto`, params, { showSuccess: false }),
        eliminarFoto: async (id, rutaFoto) => service.put(`${apiUrl}/${id}/eliminarCheckList/${rutaFoto}`),
        actualizar: async (id, params) => service.put(`${apiUrl}/${id}`, params),
        obtenerInformacion: async (unidadId) => service.get(`${apiUrl}/obtenerInformacion/unidad/${unidadId}`, { showLoading: true }),
        guardarFirma: async (id, params) => service.put(`${apiUrl}/${id}/checkListGuardarFirma`, params, { showSuccess: false })
    }
};

export default useApiCheckUnidad;
