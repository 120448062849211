import React from "react";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import FieldCalendarRange from "../../../shared/controls/fieldCalendarRange";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import FieldAutoComplete from "../../../shared/controls/fieldAutoComplete";

const FiltroAlmacen = ({filtro, onChange}) => {
    return (
        <>
            <FieldTextBox name="descripcion" label="Descripción" colMd={2} maxLength={15}
                          value={filtro.descripcion} onChange={onChange}/>

            <FieldAutoComplete name="unidad" source="unidades" label="No. Económico" colMd={1} isString={true}
                params={{ id: 0, todasLasEmpresas: true }} value={filtro.unidad} onChange={onChange} />                          

            <FieldCalendarRange name="fechas" label="Fechas" colMd={3}
                                value={filtro.fechas} onChange={onChange}/>

            <FieldDropDown name="empleadoId" label="Empleado" source="empleados" valueById={true}
                          value={filtro.empleadoId} onChange={onChange} colMd={3}/>
                              
            <FieldTextBox name="codigoArticulo" label="Código de artículo" colMd={2} maxLength={50}
                          value={filtro.codigoArticulo} onChange={onChange}/>

        </>
    )
};

export default FiltroAlmacen;
