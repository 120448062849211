import {useControlMap} from "../ControlMapProvider";
import {Button} from "primereact/button";
import React, {memo, useEffect, useState} from "react";
import useApiGeocercas from "../../../../api/catalogos/useApiGeocercas";
import {Tree} from "primereact/tree";
import {InputText} from "primereact/inputtext";
import _ from "lodash";

let timer = null;

const SeleccionGeocerca =memo( ()=>{
    const {seleccionGeocercaVisible,ocultarSeleccionGeocerca, setGeocercasVisibles,geocercas, setGeocercas,selected, setSelected} = useControlMap()
    const [tree, setTree] = useState()
    const [geocercasMap, setGeocercasMap] = useState(new Map())
    const [grupos, setGrupos] = useState([])
    const [filtro, setFiltro] = useState('')
    const api = useApiGeocercas()

    const load = async ()=>{
        const result = await api.obtenerActivas();
        setGeocercas(result.geocercas)
        setGrupos(result.grupos)
        crearArbolGeocercas(result.geocercas, result.grupos);
        const map = new Map(result.geocercas.map(e=> [e.id, e]))
        setGeocercasMap(map)
    }

    const crearArbolGeocercas = (geocercas, grupos) => {
        let agrupados = _.groupBy(geocercas, 'geocercaGrupoId' )

        let tree = []

        for (let grupoId in agrupados) {
            const grupo = grupos.find(e => e.id === parseInt(grupoId));
            const grupoGeocercas = agrupados[grupoId]
            const item = {
                key : `${grupoId}`,
                label: `${grupo?.nombre} (${grupoGeocercas.length})`,
                children:[]
            }
            for (let actual of grupoGeocercas) {
                const child = {
                    key : `${grupoId}_${actual.id}`,
                    label: actual.nombre,
                    cajas: actual.cajas,
                    unidades: actual.unidades,
                }
                item.children.push(child)
            }
            tree.push(item)
        }
       setTree(tree)
    }

    useEffect(() => {
        load().then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!seleccionGeocercaVisible) {
        return null
    }

    const handleSelected = (ids)=>{
        const seleccionadas = []
        for (let property in ids){
            const splitted =property.split("_")
            if (splitted.length <= 1)
                continue
            const geocerca = geocercasMap.get(parseInt(splitted[1]))
            if (geocerca == null)
                continue
            seleccionadas.push(geocerca)
        }
        setGeocercasVisibles(seleccionadas)
        setSelected(ids)
    }

    const onFilter =(text)=>{
        if (text.trim().length === 0 ){
            crearArbolGeocercas(geocercas, grupos)
            return
        }
        text = text.toLowerCase()
        crearArbolGeocercas(geocercas.filter(e=>e.nombre.toLowerCase().includes(text)), grupos)
    }

    const handleChangeFilter = (text)=>{
        setFiltro(text)
        if (timer != null)
            clearTimeout(timer)

        timer = setTimeout(()=>{
            onFilter(text)
        },500)
    }

    const  nodeTemplate = (node, options) => {
        const unidades = node.unidades > 0 ? `Unidades: ${node.unidades}` : null
        const cajas = node.cajas > 0 ? `Cajas: ${node.cajas}`: null

        return (
            <div style={{display:'flex', flexDirection:'column', fontSize:'0.9em'}}>
                <span className={unidades || cajas ? 'con-unidades':''}>
                    {node.label}
                </span>
                {(unidades || cajas) &&
                <span className={'unidades-cajas'}>
                    {unidades} {cajas}
                </span>
                }
            </div>
        )
    }


    return (
        <div className={'seg-modal'} style={{width:'400px'}}>
            <div className={'seg-dialog-header'}>
                <div className={'seg-dialog-title'}>
                    Mostrar/Ocultar Geocercas
                </div>
                <Button icon="pi pi-times" className="p-button-rounded p-button-text seg-dialog-close" onClick={ocultarSeleccionGeocerca} />
            </div>

            <div className="p-fluid form-value">
                <span className="p-input-icon-left">
                    <i className="pi pi-search"/>
                    <InputText value={filtro} onChange={(e) => handleChangeFilter(e.target.value)} width={'100%'}/>
                </span>
            </div>


            <div className={'form-container seg-seleccion-geocercas'}>
                <Tree value={tree} selectionMode="checkbox" selectionKeys={selected}
                      nodeTemplate={nodeTemplate}
                      onSelectionChange={e => handleSelected(e.value)}/>
            </div>
        </div>
    )
})
export default SeleccionGeocerca;