/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "primereact/button";
import { useEffect, useReducer } from "react";
import FieldCheckBox from "../../shared/controls/fieldCheckBox";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import formReducer from "../../shared/forms/formReducer";
import useApiMarcaLlanta from "../../api/catalogos/useApiMarcaLlanta";
import FormHandler from "../../shared/forms/formHandler";
import MarcaLlantaValidacionEsquema from "./MarcaLlantaValidacionEsquema";
import ReadOnlyProvider from "../../shared/forms/ReadOnlyProvider";

const MarcaLlantaDetalle = ({ marcaLlantaId, onSave, soloLectura }) => {
    const api = useApiMarcaLlanta();
    const defaultEntity = () => ({ entity: { id: 0, esMarcaInterna: false } });

    const [{ entity, errors }, dispatch] = useReducer(
        formReducer, {}, defaultEntity
    );

    const formHandler = new FormHandler({
        validationSchema: MarcaLlantaValidacionEsquema,
        api,
        dispatch
    });

    useEffect(() => {
        async function cargar() {
            const marcaLlanta = await api.obtener(marcaLlantaId);

            dispatch({ type: "update", value: marcaLlanta });
        }

        if (marcaLlantaId > 0) {
            cargar().then();
        }
    }, []);

    const guardar = async () => {
        await formHandler.save(entity);

        if (formHandler.valdation.result) {
            onSave();
        }
    };

    const actualizar = (value, propertyName) => {
        dispatch({
            type: 'update',
            value: value,
            name: propertyName
        });
    };

    return (
        <>
            <div className="form-container full-height">
                <h5>Marcas de Llantas</h5>
                <ReadOnlyProvider defaultReadOnly={soloLectura}>
                    <div className="form-row">
                        <FieldTextBox name="nombre" label="Nombre" colMd={12} required value={entity.nombre}
                            onChange={actualizar} errors={errors} />
                    </div>
                    <div className="form-row">
                        <FieldCheckBox name="esMarcaInterna" label="Es Marca Interna" value={entity.esMarcaInterna} onChange={actualizar} />
                    </div>
                </ReadOnlyProvider>
                <div className="form-footer">
                    {!soloLectura &&
                        <Button label="Guardar" type="button" onClick={guardar} />
                    }
                </div>
            </div>
        </>
    );
};

export default MarcaLlantaDetalle;