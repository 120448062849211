import React from "react";
import {
    IoMdCopy,
    IoIosAddCircle,
    IoIosArrowDropdownCircle,
    IoIosArrowDroprightCircle,
    IoIosNotificationsOutline,
} from "react-icons/io";

import {
    IoNotificationsOutline,
    IoDuplicateOutline,
    IoDocumentText,
    IoDocumentTextOutline,
    IoClose,
    IoOptionsOutline,
    IoMap,
    IoScanOutline,
    IoRadioSharp,
    IoPersonRemove, IoPersonSharp
} from "react-icons/io5";
import {
    FaSignOutAlt,
    FaCheckCircle,
    FaRegEdit,
    FaRegCheckCircle,
    FaObjectGroup,
    FaReceipt,
    FaWarehouse,
    FaDollarSign,
    FaCheck,
    FaWrench,
    FaRegUser,
    FaFileExcel,
    FaChartBar,
    FaRoute
} from "react-icons/fa";
import {SiAddthis, SiMicrosoftoutlook} from "react-icons/si";
import {FcClearFilters, FcCancel, FcFilledFilter} from "react-icons/fc";
import {
    BsSearch,
    BsFillCalendarFill,
    BsFunnel,
    BsArrowsMove,
    BsCheck,
    BsChevronLeft,
    BsChevronRight,
    BsArrowRepeat,
} from "react-icons/bs";
import {
    BiArrowBack,
    BiCheckCircle,
    BiHistory,
    BiMerge,
    BiReceipt,
    BiMessageRounded,
    BiMessageRoundedDetail
} from "react-icons/bi";
import {
    AiOutlineSave,
    AiOutlineMail,
    AiOutlineFileDone,
    AiFillDollarCircle,
    AiFillApi,
    AiOutlineFile,
} from "react-icons/ai";
import {
    MdExpandMore,
    MdExpandLess,
    MdAddBox,
    MdAttachFile,
    MdCallSplit,
    MdClose,
    MdOutlineScanner, MdScanner,
} from "react-icons/md";
import {GoLink, GoChecklist} from "react-icons/go";
import {RiDeleteBinLine, RiErrorWarningLine, RiRefreshLine} from "react-icons/ri";
import {BiDownload, BiPrinter, BiListCheck, BiEraser, BiUpload} from "react-icons/bi";
import {ImLocation2} from "react-icons/im";
import {VscTools, VscLocation} from "react-icons/vsc";
import {GrMapLocation, GrFormView, GrCircleAlert} from "react-icons/gr";
import {FcEmptyBattery} from "react-icons/fc";
import {GiCarWheel} from "react-icons/gi";
import {CgFolderAdd} from "react-icons/cg";

const IconLibrary = (props) => {
    const icons = {
        IoNotificationsOutline: <IoNotificationsOutline/>,
        IoDuplicateOutline: <IoDuplicateOutline/>,
        IoMdCopy: <IoMdCopy/>,
        GiCarWheel: <GiCarWheel/>,
        FaSignOutAlt: <FaSignOutAlt/>,
        FaCheckCircle: <FaCheckCircle/>,
        FaRegEdit: <FaRegEdit/>,
        FaRegUser: <FaRegUser/>,
        SiAddthis: <SiAddthis/>,
        BiHistory: <BiHistory/>,
        SiMicrosoftoutlook: <SiMicrosoftoutlook/>,
        IoIosAddCircle: <IoIosAddCircle/>,
        FcClearFilters: <FcClearFilters/>,
        FcCancel: <FcCancel/>,
        BsFillCalendarFill: <BsFillCalendarFill/>,
        BiArrowBack: <BiArrowBack/>,
        BiCheckCircle: <BiCheckCircle/>,
        BsSearch: <BsSearch/>,
        AiOutlineSave: <AiOutlineSave/>,
        AiFillDollarCircle: <AiFillDollarCircle/>,
        AiFillApi: <AiFillApi/>,
        BiMerge: <BiMerge/>,
        AiOutlineMail: <AiOutlineMail/>,
        AiOutlineFileDone: <AiOutlineFileDone/>,
        MdExpandMore: <MdExpandMore/>,
        MdExpandLess: <MdExpandLess/>,
        GoLink: <GoLink/>,
        RiDeleteBinLine: <RiDeleteBinLine/>,
        RiRefreshLine: <RiRefreshLine/>,
        BiDownload: <BiDownload/>,
        BiEraser: <BiEraser/>,
        GrMapLocation: <GrMapLocation/>,
        FaReceipt: <FaReceipt/>,
        BiListCheck: <BiListCheck/>,
        FaObjectGroup: <FaObjectGroup/>,
        RiErrorWarningLine: <RiErrorWarningLine/>,
        MdAddBox: <MdAddBox/>,
        MdAttachFile: <MdAttachFile/>,
        FaRegCheckCircle: <FaRegCheckCircle/>,
        IoDocumentText: <IoDocumentText/>,
        IoDocumentTextOutline: <IoDocumentTextOutline/>,
        BiPrinter: <BiPrinter/>,
        GoChecklist: <GoChecklist/>,
        ImLocation2: <ImLocation2/>,
        BsFunnel: <BsFunnel/>,
        BsArrowsMove: <BsArrowsMove/>,
        FcFilledFilter: <FcFilledFilter/>,
        IoIosArrowDropdownCircle: <IoIosArrowDropdownCircle/>,
        IoIosArrowDroprightCircle: <IoIosArrowDroprightCircle/>,
        VscTools: <VscTools/>,
        VscLocation: <VscLocation/>,
        IoIosNotificationsOutline: <IoIosNotificationsOutline/>,
        BsCheck: <BsCheck/>,
        GrFormView: <GrFormView/>,
        FaWarehouse: <FaWarehouse/>,
        FcEmptyBattery: <FcEmptyBattery/>,
        IoClose: <IoClose/>,
        FaDollarSign: <FaDollarSign/>,
        FaCheck: <FaCheck/>,
        CgFolderAdd: <CgFolderAdd/>,
        MdCallSplit: <MdCallSplit/>,
        AiOutlineFile: <AiOutlineFile/>,
        BsChevronLeft: <BsChevronLeft/>,
        BsChevronRight: <BsChevronRight/>,
        BiUpload: <BiUpload/>,
        BiReceipt: <BiReceipt/>,
        BsArrowRepeat: <BsArrowRepeat/>,
        FaWrench: <FaWrench/>,
        MdClose: <MdClose/>,
        IoOptionsOutline: <IoOptionsOutline/>,
        IoMap: <IoMap/>,
        MdScanner: <MdScanner/>,
        MdOutlineScanner: <MdOutlineScanner/>,
        IoScanOutline: <IoScanOutline/>,
        GrCircleAlert: <GrCircleAlert/>,
        IoRadioSharp: <IoRadioSharp/>,
        FaFileExcel: <FaFileExcel/>,
        IoPersonRemove: <IoPersonRemove/>,
        IoPersonSharp: <IoPersonSharp/>,
        FaChartBar: <FaChartBar/>,
        BiMessageRounded: <BiMessageRounded/>,
        BiMessageRoundedDetail: <BiMessageRoundedDetail/>,
        FaRoute: <FaRoute/>
    };

    return icons[props.iconName] || <div style={{fontSize: 9}}>no icon</div>;
};

export default IconLibrary;

/*
const Icon = props => (
    <svg width="22" height="22" viewBox="0 0 1024 1024">
        <path d={icons[props.icon]}></path>
    </svg>
);

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
};

export default Icon;*/
