import React, {useState} from "react";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import FieldTextArea from "../../../shared/controls/fieldTextArea";
import useApiEmpleados from "../../../api/catalogos/useApiEmpleados";
import FieldCalendar from "../../../shared/controls/fieldCalendar";
import moment from "moment";
import alerts from "../../../shared/alerts";


const BajaEmpleado = ({empleado, onBaja}) => {
    const [visibleModal, setVisibleModal] = useState(false);
    const [tipo, setTipo] = useState(null);
    const [causa, setCausa] = useState(null);
    const [fechaBaja, setFechaBaja] = useState(moment().startOf('day').toDate());
    const [comentarios, setComentarios] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const apiEmpleado = useApiEmpleados();

    
    const revisionMovs = async () => {
        setSubmitted(true);
        const responseliquidacion = await apiEmpleado.liquidacionPendientes(empleado.id);
        if (responseliquidacion.count>0) {         
            if (await alerts.preguntarSiNoAdvertencia('¿Esta seguro de dar de baja el empleado?, tiene pagos pendientes por dispersar.')) {
                await baja();
            }
        }   
        const responseMovimientos = await apiEmpleado.movimientosPendientes(empleado.id);
        if (responseMovimientos.count>0) {         
            if (await alerts.preguntarSiNoAdvertencia('¿Esta seguro de dar de baja el empleado?, tiene movimientos pendientes de liquidar.')) {
                await baja();
                await quitarMovimientos();
            }
        }else{
            await baja();
        }         
    };
    const baja = async () => {
        setSubmitted(true);
        if ((tipo?.id ?? 0) === 0 || fechaBaja == null) {
            return;
        }
        await apiEmpleado.baja(empleado.id, {tipo, causa,comentarios, fechaBaja});
        await apiEmpleado.envioCorreo(empleado.id,{tipo, causa,comentarios, fechaBaja, alta:false});        
        setSubmitted(false);
        setVisibleModal(false);
        onBaja();
    };
    const quitarMovimientos = async () => {
        setSubmitted(true);        
        await apiEmpleado.rechazarMovimientos(empleado.id);
        onBaja();
    };

    const renderFooter = () => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => setVisibleModal(false)} className="p-button-text"/>
                <Button label="Confirmar" icon="pi pi-check" onClick={revisionMovs} autoFocus/>
            </div>
        );
    };

    return (
        <>
            <Dialog header="¿Desea dar de baja al personal?" visible={visibleModal} style={{width: '30vw'}}
                    footer={renderFooter('displayBasic')} onHide={() => setVisibleModal(false)}>
                <div className="form-row" style={{overflow:'hidden'}}>
                    <FieldCalendar name="fechaBaja" label="Fecha" colMd={6} className="small"
                                   error={fechaBaja == null  && submitted} showButtonBar={false}
                                   filter
                                   value={fechaBaja}
                                   onChange={setFechaBaja}/>
                    <FieldDropDown name="tipo" label="Tipo de Baja" colMd={12} source="tipoBaja"
                                   error={(tipo?.id ?? 0) === 0 && submitted}
                                   scrollHeight={150}
                                   filter
                                   value={tipo}
                                   onChange={setTipo}/>
                    <FieldDropDown name="causa" label="Causa de Baja" colMd={12} source="causaBaja"
                                   error={(causa?.id ?? 0) === 0 && submitted}
                                   scrollHeight={150}
                                   filter
                                   value={causa}
                                   onChange={setCausa}/>               
                    <FieldTextArea name="comentarios" label="Comentarios" colMd={12} rows={6}
                                   value={comentarios} onChange={setComentarios}/>

                </div>
            </Dialog>

            <Button label="Dar de Baja" className="p-button-outlined p-button-danger" type="button"
                    onClick={() => setVisibleModal(true)}/>
        </>
    )
};

export default BajaEmpleado;
