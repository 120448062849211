/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState} from "react";
import "../administracion.css";
import useApiLiberacionViaje from "../../api/administracion/useApiLiberacionViaje";
import {useEffect} from "react";
import {FormattedDate} from "react-intl";
import moment from "moment";
import {Checkbox} from "primereact/checkbox";
import {produce} from "immer";
import {Button} from "primereact/button";
import constantesAdministracion from "../../administracion/constantes";
import alerts from "../../shared/alerts";
import PdfViewer from "../../shared/componentes/pdfViewer";
import constantes from "../../catalogos/constantes";
import useSeguridad, {opcionesSeguridad} from "../../Seguridad/useSeguridad";
import FieldTextArea from "../../shared/controls/fieldTextArea";
import FieldScanner from "../../shared/controls/fieldScanner";
import DocumentoHistorial from "./DocumentoHistorial";
import RechazoDocumentos from "./rechazo";
import FieldDropDown from "../../shared/controls/fieldDropDown";
import usePersistedState from "../../hooks/usePersistedState";
import ExGrid from "../../shared/Grid/ExGrid";
import ExColumn from "../../shared/Grid/ExColumn";
import { TabPanel, TabView } from "primereact/tabview";
import {fechaUtc} from "../../shared/Grid/GridCellRenderers";
const DetalleLiberacionViaje = ({viajeId, onSave, actualizarViajes, close, soloLectura}) => {
    const api = useApiLiberacionViaje();
    const [viaje, setViaje] = useState({});
    const [pdfReciboValorVisible, setPdfReciboValorVisible] = useState(false);
    const estatus = constantesAdministracion.estatusLiberacion;
    const estatusViajes = constantesAdministracion.estatusViajes;
    const [pdfCartaPorteVisible, setPdfCartaPorteVisible] = useState(false);
    const seguridad = useSeguridad();
    const [permisoLiberacionViaje, setPermisoLiberacionViaje] = useState(false);
    const [documentoId, setDocumentoId] = useState(null);
    const [visibleRechazoModal, setVisibleRechazoModal] = useState(false); //doc
    const [id, setId] = useState(null);

    const [visibleEscanear, setVisibleEscanear] = useState()
    const {tieneAcceso} = useSeguridad();
    useEffect(() => {
        setVisibleEscanear(tieneAcceso(opcionesSeguridad.escanear))
    }, []);

    const [escaner, setEscaner] = usePersistedState('escanerSeleccionado', null)

    async function cargar() {
        let permisoLiberacionViaje = seguridad.tieneAcceso(constantes.opciones.LiberarViaje);
        setPermisoLiberacionViaje(permisoLiberacionViaje);
        const viaje = await api.obtener(viajeId);

        setViaje(viaje);
    }

    useEffect(() => {
        cargar().then();
    }, []);

    const actualizar = (value, propertyName, index) => {
        const newValue = produce(viaje.documentos, (draft) => {
            draft[index][propertyName] = value;
        });

        setViaje({...viaje, documentos: newValue});
    };

    const obtenerFormData = () => {
        const formData = new FormData();
        const totalDocumentos = viaje.documentos.length;

        for (let i = 0; i < totalDocumentos; i++) {
            const archivo = viaje.documentos[i].archivo;

            formData.append("files", archivo?.file ?? new File([""], "vacio"));
        }

        formData.append("documentosJson", JSON.stringify(viaje.documentos));

        return formData;
    };

    const GuardarDocumentos = async () => {
        await api.actualizarDocumentos(viajeId, obtenerFormData());
        onSave();
    };

    const generarReciboValor = async () => {
        await api.generarReciboValor(viajeId, obtenerFormData());

        cargar().then();
        actualizarViajes();
    };

    const liberar = async () => {

        if (viaje.documentos?.some((e) => !e.entregado)) {
            alerts.aviso("Todos los documentos deben tener el check Ok");
            return;
        }
        await api.liberar(viajeId, obtenerFormData());
        onSave();
    };

    const descargarPdf = async () => {
        await api.pdf(viajeId);
        setPdfReciboValorVisible(false);
    };

    const descargarCartaPorte = async () => {
        await api.descargar(viajeId, viaje.cartaPorte);
        setPdfCartaPorteVisible(false);
    };

    const eliminar = async (item) => {
        await api.eliminarArchivo(viajeId, item.id);
    }

    const onApprove = async (documentoId) => {
        //alert('aprobado' + documentoId)
        await api.validar(documentoId, viajeId);
        cargar().then();
    }

    const onReject = (documentoId) => {
        //alert('rechazado' + documentoId);
        setId(documentoId);
        setVisibleRechazoModal(true);
    }

    const cerrarModalComentarios = () => {
        setVisibleRechazoModal(false);
        cargar().then();
    };

    return (
        <>
        <TabView activeIndex={0}>
            <TabPanel header="VIAJE">
                <div className="form-container full-height">
                    <div className="bg-light p-3">
                        <div className="d-flex justify-content-between">
                            <div>
                                <b>{viaje.operador}</b>
                            </div>

                            <div>
                                <div className="text-center">
                                    <b>RV</b>
                                </div>
                                {viaje.estatus !== estatus.porGenerarRv && (
                                    <div className="d-flex">
                                        <i className="pi pi-paperclip pr-1"/>
                                        <span>{viaje.usuarioGeneradorRv}</span>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="adm-resumen liberacion">
                            <div>
                                <div>
                                    <b>Fecha</b>
                                </div>
                                <div>
                                    <FormattedDate value={viaje.fecha} weekday="short"/> &nbsp;
                                    {moment(viaje.fecha).format("DD/MM/YYYY")}
                                </div>
                            </div>

                            <div style={{gridColumn: "2 span"}}>
                                <div>
                                    <b>Viaje</b>
                                </div>
                                <div>{viaje.cliente}</div>
                                <div className="d-flex flex-wrap align-items-center">
                                    {viaje.rutas?.map((r, index) => {
                                        return (
                                            <React.Fragment key={r.id}>
                                                <div>{r.nombre}</div>
                                                {index < viaje.rutas.length - 1 && (
                                                    <i className="pi pi-arrow-right px-1" style={{fontSize: "0.6rem"}}/>
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            </div>

                            <div style={{gridColumn: "1 span"}}>
                                {viaje.esSegmento && (
                                    <>
                                        <div>
                                            <b>Segmentos</b>
                                        </div>
                                        <div>{viaje.infoSegmento}</div>
                                    </>
                                )}
                            </div>

                            <div style={{gridColumn: "1 span"}}>
                                <div>
                                    <b>Convenio / Expeditado</b>
                                </div>
                                <div>{viaje?.convenio}</div>
                            </div>
                            <div>
                                <div>
                                    <b>Carta Porte</b>
                                </div>
                                <div>{viaje.cartaPorte}</div>
                            </div>

                            <div>
                                <div>
                                    <b>Comisión</b>
                                </div>
                                <div>$ {viaje.comision}</div>
                            </div>

                            <div>
                                <div>
                                    <b>Unidad</b>
                                </div>
                                <div>{viaje.unidad?.numeroEconomico}</div>
                                <div>{viaje.unidad?.placas}</div>
                            </div>

                            <div>
                                <div>
                                    <b>Caja</b>
                                </div>
                                <div>{viaje.caja}</div>
                            </div>

                            <div>
                                <div>
                                    <b>KM</b>
                                </div>
                                <div>{viaje.kilometros}</div>
                            </div>
                        </div>
                    </div>
                
                        <div className="mt-4 liberacion-documentos">
                            <table className="table align-middle">
                                <thead className="thead-light">
                                <tr>
                                    <th className="center" style={{width: "20%"}}>
                                        Stop
                                    </th>
                                    <th className="center" style={{width: "15%"}}>
                                        Documento
                                    </th>
                                    <th className="center" style={{width: "15%"}}>
                                        Nombre
                                    </th>
                                    <th className="center" style={{width: "15%"}}>
                                        Especificaciones
                                    </th>
                                    <th className="center" style={{width: "20%"}}>
                                        Comentario
                                    </th>
                                    <th style={{width: "7%"}}></th>
                                    <th style={{width: "7%"}}>Documento</th>
                                    <th className="center" style={{width: "5%"}}>
                                        Ok
                                    </th>
                                    <th>
                                        Historial
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {viaje.documentos?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.ruta}</td>
                                            <td className="input center">{item.documentoCategoriaId?.nombre}</td>
                                            <td className="input center">{item.descripcion}</td>
                                            <td className="input center">{item.documentoEspecificacionesId}</td>
                                            <td className="input">{item.comentarios}</td>
                                            <td className="input">
                                                {/*{item.anexo !== null && (*/}
                                                {/*    <InputFile*/}
                                                {/*        name="anexo"*/}
                                                {/*        value={item.anexo}*/}
                                                {/*        disabled={true}*/}
                                                {/*        onChange={(value, pName) => actualizar(value, pName, index)}*/}
                                                {/*    />*/}
                                                {/*)}*/}
                                            </td>
                                            <td>
                                                <FieldScanner
                                                    scanApiUrl={'administracion/liberacion/escanear'}
                                                    uploadFileUrl={`administracion/liberacion/archivo/${viajeId}/${item.id}`}
                                                    params={{viajeId, documentoId: item.id}}
                                                    value={item.archivo}
                                                    onChange={(value, pName) => actualizar(value, pName, index)}
                                                    onEliminar={() => eliminar(item)}
                                                    disabled={viaje.deshabilitado}
                                                    showApprove={!item.entregado && item.estatusDocumentos !== constantes.estatusDocumentos.RECHAZADO}
                                                    onApprove={() => onApprove(item.id)}
                                                    onReject={() => onReject(item.id)}
                                                    escanerId={escaner?.id}
                                                    visibleEscanear={visibleEscanear}
                                                >
                                                </FieldScanner>
                                            </td>
                                            <td className="center">
                                                <Checkbox
                                                    className="pt-2"
                                                    name="entregado"
                                                    checked={item.entregado}
                                                    onChange={(e) => actualizar(e.checked, "entregado", index)}
                                                    readOnly={viaje.deshabilitado || soloLectura}
                                                />
                                            </td>
                                            <td>
                                                <button className="btn btn-link btn-sm" title={'Ver'}
                                                        onClick={() => setDocumentoId(item.id)}>
                                                    Ver
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td></td>
                                    <th className={'center'}>
                                        Escaneo Adicional
                                    </th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <FieldScanner
                                            scanApiUrl={'administracion/liberacion/escanear'}
                                            uploadFileUrl={`administracion/liberacion/archivo/${viajeId}/0`}
                                            params={{viajeId, documentoId: 0}}
                                            value={viaje.escaneoGeneral}
                                            onEliminar={() => eliminar({id: 0})}
                                            disabled={viaje.deshabilitado}
                                            onApprove={() => onApprove(0)}
                                            onReject={() => onReject(0)}
                                            escanerId={escaner?.id}
                                            visibleEscanear={visibleEscanear}
                                        >

                                        </FieldScanner>
                                    </td>
                                    <td></td>
                                    <td>
                                        <button className="btn btn-link btn-sm" title={'Ver'} onClick={() => setDocumentoId(0)}>
                                            Ver
                                        </button>
                                    </td>
                                </tr>
                                {visibleEscanear &&
                                    <tr>
                                        <td colSpan="4"></td>
                                        <td colSpan={2}>
                                        <span style={{
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            justifyContent: 'flex-end',
                                            height: '45px',
                                            paddingRight: '10px'
                                        }}>
                                            Escaner
                                        </span>
                                        </td>
                                        <td colSpan="3">

                                            <FieldDropDown value={escaner}
                                                        onChange={setEscaner}
                                                        source={"escaners"}
                                                        className={'compact'}
                                                        filter={false}
                                            />
                                        </td>
                                    </tr>
                                }
                                </tfoot>
                            </table>
                        </div>
                
                    {viaje.comentariosRechazo !== "" && (
                        <div className="form-row">
                            <FieldTextArea
                                label={"Comentarios de rechazo"}
                                colMd={12}
                                disabled={true}
                                rows={5}
                                value={viaje.comentariosRechazo}
                            />
                        </div>
                    )}
                </div>

                <div className="form-footer">
                    <Button label="Salir" className="p-button-outlined" onClick={close}/>

                    {!soloLectura && (
                        <>
                            {viaje.cartaPorte > 0 && (
                                <div className="flex-grow-1">
                                    <Button
                                        label="Descargar carta porte"
                                        className="p-button-outlined"
                                        type="button"
                                        onClick={() => setPdfCartaPorteVisible(true)}
                                    />
                                </div>
                            )}
                            {viaje.estatus !== estatus.porGenerarRv && (
                                <Button
                                    label="Ver RV"
                                    className="p-button-outlined"
                                    type="button"
                                    onClick={() => setPdfReciboValorVisible(true)}
                                />
                            )}

                            {!viaje.deshabilitado && (
                                <Button className="btn-primary" label="Guardar" type="button" onClick={GuardarDocumentos}/>
                            )}

                            {viaje.estatus === estatus.porGenerarRv && viaje.estatusViaje === estatusViajes.terminado && (
                                <Button label="Generar RV" type="button" onClick={generarReciboValor}/>
                            )}

                            {!viaje.deshabilitado && permisoLiberacionViaje && viaje.permiteLiberacionViaje && (
                                <Button label="Liberar" type="button" onClick={liberar}/>
                            )}
                        </>
                    )}
                </div>
            </TabPanel>
            <TabPanel header={"Historial"}>
                    <ExGrid data={viaje.historial ?? []}>
                        <ExColumn title={'Accion'} field={'accion'}></ExColumn>
                        <ExColumn title={'Usuario'} field={'auditUser'}></ExColumn>
                        <ExColumn title={'Fecha'} field={'fechaUtc'} cellRenderer={fechaUtc} ></ExColumn>
                        <ExColumn title={"Comentarios"} field={'comentarios'}></ExColumn>
                    </ExGrid>
                </TabPanel>
            </TabView>
            {/*Modal con la vista previa de la carta porte*/}
            <PdfViewer
                header="RV"
                urlApi={`administracion/liberacion/${viajeId}/pdfReciboValor`}
                descargar={descargarPdf}
                visible={pdfReciboValorVisible}
                hide={() => setPdfReciboValorVisible(false)}
            />

            {/*Modal con la vista previa de la carta porte*/}
            {viaje.id > 0 && (
                <PdfViewer
                    header="Carta Porte"
                    urlApi={`operaciones/viajes/programacion/${viajeId}/pdfCartaPorte`}
                    descargar={descargarCartaPorte}
                    visible={pdfCartaPorteVisible}
                    hide={() => setPdfCartaPorteVisible(false)}
                />
            )}

            <DocumentoHistorial viajeId={viajeId} documentoId={documentoId}
                                onClose={() => setDocumentoId(null)}></DocumentoHistorial>

            <RechazoDocumentos visibleRechazoModal={visibleRechazoModal} setVisibleRechazoModal={setVisibleRechazoModal}
                               viajeId={viajeId} idDoc={id}
                               onSave={cerrarModalComentarios}/>
        </>
    );
};

export default DetalleLiberacionViaje;
