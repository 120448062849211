import * as Swal from "sweetalert2";
import swal from "sweetalert2";
const title = '¿Desea salir de la pantalla?';
const sePerderanLosCambios = 'Se perderán los cambios que ha realizado';

function preguntarSiDeseaSalirSinGuardar(){
    return new Promise((resolve) => {
        Swal.fire({
            title: title,
            text: sePerderanLosCambios,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Salir sin Guardar',
            cancelButtonText: 'Seguir Modificando'
        }).then((result) => {
            if (result.value) {
                resolve(true);
            }else{
                resolve(false);
            }
        })
    });
}

function inputCartaPorte(title){
    return new Promise((resolve) => {
        Swal.fire({
            title: title,
            input: 'number',
            showCancelButton: true,
            inputAttributes: {
                min: 0,
                max: 999999
             }
        }).then((result) => {
            if(result) {
                resolve(result.value);
            }
        })
    });
}

function inputNumero(title,min,max){
    return new Promise((resolve) => {
        Swal.fire({
            title: title,
            input: 'number',
            showCancelButton: true,
            inputAttributes: {
                min: min ?? 0,
                max: max ?? 99999
             }
        }).then((result) => {
            if(result) {
                resolve(result.value);
            }
        })
    });
}

function preguntarSiNo(pregunta){
    return new Promise((resolve) => {
        Swal.fire({
            title: pregunta,
            //text: sePerderanLosCambios,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                resolve(true);
            }else{
                resolve(false);
            }
        })
    });
}

function preguntarSiNoAdvertencia(pregunta){
    return new Promise((resolve) => {
        Swal.fire({
            title: pregunta,
            //text: sePerderanLosCambios,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                resolve(true);
            }else{
                resolve(false);
            }
        })
    });
}

function seleccionarCotizacion() {
    swal.fire({
        titleText: 'Aviso',
        text: 'Debe seleccionar una cotización.',
        type: 'warning',
    });
}

function seleccionarRuta() {
    swal.fire({
        titleText: 'Aviso',
        text: 'Debe seleccionar una ruta.',
        type: 'warning',
    });
}

function aviso(mensaje) {
    swal.fire({
        titleText: 'Aviso',
        text: mensaje,
        type: 'warning',
    });
}

function informacion(mensaje) {
    swal.fire({
        titleText: 'Aviso',
        text: mensaje,
        type: 'info',
    });
}

const alerts = {
    preguntarSiDeseaSalirSinGuardar,
    seleccionarCotizacion,
    seleccionarRuta,
    aviso,
    preguntarSiNo,
    preguntarSiNoAdvertencia,
    inputCartaPorte,
    inputNumero,
    informacion
};

export default alerts;