/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import FieldTextArea from "../../shared/controls/fieldTextArea";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import useApiOrderToCash from "../../api/operaciones/useApiOrderToCash";
import {pendientesPorSource} from "./OrderToCashConstants";

const CancelarFecha = ({viajeSeleccionado, onHide, onCanceled}) => {
    const visible = viajeSeleccionado != null;
    const  api = useApiOrderToCash();
    const [submitted, setSubmitted] = useState(false);
    const [viaje, setViaje] = useState(false);
    const [titulo, setTitulo] = useState('');

    useEffect(()=>{
        setViaje(viajeSeleccionado);
        setTitulo(obtenerTitulo(viajeSeleccionado));
        setSubmitted(false);
    },[viajeSeleccionado])

    const obtenerTitulo = (viaje)=>{
        let evento = pendientesPorSource.find(e=>e.id === viaje?.ultimoEvento)
        return evento?.nombre;
    }

    const cancelarFechas = async () => {
        if ((viaje?.comentarios ?? '').trim() === '') {
            setSubmitted(true);
            return;
        }

        await api.cancelarFecha(viaje.viajeId, viaje.comentarios);
        onCanceled();
    };

    const renderFooterCancelar = () => {
        return (
            <div>
                <Button label="Cancelar" icon="pi pi-x" onClick={cancelarFechas} autoFocus/>
            </div>
        );
    };

    return (
        <Dialog header={`Cancelar ${titulo} del viaje ${viaje?.cartaPorte}`} visible={visible} style={{width: '600px'}}
                footer={renderFooterCancelar('displayBasic')}
                onHide={onHide}>
            <FieldTextArea name="comentarios" label="Comentarios" colSm={12} rows={5} maxLength={250}
                           value={viaje?.comentarios}
                           error={(viaje?.comentarios?.trim()??'') === '' && submitted}
                           onChange={(comentarios)=> setViaje({...viajeSeleccionado, comentarios}) }/>
        </Dialog>
    )
}

export default CancelarFecha;
