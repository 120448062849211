import produce from "immer";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import FieldDropDown from "../../shared/controls/fieldDropDown";
import FieldTextBox from "../../shared/controls/fieldTextBox";

const ConfiguracionLear = ({ entity, soloLectura, onChange, errors }) => {

    const agregarConfiguracion = () => {
        const newList = produce(entity?.clientesFacturadores, draft => {
            draft.push({});
        });

        onChange(newList, 'clientesFacturadores');
    };

    const actualizar = (value, propertyName, index) => {
        const newList = produce(entity?.clientesFacturadores, draft => {
            draft[index][propertyName] = value;

            if (propertyName === 'clienteFacturador') {
                draft[index]['clienteFacturadorId'] = value?.id;
            }
        });

        onChange(newList, 'clientesFacturadores');
    };

    const eliminar = (index) => {
        const newList = produce(entity?.clientesFacturadores, draft => {
            draft.splice(index, 1);
        });

        onChange(newList, 'clientesFacturadores');
    };

    return (
        <div className="form-row">
            <h5>Configuración Lear</h5>
            <table className="table table-borderless">
                <thead className="thead-light">
                    <tr>
                        <th>HYP</th>
                        <th>LOC</th>
                        <th>PLANTA</th>
                        <th>CLIENTE FACTURADOR</th>
                        <th className={'ag-grid header-icons'} style={{ with: '10%' }}>
                            {!soloLectura &&
                                <ButtonIcon title={'Agregar Configuración'} iconName={'SiAddthis'} className="grid-action"
                                    onClick={agregarConfiguracion} />
                            }
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {entity?.clientesFacturadores?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="input">
                                    <FieldTextBox name={'hyp'} value={item.hyp} colMd={6} placeholder={'Hyp'} maxLength={5} disabled={soloLectura}
                                        errors={errors != null && errors[`clientesFacturadores[${index}]`] != null ? errors[`clientesFacturadores[${index}]`] : ''}
                                        onChange={(value, propertyName) => actualizar(value, propertyName, index)} className={'mt-2'} />
                                </td>
                                <td className="input">
                                    <FieldTextBox name={'loc'} value={item.loc} colMd={6} placeholder={'Loc'} maxLength={5} disabled={soloLectura}
                                        errors={errors != null && errors[`clientesFacturadores[${index}]`] != null ? errors[`clientesFacturadores[${index}]`] : ''}
                                        onChange={(value, propertyName) => actualizar(value, propertyName, index)} className={'mt-2'} />
                                </td>
                                <td className="input">
                                    <FieldTextBox name={'planta'} value={item.planta} colMd={6} placeholder={'Planta'} maxLength={50} disabled={soloLectura}
                                        errors={errors != null && errors[`clientesFacturadores[${index}]`] != null ? errors[`clientesFacturadores[${index}]`] : ''}
                                        onChange={(value, propertyName) => actualizar(value, propertyName, index)} className={'mt-2'} />
                                </td>
                                <td className="input">
                                    <FieldDropDown name={'clienteFacturador'} source="clientes" colMd={6} value={item.clienteFacturador} disabled={soloLectura}
                                        errors={errors != null && errors[`clientesFacturadores[${index}]`] != null ? errors[`clientesFacturadores[${index}]`] : ''}
                                        onChange={(value, propertyName) => actualizar(value, propertyName, index)} />
                                </td>
                                <td>
                                    {!soloLectura &&
                                        <ButtonIcon title={'Eliminar'} iconName={'RiDeleteBinLine'}
                                            onClick={() => eliminar(index)} />
                                    }
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div >
    );
};

export default ConfiguracionLear;