const constantes = {
    estatusLiberacion: {
        porGenerarRv: 141,
        porLiberar: 142,
        liberado: 143,
        liberacionRechazada: 144,
    },
    asignacionEquipo:{
        asignado:408,
        desasignar:409
    },
    tiposMovimiento:{
        prestamo : 471,
        descuento : 472,
        bono : 473,
        faltas : 474,
        complementariaConDescuento : 475, 
        tiempoExtra : 476,
        complementariaSinDescuento:477
    },
    estatusViajes: {
        porProgramar: 101,
        programado: 102,
        enRuta: 103,
        terminado: 104,
        segmentado: 105,
        cancelado: 106,
        sinViaje: -1,
    },
    estatusVacaciones:{
        porAutorizar: 161,
        autorizado: 162,
        rechazado: 163,
    },
    estatusDocumentos:{
        awaiting:403,
        pending:404,
        aproved:405,
        rejected:406,
        retry:407
    },
    estatusStp:{
        liquidada:310,
        cancelada:311,
        devuelta:312,
        solicitada:313
    },
    categorias:{
        Operativos:1,
        Mantenimiento:2,
        Seguridad:3
    }
};

export default constantes;
