/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import DetalleDanio from "./detalleDanio";
import {Sidebar} from "primereact/sidebar";
import _ from 'lodash';
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import {Button} from "primereact/button";
import Thumbnail from "../../../shared/thumbnail";
import CustomMarker from "../../../shared/controls/CustomMarker";
import DateTimeFormatter from "../../../shared/ag-grid-helper/dateTimeFormatter";
import MarkerTemplate from "./markerTemplate";
import {ImagenUnidad} from "./imagenesUnidades";

const Inspeccion = ({value, onChange, name, tipoUnidadId, imagenes, disabled, entity}) => {
    const [visibleDetalle, setVisibleDetalle] = useState(true);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [averiaActual, setAveriaActual] = useState(false);
    const [index, setIndex] = useState(-1);
    const [imagenesUnidad, setImagenesUnidad] = useState(imagenes);

    useEffect(()=>{
        setImagenesUnidad(
            imagenes.map(e=> new ImagenUnidad({
                ...e,
                tipoUnidadId: tipoUnidadId ?? 1
            }))
        );
    },[tipoUnidadId, entity?.esExterno]);

    function guardarAveria(nuevaAveria) {

        onChange(index < 0 ?
            [...value, nuevaAveria] :
            value.map((e, i) => i === index ? nuevaAveria : e)
            , name);
        setSidebarVisible(false);
    }

    function agregar(marker, ubicacion) {
        if(disabled) return;

        const siguienteConsecutivo = value.length === 0 ? 1 : (_.max(value.map(e => e.consecutivo)) + 1);
        setIndex(-1);
        setAveriaActual({
            consecutivo: siguienteConsecutivo,
            marker,
            ubicacion,
            editable:true
        });
        setSidebarVisible(true);
    }

    function ver(index) {
        setAveriaActual(value[index]);
        setIndex(index);
        setSidebarVisible(true);
    }

    function eliminar(index) {
        onChange(value.filter((e, i) => i !== index), name);
    }


    return (
        <>
            <Sidebar visible={sidebarVisible} position="right" dismissable={false} className="p-sidebar-md"
                     onHide={() => setSidebarVisible(false)}>
                <DetalleDanio name="averia" averia={averiaActual}
                              onSave={guardarAveria} close={() => setSidebarVisible(false)}/>
            </Sidebar>

            <div className={`section-container ope-inspeccion`}>
                <div className={`ope-inspeccion-imagenes flex-col-${visibleDetalle ? '7' : '12'}`}>
                    <h6 style={{gridColumnEnd: 'span 4'}}>
                        Inspección
                    </h6>
                    {imagenesUnidad?.map((item, index) => {
                        return (
                                <CustomMarker key={index}
                                              gridColumnEnd={item.gridColumnEnd}
                                              gridRowEnd={item.gridRowEnd}
                                              title={item.title}
                                              src={item.src}
                                              params={item.ubicacion}
                                              markerContainer={value?.filter(e=>e.ubicacion.id === item.ubicacion.id)}
                                              onAddMarker={agregar}
                                              markerTemplate ={MarkerTemplate}
                                />
                            )}
                    )}
                </div>
                {visibleDetalle === true &&
                <div className="flex-col-5 ope-daños">
                    <h6>
                        Detalle de Daños
                    </h6>
                    <label className="ope-daños-expandir" onClick={() => setVisibleDetalle(false)}>
                        <i className="pi pi-angle-right"/>
                    </label>
                    <table className="table with-thumbnail">
                        <thead className="thead-light">
                        <tr>
                            <th/>
                            <th scope="col" style={{width: '5%'}}>ID</th>
                            <th scope="col" style={{width: '25%'}}>TIPO DAÑO</th>
                            <th scope="col" style={{width: '30%'}}>UBICACION</th>
                            <th scope="col" className="center" style={{width: '25%'}}>FECHA Y HORA</th>
                            <th scope="col" style={{width: '5%'}}/>
                            <th scope="col" style={{width: '5%'}}/>
                        </tr>
                        </thead>
                        <tbody>
                        {value?.map((item, index) => {
                            return (
                                <>
                                    <tr key={index}>
                                        <td className="thumbnail center">
                                            <Thumbnail value={item.foto}/>
                                        </td>
                                        <td>{item.consecutivo}</td>
                                        <td>{item.tipoAveria?.nombre}</td>
                                        <td>{item.ubicacion?.nombre}</td>
                                        <td className="center "> <DateTimeFormatter value={item.fecha}/> </td>
                                        <td className="input">
                                            {item.editable === true &&
                                            <ButtonIcon title={'Eliminar'} onClick={() => eliminar(index)}
                                                        iconName={'RiDeleteBinLine'}/>
                                            }
                                        </td>
                                        <td className="center input" onClick={() => ver(index)}>
                                            <Button label="Ver" className="p-button-link"/>
                                        </td>
                                    </tr>
                                </>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                }
                {visibleDetalle === false &&
                <div className="ope-daños-contraer" onClick={() => setVisibleDetalle(true)}>
                    <label>
                        <i className="pi pi-angle-left"/>
                    </label>
                    Ver Detalle
                </div>
                }
            </div>
        </>
    );
};

export default Inspeccion;
