import useServiceFactory from "../serviceFactory";
const apiUrl = `/catalogos/empleados`;
const useApiEmpleados = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, {params}),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, {showLoading: true}),
        guardar: async (params) => service.post(`${apiUrl}`, params),
        actualizar: async (id,params) => service.put(`${apiUrl}/${id}`, params),
        alta: async (id) => service.patch(`${apiUrl}/${id}/alta`, {}),
        baja: async (id,params) => service.put(`${apiUrl}/${id}/baja`, params),
        movimientosPendientes: async (id) => service.get(`${apiUrl}/${id}/movimientosPendientes`),
        liquidacionPendientes: async (id) => service.get(`${apiUrl}/${id}/liquidacionesPendientes`),
        rechazarMovimientos: async (id) => service.put(`${apiUrl}/${id}/rechazarMovimientos`),
        envioCorreo: async (id,params) => service.post(`${apiUrl}/${id}/envioCorreo`,params, { showSuccess: true }),
        reingreso: async (id,params) => service.put(`${apiUrl}/${id}/reingreso`, params),
        subirArchivos: async(id, formData) => service.post(`${apiUrl}/${id}/archivos`, formData),
        subirFoto: async(id, formData) => service.post(`${apiUrl}/${id}/foto`, formData),
        subirAnexosAntidoping: async(id, formData) => service.post(`${apiUrl}/${id}/antidoping`, formData),
        subirAnexosActasAdministrativas: async(id, formData) => service.post(`${apiUrl}/${id}/actaAdministrativa`, formData),
        subirAnexosActasFamiliares: async(id, formData) => service.post(`${apiUrl}/${id}/actaFamiliar`, formData),
        subirArchivosAdicionales: async(id, formData) => service.post(`${apiUrl}/${id}/archivosAdicionales`, formData),
        subirAnexosLicencias: async(id, formData) => service.post(`${apiUrl}/${id}/licencia`, formData),
        subirAnexosRecursosConfiables: async(id, formData) => service.post(`${apiUrl}/${id}/recursosConfiables`, formData),
        retiro: async (id,params) => service.put(`${apiUrl}/${id}/retiroCajaahorro`, params),
        abonoCajaahorro: async (id,params) => service.put(`${apiUrl}/${id}/abonoCajaahorro`, params),
        excel: async (params) => service.get(`${apiUrl}/excel`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `Empleados.xlsx`
            }),
        excelFamiliares: async (params) => service.get(`${apiUrl}/excelFamiliares`,
        {
            params,
            showLoading: true,
            download: true,
            fileName: `EmpleadosFamiliares.xlsx`
        }),
        excelDopings: async (params) => service.get(`${apiUrl}/excelDopings`,
        {
            params,
            showLoading: true,
            download: true,
            fileName: `EmpleadosAntidopings.xlsx`
        }),
        excelInformAdicional: async (params) => service.get(`${apiUrl}/infoArchivos`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `EmpleadosInformacionArchivos.xlsx`
            }),
    }
};

export default useApiEmpleados;