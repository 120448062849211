/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import Filters from "../../shared/forms/FormFilter/Filters";
import Field from "../../shared/forms/Field";
import Buttons from "../../shared/forms/FormFilter/Buttons";
import FormFilter from "../../shared/forms/FormFilter/FormFilter";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import agGridHelper from "../../shared/ag-grid-helper";
import moment from "moment";
import {
    AgCurrencyFormatter,
    AgDateFormatter,
    AgNumberFormatter
} from "../../shared/ag-grid-helper/GridFormatters";
import FormDropDown from "../../shared/forms/FormFields/FormDropDown";
import useVentasPorUnidadApi from "../../api/reportes/useVentasPorUnidadApi";
import FormMultiSelect from "../../shared/forms/FormFields/FormMultiSelect";
import constantes from "../../catalogos/constantes";
import withPager from "../../shared/ag-grid-helper/PagerProvider";
import useGridFilter from "../../hooks/useGridFilter";
import ButtonIcon from "../../shared/controls/ButtonIcon";

const filtroInicial = {
    aniosMeses: [parseInt(moment().startOf('month').format('yyyyMM'))]
};

const FacturacionPorUnidad = () => {
    const api = useVentasPorUnidadApi();

    const {
        gridRef,
        data,
        onFilter,
        filterForm,
        onExport
    } = useGridFilter({api, initialFilter: filtroInicial,  filterAtLoad:false});

    return (
        <div className="main-screen">
            <FormFilter columns={6}
                        filter={filterForm.values}
                        onClear={filterForm.onClear}
                        onFilter={onFilter}
                        onChange={filterForm.onChange}
            >
                <Filters>
                    <Field label={"Año Mes"} field="aniosMes" source={"aniomeses"} render={FormDropDown}/>
                    <Field label={"Unidad"} field="unidadIds" params={{id:constantes.tiposEquipo.motriz}} source={"unidadesCajas"} showClear={true} render={FormMultiSelect}/>
                </Filters>
                <Buttons filter={true}
                         clear={true}
                >
                    <ButtonIcon title={'Descargar'} onClick={onExport} iconName={'BiDownload'}/>
                </Buttons>
            </FormFilter>
            <div className="divider"/>
            <div className="ag-theme-balham grid-principal cell-flex">
                <AgGridReact
                    ref={gridRef}
                    rowSelection={'single'}
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    rowData={data}
                    // onSortChanged={onSortChanged}
                    getRowId={(params) =>
                        params.data.viajeId
                    }
                    defaultColDef={agGridHelper.defaultColumns}
                    gridOptions={{
                        suppressRowClickSelection: true,
                        suppressCellSelection: true
                    }}
                >
                    <AgGridColumn field="fecha" headerName="Fecha" cellClass={'center'} valueFormatter={AgDateFormatter} maxWidth={70}/>
                    <AgGridColumn field="unidad" headerName="Unidad" cellClass={'center'}  maxWidth={70} />
                    <AgGridColumn field="tipoUnidad" headerName="Tipo Unidad" cellClass={'center'}  maxWidth={150} />
                    <AgGridColumn field="cartaPorte" headerName="Carta Porte"  maxWidth={70} />
                    <AgGridColumn field="convenio" headerName="Convenio" />
                    <AgGridColumn field="cliente" headerName="Cliente" />
                    <AgGridColumn field="ruta" headerName="Ruta" />
                    <AgGridColumn field="operador" headerName="Operador" />
                    <AgGridColumn field="caja" headerName="Caja"  maxWidth={70} />
                    <AgGridColumn field="viajeId" headerName="Viaje Id"  maxWidth={70} />
                    <AgGridColumn field="distancia" headerName="Kms" valueFormatter={AgNumberFormatter} cellClass={'right'}  maxWidth={70}
                                  headerClass={'center'}/>
                    <AgGridColumn field="facturacionSegmento" headerName="Facturación" valueFormatter={AgCurrencyFormatter} cellClass={'right'}  maxWidth={100}
                                  headerClass={'center'}/>
                    <AgGridColumn field="comision" headerName="Comisión" valueFormatter={AgCurrencyFormatter} cellClass={'right'}  maxWidth={80}
                                  headerClass={'center'}/>

                </AgGridReact>
            </div>
            {/*<Pager rowCount={data.length}/>*/}
        </div>
    )
}

const FacturacionPorUnidadWithPager = withPager(FacturacionPorUnidad);

export default FacturacionPorUnidadWithPager;
