import useServiceFactory from "../serviceFactory";

const apiUrl = `/operaciones/viajes/seguimiento`;
const apiUrlCartaPorte = `/operaciones/viajes/programacion`;

const useApiViajeSeguimiento = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params, showLoading) => service.get(`${apiUrl}`, { params, showLoading: showLoading ?? false }),
        viajes: async (params) => service.get(`${apiUrl}/viajes`, { params, showLoading: true }),
        obtenerUltimaActualizacionGps: async () =>
            service.get(`${apiUrl}/ultimaActualizacionGps`, { showLoading: true }),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, { showLoading: true }),
        obtenerGeocercas: async (id) => service.get(`${apiUrl}/${id}/geocercas`, { showLoading: true }),
        actualizar: async (id, params) => service.put(`${apiUrl}/${id}`, params),
        iniciar: async (id, params) => service.put(`${apiUrl}/${id}/iniciar`, params),
        otrosViajesEnRuta: async (params) => service.get(`${apiUrl}/OtrosViajesEnRuta`, { params, showLoading: false }),
        otrosViajesEnRutaPorUnidad: async (params) =>
            service.get(`${apiUrl}/OtrosViajesEnRutaPorUnidad`, { params, showLoading: false }),

        iniciarRetorno: async (id) => service.put(`${apiUrl}/${id}/iniciarRetorno`),
        terminar: async (id, params) => service.put(`${apiUrl}/${id}/terminar`, params),
        regresaraEnRuta: async (id) => service.put(`${apiUrl}/${id}/regresaraEnRuta`),
        regresaraProgramado: async (id) => service.put(`${apiUrl}/${id}/regresaraProgramado`),
        limpiarCapturaEdi: async (viajeId, viajeRutaId, entrada) =>
            service.put(`${apiUrl}/${viajeId}/limpiarCapturaEdi/${viajeRutaId}/${entrada}`, {
                showLoading: true,
                showSuccess: true,
            }),
        excel: async (params) =>
            service.get(`${apiUrl}/excel`, {
                params,
                showLoading: true,
                download: true,
                fileName: `seguimiento.xlsx`,
            }),
        marcarNotificaciones: async (id, params) =>
            service.put(`${apiUrl}/${id}/marcarNotificaciones`, params, { showSuccess: false }),
        descargar: async (id, cartaPorte) =>
            service.get(`${apiUrlCartaPorte}/${id}/pdfCartaPorte`, {
                showLoading: true,
                download: true,
                fileName: `cartaPorte_${cartaPorte}.pdf`,
            }),
        solicitamosDryRun: async (id) => service.put(`${apiUrlCartaPorte}/${id}/solicitarDryRun`),
    };
};

export default useApiViajeSeguimiento;
