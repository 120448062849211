/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useEffect } from "react";
import useApiEquipos from "../../api/catalogos/useApiEquipos";
import Form from "../../shared/forms/Form";
import Field from "../../shared/forms/Field";
import FormTextBox from "../../shared/forms/FormFields/FormTextBox";
import FormDropDown from "../../shared/forms/FormFields/FormDropDown";
import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import HistorialEquipos from "./Historial";
import Estatus from "./Estatus"
import FieldInputNumeroPhone from "../../shared/controls/customInputs/fieldInputPhone";
import FieldInputMAC from "../../shared/controls/customInputs/fieldInputMAC";
import ReadOnlyProvider from '../../shared/forms/ReadOnlyProvider';
import FieldTextArea from "../../shared/controls/fieldTextArea";
import useForm from "../../shared/forms/useForm";
import InputFile from "../../shared/controls/inputFile";
import produce from "immer";
import EquiposAsignados from "./EquiposAsignados";


const EquipoDetalle = ({solicitudId, onSave, onClose,soloLectura}) => {
    const [equipoAsignar, setequipoAsignacion] = useState("");
    const [equiposVisible, setEquiposVisible] = useState(false);
    const initialValues = {
        solicitud: {
            solicitudId: 0
        }
    };    
    const {
        values: solicitud,
        setValues,
        onChange
    } = useForm(initialValues);
    const api = useApiEquipos();
      const onLoad = async () => {
        if (solicitudId === 0) {
            setValues(initialValues);
            return;
        }

        let result = await api.obtener(solicitudId);
        setequipoAsignacion(solicitudId)

        setValues(result);
    };
    function actualizarLista(lista, value, propertyName) {
        const nuevo = produce(lista, draft => {
            draft[propertyName] = value;
        }
        );
        onChange(nuevo, "archivo");
    }  
    useEffect(() => {
        onLoad().then();
    }, [solicitudId]);
    const subirResponsiva = async () => {
        if (solicitud.archivo?.file != null) {
            const formData = new FormData();
            formData.append("file",solicitud.archivo?.file);
            await api.subirResponsiva(solicitud?.id, formData);
         }
    }
    const guardarDetalle = async () => {     

        await api.guardar(solicitud);
        await subirResponsiva(solicitud);
        onClose();
        onSave();
    };

    return (
        <>
           
              <div className="form-container full-height">              
                <TabView activeIndex={0}>
                    <TabPanel header="EQUIPOS">
                        
                            <Form columns={1} values={solicitud} width={'100%'}
                                        onChange={onChange}
                                        getByIdFunction={api.obtener}
                                        saveFunction={api.guardar}>
                                        <Field field={"categoria"} label={"Categoria"} cols={2} render={FormDropDown} source="categoriaEquipos" required={true} />
                                        <div>
                                         Archivo Responsiva
                                        <InputFile name="archivo"  value={solicitud.archivo}
                                            onChange={(value, pName) => actualizarLista(solicitud, value, pName)} />
                                        </div>
                                        {(solicitud?.categoria === 1555  || solicitud?.categoria === 1562 || solicitud?.categoria === 1558) && 
                                            <> 
                                            <Field field={"marcaEquipo"} label={"Marca"} cols={2} render={FormDropDown} source="marcaEquiposPC"  required={true} />
                                            <Field field={'nota'} cols={2} label={"Notas"} render={({onChange, parent, ...props }) =>
                                                <FieldTextArea {...props} 
                                                onChange={(nota) =>
                                                    onChange(
                                                        nota
                                                    )}
                                                maxLength={200} 
                                                />} />  
                                            <Field field={"modeloEquipo"} label={"Modelo"} cols={2} render={FormDropDown} source="modeloEquiposPC"   required={true} />  
                                            <Field field={"procesador"} label={"Procesador"} cols={2} render={FormDropDown} source="procesadorEquipos"   required={true} />  
                                            <Field field={"memoria"} label={"Memoria"} cols={2} render={FormDropDown} source="memoriaEquipos"   required={true} />  
                                            <Field field={"so"} label={"SO"} cols={2} render={FormDropDown} source="sOEquipos" required={true} />  
                                            
                                            <Field field={"macAdress"} cols={2} label={"Mac address (Opcional) "} render={({onChange, parent, ...props }) =>
                                                    <FieldInputMAC {...props} 
                                                    onChange={(macAdress) =>
                                                        onChange(
                                                            macAdress
                                                        )}
                                                    maxLength={200} 
                                                    />} />     
                                            <Field field={"añoFabricacion"} cols={2} label={"Año de fabricación (Opcional)"} render={({onChange, parent, ...props }) =>
                                                <FormTextBox {...props} 
                                                onChange={(añoFabricacion) =>
                                                    onChange(
                                                        añoFabricacion
                                                    )}
                                                maxLength={200} 
                                                />} />            
                                        
                                        </>     
                                        } 
                                        {solicitud?.categoria === 1556 && 
                                            <> 
                                                <Field field={"marcaEquipo"} label={"Marca Celular"} render={FormDropDown} source="marcaEquiposCel" cols={2} required={true} />
                                                <Field field={"modeloEquipo"} label={"Modelo Celular"} render={FormDropDown} source="modeloEquiposCel" cols={2}  required={true} />  
                                            

                                            </>     
                                            }                                         
                                        {solicitud?.categoria === 1557 && 
                                            <>                   
                                                <Field field={"lineaAbc"} label={"Linea ABC"} render={({onChange, parent, ...props }) =>
                                                    <FieldInputNumeroPhone {...props} cols={2}
                                                    onChange={(lineaAbc) =>
                                                        onChange(
                                                            lineaAbc
                                                        )}
                                                    maxLength={200} 
                                                    />} 
                                                    
                                                    
                                                    />  

                                            
                                                </>  
                                        }   
                                        {solicitud?.categoria === 1557 && 
                                            <>                   
                                                <Field field={"planLinea"} cols={2} label={"Plan"} render={({onChange, parent, ...props }) =>
                                                <FormTextBox {...props} 
                                                onChange={(planLinea) =>
                                                    onChange(
                                                        planLinea
                                                    )}
                                                maxLength={300} 
                                                />} />   

                                            
                                                </>  
                                        }   
                                        <Field field={"identificador"} cols={2} label={"IMEI / ICCID / Numero de Serie"} required={true} render={({onChange, parent, ...props }) =>
                                                <FormTextBox {...props} 
                                                onChange={(identificador) =>
                                                    onChange(
                                                        identificador
                                                    )}
                                                maxLength={200} 
                                                />} />      
                                      
                        
                                      <Field field={'nota'} cols={2} label={"Notas"} render={({onChange, parent, ...props }) =>
                                                <FieldTextArea {...props} 
                                                 onChange={(nota) =>
                                                    onChange(
                                                        nota
                                                    )}
                                                maxLength={200} 
                                                />} />   
                                    </Form>
                                    <div className="form-footer">
                                    <Button label="Guardar" className="p-button-outlined" type="button"
                                            onClick={guardarDetalle} />            
                                    </div>
                     
                    </TabPanel>
                    <TabPanel header="ESTATUS">
                        <ReadOnlyProvider defaultReadOnly={soloLectura}>
                            <Estatus name="estatus" historial={solicitud?.asignacionesActivas}
                                soloLectura={soloLectura} onChange={onChange} onSave={onSave} />
                        </ReadOnlyProvider>
                    </TabPanel>        
                    <TabPanel header="HISTORIAL DE ASIGNACIÓN">
                        <ReadOnlyProvider defaultReadOnly={soloLectura}>
                            <HistorialEquipos name="historialEquipos" historial={solicitud?.asignaciones}
                                soloLectura={soloLectura} />
                        </ReadOnlyProvider>
                    </TabPanel>
                      
                </TabView>
               <EquiposAsignados
                equipo={equipoAsignar}
                equiposVisible={equiposVisible}
                setEquiposVisible={setEquiposVisible}
                name="equiposAsignados"                
                onSave={onSave}
            />
            </div> 
        </>
    );
};


export default EquipoDetalle;