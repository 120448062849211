import React from "react";
import {Checkbox} from "primereact/checkbox";

const FormCheckBox = ({value, onChange, field, disabled, checkboxLabel}) => {

    return (
        <>
            {!checkboxLabel ?
                <Checkbox inputId={`${field}`} name={field} onChange={e => onChange(e.checked)} checked={value}
                      disabled={disabled}/> :
                <div style={{display:'flex', alignItems:'center', gap:'5px'}}>
                    <Checkbox inputId={`${field}`} name={field} onChange={e => onChange(e.checked)} checked={value}
                              disabled={disabled}/>
                    <label style={{marginBottom:'0'}} htmlFor={`${field}`}>{checkboxLabel}</label>
                </div>
            }
        </>
    )
}

export default FormCheckBox;
