import { useSearchParams } from 'react-router-dom'

const LoginError = () => {
    const [searchParams] = useSearchParams()
    const error = searchParams.get('error')

    return (
        <>
            {error}
        </>
    )
}

export default LoginError
