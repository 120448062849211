/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SideBarEditForm from "../../../shared/forms/SideBarEditForm";
import Form from "../../../shared/forms/Form";
import Field from "../../../shared/forms/Field";
import FormDropDown from "../../../shared/forms/FormFields/FormDropDown";
import FormCalendar from "../../../shared/forms/FormFields/FormCalendar";
// import { fechaMaximaSolicitud, fechaMinimaSolicitud } from "../../../reportes/cajaChica/CajaConstantes";
import moment from "moment";
import useApiDieselCarga from "../../../api/taller/useApiDieselCarga";
import Urea from "./Urea";
import Diesel from "./Diesel";
import { tiposCombustible, tiposProveedor } from "../DieselConstantes";
import { serialize } from "object-to-formdata";
import FormAutocomplete from "../../../shared/forms/FormFields/FormAutocomplete";
import FormTextBox from "../../../shared/forms/FormFields/FormTextBox";
import useSeguridad, { opcionesSeguridad } from "../../../Seguridad/useSeguridad";
import { useAuth } from "../../../Seguridad/AuthProvider";

const CargaCombustibleDetalle = ({ cargaId, onSaved, onCancel }) => {
    const api = useApiDieselCarga();
    const [visible, setVisible] = useState({});
    const [readOnly, setReadOnly] = useState(false);
    const { user: usuario } = useAuth();
    const seguridad = useSeguridad();
    const [esCargaAutomatica, setEsCargaAutomatica] = useState(false);

    useEffect(() => {
        setVisible({});
        setEsCargaAutomatica(false);
    }, [cargaId]);

    const onChangeForm = async (carga) => {
        const dieselOGasolina = carga.combustibleId != null && carga.combustibleId !== tiposCombustible.urea;

        if (!dieselOGasolina && carga?.combustibleId != null && carga?.proveedorEmpresaId != null) {
            var tanques = await api.obtenerTanques(
                carga.combustibleId,
                carga.proveedorEmpresaId,
                carga.proveedorUbicacionId
            );
            if (tanques.length === 1) {
                carga.urea =
                    carga.urea === undefined ? { tanqueId: tanques[0].id } : { ...carga.urea, tanqueId: tanques[0].id };
            }
        }

        const editable = seguridad.tieneAcceso(opcionesSeguridad.puedeEditarRendimiento) || cargaId === 0;
        setReadOnly(!editable || carga.readOnly);
        setVisible({
            proveedorId: dieselOGasolina,
            urea: carga.combustibleId === tiposCombustible.urea,
            diesel: dieselOGasolina && carga.proveedorId != null,
        });
    };

    const handleSave = async (carga) => {
        let urea = carga.combustibleId === tiposCombustible.urea ? carga.urea : {};
        let diesel = carga.combustibleId !== tiposCombustible.urea ? carga.diesel : {};
        const formData = serialize(
            {
                ...carga,
                ...urea,
                ...diesel,
                ...(carga.convertirGalonesALitros
                    ? diesel.conversionGalones
                    : { galon: null, precioGalonDolar: null, tasaDeCambioDolarPesos: null }),
            },
            { indices: true, dotsForObjectNotation: true }
        );
        await api.guardar(formData);
    };

    const handleObtener = async (id) => {
        const carga = await api.obtener(id);
        setEsCargaAutomatica(carga.esCargaAutomatica);
        return {
            ...carga,
            urea: {
                tanqueId: carga.tanqueId,
                litros: carga.litros,
                precio: carga.precio,
                conversionGalones: {
                    galon: carga.galon,
                    precioGalonDolar: carga.precioGalonDolar,
                    tasaDeCambioDolarPesos: carga.tasaDeCambioDolarPesos,
                },
            },
            diesel: {
                litros: carga.litros,
                precio: carga.precio,
                selloAnterior: carga.selloAnterior,
                sello: carga.sello,
                odometro: carga.odometro,
                ticket: carga.ticket,
                anexo: carga.anexo,
                comentarios: carga.comentarios,
                conversionGalones: {
                    galon: carga.galon,
                    precioGalonDolar: carga.precioGalonDolar,
                    tasaDeCambioDolarPesos: carga.tasaDeCambioDolarPesos,
                },
            },
        };
    };

    return (
        <>
            <SideBarEditForm
                id={cargaId}
                initialValues={{
                    fechaRegistro: moment().startOf("day")._d,
                    controlador: usuario.nombre,
                }}
                getByIdFunction={handleObtener}
                saveFunction={handleSave}
                deleteFunction={esCargaAutomatica ? null : api.eliminar}
                onSaved={onSaved}
                onCancel={onCancel}
                showCancelButton={true}
                onChangeForm={onChangeForm}
                formDisabled={readOnly}
            >
                <Form columns={2}>
                    <h5 style={{ gridColumnEnd: "span 2" }}>Agregar Carga</h5>
                    <Field field={"controlador"} label={"Controlador"} disabled={true} render={FormTextBox} />
                    <Field field={"fechaRegistro"} label={"Fecha"} required={true} render={FormCalendar} />
                    <Field
                        field={"operador"}
                        label={"Operador"}
                        source="diesel/operadores"
                        required={true}
                        render={FormAutocomplete}
                    />
                    <Field
                        field={"unidadId"}
                        label={"Unidad"}
                        required={true}
                        source="diesel/unidadesMotriz"
                        render={({ onParentChange, parent, ...props }) => (
                            <FormDropDown
                                source="diesel/combustibles"
                                {...props}
                                onChange={(unidadId, item) =>
                                    onParentChange({
                                        unidadId,
                                        combustibleId: item.totalCombustible === 1 ? item.combustibleId : null,
                                    })
                                }
                            />
                        )}
                    />

                    <Field
                        field={"combustibleId"}
                        label={"Tipo"}
                        required={true}
                        // si selecciona diesel, solo hay un tanque por lo que se asigna dicho tanque por default si en algun momento cambia a mas tanques de diesel,
                        // debería quedar como urea y dejar este como dropdown basico
                        render={({ onParentChange, parent, ...props }) => (
                            <FormDropDown
                                source="diesel/combustibles"
                                params={{ unidadId: parent.unidadId }}
                                {...props}
                            />
                        )}
                    />

                    <Field
                        field={"proveedorId"}
                        label={"Proveedor"}
                        source="diesel/proveedores"
                        required={true}
                        render={({ parent, onParentChange, ...props }) => (
                            <FormDropDown
                                {...props}
                                disabled={esCargaAutomatica && cargaId > 0}
                                params={{
                                    combustibleId: parent.combustibleId,
                                    tipoProveedorId: tiposProveedor.combustible,
                                    cargaId: cargaId,
                                    esCargaAutomatica: esCargaAutomatica,
                                }}
                                onChange={(proveedorId, item) =>
                                    onParentChange({
                                        proveedorId,
                                        esCargaExterna: !item.esEmpresaPropia,
                                        convertirGalonesALitros: item.convertirGalonesALitros,
                                        proveedorEmpresaId: item.empresaId,
                                        proveedorUbicacionId: item.ubicacionId,
                                    })
                                }
                            />
                        )}
                    />
                    <h5 style={{ gridColumnEnd: "span 2" }}>Carga</h5>
                    {visible.urea && <Field field={"urea"} render={(props) => <Urea {...props} />} cols={2} />}
                    {visible.diesel && <Field field={"diesel"} render={(props) => <Diesel {...props} />} cols={2} />}
                </Form>
            </SideBarEditForm>
        </>
    );
};

export default CargaCombustibleDetalle;
