import {FormattedDate} from "react-intl";
import React from "react";

const DateFormatter = ({value}) => (
    <>
        {value != null ?
            <FormattedDate value={value} day="2-digit" year="numeric" month="short" hour="numeric" minute="numeric"/> :
            ''
        }
    </>
);

export default DateFormatter;
