/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import useSessionState from "../../hooks/useSessionState";
import {produce} from "immer";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import useApiUsuarioFiltro from "../../api/catalogos/useApiUsuarioFiltro";
import moment from "moment";
import IconLibrary from "../iconLibrary";

const Filtro = ({filtroKey,defaultValue, handleSubmit, gridApi, fechasRangoDias, fechasDiasInicio, filtrarAlInicio, ...props}) => {
    defaultValue = defaultValue ?? {};

    filtrarAlInicio = filtrarAlInicio ?? true;

    const getItemInStorage = !props.showFilterButtons && !props.showSaveFilterButton;

    const [filtro, setFiltro] = useSessionState(filtroKey, defaultValue, getItemInStorage);
    const [filtroGuardado, setFiltroGuardado] = useState();
    const [hiddenFiltersShown, setHiddenFiltersShown] = useState(false);
    const elements = React.Children.toArray(props.children);
    const additionalButtons = elements.length > 1 ? elements[0] : null;
    const form = elements.length === 1 ? elements[0] : elements[1];
    const hiddenFilters = elements.find(e => e.props.name === "filtrosOcultos");
    const classButtons = `iconos ${props?.classButtons ?? ''}`;
    const helperIconStyle = {position: "absolute", fontSize: "10px", right: "3px", bottom: "3px"};
    const api = useApiUsuarioFiltro();

    function handleChange(value, name, submit) {
        const nuevoFiltro = produce(filtro, draft => {
            draft[name] = value
        });

        setFiltro(nuevoFiltro);

        if (submit){
            handleSubmit(nuevoFiltro);
        }
    }

    function resetearFiltro() {
        setFiltro({...defaultValue, fechas: null});
    }

    function obtenerDiferenciaFechas() {
        let diferenciaDias = 0;

        if (!!filtro.fechas && filtro.fechas.length === 2) {
            const fecha1 = moment(filtro.fechas[0]);
            const fecha2 = moment(filtro.fechas[1]);

            diferenciaDias = fecha2.diff(fecha1, 'days');
        }

        return diferenciaDias;
    }

    function obtenerFiltros(jsonFiltros) {
        let datos = JSON.parse(jsonFiltros);

        if (!!datos.diferenciaDias && datos.diferenciaDias > 0) {
            const fecha2 = moment().add(datos.diferenciaDias, 'days');
            datos = {...datos, fechas: [new Date(), fecha2.toDate()]};
        }
        else{
            datos = setearRangoFechas(datos);
        }

        return datos;
    }

    useEffect(() => {
        const cargarFiltros = async () => {
            let filtrosInicio = defaultValue;

            if (props.modulo) {
                let result = await api.obtener(props.modulo);

                if (!!result) {
                    setFiltroGuardado(result);
                    filtrosInicio = obtenerFiltros(result.filtros);
                }else{
                    filtrosInicio = setearRangoFechas(defaultValue);
                }

                setFiltro(filtrosInicio);
            }else{
                filtrosInicio = setearRangoFechas(filtro);
                setFiltro(filtrosInicio);
            }

            // if (gridApi != null) {
            //     handleSubmit(filtrosInicio);
            // }
            if (filtrarAlInicio)
                handleSubmit(filtrosInicio);
        };

        cargarFiltros().then();
    }, [gridApi]);

    function setearRangoFechas(filtros) {
        if(fechasDiasInicio != null || fechasRangoDias != null){
            const fechaInicio = new Date();
            const fechaFin = new Date();
            const diasaQuitarInicio = fechasDiasInicio?? 0;
            const diasaAgregarFin = (fechasRangoDias?? 30) - diasaQuitarInicio;

            fechaInicio.setDate(fechaInicio.getDate() - diasaQuitarInicio);
            fechaFin.setDate(fechaFin.getDate() + diasaAgregarFin);

            const fechas = [fechaInicio, fechaFin];
            return filtros == null? {fechas}: {fechas,...filtros};
        }

        return filtros != null? filtros: {};
    }

    function filtrar() {
        handleSubmit(filtro);
    }

    function onEnterKeyPress(event) {
        if (event.charCode === 13) {
            handleSubmit(filtro);
            event.preventDefault();
        }
    }

    async function guardarFiltros() {
        const datos = {...filtro, fechas: null};

        datos.diferenciaDias = obtenerDiferenciaFechas();

        if (props.modulo && !filtroGuardado) {
            const result = await api.guardar(props.modulo, {filtros: JSON.stringify(datos)})
            setFiltroGuardado(result);
        } else if (filtroGuardado) {
            await api.actualizar(filtroGuardado.id, {filtros: JSON.stringify(datos)})
        }
    }

    return (
        <form className="form-filtro main-filter" onKeyPress={onEnterKeyPress}>
            <div className={classButtons}>
                {additionalButtons &&
                React.cloneElement(additionalButtons)
                }

                <ButtonIcon title={"Borrar Filtros"} onClick={resetearFiltro} iconName={'FcClearFilters'}/>
                <ButtonIcon title="Buscar" onClick={filtrar} className={'action'} iconName={'BsSearch'}/>
            </div>

            <div className="form-row" style={props.styleForm}>
                {React.cloneElement(form, {filtro, onChange: handleChange})}

                {(props.showFilterButtons || props.showSaveFilterButton || props.showHiddenFiltersButton) &&
                <div className="col">
                    <div className="d-flex iconos justify-content-end mt-md-4 mr-2">
                        {(props.showFilterButtons || props.showSaveFilterButton) &&
                        <button type="button" className="button-icon position-relative FcFilledFilter"
                                title="Guardar Filtros" onClick={guardarFiltros}>
                            <IconLibrary iconName="FcFilledFilter"/>
                            <i className="pi pi-save" style={helperIconStyle}/>
                        </button>
                        }

                        {(props.showFilterButtons || props.showHiddenFiltersButton) &&
                        <button type="button" className="button-icon position-relative FcFilledFilter"
                                title={`${hiddenFiltersShown ? 'Ocultar' : 'Mostrar'} Filtros`}
                                onClick={() => setHiddenFiltersShown(!hiddenFiltersShown)}>
                            <IconLibrary iconName="FcFilledFilter"/>
                            <i className={`pi ${hiddenFiltersShown ? 'pi-minus-circle' : 'pi-plus-circle'}`}
                               style={helperIconStyle}/>
                        </button>
                        }
                    </div>
                </div>
                }
            </div>

            {hiddenFilters && hiddenFiltersShown &&
            <div className="form-row">
                {React.cloneElement(hiddenFilters, {filtro, onChange: handleChange})}
            </div>
            }
        </form>
    )
};

export default Filtro;
