import { Button } from "primereact/button";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import FieldCalendar from "../../../shared/controls/fieldCalendar";
import React, { useReducer, useState } from "react";
import formReducer from "../../../shared/forms/formReducer";
import FieldAutoComplete from "../../../shared/controls/fieldAutoComplete";
import moment from "moment";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import produce from "immer";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import { RadioButton } from "primereact/radiobutton";
import FieldInputNumber from "../../../shared/controls/fieldInputNumber";
// import UseApiOrdenServicio from "../../../api/taller/useApiOrdenServicio";
import UseApiAlmacen from "../../../api/taller/useApiAlmacen";
import alerts from "../../../shared/alerts";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import numeral from "numeral";

const DetalleAlmacen = ({ onSave, closePanel, soloLectura }) => {

    // const [datos, setDatos] = useState([]);
    // const apiOrdenServicio = UseApiOrdenServicio();
    const apiAlmacen = UseApiAlmacen();
    const [, setErrorServicios] = useState(false);

    // const [checked, setChecked] = useState(true);
    const defaultSolicitudAlmacen = () => (
        {
            entity: {
                id: 0,
                empleado: null,
                unidad: null,
                fecha: moment().startOf('day').toDate(),
                cargoUnidad: true,
                cargoEmpleado: false,
                refacciones: [],
                servicios: []
            }
        }
    );

    const [{ entity, errors }, dispatch] = useReducer(
        formReducer, {}, defaultSolicitudAlmacen
    );

    async function guardar() {
        const resultado = validacionesSave();
        if (!resultado) {
            const response = await apiAlmacen.guardar(entity);

            if (response.success) {
                onSave();
            }
            else {
                alerts.aviso(response.message);
            }
        }
    }

    function validacionesSave() {
        let _errors = false;
        if (entity.refacciones.length === 0) { alerts.aviso('Debe ingresar Refacciones.'); _errors = true; }
        if (entity.empleado === null) { alerts.aviso('Debe seleccionar un Empleado.'); _errors = true; }

        if (entity.cargoUnidad) {
            if (entity.unidad === null) { alerts.aviso('Debe seleccionar una Unidad.'); _errors = true; }
            if (entity.id === 0) { alerts.aviso('Debe seleccionar una Orden de Trabajo abierta.'); _errors = true; }
        }

        if (validaCantidadesRefacciones()) {
            alerts.aviso('Debe ingresar cantidades mayores a 0 (cero) para las refacciones.');
            _errors = true;
        }
        if (validaRefacciones()) {
            alerts.aviso('Debe ingresar refacciones.');
            _errors = true;
        }

        return _errors;
    }
    function validaRefacciones() {
        let sumar = 0;
        entity.refacciones.forEach(refa => {
            if (refa.refaccion === null || refa.refaccion === undefined) {
                sumar++;
            }
        });
        return (sumar > 0);
    }
    function validaCantidadesRefacciones() {
        let sumar = 0;
        entity.refacciones.forEach(refa => {
            if (refa.cantidad === 0 || refa.cantidad === undefined) {
                sumar++;
            }
        });
        return (sumar > 0);
    }

    const update = (value, propertyName) => {
        dispatch({ type: 'update', value: value, name: propertyName });

        if (propertyName === 'unidad')
            obtenerOrdenesDeUnidad(value);
        if (propertyName === 'cargoUnidad')
            dispatch({ type: 'update', value: (value ? false : true), name: 'cargoEmpleado' });
        if (propertyName === 'cargoEmpleado')
            dispatch({ type: 'update', value: (value ? false : true), name: 'cargoUnidad' });
    };

    async function obtenerOrdenesDeUnidad(value) {
        var ordenesTrabajo = await apiAlmacen.filtrar(value === null ? 0 : value.id);
        update(ordenesTrabajo, 'servicios');
        if (ordenesTrabajo.length === 0) {
            alerts.aviso('La unidad seleccionada no cuenta con Orden de Trabajo abierta.');
        }
    }

    function actualizarLista(lista, index, value, propertyName, namelist) {
        const nuevo = produce(lista, (draft) => {
            draft[index][propertyName] = value;
        });

        dispatch({ type: "update", value: nuevo, name: namelist });
    }

    function actualizarListaOTS(lista, index, value, propertyName, namelist) {
        const nuevo = produce(lista, (draft) => {
            draft[index][propertyName] = value;
        });

        dispatch({ type: "update", value: nuevo, name: namelist });
        dispatch({ type: "update", value: lista[index].id, name: 'id' });
    }

    function agregarALista(lista, namelist) {

        var fila = lista.length + 1;
        const nuevo = produce(lista, (draft) => {
            draft.splice(0, 0, { existente: true, fila: fila });
        });

        if (namelist === "servicios")
            setErrorServicios(false);

        dispatch({ type: "update", value: nuevo, name: namelist });
    }

    function eliminarDeLista(lista, index, namelist) {
        const nuevo = produce(lista, (draft) => {
            draft.splice(index, 1);
        });

        dispatch({ type: "update", value: nuevo, name: namelist });
    }


    return (
        <>
            <div className="form-container full-height">

                <div className="form-row">
                    <h5 className="col-12">Salidas de Inventario</h5>

                    <FieldAutoComplete required name="empleado" label="Empleados" colMd={6} errors={errors}
                        source="empleados"
                        params={{ todasLasEmpresas: true }}
                        value={entity.solicitante}
                        onChange={update} />
                    <FieldCheckBox name="cargoUnidad" label="La unidad" value={entity.cargoUnidad} checked={entity.cargoUnidad}
                        onChange={update} className="py-1" colMd={2} />
                    <FieldCheckBox name="cargoEmpleado" label="Empleado" value={entity.cargoEmpleado} checked={entity.cargoEmpleado}
                        onChange={update} className="py-1" colMd={2} />

                    <FieldAutoComplete required name="unidad" label="No Económico" colMd={6} errors={errors}
                        source="unidades"
                        params={{ todasLasEmpresas: true }} 
                        value={entity.unidad}
                        disabled={entity.cargoEmpleado}
                        onChange={update} />

                    <FieldCalendar name="fecha"
                        label="Fecha"
                        className="small"
                        showButtonBar={false}
                        value={entity.fecha}
                        colMd={6}
                        onChange={update}
                        errors={errors}
                    />

                    <div className="col-12">
                        <h5>Artículos</h5>
                        <div className="">
                            <table className="table main-edit-table" align="right">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col" className="center" style={{ width: "10%" }}>
                                            FILA
                                        </th>
                                        <th scope="col" className="center" style={{ width: "15%" }}>
                                            CODIGO
                                        </th>
                                        <th scope="col" style={{ width: "40%" }}>
                                            DESCRIPCION
                                        </th>
                                        <th scope="col" className="center" style={{ width: "15%" }}>
                                            UNIDAD
                                        </th>
                                        <th scope="col" className="center" style={{ width: "10%" }}>
                                            CANTIDAD
                                        </th>
                                        <th scope="col" className="center" style={{ width: "15%" }}>
                                            PRECIO
                                        </th>
                                        <th className={"input"} style={{ width: "0%" }}>
                                            <ButtonIcon
                                                title={"Agregar Refacciones"}
                                                iconName={"SiAddthis"}
                                                onClick={() =>
                                                    agregarALista(entity.refacciones, "refacciones")
                                                }
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {entity.refacciones?.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="input center">
                                                    {index + 1}
                                                </td>
                                                <td className="input center">
                                                    <label>
                                                        {item.existente === true && item.codigo}

                                                        {item.existente === true &&
                                                            item.refaccion?.codigo}
                                                    </label>
                                                </td>
                                                <td className="input center">
                                                    {item.existente === true && (
                                                        <>
                                                            <FieldAutoComplete
                                                                name="refaccion"
                                                                errors={errors?.[`refacciones[${index}]`]}
                                                                source="refacciones"
                                                                freeText={true}
                                                                value={item.refaccion}
                                                                onChange={(value) =>
                                                                    actualizarLista(
                                                                        entity.refacciones,
                                                                        index,
                                                                        value,
                                                                        "refaccion",
                                                                        "refacciones"
                                                                    )
                                                                }
                                                                placeholder="Codigo/Nombre"
                                                            />
                                                        </>
                                                    )}

                                                    {item.existente === false && (
                                                        <>
                                                            <FieldTextBox
                                                                name="nombre"
                                                                upperCase={true}
                                                                errors={errors?.[`refacciones[${index}]`]}
                                                                onChange={(value) =>
                                                                    actualizarLista(
                                                                        entity.refacciones,
                                                                        index,
                                                                        value,
                                                                        "nombre",
                                                                        "refacciones"
                                                                    )
                                                                }
                                                                value={item.nombre}
                                                            />
                                                        </>
                                                    )}
                                                </td>
                                                <td className="input center">
                                                    <label>
                                                        {item.existente === true &&
                                                            item.unidadDeMedida?.nombre}
                                                        {item.existente === true &&
                                                            item.refaccion?.unidadDeMedida?.nombre}
                                                    </label>

                                                    {item.existente === false && (
                                                        <FieldDropDown
                                                            name="unidadDeMedida"
                                                            source="unidadesDeMedida"
                                                            required
                                                            errors={errors?.[`refacciones[${index}]`]}
                                                            value={item.unidadDeMedida}
                                                            onChange={(value) =>
                                                                actualizarLista(
                                                                    entity.refacciones,
                                                                    index,
                                                                    value,
                                                                    "unidadDeMedida",
                                                                    "refacciones"
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </td>
                                                <td className="input center">
                                                    <FieldInputNumber
                                                        name="cantidad"
                                                        showButtonBar={false}
                                                        value={item.cantidad}
                                                        errors={errors?.[`refacciones[${index}]`]}
                                                        onChange={(value) =>
                                                            actualizarLista(
                                                                entity.refacciones,
                                                                index,
                                                                value,
                                                                "cantidad",
                                                                "refacciones"
                                                            )
                                                        } />
                                                </td>
                                                <td className="input center">
                                                    {item.existente ?
                                                        <>{numeral(item?.refaccion?.precio ?? 0).format('$ 0,0.00')}</>
                                                        :
                                                        <FieldInputNumber
                                                            name="precio"
                                                            showButtonBar={false}
                                                            value={item.precio}
                                                            errors={errors?.[`refacciones[${index}]`]}
                                                            onChange={(value) =>
                                                                actualizarLista(
                                                                    entity.refacciones,
                                                                    index,
                                                                    value,
                                                                    "precio",
                                                                    "refacciones"
                                                                )
                                                            }
                                                        />
                                                    }

                                                </td>
                                                <td className="input">
                                                    <ButtonIcon
                                                        title={"Eliminar"}
                                                        onClick={() =>
                                                            eliminarDeLista(
                                                                entity.refacciones,
                                                                index,
                                                                "refacciones"
                                                            )
                                                        }
                                                        iconName={"RiDeleteBinLine"}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>


                    {entity?.cargoUnidad === true &&
                        <div id="servicios" className="col-12 danger"><br /><br /><br />
                            <h5>Órdenes de trabajo</h5>

                            <div className="">
                                <table className="table main-edit-table" align="right">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col" style={{ width: "15%" }}>

                                            </th>
                                            <th scope="col" style={{ width: "10%" }}>
                                                NÚMERO
                                            </th>
                                            <th style={{ width: "30%" }}>
                                                TIPO
                                            </th>
                                            <th scope="col" style={{ width: "45%" }}>
                                                DESCRIPCIÓN
                                            </th>
                                            <th className={"input"} style={{ width: "0%" }}>
                                                <ButtonIcon
                                                    title={""}
                                                    iconName={"FcEmptyBattery"}
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {entity.servicios?.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="input center">
                                                        <div className="p-field-radiobutton main-item-container">
                                                            <RadioButton
                                                                name="existente"
                                                                value={true}
                                                                onChange={(e, name) =>
                                                                    actualizarListaOTS(
                                                                        entity.servicios,
                                                                        index,
                                                                        e.value,
                                                                        "seleccionada",
                                                                        "servicios"
                                                                    )
                                                                }
                                                                checked={item.seleccionada}
                                                            />
                                                            <label></label>
                                                        </div>
                                                    </td>
                                                    <td className="input">
                                                        {item.ordenDeTrabajo}
                                                    </td>
                                                    <td className="input">
                                                        {item.tipoServicio}
                                                    </td>
                                                    <td className="input">
                                                        {item.descripcion}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>

            </div>
            <div className="form-footer">
                <Button label="Cancelar" className="p-button-outlined" onClick={closePanel} type="button" />
                {!soloLectura &&
                    <Button label='Guardar' type="button" onClick={guardar} />
                }
            </div>

        </>
    )

}

export default DetalleAlmacen;
