/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import Buttons from "./Buttons";
import {defaultGridStyle} from "../Styles";
import FormBody from "../FormBody";
import Filters from "./Filters";

const FormFilter = ({filter, onClear, onFilter, onChange,  columns, flow, width, gridStyle, ...props}) => {
    columns = columns ?? 12;

    const children = React.Children.toArray(props.children);
    const fieldsContainer = children.find(e=>e.type === Filters);
    const buttonsContainer = children.find(e=>e.type === Buttons);
    const fields = React.Children.toArray(fieldsContainer.props.children);

    if (fieldsContainer == null)
        alert('Form filter should have a Filters child');

    function onEnterKeyPress(event) {
        if (event.charCode === 13) {
            onFilter(filter);
            event.preventDefault();
        }
    }

    return (
        <div className="form-filtro main-filter form-filter" style={{...defaultGridStyle(width, columns),...gridStyle}} onKeyPress={onEnterKeyPress}>
            <FormBody onChange={onChange} values={filter} fields={fields}/>
            {buttonsContainer != null &&
                <Buttons {...buttonsContainer?.props}
                         buttons={React.Children.toArray(buttonsContainer.props.children)}
                         onFilter={()=>onFilter(filter)}
                         onClear={onClear}
                />
            }
        </div>
    )
}

export default FormFilter;
