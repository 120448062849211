import {OverlayPanel} from "primereact/overlaypanel";
import React from "react";
import {useIndicadores} from "./IndicadoresProvider";
import MenuLocalizarUnidades from "./MenuLocalizarUnidades";
import {tipoIndicador} from "../SeguimentoConstantes";
import MenuMostrarRuta from "./MenuMostrarRuta";

const IndicadoresClickOverlay = () => {
    const {
        overlayClickRef,
        tipo
    } = useIndicadores()




    return (
        <OverlayPanel id={'indicador-click-overlay'} ref={overlayClickRef} style={{width: '350px'}}
                      className="seg-indicador-overlay" showCloseIcon={false}>
            {tipo === tipoIndicador.followInMap &&
                <MenuLocalizarUnidades>
                </MenuLocalizarUnidades>
            }
            {tipo === tipoIndicador.showRoute &&
                <MenuMostrarRuta>
                </MenuMostrarRuta>
            }
        </OverlayPanel>
    )
}
export default IndicadoresClickOverlay;