
import React from "react";
import FieldInputMask from "../fieldInputMask";


const fieldInputMAC = ({colMd, colSm, label, name, onChange, onBlur, value, keyFilter, rows, disabled,formatType,error, errors,required}) => {


    return (
        <>

          {label   &&
           <FieldInputMask maskVariable="**-**-**-**-**-**" required={required} name={name} label={label} colMd={colMd} errors={errors}
            value={ value }  onChange={onChange}/>
          }

           {!label  &&
            <FieldInputMask maskVariable="**-**-**-**-**-**" name={name} colMd={colMd} errors={errors}
             value={ value }  onChange={onChange}/>            
            }
        </>
    )
};


export default fieldInputMAC;