import React from "react";
import { useState } from "react";
import agGridHelper from "../../shared/ag-grid-helper";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import { Sidebar } from "primereact/sidebar";
import useApiDirecciones from "../../api/catalogos/useApiDirecciones";
import FiltroDirecciones from "./filtroDirecciones";
import DetalleDireccion from "./direccionesDetalle";
import { getSessionState } from "../../hooks/useSessionState";
import Filtro from "../../shared/componentes/filtro";
import constantes from "../constantes";
import useSeguridad from "../../Seguridad/useSeguridad";

const Direcciones = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.direcciones);
    const filtroKey = 'FiltroDirecciones';
    const [datos, setDatos] = useState([]);
    const [filtro, setFiltro] = useState({});
    const [gridApi, setGridApi] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [direccionId, setDireccionId] = useState(0);
    const api = useApiDirecciones();


    async function buscar(filtro, paramsApi) {
        agGridHelper.showLoad(gridApi, paramsApi, true);
        setFiltro(filtro);
        var filter = {
            ciudadId: filtro?.ciudad?.id,
            estadoId: filtro?.estado?.id,
            nombre: filtro?.nombre
        };
        setDatos(await api.filtrar(filter ?? getSessionState(filtroKey)));
        agGridHelper.hideLoad(gridApi, paramsApi, false);

    }


    async function actualizarGeocercas() {
        await api.actualizarGeocercas();
    }

    const onGridReady = async (params) => {
        setGridApi(params.api);
    };

    async function nuevo() {
        setSidebarVisible(true);
        setDireccionId(0);
    }

    async function onSave() {
        setSidebarVisible(false);
        await buscar(filtro);
    }


    const ButtonsHeader = () => {
        return (
            <div className={'ag-grid header-icons'}>
                <ButtonIcon title={'Actualizar Geocercas'} onClick={actualizarGeocercas} iconName={'GrMapLocation'} />
                {!soloLectura &&
                    <ButtonIcon title="Agregar" className={'grid-action'} iconName={'MdAddBox'} onClick={nuevo} />
                }
            </div>
        );
    };

    const editDireccion = (id) => {
        setSidebarVisible(true);
        setDireccionId(id);
    };

    const RowButtons = ({ data, rowIndex, api, context }) => (
        <div className={'ag-grid row-icons'}>

            <ButtonIcon title={'Editar'} iconName={'FaRegEdit'} onClick={() => context.editDireccion(data.id)} />
        </div>
    );


    return (
        <>
            <Sidebar visible={sidebarVisible} position="right" dismissable={false}
                className="p-sidebar-md" onHide={() => setSidebarVisible(false)}>
                <DetalleDireccion direccionId={direccionId} onSave={onSave}
                    close={() => setSidebarVisible(false)} soloLectura={soloLectura} />
            </Sidebar>

            <Filtro filtroKey={filtroKey} handleSubmit={buscar} defaultValue={{ nombre: '' }} gridApi={gridApi}
                classButtons="field-iconos btn-group">
                <FiltroDirecciones />
            </Filtro>

            <div className="ag-theme-balham grid-principal com-perfiles">
                <AgGridReact
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    className=""
                    frameworkComponents={
                        {
                            rowButtons: RowButtons,
                            goToDetail: agGridHelper.GoToDetailFormatter,
                            buttonsHeader: ButtonsHeader,
                            dateFormatter: agGridHelper.DateFormatter,
                            imageFormatter: agGridHelper.ImageFormatter
                        }
                    }
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    rowHeight={35}
                    context={{
                        editDireccion: editDireccion
                    }}
                    getRowClass={(params) => params.data.nombreColor}
                >
                    <AgGridColumn maxWidth={120} field="rfc" headerName="RFC" text
                        cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn maxWidth={400} field="nombre" headerName="Nombre" text
                        cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn minWidth={400} field="direccionCompleta" headerName="Direccion" text
                        cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn maxWidth={80}
                        headerName="VER"
                        cellRenderer="rowButtons"
                        pinned={'right'}
                        cellClass={'left'}
                        headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>
        </>
    )
};

export default Direcciones;
