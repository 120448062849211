/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState} from 'react'
import {TabPanel, TabView} from "primereact/tabview";
import Field from "../Field";
import PropTypes from "prop-types";

const FormTabView = ({values, onChange, onValidate, submitted, ...props}) => {

    const children = React.Children.toArray(props.children);

    const [validationErrors, setValidationErrors] = useState(new Set());

    const handleValidation = (field, hasError)=>{
        let newErrors = new Set(validationErrors);
        if (hasError)
            newErrors.add(field)
        else
            newErrors.delete(field);

        setValidationErrors(newErrors);
        onValidate(field, hasError);
    }



    return (
        <>
            <TabView>
                {children.map((item, index) => (
                    <TabPanel header={item.props.title} key={index}  headerClassName={ `tab-header`}
                              rightIcon={ submitted && validationErrors.has(item.props.field)  ? 'pi pi-exclamation-circle' : ''}
                    >
                        <Field {...item.props}
                               values={values??{}}
                               onChange={onChange}
                               onValidate={(error) => handleValidation(item.props?.field, error)}
                        />
                    </TabPanel>
                ))}
            </TabView>
        </>
    )
}



export default FormTabView;

Field.propTypes = {
    values: PropTypes.object,
    onChange: PropTypes.func
};

//todos los form containers deben tener esta propiedad es para indicarle al form body que es un container y crearlo de forma adecuada
FormTabView.defaultProps = {
    isFormContainer: true,
};

