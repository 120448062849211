import useServiceFactory from '../serviceFactory'

const useApiCotizaciones = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URL});
    return {
        filtrar: async (params) => service.get(`/operaciones/comercial/cotizaciones`, {params}),
        resumenRuta: async (params) =>
            service.post(`operaciones/comercial/cotizaciones/rutas/resumen`, params,{showLoading: true, showSuccess:false}),
        detalleCasetas: async (params, casetasExcluidas) =>
            service.get(`operaciones/comercial/cotizaciones/rutas/casetas/${casetasExcluidas}`, {params}),
        rutaDefault: async (unidadDeMedidaId,tipoUnidadId) => service.get(`/operaciones/comercial/cotizaciones/rutaDefault/${unidadDeMedidaId}/tipoUnidad/${tipoUnidadId}`, {}),
        viajesDeRegreso: async (origenId, destinoId, tipoUnidadId, rutaId) => service.get(`operaciones/comercial/cotizaciones/viajesDeRegreso/${origenId}/${destinoId}/${tipoUnidadId}/${rutaId ?? 0}`, {}),
        guardar: async (params) => service.post(`operaciones/comercial/cotizaciones`, params),
        obtener: async (id) => service.get(`operaciones/comercial/cotizaciones/${id}`, {}),
        clonar: async (id) => service.get(`operaciones/comercial/cotizaciones/${id}/clon`, {}),
        crearConvenio: async (params) => service.post(`operaciones/comercial/cotizaciones/crearConvenio`, params),
        cancelar: async (id) => service.delete(`operaciones/comercial/cotizaciones/${id}`, {}),
        descargar: async (id, numero) => service.get(`operaciones/comercial/cotizaciones/${id}/pdf/NA/0`,
            {
                showLoading: true,
                download: true,
                fileName: `cotizacion_${numero}.pdf`
            }),
        descargarVistaPrevia: async (numero, url) => service.get(url,
            {
                showLoading: true,
                download: true,
                fileName: `cotizacion_${numero}.pdf`
            }),
        excel: async (params) => service.get(`operaciones/comercial/cotizaciones/excel`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `cotizaciones.xlsx`
            }),
        comentarios: async (id, params) => service.put(`operaciones/comercial/cotizaciones/comentarios/${id}`, params)
    };
};

export default useApiCotizaciones;
