import React from "react";
import {Link} from "react-router-dom";

const GoToDetailFormatter = ({data, colDef }) => (
    <Link to={{
        pathname: `${colDef.path}/${data.id}`,
        state:{data}
    }}
    >VER</Link>
);

export default GoToDetailFormatter;
