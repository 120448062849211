import useServiceFactory from "../serviceFactory";
const apiUrl = `/catalogos/Geocercas`;
const apiGeocercasUrl = `/operaciones/gps`;

const useApiGeocercas = () => {
    const service = useServiceFactory({url: process.env.REACT_APP_API_URL});
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, {params}),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, {showLoading: true}),
        obtenerActivas: async () => service.get(`${apiUrl}/activas`, {showLoading: false}),
        actualizar: async (id,params) => service.put(`${apiUrl}/${id}`, params),
        actualizarGeocercas :  async () => service.put(`${apiGeocercasUrl}/geocercas`, {showLoading: true})        
    }
}

export default useApiGeocercas;
