import React, { useState } from "react";
import FiltroConvenios from "./filtroConvenios";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import agGridHelper from "../../../shared/ag-grid-helper";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import useApiConvenios from "../../../api/comercial/convenios";
import Filtro from "../../../shared/componentes/filtro";
import { getSessionState } from "../../../hooks/useSessionState";

const Convenios = ({ soloLectura }) => {
    const filtroKey = "filtrosConvenios";
    const [datos, setDatos] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const apiConvenios = useApiConvenios();
    const fechaInicio = new Date();
    fechaInicio.setDate(fechaInicio.getDate() - 14);
    const defaultFilter = { estatus: [], fechas: [fechaInicio, new Date()] };

    async function buscar(filtro, paramsApi) {
        filtro = filtro ?? getSessionState(filtroKey);
        const nuevoFiltro = {
            ...filtro,
            numeroConvenio: filtro.numeroConvenio,
            cliente: filtro.cliente?.nombre,
            subClienteId: filtro.subCliente?.id,
            tipoUnidadId: filtro.tipoUnidad?.id ?? 0,
            estatus: filtro.estatus.map((e) => e.id),
            origen: filtro.rutaOrigen?.nombre,
            destino: filtro.rutaDestino?.nombre,
        };

        agGridHelper.showLoad(gridApi, paramsApi, true);
        setDatos(await apiConvenios.filtrar(nuevoFiltro));
        agGridHelper.hideLoad(gridApi, paramsApi, false);
    }

    const onGridReady = async (params) => {
        setGridApi(params.api);
    };

    async function actualizarGeocercas() {
        await apiConvenios.actualizarGeocercas();
    }

    const ButtonsHeader = ({ api }) => {
        return (
            <div className={"ag-grid header-icons"}>
                <ButtonIcon title={"Actualizar Geocercas"} onClick={actualizarGeocercas} iconName={"GrMapLocation"} />
                <ButtonIcon title={"Descargar"} onClick={() => agGridHelper.export(api)} iconName={"BiDownload"} />
            </div>
        );
    };

    return (
        <>
            <Filtro filtroKey={filtroKey} handleSubmit={buscar} defaultValue={defaultFilter} gridApi={gridApi}>
                <FiltroConvenios />
            </Filtro>

            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    rowSelection={"single"}
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    frameworkComponents={{
                        goToDetail: agGridHelper.GoToDetailFormatter,
                        buttonsHeader: ButtonsHeader,
                        dateFormatter: agGridHelper.DateFormatter,
                    }}
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    getRowClass={(params) => params.data.nombreColor}
                >
                    <AgGridColumn
                        field="numeroConvenio"
                        headerName="NO. CONVENIO"
                        cellClass={"center"}
                        width={150}
                        resizable={true}
                    />
                    <AgGridColumn
                        field="cliente"
                        headerName="CLIENTE"
                        headerClass={"left"}
                        cellClass={"left"}
                        width={300}
                        resizable={true}
                    />
                    <AgGridColumn
                        field="subCliente"
                        headerName="SUBCLIENTE"
                        headerClass={"left"}
                        cellClass={"left"}
                        width={300}
                        resizable={true}
                    />
                    <AgGridColumn
                        field="rutaCiudades"
                        headerName="RUTA"
                        width={100}
                        resizable={true}
                        cellClass={"left"}
                    />
                    <AgGridColumn
                        field="tipoUnidad"
                        headerName="TIPO DE UNIDAD"
                        minWidth={100}
                        flex={3}
                        cellClass={"left"}
                        resizable={true}
                    />
                    <AgGridColumn
                        field="fecha"
                        headerName="FECHA"
                        minWidth={100}
                        cellRenderer="dateFormatter"
                        cellClass={"center"}
                        flex={2}
                        resizable={true}
                    />
                    <AgGridColumn
                        field="codigoRuta"
                        headerName="CODIGO DE RUTA"
                        minWidth={100}
                        flex={3}
                        resizable={true}
                    />
                    <AgGridColumn field="ruta" headerName="NOMBRE CONVENIO" minWidth={300} flex={3} resizable={true} />
                    <AgGridColumn
                        field="facturacion"
                        headerName="FACTURACIÓN"
                        headerClass={"center"}
                        cellClass={"right"}
                        valueFormatter={agGridHelper.currencyFormatter}
                        resizable={true}
                    />
                    <AgGridColumn
                        field="numeroDeStops"
                        headerName="# STOPS"
                        minWidth={70}
                        cellClass={"center"}
                        resizable={true}
                        flex={2}
                    />
                    <AgGridColumn
                        field="totalDocumentos"
                        headerName="# Documentos"
                        minWidth={100}
                        cellClass={"center"}
                        resizable={true}
                    />
                    <AgGridColumn field="estatus" headerName="ESTATUS" cellClass={"center"} resizable={true} />
                    <AgGridColumn
                        maxWidth={120}
                        headerName="VER"
                        cellRenderer="goToDetail"
                        pinned={"right"}
                        path="/comercial/editar_convenio"
                        headerComponent="buttonsHeader"
                        resizable={true}
                    />
                </AgGridReact>
            </div>
        </>
    );
};

export default Convenios;
