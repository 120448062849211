/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'

//only as a wrapper this will be substituted by Sub Form component on execution time
const Filters = () => {
    return (
        <>

        </>
    )
}

export default Filters;
