import React from "react";
import { useState } from "react";
import agGridHelper from "../../shared/ag-grid-helper";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import { Sidebar } from "primereact/sidebar";
import useApiClientes from "../../api/catalogos/useApiClientes";
import FiltroClientes from "./filtroClientes";
import { getSessionState } from "../../hooks/useSessionState";
import constantes from "../constantes";
import Filtro from "../../shared/componentes/filtro";
import DetalleCliente from "./detalleCliente";
import "./clientes.css";
import useSeguridad from "../../Seguridad/useSeguridad";

const Clientes = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.clientes);
    const filtroKey = 'FiltroClientes';
    const [datos, setDatos] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [clienteId, setClienteId] = useState(false);
    const api = useApiClientes();

    async function buscar(filtro, paramsApi) {
        agGridHelper.showLoad(gridApi, paramsApi, true);
        var filtroC = { ClienteId: filtro.cliente, Estatus: filtro.estatus };
        setDatos(await api.filtrar(filtroC ?? getSessionState(filtroKey)));
        agGridHelper.hideLoad(gridApi, paramsApi, false);

    }

    const onGridReady = async (params) => {
        setGridApi(params.api);
    };

    async function nuevo() {
        setSidebarVisible(true);
        setClienteId(0);
    }

    async function onSave() {
        setSidebarVisible(false);
        await buscar(getSessionState(filtroKey));
    }

    function exportar() {
        api.excel(getSessionState(filtroKey));
    }

    const ButtonsHeader = () => {
        return (
            <div className={'ag-grid header-icons'}>
                {<ButtonIcon title={'Descargar'} onClick={exportar} iconName={'BiDownload'} />}
                {!soloLectura &&
                    <ButtonIcon title="Agregar" className={'grid-action'} iconName={'MdAddBox'} onClick={nuevo} />
                }
            </div>
        );
    }; 

    const editUsuarios = (id) => {
        setSidebarVisible(true);
        setClienteId(id);
    };


    const RowButtons = ({ data, rowIndex, api, context }) => (
        <div className={'ag-grid row-icons'}>
            <ButtonIcon title={'Editar'} iconName={'FaRegEdit'} onClick={() => context.editUsuarios(data.id)} />
        </div>
    );

    return (
        <>
            <Sidebar visible={sidebarVisible} position="right" dismissable={false}
                className="p-sidebar-xl" onHide={() => setSidebarVisible(false)}>
                <DetalleCliente clienteId={clienteId} onSave={onSave} soloLectura={soloLectura} />
            </Sidebar>

            <Filtro filtroKey={filtroKey} handleSubmit={buscar} classButtons="field-iconos btn-group" gridApi={gridApi}>
                <FiltroClientes />
            </Filtro>
            {/* Clientes */}
            <div className="ag-theme-balham grid-principal com-usuarioes">
                <AgGridReact
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    className="left"
                    frameworkComponents={
                        {
                            rowButtons: RowButtons,
                            goToDetail: agGridHelper.GoToDetailFormatter,
                            buttonsHeader: ButtonsHeader,
                            dateFormatter: agGridHelper.DateFormatter,
                            imageFormatter: agGridHelper.ImageFormatter
                        }
                    }
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    rowHeight={35}
                    context={{
                        editUsuarios: editUsuarios
                    }}
                    getRowClass={(params) => params.data.nombreColor}
                >
                    <AgGridColumn field="nombreCompleto" headerName="CLIENTE" text cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn field="rfc" headerName="RFC" text cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn field="tipoCliente" headerName="TIPO CLIENTE" text cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn field="telefono" headerName="TELEFONO" text cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn field="correo" headerName="CORREO" text cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn field="estatus" headerName="ESTATUS" text cellStyle={{ textAlign: "left" }} />

                    <AgGridColumn maxWidth={80}
                        headerName="VER"
                        cellRenderer="rowButtons"
                        pinned={'right'}
                        cellClass={'left'}
                        headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>
        </>
    )
};

export default Clientes;
