import React, {useEffect, useState} from "react";
import { InputMask } from 'primereact/inputmask';
import {useReadOnly} from "../forms/ReadOnlyProvider";


const FieldInputMask = ({colMd, colSm, label, name, onChange, onBlur, value, keyFilter, className,
                            rows, disabled,formatType,error, errors,maskVariable,required}) => {

    const {readOnly} = useReadOnly()??{};
    disabled = disabled ?? readOnly;

    const [selectedValue, setSelectedValue] = useState(value);

    function onValueChange(event) {
        setSelectedValue(event.target.value);
        if (onChange != null) {
            onChange(event.target.value,name);
        }
    }

    useEffect(() => {
        let newValue = value == null ? '' : value;
        setSelectedValue(newValue);
    }, [value]);




    return (
        <>
         {label   &&
            <div className={`form-group col-md-${colMd} col-sm-${colSm != null ? colSm : '12'}`}>
               {required ? '*' : ''}<label htmlFor={name}>{label}</label>               
                <InputMask
                id={name}
                keyfilter={keyFilter}            
                className={`form-control ${className??''} ${ (error || errors?.[name]) ? 'error' : ''}`}
                onBlur={onBlur}
                onChange={onValueChange}
                value={selectedValue}
                disabled={disabled}
                rows={rows}              
                mask={maskVariable}/>
            </div>
            }



   {!label  &&
             <InputMask  id={name}
             keyfilter={keyFilter}
             className={`form-control ${className??''} ${ (error || errors?.[name]) ? 'error' : ''}`}
             onBlur={onBlur}
             onChange={onValueChange}
             value={selectedValue}
             disabled={disabled }
             rows={rows}
             mask={maskVariable}
            />
            }

        </>
    )
};


export default FieldInputMask;
