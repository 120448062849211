import useServiceFactory from '../serviceFactory'
const apiUrl = `/operaciones/comercial/expeditados`;
const apiGeocercasUrl = `/operaciones/gps`;
const apiClientes = `/catalogos/Clientes`;
const useApiExpeditados = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params) => service.get(apiUrl, {params}),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, {}),
        obtenerSubcliente: async (id) => service.get(`${apiClientes}/${id}/informacionSubCliente`, {}),
        guardar: async (noStops, params) => service.post(`${apiUrl}/${noStops}`, params),
        clonar: async (id) => service.post(`${apiUrl}/${id}/clonar`, {}),
        actualizar: async (id, params) => service.put(`${apiUrl}/${id}`, params),
        completar: async (id, params) => service.put(`${apiUrl}/${id}/completar`, params),
        suspender: async (id) => service.delete(`${apiUrl}/${id}`, {}),
        actualizarParada: async (id, paradaId, params) => service.put(`${apiUrl}/${id}/paradas/${paradaId}`, params),
        agregarParada: async (id, tipoViajeId) => service.post(`${apiUrl}/${id}/paradas/${tipoViajeId}`, {}),
        eliminarParada: async (id) => service.delete(`${apiUrl}/${id}/paradas`, {}),
        cambiaTipo: async (id, esRedondo) => service.post(`${apiUrl}/${id}/cambiaTipo/${esRedondo}`, {}),
        actualizarGeocercas :  async () => service.put(`${apiGeocercasUrl}/geocercas`, {showLoading: true}),        
    }
};

export default useApiExpeditados ;