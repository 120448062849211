import React, { useState }  from "react";
import useApiIncapacidad from "../../api/administracion/useApiIncapacidad";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import agGridHelper from "../../shared/ag-grid-helper";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import Filtro from "../../shared/componentes/filtro";
import FieldCalendarRange from "../../shared/controls/fieldCalendarRange";
import FieldMultiSelect from "../../shared/controls/fieldMultiSelect";
import { getSessionState } from "../../hooks/useSessionState";
import IncapacidadDetalle from "./IncapacidadDetalle"
import { Sidebar } from "primereact/sidebar";
import moment from "moment";
import {FormattedDate} from "react-intl";
import Seccion from "./Seccion";
import "./secciones.css";
import useSessionState from "../../hooks/useSessionState";
import constantes from "../../catalogos/constantes";
const filtroInicial = {
    fechas: [moment().startOf("month")._d, moment().startOf("day")._d],
  
};
const Incapacidad = ({ soloLectura }) => {
    const [datos, setDatos] = useState([]);  
    const filtroKey = 'FiltroIncapacidad';
    const api = useApiIncapacidad();
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [incapacidadId, setIncapacidadId] = useState(false);
    const [movimientoActivoId, setMovimientoId] = useState(constantes.esquemaPago.liquidacion)
    const [filtro, setFiltro] = useSessionState(filtroKey, {}, filtroInicial);
    
    
    const buscar = async (filtro, movimientoFiltroId) => {
        const newFiltro = {...filtro, esquemaPagos: movimientoFiltroId ?? movimientoActivoId};
        const datos = await api.filtrar(newFiltro)
        setDatos(datos);
        setFiltro(filtro);
    };
    const RowButtons = ({ data }) => (
        <div className={'ag-grid row-icons'}>
            <ButtonIcon title={'Editar'} iconName={'FaRegEdit'} onClick={() => verIncapacidad(data.incapacidadId)}/>
        </div>
    );

    const verIncapacidad = (id) => {
        setSidebarVisible(true);
        setIncapacidadId(id);
    };

    const ButtonsHeader = () => {
        return (
            <div className="ag-grid header-icons">
                <ButtonIcon title="Descargar" onClick={exportar} iconName="BiDownload" />
                {!soloLectura &&
                          < ButtonIcon title="Agregar" className="grid-action" iconName="MdAddBox"
                          onClick={() => verIncapacidad(0)} />
                }
            </div>
        );
    };
    function exportar() {
        api.excel(getSessionState(filtroKey));
    }

    const onSave = async () => {  
        setSidebarVisible(false);
        await buscar();

    }
    const DateCellRenderer = ({value}) => (
        <div>
            <FormattedDate value={value} weekday="short"/> &nbsp;
            {moment(value).format("DD/MM/YYYY")}
        </div>
    );
    const cerrar = async ()=>{
        setSidebarVisible(false)
    }
    
    const onChangeMovimiento = async (id)=>{
        setMovimientoId(id);
       await buscar(filtro, id);
    }
    return (
        <>
        <div className="main-screen tab">
        <Seccion movimientoActivoId={movimientoActivoId} onChange={onChangeMovimiento}/>
            <Sidebar visible={sidebarVisible} position="right" dismissable={false}
                className="p-sidebar-xl" onHide={() => setSidebarVisible(false)}>
             <IncapacidadDetalle incapacidadId={incapacidadId}  onSaved={onSave}
                    close={cerrar} />
            </Sidebar>          
            <Filtro filtroKey={filtroKey} handleSubmit={buscar} filtrarAlInicio={true}
                classButtons="field-iconos btn-group">
                <Filtros />
            </Filtro>
            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                      enableSorting={true}
                      enableFilter={true}
                      pagination={true}
                      frameworkComponents={
                          {
                              buttonsHeader: ButtonsHeader,
                              rowButtons: RowButtons,
                              goToDetail: agGridHelper.GoToDetailFormatter,
                              dateFormatter: agGridHelper.DateTimeFormatter,
                              dateCellRenderer: DateCellRenderer,
                          }
                      }
                    onGridReady={() => buscar(filtro).then()}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    rowHeight={35}
                >
                    
                    <AgGridColumn field="empleado" headerName="Empleado" flex={5} minWidth={210}/>                  
                    <AgGridColumn
                        field="fechaInicio"
                        headerName="Fecha Inicio"
                        cellClass="center"
                        cellRenderer="dateCellRenderer"
                        maxWidth={150}
                    />
                    <AgGridColumn
                        field="fechaFinal"
                        headerName="Fecha Final"
                        cellClass="center"
                        cellRenderer="dateCellRenderer"
                        maxWidth={150}
                    />

                    <AgGridColumn field="tipoIncapacidadId" headerName="Tipo Incapacidad" minWidth={80}/>
                   
                    <AgGridColumn maxWidth={80}
                        headerName="VER"
                        cellRenderer="rowButtons"
                        pinned={'right'}
                        cellClass={'left'}
                        headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>
         </div>
        </>
    )
}
const Filtros = ({ filtro, onChange }) => {
   
    return (
        <>
  
         <FieldMultiSelect name="empleadosId" source="empleados"   label="Empleado" colLg={2} colMd={3}
                value={filtro.empleadosId}  onChange={onChange} showFilter valueAsId={true} />

            <FieldCalendarRange name="fechas" label="Fechas" colLg={2} colMd={3} value={filtro.fechas}
                onChange={onChange} />
        </>
    )
};
export default Incapacidad;
