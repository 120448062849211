/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from "react";
import {Sidebar} from "primereact/sidebar";
import SimplePager from "../controls/SimplePager";
import PDFViewer from "mgr-pdf-viewer-react";
import {useEmpresa} from "../../layout/EmpresaProvider";

const uri = process.env.REACT_APP_API_URL;

const FilesViewer = ({title, urls, open, onHide}) => {
    const [index, setIndex] = useState(0);
    const [file, setFile] = useState({url:null, isImage:false, isPdf:false});

    const {empresaId} = useEmpresa();

    const setCurrentFile = useCallback( (index) => {
        const url = urls[index];
        const fullUrl = `${uri}/archivos/descargar/${empresaId}?url=${url}`;

        setFile({
            url: fullUrl,
            isImage: url.match(/\.(jpeg|jpg|gif|png)$/) != null,
            isPdf: url.match(/\.(pdf)$/) != null,
        })

      setIndex(index);
    },[urls])

    useEffect(() => {
        setCurrentFile(0);
    }, [open,setCurrentFile]);

    return (
        <>
            <Sidebar visible={open} position="right" dismissable={false}
                     className="p-sidebar-bg"
                     onHide={onHide}>
                <div className="form-container full-height">
                    <h5>{title}</h5>
                    {file.isImage &&
                        <div className="image-container">
                            <img alt="Imagen" src={file.url}/>
                        </div>
                    }
                    {file.isPdf &&
                        <PDFViewer document={{
                            url: file.url
                        }}/>
                    }
                </div>
                <div className="form-footer center">
                    <SimplePager index={index} length={urls.length} onChangeIndex={setCurrentFile}/>
                </div>
            </Sidebar>
        </>
    )
};
export default FilesViewer;

