/* eslint-disable react-hooks/exhaustive-deps */
import {Button} from "primereact/button";
import React, {useEffect, useState} from "react";
import _ from "lodash";
import {pendientes} from "./OrderToCashConstants";
import {Dialog} from "primereact/dialog";
import useApiOrderToCash from "../../api/operaciones/useApiOrderToCash";
import ExGrid from "../../shared/Grid/ExGrid";
import ExColumn from "../../shared/Grid/ExColumn";
import FormTextBox from "../../shared/forms/FormFields/FormTextBox";
import {useLoadingDispatch} from "../../layout/show-loading-context";
import FormInputNumber from "../../shared/forms/FormFields/FormInputNumber";

const SeleccionarViaje = ({viajes, datosRegistrar, onHide, onRegistered}) => {
     const [viajesSeleccionados, setViajesSeleccionados] =  useState([]);
    const [pendiente, setPendiente] = useState(null);
    const [form, setForm] = useState({submitted:false, valid:false});
    const dispatch = useLoadingDispatch();
    const mostrarViajes = datosRegistrar != null;

    const api= useApiOrderToCash();

    useEffect(()=>{
        if (mostrarViajes) {
            mostrarViajesSelccionados();
        }
        else {
            setViajesSeleccionados([]);
            setPendiente(null);
        }
    },[mostrarViajes, viajes])

    const mostrarViajesSelccionados = () => {
        const pendiente =  datosRegistrar.pendiente ?? (viajes.length > 0 ? viajes[0].pendiente : null);
        const orden = pendiente === pendientes.recepcionAdministracion ? 'orden' : 'cartaPorte';
        let nuevosViajes = _.sortBy(viajes, [orden]);
        setViajesSeleccionados(nuevosViajes);
        setPendiente(pendiente);
    };

    const registrarFechasFacturas = async () => {
        if (pendiente === pendientes.facturacionManual && !form.valid){
            dispatch({type: 'toast', message:'debe registrar todas las facturas', severity:'error'});
            return;
        }
        await api.registrarFechas(pendiente,viajesSeleccionados);
        onRegistered();
    };

    const renderFooterFacturas = () => {
        return (
            <div>
                <Button label="Registrar" icon="pi pi-check" onClick={registrarFechasFacturas} autoFocus/>
            </div>
        );
    };

    const onValidate = (error)=>{
        setForm({submitted: true, valid:!error});
    }

    return (
        <>
            <Dialog header="Registrar Fechas" visible={mostrarViajes} style={{width: '800px'}}
                    footer={renderFooterFacturas('displayBasic')} onHide={onHide}>
                <ExGrid
                    headerClass="header-class"
                    data={viajesSeleccionados}
                    onChange={setViajesSeleccionados}
                    getEntityId={(v)=>v.viajeId}
                    style={{maxHeight:'50vh'}}
                    onValidate={(valid)=> onValidate(valid)}
                >
                    <ExColumn field={'cliente'} title={'CLIENTE'} width={'3fr'}></ExColumn>
                    <ExColumn field={'cartaPorte'} title={'CARTA PORTE'} width={'1fr'}></ExColumn>
                    <ExColumn field={'monto'} title={'MONT0'} cellHeaderClass={'right'} cellClass={'right'} width={'1fr'}
                              cellRenderer={(props)=> <FormInputNumber {...props} mode="decimal" minFractionDigits={2} maxFractionDigits={2} disabled={pendiente !== pendientes.facturacionManual}/>}>
                    </ExColumn>
                    <ExColumn  field={'numeroFactura'} title={'NO. FACTURA'} visible={pendiente === pendientes.facturacionManual}
                               required cellRenderer={FormTextBox} ></ExColumn>
                </ExGrid>
                <div style={{marginTop:'10px'}}>
                    <span style={{fontWeight:'bold'}}> Total Viajes: </span> {viajesSeleccionados.length}
                </div>
            </Dialog>
        </>
    )
}

export default SeleccionarViaje;
