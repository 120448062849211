import React from "react";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import FieldDropDown from "../../shared/controls/fieldDropDown";

const FiltroMarcaLlanta = ({filtro, onChange}) => {
    const estatus = [{id: 1, nombre: "SI"}, {id: 2, nombre: "NO"}];
    return (
        <>
        <FieldTextBox name="nombre" label="Marca de Llanta" colMd={2} value={filtro.nombre} onChange={onChange} />
        <FieldDropDown name="estatus" options={estatus} label="Es Marca Interna" colMd={2} valueById={true}
                        value={filtro.estatus} onChange={onChange} />
        </>
    );
};

export default FiltroMarcaLlanta;