import React from "react";
import { TabPanel, TabView } from "primereact/tabview";
import useSessionState from "../../hooks/useSessionState";
import Movimientos from "./movimiento";
import constantes from "../../catalogos/constantes";
import useSeguridad from "../../Seguridad/useSeguridad";

const MovimientoSecciones = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.movimientos);
    const [activeIndex, setActiveIndex] = useSessionState('tabSeguimiento', 0);

    return (
        <>
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="main-tab-view">
          <TabPanel  headerStyle ={(seguridad.tieneAcceso(constantes.menus.movimientosSeccion) || soloLectura )?  {display:"inline"} : {display:"none"} } header="Movimientos">
                <Movimientos esSeccionAutorizar={false} soloLectura={soloLectura} esquemaPagos={constantes.esquemaPago.liquidacion} />
            </TabPanel>
            <TabPanel  headerStyle ={seguridad.tieneAcceso(constantes.menus.movimientosPorAutorizar) ?  {display:"inline"} : {display:"none"} } header="Movimientos por Autorizar">
                <Movimientos esSeccionAutorizar={true} soloLectura={soloLectura} esquemaPagos={constantes.esquemaPago.liquidacion}/>
            </TabPanel>
        </TabView>
         </>
    )
};

export default MovimientoSecciones;
