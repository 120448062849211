/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import moment from "moment";
import * as numeral from "numeral";
import FieldInputNumber from "../../../shared/controls/fieldInputNumber";
import {produce} from "immer";


const Anticipos = ({anticipos, onChange, soloConsulta}) => {

    const actualizar = (value, propertyName, index) => {
        const newValue = produce(anticipos, (draft) => {
            let row =draft[index];
            row[propertyName] = value;
            row.saldo = row.montoAnticipo - row.comprobado;
            row.noComprobado = Math.max(0, row.saldo);
            row.saldo = row.saldo * -1;
        });
        onChange(newValue,'anticipos');
    };
    const errors = null;

    return (
        <>
            <div className="form-row">
                <table className="table table-borderless">
                    <thead className="thead-light">
                    <tr>
                        <th style={{width: '15%'}}>VIAJE(CP)</th>
                        <th style={{width: '25%'}}>CONCEPTO</th>
                        <th style={{width: '30%'}}>NOTAS</th>
                        {/*<th style={{width: '30%'}}>COMENTARIOS</th>*/}
                        <th className="right" style={{width: '15%'}}>USUARIO COMPROBADO</th>
                        <th className="right" style={{width: '15%'}}>NO COMPROBADO</th>                        
                        <th className="center" style={{width: '15%'}}>COMPROBADO</th>

                    </tr>
                    </thead>
                    <tbody>
                        
                    {anticipos?.filter(e=>e.activo).map((item, index) => {
                        return (
                            <tr key={item.viajeAnticipoId}>
                                <td>{item.cartaPorte}</td>
                                <td className="p-d-flex p-flex-column">
                                    <span>{item.concepto}</span>
                                    <span>Fecha Otorgado: {moment(item.fecha).format('DD/MM/YYYY hh:mm a')}</span>
                                    <span>Monto Otorgado: <span className="bold">{numeral(item.montoAnticipo).format('$0,0.00')}</span></span>
                                </td>
                                <td>
                                    <span>{item.nota}</span>
                                </td>
                                {/*
                                <td>
                                    <FieldTextArea name="comentarios" value={item.comentarios} disabled={true}
                                                   onChange={(value, pname) => actualizar(value, pname, index)}
                                    />
                                </td>
                                */}
                                <td><span>{item.usuarioSolicito}</span></td>
                                <td className="right">{numeral(item.noComprobado).format('$0,0.00')}</td>
                                <td>
                                    <FieldInputNumber required name="comprobado" fractionDigits={2} errors={errors}
                                                      value={item?.comprobado } disabled= {soloConsulta}
                                                      onChange={(value, pname) => actualizar(value, pname, index)}/>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </>
    )
};


export default Anticipos;
