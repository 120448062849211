import React from "react";
import produce from "immer";
import InputFile from "../../../shared/controls/inputFile";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";

const Expediente = ({expediente, accesorios, errors, onChange}) => {
    function atualizarExpediente(index, archivo) {
        const newValue = produce(expediente, draft => {
                draft[index].archivo = archivo;
            }
        );
        onChange(newValue, 'expediente');
    }

    function actualizarAccesorio(index, value, propertyName) {
        const newValue = produce(accesorios, draft => {
                draft[index][propertyName] = value;
            }
        );
        onChange(newValue, 'accesorios');
    }

    function agregarAccesorio() {
        const nuevo = produce(accesorios,draft=> {
            draft.push({});}
        );
        onChange(nuevo,'accesorios');
    }

    function eliminarAccesorio(index) {
        const nuevo = produce(accesorios,draft=> {
            draft.splice(index, 1);}
        );

        onChange(nuevo,'accesorios');

    }

    return (
        <>
            <div className="form-row">
                <h5 className="col-12">Anexos</h5>
                <table className="table">
                    <thead className="thead-light">
                    <tr>
                        <th scope="col" style={{width: '50%'}}>TIPO DOCUMENTO</th>
                        <th scope="col" style={{width: '20%'}}/>
                    </tr>
                    </thead>
                    <tbody>
                    {expediente.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="input">
                                    {item.tipoDocumento.requerido ? '*' : '' }
                                    {item.tipoDocumento.nombre}
                                </td>
                                <td className="input center">
                                    <InputFile name="archivo" value={item.archivo}
                                               errors={errors[`expediente[${index}]`]}
                                               onChange={(value) => atualizarExpediente(index, value)}
                                    />
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <h5 className="col-12">Accesorios</h5>
                <table className="table">
                    <thead className="thead-light">
                    <tr>
                        <th scope="col" style={{width: '40%'}}>ACCESORIO</th>
                        <th scope="col" style={{width: '30%'}}>DESCRIPCION</th>
                        <th scope="col" style={{width: '15%'}}></th>
                        <th scope="col" className="center" style={{width: '15%'}}>ARCHIVO</th>
                        <th className={'input'} style={{width: '10%'}}>
                            <ButtonIcon title={'Agregar Ruta'} iconName={'SiAddthis'}
                                        onClick={agregarAccesorio}
                            />
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {accesorios?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="input">
                                    <FieldTextBox name="nombre"
                                                   value={ item.nombre}
                                                   errors={errors[`accesorios[${index}]`]}
                                                   onChange={(value, pname)=>actualizarAccesorio(index,value, pname)}/>
                                </td>
                                <td className="input">
                                    <FieldTextBox name="descripcion"
                                                   value={ item.descripcion}
                                                   errors={errors[`accesorios[${index}]`]}
                                                   onChange={(value, pname)=>actualizarAccesorio(index,value, pname)}/>
                                </td>
                                <td>
                                <FieldCheckBox name="inspeccion" label="Inspección" value={item.inspeccion} onChange={(value, pname)=>actualizarAccesorio(index,value, pname)}/>

                                </td>
                                <td className="input center">
                                    <InputFile name="archivo" value={item.archivo}
                                               errors={errors[`accesorios[${index}]`]}
                                               onChange={(value, pname) => actualizarAccesorio(index, value, pname)}
                                    />
                                </td>
                                <td className="input">
                                    <ButtonIcon title={'Eliminar'}
                                                onClick={() => eliminarAccesorio(index)}
                                                iconName={'RiDeleteBinLine'}/>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </>
    )
};

export default Expediente;
