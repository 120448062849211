import {Dialog} from "primereact/dialog";
import moment from "moment/moment";
import {Button} from "primereact/button";
import React, {useEffect, useState} from "react";
import alerts from "../../../../shared/alerts";
import {removeArrayAtIndex} from "../../../../shared/arrayUtility";
import useApiViajeSeguimiento from "../../../../api/operaciones/useApiViajesSeguimiento";

const TerminarViajesEnRuta = ({viaje, otrosViajesRuta, iniciarViaje, onClose}) => {

    const apiSeguimiento = useApiViajeSeguimiento();

    const [otrosViajes, setOtrosViajes] = useState([])

    useEffect(() => {
        setOtrosViajes(otrosViajesRuta.viajes)
    }, [otrosViajesRuta.viajes]);


    const handleIniciarViaje = async () => {
        await iniciarViaje();
        onClose()
    };

    const terminarViaje = async (viajeId, index) => {
        if (await alerts.preguntarSiNo("¿Desea cerrar el viaje?")) {
            await apiSeguimiento.terminar(viajeId);
            setOtrosViajes(removeArrayAtIndex(otrosViajes, index))
        }
    }


    const renderFooterModal = () => {
        return (
            <div className="p-1">
                <Button
                    className="btn-primary"
                    label={"Iniciar Viaje"}
                    type="button"
                    onClick={handleIniciarViaje}
                    disabled={otrosViajes.length >= 2}
                />

                <Button label="Cancelar" onClick={onClose} className="p-button-outlined"/>
            </div>
        );
    };

    return (<Dialog
        header={""}
        visible={otrosViajesRuta.visible}
        style={{width: "60%", height: "63%"}}
        footer={renderFooterModal()}
        onHide={onClose}
    >
        <div className="col-lg-12 col-md-12 col-sm-12">
            <h5 className="bold">Viajes en ruta con la unidad {viaje.unidad?.numeroEconomico}</h5>
            <table className="table table-borderless">
                <thead className="thead-light">
                <tr>
                    <th>Fecha Inicio</th>
                    <th>Fecha Fin</th>
                    <th>Cliente</th>
                    <th>Carta Porte</th>
                    <th>Ruta</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {otrosViajes?.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td className="input left">
                                {moment(item.fechaInicio).format("DD/MM/YYYY hh:mm a")}
                            </td>
                            <td className="input left">
                                {moment(item.fechaFin).format("DD/MM/YYYY hh:mm a")}
                            </td>
                            <td className="input">{item.cliente}</td>
                            <td className="input left">{item.cartaPorte}</td>
                            <td className="input">{item.ruta}</td>
                            <td className="input rigth">
                                <Button
                                    className="btn btn-sm btn-primary"
                                    style={{backgroundColor: "green"}}
                                    label="Cerrar Viaje"
                                    type="button"
                                    onClick={(e) => terminarViaje(item.viajeId, index)}
                                />
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    </Dialog>)
}
export default TerminarViajesEnRuta;