import React from 'react'
import useSessionState from "../hooks/useSessionState";
import constantes from "../operaciones/constantes";

const empresaContext = React.createContext(undefined)

const useEmpresaProvider = () => {
    const [empresa, setEmpresa] = useSessionState('EmpresaSeleccionada',{ disabled: false, id: 0, nombre:'' })


    const disable = () => {
        setTimeout(()=>{
            setEmpresa({...empresa,  disabled: true })
        },10)
    }

    const enable = () => {
        setEmpresa({...empresa,  disabled: false })
    }

    const onChangeEmpresa =(nuevaEmpresa)=>{
        setEmpresa({...empresa, ...nuevaEmpresa })
    }

    const actualizarDefault = (nuevaEmpresa) =>{
        if ((empresa?.id??0) === 0)
            onChangeEmpresa(nuevaEmpresa)
    }

    const getUnidadMedidaDefault = () =>{
        return empresa.unidadDistanciaDefault ??
                //todo temporalmente por que los usuarios pueden tener ya seleccionada la empresa, si la empresa es SFC y no viene configurada le asigna millas
                (empresa.id===4 ? constantes.millas : constantes.kilometros)
    }

    return {
        disable,
        enable,
        onChangeEmpresa,
        actualizarDefault,
        empresa,
        id: empresa.id,
        empresaId: empresa.id,
        getUnidadMedidaDefault: getUnidadMedidaDefault
    }
}

export const EmpresaProvider = function ({ children }) {
    const  empresa = useEmpresaProvider()

    return (
        <>
            <empresaContext.Provider value={empresa}>
                {children}
            </empresaContext.Provider>
        </>
    )
}


export default EmpresaProvider

export const useEmpresa = () => {
    return React.useContext(empresaContext)
}
