import {useEffect, useState} from "react";

const useForm = (initialValues) => {
    const [firstLoad, setFirstLoad] = useState(true);
    const [values, setValues] = useState({});
    const [isValid, setIsValid] = useState(true);


    useEffect(() => {
        if (firstLoad) {
            setValues(initialValues ?? {});
            setFirstLoad(false);
        }
    }, [initialValues, firstLoad]);


    const onChange = (values) => {
        setValues(values)
    }

    const onClear = () => {
        setValues({...initialValues});
    }

    const onValidate = (hasErrors)=>{
        setIsValid(!hasErrors);
    }

    return {
        values,
        setValues,
        onChange,
        onClear,
        onValidate,
        isValid
    };
};

export default useForm;
