import DateFormatter from "./dateFormatter";
import GoToDetailFormatter from "./goToDetailFormatter";
import * as numeral from "numeral";
import ImageFormatter from "./imageFormatter";
import DateTimeFormatter from "./dateTimeFormatter";

function showLoad(stateGripApi, paramsApi) {
    const agGridApi = stateGripApi == null ? paramsApi : stateGripApi;
    if (agGridApi == null) {
        return;
    }
    agGridApi.showLoadingOverlay();
}

function hideLoad(stateGripApi, paramsApi) {
    const agGridApi = stateGripApi == null ? paramsApi : stateGripApi;
    if (agGridApi == null) {
        return;
    }
    agGridApi.hideOverlay();
}

function currencyFormatter(cell) {
    return numeral(cell.value).format('$0,0.00');
}

function decimalFormatter(cell) {
    return numeral(cell.value).format('0,0.00');
}
function tipoCambioFormatter(cell) {
    return numeral(cell.value).format('$ 0,0.0000');
}
function getData(api) {
    const data = [];
    api.forEachNode(function(rowNode) {
        data.push(rowNode.data)
    });
    return data;
}

function insertDataAt(api, data, index) {
    let rows = getData(api);
    rows.splice(index,0, data);
    api.setRowData(rows);
    api.redrawRows();
}

function deleteData(api, index) {
    const data = [];
    api.forEachNode(function(rowNode, rowIndex) {
        if (rowIndex !== index )
            data.push(rowNode.data)
    });
    api.setRowData(data);
    api.redrawRows();
}

function updateData(api, item) {
    const data = [];
    let updated = false;
    api.forEachNode(function(rowNode) {
        if (rowNode.data.key === item.key ) {
            data.push(item);
            updated = true;
        }
        else {
            data.push(rowNode.data);
        }
    });
    if (updated === false)
        data.push(item);

    api.setRowData(data);
    api.redrawRows();
}

function setHeaderName(api, names) {
    const columns = api.getColumnDefs();
    for (let name of names ){
        columns[name.index].headerName  = name.name;
    }
    api.setColumnDefs(columns);
}

const agGridHelpers = {
    DateFormatter,
    DateTimeFormatter,
    GoToDetailFormatter,
    ImageFormatter,
    showLoad,
    hideLoad,
    loadingOverlay: '<span className="ag-overlay-loading-center">Cargando los datos</span>',
    defaultColumns: {
        flex: 4,
        minWidth: 100,
        sortable: true
    },
    export:(api)=> {
        api.exportDataAsCsv({
            columnSeparator: ','
        })
    },
    tipoCambioFormatter,
    decimalFormatter,
    currencyFormatter,
    getData,
    deleteData,
    updateData,
    insertDataAt,
    setHeaderName


};

export default agGridHelpers;