/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from 'react'
import ExHeader from "./exHeader";
import ExCell from "./ExCell";
import "./ExGrid.css"
import PropTypes from "prop-types";
import {v4 as uuidv4} from 'uuid';

const ExGrid = ({
                    data,
                    className,
                    style,
                    rowHeight,
                    rowClass,
                    headerClass,
                    onChange,
                    showAddDeleteColumn,
                    createNewEntity,
                    getEntityId,
                    name,
                    onValidate,
                    customValidations,
                    formDisabled,
                    visibleHeader,
                    ...props
                }) => {

    visibleHeader = visibleHeader ?? true;

    const [validations, setValidations] = useState(new Map());

    const children = React.Children.toArray(props.children);


    const getGridStyle = () => {
        return {
            gridTemplateColumns: children.filter(e=>e.props?.visible !== false ).map(e => e.props.width ?? '1fr').join(' ')
        };
    }

    const onCellChanged = (field, value, row, index) => {
        const newRow = {...row, [field]: value};
        const newData = data.map((e, rowIndex) => {
            return rowIndex === index ? newRow : e;
        })
        onChange(newData);
    };


    const onRowChanged = (newValues, row, index) => {
        const newRow = {...row, ...newValues};
        const newData = data.map((e, rowIndex) => {
            return rowIndex === index ? newRow : e;
        })
        onChange(newData);
    };

    const onAdd = () => {
        onChange([...(data ?? []), createNewEntity == null ?
            {_uniqueUid: uuidv4()} :
            {...createNewEntity(), _uniqueUid: uuidv4()}
        ]);
    }

    const deleteAtIndex = (index, row) => {
        const newData = data.filter((e, rowIndex) => index !== rowIndex);
        onChange(newData);
        if (validations.size > 0) {
            let newErrors = new Map(validations)
            newErrors.delete(getRowId(row))
            updateValidation(newErrors);
        }
    }

    const onCellValidate = (field, error, row) => {
        const rowId = getRowId(row);
        let newErrors = new Map(validations);
        let rowErrors = newErrors.has(rowId) ? newErrors.get(rowId) : [];
        if (error) {
            newErrors.set(rowId, [...rowErrors, field]);
        } else if (newErrors.has(rowId)) {
            rowErrors = rowErrors.filter(e => e !== field);
            if (rowErrors.length > 0) {
                newErrors.set(rowId, rowErrors);
            } else {
                newErrors.delete(rowId);
            }
        }

        updateValidation(newErrors);

    }

    const getRowId = (row) => {
        if (getEntityId == null)
            console.error('getEntityId method must be implemented in order to use validations')
        return getEntityId(row);
    }

    const updateValidation = (newValidations) => {
        setValidations(newValidations);
        if (onValidate != null)
            onValidate(newValidations.size > 0);
    }

    const getRowClass = (item)=>{
        if (typeof rowClass === 'function') {
            return rowClass(item);
        }
        return rowClass ??'';
    }

    return (
        <>
            <div className={`ex-grid  ${className ?? ''}`} style={{...getGridStyle(), ...style}}>
                {/*HEADER*/}
                {visibleHeader &&
                    <>
                        {children?.map((item) => (
                            <React.Fragment key={item.field}>
                                {item.props?.visible !== false &&
                                    <div className={`grid-header ${item.props.cellHeaderClass ?? ''}`}>
                                        <ExHeader formDisabled={formDisabled} {...item.props} onAdd={onAdd}/>
                                    </div>
                                }
                            </React.Fragment>
                        ))}
                    </>
                }
                {/*ROWS*/}
                {data?.map((row, rowIndex) => (
                    <React.Fragment key={rowIndex}>
                        {children?.map((item) => (
                            <React.Fragment key={item.field}>
                                {item.props?.visible !== false &&
                                    <div className={`grid-cell ${item.props?.cellClass ?? ''} ${getRowClass(row)}`}>
                                        <ExCell {...item.props}
                                                row={row}
                                                rowIndex={rowIndex}
                                                data={data}
                                                tableName={name}
                                                formDisabled={formDisabled}
                                                onChange={onCellChanged}
                                                onRowChanged={onRowChanged}
                                                onDelete={() => deleteAtIndex(rowIndex, row)}
                                                onValidate={(field, error) => onCellValidate(field, error, row)}
                                        />
                                    </div>
                                }
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                ))}
            </div>
        </>
    )
}


export default ExGrid;

ExGrid.propTypes = {
    data: PropTypes.array,
    name: PropTypes.string,
    className: PropTypes.string,
    rowClass: PropTypes.object,
    headerClass: PropTypes.string,
    rowHeight: PropTypes.number,
    style: PropTypes.object,
    createNewEntity: PropTypes.func, //function to create a new entity on add method defaults {_uniqueId: new Guid()}
    getEntityId: PropTypes.func, //function to get rowId (required if child fields use validation)
    minRows: PropTypes.number, //indicate min required rows
    customValidations: PropTypes.array
};
