import React, {useEffect, useState} from "react";
import {InputTextarea} from "primereact/inputtextarea";

const FieldTextArea = ({colMd, colSm, label, name, onChange, onBlur, value, keyFilter, rows, disabled,error,errors,
                           required, className,maxLength}) => {

    const [selectedValue, setSelectedValue] = useState(value);

    function onValueChange(event) {
        setSelectedValue(event.target.value);
        if (onChange != null) {
            onChange(event.target.value,name);
        }
    }

    useEffect(() => {
        let newValue = value == null ? '' : value;
        setSelectedValue(newValue);
    }, [value]);


    return (
        <>
            {label &&
            <div className={`form-group col-md-${colMd} col-sm-${colSm != null ? colSm : '12'} ${className || ''}`}>
                {required ? '*':''}<label htmlFor={name}>{label}</label>
                <InputTextarea
                    id={name}
                    keyfilter={keyFilter}
                    className={`form-control ${(error || errors?.[name]) ? 'error' : ''}` }
                    onBlur={onBlur}
                    onChange={onValueChange}
                    value={selectedValue}
                    disabled={disabled}
                    rows={rows}
                    maxLength = {maxLength ?? 500}
                />
            </div>
            }
            {!label &&
            <InputTextarea  id={name}
                       keyfilter={keyFilter}
                       className={`form-control ${ (error || errors?.[name]) ? 'error' : ''}`}
                       onBlur={onBlur}
                       onChange={onValueChange}
                       value={selectedValue}
                       disabled={disabled }
                        rows={rows}
                        maxLength = {maxLength ?? 500}

            />
            }
        </>
    )
};


export default FieldTextArea ;
