import React, {useEffect, useState} from "react";
import {InputText} from "primereact/inputtext";
import {useReadOnly} from "../forms/ReadOnlyProvider";


const FieldTextBox = ({
                          colMd, colSm, label, name, onChange, onBlur, value, required, placeholder, colLg, className,
                          keyFilter, disabled, error, errors, fractionDigits, type, maxLength, upperCase,width,onFocus
                      }) => {

    const {readOnly} = useReadOnly()??{};
    const [selectedValue, setSelectedValue] = useState('');
    disabled = disabled ?? readOnly;


    function onValueChange(event) {

        if (upperCase === true)
            setSelectedValue(event.target.value.toUpperCase());
        else
            setSelectedValue(event.target.value);

        if (onChange != null) {
            if (upperCase === true)
                onChange(event.target.value.toUpperCase(), name);
            else
                onChange(event.target.value, name);

        }
    }

    useEffect(() => {
        let newValue = value == null ? '' : value;
        setSelectedValue(newValue);
    }, [value]);

    function isNumeric() {
        return fractionDigits != null;
    }

    return (
        <>
            {label &&
            <div
                className={`form-group col-lg-${colLg ?? colMd} col-md-${colMd ?? '4'} col-sm-${colSm != null ? colSm : '12'}`}>
                {required ? '*' : ''}<label htmlFor={name}>{label}</label>
                <InputText
                    id={name}
                    keyfilter={keyFilter}
                    style={{width:width}}                    
                    className={`form-control ${className ?? ''} ${isNumeric() ? 'right' : ''} ${(error || errors?.[name]) ? 'error' : ''}`}
                    onBlur={onBlur}
                    onChange={onValueChange}
                    value={selectedValue}
                    disabled={disabled}
                    type={type}
                    maxLength={maxLength ?? 50}
                />
                {errors?.[name] && (errors[name].type === 'email' || errors[name].type === 'matches') &&
                <small id="username2-help" className="p-error p-d-block">{errors[name].message}</small>
                }
            </div>
            }
            {!label &&
            <>
                <InputText id={name}
                           keyfilter={keyFilter}
                           style ={{width:width}}
                           className={`form-control ${className ?? ''} ${isNumeric() ? 'right' : ''} ${(error || errors?.[name]) ? 'error' : ''}`}
                           onBlur={onBlur}
                           onChange={onValueChange}
                           value={selectedValue}
                           disabled={disabled}
                           type={type}
                           onFocus={onFocus}
                           placeholder={placeholder}
                           maxLength={maxLength ?? 50}
                />
                {errors?.[name] && (errors[name].type === 'email' || errors[name].type === 'matches') &&
                <small id="username2-help" className="p-error p-d-block">{errors[name].message}</small>
                }
            </>
            }
        </>
    )
};


export default FieldTextBox;
