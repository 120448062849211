/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {produce} from "immer";
import FieldTextBox from "../shared/controls/fieldTextBox";
import FieldDropDown from "../shared/controls/fieldDropDown";
import constantes from "./constantes";
import FieldAutoComplete from "../shared/controls/fieldAutoComplete";
import useApiDirecciones from "../api/catalogos/useApiDirecciones";
import DireccionModal from "./direccionModal";

const Direccion = ({
                       value,
                       name,
                       onChange,
                       errors,
                       readOnly,
                       mostrarNombre,
                       mostrarCambioNombre,
                       mostrarExternalId
                   }) => {
    const api = useApiDirecciones();
    // const [deshabilitado, setDeshabilitado] = useState(readOnly || value.id > 0);
    const [deshabilitado] = useState(readOnly);
    const [validoParaTimbrar, setValidoParaTimbrar] = useState(true);
    const [verModal, setVerModal] = useState(false);

    useEffect(() => {
        async function validar() {
            const params = {
                codigo: value?.codigoPostal,
                paisId: value?.pais?.id ?? 0,
                estadoId: value?.estado?.id ?? 0,
                municipioId: value?.municipio?.id ?? 0,
                localidadId: value?.localidad?.id ?? 0
            };

            const cp = await api.validarDireccion(params);
            setValidoParaTimbrar(cp);
        };
        if (value?.id > 0) {
            validar().then();
        }
    }, [value?.id]);

    async function actualizar(itemValue, itemName) {
        let data = null;
        if (itemName === "codigoPostal" && itemValue?.length === 5) {
            data = await api.codigoPostal({codigo: itemValue});
        }

        onChange(produce(value, (d) => {
            d[itemName] = itemValue;
            d.cambioDireccion = false;
            if(itemName === "codigoPostal"){
                d.pais = data?.pais;
            }

            d.estado = (itemName === 'pais') ? null : data?.estado ?? d.estado;
            d.municipio = (itemName === 'estado' || itemName === 'pais') ? null : data?.municipio ?? d.municipio;
        }), name);
    }

    async function cambioDireccion(itemValue, itemName) {
        // setDeshabilitado(readOnly);
        //si la dirección que pusieron no es una dirección existente actualiza el nombre
        if (itemValue?.id == null && !value.id) {
            actualizar(itemValue, itemName);
            return;
        }

        //si el nombre no existe y ya habían seleccionado una dirección les borra los campos
        if (itemValue?.id == null && value.id > 0) {
            onChange({idNombre: itemValue, pais: {id: constantes.paises.mexico}, cambioDireccion: true}, name);
            return;
        }

        //en caso contrario obtiene la dirección y esa la manda como elemento
        const direccion = await api.obtener(itemValue.id);
        // setDeshabilitado(true);
        onChange({...direccion, idNombre: itemValue, cambioDireccion: true}, name);
    }

    function cambioNombre(itemValue) {
        onChange({...value, idNombre: {nombre: itemValue, id: value.idNombre?.id}}, "direccion");
    }

    return (
        <>
            <FieldTextBox name="rfc" label="RFC" colMd={6} errors={errors} disabled={deshabilitado}
                          value={value.rfc} onChange={actualizar} maxLength={13}/>
            <div className={"form-group col-lg-6"}>
                {validoParaTimbrar ?
                    <span className="dot" title={"Direccion Valida para timbrar."} onClick={()=> setVerModal(true)}
                          style={{backgroundColor: '#00d661', float: "right"}}/>
                    :
                    <span className="dot" title={"Estado, Municipio, Localidad y Codigo Postal no valido para timbrar."}
                          style={{backgroundColor: '#ff4b4b', float: "right"}} onClick={()=> setVerModal(true)}/>
                }
            </div>
            {mostrarNombre &&
                <FieldAutoComplete name="idNombre" label="Nombre" colMd={12} errors={errors} disabled={readOnly}
                                   freeText={true} source={`direcciones/${constantes.modulos.paradas}`}
                                   value={value.idNombre} onChange={cambioDireccion}/>
            }
            {mostrarCambioNombre &&
                <FieldTextBox name="idNombre" label="Nombre" colMd={12} errors={errors} disabled={deshabilitado}
                              value={value.idNombre?.nombre} onChange={cambioNombre}/>
            }
            {mostrarExternalId &&
                <FieldTextBox name="externalId" label="Nombre Corto (ExternalId)" colMd={12}
                              value={value.externalId} onChange={actualizar}/>
            }
            <FieldTextBox name="calle" label="Calle" colMd={12} errors={errors} disabled={deshabilitado}
                          value={value.calle} onChange={actualizar}/>
            {value?.pais?.id !== constantes.paises.mexico &&
                <>
                    <FieldTextBox name="colonia" label="Colonia" colMd={12} errors={errors} disabled={readOnly}
                                  value={value.colonia} onChange={actualizar}/>
                </>
            }
            <FieldTextBox name="noExterior" label="No Exterior" colMd={6} errors={errors} disabled={deshabilitado}
                          value={value.noExterior} onChange={actualizar}/>
            <FieldTextBox name="noInterior" label="No Interior" colMd={6} disabled={deshabilitado}
                          value={value.noInterior} onChange={actualizar}/>
            <FieldTextBox name="codigoPostal" label="Codigo Postal" colMd={6} errors={errors} keyFilter={'int'}
                          maxLength={6} disabled={deshabilitado}
                          value={value.codigoPostal} onChange={actualizar}/>
            <FieldDropDown name="pais" label="Pais" colMd={6} source="paises" filter showClear={false}
                           disabled={deshabilitado}
                           value={value?.pais} onChange={actualizar}/>
            <FieldDropDown name="estado" label="Estado" colMd={12} source="estados" filter showClear={false}
                           params={(value?.pais ?? {id: 0})} errors={errors} disabled={deshabilitado}
                           value={value.estado}
                           onChange={actualizar}/>
            {value?.pais?.id === constantes.paises.mexico ?
                <>
                    <FieldDropDown name="municipio" label="Municipio" colMd={12} source="municipios" filter
                                   showClear={false} params={value.estado} errors={errors} disabled={deshabilitado}
                                   value={value.municipio} onChange={actualizar}/>
                    <FieldDropDown name="localidad" label="Localidad" colMd={12} source="localidades" filter
                                   errors={errors} showClear={true}
                                   value={value.localidad} params={value.estado} onChange={actualizar}
                                   disabled={deshabilitado}/>
                    <FieldDropDown name="colonias" label="Colonia" colMd={12} source="colonias" filter errors={errors}
                                   showClear={false}
                                   value={value.colonias} params={{id: 0, nombre: value.codigoPostal}}
                                   onChange={actualizar} disabled={deshabilitado}/>
                </>
                :
                <>
                    <FieldTextBox name="municipioNombre" label="Municipio" colMd={6} errors={errors} disabled={readOnly}
                                  value={value.municipioNombre} onChange={actualizar}/>
                    <FieldTextBox name={"numRegIdTrib"} label={"Tax ID"} colMd={6} errors={errors} disabled={readOnly}
                                  value={value?.numRegIdTrib} onChange={actualizar}/>
                </>
            }
            <DireccionModal visible={verModal} codigoPostal={value?.codigoPostal} onHide={()=> setVerModal(false)}/>
        </>
    )
};

export default Direccion;
