import React, {useState} from "react";
import {InputSwitch} from 'primereact/inputswitch';
import FieldTextArea from "../../../shared/controls/fieldTextArea";
import VisorFotografia from "../../../operaciones/ControlPatios/checkListUnidades/visorFotografia";
import produce from "immer";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import useApiCheckUnidad from "../../../api/operaciones/useApiCheckUnidad";
import UploadImageFile from "../../../shared/controls/uploadImageFile";
import {useEmpresa} from "../../../layout/EmpresaProvider";

const TableCheckListUnidad = ({ checklist, onChange, disabled, errors, ocultarHeader, checkId, urlFirma }) => {
    const api = useApiCheckUnidad();

    const [visibleModal, setVisibleModal] = useState(false);
    const [indexFotografia, setIndexFotografia] = useState(null);
    const [checkListSeccion, setCheckListSeccion] = useState([]);
    const [nameSeccion, setNameSeccion] = useState('');
    const [esFotoActual, setEsFotoActual] = useState(false);

    const {empresaId} = useEmpresa();

    async function actualizar(lista, index, value, propertyName, name) {
        let fotoActualUrl = '';
        if (checkId) {
            if (propertyName === "fotoActual") {
                if (value == null) {
                    await api.eliminarFoto(checkId, lista[index].fotoActualUrl)
                } else {
                    value.extension = "image/png"
                    fotoActualUrl = await api.guardarFoto(checkId, value)
                }
            }

            if (propertyName === "foto") {
                const newValue = produce(lista, draft => {
                    draft[index]["fotoActual"] = value;
                });

                onChange(newValue, "fotoActual");
            }
        }

        const newCheckList = produce(lista, (draft) => {
            draft[index][propertyName] = value;

            if (checkId) {
                //Si propertyName es fotoActual se vacía la propiedad url, para no enviarla al servidor al guardar
                if (propertyName === "fotoActual" && value !== null) {
                    draft[index].fotoActual.url = "";
                }

                //Esta es la url que regresa el servidor al momento de guardar una foto
                if (fotoActualUrl !== '') {
                    draft[index].fotoActualUrl = fotoActualUrl;
                }
            }
        });

        const newList = checklist.map((item) => {
            if (item.nombre === name) {
                return {
                    ...item,
                    checkList: newCheckList,
                };
            }

            return item;
        });

        onChange(newList, "checkList");
    }

    function expandir(checkList, index) {
        let expandido = checkList[index].expandido !== undefined ? !checkList[index].expandido : false;
        const newCheckList = produce(checkList, (draft) => {
            draft[index]["expandido"] = expandido;
        });

        onChange(newCheckList, "checkList");


    }

    function agregarFalla(lista, index, name) {
        let elementCopy = lista[index];
        let copia = {
            esCopia: true,
            puntoInspeccion: {nombre: elementCopy.puntoInspeccion.nombre, id: elementCopy.puntoInspeccion.id},
            esIncidencia: false, fotoAnterior: {}, fotoActual: null, comentarios: '', editable: true
        };

        const newCheckList = produce(lista, (draft) => {
            draft.push(copia);

        });

        const newList = checklist.map((item) => {
            if (item.nombre === name) {
                return {
                    ...item,
                    checkList: newCheckList,
                };
            }

            return item;
        });

        onChange(newList, "checkList");

    }

    function obtenerSrc(value) {
        if (value?.isBase64 === true) {
            return value.url;
        }
        return `${process.env.REACT_APP_API_URL}/archivos/imagen/${empresaId}?url=${value?.url}`;
    }

    const tomarFotografia = (index, checkListSeccion, name, esFotoActual) => {

        if (disabled || (!esFotoActual && checkListSeccion[index].fotoAnterior == null))
            return;

        setIndexFotografia(index);
        setVisibleModal(true);
        setCheckListSeccion(checkListSeccion);
        setNameSeccion(name);
        setEsFotoActual(esFotoActual);
    };

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    async function updateArray(lista, id, value, propertyName, name) {
        let fotoBase64 = await toBase64(value.file);
        value.file = fotoBase64;
        value.isBase64 = true;
        value.url = fotoBase64;
        value.extension = value.type;

        const newCheckList = produce(lista, draft => {
            const item = draft.find(e => e.puntoInspeccion.id === id);
            item[propertyName] = value;
        });

        const newList = checklist.map((item) => {
            if (item.nombre === name) {
                return {
                    ...item,
                    checkList: newCheckList,
                };
            }

            return item;
        });
        onChange(newList, "checkList");

    }

    return (
        <>
            <VisorFotografia esFotoAcual={esFotoActual} nameSeccion={nameSeccion} checklist={checkListSeccion}
                index={indexFotografia} visibleModal={visibleModal} onChange={actualizar}
                setVisibleModal={setVisibleModal} />

            <table style={{ backgroundColor: "white" }} className="table">
                <thead className="thead-light">
                    <tr>
                        <th scope="col" style={{ width: '20%' }}></th>
                        <th scope="col" style={{ width: '20%' }}>ESTATUS</th>
                        <th scope="col" style={{ width: '21%' }}>FOTO ANTERIOR</th>
                        <th scope="col" style={{ width: '21%' }}>FOTO ENTREGA</th>
                        <th scope="col" style={{ width: '25%' }}>COMENTARIOS</th>
                    </tr>
                </thead>
                <tbody>
                    {checklist?.map((header, index) => {
                        return (
                            <React.Fragment key={index}>

                                <tr style={{ display: ocultarHeader ? 'none' : '' }}>
                                    <td colSpan="6">
                                        <div style={{ display: "flex" }}>
                                            <i className={`cursor-pointer pi pi-angle-${header.expandido ?? true ? 'down' : 'right'}`}
                                                onClick={() => expandir(checklist, index)} />
                                            <h6>{header.nombre}</h6>
                                        </div>
                                    </td>
                                </tr>

                                {(checklist[index].expandido ?? true) && checklist[index].checkList.map(function (item, indexcheck) {
                                    return (
                                        <tr key={indexcheck}>
                                            <td className="input">
                                                <div style={{ marginLeft: "10px" }}
                                                    className="ope-alineacion-puntoInspeccion">
                                                    <h5>{item.puntoInspeccion.nombre}</h5>{item?.descripcion}

                                                    {item.puntoInspeccion.nombre === "Golpes en general" && !item.esCopia && !disabled &&
                                                        <ButtonIcon title={'Agregar Falla'} iconName={'SiAddthis'}
                                                            onClick={() => agregarFalla(checklist[index].checkList, indexcheck, header.nombre)} />
                                                    }
                                                </div>
                                            </td>
                                            <td className="input">

                                                <span className="ope-alineacion-checklist">
                                                    <InputSwitch offlabel="OK" className="check-list" onlabel="Incidencia"
                                                        name="esIncidencia" disabled={!item.editable && disabled}
                                                        checked={item.esIncidencia ?? false}
                                                        onChange={(event) => actualizar(checklist[index].checkList, indexcheck, event.target.value, "esIncidencia", header.nombre)} />

                                                    {!item.esIncidencia && (
                                                        <h5 style={{ marginLeft: "10px" }}>Ok</h5>
                                                    )}
                                                    {item.esIncidencia && (
                                                        <h5 style={{ marginLeft: "10px" }}>Incidencia</h5>
                                                    )}
                                                </span>
                                            </td>
                                            <td className="input">

                                                <div className="image-container">
                                                    <img alt="Imagen"
                                                        onClick={() => tomarFotografia(indexcheck, checklist[index].checkList, header.nombre, false)}
                                                        src={obtenerSrc(item.fotoAnterior)} style={{ width: '59%' }} />
                                                </div>

                                            </td>
                                            <td className="input">
                                                {item.fotoActual == null && (
                                                    <div
                                                        className={`${(errors != null && errors[`checkList[${index}]`] != null ? errors[`checkList[${index}]`][`checkList[${indexcheck}]?.[fotoActual]`] : '') !== '' ? 'error' : ''}`}
                                                        onClick={() => tomarFotografia(indexcheck, checklist[index].checkList, header.nombre, true)}>
                                                        <img width={"38%"} src={`../../../img/camera.png`} alt="camera" />
                                                        <div>Agregar Foto</div>
                                                    </div>
                                                )}

                                                {item.fotoActual != null && (
                                                    <img alt="Imagen"
                                                        onClick={() => tomarFotografia(indexcheck, checklist[index].checkList, header.nombre, true)}
                                                        src={obtenerSrc(item.fotoActual)} style={{ width: '59%' }} />
                                                )}

                                                {item.fotoActual == null && (
                                                    <div>
                                                        <UploadImageFile name="fotoActual" value={item.fotoActual}
                                                            errors={errors != null && errors[`checkList[${index}]`] != null ? errors[`checkList[${index}]`][`checkList[${indexcheck}]`] : ''}
                                                            onChange={(value, propertyName) => updateArray(checklist[index].checkList, item.puntoInspeccion.id, value, propertyName, header.nombre)}
                                                        />
                                                    </div>
                                                )}
                                            </td>
                                            <td>
                                                <FieldTextArea disabled={!item.editable && disabled} rows={6}
                                                    errors={errors != null && errors[`checkList[${index}]`] != null ? errors[`checkList[${index}]`][`checkList[${indexcheck}]`] : ''}
                                                    name="comentarios" value={item?.comentarios}
                                                    onChange={(value) => actualizar(checklist[index].checkList, indexcheck, value, "comentarios", header.nombre)} />
                                            </td>
                                        </tr>
                                    )
                                })}

                            </React.Fragment>
                        )
                    }
                    )}
                </tbody>
            </table>
            {(urlFirma !== undefined && urlFirma !== null) &&
                <div className="d-flex justify-content-center mt-3">
                    <img src={obtenerSrc(urlFirma)} height={150} alt="Firma" />
                </div>
            }
        </>
    )
};
export default TableCheckListUnidad;
