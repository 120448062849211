import useServiceFactory from "./serviceFactory";
const api = 'security'
const useApiSecurity = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URI })
    return {
        validate: async (token) => service.get(`/${api}/OAuth/validate`, { showSuccess: false, token, ignoreError: true }),
        oauth: async (params) => service.post(`/${api}/OAuth/accessToken`, params, { showLoading: false, showSuccess: false, ignoreError: true }),
        refreshToken: async (token) => service.post(`/${api}/OAuth/refreshToken`, {}, { showLoading: false, showSuccess: false, token })
    }
}

export default useApiSecurity
