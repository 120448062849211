/* eslint-disable react-hooks/exhaustive-deps */
import Field from "../../shared/forms/Field";
import FormFilter from "../../shared/forms/FormFilter/FormFilter";
import Filters from "../../shared/forms/FormFilter/Filters";
import FormMultiSelect from "../../shared/forms/FormFields/FormMultiSelect";
import FormTextBox from "../../shared/forms/FormFields/FormTextBox";
import FormDropDown from "../../shared/forms/FormFields/FormDropDown";
import Buttons from "../../shared/forms/FormFilter/Buttons";
import React, {useEffect, useState} from "react";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import agGridHelper from "../../shared/ag-grid-helper";
import useSeguridad from "../../Seguridad/useSeguridad";
import constantes from "../../catalogos/constantes";
import useApiInventariosCaja from "../../api/taller/useApiInventariosCaja";
import useForm from "../../shared/forms/useForm";
import {
    AgIsoDateFormatter,
    AgIsoUtcDateTimeFormatter
} from "../../shared/ag-grid-helper/GridFormatters";
import {ExportRenderer, ViewRenderer} from "../../shared/Grid/GridCellRenderers";
import InventarioCajaDetalle from "./InventarioCajaDetalle";
import _ from "lodash";
import CargadaCheckBoxRenderer from "./CargadaCheckBoxRenderer";
import SubirInventario from "./SubirInventario";

const filtroInicial = {};


const InventariosCaja = () => {
    const api = useApiInventariosCaja();
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.inventariosCaja);
    const [datos, setDatos] = useState([]);
    const [geocercas, setGeocercas] = useState([])
    const [estados, setEstados] = useState([])
    const [municipios, setMunicipios] = useState([])
    const [estatus, setEstatus] = useState([])
    const [id, setId] = useState(null)
    const [visibleSubir, setVisibleSubir] = useState(false)

    const {values: filter, onChange: onFilterChange, onClear} = useForm(filtroInicial);

    const filtrar = async (filtro) => {
        setDatos([]);
        const cargada = filtro.cargadaVacia === 'cargada' ? true : filtro.cargadaVacia === 'vacia' ? false : null;
        let datos = await api.filtrar({...filtro,cargada});

        let nuevasGeocercas = _.sortBy([...new Set(datos.map(item => item.geocerca))], e => e);
        let nuevosEstados = _.sortBy([...new Set(datos.map(item => item.estado))], e => e);
        let nuevosMunicipios = _.sortBy([...new Set(datos.map(item => item.municipio))], e => e);
        let nuevosEstatus = _.sortBy([...new Set(datos.map(item => item.estatus))], e => e);
        if ((filtro?.estados?.length ??0) === 0 || nuevosEstados.length > estados.length) {
            setEstados(nuevosEstados.map(e=>({id:e, nombre:e})));
        }
        if ((filtro?.municipios?.length??0) === 0 || nuevosMunicipios.length > municipios.length) {
            setMunicipios(nuevosMunicipios.map(e => ({id: e, nombre: e})));
        }
        if ((filtro?.geocercas?.length??0) === 0 || nuevasGeocercas.length > geocercas.length){
            setGeocercas(nuevasGeocercas.map(e=>({id:e, nombre:e})));
        }
        if ((filtro?.estatus?.length??0) === 0 || nuevosEstatus.length > estatus.length){
            setEstatus(nuevosEstatus.map(e=>({id:e, nombre:e})));
        }
        setDatos(datos);
    }

    useEffect(() => {
        filtrar(filter).then();
    }, []);

    const handleFilter = async (filtro) => {
        await filtrar(filtro);
    }

    const onExport = async () => {
        await api.excel(filter).then();
    }

    const onSaved = async () => {
        await filtrar(filter);
        setId(null);
        setVisibleSubir(false);
    }


    return (
        <div className="main-screen">
            <FormFilter columns={8} filter={filter} onChange={onFilterChange} onClear={onClear} onFilter={handleFilter}>
                <Filters>
                    <Field label={"Estados"} field="estados" options={estados} render={FormMultiSelect}/>
                    <Field label={"Municipios"} field="municipios" options={municipios} render={FormMultiSelect}/>
                    <Field label={"Geocercas"} field="geocercas" options={geocercas} render={FormMultiSelect}/>
                    <Field label={"Patios"} field="patioIds" source={'patios'} render={FormMultiSelect}/>
                    <Field label={"Cajas"} field="cajas" render={FormTextBox}/>
                    <Field label={"estatus"} field="estatus" options={estatus} render={FormMultiSelect}/>
                    <Field label={"Cargada/Vacía"} field="cargadaVacia" options={constantes.cargadaVaciaOptions} showClear={true} render={FormDropDown}/>
                </Filters>
                <Buttons filter={true}
                         clear={true}
                >
                    <button
                        type="button"
                        onClick={() => setVisibleSubir(true)}
                        className={"btn btn-sm btn-outline-primary"}
                    >
                        Subir Inventario
                    </button>
                </Buttons>
            </FormFilter>
            <div className="divider"/>

            <div className="ag-theme-balham grid-principal cell-flex">
                <AgGridReact
                    rowSelection={'single'}
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    rowData={datos}
                    defaultColDef={{
                        sortable: true,
                        resizable:true
                    }}
                    getRowId={(params) => params.data.id}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    gridOptions={{
                        suppressRowClickSelection: true,
                        suppressCellSelection: true
                    }}
                    rowHeight={35}
                    context={{
                        onExport: onExport,
                        viewDetail: (id) => {
                            setId(id)
                        }
                    }}
                >
                    <AgGridColumn width={70} field="numeroEconomico" headerName="Caja" cellClass={'center'}/>
                    <AgGridColumn width={170} field="patio" headerName="Patio"/>
                    <AgGridColumn minWidth={200} flex={1} field="geocerca" headerName="Geocerca"/>
                    <AgGridColumn width={70} field="cargada" headerName="Cargada" cellRenderer={CargadaCheckBoxRenderer}/>
                    <AgGridColumn width={70} field="enTaller" headerName="En Taller" cellRenderer={CargadaCheckBoxRenderer}/>
                    <AgGridColumn width={90} field="estatus" headerName="Estatus"/>
                    <AgGridColumn width={90} field="ultimaCartaPorte" headerName="Última Carta Porte"/>
                    <AgGridColumn width={100} field="fechaInicioUltimoViaje" headerName="Fecha Último Viaje" valueFormatter={AgIsoDateFormatter}/>
                    <AgGridColumn minWidth={200} flex={1} field="ultimoCliente" headerName="Último Cliente"/>
                    <AgGridColumn width={120} field="fechaActualizacionGps" headerName="Fecha Actualización Gps" valueFormatter={AgIsoUtcDateTimeFormatter}/>
                    <AgGridColumn width={90} field="dias" headerName="Días sin Actualizar"/>
                    <AgGridColumn width={90} field="pais" headerName="País"/>
                    <AgGridColumn width={120} field="estado" headerName="Estado"/>
                    <AgGridColumn width={150} field="municipio" headerName="Municipio"/>
                    <AgGridColumn minWidth={300} flex={2} field="ultimaUbicacion" headerName="Ultima Ubicación"/>
                    <AgGridColumn width={50} headerName="VER" pinned={'right'} headerComponent={ExportRenderer}
                                  cellRenderer={ViewRenderer}
                                  path='/comercial/editar_cotizacion'/>
                </AgGridReact>
            </div>
            <InventarioCajaDetalle id={id} disabled={soloLectura} onCancel={()=>setId(null)}  onSaved={onSaved} />
            <SubirInventario onSaved={onSaved} visible={visibleSubir} onCancel={()=>setVisibleSubir(false)}></SubirInventario>
        </div>
    )
}

export default InventariosCaja;
