/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useReducer} from "react";
import Direccion from "../../../catalogos/direccion";
import {Button} from "primereact/button";
import paradaValidacionEsquema from "./paradaValidacionEsquema";
import {Sidebar} from "primereact/sidebar";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import formReducer from "../../../shared/forms/formReducer";
import FormHandler from "../../../shared/forms/formHandler";
import constantes from "../../../catalogos/constantes";
import FieldDropDown from "../../../shared/controls/fieldDropDown";

const ParadaDetalle = ({value, onSave, entidadId, api, readOnly, canBeDeleted, onDelete}) => {
    const guid = Math.random();

    const getEntity = () => (
        {
            entity: {
                ...value,
                direccion: value.direccion == null ? {pais: {id: constantes.paises.mexico}} :
                    {...value.direccion, nombre:{id:value.direccion.id, nombre: value.direccion.nombre}}
            }
        });

    const [{entity, errors, ...state}, dispatch] = useReducer(formReducer, {}, getEntity);

    const formHandler = new FormHandler({
        validationSchema: paradaValidacionEsquema,
        onSave,
        api: {
            actualizar: async (id, parada) => {
                return await api.actualizarParada(entidadId, id, parada)
            }
        },
        dispatch
    });

    useEffect(() => {
        if (state.submitted === true) {
            formHandler.validate(entity);
        }
    }, [entity]);

    useEffect(() => {
        dispatch({type: 'reset', getDefault:getEntity});
    }, [value]);

    const save = () => formHandler.save(entity);

    const update = (value, name) => {
        dispatch({type: 'update', value, name});
        if (value.cambioDireccion === true ) {
            dispatch({type: 'update', value: value.telefono, name: 'telefono'});
        }
    };

    const updateGeocerca = (value, name) => {
        dispatch({type: 'update', value: {...entity.direccion,geocerca:value}, name: 'direccion'});
    
};

    return (
        <>
            <ButtonIcon iconName='FaRegEdit' onClick={() => dispatch({type: 'show'})}/>
            {(canBeDeleted === true && readOnly !== true && value.esStop === true) &&
            <ButtonIcon iconName='RiDeleteBinLine' onClick={onDelete}/>}
            <Sidebar visible={state.visible} position="right" dismissable={false}
                     className="p-sidebar-md" onHide={() => dispatch({type: 'hide'})}>
                <div className="form-container full-height">
                    <h5>Dirección</h5>
                    <div className="form-row">
                        <Direccion value={entity?.direccion} name="direccion" onChange={update} readOnly={readOnly}
                                   mostrarNombre={true}
                                   errors={errors?.direccion}/>

                        <FieldDropDown  name="geocerca" label="Geocerca" source="geocercas" params={{ id: guid }} colMd={6} filter
                                    errors={errors?.direccion} disabled={readOnly}
                                    value={entity?.direccion.geocerca} onChange={updateGeocerca}/>
                    </div>
                </div>
                <div className="form-footer">
                    {readOnly !== true && <Button label="Guardar" type="button" onClick={save}/>}
                </div>
            </Sidebar>
        </>
    )
};

export default ParadaDetalle;