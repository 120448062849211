import React from "react";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import produce from "immer";

const ConfigFreightVerify = ({ config, onChange }) => {
    const actualizar = (value, name) => {
        const newValue = produce(config, draft => {
            draft[name] = value;
        });

        onChange(newValue, 'freightVerifyConfig');
    }

    return (
        <div className="form-row">
            <FieldTextBox name="scac" label="Scac" value={config.scac} colMd={6} maxLength={100} required={true}
                onChange={actualizar} />
            <FieldTextBox name="fvCarrierId" label="FV Carrier" value={config.fvCarrierId} colMd={6} maxLength={100} required={true}
                onChange={actualizar} />
            <FieldTextBox name="token" label="Token" value={config.token} colMd={12} maxLength={100} required={true}
                onChange={actualizar} />
        </div>
    );
};

export default ConfigFreightVerify;