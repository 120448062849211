import React from "react";
import FieldAutoComplete from "../../shared/controls/fieldAutoComplete";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import FieldCalendarRange from "../../shared/controls/fieldCalendarRange";
import FieldDropDown from "../../shared/controls/fieldDropDown";
import constantes from "../constantes";
import { default as constante } from "../../catalogos/constantes";

const FiltroControlPatios = ({ filtro, onChange, tipo }) => {

    return (
        <>
            {tipo === constantes.tipoControlPatios.acceso &&
                <>
                    <FieldAutoComplete name="unidad" source="unidades" label="No. Económico" colMd={2}
                        params={{ id: constante.tiposEquipo.motriz, todasLasEmpresas: true }} value={filtro.unidad} onChange={onChange} />
                </>
            }
            {tipo === constantes.tipoControlPatios.salida &&
                <>
                    <FieldAutoComplete name="unidad" source="unidades" label="No. Económico" colMd={1}
                        params={{ id: constante.tiposEquipo.motriz, todasLasEmpresas: true }} value={filtro.unidad} onChange={onChange} />
                 
                     <FieldTextBox name="caja" label="Caja" colLg={1} colMd={3} value={filtro.caja} onChange={onChange} />
                </>
            }
            <FieldTextBox name="operador" label="Operador" colMd={2}
                value={filtro.operador} onChange={onChange} />

            <FieldCalendarRange name="fechas" label="Fechas" colMd={2}
                value={filtro.fechas} onChange={onChange} />

            <FieldDropDown name="tipoControlPatio" source="tiposControlPatio" label="Tipo" colMd={2} valueById={true}
                value={filtro.tipoControlPatio} onChange={onChange} />
            <FieldDropDown name="patioUbicacion" label="Ubicacion" colMd={2} source="patios" valueById={true}
                value={filtro.patioUbicacion} onChange={onChange} />

            <FieldDropDown name="estadoElemento" label="Estado" colMd={2} source="general/EstadoElemento" valueById={true}
                value={filtro.estadoElemento} onChange={onChange} />
        </>
    )
};

export default FiltroControlPatios;
