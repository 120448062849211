import useServiceFactory from "../serviceFactory";

const apiUrl = `/catalogos/equipos`;

const useApiEquipos = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, { params, showLoading: true }),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, { showLoading: true }),
        guardar: async (params) => service.post(`${apiUrl}`, params),
        subirResponsiva: async(id, formData) => service.post(`${apiUrl}/${id}/responsiva`, formData),
        guardarFirma: async (id, empleadoId, params) => service.put(`${apiUrl}/${id}/guardarFirma/${empleadoId}`, params, { showSuccess: false }),
        actualizarAsignacion: async (id, params) => service.put(`${apiUrl}/${id}/actualizarAsignacion/`, params, { showSuccess: false }),
        eliminarResponsiva: async (id, empleadoId) => service.put(`${apiUrl}/${id}/eliminarResponsiva/${empleadoId}`, { showSuccess: false }),
        descarga: async (id, empleadoId) => service.get(`${apiUrl}/${id}/dowload/${empleadoId}`, {
            showLoading: true,
            download: true,
            fileName: `responsiva_${empleadoId}.pdf`
        })
    }
};

export default useApiEquipos;