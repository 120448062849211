import React from "react";
import FieldCalendarRange from "../../shared/controls/fieldCalendarRange";
const FiltroMantenimientoStp = ({filtro, onChange}) => {
    return (
        <>
               <FieldCalendarRange name="fechas" label="Fechas" colLg={2} colMd={3} value={filtro.fechas}
                onChange={onChange} />

        </>
    )
};

export default FiltroMantenimientoStp;
