import React, {useState} from "react";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import agGridHelper from "../../shared/ag-grid-helper";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import {getSessionState} from "../../hooks/useSessionState";
import Filtro from "../../shared/componentes/filtro";
import FieldCalendarRange from "../../shared/controls/fieldCalendarRange";
import FieldMultiSelect from "../../shared/controls/fieldMultiSelect";
import {Sidebar} from "primereact/sidebar";
import DetalleMovimientos from "./movimientoDetalle";
import useApiMovimientos from "../../api/administracion/useApiMovimientos";
import AgregarDescuento from "./Descuento";
import constantes from "../../catalogos/constantes";
import useSeguridad from "../../Seguridad/useSeguridad";
import alerts from "../../shared/alerts";
import {Dialog} from "primereact/dialog";
import FieldTextArea from "../../shared/controls/fieldTextArea";
import {Button} from "primereact/button";

const Movimientos = ({esSeccionAutorizar, soloLectura, esquemaPagos}) => {
    const filtroKey = 'FiltroMovimientos' + (esSeccionAutorizar ? "PorAutorizar" : "");
    const [datos, setDatos] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [movimientoId, setMovimientoId] = useState(false);
    const api = useApiMovimientos();
    const seguridad = useSeguridad();
    const [nuevoDescuento, setNuevoDescuento] = useState(false);
    const permisoOficina = seguridad.tieneAcceso(constantes.opciones.BusquedaMovsOficina);
    const permisoStaff = seguridad.tieneAcceso(constantes.opciones.BusquedaMovsStaff);
    const permisoOperativos = seguridad.tieneAcceso(constantes.opciones.BusquedaMovsOperativos);
    const [verModal, setVerModal] = useState(false);
    const [comentario, setComentario] = useState("");

    async function buscar(filtro, paramsApi) {
        agGridHelper.showLoad(gridApi, paramsApi, true);
        setDatos(await api.filtrar(obtenerFiltro(filtro)));
        agGridHelper.hideLoad(gridApi, paramsApi, false);


    }

    const obtenerFiltro = (filtro) => {
        filtro = filtro ?? getSessionState(filtroKey);

        return {...filtro, porAutorizar: esSeccionAutorizar, esquemaPagos: esquemaPagos,};
    };

    const onGridReady = async (params) => {
        setGridApi(params.api);
    };

    async function onSave() {
        setSidebarVisible(false);
        setNuevoDescuento(false);
        await buscar();
    }

    const verMovimiento = (id) => {
        setSidebarVisible(true);
        setMovimientoId(id);
    };

    function exportar() {
        api.excel(getSessionState(filtroKey));
    }

    async function autorizar(id, esquemaPago, esRechazar = false) {
        setMovimientoId(id);
        esquemaPagos = esquemaPago
        if (esRechazar) {
            setComentario("");
            setVerModal(true);
        } else {
            if (await alerts.preguntarSiNo(`${esRechazar ? "Rechazar movimiento" : "Aprobar movimiento"}, ¿deseas continuar?`)) {
                await api.autorizar(id);
                await buscar();
            }
        }
    }

    async function handleClick(esquemaPago) {

        esquemaPagos = esquemaPago;
        await buscar();
    };
    const ButtonsHeader = () => {
        return (
            <div className="ag-grid header-icons">
                <ButtonIcon title="Descargar" onClick={exportar} iconName="BiDownload"/>

                {esSeccionAutorizar && !soloLectura &&
                    <ButtonIcon title="Agregar descuento" className="grid-action" iconName="MdAddBox"
                                onClick={() => setNuevoDescuento(true)}/>
                }
            </div>
        );
    };

    const RowButtons = ({data}) => (
        <div className="ag-grid row-icons">
            <button className="btn btn-link btn-sm" title={'Ver'} onClick={() => verMovimiento(data.id)}>
                Ver
            </button>
        </div>
    );
    const AutorizarCellRenderer = ({data}) => (
        <div className="d-flex align-items-center justify-content-center">
            {!soloLectura &&
                <>
                    <button title="Autorizar" type="button" className="button-icon" onClick={() => autorizar(data.id, data.esquemaPagos)}>
                        <i className="pi pi-check-circle text-success"/>
                    </button>
                </>
            }
        </div>
    );
    const RechazarCellRenderer = ({data}) => (
        <div className="d-flex align-items-center justify-content-center">
            {!soloLectura &&
                <>
                    <button title="Rechazar" type="button" className="button-icon"
                            onClick={() => autorizar(data.id, data.esquemaPagos, true)}>
                        <i className="pi pi-times-circle text-danger"/>
                    </button>

                </>
            }
        </div>
    );

    const rechazar = async () => {
        await api.rechazar(movimientoId, {comentario: comentario});
        setVerModal(false);
        await buscar();
    };

    const renderFooterModal = () => {
        return (
            <div>
                <Button label="Cerrar" onClick={()=> setVerModal(false)} className="p-button-outlined"/>
                <Button label="Rechazar" onClick={rechazar} autoFocus/>
            </div>
        );
    };

    return (
        <>

            <Filtro filtroKey={filtroKey} handleSubmit={buscar} fechasDiasInicio={1} fechasRangoDias={1}>
                <>
                    {(permisoStaff &&
                        <button type="button" onClick={() => handleClick(constantes.esquemaPago.staff)}
                                className={`btn btn-sm btn-primary`}>
                            Movimientos Administrativos
                        </button>
                    )}
                    {(permisoOperativos &&
                        <button type="button" onClick={() => handleClick(constantes.esquemaPago.liquidacion)}
                                className={`btn btn-sm btn-primary`}>
                            Movimientos Operativos
                        </button>
                    )}

                    {(permisoOficina &&
                        <button type="button" onClick={() => handleClick(constantes.esquemaPago.oficina)}
                                className={`btn btn-sm btn-primary`}>
                            Movimientos Oficina
                        </button>
                    )}
                </>

                <Filtros/>
            </Filtro>

            <Sidebar visible={sidebarVisible} position="right" dismissable={false} className="p-sidebar-md"
                     onHide={() => setSidebarVisible(false)}>
                <DetalleMovimientos movimientoId={movimientoId} onSaved={onSave} actualizarViajes={buscar}
                                    close={() => setSidebarVisible(false)} editable={!esSeccionAutorizar}
                                    soloLectura={soloLectura}/>
            </Sidebar>

            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    frameworkComponents={
                        {
                            buttonsHeader: ButtonsHeader,
                            rowButtons: RowButtons,
                            autorizarCellRenderer: AutorizarCellRenderer,
                            rechazarCellRenderer: RechazarCellRenderer,
                            goToDetail: agGridHelper.GoToDetailFormatter,
                            dateFormatter: agGridHelper.DateTimeFormatter,
                        }
                    }
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    rowHeight={35}
                >
                    <AgGridColumn flex={3} minWidth={180} field="fechaSolicitud" headerName="FECHA SOLICITUD"
                                  cellRenderer="dateFormatter"/>
                    <AgGridColumn flex={3} minWidth={180} field="fechaAutorizacion" headerName="FECHA AUTORIZACION"
                                  cellRenderer="dateFormatter"/>
                    <AgGridColumn field="usuarioAutorizo" headerName="EMPLEADO AUTORIZACION" flex={5} minWidth={260}
                                  cellClass={'left'} headerClass={'left'}/>
                    <AgGridColumn field="empleado" headerName="EMPLEADO" flex={5} minWidth={280} cellClass={'left'}
                                  headerClass={'left'}/>
                    <AgGridColumn minWidth={230} field="tipo" headerName="TIPO MOVIMIENTO" flex={5} cellClass={'left'}
                                  headerClass={'left'}/>
                    <AgGridColumn maxWidth={150} field="monto" headerName="MONTO" cellStyle={{textAlign: "right"}}
                                  valueFormatter={agGridHelper.currencyFormatter}/>
                    <AgGridColumn maxWidth={150} field="tiempoExtra" headerName="TIEMPO EXTRA"
                                  cellStyle={{textAlign: "right"}}
                                  valueFormatter={agGridHelper.decimalFormatter}/>
                    <AgGridColumn minWidth={230} field="concepto" headerName="CONCEPTO"
                                  cellStyle={{textAlign: "center"}}/>
                    <AgGridColumn field="saldoActual" headerName="BALANCE" cellStyle={{textAlign: "right"}}
                                  maxWidth={150} valueFormatter={agGridHelper.currencyFormatter}/>
                    <AgGridColumn field="solicitadoPor" headerName="SOLICITADO POR" cellStyle={{textAlign: "left"}}/>
                    <AgGridColumn maxWidth={100} field="estatus" headerName="ESTATUS"/>
                    <AgGridColumn maxWidth={100} hide={!esSeccionAutorizar} headerName="AUTORIZAR"
                                  cellRenderer="autorizarCellRenderer"/>
                    <AgGridColumn maxWidth={100} hide={!esSeccionAutorizar} headerName="RECHAZAR"
                                  cellRenderer="rechazarCellRenderer"/>

                    <AgGridColumn maxWidth={80}
                                  headerName="VER"
                                  cellRenderer="rowButtons"
                                  pinned={'right'}
                                  cellClass={'left'}
                                  headerComponent="buttonsHeader"/>
                </AgGridReact>
            </div>
            {/* Dialogo para agregar descuentos */}
            <AgregarDescuento onSave={onSave} visible={nuevoDescuento} hide={() => setNuevoDescuento(false)}/>
            <Dialog header={"Rechazar Movimiento"} visible={verModal} footer={renderFooterModal}
                    style={{width: '60vw'}} onHide={() => setVerModal(false)}>
                <FieldTextArea name={"comentarios"} label={"Comentarios"} colMd={12} rows={3}
                               value={comentario} onChange={(value) => setComentario(value)}/>
            </Dialog>
        </>
    )
};

const Filtros = ({filtro, onChange}) => {
    return (
        <>
            <FieldMultiSelect name="empleados" source="empleados" label="Empleado" colLg={2} colMd={3}
                              value={filtro.empleados} onChange={onChange} showFilter valueAsId={true}/>

            <FieldMultiSelect name="tiposMovimiento" showFilter source="tipoGasto" label="Tipo Movimiento" colMd={3}
                              value={filtro.tiposMovimiento} onChange={onChange} valueAsId={true}/>

            <FieldMultiSelect name="conceptos" showFilter source="conceptosMovs" label="Conceptos" colMd={3}
                              value={filtro.conceptosMovs} onChange={onChange} valueAsId={true}/>

            <FieldCalendarRange name="fechas" label="Fechas" colLg={2} colMd={3} value={filtro.fechas}
                                onChange={onChange}/>

            <FieldMultiSelect name="estatus" source="estatusMovimiento" label="Estatus" colLg={2} colMd={2}
                              value={filtro.estatus} onChange={onChange} valueAsId={true}/>
        </>
    )
};

export default Movimientos;
