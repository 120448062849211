/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import agGridHelpers from "../../shared/ag-grid-helper";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { EditIconRenderer, GridEditHeader, ReadOnlyCheckBoxRenderer } from "../../shared/ag-grid-helper/GridRenderers";
import Form from "../../shared/forms/Form";
import SidebarEditForm from "../../shared/forms/SideBarEditForm";
import Field from "../../shared/forms/Field";
import FormTextBox from "../../shared/forms/FormFields/FormTextBox";
import FormCheckBox from "../../shared/forms/FormFields/FormCheckBox";
import FormDropDown from "../../shared/forms/FormFields/FormDropDown";
import useApiConfiguracionBono from "../../api/catalogos/useApiConfiguracionBono";
import constantes from "../constantes";
import useSeguridad from "../../Seguridad/useSeguridad";

const titleStyle = {
    marginBottom: 0,
    marginTop: '10px'
};

const ConfiguracionBono = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.configuracionBono);
    const [datos, setDatos] = useState({ tiposBono: [], conceptos: [] });
    const [currentId, setCurrentId] = useState({});
    //esta variable se utilizara en el parámetro del source de tipo de bono para forzar a hacer el reload del drop down cada que se guarde algo
    const [contGuardados, setContGuardados] = useState(0);
    const api = useApiConfiguracionBono();

    useEffect(() => {
        load().then();
    }, []);

    const load = async () => {
        const [tiposBono, conceptos] = await Promise.all([api.filtrarTipoBono(), api.filtrarConceptoBono()]);
        setDatos({ tiposBono, conceptos });
    };

    const gridConfig = {
        rowSelection: 'single',
        enableSorting: true,
        frameworkComponents: {
            readOnlyCheckBoxRenderer: ReadOnlyCheckBoxRenderer,
            editRenderer: EditIconRenderer,
            editHeader: GridEditHeader
        },
        defaultColDef: agGridHelpers.defaultColumns
    };

    const sideBarCommon = {
        onSaved: () => {
            load().then();
            setCurrentId({});
            setContGuardados(contGuardados + 1);
        },
        onCancel: () => setCurrentId({})
    };

    return (<>
        <div className="main-screen" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '15px 30px', gridTemplateRows: '40px 1fr' }}>
            <h5 style={titleStyle}>
                Tipos de Compensacion
            </h5>
            <h5 style={titleStyle}>
                Concepto
            </h5>
            <div className="ag-theme-balham">
                <AgGridReact {...gridConfig} formDisabled={soloLectura}
                    rowData={datos.tiposBono}
                    context={{ onEdit: (id) => setCurrentId({ tipoBono: id }), onAdd: () => setCurrentId({ tipoBono: 0 }) }}>
                    <AgGridColumn field="nombre" headerName="Nombre" cellClass={'left'} />
                    <AgGridColumn field="activo" headerName="Activo" cellClass={'center'} cellRenderer={'readOnlyCheckBoxRenderer'} />
                    <AgGridColumn maxWidth={60} headerComponent={soloLectura ? '' : 'editHeader'} cellClass={'center'} cellRenderer="editRenderer" />
                </AgGridReact>
            </div>
            <div className="ag-theme-balham">
                <AgGridReact {...gridConfig} rowData={datos.conceptos} context={{ onEdit: (id) => setCurrentId({ concepto: id }), onAdd: () => setCurrentId({ concepto: 0 }) }}>
                    <AgGridColumn field="nombre" headerName="Nombre" cellClass={'left'} />
                    <AgGridColumn field="tipoBono" headerName="Tipo de Bono" cellClass={'left'} />
                    <AgGridColumn field="activo" headerName="Activo" cellClass={'center'} cellRenderer={'readOnlyCheckBoxRenderer'} />
                    <AgGridColumn maxWidth={60} headerComponent={soloLectura ? '' : 'editHeader'} cellClass={'center'} cellRenderer="editRenderer" />
                </AgGridReact>
            </div>
        </div>
        <SidebarEditForm id={currentId.tipoBono}
            initialValues={{ activo: true }}
            getByIdFunction={api.getTipoBono}
            saveFunction={api.guardarTipoBono}
            deleteFunction={api.eliminarTipoBono}
            formDisabled={soloLectura}
            {...sideBarCommon}>
            <Form columns={1}>
                <h5>Tipo de Compensacion</h5>

                <Field field={"nombre"} label={"Nombre"} required={true} render={FormTextBox} />
                <Field field={"activo"} label={"Activo"} render={FormCheckBox} />
            </Form>
        </SidebarEditForm>
        <SidebarEditForm id={currentId.concepto}
            initialValues={{ activo: true }}
            getByIdFunction={api.getConceptoBono}
            saveFunction={api.guardarConceptoBono}
            deleteFunction={api.eliminarConceptoBono}
            formDisabled={soloLectura}            
            {...sideBarCommon}>
            <Form columns={1}>
                <h5>Concepto</h5>
                <Field field={"nombre"} label={"Nombre"} required={true} render={FormTextBox} />
                <Field field={"tipoBonoId"} label={"Tipo de Bono"} source="tiposBono" render={FormDropDown} params={{ contGuardados }} required={true} />
                <Field field={"activo"} label={"Activo"} render={FormCheckBox} />
            </Form>
        </SidebarEditForm>
    </>)
};

export default ConfiguracionBono;