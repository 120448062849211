import {Dialog} from "primereact/dialog";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import {CloseIcon} from "../../../icons/SeguimientoYMonitoreo/SeguimientoIcons";
import {Button} from "primereact/button";
import React from "react";
import UnidadCaja from "./UnidadCaja";

const CompartirLinkModal = ({viajes, onEliminar}) => {
    viajes = [{unidad:'003-TBX', caja:'4059-TBX'},{unidad:'003-TBX', caja:'4059-TBX'}]

    return (
        <>
            <Dialog header="Compartir Link"
                    className={'custom-footer'}
                    visible={false} style={{ width: '400px' }}
                    onHide={() => {}}
            >
                <div className="p-fluid">
                    <FieldTextBox label={'Correo'}/>
                </div>
                <div className={'p-fluid f-group'}>
                    <span className={'gray-label'}>
                        UNIDADES A COMPARTIR
                    </span>
                </div>
                <div className={'chip-viajes f-group'} >
                    {viajes && viajes.map((viaje, index) =>
                        <div key={index} className={'chip-viaje'}>
                            <UnidadCaja viaje={viaje}/>
                            <CloseIcon/>
                        </div>
                    )}
                </div>
                <div className={'fluid'} class={'dialog-footer center-buttons'}>
                    <Button label="Enviar"  className={'p-button-sm'} onClick={()=>{}} autoFocus />
                </div>
            </Dialog>
        </>
    )
}
export default CompartirLinkModal;