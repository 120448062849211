import {InputText} from "primereact/inputtext";
import React, {useEffect, useState} from "react";
import useApi from "../../api/useApi";
import {ProgressBar} from "primereact/progressbar";

let timeout = null;

const DireccionAutocomplete = ({value, placeholder, onChange}) => {
    const [searchText, setSearchText] = useState('')
    const [visibleResultados, setVisibleResultados] = useState(false)
    const [resultados, setResultados] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(0)

    const api = useApi();

    useEffect(() => {
        if (value?.direccion != null)
            setSearchText(value.direccion)
    }, [value]);

    const search = async (text) => {
        setResultados([]);
        setLoading(true)
        let result = await api.get(`routing/autocomplete/${text}`)
        setSelectedIndex(0)
        setLoading(false)
        setResultados(result)

    }

    const handleChange = (value) => {
        setVisibleResultados(true)
        setSearchText(value)
        onChange(null)
        clearTimeout(timeout)
        if (value.length > 0)
            timeout = setTimeout(() => search(value), 500)
    }

    const onFocus = () => {
        setVisibleResultados(true)
    }

    const onKeyDown = (event) => {
        switch (event.key) {
            case "Enter":
                onSelect(selectedIndex)
                return;
            case "ArrowDown":
                down()
                return;
            case "ArrowUp":
                up()
                return;
            default:
                return;
        }
    }

    const onSelect =(index)=>{
        if (index >=0 && index < resultados.length){
            onChange(resultados[index])
        }
        setVisibleResultados(false)
    }

    const up = () => {
        let newIndex = selectedIndex - 1;
        if (newIndex < 0) {
            newIndex = resultados.length - 1;
        }
        setSelectedIndex(newIndex)
    }

    const down = () => {
        let newIndex = selectedIndex + 1;
        if (newIndex >= resultados.length) {
            newIndex = 0;
        }
        setSelectedIndex(newIndex)
    }

    const onBlur =()=>{
         setTimeout(()=>{
             setVisibleResultados(false)
         },200)
    }

    return (
        <div style={{position: 'relative', width: '100%'}} onKeyDown={onKeyDown}>
            <InputText
                style={{width: '100%'}}
                onChange={(event) => handleChange(event.target.value)}
                value={searchText}
                maxLength={100}
                placeholder={placeholder}
                onFocus={onFocus}
                onBlur={onBlur}
            />
            {visibleResultados &&
                <div style={{
                    position: 'absolute',
                    top: '38px',
                    left: '0',
                    width: '100%',
                    zIndex: 100,
                    backgroundColor: 'white',
                    padding: '5px',
                    borderRadius: '0 0 5px 5px',
                    border: '1px solid #a3aad2',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    {loading && <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>}
                    {resultados.length === 0 &&
                        <span>
                            Buscar por unidad, geocerca, o dirección
                        </span>
                    }
                    {resultados.map((item, index) =>
                        <div key={index}
                             onMouseEnter={()=>setSelectedIndex(index)}
                             style={{
                                 width: '100%',
                                 cursor: 'pointer',
                                 display: 'flex',
                                 flexDirection: 'column',
                                 padding: '5px',
                                 backgroundColor: selectedIndex === index ? '#e3e6ef' : ''

                             }}>
                            <span style={{}}  onClick={()=>onSelect(index)}>
                                {item.direccion}
                            </span>
                            <span style={{color: '#888888'}}  onClick={()=>onSelect(index)}>
                                {item.origen}
                            </span>

                        </div>
                    )}
                </div>
            }
        </div>
    )
}
export default DireccionAutocomplete;