import React, {  useState } from 'react'
import useApiAgenda from "../../api/catalogos/useApiAgenda";
import Filtro from "../../shared/componentes/filtro";
import FiltroAgenda from "../Agenda/FiltroAgenda";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import AgendaDetalle from "../Agenda/AgendaDetalle";
import { Sidebar } from 'primereact/sidebar';
import useSeguridad from "../../Seguridad/useSeguridad";
import constantes from "../constantes";
import { getSessionState } from "../../hooks/useSessionState";
const Agenda=()=>{
    const seguridad = useSeguridad();
    const filtroKey = 'filtrosEmpleados';
    const [gridApi] = useState(null);
    const api = useApiAgenda();
    const [datos, setDatos] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const soloLectura = !seguridad.esEditable(constantes.menus.puestos);
    const [id, setEquipo] = useState(false);
    const obtenerFiltro = (filtro) => {
        filtro = filtro ?? getSessionState(filtroKey);
        return {
            ...filtro
        };
    };
    const permisoGuardarAgenda = seguridad.tieneAcceso(constantes.opciones.permisoGuardarAgenda);
    async function buscar(filtro, paramsApi) {
         let datos = await api.filtrar(obtenerFiltro(filtro));
         setDatos(datos);


    }
    const verTelefonos = (id) => {
        setSidebarVisible(true);
        setEquipo(id);
    };
    const RowButtons = ({ data }) => (
        <div className="d-flex justify-content-end">
           <ButtonIcon title={'Editar'} iconName={'FaRegEdit'} className={'text-primary'}  onClick={() => verTelefonos(data.id)}/>
        </div>
    );
    return (
        <>
           <Filtro filtroKey={filtroKey} handleSubmit={buscar} defaultValue={{ nombre: '' }} gridApi={gridApi}
                classButtons="field-iconos btn-group">
                <FiltroAgenda />
            </Filtro>

            <Sidebar visible={sidebarVisible} position="right" dismissable={false} className="p-sidebar-xl"
                onHide={() => setSidebarVisible(false)}>
                <AgendaDetalle empleadoid={id} onSave={buscar} name="datosGenerales"
                    close={() => setSidebarVisible(false)} soloLectura={soloLectura} />
            </Sidebar>
             <div className="table-custom">
                <table className="table table-sm table-striped table-fixed"
                    style={{ height: '98%' }}>
                    <thead>
                        <tr>
                            <th style={{ width: 100 }}>Nombres</th>
                            <th style={{ width: 800 }}>Linea ABC</th>
                            {(permisoGuardarAgenda) &&
                              <th style={{ width: 120 }}>Linea Personal</th>          
                            }                                
                            <th className="center">
                              
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {datos?.map((item, index) => {
                          return (
                                <tr key={index} >
                                    <td style={{ width: 400 }}>{item.nombre}</td>                                   
                                    <td style={{ width: 200 }}>{item.telefonoAbc}</td>                                  
                                    {(permisoGuardarAgenda) &&
                                         <td className="text-center">{item.telefonoPersonal}</td>          
                                    }  

                                    <td>
                                        <RowButtons data={item} />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default Agenda;