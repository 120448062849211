/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import { Button } from "primereact/button";
import React, { useEffect, useReducer, useState } from "react";
import useApiEstaciones from "../../api/catalogos/useApiEstaciones";
import formReducer from "../../shared/forms/formReducer";
import FormHandler from "../../shared/forms/formHandler";
import estacionesValidacionEsquema from "./estacionesValidacionEsquema";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import FieldDropDown from "../../shared/controls/fieldDropDown";
import FieldCheckBox from "../../shared/controls/fieldCheckBox";
import ReadOnlyProvider from "../../shared/forms/ReadOnlyProvider";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import produce from "immer";
import alerts from "../../shared/alerts";
import "./estaciones.css";
import useApiSource from "../../api/source";
import { TabPanel, TabView } from "primereact/tabview";

const DetalleEstaciones = ({ estacionId, onSave, close, soloLectura }) => {
    const api = useApiEstaciones();
    const apiSource = useApiSource();

    const defaultEntity = () => ({
        entity: { id: 0, activo: true, clientesIds: [], usuariosIds: [], subClientesIds: [] },
    });
    const [{ entity, errors }, dispatch] = useReducer(formReducer, {}, defaultEntity);
    const [clientes, setClientes] = useState(null);
    const [subClientes, setsubClientes] = useState(null);

    const [activeIndex, setActiveIndex] = useState();

    useEffect(() => {
        async function cargar() {
            dispatch({ type: "update", value: await api.obtener(estacionId) });
        }

        if (estacionId > 0) {
            cargar().then();
        }
        cargarCatalogos();
    }, []);

    async function cargarCatalogos() {
        const clientes = await apiSource.obtener("clientes");
        setClientes(clientes);

        const subClientes = await apiSource.obtener("subClientesTodos");
        setsubClientes(subClientes);
    }

    const actualizar = (value, propertyName) => {
        dispatch({ type: "update", value: value, name: propertyName });
    };

    const existeClientesLogisticos = () => {
        const clientesLogisticos = clientes?.filter((n) => entity.clientesIds.includes(n.id) && n.esLogistico);
        return clientesLogisticos?.length > 0;
    };

    const formHandler = new FormHandler({
        validationSchema: estacionesValidacionEsquema,
        api,
        dispatch,
    });

    const guardar = async () => {
        if (entity.clientesIds?.some((e) => e === null)) {
            alerts.aviso("Seleccione los clientes correctamente");
            return;
        }

        if (entity.usuariosIds?.some((e) => e === null)) {
            alerts.aviso("Seleccione los usuarios correctamente");
            return;
        }

        if (entity.subClientesIds?.some((e) => e === null)) {
            alerts.aviso("Seleccione los subclientes correctamente");
            return;
        }

        await formHandler.save(entity);

        if (formHandler.valdation.result) {
            onSave();
        }
    };

    function agregarALista(lista, namelist) {
        const nuevo = produce(lista, (draft) => {
            draft.splice(lista.length, 0, null);
        });

        dispatch({ type: "update", value: nuevo, name: namelist });
    }

    function eliminarDeLista(lista, index, namelist) {
        if (namelist === "clientesIds") {
            let subclientesAEliminar = subClientes?.filter((element) => {
                if (element.clienteId === lista[index]) {
                    return element.id;
                }
                return null;
            });

            const subclientes = entity.subClientesIds?.filter(
                (n) => !subclientesAEliminar.some((item) => item.id === n)
            );

            dispatch({ type: "update", value: subclientes, name: "subClientesIds" });
        }

        const nuevo = produce(lista, (draft) => {
            draft.splice(index, 1);
        });

        dispatch({ type: "update", value: nuevo, name: namelist });
    }

    function actualizarLista(lista, index, value, namelist) {
        const nuevo = produce(lista, (draft) => {
            draft[index] = value;
        });

        dispatch({ type: "update", value: nuevo, name: namelist });
    }

    return (
        <>
            <div className="form-container full-height">
                <ReadOnlyProvider defaultReadOnly={soloLectura}>
                    <div className="form-row">
                        <h5 className="col-12">Datos Generales</h5>

                        <FieldTextBox
                            name="nombre"
                            label="Nombre"
                            colMd={10}
                            required
                            maxLength={100}
                            value={entity.nombre}
                            onChange={actualizar}
                            errors={errors}
                        />

                        <div className="form-group col-md-2 check-Activo">
                            <FieldCheckBox name="activo" label="Activo" value={entity.activo} onChange={actualizar} />
                        </div>
                    </div>
                    <TabView
                        activeIndex={activeIndex}
                        onTabChange={(e) => setActiveIndex(e?.index)}
                        className="main-tab-view diesel"
                    >
                        <TabPanel header="Clientes">
                            <div className="form-row">
                                <table className="table main-edit-table tableFixHead" align="right">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col" className="center" style={{ width: "10%" }}>
                                                Cliente
                                            </th>
                                            <th className={"input"} style={{ width: "0%" }}>
                                                <ButtonIcon
                                                    title={"Agregar Clientes"}
                                                    iconName={"SiAddthis"}
                                                    onClick={() => agregarALista(entity.clientesIds, "clientesIds")}
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {entity.clientesIds?.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <FieldDropDown
                                                            name={"estacion"}
                                                            source="clientes"
                                                            fire={true}
                                                            valueById={true}
                                                            required
                                                            value={entity.clientesIds[index]}
                                                            onChange={(value) =>
                                                                actualizarLista(
                                                                    entity.clientesIds,
                                                                    index,
                                                                    value,
                                                                    "clientesIds"
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <ButtonIcon
                                                            title={"Eliminar"}
                                                            onClick={() =>
                                                                eliminarDeLista(
                                                                    entity.clientesIds,
                                                                    index,
                                                                    "clientesIds"
                                                                )
                                                            }
                                                            iconName={"RiDeleteBinLine"}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </TabPanel>
                        <TabPanel header={"Sub Clientes"} style={{ display: existeClientesLogisticos() ? "" : "none" }}>
                            <div className="form-row">
                                <table className="table main-edit-table tableFixHead" align="right">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col" className="center" style={{ width: "10%" }}>
                                                SubCliente
                                            </th>
                                            <th className={"input"} style={{ width: "0%" }}>
                                                <ButtonIcon
                                                    title={"Agregar SubClientes"}
                                                    iconName={"SiAddthis"}
                                                    onClick={() =>
                                                        agregarALista(entity.subClientesIds, "subClientesIds")
                                                    }
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ height: "150px" }}>
                                        {entity.subClientesIds?.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="input center">
                                                        <FieldDropDown
                                                            name={"estacion"}
                                                            source="subClientesporClientes"
                                                            params={{ clientesId: entity.clientesIds }}
                                                            fire={true}
                                                            valueById={true}
                                                            required
                                                            value={entity.subClientesIds[index]}
                                                            onChange={(value) =>
                                                                actualizarLista(
                                                                    entity.subClientesIds,
                                                                    index,
                                                                    value,
                                                                    "subClientesIds"
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                    <td className="input">
                                                        <ButtonIcon
                                                            title={"Eliminar"}
                                                            onClick={() =>
                                                                eliminarDeLista(
                                                                    entity.subClientesIds,
                                                                    index,
                                                                    "subClientesIds"
                                                                )
                                                            }
                                                            iconName={"RiDeleteBinLine"}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </TabPanel>
                        <TabPanel header="Usuarios">
                            <div className="form-row">
                                <table className="table main-edit-table tableFixHead" align="right">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col" className="center" style={{ width: "10%" }}>
                                                Usuario
                                            </th>
                                            <th className={"input"} style={{ width: "0%" }}>
                                                <ButtonIcon
                                                    title={"Agregar Usuarios"}
                                                    iconName={"SiAddthis"}
                                                    onClick={() => agregarALista(entity.usuariosIds, "usuariosIds")}
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ height: existeClientesLogisticos() ? "150px" : "210px" }}>
                                        {entity.usuariosIds?.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="input center">
                                                        <FieldDropDown
                                                            name="estacion"
                                                            source="usuarios"
                                                            required
                                                            fire={true}
                                                            valueById={true}
                                                            value={entity.usuariosIds[index]}
                                                            onChange={(value) =>
                                                                actualizarLista(
                                                                    entity.usuariosIds,
                                                                    index,
                                                                    value,
                                                                    "usuariosIds"
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                    <td className="input">
                                                        <ButtonIcon
                                                            title={"Eliminar"}
                                                            onClick={() =>
                                                                eliminarDeLista(
                                                                    entity.usuariosIds,
                                                                    index,
                                                                    "usuariosIds"
                                                                )
                                                            }
                                                            iconName={"RiDeleteBinLine"}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </TabPanel>
                    </TabView>
                </ReadOnlyProvider>
            </div>
            <div className="form-footer">
                <Button label="Cancelar" className="p-button-outlined" type="button" onClick={close} />

                {!soloLectura && <Button label="Guardar" type="button" onClick={guardar} />}
            </div>
        </>
    );
};

export default DetalleEstaciones;
