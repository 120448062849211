/* eslint-disable react-hooks/exhaustive-deps */
import useApiSource from "../../api/source";
import useSeguridad from "../../Seguridad/useSeguridad";
import React, {useCallback, useEffect, useState} from "react";
import constantes from "../../catalogos/constantes";

const Seccion = ({movimientoActivoId, onChange}) => {
    const seguridad = useSeguridad();
    const [movs, setMovs] = useState([])
    const permisoOficina = seguridad.tieneAcceso(constantes.opciones.BusquedaMovsOficina);
    const permisoStaff = seguridad.tieneAcceso(constantes.opciones.BusquedaMovsStaff);
    const permisoOperativos = seguridad.tieneAcceso(constantes.opciones.BusquedaMovsOperativos);
    const api = useApiSource()
    const cargar = useCallback(async () => {
        const movimientos = await api.obtener('esquemasPagoIncapacidad')
        setMovs([...movimientos])
    }, [])

    useEffect(() => {
        cargar().then()
    }, [cargar]);

    const movimientosFiltrados = movs.filter((movimiento) => {
        if (movimiento.id === constantes.esquemaPago.liquidacion && permisoOperativos) {
            return true; // Mostrar Movimientos Operativos
        }
        if (movimiento.id === constantes.esquemaPago.staff && permisoStaff) {
            return true; // Mostrar Movimientos Staff
        }
        if (movimiento.id === constantes.esquemaPago.oficina && permisoOficina) {
            return true; // Mostrar Movimientos Oficina
        }
        return false; 
    });
console.log(movimientosFiltrados)
    return (
        <div className="tab-tabs movimientos">
            {movimientosFiltrados.map((movimiento, index) => (
                <div key={index} className={`tab-tab ${movimiento.id === movimientoActivoId ? 'activa' : ''}`} onClick={() => {
                    onChange(
                        movimiento.id
                    )
                }}>
                    <span>{movimiento.nombre}</span>
                </div>
            ))}
        </div>
    )
}
export default Seccion;