import {FormattedDate, FormattedTime} from "react-intl";
import React from "react";

const DateTimeFormatter = ({value}) => (
    <>
        {value != null ?
            <>
                <FormattedDate value={value} day="2-digit" year="numeric" month="short"/>&nbsp;
                <FormattedTime value={value} hour24={true} />
            </> :
            ''
        }
    </>
);

export default DateTimeFormatter;
