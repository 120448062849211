import React from "react";

import FieldInputNumber from "../../shared/controls/fieldInputNumber";
import produce from "immer";

const ParametroControlPatios = ({ Caja53Ps, Caja48Ps, onChange, errors }) => {
  function actualizarPropiedad(objeto, value, propertyName, propertyEntity) {
    const nuevo = produce(objeto, (draft) => {
      draft[propertyName] = value;
    });

    onChange(nuevo, propertyEntity);
  }

  return (
    <>
      <div style={{ background: "white" }}>
        <div style={{ width: "100%" }} className={`section-container`}>
          <h5 style={{ width: "100%" }}>Control de Patios</h5>

          <div className="flex-col-6">
            <div className="form-row">
              <table  style={{ width: "100%", fontSize: "smaller" }}  className="com-parametro table table-borderless center"  >                                     
                <thead>
                  <tr>
                    <th colSpan="6">CAJA 53 PIES</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th>LARGO</th>
                    <th>ANCHO</th>
                    <th>ALTO</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key="{1}">
                    <td>Medidas Internas</td>
                    <td>
                      <FieldInputNumber  className="small"  name="internaLargo"  fractionDigits={2}   showButtonBar={false}      value={Caja53Ps?.internaLargo}                                                                                                               
                        errors={errors[`caja53Ps`]}
                        onChange={(value, pName) =>  actualizarPropiedad(Caja53Ps,
                          value,
                          pName,
                          "caja53Ps"                                                     
                          )
                        }
                      />
                    </td>
                    <td>
                      {" "}
                      <FieldInputNumber  className="small"  name="InternaAncho" showButtonBar={false}  fractionDigits={2}    value={Caja53Ps?.internaAncho}                                                                                                                        
                        errors={errors[`caja53Ps`]}
                        onChange={(value, pName) =>
                          actualizarPropiedad(
                            Caja53Ps,
                            value,
                            pName,
                            "caja53Ps"
                          )
                        }
                      />
                    </td>
                    <td>
                      {" "}
                      <FieldInputNumber
                        label=""
                        className="small"
                        name="internaAlto"
                        showButtonBar={false}
                        fractionDigits={2}
                        value={Caja53Ps?.internaAlto}
                        errors={errors[`caja53Ps`]}
                        onChange={(value, pName) =>
                          actualizarPropiedad(
                            Caja53Ps,
                            value,
                            pName,
                            "caja53Ps"
                          )
                        }
                      />
                    </td>
                  </tr>
                  <tr key="{2}">
                    <td>Medidas Externas</td>
                    <td>
                      <FieldInputNumber
                        label=""
                        className="small"
                        name="externaLargo"
                        fractionDigits={2}
                        showButtonBar={false}
                        value={Caja53Ps?.externaLargo}
                        errors={errors[`caja53Ps`]}
                        onChange={(value, pName) =>
                          actualizarPropiedad(
                            Caja53Ps,
                            value,
                            pName,
                            "caja53Ps"
                          )
                        }
                      />
                    </td>
                    <td>
                      {" "}
                      <FieldInputNumber
                        label=""
                        className="small"
                        name="externaAncho"
                        showButtonBar={false}
                        fractionDigits={2}
                        value={Caja53Ps?.externaAncho}
                        errors={errors[`caja53Ps`]}
                        onChange={(value, pName) =>
                          actualizarPropiedad(
                            Caja53Ps,
                            value,
                            pName,
                            "caja53Ps"
                          )
                        }
                      />
                    </td>
                    <td>
                      {" "}
                      <FieldInputNumber
                        label=""
                        className="small"
                        name="externaAlto"
                        showButtonBar={false}
                        fractionDigits={2}
                        value={Caja53Ps?.externaAlto}
                        errors={errors[`caja53Ps`]}
                        onChange={(value, pName) =>
                          actualizarPropiedad(
                            Caja53Ps,
                            value,
                            pName,
                            "caja53Ps"
                          )
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex-col-6">
            <div className="form-row">
              <table
                style={{ width: "100%", fontSize: "smaller" }}
                className="com-parametro table table-borderless center"
              >
                <thead>
                  <tr>
                    <th colSpan="6">CAJA 48 PIES</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th>LARGO</th>
                    <th>ANCHO</th>
                    <th>ALTO</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key="{1}">
                    <td>Medidas Internas</td>
                    <td>
                      <FieldInputNumber
                        label=""
                        className="small"
                        name="internaLargo"
                        showButtonBar={false}
                        value={Caja48Ps?.internaLargo}
                        errors={errors[`caja48Ps`]}
                        fractionDigits={2}
                        onChange={(value, pName) =>
                          actualizarPropiedad(
                            Caja48Ps,
                            value,
                            pName,
                            "caja48Ps"
                          )
                        }
                      />
                    </td>
                    <td>
                      {" "}
                      <FieldInputNumber
                        label=""
                        className="small"
                        name="InternaAncho"
                        fractionDigits={2}
                        showButtonBar={false}
                        value={Caja48Ps?.internaAncho}
                        errors={errors[`caja48Ps`]}
                        onChange={(value, pName) =>
                          actualizarPropiedad(
                            Caja48Ps,
                            value,
                            pName,
                            "caja48Ps"
                          )
                        }
                      />
                    </td>
                    <td>
                      {" "}
                      <FieldInputNumber
                        label=""
                        className="small"
                        name="internaAlto"
                        fractionDigits={2}
                        showButtonBar={false}
                        value={Caja48Ps?.internaAlto}
                        errors={errors[`caja48Ps`]}
                        onChange={(value, pName) =>
                          actualizarPropiedad(
                            Caja48Ps,
                            value,
                            pName,
                            "caja48Ps"
                          )
                        }
                      />
                    </td>
                  </tr>
                  <tr key="{2}">
                    <td>Medidas Externas</td>
                    <td>
                      <FieldInputNumber
                        label=""
                        className="small"
                        name="externaLargo"
                        fractionDigits={2}
                        showButtonBar={false}
                        value={Caja48Ps?.externaLargo}
                        errors={errors[`caja48Ps`]}
                        onChange={(value, pName) =>
                          actualizarPropiedad(
                            Caja48Ps,
                            value,
                            pName,
                            "caja48Ps"
                          )
                        }
                      />
                    </td>
                    <td>
                      {" "}
                      <FieldInputNumber
                        label=""
                        className="small"
                        name="externaAncho"
                        showButtonBar={false}
                        fractionDigits={2}
                        value={Caja48Ps?.externaAncho}
                        errors={errors[`caja48Ps`]}
                        onChange={(value, pName) =>
                          actualizarPropiedad(
                            Caja48Ps,
                            value,
                            pName,
                            "caja48Ps"
                          )
                        }
                      />
                    </td>
                    <td>
                      {" "}
                      <FieldInputNumber
                        label=""
                        className="small"
                        name="externaAlto"
                        fractionDigits={2}
                        showButtonBar={false}
                        value={Caja48Ps?.externaAlto}
                        errors={errors[`caja48Ps`]}
                        onChange={(value, pName) =>
                          actualizarPropiedad(
                            Caja48Ps,
                            value,
                            pName,
                            "caja48Ps"
                          )
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParametroControlPatios;
