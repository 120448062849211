import React, { useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import agGridHelper from "../../shared/ag-grid-helper";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import { getSessionState } from "../../hooks/useSessionState";
import Filtro from "../../shared/componentes/filtro";
import FieldCalendarRange from "../../shared/controls/fieldCalendarRange";
import FieldMultiSelect from "../../shared/controls/fieldMultiSelect";
import { Sidebar } from "primereact/sidebar";
import useApiVacaciones from "../../api/administracion/useApiVacaciones";
import DetalleVacaciones from "./vacacionesDetalle";
import {    
    AgDateTimeFormatter    
    
} from "../../shared/ag-grid-helper/GridFormatters";
const Vacaciones = ({ soloLectura }) => {
    const filtroKey = 'FiltroVacaciones';
    const [datos, setDatos] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [vacacionesId, setVacacionesId] = useState(false);
    const api = useApiVacaciones();

    const onGridReady = async (params) => {
        setGridApi(params.api);
    };
   
    async function buscar(filtro, paramsApi) {
        agGridHelper.showLoad(gridApi, paramsApi, true);
        setDatos(await api.filtrar(filtro ?? getSessionState(filtroKey)));
        agGridHelper.hideLoad(gridApi, paramsApi, false);
    }

    async function onSave() {
        setSidebarVisible(false);
        await buscar();
    }

    const verVacaciones = (id) => {
        setSidebarVisible(true);
        setVacacionesId(id);
    };

    function exportar() {
        api.excel(getSessionState(filtroKey));
    }
    const ButtonsHeader = () => {
        return (
            <div className="ag-grid header-icons">
                <ButtonIcon title="Descargar" onClick={exportar} iconName="BiDownload" />
                {!soloLectura &&
                    <ButtonIcon title="Agregar" className="grid-action" iconName="MdAddBox"
                        onClick={() => verVacaciones(0)} />
                }
            </div>
        );
    };

    const RowButtons = ({ data }) => (
        <div className="ag-grid row-icons">
            <button className="btn btn-link btn-sm" title={'Editar'} onClick={() => verVacaciones(data.id)}>
                Ver
            </button>
        </div>
    );

    return (
        <>
            <Filtro filtroKey={filtroKey} handleSubmit={buscar} fechasDiasInicio={7} fechasRangoDias={7}>
                <Filtros />
            </Filtro>

            <Sidebar visible={sidebarVisible} position="right" dismissable={false} className="p-sidebar-md"
                onHide={() => setSidebarVisible(false)}>
                <DetalleVacaciones vacacionesId={vacacionesId} onSave={onSave}
                    close={() => setSidebarVisible(false)} />
            </Sidebar>

            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    frameworkComponents={
                        {
                            buttonsHeader: ButtonsHeader,
                            rowButtons: RowButtons,
                            goToDetail: agGridHelper.GoToDetailFormatter
                        }
                    }
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    rowHeight={35}
                >
                    <AgGridColumn field="empleado" cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn field="antiguedad" headerName="Antigüedad" />
                    <AgGridColumn field="diasPorAntiguedad" headerName="Días p/Antigüedad" />
                    <AgGridColumn field="diasTomados" headerName="Días Tomados" />
                    <AgGridColumn field="diasDisponibles" headerName="Días Disponibles" />
                    <AgGridColumn field="dias" headerName="Días Solicitados" />
                    <AgGridColumn field="estatus" />
                    <AgGridColumn field="fechaSolicitud" headerName="Fecha Solicitud" valueFormatter={AgDateTimeFormatter} minWidth={150} />
                    <AgGridColumn field="fechaAutorizacion" headerName="Fecha Autorización" valueFormatter={AgDateTimeFormatter} minWidth={150} />
                    <AgGridColumn field="monto" headerName="Monto" valueFormatter={agGridHelper.decimalFormatter}/>
                    <AgGridColumn maxWidth={80}
                        headerName="VER"
                        cellRenderer="rowButtons"
                        pinned={'right'}
                        cellClass={'left'}
                        headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>
        </>
    )
};

const Filtros = ({ filtro, onChange }) => {
    return (
        <>
            <FieldMultiSelect name="empleados" source="empleados" label="Empleado" colLg={2} colMd={3}
                value={filtro.empleados} onChange={onChange} showFilter valueAsId={true} />

            <FieldCalendarRange name="fechas" label="Fecha Solicitado" colLg={2} colMd={3} value={filtro.fechas}
                onChange={onChange} />

            <FieldMultiSelect name="estatus" source="estatusVacaciones" label="Estatus" colLg={2} colMd={2}
                value={filtro.estatus} onChange={onChange} valueAsId={true} />
        </>
    )
};

export default Vacaciones;
