/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from "moment";
import _ from "lodash";
import OrdenTrabajoEvento from "./ordenTrabajoEvento";
import UseApiOrdenServicio from "../../../../api/taller/useApiOrdenServicio";

const Calendario = ({filtros}) => {
    const ref = React.createRef();
    const [eventos, setEventos] = useState();
    const apiOrdenServicio = UseApiOrdenServicio();

    useEffect(()=>{
        filtrar(filtros);
    },[filtros]);

    const filtrar = async (rango) => {
        if (filtros == null)
            return;
        const filtro = {...filtros, fechas:[rango.start, rango.end]};
        actualizarEventos(await apiOrdenServicio.filtrar(filtro));
    };

    const actualizarEventos = (servicios) => {
        const mecanicos = _.uniq(servicios.map(e => e.mecanicoId));
        const nuevosEventos =servicios.map(e => {
            return {
                ...e,
                tipo: mecanicos.indexOf(e.mecanicoId) % 4,
                start: e.fechaEntrada,
                end: e.fechaSalida
            }
        });
        setEventos(nuevosEventos);

    };


    return (
        <FullCalendar
            ref={ref}
            defaultView="timeGridWeek"
            plugins={[timeGridPlugin]}
            height={'auto'} // sets height to height of resources.
            slotWidth={60}
            allDaySlot={false}
            locale={'es'}
            datesSet={filtrar}
            slotMinTime={'08:00'} // start timeline at this time, must be in format '08:00'
            // slotMaxTime={'15:00'} // end timeline at this time, must be in format '18:00'
            slotLabelFormat={(d) => moment(d.date).format('hh:mm a')}
            events={eventos}
            eventContent={OrdenTrabajoEvento}
        />
    )
};

export default Calendario;


