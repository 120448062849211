const constantes = {
    estatusSolicitudes: {
        abierta: 61,
        programada: 62,
        cerrada: 63,
    },
    estatusOrdenesTrabajo: {
        pteProgramar: 71,
        programada: 72,
        reprogramada: 73,
        MttoRealizado: 74,
        entradaTaller: 75,
        cancelado: 79,
    },

    tiposServicio: {
        MantenimientoCorrectivo: 261,
        MantenimientoPreventivo: 262,
    },
    estatusLlanta: {
        enUso: 81,
        enAlmacen: 82,
        enDesecho: 83,
        envioRenovado: 84,
        renovado: 85,
        rechazoRenovado: 86,
    },
    usuarioStorage: "usuarioABC",
    tiposUnidad: {
        Pipa: 2,
        Utilitario: 6,
    },

    estatusCheck: {
        Ok: 391,
        NecesitaServicio: 392,
        NA: 393,
    },
    estatusRenovacionLlanta: {
        envioRenovado: 181,
        renovado: 182,
        rechazoRenovado: 183,
    },
    empresa: {
        ABC: 1,
        TBX: 2,
        REX: 3,
        SCF: 4,
    },
};

export default constantes;
