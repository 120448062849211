/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";
import {RequiredValidator} from "../validators";

const requiredValidator = new RequiredValidator();

const useInputValidation = ({value, field, required, validators, onValidate, parent}) => {
    const [validationErrors, setValidationErrors] = useState(new Map());

    const validate = ()=>{
        if (!required  && (validators??[]).length === 0 ) {
            if(onValidate != null) {
                setValidationErrors(new Map());
                onValidate(false);
            }
            return;
        }

        let errors = new Map();
        const currentValidators = [...(validators??[]), ...(required ? [requiredValidator] : []) ];
        for (let validator of currentValidators){
            const result = validator?.validate(value, field, parent)??{};
            if (result.error){
                errors.set(result.errorType, result.message)
            }
        }

        setValidationErrors(errors);
        onValidate(errors.size > 0);
    }

    useEffect(() => {
            validate();
    }, [value, required]);

    return {
        hasValidationError : validationErrors.size > 0,
        validationErrorCss :  validationErrors.size > 0 ?  'validation-error' :''
    };
};

export default useInputValidation;
