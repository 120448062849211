/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react';
import Filtro from "../../../shared/componentes/filtro";
import FieldAutoComplete from "../../../shared/controls/fieldAutoComplete";
import FieldCalendarRange from "../../../shared/controls/fieldCalendarRange";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import FieldMultiSelect from "../../../shared/controls/fieldMultiSelect";
import constantes from "../../../catalogos/constantes";
import { Checkbox } from "primereact/checkbox";
import produce from "immer";
import moment from "moment";
import { FormattedDate } from "react-intl";
import { getSessionState } from "../../../hooks/useSessionState";
import useApiPrefacturacion from "../../../api/operaciones/useApiPrefacturacion";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import { Sidebar } from "primereact/sidebar";
import alerts from "../../../shared/alerts";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import IconLibrary from "../../../shared/iconLibrary";
import { Button } from "primereact/button";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import FieldInputNumber from "../../../shared/controls/fieldInputNumber";
import * as numeral from "numeral";
import PrefacturacionDetalle from "./prefacturacionDetalle";

import useSeguridad from "../../../Seguridad/useSeguridad";

const Prefacturacion = () => {
    const filtroKey = 'filtrosProgramacionPrefacturacion';
    const [datos, setDatos] = useState([]);
    const [todoSeleccionado, setTodoSeleccionado] = useState(false);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [prefacturaId, setPrefacturaId] = useState(0);
    const api = useApiPrefacturacion();
    const styleEspacioCelda = { backgroundColor: '#f3f5fb', width: '1%', borderTop: 'none' };
    const ref = useRef(null);
    const seguridad = useSeguridad();
    const [permisoDesligarFactura, setDesligarFactura] = useState(false);
    const [permisoReenviarFactura, setReenviarFactura] = useState(false);

    useOnClickOutside(ref, () => iluminarFila());

    useEffect(() => {
        let permisoDesligarFactura = seguridad.tieneAcceso(constantes.opciones.DesligarFactura);
        let permisoReenviarFactura = seguridad.tieneAcceso(constantes.opciones.ReenviarFactura);
        setDesligarFactura(permisoDesligarFactura);
        setReenviarFactura(permisoReenviarFactura);
    },[]);

    function obtenerFiltro(filtro) {
        filtro = filtro ?? getSessionState(filtroKey);
        return {
            ...filtro,
            rutaOrigenId: filtro.rutaOrigen?.id,
            rutaDestinoId: filtro.rutaDestino?.id,
            esProgramacion: true,
        };
    }

    async function buscar(filtro) {
        const viajes = await api.filtrar(obtenerFiltro(filtro));
        setDatos(viajes);
    }

    //Selecciona los viajes
    const seleccionarTodo = (e) => {
        const checked = e.target.checked;

        const newDatos = produce(datos, (draft) => {
            datos.forEach((n, index) => {
                draft[index].seleccionado = checked;
            })
        });

        setTodoSeleccionado(checked);
        setDatos(newDatos);
    };

    const seleccionar = (e, item, index) => {
        const checked = e.target.checked;

        const newDatos = produce(datos, (draft) => {
            draft[index].seleccionado = checked;
        });

        setDatos(newDatos);
        setTodoSeleccionado(newDatos.every(e => e.seleccionado));
    };

    // Se utilizara para actualizacion de datos
    const actualizar = (value, name, index) => {
        let newDatos = produce(datos, (draft) => {
            draft[index][name] = value;

            draft[index].subtotal = draft[index].flete + draft[index].pistas +
                draft[index].cambio + draft[index].maniobras + draft[index].otros +
                (draft[index].cantidadDemoras * draft[index].demoras);


            draft[index].existeCambio = true;

        });


        newDatos = newDatos.map((item) => {

            if (item.prefacturacionPadreId == null) {

                const agrupados = newDatos.filter(n => n.prefacturacionPadreId === item.id);

                if (agrupados.length === 0) {
                    return { ...item };
                }

                var flete = 0, pistas = 0, cambio = 0,
                    maniobras = 0, cantidadDemoras = 0, demoras = 0, otros = 0, subtotal = 0;

                agrupados.forEach(s => {
                    flete += s.flete;
                    pistas += s.pistas;
                    cambio += s.cambio;
                    maniobras += s.maniobras;
                    cantidadDemoras += s.cantidadDemoras;
                    demoras += s.demoras;
                    otros += s.otros;
                    subtotal += s.subtotal;
                })

                return {
                    ...item, flete: flete, pistas: pistas, cambio: cambio, maniobras: maniobras, cantidadDemoras: cantidadDemoras, demoras: demoras
                    , otros: otros, subtotal: subtotal
                };

            }
            else
                return { ...item };

        });

        setDatos(newDatos);
    };

    const guardar = async (index) => {

        if (datos[index].existeCambio) {
            datos[index].existeCambio = false;
            await api.actualizar(datos[index].id, datos[index]);
            let newDatos = produce(datos, (draft) => {
                draft[index].existeCambio = false;
            });


            setDatos(newDatos);

        }
    };

    //Se utilizara para abrir el modal
    function abrirForma(prefacturaId = 0) {
        setSidebarVisible(true);
        setPrefacturaId(prefacturaId);
    }

    async function desligarFactura(id) {
        if (
            (await alerts.preguntarSiNoAdvertencia(
              "¿Desea desligar la factura actual de SAS?"
            )) === true
          ) { 
            await api.desligarFactura(id);
            onSave();
          }
    }

    async function onSave() {
        setSidebarVisible(false);
        await buscar();
    }

    function iluminarFila(item, index) {
        const indexFilaPrevia = datos.findIndex(e => (!item || e.id !== item.id) && e.iluminado);

        const newDatos = produce(datos, (draft) => {
            if (index != null) {
                draft[index].iluminado = true;
            }

            if (indexFilaPrevia >= 0) {
                draft[indexFilaPrevia].iluminado = false;
            }
        });

        setDatos(newDatos);
    }

    //Utilizado para expandir
    const expandir = (item, index) => {
        const expandido = !item.expandido;

        const newDatos = produce(datos, (draft) => {
            draft[index].expandido = expandido;

            const segmentos = datos.filter(e => e.prefacturacionPadreId === item.id);

            segmentos.forEach(s => {
                const indexSegmento = datos.findIndex(e => e.id === s.id);

                draft[indexSegmento].visible = expandido;
            })
        });

        setDatos(newDatos);
    };

    // Se utilizara para agregar estilos
    function obtenerEstiloFila(item) {
        const highlight = item.iluminado ? 'highlight' : '';
        const esAgrupado = item.esAgrupado ? 'viaje-segmento' : '';
        return `${highlight} ${esAgrupado}`
    }

    const reenviar = async() => {
        var cartaporte = await alerts.inputCartaPorte('Carta Porte a Reenviar a Prefacturación:');
        if (cartaporte) {
            await api.reenviaraPrefacturacion(cartaporte);
            buscar().then();
        }        
    }

    const agrupar = async () => {

        let datosSeleccionados = datos.filter(x => x.seleccionado && !x.esAgrupado);
        let datosEstatusIncorrecto = datosSeleccionados.filter(x => x.estatusId !== constantes.estatusPrefacturacion.porfacturar);

        if (datosSeleccionados.length <= 1 || datosEstatusIncorrecto.length > 0) {
            alerts.aviso('Debe seleccionar al menos 2 prefacturas por facturar');
            return;
        }

        var cliente = datosSeleccionados[0].clienteId;
        let datosClienteSeleccionados = datosSeleccionados.filter(x => x.clienteId === cliente);

        if (datosClienteSeleccionados.length !== datosSeleccionados.length) {
            alerts.aviso('Debe seleccionar prefacturas del mismo cliente');
            return;
        }


        var moneda = datosSeleccionados[0].moneda;
        let datosmonedaSeleccionados = datosSeleccionados.filter(x => x.moneda === moneda);

        if (datosmonedaSeleccionados.length !== datosSeleccionados.length) {
            alerts.aviso('Debe seleccionar prefacturas con el mismo tipo de moneda');
            return;
        }

        const ids = datosSeleccionados.map(e => e.id).join(",");

        if (await alerts.preguntarSiNoAdvertencia('Se agruparan las prefacturas seleccionadas ¿Desea continuar?')) {
            await api.agruparPrefacturacion(ids);
            buscar().then();
        }

    };

    const eliminarAgrupacion = async (id) => {


        if (await alerts.preguntarSiNoAdvertencia('Se eliminara la prefactura de la agrupacion ¿Desea continuar?')) {
            await api.eliminarAgrupacion(id);
            buscar().then();
        }

    };

    function exportar() {
        api.excel(getSessionState(filtroKey));
    }

    function deshabilitarConceptos(prefacturacion) {
        if (prefacturacion.esAgrupado && prefacturacion.prefacturacionPadreEstatus !== constantes.estatusPrefacturacion.porfacturar)
            return true;

        if (prefacturacion.tieneAgrupados)
            return true;

        if (prefacturacion.estatusId !== constantes.estatusPrefacturacion.porfacturar)
            return true;
        if(prefacturacion.timbrado ===true)
            return true;

        return false;
    }

    return (
        <>
            <Filtro filtroKey={filtroKey} classButtons="field-iconos" styleForm={{ marginTop: "40px" }} filtrarAlInicio={false}
                fechasRangoDias={7} fechasDiasInicio={7} handleSubmit={buscar} modulo={constantes.modulos.prefacturacion}
                showSaveFilterButton>

                <FiltrosProgramacion />
            </Filtro>

            <Sidebar visible={sidebarVisible} position="right" dismissable={false} className="p-sidebar-lg"
                onHide={() => setSidebarVisible(false)}>

                <PrefacturacionDetalle prefacturaId={prefacturaId} onSave={onSave}
                    close={() => onSave()} />

            </Sidebar>


            <div className="table-custom">
                <table className="table table-sm table-striped table-fixed">
                    <thead>
                        <tr>
                            <th style={{ width: '2%' }}>
                                <Checkbox onChange={seleccionarTodo} checked={todoSeleccionado} />
                            </th>
                            <th style={{ width: '3%' }} />
                            <th className="text-left" style={{ width: '5%' }}>FECHA</th>
                            <th style={{ width: '30%' }}>VIAJE</th>
                            <th style={{ width: '11%' }}>COMENTARIOS</th>
                            <th style={styleEspacioCelda} />
                            <th style={{ width: '8%' }}>FLETE ($)</th>
                            <th style={{ width: '8%' }}>PISTAS ($)</th>
                            <th style={{ width: '8%' }}>CAMBIO ($)</th>
                            <th style={{ width: '10%' }}>MANIOBRAS ($)</th>
                            <th style={{ width: '4%' }}></th>
                            <th style={{ width: '8%' }}>DEMORAS($)</th>
                            <th style={{ width: '8%' }}>OTROS ($)</th>
                            <th style={styleEspacioCelda} />
                            <th style={{ width: '8%' }}>SUBTOTAL</th>
                            <th className="text-center" style={{ width: '20%' }}>ESTATUS</th>
                            <th className="header-icons" style={{ width: '12%' }}>
                                <div className="d-flex" style={{ float: 'right' }}>
                                    <ButtonIcon title={'Descargar'} onClick={exportar} iconName={'BiDownload'} />
                                    {permisoReenviarFactura &&
                                        <ButtonIcon title={'Reenviar a Prefacturacion'} className={'grid-action'} onClick={reenviar} iconName={'FaReceipt'} />
                                    }
                                    <ButtonIcon title={'Agrupar'} className={'grid-action'} onClick={agrupar} iconName={'FaObjectGroup'} />
                                </div>
                            </th>
                        </tr>
                    </thead>

                    <tbody ref={ref}>
                        {datos.map((item, index) => {
                            return (
                                <>
                                    {(!item.esAgrupado || item.visible) &&
                                        <tr key={item.id} className={obtenerEstiloFila(item)}>
                                            <td className="text-center" style={{ width: '2%' }}>
                                                {!item.esAgrupado &&
                                                    <Checkbox checked={item.seleccionado}
                                                        onChange={(e) => seleccionar(e, item, index)} />
                                                }
                                            </td>
                                            <td style={{ width: '2%' }}>
                                                {item.tieneAgrupados &&
                                                    <div className="cursor-pointer d-flex pl-1 align-items-center"
                                                        onClick={() => expandir(item, index)} title="Ver Segmentos"
                                                        style={{ fontSize: "1rem" }}>
                                                        <IconLibrary
                                                            iconName={`IoIosArrowDrop${item.expandido ? 'downCircle' : 'rightCircle'}`}
                                                        />
                                                    </div>
                                                }
                                            </td>
                                            <td style={{ width: '6%' }}>
                                                {!item.esAgrupado &&
                                                    <>
                                                        <div className="d-flex flex-wrap align-items-center"
                                                            style={{ fontSize: '0.8em' }}>
                                                            <FormattedDate value={item.fechaInicio} weekday="short" /> &nbsp;
                                                            {moment(item.fechaInicio).format('DD/MM/YYYY')}
                                                        </div>
                                                    </>
                                                }
                                            </td>




                                            <td style={{ width: '30%' }}>
                                                {!item.esAgrupado &&
                                                    <>
                                                        <b>{item.clienteNombre}</b>
                                                        <span className="pl-1 text-muted" style={{ fontSize: '0.8em' }}>{` ${item.cartaPorte}`}</span>

                                                    </>
                                                }

                                                {item.tieneAgrupados &&
                                                    <div className="d-flex flex-wrap align-items-center"
                                                        style={{ fontSize: '0.8em' }}>
                                                        Varios
                                                    </div>
                                                }
                                                {!item.tieneAgrupados &&
                                                    <div className="d-flex flex-wrap align-items-center"
                                                        style={{ fontSize: '0.8em' }}>
                                                        {item.rutas.map((r, index) => {
                                                            return <React.Fragment key={r.id}>
                                                                <div>{`${r.nombre} (${r.hora})`}</div>
                                                                {index < item.rutas.length - 1 &&
                                                                    <i className="pi pi-arrow-right px-1" />
                                                                }
                                                            </React.Fragment>
                                                        }
                                                        )}
                                                        {item.esAgrupado &&
                                                            <>
                                                                -CP-{item.cartaPorte}
                                                            </>
                                                        }
                                                    </div>
                                                }
                                            </td>
                                            <td style={{ width: '11%' }}>
                                                <div className="d-flex flex-wrap align-items-center"
                                                    style={{ fontSize: '0.8em' }}>
                                                    {
                                                        item.comentarios
                                                    }
                                                </div>
                                            </td>
                                            <td style={styleEspacioCelda} />
                                            <td style={{ width: '8%' }}>
                                                <FieldInputNumber disabled={deshabilitarConceptos(item, true)} fractionDigits={2} onBlur={() => guardar(index)} onChange={(value) => actualizar(value, "flete", index)} value={item.flete} name="flete" />
                                            </td>
                                            <td style={{ width: '8%' }}>
                                                <FieldInputNumber disabled={deshabilitarConceptos(item, false)} fractionDigits={2} onBlur={() => guardar(index)} onChange={(value) => actualizar(value, "pistas", index)} value={item.pistas} name="pistas" />
                                            </td>
                                            <td style={{ width: '8%' }}>
                                                <FieldInputNumber disabled={deshabilitarConceptos(item, false)} fractionDigits={2} onBlur={() => guardar(index)} onChange={(value) => actualizar(value, "cambio", index)} value={item.cambio} name="cambio" />
                                            </td>
                                            <td style={{ width: '8%' }}>
                                                <FieldInputNumber disabled={deshabilitarConceptos(item, false)} fractionDigits={2} onBlur={() => guardar(index)} onChange={(value) => actualizar(value, "maniobras", index)} value={item.maniobras} name="maniobras" />
                                            </td>
                                            <td style={{ width: '4%' }}>
                                                <FieldInputNumber disabled={deshabilitarConceptos(item, false)} onBlur={() => guardar(index)} onChange={(value) => actualizar(value, "cantidadDemoras", index)} value={item.cantidadDemoras} name="cantidadDemoras" />
                                            </td>
                                            <td style={{ width: '8%' }}>
                                                <FieldInputNumber disabled={deshabilitarConceptos(item, false)} fractionDigits={2} onBlur={() => guardar(index)} onChange={(value) => actualizar(value, "demoras", index)} value={item.demoras} name="demoras" />
                                            </td>

                                            <td style={{ width: '8%' }}>
                                                <FieldInputNumber disabled={deshabilitarConceptos(item, false)} fractionDigits={2} onBlur={() => guardar(index)} onChange={(value) => actualizar(value, "otros", index)} value={item.otros} name="otros" />
                                            </td>

                                            <td style={styleEspacioCelda} />
                                            <td style={{ width: '8%', textAlign: "center" }}> {numeral(item.subtotal).format('$0,0.00')}   {item.monedaDescripcion}</td>
                                            <td style={{ width: '20%', textAlign: "center" }}>
                                                {item.prefacturacionPadreEstatus == null &&
                                                    <>
                                                    <div className="d-flex flex-wrap align-items-center"
                                                        style={{ fontSize: '1.2em' }}>
                                                        {item.estatus}                                                      
                                                        {item.estatusId === constantes.estatusPrefacturacion.enviadoAFacturacion &&
                                                         item.facturaId > 0 &&
                                                        <> {`SaaS(${item.facturaId})`}
                                                        </>
                                                        }
                                                        {item.viajeFactura>0 &&
                                                        <> {`SaaSCCP(${item.viajeFactura})`}
                                                        </>
                                                        }
                                                        {item.estatusId === constantes.estatusPrefacturacion.facturado &&
                                                        <> {`(${item.numeroFactura})`}
                                                        </>
                                                        }
                                                        {item.numeroFacturaVIaje !==null  &&                                                         
                                                        <> {`(${item.numeroFacturaVIaje})`}
                                                        </>
                                                        }
                                                    </div>
                                                    </>
                                                }
                                            </td>
                 
                                            <td style={{ width: '12%' }}>
                                                {item.esAgrupado ?
                                                    <>
                                                        {item.prefacturacionPadreEstatus === constantes.estatusPrefacturacion.porfacturar &&
                                                            <img src="/img/desagrupar.png" onClick={() => eliminarAgrupacion(item.id)} className="pr-2" alt="arrow"
                                                                style={{ height: "25px", marginLeft: "6px" }} />
                                                        }
                                                    </>
                                                    :
                                                    <div className="d-flex align-items-center">
                                                        <div style={{ display: "flex" }} className="table-button flex-grow-3">
                                                        {item.facturaId > 0 &&
                                                        <> 
                                                         <ButtonIcon marginTop="10px" color="#2760D3" title="Factura" className="grid-action"
                                                                iconName={'AiFillDollarCircle'} />
                                                        </>
                                                        }
                                                        {item.viajeFactura>0 &&
                                                        <><div className="text-primary" title="Timbrado CCP"  style={{fontSize: "1rem"}}>
                                                            <IconLibrary iconName="FaDollarSign"/>
                                                        </div>
                                                        </>
                                                        }
                                                       
                                                        {/* <div className="text-primary" title="Timbrado">
                                                                <IconLibrary iconName="FaDollarSign"/>
                                                            </div> */}
                                                            <Button onClick={() => abrirForma(item.id)} label="Ver" className="p-button-link btn-sm" />
                                                            {permisoDesligarFactura
                                                            && (item.estatusId === constantes.estatusPrefacturacion.enviadoAFacturacion  || item.estatusId === constantes.estatusPrefacturacion.facturado)
                                                            && 
                                                                <ButtonIcon marginTop="10px" color="#2760D3" title="Desligar Factura" className="grid-action"
                                                                    iconName={'AiFillApi'} onClick={() => desligarFactura(item.id)}/>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    }
                                </>
                            )
                        })
                        }
                    </tbody>
                </table>
            </div>




        </>
    );
};

const FiltrosProgramacion = ({ filtro, onChange }) => {

    // useEffect(() => {

    //     filtro ={...filtro,         
    //         esLiberado: true,
    //     }
    // }, []);

    return (
        <>
            <FieldMultiSelect name="clientes" source="clientes" label="Cliente" colLg={2} colMd={3}
                value={filtro.clientes} onChange={onChange} showFilter valueAsId={true} />

            <FieldCalendarRange name="fechas" label="Fechas" colLg={2} colMd={2}
                value={filtro.fechas} onChange={onChange} />
            <div className="form-group col-md-3 col-sm-12">
                <label>Ruta</label>
                <div className="col-auto">
                    <FieldAutoComplete name="rutaOrigen" colMd={2} freeText={true} placeholder="Desde"
                        source="municipios" value={filtro.rutaOrigen} onChange={onChange} />
                    <FieldAutoComplete name="rutaDestino" colMd={2} freeText={true} placeholder="Hasta"
                        source="municipios" value={filtro.rutaDestino} onChange={onChange} />
                </div>
            </div>


            <FieldTextBox name="idViaje" label="ID Viaje" colLg={1} colMd={3}
                value={filtro.idViaje} onChange={onChange} />

            <FieldTextBox name="cartaPorte" label="Carta Porte" colLg={1} colMd={3}
                value={filtro.cartaPorte} onChange={onChange} />

            <FieldMultiSelect name="estatus" source="estatusFacturacion" label="Estatus" colLg={2} colMd={3}
                value={filtro.estatus} onChange={onChange} valueAsId={true} />

            <FieldCheckBox flexflow="row-reverse" name="esLiberado" colMd={11} label="Solo Liberados" value={filtro.esLiberado ?? true} onChange={onChange} />
        </>
    )
};


export default Prefacturacion;


