import React, { useState } from "react";
import FiltroSolicitudServicios from "./filtroSolicitudServicios";
import DetalleSolicitudServicio from "./detalleSolicitudServicio";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import agGridHelper from "../../../shared/ag-grid-helper";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import useApiSolicitud from "../../../api/catalogos/useApiSolicitudServicio";
import { Sidebar } from "primereact/sidebar";
import { getSessionState } from "../../../hooks/useSessionState";
import constantes from "../../constantes";
import Filtro from "../../../shared/componentes/filtro";

const SolicitudesServicios = ({ soloLectura }) => {
    const filtroKey = 'filtrosSolicitudServicios';
    const [datos, setDatos] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [solicitudServicioId, setSolicitudServicioId] = useState(false);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const apiSolicitud = useApiSolicitud();
    const defaultFilter = {
        estatus: [
            constantes.estatusSolicitudes.abierta,
            constantes.estatusSolicitudes.programada
        ]
    };

    async function buscar(filtro, paramsApi) {
        closeSolicitud();
        agGridHelper.showLoad(gridApi, paramsApi, true);
        setDatos(await apiSolicitud.filtrar(filtro));
        agGridHelper.hideLoad(gridApi, paramsApi, false);
    }

    const onGridReady = async (params) => {
        setGridApi(params.api);
    };

    //Agregar nueva Solicitud Servicio
    async function nuevo() {
        setSidebarVisible(true);
        setSolicitudServicioId(0);
    }

    function onUpdateStatus() {
        setSidebarVisible(false);
        buscar();
    }

    const editSolicitud = (id) => {
        setSidebarVisible(true);
        setSolicitudServicioId(id);
    };

    const closeSolicitud = () => {
        setSidebarVisible(false);
    };

    function exportar() {
        apiSolicitud.excel(getSessionState(filtroKey));
    }

    const ButtonsHeader = ({ api }) => {
        return (
            <div className={'ag-grid header-icons'}>
                <ButtonIcon title={'Descargar'} onClick={exportar} iconName={'BiDownload'} />
                {!soloLectura &&
                    <ButtonIcon title="Agregar" className={'grid-action'} iconName={'MdAddBox'} onClick={nuevo} />
                }
            </div>
        );
    };

    const RowButtons = ({ data, rowIndex, api, context }) => (
        <div className={'ag-grid row-icons'}>
            <ButtonIcon title={'Editar'} iconName={'FaRegEdit'} onClick={() => context.editSolicitud(data.id)} />
        </div>
    );

    return (
        <>


            <Sidebar visible={sidebarVisible} position="right" dismissable={false} className="p-sidebar-bg" onHide={() => setSidebarVisible(false)}>
                <DetalleSolicitudServicio solicitudServicioId={solicitudServicioId} onSave={buscar} onUpdateStatus={onUpdateStatus} closeSolicitud={closeSolicitud}
                    soloLectura={soloLectura} />
            </Sidebar>

            <Filtro filtroKey={filtroKey} handleSubmit={buscar} defaultValue={defaultFilter} gridApi={gridApi}>
                <FiltroSolicitudServicios />
            </Filtro>

            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    rowSelection={'single'}
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    frameworkComponents={
                        {
                            buttonsHeader: ButtonsHeader,
                            dateFormatter: agGridHelper.DateFormatter,
                            rowButtons: RowButtons
                        }
                    }
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    context={{
                        editSolicitud: editSolicitud
                    }}
                >
                    <AgGridColumn field="codigo" headerName="SOLICITUD SERVICIO" cellClass={'center'} />
                    <AgGridColumn field="descripcion" headerName="DESCRIPCIÓN" cellClass={'center'} />
                    <AgGridColumn field="tipoUnidad" headerName="TIPO UNIDAD" headerClass={'center'} minWidth={150} />
                    <AgGridColumn field="unidad" headerName="NO. ECONOMICO" minWidth={150} />
                    <AgGridColumn field="nombreSolicitante" headerName="NOMBRE SOLICITANTE" minWidth={150} />
                    <AgGridColumn field="fechaSolicitud" cellRenderer="dateFormatter" headerName="FECHA SOLICITADO" minWidth={150} />
                    <AgGridColumn field="fechaProgramada" cellRenderer="dateFormatter" headerName="FECHA PROGRAMADA" minWidth={150} />
                    <AgGridColumn field="fechaCierre" cellRenderer="dateFormatter" headerName="FECHA DE CIERRE" minWidth={150} />
                    <AgGridColumn field="estatus" headerName="ESTATUS" cellClass={'center'} />
                    <AgGridColumn maxWidth={80}
                        headerName="VER"
                        cellRenderer="rowButtons"
                        pinned={'right'}
                        cellClass={'left'}
                        headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>

        </>
    )
};

export default SolicitudesServicios;
