/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Tooltip } from "primereact/tooltip";
import numeral from "numeral";
import { ProgressSpinner } from "primereact/progressspinner";
import useApiEstatusDiarios from "../../api/reportes/useEstatusDiariosApi";

const Viajes = ({ facturacion, unidadId, dia, otraEmpresa, coordinador, coordinadorNombreCompleto }) => {
    const api = useApiEstatusDiarios();

    const [loading, setLoading] = useState(false);
    const [viajes, setViajes] = useState(null);

    const onShowViajes = async () => {
        if (viajes == null) {
            setLoading(true);
            const result = await api.viajes(unidadId, dia.fecha);
            setViajes(result);
            setLoading(false);
        }
    };

    return (
        <>
            {facturacion > 0 && (
                <div onMouseEnter={onShowViajes} className="img-viajes">
                    {!loading ? (
                        <>
                            <Tooltip
                                target={`.viajes-${unidadId}-${dia.fecha}`}
                                style={{ maxWidth: "500px" }}
                                hideDelay={100}
                            >
                                <div style={{ width: "450px" }} className="tooltip-viajes">
                                    <div>Emp.</div>
                                    <div>Cliente</div>
                                    <div>Ruta</div>
                                    <div className="right">Monto</div>
                                    {viajes?.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <div>{item.empresaNombre}</div>
                                            <div>{item.cliente}</div>
                                            <div>{item.ruta}</div>
                                            <div className="right">
                                                {" "}
                                                {numeral(item.facturacionSegmento).format("$0,0")}
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </Tooltip>
                            <img className={`viajes-${unidadId}-${dia.fecha}`} src="/img/Truck.png" alt="viajes" />
                            <span style={{ position: "absolute", fontWeight: "bold", left: "-25px" }}>
                                {otraEmpresa}
                            </span>

                            <Tooltip
                                style={{ fontSize: "10px" }}
                                target={`.tooltip-${coordinador}-${unidadId}-${dia.fecha}`}
                                hideDelay={100}
                            >
                                {coordinadorNombreCompleto}
                            </Tooltip>
                            <span
                                className={`tooltip-${coordinador}-${unidadId}-${dia.fecha}`}
                                style={{ position: "absolute", fontWeight: "bold", top: "15px", cursor: "pointer" }}
                            >
                                {coordinador}
                            </span>
                        </>
                    ) : (
                        <ProgressSpinner style={{ width: "30px", height: "30px" }} />
                    )}
                </div>
            )}
        </>
    );
};

export default Viajes;
