import useServiceFactory from "../serviceFactory";

const apiUrl = "/administracion/incapacidad";

const useApiIncapacidad = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URL});
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, {params, showLoading: true}),
        obtener: async (id) => service.get(`${apiUrl}/${id}`),
        guardar: async (formData) => service.post(`${apiUrl}`, formData),    
        excel: async (params) =>
            service.get(`${apiUrl}/excel`, {
                params,
                showLoading: true,
                download: true,
                fileName: `Incapacidad.xlsx`,
            })
    };
};

export default useApiIncapacidad;
