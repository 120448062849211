import { createContext } from "react";
import useOfflineApi from "./useOfflineApi";

export const OfflineApiContext = createContext({});

export const OfflineApiProvider = ({ children }) => {
    const value = useOfflineApi();

    return (
        <OfflineApiContext.Provider value={value}>{children}</OfflineApiContext.Provider>
    );
};
