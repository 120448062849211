/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import FieldCalendar from "../../../shared/controls/fieldCalendar";
import produce from "immer";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import { Accordion, AccordionTab } from "primereact/accordion";
import InputFile from "../../../shared/controls/inputFile";
import ImageFile from "../../../shared/controls/imageFile";
import useSeguridad from "../../../Seguridad/useSeguridad";
import QRCode from "qrcode";
import constantes from "../../constantes";
const Archivos = ({
    empleado,
    onChange,
    foto,
    archivosAdicionales,
    documentosGenerales,
    documentosOperador,
    errors,
    soloLectura,
}) => {

    const [errorDocumentos, setErrorDocumentos] = useState({});
    const [errorLicencias, setErrorLicencias] = useState(false);
    const canvasRef = useRef();
    const seguridad = useSeguridad();
    const permisoQREmpleados = seguridad.tieneAcceso(constantes.opciones.EmpleadosQR);
    useEffect(() => {
        if (empleado?.asignacion?.esOperador === true) {
            if (empleado.licencias.length === 0) agregarALista(empleado.licencias, "licencias");
        }
        if (permisoQREmpleados) {
            var text2Encode = `{idEmpleado: ${empleado?.codigoEmpleado},nombreEmpleado: ${empleado?.datosGenerales?.nombre} ${empleado?.datosGenerales?.apellidoPaterno} ${empleado?.datosGenerales?.apellidoMaterno},empresa: ${empleado?.empresaId} }`;
            //console.log("text2Encode:" + text2Encode);
            var cipherText = Buffer.from(text2Encode).toString("base64");
            //console.log("base64Text:" + base64EncodedText);

            QRCode.toCanvas(
                canvasRef.current,
                // QR code doesn't work with an empty string
                // so we are using a blank space as a fallback
                cipherText,
                (error) => error && console.error(error)
            );
        }
    }, [empleado.asignacion?.puesto?.id, empleado?.asignacion?.tipoEmpleado?.id]);

    function updateArrayGenerales(tipoDocumentoId, value, propertyName) {
        const newValue = produce(documentosGenerales, (draft) => {
            const item = draft.find((e) => e.tipoDocumento.id === tipoDocumentoId);
            item[propertyName] = value;
        });
        onChange(newValue, "documentosGenerales");
    }

    function updateArrayOperador(tipoDocumentoId, value, propertyName) {
        const newValue = produce(documentosOperador, (draft) => {
            const item = draft.find((e) => e.tipoDocumento.id === tipoDocumentoId);
            item[propertyName] = value;
        });
        onChange(newValue, "documentosOperador");
    }

    function actualizarLista(lista, index, value, propertyName, namelist) {
        const nuevo = produce(lista, (draft) => {
            draft[index][propertyName] = value;
        });

        onChange(nuevo, namelist);
    }

    function agregarALista(lista, namelist) {
        const nuevo = produce(lista, (draft) => {
            draft.splice(0, 0, {});
        });
        onChange(nuevo, namelist);
    }

    function eliminarDeLista(lista, index, namelist) {
        const nuevo = produce(lista, (draft) => {
            draft.splice(index, 1);
        });

        onChange(nuevo, namelist);
    }

    function agregarArchivosAdicionales() {
        const nuevo = produce(archivosAdicionales, (draft) => {
            draft.push({});
        });
        onChange(nuevo, "archivosAdicionales");
    }

    function actualizarArchivosAdicionales(index, value, propertyName) {
        const newValue = produce(archivosAdicionales, (draft) => {
            draft[index][propertyName] = value;
        });
        onChange(newValue, "archivosAdicionales");
    }

    function eliminarArchivosAdicionales(index) {
        const nuevo = produce(archivosAdicionales, (draft) => {
            draft.splice(index, 1);
        });

        onChange(nuevo, "archivosAdicionales");
    }
    useEffect(() => {
        setErrorDocumentos({
            generales:
                errors.documentosGenerales != null && documentosGenerales.findIndex((d) => tieneError(d, false)) >= 0,
            operadores:
                errors.documentosOperador != null && documentosOperador.findIndex((d) => tieneError(d, true)) >= 0,
        });

        if (errors["licencias"] == null) {
            setErrorLicencias(false);
        } else {
            setErrorLicencias(true);
        }
    }, [errors]);

    function tieneError(documento, esParaOperador) {
        return esParaOperador
            ? documento.tipoDocumento.esParaOperador === true &&
                  documento.tipoDocumento.requerido === true &&
                  (documento.numeroDocumento == null || documento.numeroDocumento === "" || documento.vigencia == null)
            : documento.tipoDocumento.esParaOperador === false &&
                  documento.tipoDocumento.requerido === true &&
                  (documento.archivo?.isEmpty ?? true) === true;
    }

    return (
        <>
            <div className="d-flex justify-content-center">
                <h5>Foto</h5>
            </div>
            <div className="d-flex justify-content-center">
                <div style={{ padding: 15 }}>
                    <ImageFile name="foto" value={foto} onChange={(value) => onChange(value, "foto")} />
                </div>

                {permisoQREmpleados && <canvas ref={canvasRef} />}
            </div>
            <Accordion multiple activeIndex={[0]}>
                <AccordionTab
                    header={
                        <>
                            <span> Documentos Generales</span>
                            {errorDocumentos.generales && <ButtonIcon type={"icon"} iconName={"RiErrorWarningLine"} />}
                        </>
                    }
                >
                    <table className="table table-borderless">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col" style={{ width: "50%" }}>
                                    TIPO DOCUMENTO
                                </th>
                                <th scope="col" style={{ width: "30%" }}>
                                    VIGENCIA
                                </th>
                                <th scope="col" style={{ width: "20%" }}>
                                    DOCUMENTO
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {documentosGenerales.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="input">
                                            {item.tipoDocumento.requerido ? "*" : ""}
                                            {item.tipoDocumento.nombre}
                                        </td>
                                        <td>
                                            {item.tipoDocumento.id === constantes.tipoDocumento.antecedentes && (
                                                <FieldCalendar
                                                    name="vigencia"
                                                    value={item.vigencia}
                                                    className="small large-padding-bottom"
                                                    showButtonBar={false}
                                                    errors={errors[`documentosGenerales[${index}]`]}
                                                    onChange={(value, propertyName) =>
                                                        updateArrayGenerales(item.tipoDocumento.id, value, propertyName)
                                                    }
                                                />
                                            )}
                                        </td>
                                        <td className="input">
                                            <InputFile
                                                name="archivo"
                                                value={item.archivo}
                                                errors={errors[`documentosGenerales[${index}]`]}
                                                onChange={(value, propertyName) =>
                                                    updateArrayGenerales(item.tipoDocumento.id, value, propertyName)
                                                }
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </AccordionTab>
                <AccordionTab
                    header={
                        <>
                            <span> Documentos Operador</span>
                            {errorLicencias && <ButtonIcon type={"icon"} iconName={"RiErrorWarningLine"} />}
                        </>
                    }
                    headerStyle={{ display: empleado?.asignacion?.esOperador ? "" : "none" }}
                >
                    {documentosOperador.map((item, index) => (
                        <>
                            {
                                <div className="form-row">
                                    <h5 className="col-12">
                                        {item.tipoDocumento.requerido ? "*" : ""}
                                        {item.tipoDocumento.nombre}
                                    </h5>
                                    <FieldTextBox
                                        name="numeroDocumento"
                                        label={`Numero de ${item.tipoDocumento.nombre}`}
                                        colMd={5}
                                        value={item.numeroDocumento}
                                        errors={errors[`documentosOperador[${index}]`]}
                                        onChange={(value, propertyName) =>
                                            updateArrayOperador(item.tipoDocumento.id, value, propertyName)
                                        }
                                    />
                                    <FieldCalendar
                                        name="vigencia"
                                        label={`Vigencia ${item.tipoDocumento.nombre}`}
                                        colMd={5}
                                        value={item.vigencia}
                                        className="small large-padding-bottom"
                                        showButtonBar={false}
                                        errors={errors[`documentosOperador[${index}]`]}
                                        onChange={(value, propertyName) =>
                                            updateArrayOperador(item.tipoDocumento.id, value, propertyName)
                                        }
                                    />
                                    <div className={`form-group col-md-2 col-sm-6'`}>
                                        <label>&nbsp;</label>
                                        <div className="d-flex">
                                            <InputFile
                                                name="archivo"
                                                value={item.archivo}
                                                errors={errors[`documentosOperador[${index}]`]}
                                                onChange={(value, propertyName) =>
                                                    updateArrayOperador(item.tipoDocumento.id, value, propertyName)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    ))}
                    {empleado.licencias.map((item, index) => (
                        <>
                            {
                                <div>
                                    <div className="form-row">
                                        <h5 className="col-12">*Licencia</h5>
                                        <FieldTextBox
                                            name="numeroDocumento"
                                            label={`Numero de Licencia`}
                                            colMd={5}
                                            value={item.numeroDocumento}
                                            errors={errors[`licencias[${index}]`]}
                                            onChange={(value, pName) =>
                                                actualizarLista(empleado.licencias, index, value, pName, "licencias")
                                            }
                                        />
                                        <FieldCalendar
                                            name="vigencia"
                                            label={`Vigencia Licencia`}
                                            colMd={5}
                                            value={item.vigencia}
                                            className="small large-padding-bottom"
                                            showButtonBar={false}
                                            errors={errors[`licencias[${index}]`]}
                                            onChange={(value, pName) =>
                                                actualizarLista(empleado.licencias, index, value, pName, "licencias")
                                            }
                                        />
                                        <div className={`form-group col-md-2 col-sm-6'`}>
                                            <label>&nbsp;</label>
                                            <div className="d-flex">
                                                <InputFile
                                                    name="archivo"
                                                    value={item.archivo}
                                                    errors={errors[`licencias[${index}]`]}
                                                    onChange={(value, pName) =>
                                                        actualizarLista(
                                                            empleado.licencias,
                                                            index,
                                                            value,
                                                            pName,
                                                            "licencias"
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <FieldDropDown
                                            name="tipoLicencia"
                                            label="Categoria Licencia"
                                            colMd={5}
                                            source="tiposLicencia"
                                            filter
                                            errors={errors[`licencias[${index}]`]}
                                            value={item?.tipoLicencia}
                                            onChange={(value, pName) =>
                                                actualizarLista(empleado.licencias, index, value, pName, "licencias")
                                            }
                                        />

                                        <FieldDropDown
                                            name="categoriaLicencia"
                                            label="Modalidad Licencia"
                                            colMd={5}
                                            source="categoriasLicencia"
                                            filter
                                            errors={errors[`licencias[${index}]`]}
                                            value={item?.categoriaLicencia}
                                            onChange={(value, pName) =>
                                                actualizarLista(empleado.licencias, index, value, pName, "licencias")
                                            }
                                        />

                                        <FieldDropDown
                                            name="tipoLicenciaModalidad"
                                            label="Tipo Licencia"
                                            colMd={5}
                                            source="tiposLicenciaModalidad"
                                            filter
                                            errors={errors[`licencias[${index}]`]}
                                            value={item?.tipoLicenciaModalidad}
                                            onChange={(value, pName) =>
                                                actualizarLista(empleado.licencias, index, value, pName, "licencias")
                                            }
                                        />

                                        <div className={`form-group col-md-2 col-sm-6'`}>
                                            <label>&nbsp;</label>
                                            <div className="d-flex">
                                                {!soloLectura && (
                                                    <ButtonIcon
                                                        title={"Eliminar"}
                                                        onClick={() =>
                                                            eliminarDeLista(empleado.licencias, index, "licencias")
                                                        }
                                                        iconName={"RiDeleteBinLine"}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    ))}

                    <div className="form-row">
                        {!soloLectura && (
                            <Button
                                label="Agregar Otra Licencia"
                                icon="pi pi-plus-circle"
                                type="button"
                                className="p-button-outlined"
                                onClick={() => agregarALista(empleado.licencias, "licencias")}
                            />
                        )}
                    </div>
                </AccordionTab>
                <AccordionTab
                    header={
                        <>
                            <span> Archivos Adicionales</span>
                        </>
                    }
                >
                    <table className="table">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col" style={{ width: "60%" }}>
                                    NOMBRE DEL DOCUMENTO
                                </th>
                                <th scope="col" style={{ width: "15%" }}></th>
                                <th scope="col" className="center" style={{ width: "15%" }}>
                                    ARCHIVO
                                </th>
                                <th className={"ag-grid header-icons"} style={{ width: "10%" }}>
                                    {!soloLectura && (
                                        <ButtonIcon
                                            title={"Agregar archivo"}
                                            iconName={"SiAddthis"}
                                            onClick={agregarArchivosAdicionales}
                                            className="grid-action"
                                        />
                                    )}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {archivosAdicionales?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="input">
                                            <FieldTextBox
                                                name="nombre"
                                                value={item.nombre}
                                                errors={errors[`archivosAdicionales[${index}]`]}
                                                onChange={(value, pname) =>
                                                    actualizarArchivosAdicionales(index, value, pname)
                                                }
                                            />
                                        </td>
                                        <td className="input center">
                                            <InputFile
                                                name="archivo"
                                                value={item.archivo}
                                                errors={errors[`archivosAdicionales[${index}]`]}
                                                onChange={(value, pname) =>
                                                    actualizarArchivosAdicionales(index, value, pname)
                                                }
                                            />
                                        </td>
                                        <td className="input">
                                            {!soloLectura && (
                                                <ButtonIcon
                                                    title={"Eliminar"}
                                                    onClick={() => eliminarArchivosAdicionales(index)}
                                                    iconName={"RiDeleteBinLine"}
                                                />
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </AccordionTab>
            </Accordion>
        </>
    );
};

export default Archivos;
