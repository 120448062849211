import * as yup from "yup";

const detalleDanioValidacionEsquema = yup.object().shape({
    descripcion: yup.string().required(),
    tipoAveria: yup.object({
        id: yup.number().required().min(1)
    }),
    // foto: yup.object({
    //     url: yup.string().required()
    // })
});

export default detalleDanioValidacionEsquema;
