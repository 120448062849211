
import React from "react";
import FieldInputMask from "../fieldInputMask";


const FieldInputPhone = ({colMd, colSm, label, name, onChange, onBlur, value, keyFilter, rows, disabled,formatType,error, errors,required}) => {


    return (
        <>

          {label   &&
           <FieldInputMask maskVariable="(999 999 99 99)" required={required} name={name} label={label} colMd={colMd} errors={errors} disabled={disabled}
            value={ value }  onChange={onChange}/>
          }

           {!label  &&
            <FieldInputMask maskVariable="(999 999 99 99)" name={name} colMd={colMd} errors={errors} disabled={disabled}
             value={ value }  onChange={onChange}/>            
            }
        </>
    )
};


export default FieldInputPhone;