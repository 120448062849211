/* eslint-disable react-hooks/exhaustive-deps */
import React ,{ useState }from "react";
import { Dialog } from "primereact/dialog";
import FieldCheckBox from '../../shared/controls/fieldCheckBox';
import FieldTextArea from "../../shared/controls/fieldTextArea";
import FieldDropDown from "../../shared/controls/fieldDropDown";
import { Button } from "primereact/button";
import useApiEmpleados from "../../api/catalogos/useApiEmpleados";
import useApiAsignacionEquipos from "../../api/administracion/useAsignacionEquipos";
const EquiposAsignados =({ equiposVisible,setEquiposVisible, equipo, onSave })=>{
    const apiAsignacion = useApiAsignacionEquipos();
    const apiEmpleados = useApiEmpleados();
    const [asignacionEmpleado, setAsignacionEmpleado] =  useState('');
    const [nota, setNota] =  useState('');


        async function actualizarAsignacion(value) {

        let apAsignacion = await apiEmpleados.obtener(value.id);

        setAsignacionEmpleado('')
        setAsignacionEmpleado(apAsignacion)     
    };
    async function guardarAsignacion() {        
        equipo.empleadoId=asignacionEmpleado.id;
        equipo.equipoId=equipo.id
        equipo.nota = nota;

        await apiAsignacion.guardar(equipo);
        setEquiposVisible(false);
        onSave();
    };
    async function actualizarNota(value) {    

        setNota(value)     
    };
    return (        
        <>   
            <Dialog header={"¿Desea asignar equipo?"}  visible={equiposVisible} style={{ width: '65vw' }} modal onHide={() => setEquiposVisible(false)}>
                        <div className="form-row">
                            <FieldCheckBox name="compartido" label="Compartido" flexflow="row-reverse" colMd={12}/>
                        </div>
                        <div className="form-row">
                        <FieldDropDown  value={ asignacionEmpleado?.empleadoId }  required name="empleado" label="Empleado" colMd={12} source="empleados" filter className="dropdown-bottom"
                                onChange={actualizarAsignacion}/>
                            <FieldDropDown disabled value={ asignacionEmpleado?.asignacion?.departamento }  name="departamentoId" label="Departamento" colMd={12} source="departamentos" filter/>

                            <FieldDropDown disabled required value={ asignacionEmpleado?.asignacion?.puesto} name="puestoId" label="Puesto" colMd={12} source="puestos"  filter className="dropdown-bottom"
                                />

                        <FieldDropDown disabled value={  asignacionEmpleado?.asignacion?.jefeInmediato } name="jefeInmediatoId" label="Jefe Inmediato" colMd={12} source="empleados" filter className="dropdown-bottom" />   
                        
                        <FieldTextArea label={'Notas'} colMd={12} rows={5}  onChange={actualizarNota} 
                                value={ equipo?.nota} />
                        
                        </div>
                        <div className="form-footer">
                            <Button label="Guardar" className="p-button-outlined" type="button" onClick={guardarAsignacion}
                                    />            
                            </div>
                    </Dialog>
        </>
    )
}
export default EquiposAsignados;