import * as yup from "yup";
import constantes from "../../catalogos/constantes";


const guardar = yup.object().shape({
        datosGenerales: yup.object({
            numeroEconomico: yup.string().required(),
            tipoEquipo: yup.object({
                id: yup.number().required().min(1),
            }),
            tipoUnidad: yup.object({
                id: yup.number().required().min(1),
            }),
            marcaArrastre: yup.mixed().when("esMotriz", {
                is: false,
                then: yup.object({
                    id: yup.number().required().min(1),
                }),
            }),
            marcaMotriz: yup.mixed().when("esMotriz", {
                is: true,
                then: yup.object({
                    id: yup.number().required().min(1),
                }),
            }),
            modelo: yup.number().required(),
            // arrendatario: yup.string().when("renta", {
            //     is: true,
            //     then: yup.string().required()
            // }),
        })
    })
;

const alta = yup.object().shape({
    datosGenerales: yup.object({
        numeroEconomico: yup.string().required(),
        tipoEquipo: yup.object({
            id: yup.number().required().min(1),
        }),
        tipoUnidad: yup.object({
            id: yup.number().required().min(1),
        }),
        marcaArrastre: yup.mixed().when("esMotriz", {
            is: false,
            then: yup.object({
                id: yup.number().required().min(1),
            }),
        }),
        marcaMotriz: yup.mixed().when("esMotriz", {
            is: true,
            then: yup.object({
                id: yup.number().required().min(1),
            }),
        }),
        modelo: yup.number().required(),
        placas: yup.string().required(),
        numeroSerie: yup.string().required(),
        nacionalCruce: yup.object({
            id: yup.number().required().min(1),
        }),

        proveedorGPS: yup.object({
            id: yup.number().required().min(1),
        }),

        polizaSeguro: yup.mixed().when("tipoEquipo.id", {
            is: constantes.tiposEquipo.motriz,
            then: yup.mixed().required()
        }),

        incisoPoliza: yup.mixed().when("tipoEquipo.id", {
            is: constantes.tiposEquipo.motriz,
            then: yup.mixed().required()
        }),

        agenteSeguro: yup.mixed().when("tipoEquipo.id", {
            is: constantes.tiposEquipo.motriz,
            then: yup.mixed().required()
        }),

        telefonoAseguradora: yup.mixed().when("tipoEquipo.id", {
            is: constantes.tiposEquipo.motriz,
            then: yup.mixed().required()
        }),


        aseguradora: yup.mixed().when("tipoEquipo.id", {
            is: constantes.tiposEquipo.motriz,
            then: yup.object().shape({
                id: yup.number().required().min(1),
            })
        }),
        // polizaRemolqueIndistinto: yup.string().required(),
        // polizaRemolque: yup.string().required(),
        // endoso: yup.string().required(),
        // arrendatario: yup.string().when("renta", {
        //     is: true,
        //     then: yup.string().required()
        // }),
    }),
    especificaciones: yup.mixed().when("datosGenerales.esMotriz", {
        is: true,
        then: yup.object({
            capacidadTanque: yup.number().required(),
            odometroInicial: yup.number().required(),
            marcaMotor: yup.object({
                id: yup.number().required().min(1),
            }),
            numeroMotor: yup.string().required(),
            softwareEscaner: yup.object({
                id: yup.number().required().min(1),
            }),
            tarjetasCruce: yup.array()
                .of(
                    yup.object().shape({
                        numero: yup.mixed().when("requerido", {
                            is: true,
                            then: yup.string().required(),
                        })
                    })
                ),
            tipoCombustible: yup.array()
                .of(
                    yup.object()
                        .shape({
                            nombre: yup.string().required()
                        })
                )
        }),
    }),
    documentacion:
        yup.array()
            .of(
                yup.object().shape({
                    vigencia: yup.mixed().when("tipoDocumento.vigenciaRequerida", {
                        is: true,
                        then: yup.string().required(),
                    }),
                    archivo: yup.mixed().when("tipoDocumento.requerido", {
                        is: true,
                        then: yup.object().shape({
                            isEmpty: yup.boolean().required().oneOf([false])
                        })
                    })
                })
            ),
    expediente:
        yup.array()
            .of(
                yup.object().shape({
                    archivo: yup.mixed().when("tipoDocumento.requerido", {
                        is: true,
                        then: yup.object().shape({
                            isEmpty: yup.boolean().required().oneOf([false])
                        })
                    })
                })
            ),
    accesorios:
        yup.array()
            .of(
                yup.object().shape({
                    nombre: yup.string().required(),
                })
            ),
});

const unidadValidacionEsquema = {
    alta,
    guardar
};

export default unidadValidacionEsquema;
