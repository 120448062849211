import React, {useState} from "react";
import ButtonIcon from "./ButtonIcon";
import Camera, {FACING_MODES} from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import {isMobile} from 'react-device-detect';
import {useEmpresa} from "../../layout/EmpresaProvider";

const FieldCamera = ({name, value, onChange, error, errors, disabled , width}) => {
    const [visibleEliminar, setVisibleEliminar] = useState(false);
    value = value ?? {isEmpty: true};

    const {empresaId} = useEmpresa();

    function handleTakePhoto (dataUri) {
        const newValue = {
            file: dataUri ,
            url: dataUri,
            isEmpty: false,
            name: 'foto.jpg',
            isBase64:true
        };
        if (onChange != null) {
            onChange(newValue, name);
        }
    }

    function eliminarImagen() {
        onChange(null, name);
        setVisibleEliminar(false);
    }

    function obtenerSrc(){
        if (value.isBase64 === true ){
            return value.file ?? value.url;
        }
        return `${process.env.REACT_APP_API_URL}/archivos/imagen/${empresaId}?url=${value.url}`;
    }
    return (
        <>
            <div style = {{width:"auto"}} className={`camara ${(error || errors?.[name]) ? 'error' : ''}`}>
                { (value.isEmpty??true) &&
                    <Camera 
                        idealFacingMode = {isMobile ?  FACING_MODES.ENVIRONMENT : FACING_MODES.USER}
                        isImageMirror={false}
                        onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } } />
                }
                {!value.isEmpty &&
                <div className="image-container" onMouseOver={()=>setVisibleEliminar(true)} onMouseLeave={()=>setVisibleEliminar(false)} >
                    <img alt="Imagen"
                         src={obtenerSrc()}
                         width={width != null ? width :"100%" }
                    />
                    {visibleEliminar && disabled !== true && <ButtonIcon className="clear" iconName="RiDeleteBinLine" onClick={eliminarImagen}/> }
                </div>
                }
            </div>
        </>
    )
};

export default FieldCamera;