import React from "react";
import FieldAutoComplete from "../../../shared/controls/fieldAutoComplete";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import FieldCalendarRange from "../../../shared/controls/fieldCalendarRange";
import FieldMultiSelect from "../../../shared/controls/fieldMultiSelect";
import FieldDropDown from "../../../shared/controls/fieldDropDown";

const FiltroConvenios = ({ filtro, onChange }) => {
    return (
        <>
            <FieldTextBox
                name="numeroConvenio"
                label="No. Convenio"
                colMd={1}
                keyFilter={"int"}
                value={filtro.numeroConvenio}
                onChange={onChange}
            />
            <FieldAutoComplete
                name="cliente"
                source="clientes"
                label="Cliente"
                colMd={2}
                value={filtro.cliente}
                onChange={onChange}
            />

            <FieldDropDown
                name="subCliente"
                label="SubCliente"
                source="subClientes"
                params={{ clienteId: filtro.cliente?.id ?? 0 }}
                showClear={true}
                colMd={2}
                value={filtro.subCliente}
                onChange={onChange}
            />

            <div className="form-group col-md-2 col-sm-12">
                <label>Ruta</label>
                <div className="col-auto">
                    <FieldAutoComplete
                        name="rutaOrigen"
                        source="rutasOrigen"
                        colMd={1}
                        freeText={true}
                        placeholder="Desde"
                        value={filtro.rutaOrigen}
                        onChange={onChange}
                    />
                    <FieldAutoComplete
                        name="rutaDestino"
                        source="rutasDestino"
                        colMd={1}
                        freeText={true}
                        placeholder="Hasta"
                        value={filtro.rutaDestino}
                        onChange={onChange}
                    />
                </div>
            </div>
            <FieldDropDown
                name="tipoUnidad"
                source="tiposUnidad"
                label="Tipo de Unidad"
                colMd={1}
                value={filtro.tipoUnidad}
                onChange={onChange}
            />
            <FieldCalendarRange name="fechas" label="Fechas" colMd={2} value={filtro.fechas} onChange={onChange} />
            <FieldMultiSelect
                name="estatus"
                source="estatusConvenio"
                label="Estatus"
                colMd={2}
                value={filtro.estatus}
                onChange={onChange}
            />
            <FieldTextBox
                name="codigoRuta"
                label="Codigo de Ruta"
                colMd={2}
                value={filtro.codigoRuta}
                onChange={onChange} />
        </>
    );
};

export default FiltroConvenios;
