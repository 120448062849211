/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Field from "../../shared/forms/Field";
import FormMultiSelect from "../../shared/forms/FormFields/FormMultiSelect";
import FormTextBox from "../../shared/forms/FormFields/FormTextBox";
import Buttons from "../../shared/forms/FormFilter/Buttons";
import Filters from "../../shared/forms/FormFilter/Filters";
import FormFilter from "../../shared/forms/FormFilter/FormFilter";
import useForm from "../../shared/forms/useForm";
import constantes from "../constantes";
import useSeguridad from "../../Seguridad/useSeguridad";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import EquipoDetalle from "../Equipos/EquipoDetalle";
import { Sidebar } from "primereact/sidebar";
import useApiEquipos from "../../api/catalogos/useApiEquipos";
import agGridHelper from "../../shared/ag-grid-helper";
import constantesAdministracion from "../../administracion/constantes";
import EquiposAsignados from "./EquiposAsignados";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import ResponsivaPdfViewer from "./ResponsivaPdfViewer";
import SignaturePad from "react-signature-canvas";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import FieldTextArea from "../../shared/controls/fieldTextArea";
import FieldCheckBox from "../../shared/controls/fieldCheckBox";
const uri = process.env.REACT_APP_API_URL;
const filtroInicial = {
    nombre: "",
};
const Equipos = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.puestos);
    const { values: filter, onChange: onFilterChange, onClear } = useForm(filtroInicial);
    const equipos = [
        { id: 1558, nombre: "Laptop" },
        { id: 1555, nombre: "Computadora" },
        { id: 1556, nombre: "Celular" },
        { id: 1557, nombre: "Linea" },
    ];
    const [datos, setDatos] = useState([]);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const api = useApiEquipos();
    const [equiposVisible, setEquiposVisible] = useState(false);
    const [equipoId, setEquipo] = useState(false);
    const [equipoAsignar, setequipoAsignacion] = useState("");
    const [gridApi] = useState();
    const [urlPdf, setUrlPdf] = useState("");
    const [visible, setVisible] = useState(false);
    const firma = useRef(null);
    const [firmar, setFirmar] = useState(false);
    const [idAsignacion, setIdAsignacion] = useState(0);
    const [empleadoId, setEmpleadoId] = useState(0);
    const [firmado, setFirmado] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [gabinete , setGabinete ] = useState(false)
    const [mouse, setMouse] =useState(false)
    const [teclado, setTeclado] = useState(false)
    const [bateria, setBateria] = useState(false)
    const [comentarios, setComentarios] = useState('')
    const [cargador , setCargador ] = useState(false)
    const [equipoid, setEquiposId ] = useState(false)
    async function filtrar(filtro) {
        let datos = await api.filtrar(filtro);
        setDatos(datos);

    }
    const asignaciones = (id, equipo) => {
        setEquiposId(id)
        setEquiposVisible(true);
        setequipoAsignacion(equipo);
    };
    const verEquipo = (id) => {

        equipoid(id)
        setSidebarVisible(true);
        setEquipo(id);
    };

    async function onSave() {
        setSidebarVisible(false);
        await filtrar();
    }
    async function nuevo() {
        setSidebarVisible(true);
        setEquipo(0);
    }

    async function descargar(id, empleadoId, firmado) {
        if (firmado){            
            setVisible(true) //responsiva

        }else{ 
            setVisibleModal(true) //comentarios
            setGabinete(false)
            setMouse(false)
            setTeclado(false)
            setBateria(false)
            setComentarios('')
            setCargador(false)

        }

        setIdAsignacion(id);
        setEmpleadoId(empleadoId);
        setFirmado(firmado);
        setUrlPdf(`${uri}/catalogos/equipos/${id}/dowload/${empleadoId}`); //prod
        //setUrlPdf(`${uri}catalogos/equipos/${id}/dowload/${empleadoId}`);
    }
    async function asignarComentarios (){
        if (idAsignacion>0){
        await api.actualizarAsignacion(idAsignacion, {comentarios, teclado,bateria,cargador,mouse});
        setVisibleModal(false);
        setVisible(true)
        setGabinete(false)
        setMouse(false)
        setTeclado(false)
        setBateria(false)
        setComentarios('')
        setCargador(false)
       
        }
     }
    
    const renderFooter = ()  => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => setVisibleModal(false)} className="p-button-text"/>
                <Button label="Confirmar" icon="pi pi-check" onClick={asignarComentarios} />
            </div>
        );
    };
    const descargarPdf = async () => {
        await filtrar();
        await api.descarga(idAsignacion, empleadoId);
    };
    const EditarCellRenderer = ({ data }) => (
        <div className="d-flex align-items-center justify-content-center">
            <ButtonIcon
                title={"Editar"}
                iconName={"FaRegEdit"}
                className={"text-primary"}
                onClick={() => verEquipo(data.id)}
            />
        </div>
    );
    const AsignacionCellRenderer = ({ data }) => (
        <div className="d-flex align-items-center justify-content-center">
           <ButtonIcon
                title={"Asignar"}
                iconName={"FaRegUser"}
                className={"text-primary"}
                onClick={() => asignaciones(data.id, data)}
            />            
        </div>
    );
    const ResponsivaCellRenderer  = ({ data }) => (
      
        <div className="d-flex justify-content-end">                
            {data?.firmas?.map((item, index) => {
                return (
                    <React.Fragment key={index}>
                        {item?.firmado ? (
                            <ButtonIcon
                                title={`Reponsiva ${item.empleado}`}
                                iconName={"AiOutlineFileDone"}
                                className={"text-success"}
                                onClick={() => descargar(data.id, item.empleadoId, item.firmado)}
                            />
                        ) : (
                            <ButtonIcon
                                title={`Reponsiva ${item.empleado}`}
                                iconName={"AiOutlineFileDone"}
                                className={"text-secondary"}
                                onClick={() => descargar(data.id, item.empleadoId, item.firmado)}
                            />
                        )}
                    </React.Fragment>
                );
            })}
           
        </div>
    );

    async function buscar(filtro, paramsApi) {
        agGridHelper.showLoad(gridApi, paramsApi, true);
    }

    useEffect(() => {
        filtrar();
    }, []);

    const ButtonsHeader = () => {
        return (
            <div className={"d-flex justify-content-end header-icons"}>
                {!soloLectura && (
                    <ButtonIcon title="Agregar" className={"grid-action"} iconName={"MdAddBox"} onClick={nuevo} />
                )}
            </div>
        );
    };
    const ImageEstatusRenderer = (value) => {
        if (
            value !== undefined &&
            parseInt(value.estatusAsignacion) === constantesAdministracion.asignacionEquipo.asignado
        ) {
            return <img src="../../../../img/FV.png" alt="Imagen" width="15px" style={{ marginLeft: "2px" }} />;
        } else {
            return null;
        }
    };

    const footerResponsiva = (
        <div>
            {!firmado ? (
                <Button label="Firmar" icon="pi pi-check" onClick={() => setFirmar(true)} />
            ) : (
                <Button label="Descarga" icon="pi pi-check" onClick={descargarPdf} />
            )}
            <Button
                label="Cerrar"
                className="p-button-secondary"
                icon="pi pi-times"
                onClick={() => cerrar() }
            />
        </div>
    );

    const cerrar = async () => {
        await filtrar();
        setVisible(false)
    }  
    const enviarFirma = async () => {
        setVisible(false);

        //const firmaBase64 = await firma.current.canvasContainer.childNodes[1].toDataURL();
        const firmaBase64 = firma.current.getTrimmedCanvas().toDataURL("image/png");
        const firmaImagen = {
            file: firmaBase64,
            isBase64: true,
            url: firmaBase64,
            extension: "png",
        };

        const url = await api.guardarFirma(idAsignacion, empleadoId, firmaImagen);
        // setUrlPdf(`${uri}/catalogos/equipos/${idAsignacion}/dowload/${empleadoId}/${url}`); prod
        setUrlPdf(`${uri}catalogos/equipos/${idAsignacion}/dowload/${empleadoId}/${url}`);
        setFirmado(true);
        setFirmar(false);
        setVisible(true);
        await buscar();
    };

    const footerFirma = (
        <div>
            <Button label="Firmar" icon="pi pi-check" onClick={enviarFirma} />
            <Button label="Borrar Firma" onClick={() => firma.current.clear()} />
            <Button label="Cerrar" className="p-button-secondary" icon="pi pi-times" onClick={() => setFirmar(false)} />
        </div>
    );

    const refrescar = async () => {
        setEquiposVisible(false);
        await buscar();
    };


    return (
        <>
            <FormFilter
                columns={4}
                handleSubmit={buscar}
                filter={filter}
                onChange={onFilterChange}
                onClear={onClear}
                onFilter={(filtro) => filtrar(filtro)}
            >
                <Filters>
                    <Field label={"Nombre"} field={"nombre"} render={FormTextBox} />
                    <Field label={"Equipo"} field={"equipo"} options={equipos} render={FormMultiSelect} />
                    <Field label={"Número Serie"} field={"identificador"} render={FormTextBox} />
                </Filters>
                <Buttons filter={true} clear={true}></Buttons>
            </FormFilter>

            <Sidebar
                visible={sidebarVisible}
                position="right"
                dismissable={false}
                className="p-sidebar-xl"
                onHide={() => setSidebarVisible(false)}
            >
                <EquipoDetalle
                    solicitudId={equipoId}
                    
                    onSave={onSave}
                    close={() => setSidebarVisible(false)}
                    soloLectura={soloLectura}
                />
            </Sidebar>

           
            <div className="ag-theme-balham grid-principal">
            <AgGridReact
                    maxWidth={45}
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    rowHeight={35}  
                    frameworkComponents={
                        {
                            buttonsHeader: ButtonsHeader,
                            editarCellRenderer:EditarCellRenderer,
                            asignacionCellRenderer:AsignacionCellRenderer,
                            responsivaCellRenderer:ResponsivaCellRenderer,
                            imageEstatusRenderer: ImageEstatusRenderer,
                            dateFormatter: agGridHelper.DateTimeFormatter,
                        }
                    }
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                >
                    <AgGridColumn maxWidth={40} checkboxSelection={true}/>
                    <AgGridColumn maxWidth={100} headerName="" cellRenderer="imageEstatusRenderer" />
                    <AgGridColumn  flex={5} field="categoria" headerName="Categoria"/>
                    <AgGridColumn field="marcaEquipo" headerName="Marca/Modelo Equipo"/>
                    <AgGridColumn field="lineaAbc" headerName="Linea ABC"/>
                    <AgGridColumn field="empleado" headerName="Empleado"/>
                    <AgGridColumn field="nota" headerName="Notas"/>
                    <AgGridColumn field="fecha" headerName="Fecha" cellRenderer="dateFormatter"  />
                    <AgGridColumn field="identificador" headerName="IMEI / ICCID / Numero de Serie"/>
                    <AgGridColumn maxWidth={100} headerName="Responsiva a firmar" cellRenderer="responsivaCellRenderer" />
                    <AgGridColumn maxWidth={100} headerName="Asignación" cellRenderer="asignacionCellRenderer" />
                    <AgGridColumn maxWidth={100} headerName="Editar" cellRenderer="editarCellRenderer" />
                    <AgGridColumn maxWidth={80}
                        headerName="VER"
                        cellRenderer="rowButtons"
                        pinned={'right'}
                        cellClass={'left'}
                        headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>

            <EquiposAsignados
                equipo={equipoAsignar}
                equiposVisible={equiposVisible}
                setEquiposVisible={setEquiposVisible}
                name="equiposAsignados"
                onSave={refrescar}
            />
            <Dialog header="Se entrega : " visible={visibleModal} style={{width: '60vw'}} 
                    footer={renderFooter('displayBasic')} onHide={() => setVisibleModal(false)}>
                        <div className="form-container">
                            <div className="form-container">
                                <div className="row">
                                    <div className="col-md-2">
                                        <FieldCheckBox name="gabinete" label="Gabinete" className="py-1" value={gabinete} onChange={setGabinete}/>
                                    </div>
                                    <div className="col-md-2">
                                        <FieldCheckBox name="mouse" label="Mouse" className="py-1" value={mouse} onChange={setMouse}/>
                                    </div>
                                    <div className="col-md-2">
                                        <FieldCheckBox name="teclado" label="Teclado" className="py-1" value={teclado} onChange={setTeclado}/>
                                    </div>                                    
                                    <div className="col-md-2">
                                        <FieldCheckBox name="bateria" label="Batería" className="py-1" value={bateria} onChange={setBateria}/>
                                    </div>
                                    <div className="col-md-2">
                                        <FieldCheckBox name="cargador" label="Cargador" className="py-1"  value={cargador} onChange={setCargador}/>
                                    </div>
                                    <div className="col-md-12">
                                        <FieldTextArea name="comentarios" label="Comentarios" colMd={12} rows={8} value={comentarios} onChange={setComentarios}/>         
                                    </div>
                                </div>
                            </div>
                </div>
            </Dialog>

            <Dialog
                header="Responsiva"
                visible={visible}
                maximized={true}
                modal={true}
                onHide={() => setVisible(false)}
                footer={footerResponsiva}
            >
                <ResponsivaPdfViewer pdfUrl={urlPdf} />
            </Dialog>
            <Dialog header="Firmar Responsiva" visible={firmar} onHide={() => setFirmar(false)} footer={footerFirma}>
                <div className="form-row">
                <SignaturePad ref={firma} backgroundColor="#FFF"  canvasProps={{ className: "signatureCanvas" }} />
                    {/* <CanvasDraw ref={firma} brushRadius={1} hideGrid={false} backgroundColor="rgba(255, 255, 255, 0)"
                        canvasHeight={200} hideInterface={true} imgSrc="../../img/firma.png" /> */}
                </div>
            </Dialog>
        </>
    );
};
export default Equipos;
