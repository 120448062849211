import useServiceFactory from "../serviceFactory";
const apiUrl = `/catalogos/estaciones`;
const useApiEstaciones = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, { params }),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, { showLoading: true }),
        guardar: async (params) => service.post(`${apiUrl}`, params),
        viajes: async (params) => service.get(`${apiUrl}/viajes`, {...params, showLoading: true }),
        asignarEstacion: async (estacionId, viajeId) => service.post(`${apiUrl}/${estacionId}/viajes/${viajeId}`, {}, {showLoading:false}),
        actualizar: async (id, params) => service.put(`${apiUrl}/${id}`, params),
        excel: async (params) =>
            service.get(`${apiUrl}/excel`, {
                ...params,
                showLoading: true,
                download: true,
                fileName: `Estaciones.xlsx`,
            }),
    };
};

export default useApiEstaciones;
