import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {useReducer} from 'react';
import useApiLiberacionViaje from "../../api/administracion/useApiLiberacionViaje";
import FieldDropDown from '../../shared/controls/fieldDropDown';
import FieldTextArea from "../../shared/controls/fieldTextArea";
import formReducer from '../../shared/forms/formReducer';
import FormHandler from "../../shared/forms/formHandler";
import {agregarRechazo} from "./rechazoValidacionEsquema";

const RechazarDocumento = ({visibleRechazoModal, setVisibleRechazoModal, idDoc, viajeId, onSave}) => {
    const api = useApiLiberacionViaje();
    const defaultEntity = () => ({
        entity: {
            motivoRechazo: null,
            comentarios: null
        }
    });

    const [{entity, errors}, dispatch] = useReducer(formReducer, {}, defaultEntity);

    const formHandler = new FormHandler({
        validationSchema: agregarRechazo,
        api,
        dispatch
    });

    const actualizar = (value, propertyName) => {
        dispatch({type: 'update', value: value, name: propertyName});
    };

    const guardar = async () => {
        const seguir = await formHandler.validate(entity);
        if (seguir.result) {
            await api.rechazarComentarios(viajeId, idDoc, entity);

            dispatch({
                type: "update",
                value: {...defaultEntity}
            });
            setVisibleRechazoModal(false);
            onSave();
        }
    };

    const close = () => {
        dispatch({
            type: "update",
            value: {...defaultEntity}
        });
        setVisibleRechazoModal(false);
    }

    const renderFooterModal = () => {
        return (
            <div>
                <Button label="Cancelar" onClick={close} className="p-button-outlined"/>
                <Button label="Confirmar" onClick={guardar} autoFocus/>
            </div>
        );
    }

    return (
        <Dialog header="Rechazo de Documento" visible={visibleRechazoModal} style={{width: '35vw'}}
                footer={renderFooterModal()} onHide={close}>
            <div className="form-row">
                <FieldDropDown name="MotivoRechazo" source="MotivoDocumentosRechazo" value={entity.MotivoRechazo}
                               label="Motivo Rechazo"
                               params={{id: viajeId}} required colMd={12} error={errors?.MotivoRechazo}
                               onChange={actualizar}/>
                {entity.MotivoRechazo?.id === 1552 &&
                    <FieldTextArea name="Comentarios" label="Comentario" value={entity.comentario} colMd={12} rows={3}
                                   error={errors?.comentario} onChange={actualizar}/>
                }
            </div>
        </Dialog>
    )
};


export default RechazarDocumento;
