/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import moment from "moment";
import * as numeral from "numeral";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import produce from "immer";

const Descuentos = ({liquidacion, descuentos, onChange, esOperador, soloConsulta}) => {

    const actualizar = (value, propertyName, index) => {
        const newValue = produce(descuentos, (draft) => {
            draft[index][propertyName] = value;
        });

        onChange(newValue,'descuentos');
    };

    return (
        <>
            <div className="form-row">
                <table className="table table-borderless">
                    <thead className="thead-light">
                    <tr>
                    {!soloConsulta && esOperador && liquidacion.aplazarMovimientos && <th/>}
                        <th style={{width: '10%'}}></th>
                        <th style={{width: '25%'}}>CONCEPTO</th>
                        <th style={{width: '25%'}}>NOTA</th>
                        <th style={{width: '35%'}}>DETALLE</th>
                        <th style={{width: '10%'}}>MONTO</th>
                    </tr>
                    </thead>
                    <tbody>
                    {liquidacion.descuentos?.map((item, index) => {
                        return (
                            <tr key={index}>
                                {!soloConsulta && liquidacion.aplazarMovimientos &&
                                <td>
                                    <FieldCheckBox name="seleccionado" value={item.seleccionado}
                                                   onChange={(value, pname) => actualizar(value, pname, index)}
                                    />
                                </td>                            
                                }                                
                                <td>{item.concepto}</td>
                                <td>{item.nota}</td>
                                <td className="p-d-flex p-flex-column">
                                <span>Fecha de Solicitud: {moment(item.fecha).format('DD/MM/YYYY hh:mm a')}</span>
                                <span>Solicitante: {item.solicitante}</span>                                
                                    <div>
                                        # Exhibiciones: <span className="bold">{item.numeroDeParcialidades}</span> |
                                        # Pago: <span className="bold">{item.parcialidad}/{item.numeroDeParcialidades}</span>
                                    </div>                                   
                                </td>
                                <td>{numeral(item.monto).format('$0,0.00')}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </>
    )
};


export default Descuentos;
