import React, { useState} from "react";
import produce from "immer";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import FieldCheckBox from "../../shared/controls/fieldCheckBox";

const SubClientes = ({ entity, soloLectura, onChange, errors }) => {
    const [subcliente, setSubCliente] = useState(entity);

    const [, setIsDirty] = useState(false);
    const agregarConfiguracion = () => {
        const newList = produce(subcliente?.subClientes, (draft) => {
            draft.push({ activo: true });
        });

        onChange(newList, "subClientes");
    };

    const actualizar = (value, propertyName, index) => {
        const newList = produce(subcliente?.subClientes, (draft) => {
            draft[index][propertyName] = value;
        });

        onChange(newList, "subClientes");
    
    };
    const onUpdateFreightVerify = (checked,propertyName, index) => {
        const updatedSubClientes = [...subcliente.subClientes];
        updatedSubClientes[index] = {
            ...updatedSubClientes[index],
            aplicaFrightVerify: checked,
            aplicaQS3: checked ? false : updatedSubClientes[index].aplicaQS3
        };      
        setSubCliente({...subcliente, subClientes: updatedSubClientes});
        setIsDirty(true);   
        onChange(updatedSubClientes, "subClientes");
    }
    
    const onUpdateQS3 = (checked, propertyName,index) => {
        const updatedSubClientes = [...subcliente.subClientes];
        updatedSubClientes[index] = {
            ...updatedSubClientes[index],
            aplicaQS3: checked,
            aplicaFrightVerify: checked ? false : updatedSubClientes[index].aplicaFrightVerify
        };
        setSubCliente({...subcliente, subClientes: updatedSubClientes});
        setIsDirty(true);       
        onChange(updatedSubClientes, "subClientes");
    }
    
    

    return (
        <div className="form-row">
            <h5>SubClientes</h5>
            <table className="table table-borderless">
                <thead className="thead-light">
                    <tr>
                        <th>NOMBRE</th>
                        <th>ACTIVO</th>
                        <th>FV</th>
                        <th>QS3</th>
                        <th className={"ag-grid header-icons"} style={{ with: "10%" }}>
                            {!soloLectura && (
                                <ButtonIcon
                                    title={"Agregar Configuración"}
                                    iconName={"SiAddthis"}
                                    className="grid-action"
                                    onClick={agregarConfiguracion}
                                />
                            )}
                        </th>
                       
                    </tr>
                </thead>
                <tbody>
                    {subcliente?.subClientes?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="input">
                                    <FieldTextBox
                                        name={"nombreSubCliente"}
                                        value={item.nombreSubCliente}
                                        colMd={6}
                                        placeholder={"Nombre"}
                                        maxLength={500}
                                        disabled={soloLectura}
                                        errors={
                                            errors != null && errors[`subClientes[${index}]`] != null
                                                ? errors[`subClientes[${index}]`]
                                                : ""
                                        }
                                        onChange={(value, propertyName) => actualizar(value, propertyName, index)}
                                        className={"mt-2"}
                                    />
                                </td>
                                <td>
                                    {!soloLectura && (
                                        <FieldCheckBox
                                            name="activo"
                                            colLg={2}
                                            colMd={3}
                                            value={item.activo}
                                            onChange={(value, propertyName) => actualizar(value, propertyName, index)}
                                        />
                                    )}
                                </td>
                                <td>
                                    {!soloLectura && (
                                        <FieldCheckBox
                                            name="aplicaFrightVerify"
                                            colLg={2}
                                            colMd={3}
                                            value={item.aplicaFrightVerify}
                                            onChange={(value, propertyName) => onUpdateFreightVerify(value,propertyName, index)}
                                        />
                                    )}
                                </td>
                                <td>
                                    {!soloLectura && (
                                        <FieldCheckBox
                                            name="aplicaQS3"
                                            colLg={2}
                                            colMd={3}
                                            value={item.aplicaQS3}
                                            onChange={(value, propertyName) => onUpdateQS3(value,propertyName,index)}
                                        />
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default SubClientes;
