import ExGrid from "../../../shared/Grid/ExGrid";
import ExColumn from "../../../shared/Grid/ExColumn";

import moment from "moment";

const LogOrdenTrabajo = ({data}) => {
    return (<>
        <ExGrid data={data} style={{maxHeight:'180px'}}>
            <ExColumn field={'FechaCancelacion'} title={'Fecha'} cellRenderer={({value})=> moment(value).format("YYYY-MM-DD hh:mm A")}/>
            <ExColumn field={'motivo'} title={'Motivo'}/>
            <ExColumn field={'comentarios'} title={'Comentarios'}/>
            <ExColumn field={'usuario'} title={'Usuario'}/>
        </ExGrid>
    </>)
}
export default LogOrdenTrabajo;