/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import agGridHelper from "../../../shared/ag-grid-helper";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import useForm from "../../../shared/forms/useForm";
import moment from "moment";
import FormFilter from "../../../shared/forms/FormFilter/FormFilter";
import Filters from "../../../shared/forms/FormFilter/Filters";
import Field from "../../../shared/forms/Field";
import FormCalendarRange from "../../../shared/forms/FormFields/FormCalendarRange";
import FormMultiSelect from "../../../shared/forms/FormFields/FormMultiSelect";
import Buttons from "../../../shared/forms/FormFilter/Buttons";
import DetalleRendimientosDiesel from "./detalleRendimientosDiesel";
import useApiDieselRendimiento from "../../../api/taller/useApiDieselRendimiento";
import FormTextBox from "../../../shared/forms/FormFields/FormTextBox";
import {
    AgCurrencyFormatter,
    AgDateTimeFormatter,
    AgNumberFormatter,
    AgHourFormatter,
} from "../../../shared/ag-grid-helper/GridFormatters";
import { VerIconRenderer } from "../../../shared/ag-grid-helper/GridRenderers";
import { v4 as uuidv4 } from "uuid";

const filtroInicial = {
    fecha: [moment().startOf("month")._d, moment().startOf("day")._d],
};

const RendimientosDiesel = ({ soloLectura }) => {
    const api = useApiDieselRendimiento();
    const [datos, setDatos] = useState([]);
    const [rendimientoId, setRendimientoId] = useState(null);
    const { values: filter, onChange: onFilterChange, onClear } = useForm(filtroInicial);

    const [exportGuid, setExportGuid] = useState(null);

    useEffect(() => {
        if (exportGuid != null) api.excel(filter).then();
    }, [exportGuid]);

    const filtrar = async (filtro) => {
        setDatos([]);
        const datos = await api.filtrar(filtro);
        setDatos(datos);
    };

    const onExport = async () => {
        setExportGuid(uuidv4());
    };

    const ButtonsHeader = () => {
        return (
            <div className={"ag-grid header-icons"}>
                <ButtonIcon title={"Descargar"} iconName={"BiDownload"} onClick={onExport} />
                {!soloLectura && (
                    <ButtonIcon
                        title="Agregar"
                        className={"grid-action"}
                        iconName={"MdAddBox"}
                        onClick={() => setRendimientoId(0)}
                    />
                )}
            </div>
        );
    };

    return (
        <>
            <FormFilter
                columns={6}
                gridStyle={{ paddingTop: "0", marginTop: "0" }}
                filter={filter}
                onChange={onFilterChange}
                onClear={onClear}
                onFilter={(filtro) => filtrar(filtro, null)}
            >
                <Filters>
                    <Field field="fecha" label="Fechas" render={FormCalendarRange} />
                    <Field
                        field="unidadIds"
                        label="Numero económico"
                        source="diesel/unidadesMotriz"
                        render={FormMultiSelect}
                    />
                    <Field field="operadorIds" label="Operador" source="diesel/operadores" render={FormMultiSelect} />
                    <Field field="estatusIds" label="Estatus" source="diesel/estatus" render={FormMultiSelect} />
                    <Field field="folio" label="Folio" render={FormTextBox} />
                </Filters>
                <Buttons filter={true} clear={true} style={{ top: "-50px" }}></Buttons>
            </FormFilter>
            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    frameworkComponents={{
                        buttonsHeader: ButtonsHeader,
                        verIconRenderer: VerIconRenderer,
                    }}
                    onGridReady={() => filtrar(filter).then()}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    context={{
                        onEdit: (id) => setRendimientoId(id),
                    }}
                >
                    <AgGridColumn field="folio" headerName="FOLIO" cellClass={"center"} />
                    <AgGridColumn field="operador" headerName="OPERADOR" minWidth={200} />
                    <AgGridColumn field="numeroEconomico" headerName="NO. ECONOMICO" maxWidht={50} />
                    <AgGridColumn field="tipoUnidad" headerName="TIPO DE UNIDAD" />
                    <AgGridColumn
                        field="fechaRegistroLocal"
                        headerName="FECHA REGISTRO"
                        valueFormatter={AgDateTimeFormatter}
                        minWidth={150}
                    />
                    <AgGridColumn field="odometro" headerName="ODOMETRO" valueFormatter={AgNumberFormatter} />
                    <AgGridColumn
                        field="kmsRecorridos"
                        headerName="KMS RECORRIDOS"
                        valueFormatter={AgNumberFormatter}
                    />
                    <AgGridColumn field="litrosCarga" headerName="LITROS CARGA" valueFormatter={AgNumberFormatter} />
                    <AgGridColumn
                        field="precioPorLitro"
                        headerName="COSTO"
                        valueFormatter={AgCurrencyFormatter}
                        maxWidht={50}
                    />
                    <AgGridColumn
                        field="rendimientoEscaner"
                        headerName="RENDIMIENTO ESCANER"
                        valueFormatter={AgNumberFormatter}
                        maxWidht={50}
                    />
                    <AgGridColumn
                        field="rendimientoCalculado"
                        headerName="RENDIMIENTO CALCULADO"
                        valueFormatter={AgNumberFormatter}
                    />
                    <AgGridColumn
                        field="diferencialLitros"
                        headerName="DIFERENCIAL (LTS)"
                        valueFormatter={AgNumberFormatter}
                    />
                    <AgGridColumn field="descuento" headerName="Descuento" valueFormatter={AgCurrencyFormatter} />
                    <AgGridColumn field="controlador" headerName="CONTROLADOR" />
                    <AgGridColumn
                        field="tiempoViaje"
                        headerName="Tiempo de viaje"
                        title="Tiempo de viaje"
                        valueFormatter={AgHourFormatter}
                    />
                    <AgGridColumn
                        field="tiempoMarchaLenta"
                        headerName="Tiempo de marcha lenta"
                        title="Tiempo de marcha lenta"
                        valueFormatter={AgHourFormatter}
                    />
                    <AgGridColumn
                        field="velocidadMaxima"
                        headerName="Velocidad maxima"
                        title="Velocidad maxima"
                        valueFormatter={AgNumberFormatter}
                    />
                    <AgGridColumn
                        field="velocidadMaxima1"
                        headerName="Velocidad maxima -1"
                        title="Velocidad maxima -1"
                        valueFormatter={AgNumberFormatter}
                    />
                    <AgGridColumn
                        field="porcentajeRelenti"
                        headerName="Porcentaje de relenti"
                        title="Porcentaje de relenti"
                        valueFormatter={AgNumberFormatter}
                    />
                    <AgGridColumn
                        field="litrosBaja"
                        headerName="Litros en baja"
                        title="Litros en baja"
                        valueFormatter={AgNumberFormatter}
                    />
                    <AgGridColumn field="estatus" headerName="ESTATUS" />
                    <AgGridColumn
                        maxWidth={100}
                        headerName="VER"
                        cellRenderer="verIconRenderer"
                        pinned={"right"}
                        cellClass={"left"}
                        headerComponent="buttonsHeader"
                    />
                </AgGridReact>
            </div>
            <DetalleRendimientosDiesel
                rendimientoId={rendimientoId}
                onSaved={() => {
                    filtrar(filter).then();
                    setRendimientoId(null);
                }}
                onCancel={() => setRendimientoId(null)}
            />
        </>
    );
};

export default RendimientosDiesel;
