/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import useApiNotificationes from "../../api/shared/useApiNotificationes";
import {Sidebar} from "primereact/sidebar";
import {Badge} from "primereact/badge";
import {SignalRContext} from "../../App";
import {enqueueSnackbar} from "notistack";
import constantes from "../../catalogos/constantes";
import DetalleSeguimiento from "../../operaciones/viajes/seguimientoMonitoreo/seguimiento/seguimientoDetalle";
import AtenderNotification from "./AtenderNotification";
import _ from "lodash";
import DetalleLiberacionViaje from "../../administracion/liberacion/liberacionViajeDetalle";

const UserNotifications = () => {
    const [notificaciones, setNotificaciones] = useState([]);
    const [open, setOpen] = useState(false);
    const api = useApiNotificationes();
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [viajeId, setViajeId] = useState();
    const [contador, setContador] = useState(1);
    const [timer, setTimer] = useState(null);
    const [atenderVisible, setDialogAtenderVisible] = useState(false);
    const [titulo, setTitulo] = useState("");
    const [notificacionAlerta, setNotificacionAlerta] = useState(null);
    const [verLiberacion, setVerLiberacion] = useState(false);

    const load = async () => {
        const notificaciones = await api.get(false);
        setNotificaciones(notificaciones);
    };

    useEffect(() => {
        load().then();
    }, []);

    SignalRContext.useSignalREffect(
        "multiplesNotificaciones", // Your Event Key
        (listaNotificacion) => {
            multiplesNotificaciones(listaNotificacion);
        }
    );

    const multiplesNotificaciones = (listaNotificacion) => {
        var notificacionesAborrar = listaNotificacion.filter(
            (e) => e.estatusId === constantes.estatusNotificaciones.borrada
        );
        var notificacionesActualizadas = notificaciones.filter(
            (e) => !notificacionesAborrar.some((k) => k.id === e.id)
        );

        var notificacionesNuevas = listaNotificacion.filter(
            (e) => e.estatusId !== constantes.estatusNotificaciones.borrada
        );

        for (let i = 0; i < notificacionesNuevas.length; i++) {
            notificacionesActualizadas.push(notificacionesNuevas[i]);
            mostrarSnackBar(notificacionesNuevas[i]);
        }

        const notificacionesOrdenadas = _.orderBy(
            notificacionesActualizadas.map((e) => {
                return {...e};
            }),
            ["fecha"],
            ["desc"]
        );

        setNotificaciones(notificacionesOrdenadas);
    };

    const mostrarSnackBar = (nuevaNotificacion) => {
        if (timer != null) {
            clearTimeout(timer);
        }

        const newTimer = setTimeout(() => {
            const mensaje = contador > 1 ? `Tiene ${contador} nuevas notificaciones` : nuevaNotificacion.titulo;

            if (document.hasFocus()) {
                // Comprueba si el documento  tiene el enfoque
                enqueueSnackbar(mensaje, {
                    variant: "info",
                    anchorOrigin: {horizontal: "right", vertical: "top"},
                });
            }
            setContador(1);
        }, 3000);

        setContador(contador + 1);
        setTimer(newTimer);
    };

    SignalRContext.useSignalREffect(
        "test", // Your Event Key
        (notificacion) => {
            alert(notificacion);
        }
    );

    const onEdit = (viajeId) => {
        setViajeId(viajeId);
        setSidebarVisible(true);
    };
    const onSave = () => {
        setSidebarVisible(false);
    };

    const leerNotificaciones = async () => {
        setOpen(true);
        await api.leidas(notificaciones.map((e) => e.id));
    };

    const onEditViaje = (notificacion) => {
        onEdit(notificacion?.entidadId);
    };

    const onLeida = async (notificacion) => {
        var tipo = mostrarAccion(notificacion);

        if (tipo === "Atender") {
            setTitulo(notificacion.titulo);
            setDialogAtenderVisible(true);
            setNotificacionAlerta(notificacion);
        } else {
            await api.borrar([notificacion.id]);
        }
    };

    var offsetMinutos = new Date().getTimezoneOffset();
    var offsetMilisegundos = offsetMinutos * 60 * 1000;

    const mostrarFechaNavegador = (fechaUtcString) => {
        // Crea un objeto Date usando la fecha UTC

        let fechaUtc = new Date(Date.parse(fechaUtcString));

        var fechaLocal = fechaUtc;
        if (!fechaUtcString.endsWith("Z")) {
            // Aplica el offset para convertir la fecha y hora a la zona horaria local del navegador
            fechaLocal = new Date(fechaUtc.getTime() - offsetMilisegundos);
        }

        var opcionesFormato = {
            day: "2-digit",
            month: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
        };

        // Convierte la fecha a una cadena formateada
        var fechaFormateada = fechaLocal.toLocaleString(undefined, opcionesFormato).replace(/[/]/g, "-");

        return fechaFormateada;
    };

    const onEditRelease = (notificacion) => {
        setViajeId(notificacion?.entidadId);
        setVerLiberacion(true);
    }

    const mostrarAccion = (notificacion) => {
        if (notificacion.modulo === constantes.modulosAlertas.viaje &&
            (notificacion.tipoNotificacion === constantes.tiposNotificacion.excesoVelocidad ||
                notificacion.tipoNotificacion === constantes.tiposNotificacion.DesconexionGps ||
                notificacion.tipoNotificacion === constantes.tiposNotificacion.PerdidaGps ||
                notificacion.tipoNotificacion === constantes.tiposNotificacion.horasDescansoBajo ||
                notificacion.tipoNotificacion === constantes.tiposNotificacion.horasDescansoMedio ||
                notificacion.tipoNotificacion === constantes.tiposNotificacion.horasDescansoAlto)) {
            return "Atender";
        } else return "Posponer";
    };

    return (
        <>
            <i
                className="pi pi-bell p-mr-4 p-text-secondary p-overlay-badge notificacion"
                style={{fontSize: "1.3rem"}}
                onClick={leerNotificaciones}
            >
                <Badge value={notificaciones.length}></Badge>
            </i>

            <Sidebar
                visible={open}
                position="right"
                className="p-sidebar-sm notificaciones"
                onHide={() => setOpen(false)}
            >
                <span className={"notificaciones-header"}>{notificaciones.length} Pendientes</span>
                {notificaciones.map((notificacion) => (
                    <div key={notificacion.id} className={"user-notification"}>
                        <div className={"notification-header"}>
                            {notificacion.titulo} ({notificacion.empresa})&nbsp;
                            <label style={{fontSize: "10px"}}> {mostrarFechaNavegador(notificacion.fecha)}</label>
                        </div>
                        <div className={"notificacion-mensaje"}>{notificacion.mensaje}</div>
                        <div className={"ver-mas"}>
                            <label onClick={() => onLeida(notificacion)}>{mostrarAccion(notificacion)}</label>
                            {(notificacion.tipoNotificacion === constantes.tiposNotificacion.inicioViaje ||
                                notificacion.tipoNotificacion === constantes.tiposNotificacion.finalizarViaje ||
                                notificacion.tipoNotificacion === constantes.tiposNotificacion.proximoinicioViaje) && (
                                <label onClick={() => onEditViaje(notificacion)}>Ver mas</label>
                            )}
                            {notificacion.tipoNotificacion === constantes.tiposNotificacion.actualizacionDocumentosViaje &&
                                <label onClick={() => onEditRelease(notificacion)}>Ver mas</label>
                            }
                        </div>
                    </div>
                ))}
            </Sidebar>

            <Sidebar
                visible={sidebarVisible}
                position="left"
                dismissable={false}
                className="p-sidebar-lg"
                onHide={() => setSidebarVisible(false)}
            >
                <DetalleSeguimiento
                    viajeId={viajeId}
                    onSave={onSave}
                    close={() => setSidebarVisible(false)}
                    soloLectura={false}
                />
            </Sidebar>
            <AtenderNotification
                visible={atenderVisible}
                setDialogVisible={setDialogAtenderVisible}
                titulo={titulo}
                notificacionAlerta={notificacionAlerta}>
            </AtenderNotification>
            <Sidebar visible={verLiberacion}
                     position={'left'}
                     dismissable={false}
                     className="p-sidebar-lg"
                     onHide={() => setVerLiberacion(false)}>
                <DetalleLiberacionViaje viajeId={viajeId}
                                        onSave={() => setVerLiberacion(false)}
                                        close={() => setVerLiberacion(false)}
                                        soloLectura={false}
                                        actualizarViajes={() => setVerLiberacion(true)}
                />
            </Sidebar>
        </>
    );
};

export default UserNotifications;
