import React, {useState} from "react";
import ButtonIcon from "../controls/ButtonIcon";
import FilesViewer from "../componentes/filesViewer";

const FileViewerFormatter = ({value, colDef, ...props}) => {
    const [open, setOpen] = useState(false);
    const values = Array.isArray(value) ? value : [value];
        return (
            <>
                {value != null && values.length > 0 ?
                    <span style={{fontSize: '1.5rem', cursor: 'pointer'}}>
                    <ButtonIcon iconName="AiOutlineFile" onClick={()=>setOpen(true)}/>
                </span> :
                    ''
                }
                {open &&
                   <FilesViewer
                        open={open}
                        onHide={()=>setOpen(false)}
                        title={colDef.title}
                        urls={values}
                   />
                }
            </>
        )
    }
;

export default FileViewerFormatter;
