
/* eslint-disable react-hooks/exhaustive-deps */
import React,{useEffect,useReducer} from "react";
import formReducer from "../../shared/forms/formReducer";
import FieldInputNumeroCurp from "../../shared/controls/customInputs/fieldInputCurp";
import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import FieldInputNumeroPhone from "../../shared/controls/customInputs/fieldInputPhone";
import useApiAgenda from "../../api/catalogos/useApiAgenda";
import useSeguridad from "../../Seguridad/useSeguridad";
import constantes from "../constantes";
import FieldInputNumeroRfc from "../../shared/controls/customInputs/fieldInputRfc";
import Operador from "../Agenda/operador"
import FieldDropDown from "../../shared/controls/fieldDropDown";
import FieldInputNumeroClabe from "../../shared/controls/customInputs/fieldInputNumeroClabe";
import FieldInputNumeroTarjeta from "../../shared/controls/customInputs/fieldInputNumeroTarjeta";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import FieldInputNss from "../../shared/controls/customInputs/fieldInputNss";
import FieldCalendar from "../../shared/controls/fieldCalendar";
import produce from "immer";
const AgendaDetalle = ({ empleadoid, onSave,name, close }) => {
const seguridad = useSeguridad();
const permisoGuardarAgenda = seguridad.tieneAcceso(constantes.opciones.permisoGuardarAgenda);
const api = useApiAgenda();

const defaultEmpleado = () => (
    {
        entity: {
            id:0,
            datosGeneralesDto:  { 
                telefonoPersonal:'',
                telefonoABC:''
            },
            licencias: [],
          
        }
    }
);


const [{ entity, errors }, dispatch] = useReducer(formReducer, {}, defaultEmpleado);
useEffect(() => {
    let timer1 = setTimeout(loadInfo, 100);
    return () => {
        clearTimeout(timer1)
    }
}, [empleadoid]);

useEffect(() => {
}, [entity]);
const loadInfo = async () => {   
    let empleado = { entity: await api.obtener(empleadoid) };
    dispatch({ type: 'reset', getDefault: () => empleado });   
};
function actualizar(value, propertyName) {   
    const nuevo = produce(entity?.datosGenerales ?? {}, draft => {
        draft[propertyName] = value
    });
    onChange(nuevo, name);
}
const onChange = (value, name) => {        
    dispatch({ type: 'update', value, name });
};

const guardarLineas = async () => {   
        await api.guardar(entity);
        close(false)
        onSave();
        
};

return (
    <>
            <TabView activeIndex={0}>
                    <TabPanel header="Información General">
                    <div className="form-row">               
                           
                            <h6 className="col-8"><b>Nombre: </b> {entity?.datosGenerales?.nombre + " " + entity?.datosGenerales?.apellidoPaterno + " " + entity?.datosGenerales?.apellidoMaterno}</h6>
                            
                            <FieldInputNumeroCurp required name="curp" label="CURP" colMd={6} disabled
                                value={entity.datosGenerales?.curp} onChange={actualizar} />


                            <FieldInputNumeroRfc required name="rfc" label="RFC" colMd={6} disabled
                                value={entity.datosGenerales?.rfc} onChange={actualizar} />
                            <FieldCalendar name="fechaIngreso"
                            label="Fecha de Ingreso"
                            className="small"
                            value={entity?.datosGenerales?.fechaIngreso}
                            showButtonBar={false}
                            colMd={6}     
                            disabled                 
                            onChange={actualizar} />
                             {(permisoGuardarAgenda) &&
                                     <FieldInputNumeroPhone required name="telefonoPersonal" label="Telefono Personal" colMd={12}
                                     value={entity.datosGenerales?.telefonoPersonal} onChange={actualizar} />
          
                            }    
                            <FieldInputNumeroPhone name="telefonoABC" label="Telefono ABC" colMd={12}
                                value={entity.datosGenerales?.telefonoABC} onChange={actualizar} />
                            
                            <h5 className="col-12">IMMS</h5>

                            <FieldInputNss required name="nss" disabled label="NSS" colMd={6} errors={errors}
                               value={ entity.seguridadSocial?.nss }  onChange={actualizar}/> 

                            <FieldTextBox name="unidadMedica" disabled label="Unidad Médica" colMd={6} errors={errors}
                              value={  entity.seguridadSocial?.unidadMedica }  onChange={actualizar}/>


                            <h5 className="col-12">Datos Bancarios</h5>
                                <FieldDropDown required name="banco" label="Banco" colMd={6} source="bancos" disabled filter errors={errors}
                                            value={ entity?.nomina?.banco } onChange={actualizar}/>
                                                        
                                <FieldInputNumeroTarjeta  name="numeroTarjeta" label="No. Tarjeta" colMd={6} errors={errors} disabled={true}
                                            value={  entity?.nomina?.numeroTarjeta }  onChange={actualizar}/>
                                                                        
                                <FieldTextBox  name="numeroCuenta" label="No. Cuenta" colMd={6} errors={errors} disabled keyFilter={'int'}
                                            value={  entity?.nomina?.numeroCuenta }  onChange={actualizar}/>
                            
                                <FieldInputNumeroClabe required name="clabe" label="CLABE" colMd={6} errors={errors} disabled={true}
                                            value={  entity?.nomina?.clabe }  onChange={actualizar}/> 
                               <h5 className="col-12">Otra Información Bancaria</h5>
                                <FieldDropDown required name="bancoIdSeg" label="Banco" colMd={6} source="bancos"  disabled filter errors={errors}
                               value={ entity?.nomina?.bancoIdSeg } onChange={actualizar}/>                                                        
                                <FieldInputNumeroTarjeta  name="numeroTarjetaSeg" label="No. Tarjeta" colMd={6} errors={errors} disabled={true}
                                            value={  entity?.nomina?.numeroTarjetaSeg }  onChange={actualizar}/>
                                                                        
                                <FieldTextBox  name="numeroCuentaSeg" label="No. Cuenta" colMd={6} errors={errors} disabled keyFilter={'int'}
                                            value={ entity?.nomina?.numeroCuentaSeg }  onChange={actualizar}/>
                            
                                <FieldInputNumeroClabe required name="clabeSeg" label="CLABE" colMd={6} errors={errors} disabled={true}
                                            value={  entity?.nomina?.clabeSeg }  onChange={actualizar}/> 

                                    <div className="form-footer">
                                        {(permisoGuardarAgenda) &&
                                            <Button label="Guardar" className="p-button-outlined" type="button"
                                                onClick={guardarLineas} />     
                                        }       
                                     </div>

                        </div>
                          
                    </TabPanel>
                    {entity?.asignacion?.tipoEmpleado?.esOperador &&
                        <TabPanel header="Operador">                          
                            <Operador empleado={entity}                              
                                    archivosAdicionales={entity?.archivosAdicionales}
                                    documentosGenerales={entity?.documentosGenerales}
                                    documentosOperador={entity?.documentosOperador} />                           
                        </TabPanel>
                    }  
            </TabView>
            
        </>
    )
};


export default AgendaDetalle;
