import React, {useState} from "react";
import {Dialog} from "primereact/dialog";
import {useEmpresa} from "../../../layout/EmpresaProvider";


const MarkerTemplate = (props) => {
    const [visible, setVisible] = useState(false);
    const {empresaId} = useEmpresa();

    function obtenerSrc(){
        const foto = props.entity.foto;
        if (foto == null){
            return '';
        }

        if (foto.isBase64 === true ){
            return foto.file;
        }
        return `${process.env.REACT_APP_API_URL}/archivos/imagen/${empresaId}?url=${foto.url}`;
    }


    return (
        <>
            <span onClick={()=>setVisible(true)} style={{cursor:'pointer'}}>
                {props.entity.consecutivo}
            </span>

            <Dialog visible={visible} style={{width: '98vw'}}
                    onHide={() => setVisible(false)}>
                <div className="thumbnail-modal">
                    <div className="margenes"/>
                    <img alt="img" src={obtenerSrc()} width={'70%'} onClick={() => setVisible(true)}/>
                    <div className="margenes"/>
                </div>
            </Dialog>
        </>
    );
};

export default MarkerTemplate;