/* eslint-disable */
import React, {useState} from "react";
import moment from "moment";
import Calendario from "../../../shared/componentes/calendario";
import IconLibrary from "../../../shared/iconLibrary";
import constantesOperacion from "../../constantes";
import constantes from "../../../catalogos/constantes";
import * as numeral from "numeral";

const estatus = constantesOperacion.estatusViajes;

const listaEstatus = [
    {id: estatus.terminado, nombre: "Terminado", estilo: "status-terminado"},
    {id: estatus.enRuta, nombre: "En Ruta", estilo: "status-ruta"},
    {id: estatus.programado, nombre: "Programado", estilo: "status-programado"},
    {id: estatus.porProgramar, nombre: "Por Programar", estilo: "status-por-programar"}
];

const Item = ({evento}) => {
    const getEstilos = () => {
        const estatus = listaEstatus.find(e => e.id === evento.estatusId);
        const esVacio = evento.tipoViajeId === constantesOperacion.tipoProgramacionViaje.vacio;
        return `viaje-en-calendario ${estatus.estilo} ${esVacio ? 'vacio' : ''}`;
    };

    const getTitle = () => {
        const operador = `${evento.operador?.nombre} | Caja:${evento.caja != null ? evento.caja.nombre : ' Vacío'} \n`;
        let rutas = "";
        evento.rutas?.forEach((e, index) => {
            rutas += `${e.nombre} (${e.hora}) ${index < evento.rutas.length - 1 ? '->' : ''}`;
        });
        const cliente = `\n${evento.cliente?.nombre} ${evento.idViaje != null ? `, ID ${evento.idViaje}` : ''}`;
        return operador + rutas + cliente;
    };

    return (
        <div className={getEstilos()} title={getTitle()}>
            <div>{evento.operador?.nombre} | Caja: {evento.caja != null ? evento.caja.nombre : ' Vacío'}</div>
            <div className="d-flex align-items-center">
                {evento.rutas?.map((r, index) => {
                        return <React.Fragment key={r.id}>
                            <div>{`${r.nombre} (${r.hora})`}</div>
                            {index < evento.rutas.length - 1 &&
                            <i className="pi pi-arrow-right px-1" style={{fontSize: '0.6rem'}}/>
                            }
                        </React.Fragment>
                    }
                )}
            </div>
            <div>
                {evento.cliente?.nombre}{evento.idViaje != null && evento.idViaje !== '' ? `, ID ${evento.idViaje}` : ''}
            </div>
        </div>
    );
};

const ItemTitulo = ({item}) => {
    const mantenimientoProximo = item.estatusMantenimiento.estatus === constantes.estatusMantenimiento.proximo;
    const mantenimientoVencido = item.estatusMantenimiento.estatus === constantes.estatusMantenimiento.vencido;

    function obtenerTitle() {
        if (!mantenimientoProximo && !mantenimientoVencido) return "";

        const mensaje = mantenimientoVencido ? 'Mantenimiento vencido hace' : 'Próximo mantenimiento en';
        const estatus = item.estatusMantenimiento;
        const kilometros = mantenimientoVencido ? estatus?.kilometrosVencidos : estatus?.kilometrosParaMantenimiento;
        return `${mensaje} ${numeral(kilometros).format('0,0')} km`
    }

    return (
        <div className="item-titulo d-flex align-items-center" style={{height: '100%'}} title={obtenerTitle()}>
            <div className={mantenimientoProximo ? "text-warning" : 'text-danger'}
                 style={{fontSize: '1rem', padding: '0 1.5rem'}}>
                {mantenimientoProximo || mantenimientoVencido ?
                    <IconLibrary iconName="VscTools"/>
                    : <span className="mr-3"/>
                }
            </div>
            <div className="text-left">
                <div><b>{item.nombre}</b></div>
                <div>{item.tipoUnidad}</div>
            </div>
        </div>
    );
};

const CalendarioUnidadViaje = ({unidades}) => {
    const [fecha, setFecha] = useState(moment(new Date()).startOf('day'));

    const onFechaInicialCambio = (nuevaFechaInicial) => {
        setFecha(nuevaFechaInicial);
    };

    return (
        <>
            {unidades != null &&
            <Calendario
                datos={unidades}
                titulo="UNIDAD"
                ItemTitulo={ItemTitulo}
                ItemTemplate={Item}
                numeroDeDias={2}
                fechaInicial={fecha}
                onFechaInicialCambio={onFechaInicialCambio}
            >
            </Calendario>
            }

            <div className="d-flex justify-content-center mt-3">
                {listaEstatus.map(e => {
                    return (
                        <div key={e.id} className="d-flex">
                            <div className={e.estilo} style={{width: '30px', height: '20px'}}/>
                            <span className="pl-2 pr-5">{e.nombre}</span>
                        </div>
                    )
                })}
            </div>
        </>
    )
};


export default CalendarioUnidadViaje;
