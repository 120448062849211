/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer } from "react";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import useApiParametros from "../../api/catalogos/useApiParametros";
import ParametroComercial from "./ParametrosComercial";
import ParametroControlPatios from "./ParametrosControlPatios";
import formReducer from "../../shared/forms/formReducer";
import parametrosValidacionEsquema from "./parametrosValidacionEsquema";
import FormHandler from "../../shared/forms/formHandler";
import "./parametro.css";
import useSeguridad from "../../Seguridad/useSeguridad";
import constantes from "../constantes";
import ReadOnlyProvider from "../../shared/forms/ReadOnlyProvider";

const Parametro = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.parametros);
    const api = useApiParametros();
    const defaultParametros = () => (
        {
            entity: {
                parametrosPorkm: [],
                parametrosPorDia: [],
            }
        }
    );
    const [{ entity, errors }, dispatch] = useReducer(
        formReducer, {}, defaultParametros
    );

    useEffect(() => {
        async function obtenerParametros() {
            const result = await api.filtrar();
            const restart = { entity: result };
            dispatch({ type: 'reset', getDefault: () => restart });

        }

        obtenerParametros().then();
    }, []);


    const formHandler = new FormHandler({
        validationSchema: parametrosValidacionEsquema,
        api: api,
        dispatch,
    });

    const save = async (callValidation) => {

        formHandler.setValidacionSchema(parametrosValidacionEsquema);

        await formHandler.save(entity, true);

    };



    const update = (value, name) => {

        dispatch({ type: 'update', value, name });
    };


    return (
        <>



            <div className="form-container">


                <div className="row align-items-start">
                    <div className="col">
                        <h5>Parametros</h5>
                    </div>
                    <div style={{ flexGrow: "0" }} className="col ml-auto">
                        {!soloLectura &&
                            <ButtonIcon title="Guardar" iconName={"AiOutlineSave"} onClick={save} />
                        }
                    </div>
                </div>

                <br />
                <ReadOnlyProvider defaultReadOnly={soloLectura}>
                    <ParametroComercial errors={errors ?? {}} onChange={update}
                        rendimientoDieselPorKmDefault={entity.rendimientoDieselPorKmDefault}
                        rendimientoDieselPorKm={entity.rendimientoDieselPorKm}
                        costoDiesel={entity.costoDiesel}
                        parametrosPorkm={entity?.parametrosPorkm}
                        precioPorKilometro={entity?.precioPorKilometro}
                        parametrosPorDia={entity?.parametrosPorDia} />

                    <br />
                    <ParametroControlPatios errors={errors ?? {}} onChange={update} Caja53Ps={entity?.caja53Ps} Caja48Ps={entity?.caja48Ps} />

                </ReadOnlyProvider>
            </div>

        </>
    )
};

export default Parametro;
