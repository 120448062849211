import * as yup from "yup";

export const gastoValidacionEsquema = yup.object().shape({
    // operador: yup.mixed().required(),
    operador: yup.mixed().when(['urgente', 'extProv'], {
     is: (urgente, extProv) => urgente === true && (extProv === 1 || extProv === 3),
     then: yup.mixed().required('Operador es requerido cuando es urgente y extProv es 1 o 3.'),
     otherwise: yup.mixed(), // No requerido en otros casos
     }),
     cuentas: yup.mixed().when(['urgente', 'extProv'], {
         is: (urgente, extProv) => urgente === true && extProv === 3,
         then: yup.mixed().required(''),
         otherwise: yup.mixed().notRequired()
     }),
     nombreexterno: yup.mixed().when(['urgente', 'extProv'], {
         is: (urgente, extProv) => urgente === true && extProv === 2,
         then: yup.mixed().required(''),
         otherwise: yup.mixed().notRequired()
     }),
     banco: yup.mixed().when(['urgente', 'extProv'], {
         is: (urgente, extProv) => urgente === true && (extProv === 1 || extProv === 2),
         then: yup.mixed().required('Banco es requerido cuando es urgente y extProv es 1 o 2'),
         otherwise: yup.mixed().notRequired(), // No requerido en otros casos
     }),
     clabeAnticipo: yup.mixed().when(['urgente', 'extProv'], {
         is: (urgente, extProv) => urgente === true && (extProv === 1 || extProv === 2),
         then: yup.mixed().required('Clabe es requerida cuando es urgente y extProv es 1 o 2'),
         otherwise: yup.mixed().notRequired(), // No requerido en otros casos
     }),
     tipoGasto: yup.mixed().required(),
     conceptoCajaChica: yup.mixed().required(),
     monto: yup.number().required(),
     comentario: yup.string().required()
 });
 