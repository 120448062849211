import * as yup from "yup";
import constantes from "./constantes";

const direccionValidacionEsquema = (nombreRequerido,geocercaRequerida = false) => (
    yup.object({
        idNombre: nombreRequerido ? yup.mixed().required() : null,
        geocerca: geocercaRequerida ? yup.mixed().required() : null,
        calle: yup.string().required(),
        colonia: yup.string().required(),
        noExterior: yup.string().required(),
        codigoPostal: yup.string().required(),
        estado: yup.mixed().when("pais.id", {
            is: constantes.paises.mexico,
            then: yup.mixed().required()
        }),
        municipio: yup.mixed().when("pais.id", {
            is: constantes.paises.mexico,
            then: yup.mixed().required()
        }),
        municipioNombre: yup.mixed().when("pais.id", {
            is: constantes.paises.mexico,
            otherwise: yup.mixed().required()
        }),
        // colonias: yup.mixed().when("pais.id", {
        //     is:constantes.paises.mexico,
        //     then: yup.mixed().required()
        // })
    })
);

export default direccionValidacionEsquema;
