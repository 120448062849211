/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import * as numeral from "numeral";

const TanqueVista = ({capacidad, porcentajeAdvertencia, porcentajeBandera, disponible, cantidadSegmentos, mostrarUbicacion, ubicacion}) => {
    const [segmentos, setSegmentos] = useState([]);

    useEffect(() => {
        crearSegmentos();
    }, [capacidad])

    const crearSegmentos = () => {
        const segmentos = [];
        const litrosSegmento = capacidad / cantidadSegmentos;
        for (let i = 1; i <= cantidadSegmentos; i++) {
            segmentos.push({litros: litrosSegmento * i, porcentaje: litrosSegmento * i * 100 / capacidad});
        }

        setSegmentos(segmentos);
    }


    return (
        <>
            <div className="tanque-vista">
                {mostrarUbicacion &&
                    <span className="ubicacion">
                        {ubicacion}
                    </span>
                }
                {segmentos.map((segmento, index) => (
                    <div className={`segmento ${index===segmentos.length -1 ? 'ultimo' : ''}`} style={{left: `${segmento.porcentaje}%`}}>
                        <div className="label">
                            <span>{numeral(segmento.litros).format('0,0')} Lts</span>
                        </div>
                    </div>
                ))}
                <div className="advertencia" style={{left: `${porcentajeAdvertencia}%`}}/>
                <div className="bandera" style={{left: `${porcentajeBandera}%`}}/>
                <div className="disponible" style={{width: `${disponible * 100 / capacidad}%`}}>
                    <div className="label">
                        <span className="texto">
                            {numeral(disponible).format('0,0')} Lts
                        </span>
                        <i className="indicador pi pi-caret-down" style={{'fontSize': '1em'}}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TanqueVista;
