import useServiceFactory from "../serviceFactory";

const apiUrl = `/administracion/movimiento`;

const useApiMovimientos = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, { params, showLoading: true }),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, { showLoading: true }),
        movimientoAnterior: async (params) => service.get(`${apiUrl}/movimientoAnterior`, { params }),
        guardar: async (params) => service.post(`${apiUrl}`, params),
        actualizar: async (id, params) => service.put(`${apiUrl}/${id}`, params),
        actualizarComprobado: async (id, params) => service.post(`${apiUrl}/${id}/actualizarComprobado`, params, { showSuccess: true }),
        archivoGasto: async (id, formData) => service.post(`${apiUrl}/${id}/archivoGasto`, formData),
        autorizar: async (id) => service.put(`${apiUrl}/${id}/autorizar`,{ showSuccess: true }),
        rechazar: async (id, params) => service.put(`${apiUrl}/${id}/rechazar`, params),
        cancelar: async (id) => service.put(`${apiUrl}/${id}/cancelar`),
        excel: async (params) => service.get(`${apiUrl}/excel`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `Movimientos.xlsx`
            }),
        agregarDescuento: async (params) => service.post(`${apiUrl}/agregarDescuento`, params, { showSuccess: true }),
        agregarGasto: async (params) => service.post(`${apiUrl}/agregarGasto`, params, { showSuccess: true })
    }
};

export default useApiMovimientos;
