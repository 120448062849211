/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import UseApiOrdenServicio from "../../../api/taller/useApiOrdenServicio";
import { Accordion, AccordionTab } from "primereact/accordion";
import { RadioButton } from "primereact/radiobutton";
import produce from "immer";
import { useNavigate } from "react-router-dom";
import alerts from "../../../shared/alerts";
import constantes from "../../constantes";


const CheckList = ({
  ordenTrabajoId,
  guardarInformacion,
  cancelarGuardado,
}) => {
  const apiOrdenServicio = UseApiOrdenServicio();
  const [checklist, setChecklist] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    let timer1 = setTimeout(loadCheckList, 100);
    return () => {
      clearTimeout(timer1);
    };
  }, [ordenTrabajoId]);

  useEffect(() => {
    if (guardarInformacion) {
      confirmar();
    }
  }, [guardarInformacion]);

  const loadCheckList = async () => {


    if (ordenTrabajoId === 0)
      return;

    let checklist = await apiOrdenServicio.obtieneCheckList(ordenTrabajoId);
    setChecklist(checklist);
  };

  const confirmar = async () => {

    const errorlist = checklist
      .filter(obj => {
        obj.error = obj.checkList.some(el => el.estatus.id === 0);
        return obj.error;
      });


    const newList = checklist.map((item) => {

      const filtered = errorlist.filter(function (element) {
        return element.nombre === item.nombre
      });

      const objectComplete = checklist.filter(function (element) {
        return element.nombre === item.nombre
      });


      if (filtered[0] !== undefined) {
        const updatedItem = {
          ...objectComplete[0],
          error: true,
        };
        return updatedItem;
      }
      else {
        const updatedItem = {
          ...objectComplete[0],
          error: false,
        };
        return updatedItem;
      }
    });

    if (errorlist.length > 0) {
      cancelarGuardado();
      setChecklist(newList);
      return;
    }


    if (
      (await alerts.preguntarSiNoAdvertencia(
        "¿Desea confirmar la orden de trabajo?"
      )) === true
    ) {
      await apiOrdenServicio.confirmar(ordenTrabajoId, checklist);
      history("/taller/mantenimiento");
    }

    cancelarGuardado();
  };

  function actualizarLista(lista, index, value, propertyName, name) {
    const newCheckList = produce(lista, (draft) => {
      draft[index][propertyName].id = value;
    });

    const newList = checklist.map((item) => {
      if (item.nombre === name) {
        const updatedItem = {
          ...item,
          checkList: newCheckList,
        };
        return updatedItem;
      }

      return item;
    });

    setChecklist(newList);
  }

  return (
    <>
      <div style={{ background: "white" }}>
        <div>
          <h5>Diagnostico Multipuntos</h5>
        </div>

        <div className={`section-container`}>
          <div className="flex-col-6">
            {checklist.map((item, index) => {
              return (
                <div className="flex-col-6 danger">
                  {(index % 2 ? "Impar" : "Par") === "Par" && (
                    <>
                      <div className="danger">
                        <div className="danger">
                          <table className="table">
                            <thead className="thead-light"></thead>
                            <tbody className={`${item.error === true ? "error" : ""}`}>
                              <Accordion id="accordeon" style={{ width: "100%" }} multiple activeIndex={[0]}>
                                <AccordionTab
                                  contentStyle={{ width: "100%" }}
                                  header={
                                    <>
                                      <span>
                                        <div style={{ display: "flex", width: "100%" }}>
                                          <div style={{ width: "50%" }} >{item.nombre}</div>
                                          <div style={{ width: "20%" }}>OK</div>
                                          <div style={{ width: "30%" }}>NECESITA SERVICIO</div>
                                          <div style={{ width: "20%" }}>N/A</div>

                                        </div>
                                      </span>

                                    </>
                                  }
                                >
                                  {checklist[index].checkList.map(function (
                                    check,
                                    indexcheck
                                  ) {
                                    return (
                                      <tr key={indexcheck}>
                                        <td style={{ width: "43%" }}>
                                          {check.puntoInspeccion.nombre}
                                        </td>
                                        <td style={{ width: "20em" }}>
                                          <div className="">
                                            <RadioButton
                                              name="estatus"
                                              value={constantes.estatusCheck.Ok}
                                              id="radioButtonOk"
                                              inputId={`${item.nombre}_${indexcheck}`}
                                              onChange={(e, name) =>
                                                actualizarLista(
                                                  checklist[index].checkList,
                                                  indexcheck,
                                                  e.value,
                                                  "estatus",
                                                  item.nombre
                                                )
                                              }
                                              checked={
                                                check.estatus.id === constantes.estatusCheck.Ok
                                              }
                                            />
                                          </div>
                                        </td>

                                        <td style={{ width: "20em" }}>
                                          <div className="">
                                            <RadioButton
                                              name="estatus"
                                              value={constantes.estatusCheck.NecesitaServicio}
                                              id="radioButtonOk"
                                              onChange={(e, name) =>
                                                actualizarLista(
                                                  checklist[index].checkList,
                                                  indexcheck,
                                                  e.value,
                                                  "estatus",
                                                  item.nombre
                                                )
                                              }
                                              checked={
                                                check.estatus.id === constantes.estatusCheck.NecesitaServicio
                                              }
                                            />
                                          </div>
                                        </td>

                                        <td style={{ width: "20em" }}>
                                          <div className="">
                                            <RadioButton
                                              name="estatus"
                                              value={constantes.estatusCheck.NA}
                                              id="radioButtonOk"
                                              onChange={(e, name) =>
                                                actualizarLista(
                                                  checklist[index].checkList,
                                                  indexcheck,
                                                  e.value,
                                                  "estatus",
                                                  item.nombre
                                                )
                                              }
                                              checked={
                                                check.estatus.id === constantes.estatusCheck.NA
                                              }
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </AccordionTab>
                              </Accordion>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>

          <div className="flex-col-6">
            {checklist.map((item, index) => {
              return (
                <div className="flex-col-6">
                  {(index % 2 ? "Impar" : "Par") === "Impar" && (
                    <>
                      <div className="">
                        <div className="">
                          <table className="table">
                            <thead className="thead-light"></thead>
                            <tbody className={`${item.error === true ? "error" : ""}`}>
                              <Accordion
                                style={{ margintop: "-17px" }}
                                multiple
                                activeIndex={[0]}
                              >
                                <AccordionTab
                                  style={{ background: "white" }}
                                  header={
                                    <>
                                      <span>
                                        <div style={{ display: "flex", width: "100%" }}>
                                          <div style={{ width: "50%" }} >{item.nombre}</div>
                                          <div style={{ width: "20%" }}>OK</div>
                                          <div style={{ width: "30%" }}>NECESITA SERVICIO</div>
                                          <div style={{ width: "20%" }}>N/A</div>
                                        </div>
                                      </span>
                                    </>
                                  }
                                >
                                  {checklist[index].checkList.map(function (check, indexcheck) {
                                    return (
                                      <tr key={indexcheck}>
                                        <td style={{ width: "43%" }}>
                                          {check.puntoInspeccion.nombre}
                                        </td>
                                        <td style={{ width: "20em" }}>
                                          <div className="">
                                            <RadioButton
                                              name="estatus"
                                              value={constantes.estatusCheck.Ok}
                                              id="radioButtonOk"
                                              inputId={`${item.nombre}_${indexcheck}`}
                                              onChange={(e, name) =>
                                                actualizarLista(
                                                  checklist[index].checkList,
                                                  indexcheck,
                                                  e.value,
                                                  "estatus",
                                                  item.nombre
                                                )
                                              }
                                              checked={
                                                check.estatus.id === constantes.estatusCheck.Ok

                                              }
                                            />
                                          </div>
                                        </td>

                                        <td style={{ width: "20em" }}>
                                          <div className="">
                                            <RadioButton
                                              name="estatus"
                                              value={constantes.estatusCheck.NecesitaServicio}
                                              id="radioButtonOk"
                                              onChange={(e, name) =>
                                                actualizarLista(
                                                  checklist[index].checkList,
                                                  indexcheck,
                                                  e.value,
                                                  "estatus",
                                                  item.nombre
                                                )
                                              }
                                              checked={
                                                check.estatus.id === constantes.estatusCheck.NecesitaServicio
                                              }
                                            />
                                          </div>
                                        </td>

                                        <td style={{ width: "20em" }}>
                                          <div className="">
                                            <RadioButton
                                              name="estatus"
                                              value={constantes.estatusCheck.NA}
                                              id="radioButtonOk"
                                              onChange={(e, name) =>
                                                actualizarLista(
                                                  checklist[index].checkList,
                                                  indexcheck,
                                                  e.value,
                                                  "estatus",
                                                  item.nombre
                                                )
                                              }
                                              checked={
                                                check.estatus.id === constantes.estatusCheck.NA
                                              }
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </AccordionTab>
                              </Accordion>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckList;
