/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import useApiViajeSeguimiento from "../../../../api/operaciones/useApiViajesSeguimiento";
import {ProgressBar} from "primereact/progressbar";

const GeocercasViaje = ({viaje}) => {
    const api = useApiViajeSeguimiento();

    const [geocercas, setGeocercas] = useState([]);

    useEffect(() => {
        async function cargar() {
            const result = await api.obtenerGeocercas(viaje.id)
            setGeocercas(result);
        }

        cargar().then();
    }, []);

    const getBarraClassName = () => {
        return !viaje.terminado && viaje.enTiempo ? 'viaje-a-tiempo' : !viaje.terminado ? 'viaje-retrasado' : '';
    };

    return (
        <>
            <div className={"progressbar-sm " + getBarraClassName()}>
                <ProgressBar value={viaje.porcentajeViaje} showValue={false}
                             color={`${viaje.terminado ? '#00d661' : '#ff4b4b'}`}/>
            </div>
            <div className="text-right pr-3">
                <b>ETA: <span className={viaje.enTiempo? 'text-success': 'text-danger'}>
                    {viaje.eta}
                </span></b>
            </div>

            <table className="table table-borderless mb-0" style={{lineHeight: 1.2, width: '60%'}}>
                <tbody>
                {geocercas.map((e, index) =>
                    <tr key={e.id}>
                        <td style={{maxWidth: '120px'}}>
                            <b>{e.nombre}</b>
                            {index + 1 < geocercas.length &&
                            <div className="pt-2"><i className="pi pi-ellipsis-v"/></div>
                            }
                        </td>

                        <td>
                            <div className="d-flex flex-column">
                                <span>Hora Sug.: {e.fechayHoraSugerida}</span>
                                <span>Hora Real: {e.fechayHoraReal}</span>

                                {e.horaReal &&
                                <div className={`d-flex mt-1 text-${e.enTiempo ? 'success' : 'danger'}`}>
                                    <i className={`pi ${e.enTiempo ? 'pi-check-circle' : 'pi-times-circle'}`}/>
                                    <span className="pl-1">{e.enTiempo ? "A TIEMPO" : "RETRASO"}</span>
                                </div>
                                }
                            </div>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </>
    );
};

export default GeocercasViaje;
