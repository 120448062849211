import useServiceFactory from "../serviceFactory";
const apiUrl = `/catalogos/agenda`;
const useApiAgenda = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, {params}),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, {showLoading: true}),
        guardar: async (params) => service.put(`${apiUrl}`, params)
    }
};

export default useApiAgenda;
