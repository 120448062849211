/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef} from "react";
import {Button} from "primereact/button";


const InputClipboardFile = ({onChange}) => {

    const inputEl = useRef(null);

    const handleUpload = (event) => {

        const file =event.target.files[0];
        const components = file.name.split('.');


        onChange({
            isEmpty: false,
            file: file,
            nombre: file.name,
            extension: components[components.length - 1]
        });
    }


    const onClick = () => {
        inputEl.current.click();
    };


    return (
        <>
            <Button className="p-button-rounded p-button-text" icon="pi pi-search" onClick={onClick}
                    title={'Copiar desde el portapapeles'}/>
            <input ref={inputEl} style={{display: 'none'}} type="file" onChange={handleUpload}
                   accept=".gif,.jpg,.jpeg,.png,.pdf"/>
        </>
    )
};


export default InputClipboardFile;
