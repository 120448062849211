import * as yup from "yup";
import constantes from "../constantes";

const guardar = yup.object().shape({
    datosGenerales: yup.object({
        fechaIngreso: yup.mixed().required(),
        nombre: yup.string().required(),
        apellidoPaterno: yup.string().required(),
        apellidoMaterno: yup.string().required(),
        curp: yup.string().required(),
        sexo: yup.object({
            id: yup.number().required().min(1),
        }),
        rfc: yup.string().required(),
      
    }),
    nomina: yup.object({
        esquemaPagos: yup.number().required(),
        fechaInicioAhorro: yup.mixed()
        .when("ahorro",{
            is:(e) => (e!== null && e!== 0),
            then: yup.mixed().required()
        }),  
        numeroExhibiciones: yup.mixed()
        .when("prestamoCajaAhorro",{
            is:(e) => (e!== null && e!== 0) ,
            then: yup.number().required().min(1),
        }),
        fechaInicio: yup.mixed()
        .when("prestamoCajaAhorro",{
            is:(e) => (e!== null && e!== 0) ,
            then: yup.mixed().required()
        })           
    }),
    asignacion: yup.object({
        departamento: yup.object({
            id: yup.number().required().min(1),
        }),
        puesto: yup.object({
            id: yup.number().required().min(1),
        }),
        tipoEmpleado: yup.object({
            id: yup.number().required().min(1),
        })
    }),
    licencias:  yup.mixed().when("asignacion.esOperador", {
        is: true,
        then :yup.array()
            .of(
                yup.object().shape({
                    tipoLicencia: yup.object({
                        id: yup.number().required().min(1),
                    }),
                    categoriaLicencia: yup.object({
                        id: yup.number().required().min(1),
                    }),
                    vigencia: yup.mixed().required(),
                    archivo: yup.mixed().required(),
                    numeroDocumento: yup.mixed().required(),
                })
            ),
    archivosAdicionales:
            yup.array()
                .of(
                    yup.object().shape({
                        nombre: yup.string().required(),                     
                    })
                ),
    })
});
 
const alta = yup.object().shape({
    datosGenerales: yup.object({
        fechaIngreso: yup.mixed().required(),
        nombre: yup.string().required(),
        apellidoPaterno: yup.string().required(),
        apellidoMaterno: yup.string().required(),
        curp: yup.string().required(), 
        sexo: yup.object({
            id: yup.number().required().min(1),
        }),
        rfc: yup.string().required(),
        estadoCivil: yup.object({
            id: yup.number().required().min(1),
        }),
        fechaNacimiento: yup.mixed().required(),
        estadoNacimiento: yup.object({
            id: yup.number().required().min(1),
        }),
        telefonoABC: yup.string().required(),
        // correo: yup.string().required(),
        talla: yup.object({
            id: yup.number().required().min(1),
        })
    }),
    asignacion: yup.object({
        departamento: yup.object({
            id: yup.number().required().min(1),
        }),
        puesto: yup.object({
            id: yup.number().required().min(1),
        }),
        // jefeInmediato: yup.object({
        //     id: yup.number().required().min(1),
        // }),
        tipoEmpleado: yup.object({
            id: yup.number().required().min(1),
        })
    }),
    familiares: yup.array()
        .of(
            yup.object().shape({
                relacionFamilia: yup.object({
                    id: yup.number().required().min(1),
                }),
                nombre: yup.string().required(),
                fechaNacimiento: yup.mixed().required(),
                acta: yup.mixed().when("relacionFamilia.id", {
                    is: true,
                    then: yup.object().shape({
                        isEmpty: yup.boolean()
                    })
                })
            })
            
        ),
    datosPersonales: yup.object({
        codigoPostal: yup.string().required(),
        estado: yup.string().required().min(1),
        municipio: yup.string().required(),
        colonia: yup.string().required(),
        calle: yup.string().required(),
        numeroExterior: yup.string().required(),
        // numeroInterior: yup.string().required(),
    }),
    nomina: yup.object({
        banco: yup.object({
            id: yup.number().required().min(1),
        }),
        // numeroTarjeta: yup.string().required(),
        numeroCuenta: yup.string().required(),
        clabe: yup.string().required(),
        beneficiario: yup.string().required(),
        relacionBeneficiario: yup.object({
            id: yup.number().required().min(1),
        }),
        telefonoBeneficiario: yup.string().required(),
        esquemaPagos: yup.number().required(),
        // sueldoBaseSemanal: yup.number().required(),
        ispt: yup.number().required(),
        // pensionAlimenticia: yup.number().required(),
        proveedorPago: yup.object({
            id: yup.number().required().min(1),
        }),
        fechaInicioAhorro: yup.mixed()
        .when("ahorro",{
            is:(e) => (e!== null && e!== 0) ,
            then: yup.mixed().required()
        }),  
        numeroExhibiciones: yup.mixed()
        .when("prestamoCajaAhorro",{
            is:(e) => (e!== null && e!== 0) ,
            then: yup.number().required().min(1),
        }),
        fechaInicio: yup.mixed()
        .when("prestamoCajaAhorro",{
            is:(e) => (e!== null && e!== 0) ,
            then: yup.mixed().required()
        })    
    }),
    seguridadSocial: yup.object({
        nss: yup.string().required(),
        // unidadMedica: yup.string().required(),
        salarioIMMS: yup.number().required(),
        descuentoIMMS: yup.number().required()
    }),
    antidoping: yup.array()
        .of(
            yup.object().shape({
                fecha: yup.mixed().required(),
                resultado: yup.object({
                    id: yup.number().required().min(1),
                }),
                examenPositivo: yup.mixed().when("resultado.id", {
                    is: constantes.resultado.positivo,
                    then: yup.mixed().required()
                }),
                anexo: yup.mixed().when("resultado.id", {
                    is: constantes.resultado.positivo,
                    then: yup.object().shape({
                        isEmpty: yup.boolean()
                    })
                }),
            })
        ),

        actasAdministrativas: yup.array()
        .of(
            yup.object().shape({
                motivo: yup.object({
                    id: yup.number().required().min(1),
                }),
                fecha: yup.mixed().required(),              
                anexo: yup.mixed().when("motivo.id", {
                    is: true,
                    then: yup.object().shape({
                        isEmpty: yup.boolean()
                    })
                }),
            })
        ),
        documentosGenerales: yup.array()
        .of(
            yup.object().shape({
                numeroDocumento: yup.mixed().when("tipoDocumento.requeridoOoerador", {
                    is: true,
                    then: yup.mixed().required()
                }),
                vigencia: yup.mixed().when("tipoDocumento.requeridoOoerador", {
                    is: true,
                    then: yup.mixed().required()
                }),
                archivo: yup.mixed().when("tipoDocumento.requerido", {
                    is: true,
                    then: yup.object().shape({
                        isEmpty: yup.boolean()
                    })
                }),
            })
        ),  
        documentosOperador: yup.array()
        .of(
            yup.object().shape({
                numeroDocumento: yup.mixed().when("tipoDocumento.requeridoOoerador", {
                    is: true,
                    then: yup.mixed().required()
                }),
                vigencia: yup.mixed().when("tipoDocumento.requeridoOoerador", {
                    is: true,
                    then: yup.mixed().required()
                }),
                archivo: yup.mixed().when("tipoDocumento.requerido", {
                    is: true,
                    then: yup.object().shape({
                        isEmpty: yup.boolean()
                    })
                }),
            })
        ),            
    licencias:  yup.mixed().when("asignacion.esOperador", {
        is: true,
        then :yup.array()
            .of(
                yup.object().shape({
                    tipoLicencia: yup.object({
                        id: yup.number().required().min(1),
                    }),
                    categoriaLicencia: yup.object({
                        id: yup.number().required().min(1),
                    }),
                    vigencia: yup.mixed().required(),
                    archivo: yup.mixed().required(),
                    numeroDocumento: yup.mixed().required(),
                })
            ),
    archivosAdicionales:
            yup.array()
                .of(
                    yup.object().shape({
                        nombre: yup.string().required(),                     
                    })
                ),
    })

});

const empleadoValidacionEsquema = {
    alta,
    guardar
};

export default empleadoValidacionEsquema;