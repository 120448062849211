import useServiceFactory from "../serviceFactory";
const apiUrl = `/taller/mantenimiento/llantas`;
const apiUrlRenovar =  `/taller/recepcionRenovado`;
const useApiLlantas = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, { params }),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, { showLoading: true }),
        guardar: async (params) => service.post(`${apiUrl}`, params),
        actualizar: async (id, params) => service.put(`${apiUrl}/${id}`, params, { showSuccess: true }),
        asignar: async (id, params) => service.put(`${apiUrl}/${id}/asignar`, params),
        enviarAlmacen: async (id) => service.patch(`${apiUrl}/${id}/enviarAlmacen`, {}),
        enviarDesecho: async (id) => service.patch(`${apiUrl}/${id}/enviarDesecho`, {}),
        excel: async (params) => service.get(`${apiUrl}/excel`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `Llantas.xlsx`
            }),
        llantas: async (id) => service.get(`${apiUrl}/llantas/${id}`, { showLoading: true }),
        rotar: async(id, params) => service.put(`${apiUrl}/rotar/${id}`, params, { showSuccess: true }),
        renovar: async (params) => service.post(`${apiUrlRenovar}`, params)
    }
};

export default useApiLlantas;