import React, {useState} from "react";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import FieldCalendarRange from "../../shared/controls/fieldCalendarRange";
import useApiEstatusDiarios from "../../api/reportes/useEstatusDiariosApi";
import {enqueueSnackbar} from "notistack";
import FieldMultiSelect from "../../shared/controls/fieldMultiSelect";
import moment from "moment";
import constantes from "../../catalogos/constantes";

const estatus = constantes.estatus.EstatusDiarios;
const todos = [estatus.Disponible, estatus.Operando, estatus.EnManteimiento, estatus.ConProblemas]

const ExportarModal = ({visible, onHide}) => {

    const api = useApiEstatusDiarios()

    const [filtro, setFiltro] = useState({estatus:todos, fechas:[moment().add(-1,'week')._d, new Date()]})

    const onExport = async ()=>{
        const fechas = filtro.fechas;
        if (fechas == null || fechas.length < 2 || fechas[1] == null) {
            enqueueSnackbar('Debe seleccionar un rango de fechas', {
                variant: "error",
                anchorOrigin: {horizontal: "right", vertical: "top"},
            });
            return
        }
        await api.excel(filtro)
        onHide()
    }

    const renderFooter = () => {
        return (
            <div>
                <Button
                    label={"Exportar"}
                    icon="pi pi-check"
                    autoFocus
                    onClick={onExport}
                />
            </div>
        );
    };

    return (<Dialog
        header={`Exportar`}
        visible={visible}
        position={"center"}
        modal
        style={{width: "400px"}}
        footer={renderFooter()}
        onHide={onHide}
        className="p-dialog-content-Overflow"
        draggable={false}
        resizable={false}
    >
        <FieldMultiSelect name="estatus" label="Estatus" colMd={12} value={filtro.estatus} source={'estatusDiarios'}
                           valueAsId={true}  onChange={(estatus)=>setFiltro({...filtro,estatus})} />
        <FieldCalendarRange name="fechas" label="Rango de Fechas" colMd={12} value={filtro.fechas}
                            onChange={(fechas)=>setFiltro({...filtro, fechas})} />
    </Dialog>)
}
export default ExportarModal;