import React, {useState} from "react";
import produce from "immer";
import FieldInputNumber from "../../../shared/controls/fieldInputNumber";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import {SelectButton} from "primereact/selectbutton";
import FieldMultiSelect from "../../../shared/controls/fieldMultiSelect";
import FieldCalendar from "../../../shared/controls/fieldCalendar";

const Especificaciones = ({especificaciones, name, errors, onChange}) => {
    const options = ['%'];
    const [tipoPorcentajeDiesel] = useState('%');

    async function actualizar(value, propertyName) {
        const nuevo = produce(especificaciones ?? {}, draft => {
            draft[propertyName] = value;
        });
        onChange(nuevo, name);
    }

    function updateArray(index, name, value, propertyName) {
        const newValue = produce(especificaciones[name], draft => {
                draft[index][propertyName] = value;
            }
        );
        actualizar(newValue, name);
    }

    return (
        <>
            <div className="form-row">
                <h5 className="col-12">General</h5>
                <FieldInputNumber name="capacidadTanque" label="Capacidad Tanque (Lts)" colMd={6} errors={errors}
                                  required
                                  value={especificaciones.capacidadTanque} onChange={actualizar}/>
                <FieldMultiSelect showFilter={false} maxSelectedLabels={2} name="tipoCombustible"
                                  label="Tipo de Combustible" source="general/Tipo de combustible" colMd={6}
                                  value={especificaciones.tipoCombustible} onChange={actualizar} required
                                  errors={errors}/>
                <FieldInputNumber name="odometroInicial" label="Odómetro Inicial (compra)" colMd={6} errors={errors}
                                  required
                                  value={especificaciones.odometroInicial} onChange={actualizar}/>
                <FieldInputNumber name="odometroActual" label="Odómetro Actual" disabled={true} colMd={6}
                                  value={especificaciones.odometroActual}/>
                <FieldDropDown name="marcaMotor" label="Marca de Motor" colMd={6} source="marcasMotor" filter
                               errors={errors} required
                               value={especificaciones.marcaMotor} onChange={actualizar}/>
                <FieldTextBox name="numeroMotor" label="Número de Motor" colMd={6} required errors={errors}
                              value={especificaciones.numeroMotor} onChange={actualizar}/>

                <FieldDropDown name="caballaje" label="Caballaje" colMd={6} source="general/Caballaje" filter
                               value={especificaciones.caballaje} onChange={actualizar}/>

                <FieldDropDown name="capacidadSuspension" label="Capacidad de Suspensión" colMd={6}
                               source="general/CapacidadSuspension" filter
                               value={especificaciones.capacidadSuspension} onChange={actualizar}/>

                <FieldDropDown name="pasoDiferencial" label="Paso Diferencial" colMd={6}
                               source="general/PasoDiferencial" filter
                               value={especificaciones.pasoDiferencial} onChange={actualizar}/>

                <FieldDropDown name="epa" label="EPA" colMd={6} source="general/EPA" filter
                               value={especificaciones.epa} onChange={actualizar}/>

                <div className={`form-group col-md-6 col-sm-12`}>
                    <label>Porcentaje de error (diesel)</label>
                    <div className="col-auto">
                        <FieldInputNumber name="dieselPorcentaje" colMd={6} fractionDigits={2} errors={errors}
                                          value={especificaciones.dieselPorcentaje} onChange={actualizar}/>
                        <SelectButton value={tipoPorcentajeDiesel} options={options}/>
                    </div>
                </div>
                <div className={`form-group col-md-6 col-sm-12`}>
                    <label>Rendimiento esperado</label>
                    <div className="col-auto">
                        <FieldInputNumber name="rendimientoEsperado" colMd={6} fractionDigits={2} errors={errors}
                                          value={especificaciones.rendimientoEsperado} onChange={actualizar}/>
                    </div>
                </div>
                <FieldInputNumber name={'kmMantenimiento'} label={'Kms. para Mtto.'}
                                  value={especificaciones?.kmMantenimiento}
                                  onChange={actualizar} colMd={6} fractionDigits={2} errors={errors}/>
                <FieldInputNumber name={'proximoMantenimiento'} label={'Kms. Proximo  Mtto.'}
                                  value={especificaciones?.proximoMantenimiento}
                                  onChange={actualizar} colMd={6} fractionDigits={2} errors={errors}/>
                <FieldCalendar name={'fechaMantenimiento'} label={'Fecha Proximo Mtto.'}
                               value={especificaciones?.fechaMantenimiento}
                               onChange={actualizar} colMd={6} errors={errors}/>
                <FieldInputNumber name={'pesoBruto'} label={'Peso (Kg.)'} value={especificaciones?.pesoBruto}
                                  onChange={actualizar} colMd={6} fractionDigits={2} errors={errors}/>
                <FieldInputNumber name={'metaRendimiento'} label={'Meta de Rendimiento (Km/L)'} value={especificaciones?.metaRendimiento}
                onChange={actualizar} colMd={6} fractionDigits={2} errors={errors}/>
                <FieldDropDown
                    name="UnidadSensorDieselId"
                    label="Sensor Diesel"
                    colMd={6}
                    source="unidadesSensorDiesel"                    
                    errors={errors}
                    showClear={false}
                    value={especificaciones?.unidadSensorDieselId}
                    valueById={true}
                    onChange={actualizar}
                />
                <h5 className="col-12">Escaner</h5>
                <FieldDropDown name="softwareEscaner" label="Software Escaner" colMd={6} errors={errors} required
                               source="general/Software escaner" filter
                               value={especificaciones.softwareEscaner} onChange={actualizar}/>
                <h5 className="col-12">IAVE</h5>
                {especificaciones.tarjetasCruce?.map((item, index) =>
                    <>
                        <FieldTextBox name="numero" label={item.tarjetaCruce.nombre} colMd={6} key={index}
                                      required={item.requerido}
                                      value={item.numero} errors={errors[`tarjetasCruce[${index}]`]}
                                      onChange={(value, name) => updateArray(index, 'tarjetasCruce', value, name)}
                        />
                    </>
                )}
                <FieldTextBox name="hologramaOxxogas" label="Holograma Oxxogas" colMd={6} errors={errors}
                              value={especificaciones.hologramaOxxogas} onChange={actualizar}/>
            </div>
        </>
    )
};

export default Especificaciones;
