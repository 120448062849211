
import React from "react";
import DateFormatter from "../../../shared/ag-grid-helper/dateFormatter";
import * as numeral from "numeral";

const LLantasHistorial = ({ historial }) => {
  return (
    <>
      <div className="detalle-llanta form-container full-height">

        <div className="form-row">
          <h5 className="col-12">Historial de Asignacion</h5>
          <br />
          <br />
          <div className="col-12">
            <table className="table main-edit-table" align="right">
              <thead className="thead-light">
                <tr className="text-center">
                  <th scope="col" style={{ width: "20%" }}>FECHA</th>
                  <th scope="col" style={{ width: "15%" }}>NO. ECONOMICO</th>
                  <th scope="col" style={{ width: "15%" }}>POSICION</th>
                  <th scope="col" style={{ width: "15%" }}>PROFUNDIDAD</th>
                  <th scope="col" style={{ width: "15%" }}>KILOMETROS</th>                                    
                  <th style={{ width: "20%" }}>ESTATUS</th>
                </tr>
              </thead>
              <tbody>
                {historial?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="input text-center">
                        <DateFormatter value={item.fecha} />
                      </td>
                      <td className="input text-center">
                        <label>{item.unidad?.nombre}</label>
                      </td>
                      <td className="input text-center">
                        <label>{item.posicion}</label>
                      </td>
                      <td className="input text-right">
                        <label>{numeral(item.profundidad).format('0,0.00')}</label>
                      </td>
                      <td className="input text-right">
                        <label>{numeral(item.kilometros).format('0,0.00')}</label>
                      </td>                                            
                      <td className="input text-center">
                        <label>{item.estatus}</label>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
};

export default LLantasHistorial;
