/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "primereact/button";
import React, { useEffect, useReducer, useState } from "react";
import FieldCalendar from "../../shared/controls/fieldCalendar";
import InputFile from "../../shared/controls/inputFile";
import formReducer from "../../shared/forms/formReducer";
import useApiFlotillas from "../../api/comercial/useApiFlotillas";
import FormHandler from "../../shared/forms/formHandler";
import CargaMasivaValidacionEsquema from "./CargaMasivaValidacionEsquema";

const CargaMasiva = ({onSave}) => {
    const api = useApiFlotillas();
    const esquemaValidacion = useState(CargaMasivaValidacionEsquema);        

    const defaultDocument = () => ({
        entity: {}
    });

    const[{entity, errors, ...state}, dispatch] = useReducer(
        formReducer, {}, defaultDocument        
    );    

    const formHandler = new FormHandler({
        validationSchema: CargaMasivaValidacionEsquema,
        api: api,
        dispatch
    });

    const update = (value, name) => {
        dispatch({type: 'update', value, name});
    };

    useEffect(() => {
        if(state.submitted){
            formHandler.setValidacionSchema(esquemaValidacion);
            formHandler.validate(entity);
        }
    }, [entity]);

    async function subirDocumento() {
        const { result } = await formHandler.validate(entity);
        if(result){
            if (entity.archivo?.file !== null){
                const formData = new FormData();
                formData.append('file', entity.archivo.file);
                const res = await api.subirPermisoTransitoLocal(formData);
                const params = { vigencia: entity.vigencia, fileUrl: res.fileUrl };
                await api.vigenciaPermisoTransito(params);
                onSave();           
            } 
        }        
    }

    return (
        <>
        <div className="form-container">
            <h5 className="col-12">Carga masiva de permiso de tránsito</h5>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col" style={{width:'50%'}}>Fecha de vencimiento</th>
                        <th scope="col" style={{width:'50%'}}/>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="input">
                            <FieldCalendar name="vigencia" className="small" value={entity.vigencia} 
                                showButtonBar={false} onChange={update} error={errors?.vigencia} required />
                        </td>
                        <td className="input center">
                            <InputFile name="archivo" value={entity.archivo} onChange={update} error={errors?.archivo} required />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="form-footer">
            <Button label="Cargar" type="button" onClick={subirDocumento}/>
        </div>
        </>
    );
};

export default CargaMasiva;