import useServiceFactory from "../serviceFactory";
const apiUrl = `/seguridad/parametros`;
const useApiPerfiles = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, {params}),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, {showLoading: true}),
        guardar: async (params) => service.post(`${apiUrl}`, params),
        actualizar: async (id,params) => service.put(`${apiUrl}/${id}`, params),
        eliminar: async (id) => service.delete(`${apiUrl}/${id}`),
        excel: async (params) => service.get(`${apiUrl}/excel`,
            {
                ...params,
                showLoading: true,
                download: true,
                fileName: `Perfiles.xlsx`
            })
    }
};

export default useApiPerfiles;
