/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import {AutoComplete} from 'primereact/autocomplete'
import * as qs from "qs";
import useApiSource from "../../api/source";
import {useReadOnly} from "../forms/ReadOnlyProvider";

const FieldAutoComplete = ({colMd, colSm, label, name, source, onChange, value, onBlur, error, errors, colLg,
                               placeholder, params, disabled, freeText,
                               isString,
                               className, showNotFound , required}) => {
    const [selectedValue, setSelectedValue] = useState(value);
    const [notFound, setNotFound] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const apiSource = useApiSource();

    const {readOnly} = useReadOnly()??{};
    disabled = disabled ?? readOnly;

    freeText = freeText == null ? false : freeText;
    let timer = null;

    async function searchOption(event) {
        let query = `${event.query}${params == null ? '' : `?${qs.stringify(params)}`}`;
        const options = await apiSource.buscar(source, query);
        if (options != null) {
            setFilteredOptions(options);
        }
    }

    useEffect(() => {
        setNotFound(
            !(value?.id > 0)
        );
        setSelectedValue(
                isString === true ?  {nombre:value??''} :
                {...value, nombre: value?.nombre == null ? '' : value.nombre}
            );
    }, [value]);

    function fireChange(itemName, itemValue) {
        clearTimeout(timer);
        if (onChange != null && value !== itemValue) {
            onChange(isString === true ? itemValue.nombre : itemValue, itemName);
        }
    }

    function checkValueOnBlur() {
        if(selectedValue?.nombre !== ""){
            if (typeof selectedValue === 'string') {
                timer = setTimeout(() => {
                    const value = freeText === true ? {nombre: selectedValue} : null;
                    setSelectedValue(value);
                    fireChange(name, value);
                    setNotFound(true);
                }, 100);
            } else {
                setNotFound(false);
            }
            if (onBlur != null)
                onBlur();
        }
    }

    const onHandleChange = (e)=>{
        if(e.value === ""){
            setSelectedValue(null);
            setNotFound(true);
            onChange(null, name);
        }else{
            setSelectedValue(e.value)
        }
    };

    return (
        <>
            {label &&
            <div className={`form-group d-flex flex-column col-lg-${colLg ?? colMd} col-md-${colMd??'4'} col-sm-${colSm != null ? colSm : '12'}`}>
                {(showNotFound === true && notFound === true) &&
                <label className="autocomplete-not-found-item">No encontrado</label>}
                <label htmlFor={name}>{required ? '*' : ''}{label}</label>    
                <AutoComplete
                    id={name}
                    name={name}
                    value={selectedValue}
                    suggestions={filteredOptions}
                    completeMethod={searchOption}
                    className={`prime-form-control ${(error || errors?.[name]) ? 'error' : ''} ${className ? className : ''}`}
                    field="nombre"
                    onChange={onHandleChange}
                    onSelect={(e) => fireChange(name, e.value)}
                    onBlur={checkValueOnBlur}
                    disabled={disabled}
                />
            </div>
            }
            {!label &&
            <>
                <AutoComplete
                    id={name}
                    name={name}
                    value={selectedValue}
                    suggestions={filteredOptions}
                    completeMethod={searchOption}
                    className={`prime-form-control ${(error || errors?.[name]) ? 'error' : ''} ${className ? className : ''}`}
                    field="nombre"
                    onChange={(e) => setSelectedValue(e.value)}
                    onSelect={(e) => fireChange(name, e.value)}
                    onBlur={checkValueOnBlur}
                    placeholder={placeholder}
                    disabled={disabled}
                />
            </>
            }
        </>
    )
};


export default FieldAutoComplete;

