import React from "react";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import FieldMultiSelect from "../../../shared/controls/fieldMultiSelect";

const FiltroLlantas = ({filtro, onChange}) => {
    return (
        <>
            <FieldTextBox name="idCautin" label="ID Llanta" colMd={2}
                          value={filtro.idCautin} onChange={onChange}/>
            <FieldTextBox name="unidad" label="No. Económico" colMd={2} value={filtro.unidad} onChange={onChange}/>
            <div className={`form-group col-md-2 col-sm-12`}>
                <label>Km Recorridos</label>
                <div className="col-auto">
                    <FieldTextBox name="kmsDe" placeholder="De" keyFilter={'int'}
                                  value={filtro.kmsDe} onChange={onChange}/>
                    <FieldTextBox name="kmsHasta" placeholder="Hasta" keyFilter={'int'}
                                  value={filtro.kmsHasta} onChange={onChange}/>
                </div>
            </div>
            <FieldMultiSelect name="estatus" source="estatus/Llantas" label="Estatus" colMd={2} valueAsId={true}
                              value={filtro.estatus} onChange={onChange}/>
        </>
    )
};

export default FiltroLlantas;
