/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { InputTextarea } from 'primereact/inputtextarea';
import useInputValidation from "./useInputValidation";

const FormTextArea = ({value, parent, onChange,  field, keyFilter, disabled, formDisabled, maxLength, width, required, validators, onValidate, onParentChange, ...props}) => {

    const {validationErrorCss} = useInputValidation({
        value,
        field,
        required,
        validators,
        onValidate,
        parent
    });



    return (
        <>
            <InputTextarea
                {...props}
                id={field}
                keyfilter={keyFilter}
                style={{width: width ?? '100%'}}
                className={`${validationErrorCss}`}
                onChange={(event) => onChange(event.target.value)}
                value={value ?? ''}
                disabled={disabled?? formDisabled}
                maxLength={maxLength ?? 1000}
            />
        </>
    )
}

export default FormTextArea;
