import React from 'react'
import Field from "./Field";

const FormBody = ({values, onChange,  onValidate, formDisabled, onDestroy, submitted, fields}) => {
    return (
        <>
            {fields?.filter(e=>e.props.isVisible == null ? true : e.props.isVisible(values))
                .map((item, index) => (
                    <React.Fragment key={item.props?.field??index}>
                        {item.type  === Field ?
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    gridColumnEnd: `span ${item.props.cols ?? 1}`,
                                    ...item.props?.style
                                }}
                                className={`form-field ${item.props.className ?? ''}`}>
                                <Field {...item.props}
                                       values={values??{}}
                                       onChange={onChange}
                                       formDisabled={formDisabled}
                                       submitted={submitted}
                                       onValidate={(error) => {
                                           if (onValidate != null)
                                            onValidate(item.props?.field, error)
                                       }}
                                       onDestroy={()=> {
                                           if (onDestroy !=null)
                                                onDestroy(item.props?.field)
                                       }}
                                />
                            </div>
                            :
                            <>
                                {/*si es un form container entonces crea el elemento agregándole las propiedades de forms necesarias*/}
                                {/*en caso contrario solo clona el elemento*/}
                                {item.props.isFormContainer ?
                                    React.cloneElement(item, {...item.props,
                                        values,
                                        onChange,
                                        formDisabled,
                                            submitted,
                                        onValidate:onValidate
                                    }
                                    ) :
                                    React.cloneElement(item, {...item.props})
                                }
                            </>
                        }
                    </React.Fragment>

                ))}
        </>
    )
}

export default FormBody;
