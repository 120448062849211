import React, { useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import useApiBanxico from "../../api/administracion/useApiBanxico";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import FiltroTipoDeCambio from "./filtortipodecambio";
import Filtro from "../../shared/componentes/filtro";
import { getSessionState } from "../../hooks/useSessionState";
import agGridHelper from "../../shared/ag-grid-helper";
import useSeguridad from "../../Seguridad/useSeguridad";
import constantes from "../../catalogos/constantes";

const TipoDeCambio = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.tipoDeCambio);
    const filtroKey = "filtroTipoDeCambio";
    const [datos, setDatos] = useState([]);
    const [gridApi] = useState(null);
    const apiService = useApiBanxico();
    const fechaInicio = new Date();
    fechaInicio.setDate(fechaInicio.getDate() - 5);

    const defaultFilter = {
        fechas: [fechaInicio, new Date()]
        , monedaA: null
    };

    async function buscar(filtro, paramsApi) {
        agGridHelper.showLoad(gridApi, paramsApi, true);
        setDatos(await apiService.filtrar(getSessionState(filtroKey)));
        agGridHelper.hideLoad(gridApi, paramsApi, false);
    }
    function exportar() {
        apiService.excel(getSessionState(filtroKey));
    }

    function actualizarTipoDeCambio() {
        apiService.guardarTipoCambio();
    }
    const ButtonsHeader = ({ api }) => {
        return (
            <div className={'ag-grid header-icons'}>
                {!soloLectura &&
                    <ButtonIcon title={'Actualizar Tipo de cambio'} onClick={actualizarTipoDeCambio} iconName={'AiFillDollarCircle'} />
                }
                <ButtonIcon title={'Descargar'} iconName={'BiDownload'} onClick={exportar} />
            </div>
        );
    };
    const editSolicitud = (id) => {

    };
    const onGridReady = async (params) => {

    };
    const RowButtons = ({ data }) => (
        <div className={'ag-grid row-icons'}>
        </div>
    );
    return (
        <>
            <Filtro filtroKey={filtroKey} classButtons="field-iconos" handleSubmit={buscar} defaultValue={defaultFilter}>
                <FiltroTipoDeCambio></FiltroTipoDeCambio>
            </Filtro>
            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    rowSelection={'single'}
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    frameworkComponents={
                        {
                            buttonsHeader: ButtonsHeader,
                            dateFormatter: agGridHelper.DateFormatter,
                            rowButtons: RowButtons
                        }
                    }
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    context={{
                        editSolicitud: editSolicitud
                    }}
                >
                    <AgGridColumn field="fecha" cellRenderer="dateFormatter" headerName="Fecha Aplica" minWidth={100} />
                    <AgGridColumn field="moneda" headerName="Moneda" cellClass={'center'} minWidth={40} />
                    <AgGridColumn field="rate" headerName="Tipo de Cambio" cellClass={'center'} minWidth={80} valueFormatter={agGridHelper.tipoCambioFormatter} />

                    <AgGridColumn maxWidth={70}
                        headerName=""
                        cellRenderer="rowButtons"
                        pinned={'right'}
                        cellClass={'left'}
                        headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>
        </>
    )
}

export default TipoDeCambio;