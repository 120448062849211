import React, {useEffect, useState} from "react";
import {Chips} from "primereact/chips";


const FieldChips = ({
                          colMd, colSm, label, name, onChange, value, required, colLg, className,
                           error, errors, width
                      }) => {

    const [selectedValue, setSelectedValue] = useState([]);


    function onValueChange(value) {

        setSelectedValue(value);

        if (onChange != null) {
            onChange(value, name);
        }
    }

    useEffect(() => {
        let newValue = value == null ? '' : value;
        setSelectedValue(newValue);
    }, [value]);



    return (
        <>
                <div
                    className={`form-group col-lg-${colLg ?? colMd} col-md-${colMd ?? '4'} col-sm-${colSm != null ? colSm : '12'}`}>
                    {required ? '*' : ''}<label htmlFor={name}>{label}</label>
                    <Chips
                        value={selectedValue}
                        onChange={(e) => onValueChange(e.value)}
                        style={{width:width??'100%'}}
                        className={`${className ?? ''} ${(error || errors?.[name]) ? 'error' : ''}`}
                        separator="," />
                    {errors?.[name] && (errors[name].type === 'email' || errors[name].type === 'matches') &&
                        <small id="username2-help" className="p-error p-d-block">{errors[name].message}</small>
                    }
                </div>

        </>
    )
};


export default FieldChips;
