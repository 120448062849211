import useServiceFactory from "../serviceFactory";

const apiUrl = `/operaciones/viajes/monitoreo`;

const useApiViajeMonitoreo = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URL});
    return {
        filtrar: async (params, showLoading) => service.get(`${apiUrl}`, {params, showLoading}),
        unidades: async (params, showLoading) => service.get(`${apiUrl}/unidades`, {params, showLoading}),
        obtener: async (id, params) => service.get(`${apiUrl}/${id}`, {params, showLoading: true}),
        agregarIncidencia: async (id, params) => service.put(`${apiUrl}/${id}`, params),
        eliminarIncidencia: async (incidenciaId) => service.put(`${apiUrl}/eliminarIncidencia/${incidenciaId}`),
        marcarNotificaciones: async (id, params) => service.put(`${apiUrl}/${id}/marcarNotificaciones`, params,
            {showSuccess: false}),
        agregarComentario: async (id, comentarios) => service.post(`${apiUrl}/notificacion/${id}/comentario/${comentarios}`),
        activarTipoNotificacion: async (id, params) => service.put(`${apiUrl}/${id}/activar`, params,
            {showSuccess: false}),
        obtenerGeocercas: async () => service.get(`${apiUrl}/geocercas`),
        actualizarUltimaNotificacion: async(id, comentarioid) => service.post(`${apiUrl}/ultimaNotificacion/${id}/comentario/${comentarioid}`)
    }
};

export default useApiViajeMonitoreo;
