import React from "react";
import useSessionState from "../../hooks/useSessionState";
import constantes from "../../catalogos/constantes";
import { TabPanel, TabView } from "primereact/tabview";
import RendimientosDiesel from "./rendimientosDiesel/rendimientosDiesel";
import Tanques from "./tanque/Tanques";
import { tiposCombustible } from "./DieselConstantes";
import "./diesel.css"
import CargaCombustible from "./Cargas/CargaCombustible";
import useSeguridad from "../../Seguridad/useSeguridad";

const Diesel = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.diesel);
    const [activeIndex, setActiveIndex] = useSessionState('tabDiesel', 0);

    return (
        <>
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e?.index)} className="main-tab-view diesel">
                <TabPanel headerStyle={(seguridad.tieneAcceso(constantes.menus.cargasDiesel) || soloLectura) ? { display: "inline" } : { display: "none" }} header="Cargas">
                    <CargaCombustible soloLectura={soloLectura} />
                </TabPanel>
                <TabPanel headerStyle={seguridad.tieneAcceso(constantes.menus.rendimientosDiesel) ? { display: "inline" } : { display: "none" }} header="Rendimientos">
                    <RendimientosDiesel soloLectura={soloLectura} />
                </TabPanel>
                <TabPanel headerStyle={seguridad.tieneAcceso(constantes.menus.tanqueDiesel) ? { display: "inline" } : { display: "none" }} header="Tanque - Diésel">
                    <Tanques soloLectura={soloLectura}
                        combustibleId={tiposCombustible.diesel}
                    />
                </TabPanel>
                <TabPanel headerStyle={seguridad.tieneAcceso(constantes.menus.tanqueUrea) ? { display: "inline" } : { display: "none" }} header="Tanque - Urea">
                    <Tanques soloLectura={soloLectura}
                        combustibleId={tiposCombustible.urea}
                    />
                </TabPanel>
            </TabView>
        </>
    )
};

export default Diesel;
