import React from "react";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import FieldCalendar from "../../../shared/controls/fieldCalendar";
import produce from "immer";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import constantes from "../../constantes";
import DateFormatter from "../../../shared/ag-grid-helper/dateFormatter";
import FieldMultiSelect from "../../../shared/controls/fieldMultiSelect";
import InputFile from "../../../shared/controls/inputFile";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import ReadOnlyProvider from "../../../shared/forms/ReadOnlyProvider";
import useSeguridad from "../../../Seguridad/useSeguridad";
import moment from "moment";
const Historial = ({ historial, antidoping, onChange, errors, actasAdministrativas, recursosConfiables, soloLectura, permisoHistorialEmpleados, bitacora }) => {
    const seguridad = useSeguridad();
    const permisoHistorial = seguridad.tieneAcceso(constantes.opciones.permisoHistorial);
    const permisoActasAdministrativas = seguridad.tieneAcceso(constantes.opciones.permisosActasAdm);

    function actualizarLista(lista, index, value, propertyName, namelist) {
        const nuevo = produce(lista, draft => {
            draft[index][propertyName] = value;
        }
        );

        onChange(nuevo, namelist);
    }

    function agregarALista(lista, namelist) {
        const nuevo = produce(lista, draft => {
            draft.splice(0, 0, {});
        }
        );
        onChange(nuevo, namelist);
    }

    function eliminarDeLista(lista, index, namelist) {
        const nuevo = produce(lista, draft => {
            draft.splice(index, 1);
        }
        );

        onChange(nuevo, namelist);
    }

    function agregarBitacora() {
        let fecha = new Date();
        fecha.setMonth(fecha.getMonth() + 1);

        const newItem = {
            id: 0,
            descripcion: 'Entrega de Bitacora',
            vigencia: fecha
        }
        const newValue = produce(bitacora, (draft) => {
            draft.push(newItem);
        });

        onChange(newValue, 'empleadoBitacoras');
    };

    return (
        <ReadOnlyProvider defaultReadOnly={soloLectura && !permisoHistorialEmpleados}>
            {(permisoHistorial &&
                <>
                    <h5 id="historia">Historia</h5>
                    <table className="table table-borderless">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col" style={{ width: '15%' }}>ESTATUS</th>
                                <th scope="col" style={{ width: '15%' }}>FECHA</th>
                                <th scope="col" style={{ width: '20%' }}>MOTIVO</th>
                                <th scope="col" style={{ width: '20%' }}>CAUSA</th>
                                <th scope="col" style={{ width: '30%' }}>COMENTARIOS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {historial?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="input">
                                            {item.tipo}
                                        </td>
                                        <td className="input">
                                            <DateFormatter value={item.fecha} />
                                        </td>
                                        <td className="input">
                                            {item.motivo}
                                        </td>
                                        <td className="input">
                                            {item.causa}
                                        </td>
                                        <td className="input">
                                            {item.comentarios}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                </>
            )}
            <h5>Antidoping</h5>
            <table className="table">
                <thead className="thead-light">
                    <tr>
                        <th scope="col" style={{ width: '20%' }}>FECHA</th>
                        <th scope="col" style={{ width: '20%' }}>Tipos de reactivos</th>
                        <th scope="col" style={{ width: '20%' }}>RESULTADO</th>
                        <th scope="col" style={{ width: '20%' }} />
                        <th scope="col" className="center" style={{ width: '10%' }}>ANEXO</th>
                        <th className={'ag-grid header-icons'} style={{ width: '10%' }}>
                            {(!soloLectura || permisoHistorialEmpleados) &&
                                <ButtonIcon title={'Agregar Ruta'} iconName={'SiAddthis'} className="grid-action"
                                    onClick={() => agregarALista(antidoping, "antidoping")} />
                            }
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {antidoping?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="input">
                                    <FieldCalendar name="fecha"
                                        className="small"
                                        value={item.fecha}
                                        showButtonBar={false}
                                        errors={errors[`antidoping[${index}]`]}
                                        onChange={(value, pName) => actualizarLista(antidoping, index, value, pName, "antidoping")} />
                                </td>
                                <td className="input">
                                    <FieldDropDown name="tiposDeReactivosId" source="tiposDeReactivos"
                                        value={item.tiposDeReactivosId}
                                        errors={errors[`antidoping[${index}]`]}
                                        onChange={(value, pName) => actualizarLista(antidoping, index, value, pName, "antidoping")} />
                                </td>
                                <td className="input">
                                    <FieldDropDown name="resultado" source="resultadosAntidoping"
                                        value={item.resultado}
                                        errors={errors[`antidoping[${index}]`]}
                                        onChange={(value, pName) => actualizarLista(antidoping, index, value, pName, "antidoping")} />
                                </td>
                                <td className="input">
                                    {item.resultado?.id === constantes.resultado.positivo &&
                                        <FieldMultiSelect name="examenPositivo"
                                            source="tiposAntidoping"
                                            value={item.examenPositivo}
                                            valueAsId={true}
                                            maxSelectedLabels={2}
                                            errors={errors[`antidoping[${index}]`]}
                                            onChange={(value, pName) => actualizarLista(antidoping, index, value, pName, "antidoping")} />
                                    }
                                </td>
                                <td className="input center">
                                    <InputFile name="anexo" value={item.anexo} errors={errors[`antidoping[${index}]`]} disabled={soloLectura && !permisoHistorialEmpleados}
                                        onChange={(value, pName) => actualizarLista(antidoping, index, value, pName, "antidoping")} />
                                </td>
                                <td className="input">
                                    {(!soloLectura || permisoHistorialEmpleados) &&
                                        <ButtonIcon title={'Eliminar'}
                                            onClick={() => eliminarDeLista(antidoping, index, "antidoping")}
                                            iconName={'RiDeleteBinLine'} />
                                    }
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>


            <h5>Recurso Confiable</h5>
            <table className="table">
                <thead className="thead-light">
                    <tr>
                        <th scope="col" style={{ width: '40%' }}>TIPO</th>
                        <th scope="col" style={{ width: '35%' }}>VIGENCIA</th>
                        <th scope="col" className="center" style={{ width: '15%' }}>ARCHIVO</th>
                        <th className={'ag-grid header-icons'} style={{ width: '10%' }}>
                            {(!soloLectura || permisoHistorialEmpleados) &&
                                <ButtonIcon title={'Agregar Ruta'} iconName={'SiAddthis'} className="grid-action"
                                    onClick={() => agregarALista(recursosConfiables, "recursosConfiables")} />
                            }
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {recursosConfiables?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="input">
                                    <FieldDropDown name="tipo" source="general/Tipo Recurso Confiable"
                                        value={item.tipo}
                                        errors={errors[`recursosConfiables[${index}]`]}
                                        onChange={(value, pName) => actualizarLista(recursosConfiables, index, value, pName, "recursosConfiables")} />
                                </td>
                                <td className="input">
                                    <FieldCalendar name="fecha"
                                        className="small"
                                        value={item.fecha}
                                        showButtonBar={false}
                                        errors={errors[`recursosConfiables[${index}]`]}
                                        onChange={(value, pName) => actualizarLista(recursosConfiables, index, value, pName, "recursosConfiables")} />
                                </td>
                                <td className="input center">
                                    <InputFile name="anexo" value={item.anexo} errors={errors[`recursosConfiables[${index}]`]} disabled={soloLectura && !permisoHistorialEmpleados}
                                        onChange={(value, pName) => actualizarLista(recursosConfiables, index, value, pName, "recursosConfiables")} />
                                </td>
                                <td className="input">
                                    {(!soloLectura || permisoHistorialEmpleados) &&
                                        <ButtonIcon title={'Eliminar'}
                                            onClick={() => eliminarDeLista(recursosConfiables, index, "recursosConfiables")}
                                            iconName={'RiDeleteBinLine'} />
                                    }
                                </td>
                            </tr>
                        )
                    }



                    )}

                </tbody>
            </table>

            {(permisoActasAdministrativas &&
                <>
                    <h5>Actas Administrativas</h5>
                    <table className="table">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col" style={{ width: '20%' }}>FECHA</th>
                                <th scope="col" style={{ width: '25%' }}>MOTIVO</th>
                                <th scope="col" style={{ width: '35%' }}>COMENTARIO </th>
                                <th scope="col" className="center" style={{ width: '10%' }}>ANEXO</th>
                                <th className={'ag-grid header-icons'} style={{ width: '10%' }}>
                                    {(!soloLectura || permisoHistorialEmpleados) &&
                                        <ButtonIcon title={'Agregar Ruta'} iconName={'SiAddthis'} className="grid-action"
                                            onClick={() => agregarALista(actasAdministrativas, "actasAdministrativas")} />
                                    }
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {actasAdministrativas?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="input">
                                            <FieldCalendar name="fecha"
                                                className="small"
                                                value={item.fecha}
                                                showButtonBar={false}
                                                errors={errors[`actasAdministrativas[${index}]`]}
                                                onChange={(value, pName) => actualizarLista(actasAdministrativas, index, value, pName, "actasAdministrativas")} />
                                        </td>
                                        <td className="input">
                                            <FieldDropDown name="motivo" source="general/Motivo acta administrativa"
                                                value={item.motivo}
                                                errors={errors[`actasAdministrativas[${index}]`]}
                                                onChange={(value, pName) => actualizarLista(actasAdministrativas, index, value, pName, "actasAdministrativas")} />
                                        </td>
                                        <td className="input">
                                            <FieldTextBox name="comentarios" errors={errors[`actasAdministrativas[${index}]`]} value={item.comentarios}
                                                onChange={(value, pName) => actualizarLista(actasAdministrativas, index, value, pName, "actasAdministrativas")} />
                                        </td>
                                        <td className="input center">
                                            <InputFile name="anexo" value={item.anexo} errors={errors[`actasAdministrativas[${index}]`]} disabled={soloLectura && !permisoHistorialEmpleados}
                                                onChange={(value, pName) => actualizarLista(actasAdministrativas, index, value, pName, "actasAdministrativas")} />
                                        </td>
                                        <td className="input">
                                            {(!soloLectura || permisoHistorialEmpleados) &&
                                                <ButtonIcon title={'Eliminar'}
                                                    onClick={() => eliminarDeLista(actasAdministrativas, index, "actasAdministrativas")}
                                                    iconName={'RiDeleteBinLine'} />
                                            }
                                        </td>
                                    </tr>
                                )
                            }



                            )}

                        </tbody>
                    </table>
                </>
            )}

            <h5>Bitacora de Viaje</h5>
            <table className="table">
                <thead className="thead-light">
                    <tr>
                        <th>Descipcion</th>
                        <th>Vigencia</th>
                        <th className={'ag-grid header-icons'} style={{ width: '10%' }}>
                            {(!soloLectura || permisoHistorialEmpleados) &&
                                <ButtonIcon title={'Agregar Bitacora'} iconName={'SiAddthis'} className="grid-action"
                                    onClick={agregarBitacora} />
                            }
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {bitacora?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item?.descripcion}</td>
                                <td>{moment(item?.vigencia).format('DD/MM/yyyy')}</td>
                                <td className="input">
                                    {(!soloLectura || permisoHistorialEmpleados) && item?.id === 0 &&
                                        <ButtonIcon title={'Eliminar'}
                                            onClick={() => eliminarDeLista(bitacora, index, "empleadoBitacoras")}
                                            iconName={'RiDeleteBinLine'} />
                                    }
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </ReadOnlyProvider>
    )
};

export default Historial;