/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import * as numeral from "numeral";
import {ESTATUS_DIARIO, estatusMap, motivoMap} from "./StatusButton";
import moment from "moment";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import useApiEstatusDiarios from "../../api/reportes/useEstatusDiariosApi";

const DiaEstatus = ({ dia, filtro, onClick }) => {
    const api = useApiEstatusDiarios()

    const esHoy = moment(dia.fecha).isSame(moment(), "day");

    console.log(esHoy, dia.fecha)

    const handleClick = (event, campo, valor) => {
        onClick(dia, campo, valor);
        event.stopPropagation()
    };

    const getStyle = (estatusId, color) => {
        return filtro.estatusId === estatusId && filtro.fecha === dia.fecha
            ? {
                    border: `3px solid ${color}`,
                    marginBottom:'0'
              }
            : {
                  borderLeft: `3px solid ${color}`,
                  marginBottom:'4px'
              };
    };

    const motivoSeleccionado = (motivoId)=>{
        return filtro.motivoId === motivoId && filtro.fecha === dia.fecha;
    }

    const descargarReporte = async (event)=>{
        event.stopPropagation()
        await api.excelUnidadesDisponibles()
    }


    return (
        <>
            {Array.from(estatusMap.entries()).map(([id, value]) => (
                <div
                    style={getStyle(id, value.color)}
                    className={`estatus-count`}
                    onClick={(e) => handleClick(e,'estatusId', id)}
                    key={id}
                >
                    <span className="cantidad">{numeral(dia[`${value.clase}Porcentaje`]).format("0%")}</span>

                    <span className="porcentaje">{dia[value.clase]}</span>

                    {
                        esHoy && id === ESTATUS_DIARIO.disponible &&
                        <div className={'exportar-disponibles'}>
                            <ButtonIcon
                            title={'Eliminar Cambios'}
                            style={{width: '10px', height: '10px', color:'green'}}
                            enviarEventoEnClick={true}
                            iconName={'FaFileExcel'} onClick={descargarReporte}/>
                        </div>
                    }

                    <div
                        style={{
                            display: "block",
                            gridTemplateColumns: "35% 35% 35%",
                            fontSize: "9px",
                            width: "60%",
                            marginLeft: "25%",
                        }}
                    >
                        {Array.from(motivoMap.entries()).map(([motivoId, valueEstatus]) => (
                            <>
                                {valueEstatus.estatus === id && (
                                    <div
                                        onClick={(e)=>handleClick(e,'motivoId',motivoId)}
                                        title={valueEstatus.descripcion}
                                        className={`motivos ${motivoSeleccionado(motivoId) ? 'seleccionado':''}`}
                                    >
                                        <div>
                                            <span>
                                                <strong style={{ color: "gray" }}>{valueEstatus.name}</strong>
                                            </span>
                                        </div>
                                        <div style={{ position: "relative" }}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    top: "-7px",
                                                    right: "0px",
                                                }}
                                            >
                                                <strong style={{ color: "gray" }}>{dia[valueEstatus.clase]}</strong>
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </>
                        ))}
                    </div>
                </div>
            ))}
        </>
    );
};

export default DiaEstatus;
