import _ from 'lodash';
import {immerable, produce} from "immer"
import * as numeral from "numeral";



class Ruta {
    [immerable] = true;

    constructor(props) {
        this.key = Date.now() + Math.random().toString().slice(2);
        this.id = 0;
        this.comentario = '';
        this.unidadDeMedida = null;
        this.accesorios = [];
        this.viajesPorSemana = 0;
        this.facturacionUsd = false;
        this.facturacionMxn = 0;
        this.montoFacturacionUsd = 0;
        this._facturacionModificada = props?.facturacionMxn > 0;
        this.tipoDeCambio = 0;
        this.totalAccesorios = 0;
        this.precioSugeridoPorUnidad = 20;
        Object.assign(this, props);
        this.viaje = new Viaje(props?.viaje);
        this.costo = new Costo(props?.costo);
        this.viajeRegreso = new ViajeRegreso(props?.viajeRegreso);

        if (props != null && (props.accesorios?.length ?? 0) > 0) {
            this.accesorios = props.accesorios.map(e => new Accesorio(e));
            this.actualizarTotalAccesorios();
        }
    }

    get FacturacionMxn() {
        return this.facturacionMxn
    }

    set FacturacionMxn(value) {
        this.facturacionMxn = value;
        this._facturacionModificada = value > 0;
    }

    actualizarTotalAccesorios() {
        this.totalAccesorios = _.sumBy(this.accesorios, 'total');
    }

    agregarAccesorio() {
        this.accesorios.push(new Accesorio());
    }

    get viajesPorMes() {
        return this.viajesPorSemana * 4;
    }

    eliminarAccesorio(index) {
        this.accesorios.splice(index, 1);
        this.actualizarTotalAccesorios();
    }

    actualizarAccesorio(index, propiedad, valor) {
        return produce(this, draft => {
            draft.accesorios[index][propiedad] = valor;
            draft.actualizarTotalAccesorios();
        });
    }

    actualizarCosto(resumenRuta, quitarCasetasExcluidas = false) {
        return produce(this, draft => {
            draft.costo.longitud = Math.round(resumenRuta.longitud * this.multiplicadorTipoViaje);
            draft.actualizarCasetasExcluidas(quitarCasetasExcluidas);
            draft.costo = draft.costo.actulizarCuotaYDiesel(resumenRuta.costoCaseta * this.multiplicadorTipoViaje, draft.costo.longitud);
            draft.actualizarFacturacion();
        });
    }

    actualizarFacturacion() {
        if (this._facturacionModificada === true) {
            return;
        }
        this.facturacionMxn = this.costo.longitud * this.precioSugeridoPorUnidad;
    }

    get multiplicadorTipoViaje() {
        return this.viaje.esViajeRedondo ? 2 : 1;
    }

    get utilidad() {
        return this.facturacionMxn + this.totalAccesorios - this.costo.costoRuta;
    }

    get porcentajeMargen() {
        return this.facturacionMxn === 0 ? 0 : this.utilidad / this.facturacionMxn;
    }

    get margen() {
        return numeral(this.porcentajeMargen).format('% 0.00');
    }

    get utilidadPorSemana() {
        return this.viajesPorSemana * this.utilidad;
    }

    get utilidadPorMes() {
        return this.viajesPorMes * this.utilidad;
    }

    get precioPorKilometro() {
        return this.costo.longitud === 0 ? 0 : this.facturacionMxn / this.costo.longitud;
    }

    get MontoFacturacionUsd() {
        return this.montoFacturacionUsd;
        /*
        if (this.tipoDeCambio === 0)
            return null;
        return this.facturacionUsd === true ? (this.facturacionMxn / this.tipoDeCambio) : 1;*/
    }

    set MontoFacturacionUsd(value) {
        this.montoFacturacionUsd = value;
        this._facturacionModificada = value > 0;
    }

    setValoresPorDefault(valoresDefault) {
        return produce(this, draft => {
            draft.costo.gastos = valoresDefault.gastos.map(e => new Gasto(e));
            draft.costo.rendimiento = valoresDefault.costo.rendimiento;
            draft.tipoDeCambio = valoresDefault.tipoDeCambio;
            draft.costo.costoDiesel = valoresDefault.costo.costoDiesel;
            draft.precioSugeridoPorUnidad = valoresDefault.precioSugeridoPorUnidad;
        });
    }

    cambiarMontoFacturacionUsd() {
        if (this.tipoDeCambio === 0 || this.tipoDeCambio == null)
            this.montoFacturacionUsd  = 0;
        else {
            this.montoFacturacionUsd = this.facturacionUsd === true ? (this.facturacionMxn / this.tipoDeCambio) : 1;
        }
    }

    cambiarMontoFacturacionMxn() {
        if (this.tipoDeCambio === 0 || this.tipoDeCambio == null)
            this.facturacionMxn = 0;
        else {
            this.facturacionMxn = this.facturacionUsd === true ? (this.montoFacturacionUsd * this.tipoDeCambio) : 1;
        }
    }

    cambioDeTipoViaje() {
        return produce(this, draft => {
            draft.costo = draft.costo.cambioDeTipoViaje(this.viaje.esViajeRedondo);
            draft.actualizarFacturacion();
        });
    }

    actualizarViajeRegreso() {
        return produce(this, draft => {
            draft.viajeRegreso.actualizar(draft.viaje.destino, draft.viaje.origen);
        });
    }

    actualizarCasetasExcluidas(quitarCasetasExcluidas) {
        if(quitarCasetasExcluidas){
            this.costo.casetasExcluidas = "";
        }
    }

}

class ViajeRegreso {
    [immerable] = true;

    constructor(props) {
        this.origen = props?.origen;
        this.destino = props?.destino;
        this.cambiados = props?.cambiados;
        this.rutaSeleccionada = props?.rutaSeleccionada;
    }

    actualizar(origen, destino) {
        this.origen = origen;
        this.destino = destino;
    }

}

class Viaje {
    [immerable] = true;

    constructor(props) {

        this.tipoViajeId = 42;
        this.estadoOrigen = null;
        this.estadoDestino = null;
        this.origen = null;
        this.destino = null;
        this.tipoTarifa = null;
        this.tipoUnidad = null;
        this.puntosIntermedios = [];
        Object.assign(this, props);
    }

    get esViajeRedondo() {
        return this.tipoViajeId === 42;
    }

    get tipoViajeNombre() {
        return this.esViajeRedondo ? 'Redondo' : 'Sencillo';
    }

    //regresa si el viaje es válido, esto es cuando ya el usuario ya llenó todos los campos
    get viajeValido() {
        return this.estadoOrigen != null &&
            this.estadoDestino != null &&
            this.origen != null &&
            this.destino != null &&
            this.tipoTarifa != null &&
            this.tipoUnidad != null &&
            this.puntosIntermedios.every(pi => pi.estado && pi.ciudad);
    }

    get ids(){
        const datos = {
            estadoOrigenId: this.estadoOrigen.id,
            estadoDestinoId: this.estadoDestino.id,
            origenId: this.origen.id,
            destinoId: this.destino.id,
            tipoTarifaId: this.tipoTarifa?.id > 0 ? this.tipoTarifa.id : 1
        };

        this.puntosIntermedios.forEach((pi, index)=> {
            datos[`estadoIntermedio${index + 1}`] = pi.estado.id;
            datos[`ciudadIntermedia${index + 1}`] = pi.ciudad.id;
        });

        return datos;
    }
}

class Costo {

    [immerable] = true;

    constructor(props) {
        this.longitud = 0;
        this.rendimiento = 0;
        this.costoDiesel = 0;
        this.gastos = [];
        this.costoRuta = 0;
        this.diasEnRuta = 1;
        Object.assign(this, props);
        if (props != null && props.gastos.length > 0) {
            this.gastos = props.gastos.map(e => new Gasto(e));
        }
    }

    get diesel() {
        return this.rendimiento !== 0 ? this.longitud / this.rendimiento : 0;
    };

    get totalDiesel() {
        return this.diesel * this.costoDiesel;
    }

    get costoPorKilometro() {
        return this.longitud === 0 ? null : this.costoRuta / this.longitud;
    };

    get costoCaseta() {
        return this.gastos.find(e => e.esAutopista)?.costo ?? 0;
    }

    agregarGasto() {
        return produce(this, draft => {
            draft.gastos.push(new Gasto({longitud: this.longitud}));
        });
    };

    eliminarGasto(index) {
        return produce(this, draft => {
            draft.gastos.splice(index, 1);
            draft.costoRuta = _.sumBy(draft.gastos, 'costo');
        });
    };

    actualizarGasto(index, propiedad, valor) {
        return produce(this, draft => {
            draft.gastos[index][propiedad] = valor;
            draft.gastos[index].actualizado = true;
            draft.gastos[index].calcularCosto(draft.longitud, draft.diasEnRuta);
            draft.costoRuta = _.sumBy(draft.gastos, 'costo');
        });
    }

    actualizar(valor, propiedad) {
        return produce(this, draft => {
            draft[propiedad] = valor;
            draft.gastos[0].costo = draft.totalDiesel;
            //obtiene los gastos actualizando el costo total cuando este no ha sido modificado
            for (let gasto of draft.gastos) {
                gasto.calcularCosto(draft.longitud, draft.diasEnRuta);
            }

            draft.costoRuta = _.sumBy(draft.gastos, 'costo');
        });
    }



    actulizarCuotaYDiesel(cuota, longitud) {
        return produce(this, draft => {
            draft.gastos = draft.gastos.map(e => new Gasto(e.esAutopista? {
                ...e,
                costo: cuota,
                costoPorKilometro: cuota / longitud,
                actualizado: true
            } : e.calcularCosto(longitud, draft.diasEnRuta)));
            draft.gastos[0].costo = draft.totalDiesel;
            draft.costoRuta = _.sumBy(draft.gastos, 'costo');
        });
    };

    actualizarCuota(cuota, casetasExcluidas) {
        return produce(this, draft => {
            let caseta = draft.gastos.find(e => e.esAutopista);
            caseta.costo = cuota;
            draft.costoRuta = _.sumBy(draft.gastos, 'costo');
            draft.casetasExcluidas = casetasExcluidas;
        });
    }

    cambioDeTipoViaje(esViajeRedondo) {
        return produce(this, draft => {
            const multiplicador = (esViajeRedondo ? 2 : 0.5);
            draft.longitud = Math.round(draft.longitud * multiplicador);
            draft.gastos[0].costo = draft.totalDiesel;
            for (let gasto of draft.gastos) {
                if (gasto.esAutopista) {
                    gasto.costo = gasto.costo * multiplicador;
                }
                gasto.calcularCosto(draft.longitud, draft.diasEnRuta);
            }


            draft.costoRuta = _.sumBy(draft.gastos, 'costo');

        });
    }
}

class Gasto {
    [immerable] = true;
    #tipoCostoCaseta = 'Autopistas';
    #tipoCostoDiesel = 'Total Diesel';

    constructor(props) {

        this.id = 0;
        this.tipoCostoId = null;
        this.costo = 0;
        this.tipoCostoNombre = null;
        this.costoPorKilometro = 0;
        this.costoPorKilometroDefault = 0;
        this.costoPorDiaRuta = 0;
        Object.assign(this, props);
    }

    calcularCosto(longitud, diasEnRuta) {
        if (this.actualizado === true || this.costoPorKilometroDefault === 0) {
            if (this.costoPorDiaRuta > 0 && this.actualizado !== true) {
                this.costo = (diasEnRuta ?? 0) * this.costoPorDiaRuta;
            }

            this.costoPorKilometro = longitud > 0 ? this.costo / longitud : this.costoPorKilometro;
            return this;
        }
        this.costo = longitud * this.costoPorKilometro;

        return this;
    };


    get esOtroGasto() {
        return this.tipoCostoId == null || this.tipoCostoId === 0;
    };

    get esDiesel() {
        return this.tipoCostoNombre === this.#tipoCostoDiesel;
    }

    get esAutopista() {
        return this.tipoCostoNombre === this.#tipoCostoCaseta;
    };
}

class Accesorio {
    [immerable] = true;

    constructor(props) {

        this.id = 0;
        this.accesorioId = null;
        this.cantidad = 0;
        this.precio = 0;
        Object.assign(this, props);
    }

    get total() {
        return this.cantidad * this.precio;
    }

}

export {
    Ruta,
    Viaje,
    Costo,
    Gasto,
    Accesorio
}
