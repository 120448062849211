import React from "react";
import useSessionState from "../../hooks/useSessionState";
import {TabPanel, TabView} from "primereact/tabview";
import ViajeEstaciones from "./ViajeEstaciones";
import ConfiguracionEstaciones from "./ConfiguracionEstaciones";

const Estaciones = () => {
    const [activeIndex, setActiveIndex] = useSessionState('tabEstaciones', 0);
    return (
        <>
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e?.index)} className="main-tab-view diesel">
                <TabPanel header="Viajes">
                    <ViajeEstaciones/>
                </TabPanel>
                <TabPanel header="Configuracion">
                    <ConfiguracionEstaciones/>
                </TabPanel>
            </TabView>
        </>
    );
};

export default Estaciones;
