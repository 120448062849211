/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";
import {DirectionsRenderer} from "@react-google-maps/api";
import {useControlMap} from "../ControlMapProvider";

const MapsDirectionsRenderer = ({places})=>{

    const [directions, setDirections] = useState()
    const [error, setError] = useState()

    const {setLegsMapeo} = useControlMap() ?? {setLegsMapeo:[]}

    const renderDirections =()=>{

        const waypoints = places.map(p =>({
            location: {lat: p.latitude, lng:p.longitude},
            stopover: true
        }))
        const origin = waypoints.shift().location;
        const destination = waypoints.pop().location;

        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
            {
                origin: origin,
                destination: destination,
                travelMode: window.google.maps.TravelMode.DRIVING,
                waypoints: waypoints
            },
            (result, status) => {

                setLegsMapeo(result.routes[0].legs)
                if (status === window.google.maps.DirectionsStatus.OK) {
                    setDirections(result)
                    setError(null)
                } else {
                    setError(error)
                }
            }
        );

    }

    useEffect(() => {
        if (window.google?.maps != null && places?.length > 0){
            renderDirections()
        }
    }, [places]);



    if (error) {
        return <h1>{error}</h1>;
    }

    if (places?.length ===0){
        return null
    }

    return (
        <DirectionsRenderer directions={directions}
    />)
}
export default MapsDirectionsRenderer;