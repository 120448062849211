import React from "react";
import FieldAutoComplete from "../../../shared/controls/fieldAutoComplete";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import FieldCalendarRange from "../../../shared/controls/fieldCalendarRange";
import FieldMultiSelect from "../../../shared/controls/fieldMultiSelect";
import FieldDropDown from "../../../shared/controls/fieldDropDown";

const FiltroExpeditados = ({filtro, onChange}) => {
    return (
        <>
            <FieldTextBox name="id" label="Id" colMd={1} keyFilter={'int'}
                          value={filtro.id} onChange={onChange}/>
            <FieldAutoComplete name="cliente" source="clientes" label="Cliente" colMd={2}
                               value={filtro.cliente} onChange={onChange}/>
            <div className="form-group col-md-4 col-sm-12">
                <label>Ruta</label>
                <div className="col-auto">
                    <FieldAutoComplete name="rutaOrigen" source="destinos" colMd={2} freeText={true} placeholder="Desde"
                                       value={filtro.rutaOrigen} onChange={onChange}/>
                    <FieldAutoComplete name="rutaDestino" source="destinos" colMd={2} freeText={true} placeholder="Hasta"
                                       value={filtro.rutaDestino} onChange={onChange}/>
                </div>
            </div>
            <FieldDropDown name="tipoUnidad" source="tiposUnidad" label="Tipo de Unidad" colMd={1}
                           value={filtro.tipoUnidad} onChange={onChange}/>
            <FieldCalendarRange name="fechas" label="Fechas" colMd={2}
                                value={filtro.fechas} onChange={onChange}/>
            <FieldMultiSelect name="estatus" source="estatusExpeditados" label="Estatus" colMd={2}
                              value={filtro.estatus} onChange={onChange}/>
        </>
    )
};

export default FiltroExpeditados;
