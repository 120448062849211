/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import agGridHelper from "../../../shared/ag-grid-helper";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import { getSessionState } from "../../../hooks/useSessionState";
import FieldCalendarRange from "../../../shared/controls/fieldCalendarRange";
import moment from "moment";
import Filtro from "../../../shared/componentes/filtro";
import useApiViajesFreightVerify from "../../../api/operaciones/useApiViajesFreightVerify";
import alerts from "../../../shared/alerts";
import constantes from "../../../catalogos/constantes";
import FieldTextBox from "../../../shared/controls/fieldTextBox";

const FreightVerify = () => {
    const filtroKey = 'FiltroFreightVerify';
    const [datos, setDatos] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const api = useApiViajesFreightVerify();

    useEffect(() => {
        const interval = setInterval(() => {
            buscar().then();
        }, 300000);    
        return () => clearInterval(interval);
    }, []);
    
    const onGridReady = async (params) => {
        setGridApi(params.api);
    };

    async function buscar(filtro, paramsApi) {
        filtro = filtro ?? getSessionState(filtroKey);
        agGridHelper.showLoad(gridApi, paramsApi, true);
        var result = await api.filtrar({ ...filtro })
        setDatos(result);
        agGridHelper.hideLoad(gridApi, paramsApi, false);
    }

    function exportar() {
        api.excel(getSessionState(filtroKey));
    }

    function integrar() {
        api.integrar();
    }

    async function transmitir(id, finalizarTransmision)
    {        


        if(finalizarTransmision) {
            var dias = await alerts.inputNumero('Dias a retransmitir:',1,30);
            if (dias) {
                await api.transmitir(id,dias);
            }        
        } else {
            await api.transmitir(id,0);
        }
        
        await buscar();
    }

    const ButtonsHeader = () => {
        return (
            <div className="ag-grid header-icons">
                <ButtonIcon title="Integrar" onClick={integrar} iconName={'RiRefreshLine'} />
                <ButtonIcon title="Descargar" onClick={exportar} iconName="BiDownload" />
            </div>
        );
    };

    const RowButtons = ({ data, context }) => (
        <div className={'ag-grid row-icons'}>
            <ButtonIcon className={'btn btn-outline btn-block'} title={data.finalizarTransmision ? 'Iniciar Transmision' : 'Finalizar Transmision'} iconName={'GiCarWheel'} spin={data.transmitiendo} onClick={() => context.transmitir(data.id, data.finalizarTransmision)} />
        </div>
    );

    const DateCellRenderer = ({ value }) => (
        <div>         
            {value && moment(value).format('DD/MM/YYYY HH:mm a' )}
        </div>
    );

    return (
        <>
            <Filtro filtroKey={filtroKey} handleSubmit={buscar} classButtons="field-iconos btn-group"
            fechasRangoDias={7} fechasDiasInicio={7}  modulo={constantes.modulos.freightVerify}
            >
                <Filtros />
            </Filtro>

            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    frameworkComponents={
                        {
                            buttonsHeader: ButtonsHeader,
                            rowButtons: RowButtons,
                            dateCellRenderer: DateCellRenderer,
                            goToDetail: agGridHelper.GoToDetailFormatter
                        }
                    }
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    rowHeight={35}
                    context={{
                        transmitir: transmitir
                    }}
                >
                    <AgGridColumn field="cartaPorte" headerName="CARTA PORTE" cellStyle={{ textAlign: "left" }} minWidth={50} maxWidth={100} />
                    <AgGridColumn field="codigoRuta" headerName="CODIGO RUTA" cellStyle={{ textAlign: "left" }} minWidth={50} maxWidth={120} />
                    <AgGridColumn field="idViaje" headerName="ID VIAJE" cellStyle={{ textAlign: "left" }} minWidth={50} maxWidth={100} />
                    <AgGridColumn field="unidad" headerName="UNIDAD" cellStyle={{ textAlign: "left" }} minWidth={50} maxWidth={80} />
                    <AgGridColumn field="caja" headerName="CAJA" cellStyle={{ textAlign: "left" }} minWidth={50} maxWidth={80} />
                    <AgGridColumn field="eventosIntegrados" headerName="ENVIOS" cellStyle={{ textAlign: "left" }}  minWidth={50} maxWidth={120} />
                    <AgGridColumn field="eventosFallidos" headerName="ERRORES" cellStyle={{ textAlign: "left" }} minWidth={50} maxWidth={80} />
                    <AgGridColumn field="fechaInicio" headerName="FECHA INICIO" cellRenderer="dateCellRenderer"  />
                    <AgGridColumn field="fechaFin" headerName="FECHA FIN" cellRenderer="dateCellRenderer"  />
                    <AgGridColumn field="fechaInicioRetransmitir"  headerName="RETRANSMISION" cellRenderer="dateCellRenderer" />
                    <AgGridColumn field="diasTotalesRetransmision"  headerName="DIAS +" cellStyle={{ textAlign: "left" }}  maxWidth={100}/>
                    <AgGridColumn maxWidth={70}
                        headerName="VER"
                          cellRenderer="rowButtons"
                        pinned={'right'}
                        cellClass={'left'}
                        headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>
        </>
    )
};

const Filtros = ({ filtro, onChange }) => {
    return (
        <>
            <FieldCalendarRange name="fechas" label="Fechas" colLg={2} colMd={3} value={filtro.fechas}
                onChange={onChange} />
            <FieldTextBox name="cartaPorte" label="Carta Porte" colLg={1} colMd={3}
                          value={filtro.cartaPorte} onChange={onChange}/>
            <FieldTextBox name="idViaje" label="ID Viaje" colLg={1} colMd={3}
                          value={filtro.idViaje} onChange={onChange}/>
        </>
    )
};

export default FreightVerify;
