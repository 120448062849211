/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import useApiInventariosCaja from "../../api/taller/useApiInventariosCaja";
import useForm from "../../shared/forms/useForm";
import Field from "../../shared/forms/Field";
import FormDropDown from "../../shared/forms/FormFields/FormDropDown";
import FormCheckBox from "../../shared/forms/FormFields/FormCheckBox";
import {Button} from "primereact/button";
import Form from "../../shared/forms/Form";
import {Dialog} from "primereact/dialog";

const InventarioCajaDetalle = ({ id, onCancel, onSaved, disabled }) => {
    const api = useApiInventariosCaja();
    const visible = id != null;

    const {
        values,
        setValues,
        onChange,
    } = useForm();

    useEffect(() => {
        load(id).then();
    }, [id]);

    const load = async (id) => {
        if (id == null)
            return
        const result = await api.obtener(id);
        setValues(result);
    }

    const onSave = async () => {
        await api.guardar(values);
        await onSaved();
    }

    return (
        <>
            <Dialog header={`Actualizar ${values?.numeroEconomico}`} visible={visible} style={{ width: '400px' }}
                    onHide={onCancel}>
                <Form values={values}
                      onChange={onChange}
                      formDisabled={disabled}
                      className={`form-container`}
                      columns={2}
                >
                    <Field label="Patio" field={"patioId"} source={"patios"} render={FormDropDown} showClear={true}  cols={2}/>
                    <Field label="Cargada" field={"cargada"} render={FormCheckBox} />
                    <Field label="En Taller" field={"enTaller"} render={FormCheckBox} />
                </Form>

                <div className="form-footer">
                        <Button label="Guardar" className="p-button-outlined" type="button" onClick={onSave} />
                </div>
            </Dialog>
        </>
    )
}

export default InventarioCajaDetalle;
