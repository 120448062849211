/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import ButtonIcon from "../../controls/ButtonIcon";

const AddButton = ({onAdd, formDisabled}) => {

    return (
        <>
            {!formDisabled &&
                <ButtonIcon title={'Agregar'} iconName={'SiAddthis'}
                            onClick={onAdd}
                />
            }
        </>
    )
}

export default AddButton;