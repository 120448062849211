import React, { useState } from 'react';
import FiltroCotizaciones from "./filtrosCotizaciones";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import agGridHelper from "../../../shared/ag-grid-helper";
import alerts from "../../../shared/alerts";
import { Link } from "react-router-dom";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import { useNavigate } from "react-router-dom";
import useApiCotizaciones from "../../../api/comercial/cotizaciones";
import constantes from "../../constantes";
import Filtro from "../../../shared/componentes/filtro";
import { getSessionState } from "../../../hooks/useSessionState";

const Cotizaciones = ({ soloLectura }) => {
    const filtroKey = 'filtrosCotizacion';
    const [datos, setDatos] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const history = useNavigate();
    const apiCotizaciones = useApiCotizaciones();
    const fechaInicio = new Date();
    fechaInicio.setDate(fechaInicio.getDate() - 14);
    const defaultFilter = { estatus: [{ id: constantes.estatusCotizaciones.enProceso }], fechas: [fechaInicio, new Date()] };

    const obtenerFiltro = (filtro) => {
        filtro = filtro ?? obtenerFiltro(getSessionState(filtroKey));
        return {
            ...filtro,
            noCotizacion: filtro.noCotizacion,
            cliente: filtro.cliente?.nombre,
            tipoUnidadId: filtro.tipoUnidad?.id ?? 0,
            rutaOrigen: filtro.rutaOrigen?.nombre,
            rutaDestino: filtro.rutaDestino?.nombre,
            estatus: filtro.estatus?.map(e => e.id)
        }
    };

    async function buscar(filtro, paramsApi) {
        agGridHelper.showLoad(gridApi, paramsApi, true);
        setDatos(await apiCotizaciones.filtrar(obtenerFiltro(filtro)));
        agGridHelper.hideLoad(gridApi, paramsApi, false);
    }

    const onGridReady = async (params) => {
        setGridApi(params.api);
    };

    function clonar(api) {
        let row = api.getSelectedRows().pop();
        if (row == null) {
            alerts.seleccionarCotizacion();
            return;
        }

        history(`/comercial/nueva_cotizacion/true/${row.id}`);
    }

    function exportar() {
        apiCotizaciones.excel(obtenerFiltro(getSessionState(filtroKey)));
    }

    const ButtonsHeader = ({ api }) => {
        return (
            <div className={'ag-grid header-icons'}>
                <ButtonIcon title={'Descargar'} onClick={exportar} iconName={'BiDownload'} />
                {!soloLectura &&
                    <>
                        <ButtonIcon title="Clonar" onClick={() => clonar(api)} iconName={'IoDuplicateOutline'} />
                        <Link title="Agregar Cotización" to={'/comercial/nueva_cotizacion'}>
                            <ButtonIcon title="Agregar Cotización" className={'grid-action'} iconName={'MdAddBox'} />
                        </Link>
                    </>
                }
            </div>
        );
    };

    return (
        <>
            <Filtro filtroKey={filtroKey} handleSubmit={buscar} defaultValue={defaultFilter} gridApi={gridApi}>
                <FiltroCotizaciones />
            </Filtro>

            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    rowSelection={'single'}
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    frameworkComponents={
                        {
                            goToDetail: agGridHelper.GoToDetailFormatter,
                            buttonsHeader: ButtonsHeader,
                            dateFormatter: agGridHelper.DateFormatter
                        }
                    }
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                >
                    <AgGridColumn maxWidth={40} checkboxSelection={true} />
                    <AgGridColumn field="noCotizacion" headerName="NO. COTIZACIÓN" cellClass={'center'} />
                    <AgGridColumn field="cliente" headerName="CLIENTE" minWidth={150} />
                    <AgGridColumn field="fecha" headerName="FECHA" cellRenderer="dateFormatter" cellClass={'center'} />
                    <AgGridColumn field="totalRutas" headerName="RUTAS" cellClass={'center'} />
                    <AgGridColumn field="estatus" headerName="ESTATUS" cellClass={'center'} />
                    <AgGridColumn maxWidth={120} headerName="VER" cellRenderer="goToDetail" pinned={'right'}
                        path='/comercial/editar_cotizacion' headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>
        </>
    )
};

export default Cotizaciones;


