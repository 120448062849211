import {ShowRouteIcon} from "../../../../icons/SeguimientoYMonitoreo/SeguimientoIcons";
import React from "react";
import {useIndicadores} from "./IndicadoresProvider";
import alerts from "../../../../shared/alerts";
import {useSeguimientoMonitoreo} from "../SeguimientoMonitoreoProvider";

const MenuMostrarRuta = () => {

    const {mostrarRuta,mostrarViajeEnMapa, ruta, ocultarRuta} = useSeguimientoMonitoreo()

    const {
        overlayClickRef,
        viajeActual,
        setViajeRutaId
    } = useIndicadores()

    const handleMostrarRuta = async (esCaja, ocultar) => {
        if (ocultar){
            ocultarRuta()
            overlayClickRef.current.hide()
            setViajeRutaId(null)
            return;
        }


        mostrarViajeEnMapa(viajeActual)
        let unidadId = null;
        if (esCaja){
            if (viajeActual.cajaId == null || viajeActual.cajaId === 0){
                alerts.aviso('El viaje no tiene caja asignada o la caja es externa')
            }
            unidadId = viajeActual.cajaId;
        }else {
            if (viajeActual.unidadId == null || viajeActual.unidadId === 0){
                alerts.aviso('El viaje no tiene unidad asignada')
            }
            unidadId = viajeActual.unidadId;
        }
        await mostrarRuta(unidadId, viajeActual.viajeId)
        setViajeRutaId(viajeActual.viajeId)
        overlayClickRef.current.hide()
    }

    return (
        <div className={'seg-localizar-mapa'}>
            <span className={'bold'}>
                Rutas
            </span>
            <span className={'seg-link'} onClick={()=>handleMostrarRuta(false, viajeActual.unidadId === ruta.unidadId)}>
                <ShowRouteIcon/> {viajeActual.unidadId === ruta.unidadId ? 'Ocultar' : 'Mostrar' } ruta de la unidad
            </span>
            <span className={'seg-link'} onClick={()=>handleMostrarRuta(true, viajeActual.cajaId === ruta.unidadId)}>
                <ShowRouteIcon/> {viajeActual.cajaId === ruta.unidadId ? 'Ocultar' : 'Mostrar' } ruta de la caja
            </span>
        </div>
    )
}
export default MenuMostrarRuta;