/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer } from "react";
import useApiConceptosCobro from "../../api/catalogos/useApiConceptosCobro";
import { Button } from "primereact/button";
import FormHandler from "../../shared/forms/formHandler";
import ConceptosCobroValidacionEsquema from "./ConceptosCobroValidacionEsquema";
import formReducer from "../../shared/forms/formReducer";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import FieldCheckBox from "../../shared/controls/fieldCheckBox";
import ReadOnlyProvider from "../../shared/forms/ReadOnlyProvider";

const DetalleConceptosCobro = ({ conceptoId, onSave, soloLectura }) => {

    const defaultEntity = () => ({ entity: { id: 0, activo: true } });

    const [{ entity, errors }, dispatch] = useReducer(
        formReducer, {}, defaultEntity
    );

    const api = useApiConceptosCobro();

    const formHandler = new FormHandler({
        validationSchema: ConceptosCobroValidacionEsquema,
        api,
        dispatch
    });

    useEffect(() => {
        async function cargar() {
            const concepto = await api.obtener(conceptoId);

            dispatch({ type: "update", value: concepto });
        }

        if (conceptoId > 0) {
            cargar().then();
        }
    }, []);

    const actualizar = (value, propertyName) => {
        dispatch({
            type: 'update',
            value: value,
            name: propertyName
        });
    };

    const guardar = async () => {
        await formHandler.save(entity);

        if (formHandler.valdation.result) {
            onSave();
        }
    };

    return (
        <>
            <div className="form-container full-height">
                <h5>Conceptos de Cobro</h5>
                <ReadOnlyProvider defaultReadOnly={soloLectura}>
                    <div className="form-row">
                        <FieldTextBox name="nombre" label="Nombre" colMd={12} required value={entity.nombre}
                            onChange={actualizar} errors={errors} />
                        <FieldTextBox name="claveSAT" label="Clavel en SAT" colMd={12} required value={entity.claveSAT}
                            onChange={actualizar} errors={errors} />
                    </div>
                    <div className="form-row">
                        <FieldCheckBox name="activo" label="Activo" value={entity.activo} onChange={actualizar} />
                    </div>
                </ReadOnlyProvider>
                <div className="form-footer">
                    {!soloLectura &&
                        <Button label="Guardar" type="button" onClick={guardar} />
                    }
                </div>
            </div>
        </>
    )
};

export default DetalleConceptosCobro;