import React from "react";
import {convertirMinutosATexto, utcToString} from "../../../../shared/format";

const TooltipGenerico = ({tooltip}) => {
    return (<>
        <span className={'t-label'}>{tooltip?.datos?.label}</span>
        <span className={'t-value'}>{tooltip?.datos?.value}</span>
        {tooltip?.datos?.indicador?.eta &&
            <>
                <div style={{ marginTop: "10px"}}>
                    <span className={'t-label'}>Eta:</span>
                    <span className={'t-value'}>{utcToString(tooltip?.datos?.indicador?.eta)} hrs</span>
                </div>

                { tooltip?.datos?.indicador?.minutosRetraso > 0 &&
                    <>
                        <span className={'t-label'}>Retraso:</span>
                        <span className={'t-value'}>{convertirMinutosATexto(tooltip?.datos?.indicador?.minutosRetraso)}</span>
                    </>
                }
                <div>
                    <span className={'t-label'}>Distancia:</span>
                    <span className={'t-value'}>{tooltip?.datos?.indicador?.distanciaKm} Km</span>
                </div>
            </>
        }
        {tooltip?.datos?.label2 &&
            <>
                <div>
                    <span className={'t-label'}>{tooltip?.datos?.label2}</span>
                    <span className={'t-value'}>{tooltip?.datos?.value2}</span>
                </div>
            </>
        }

    </>)
}
export default TooltipGenerico;