import useServiceFactory from "../serviceFactory";
const apiUrl = `/operaciones/controlPatios`;

const useApiPatios = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, { params }),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, { showLoading: true }),
        nuevoPaseSalida: async (params) => service.post(`${apiUrl}/nuevoPaseSalida`, params),
        acceso: async (params) => service.post(`${apiUrl}/acceso`, params),
        salida: async (params) => service.post(`${apiUrl}/salida`, params),
        actualizar: async (id, params) => service.put(`${apiUrl}/${id}`, params),
        actualizarLlantas: async (id) => service.patch(`${apiUrl}/${id}/actualizaLlantas`),
        unidadAveriasyLLantas: async (unidadId) => service.get(`/operaciones/flotillas/${unidadId}/averiasyLlantas`, { showLoading: true }),
        obtenerSalidaPorUnidad: async (unidadId) => service.get(`${apiUrl}/salida/unidad/${unidadId}`, { showLoading: true }),
        obtenerSelloFiscal: async (cajaId) => service.get(`${apiUrl}/caja/${cajaId}/ultimaEntrada/selloFiscal`),
        obtenerSelloFiscalExterno: async (cajaId) => service.get(`${apiUrl}/caja/${cajaId}/ultimaEntradaExterno/selloFiscal`),
        eliminarSalidasProgramadas: async (ids) => service.delete(`${apiUrl}/${ids}`),
        cancelarSalidasProgramadas: async (ids, comentarios) => service.patch(`${apiUrl}/${ids}/${comentarios}`),
        obtenerInformacion: async (unidadId) => service.get(`${apiUrl}/obtenerInformacion/unidad/${unidadId}`, { showLoading: true }),
        guardarFotoAccesorio: async (id, checklistId, params) => service.put(`${apiUrl}/${id}/accesorioGuardarFoto/${checklistId}`, params, { showSuccess: false }),
        excel: async (params, esAcceso) => service.get(`${apiUrl}/excel${esAcceso ? 'Accesos' : 'Salidas'}`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: esAcceso ? "ControlPatios_Accesos.xlsx" : "ControlPatios_Salidas.xlsx"
            }),
        guardarBitacora: async (id, params) => service.put(`${apiUrl}/guardarBitacora/${id}`, params, { showSuccess: false }),
        eliminarBitacora: async (id) => service.delete(`${apiUrl}/eliminarBitacora/${id}`)
    }
};

export default useApiPatios;
