import React, {useState} from "react";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import FieldTextArea from "../../../shared/controls/fieldTextArea";
import useApiFlotillas from "../../../api/comercial/useApiFlotillas";
import produce from "immer";
import FieldCalendar from "../../../shared/controls/fieldCalendar";


const BajaUnidad = ({unidad, onBaja}) => {
    const [visibleModal, setVisibleModal] = useState(false);
    const [baja, setBaja] = useState({comentarios:''});
    const [submitted, setSubmitted] = useState(false);
    const api = useApiFlotillas();

    async function actualizar(value, propertyName) {
        setBaja(produce(baja, draft => {
            draft[propertyName] = value
        }));

    }

    const darDebaja = async () => {
        setSubmitted(true);
        if ((baja.tipo?.id ?? 0) === 0 || baja.comentarios.length === 0 || baja.fecha == null) {
            return;
        }
        await api.baja(unidad.id, baja);
        setSubmitted(false);
        setVisibleModal(false);
        onBaja();
    };

    const renderFooter = () => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => setVisibleModal(false)} className="p-button-text"/>
                <Button label="Confirmar" icon="pi pi-check" onClick={darDebaja} autoFocus/>
            </div>
        );
    };

    return (
        <>
            <Dialog header="¿Desea dar de baja la unidad?" visible={visibleModal} style={{width: '30vw'}}
                    footer={renderFooter('displayBasic')} onHide={() => setVisibleModal(false)}>
                <div className="form-row">
                    <FieldDropDown name="tipo" label="Motivo de Baja" colMd={12} source="general/Motivo de baja unidades"
                                   error={(baja.tipo?.id ?? 0) === 0 && submitted}
                                   scrollHeight={150}
                                   filter
                                   value={baja.tipo}
                                   onChange={actualizar}/>

                    <FieldCalendar name="fecha" label="Fecha" className="xs" value={baja.fecha}
                                   showButtonBar={false} error={!baja.fecha && submitted}
                                   onChange={actualizar}/>

                    <FieldTextArea name="comentarios" label="Comentarios" colMd={12} rows={11}
                                   error={baja.comentarios.length === 0 && submitted}
                                   value={baja.comentarios} onChange={actualizar}/>
                </div>
            </Dialog>

            <Button label="Dar de Baja" className="p-button-outlined p-button-danger" type="button"
                    onClick={() => setVisibleModal(true)}/>
        </>
    )
};

export default BajaUnidad;
