import React from 'react'

const CountStateContext = React.createContext({show:0, toast:{counter:0}});
const CountDispatchContext = React.createContext(null);

function countReducer(state, action) {
    switch (action.type) {
        case 'show': {
            return {
                ...state,
                show: state.show + 1
            }
        }
        case 'hide': {
            return {
                ...state,
                show: state.show > 0 ?  state.show - 1 : 0
            }
        }
        case 'toast' :{
            return{
                ...state,
                toast:{
                    counter: (isNaN(state.counter) ? 0 : state.counter)  + 1,
                    message: action.message,
                    severity: action.severity,


                }
            }
        }
        case 'sessionExpired':{
            return{
                ...state,
                showSessionExpired : true
            }
        }
        case 'hideSessionExpired' : {
            return {...state,
                showSessionExpired : false
            }
        }
        case 'setDatosPath' : {
            return {...state,
                datosPath : action.datosPath
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function MainLoadingProvider({children}) {
    const [state, dispatch] = React.useReducer(countReducer, {show: 0, toast:{counter:0}});
    return (
        <CountStateContext.Provider value={state}>
            <CountDispatchContext.Provider value={dispatch}>
                {children}
            </CountDispatchContext.Provider>
        </CountStateContext.Provider>
    )
}

function useLoadingState() {
    const context = React.useContext(CountStateContext);
    if (context === undefined) {
        throw new Error('useCountState must be used within a CountProvider')
    }
    return context
}

function useLoadingDispatch() {
    const context = React.useContext(CountDispatchContext);
    if (context === undefined) {
        throw new Error('useCountDispatch must be used within a CountProvider')
    }
    return context
}



export {MainLoadingProvider, useLoadingState, useLoadingDispatch}
