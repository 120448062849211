import * as yup from "yup";

const guardar = yup.object().shape({
    idCautin: yup.string().required(),
    marca: yup.object({
        id: yup.number().required().min(1)
    }),
    estado: yup.object({
        id: yup.number().required().min(1)
    }),
    tamano: yup.object({
        id: yup.number().required().min(1)
    }),
    modelo: yup.string().required(),
    costo: yup.number().required(),
    tipoLLanta: yup.object({
        id: yup.number().required().min(1)
    }),
    dot: yup.string().required(),
    profundidad: yup.number().required()
});

const asignar = yup.object().shape({
    idCautin: yup.string().required(),
    modelo: yup.string().required(),
    marca: yup.object({
        id: yup.number().required().min(1)
    }),
    estado: yup.object({
        id: yup.number().required().min(1)
    }),
    tamano: yup.object({
        id: yup.number().required().min(1)
    }),
    unidad: yup.object({
        id: yup.number().required().min(1)
    }),
    posicion: yup.object({
        id: yup.number().required().min(1)
    }),
    tipoLLanta: yup.object({
        id: yup.number().required().min(1)
    }),
    kilometros: yup.number().required()
});

const actualizar = yup.object().shape({
    kilometros: yup.number().required(),
    profundidad: yup.number().required()
});

const llantaValidacionEsquema = {
    guardar,
    asignar,
    actualizar
};

export default llantaValidacionEsquema;



