import useServiceFactory from "../serviceFactory";

const apiUrl = `/administracion/liquidaciones`;
const apiUrlCajaChica = `/solicitudes`;

const useApiLiquidaciones = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    const serviceCajaChica = useServiceFactory({ uri: process.env.REACT_APP_CAJA_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, { params, showLoading: true }),
        obtener: async (empleadoId,params) => service.get(`${apiUrl}/empleado/${empleadoId}`,{
            params,
            showLoading: true           
        }),
        liquidar: async (formData) => service.post(`${apiUrl}`, formData, { showSuccess: true }),
        liquidacionAdministrativa: async (ids) => service.post(`${apiUrl}/${ids}`, { showLoading: true }),
        semanaLaboral: async () => service.get(`${apiUrl}/semanalaboral`, { showLoading: false }),
        excel: async (params) => service.get(`${apiUrl}/excel`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `Pre-Liquidacion.xlsx`
            }),
        comprobar: async (formData) => serviceCajaChica.post(`${apiUrlCajaChica}/comprobar`, formData),
        comprobarGastos: async (formData) => service.post(`${apiUrl}/comprobarGastos`, formData)        
    }
};

export default useApiLiquidaciones;
