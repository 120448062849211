import React from "react";
import FieldRadioButtonList from "../../../../shared/controls/fieldRadioButtonList";
import FormDropDown from "../../../../shared/controls/fieldDropDown";
import {produce} from "immer"
import {Button} from "primereact/button";
import FieldDropDown from "../../../../shared/controls/fieldDropDown";


const FormaViaje = ({value, name, onChange, errors, editable, puedeModificarCosto}) => {
    const camposOrigenDestino = ["estadoOrigen", "estadoDestino", "origen", "destino"];
    const origenDestino = {};
    camposOrigenDestino.forEach(c => origenDestino[c] = value[c]);

    const editableTarifa = editable || puedeModificarCosto;

    function actualizar(itemValue, itemName) {
        if (onChange != null) {
            const cambioDeTipoDeViaje = itemName === 'tipoViajeId';
            const cambioDePuntosIntermedios = itemName === 'puntosIntermedios';
            const cambioClasificacion = (itemName === 'clasificacionCargaId' || itemName === 'clasificacionRutaId')

            let nuevoValor = produce(value, (d) => {
                d[itemName] = itemValue;

                if (itemName === "estadoOrigen") {
                    d.origen = null;
                }

                if (itemName === "estadoDestino") {
                    d.destino = null;
                }
            });

            onChange(nuevoValor, name, cambioDeTipoDeViaje, cambioDePuntosIntermedios, cambioClasificacion);
        }
    }

    function agregarPuntoIntermedio() {
        const id = value.puntosIntermedios.length === 0 ? 1 : Math.max(...value.puntosIntermedios.map(m => m.id)) + 1;
        const nuevoValor = [...value.puntosIntermedios, {id}];

        actualizar(nuevoValor, "puntosIntermedios");
    }

    function quitarPuntoIntermedio(id) {
        const nuevoValor = value.puntosIntermedios.filter(m => m.id !== id);

        actualizar(nuevoValor, "puntosIntermedios");
    }

    function actualizarPuntoIntermedio(itemValue, itemName, index) {
        const nuevoValor = produce(value.puntosIntermedios, (draft) => {
            draft[index][itemName] = itemValue;

            if (itemName === "estado") {
                draft[index]["ciudad"] = null;
            }
        });

        actualizar(nuevoValor, "puntosIntermedios");
    }

    return (
        <>
            <div className="form-row">
                <div className={"col-md-6"}>
                    <FieldRadioButtonList source="tiposViaje" name="tipoViajeId"
                                          disabled={!editable}
                                          value={value.tipoViajeId} onChange={actualizar}>
                    </FieldRadioButtonList>
                </div>

                <div className={"col-md-6"}>
                    <FieldRadioButtonList source="clasificacionesCarga" name="clasificacionCargaId"
                                          disabled={!editableTarifa} errors={errors}
                                          value={value.clasificacionCargaId} onChange={actualizar}>
                    </FieldRadioButtonList>
                </div>

                <div className={"col-md-6"} style={{marginBottom:'10px'}}>
                    <FieldRadioButtonList source="clasificacionesRuta" name="clasificacionRutaId"
                                          disabled={!editableTarifa} errors={errors}
                                          value={value.clasificacionRutaId} onChange={actualizar}>
                    </FieldRadioButtonList>
                </div>
                <div className={"col-md-6"} >
                </div>

                <FormDropDown name="estadoOrigen" label="Estado Origen" source="estados" colMd={6} filter
                              disabled={!editableTarifa}
                              value={value.estadoOrigen} onChange={actualizar} error={errors?.estadoOrigen}/>
                <FormDropDown name="estadoDestino" label="Estado Destino" source="estados" colMd={6} filter
                              disabled={!editableTarifa}
                              value={value.estadoDestino} onChange={actualizar} error={errors?.estadoDestino}/>
                <FieldDropDown name="origen" label="Ciudad Origen" source="ciudadesSct" params={value.estadoOrigen}
                               filter
                               colMd={6} error={errors?.origen} disabled={value.estadoOrigen == null || !editableTarifa}
                               value={value.origen} onChange={actualizar}/>
                <FieldDropDown name="destino" label="Ciudad Destino" source="ciudadesSct" filter
                               params={value.estadoDestino}
                               colMd={6} error={errors?.destino} disabled={value.estadoDestino == null || !editableTarifa}
                               value={value.destino} onChange={actualizar}/>

                <FormDropDown name="tipoTarifa" label="Tipo Tarifa" source="tiposTarifa" colMd={6}
                              disabled={!editableTarifa}
                              error={errors?.tipoTarifa}
                              value={value.tipoTarifa} onChange={actualizar}/>
                <FormDropDown name="tipoUnidad" label="Tipo Unidad" source="tiposUnidad" colMd={6}
                              disabled={!editableTarifa}
                              error={errors?.tipoUnidad}
                              value={value.tipoUnidad} onChange={actualizar}/>
            </div>

            <div className="form-row">
                {(value.puntosIntermedios.length < 2 && editable) &&
                    <div className="col-12">
                        <Button type="button" label="Agregar Punto Intermedio" className="p-button-text pl-0"
                                icon="pi pi-plus-circle" onClick={agregarPuntoIntermedio}/>
                    </div>
                }

                {value.puntosIntermedios.map((pi, index) => {
                    return (
                        <React.Fragment key={pi.id}>
                            <div className="col-12">
                                <i className="pi pi-minus-circle text-danger cursor-pointer mt-2"
                                   onClick={() => quitarPuntoIntermedio(pi.id)}/>
                                <label className="text-primary ml-1">Punto Intermedio {index + 1}</label>
                            </div>

                            <FormDropDown name="estado" label="Estado" value={pi.estado} source="estados" colMd={6}
                                          filter error={errors != null && errors[`puntosIntermedios[${index}]`]?.estado}
                                          onChange={(value, name) => actualizarPuntoIntermedio(value, name, index)}/>

                            <FieldDropDown name="ciudad" label="Ciudad" source="ciudadesSct" params={pi.estado} filter
                                           colMd={6} disabled={pi.estado == null} value={pi.ciudad}
                                           onChange={(value, name) => actualizarPuntoIntermedio(value, name, index)}
                                           error={errors != null && errors[`puntosIntermedios[${index}]`]?.ciudad}/>
                        </React.Fragment>
                    )
                })
                }
            </div>
        </>
    );
};

export default FormaViaje;

