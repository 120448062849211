import useServiceFactory from './serviceFactory'

const useApiSeguridad = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URL});
    return {
        validar: async (params) => service.post(`/seguridad/validar`, params, {showLoading: false}),
        actualizarPassword: async (params) => service.post(`/seguridad/actualizarPassword`, params, {showLoading: false}),
        cambiarEmpresa: async (empresaId)=> service.get(`/seguridad/empresa/${empresaId}`,{showLoading: true}),
        refreshToken: async()=> service.get(`/seguridad`, {showLoading: false})
    }
};

export default useApiSeguridad;
