function mergeObject(properties, destinationObject, value) {
    if (properties.length === 1) {
        destinationObject[properties[0]] = value;
        return;
    }

    const propertyName = properties.shift();
    let childObject = destinationObject[propertyName];
    if (childObject == null){
        childObject = {};
        destinationObject[propertyName] = childObject;
    }

    if (propertyName.includes('[')) {
        destinationObject[propertyName.substring(0, propertyName.indexOf('['))] = {
            type: 'itemArray',
            message: 'error en alguno de los elementos'
        };
    }

    mergeObject(properties, childObject,  value);
}

class YupValidator {
    constructor(schema){
        this.schema = schema;
        this.submitted = false;
        this.errors = {};
    }

    validate(values) {
        let self = this;
        this.submitted = true;
        self.errors={};
        return new Promise((resolve, reject) => {
            this.schema.validate(values, {abortEarly: false})
                .then(() => {
                    resolve(true)
                })
                .catch(function (err) {
                    for (let er of err.inner){
                        mergeObject(er.path.split('.'), self.errors, {type:er.type, message:er.message} );
                    }
                    resolve(false);
                });
        });
    }
}

export default YupValidator;