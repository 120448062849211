import React  from "react";
import {Checkbox} from "primereact/checkbox";
import ButtonIcon from "../controls/ButtonIcon";


export const ReadOnlyCheckBoxRenderer = ({value}) => {

    return (
        <Checkbox checked={value} className={'disabled'}/>
    );
};

export const EditIconRenderer = ({data, context}) => (
    <div className={'ag-grid row-icons'}>
        <ButtonIcon title={'Editar'} iconName={'FaRegEdit'} onClick={() => context.onEdit(data.id)}/>
    </div>
);

export const GridEditHeader = ({context}) => {
    return (
        <div className={'ag-grid header-icons'}>
            <ButtonIcon title="Agregar" className={'grid-action'} iconName={'MdAddBox'} onClick={()=>context.onAdd()}/>
        </div>
    );
};

export const VerIconRenderer = ({data, context}) => (
    <button className="btn btn-link btn-sm" title={'Ver'} onClick={()=>context.onEdit(data.id)}>Ver</button>
);
