import React, {useState, createContext, useContext} from "react";

const pagerContext = createContext(undefined);

const PagerProvider = ({children, pageSize}) => {
    const [pager, setPager] = useState({pageSize: pageSize ?? 50, page:1});

    const onChangePage = (newPage)=>{
        setPager({...pager, page:newPage});
    }

    const setTotalRows = (totalRows)=>{
        setPager({...pager, totalRows, totalPages : Math.ceil(totalRows / pageSize)});
    }

    const reset = () =>{
        setPager({...pager, page:1});
    }

    return (
        <pagerContext.Provider value={{...pager, onChangePage, setTotalRows, reset}}>
            {children}
        </pagerContext.Provider>
    );
}

const withPager = (Component, pageSize) => ({ ...props }) => (
    <PagerProvider pageSize={pageSize ?? 200}>
        <Component {...props} />
    </PagerProvider>
);

export default withPager;

export const usePager = () => {
    return useContext(pagerContext);
}
