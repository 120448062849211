import React, { useEffect, useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import { useReadOnly } from "../forms/ReadOnlyProvider";

const FieldInputNumber = ({
    colMd,
    colSm,
    label,
    name,
    value,
    onChange,
    fractionDigits,
    error,
    errors,
    disabled,
    required,
    className,
    onBlur,
    min,
    max,
    useGrouping,
    placeholder,
}) => {
    const [selectedValue, setSelectedValue] = useState(value);

    const { readOnly } = useReadOnly() ?? {};
    disabled = disabled ?? readOnly;

    useEffect(() => {
        setSelectedValue(value);
    }, [value]);

    function fireChange(event) {
        setSelectedValue(event.value);
        if (onChange != null) {
            onChange(event.value, name);
        }
    }

    function fireBlur(event) {
        if (onBlur != null) {
            onBlur(event.value, name);
        }
    }

    const handleFocus = (event) => event.target.select();

    return (
        <>
            {label && (
                <div className={`form-group col-md-${colMd} col-sm-${colSm != null ? colSm : "12"}`}>
                    {required ? "*" : ""}
                    <label htmlFor={name}>{label}</label>
                    <InputNumber
                        id={name}
                        className={`form-control ${disabled ? "disabled" : ""}`}
                        inputClassName={`form-control ${className ?? ""} right ${
                            error || errors?.[name] ? "error" : ""
                        } ${disabled ? "disabled" : ""}`}
                        onChange={fireChange}
                        onBlur={fireBlur}
                        value={selectedValue}
                        onFocus={handleFocus}
                        disabled={disabled}
                        locale="es-MX"
                        minFractionDigits={fractionDigits}
                        maxFractionDigits={fractionDigits}
                        useGrouping={useGrouping}
                        placeholder={placeholder}
                    />
                </div>
            )}
            {!label && (
                <InputNumber
                    id={name}
                    className={`form-control ${disabled ? "disabled" : ""}`}
                    inputClassName={`form-control ${className ?? ""} right ${error || errors?.[name] ? "error" : ""} ${
                        disabled ? "disabled" : ""
                    }`}
                    onChange={fireChange}
                    onBlur={fireBlur}
                    onFocus={handleFocus}
                    value={selectedValue}
                    disabled={disabled}
                    locale="es-MX"
                    minFractionDigits={fractionDigits}
                    maxFractionDigits={fractionDigits}
                    min={min}
                    max={max}
                    useGrouping={useGrouping}
                    placeholder={placeholder}
                />
            )}
        </>
    );
};

export default FieldInputNumber;
