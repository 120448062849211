import * as yup from "yup";

const EstatusDiariosValidacionEsquema = yup.object().shape({
    fechaCompromiso: yup.mixed()
        .when("noValidar",{
            is:false,
            then: yup.mixed().required()
        }),
    motivo:yup.mixed()
        .when("noValidar",{
            is:false,
            then: yup.mixed().required()
        }),
    fechaCompromisoOperador: yup.mixed()
        .when("fechaCompromisoSinOperadorRequerida",{
            is:true,
            then: yup.mixed().required()
        }),
});

export default EstatusDiariosValidacionEsquema;
