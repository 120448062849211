import { useState } from "react";
import Field from "../../../shared/forms/Field";
import Form from "../../../shared/forms/Form";
import FormDropDown from "../../../shared/forms/FormFields/FormDropDown";
import FormTextBox from "../../../shared/forms/FormFields/FormTextBox";
import useApiLlantas from "../../../api/taller/useApiLlantas";
import useForm from "../../../shared/forms/useForm";
import { Button } from "primereact/button";
import { produce } from "immer";
import alerts from "../../../shared/alerts";
import { Dialog } from "primereact/dialog";
import FieldInputNumber from "../../../shared/controls/fieldInputNumber";
import { date } from "yup";

const RotacionLlanta = ({ onSave }) => {
    const [verRotacion, setVerRotacion] = useState(false);
    const api = useApiLlantas();
    const initialValues = {
        llantas: [],
        auxiliar: []
    };
    const [verDialog, setVerDialog] = useState(false);
    const [llanta, setLlanta] = useState({});

    const {
        values: unidad,
        setValues,
        onChange,
        onValidate,
        isValid
    } = useForm(initialValues);

    const cambiarUnidad = async (id) => {
        let result = await api.llantas(id);
        result.auxiliar = result.llantas;
        setValues(result);
        setVerRotacion(true);
    };

    const allowDrop = (ev) => {
        ev.preventDefault();
    };

    const drag = (ev) => {
        ev.dataTransfer.setData("text", ev.target.id);
    };

    const drop = (ev, item) => {
        ev.preventDefault();
        var data = ev.dataTransfer.getData("text");
        if (data === undefined)
            return;

        ev.target.appendChild(document.getElementById(data));

        const newValue = produce(unidad, (draft) => {
            let row = draft.llantas.find(l => l.id === item.id);
            let target = draft.auxiliar.find(p => p.id === parseInt(data));
            row.posicion = target.posicion;
            row.actualizado = true;
            row.idAux = target.id;
            target.idAux = item.id;
        });

        setValues(newValue);
        setLlanta(item);
        setVerDialog(true);
    };

    const returnDrop = (ev) => {
        ev.preventDefault();
        var data = ev.dataTransfer.getData("text");
        if (date === undefined)
            return;

        ev.target.appendChild(document.getElementById(data));

        const newValue = produce(unidad, (draft) => {
            let row = draft.llantas.find(l => l.idAux === parseInt(data));
            row.posicion = row.posicionActual;
            row.actualizado = false;
            row.idAux = 0;
        });

        setValues(newValue);
    };

    const guardar = async () => {
        if (!isValid)
            return;

        const grupo = unidad?.llantas?.reduce((result, currentValue) => {
            (result[currentValue['posicion']] = result[currentValue['posicion']] || []).push(
                currentValue
            );
            return result;
        }, {});

        let count = 0;
        unidad.llantas.forEach(e => {
            if (grupo[e.posicion].length > 1) {
                count++;
            }
        });

        if (count > 0) {
            alerts.aviso('Hay llantas en una misma posicion.');
            return;
        }

        const actualizados = unidad.llantas.filter(l => l.actualizado);

        if (actualizados.length === 0) {
            alerts.aviso('Es necesario cambiar de posción al menos 2 llantas.');
            return;
        }

        await api.rotar(unidad.id, actualizados);
        onSave(false);
    };

    const actualizar = async () => {
        if ((llanta?.profundidad ?? '') === '' || (llanta?.kilometros ?? '') === '')
            return;

        const newValue = produce(unidad, (draft) => {
            let row = draft.llantas.find(l => l.id === llanta.id);
            row.profundidad = llanta.profundidad;
            row.kilometros = llanta.kilometros;
        });

        setValues(newValue);

        setVerDialog(false);
    };

    const update = (value, propertyName) => {
        const newValue = produce(llanta, draft => {
            draft[propertyName] = value
        });

        setLlanta(newValue);
    }

    const renderFooterModal = () => {
        return (
            <div>
                <Button label="Guardar" onClick={actualizar} autoFocus />
            </div>
        );
    };

    return (
        <>
            <div className="form-container full-height" style={{ paddingLeft: "1px" }}>
                <Form columns={2} values={unidad} onChange={onChange} onValidate={onValidate}>
                    <h5 style={{ gridColumnEnd: 'span 2' }}>
                        Rotacion de llantas
                    </h5>
                    <Field field={"id"} label={"Unidad"} source="unidades" required={true} cols={1}
                        render={({ onParentChange, parent, ...props }) =>
                            <FormDropDown {...props} onChange={(unidadId) => cambiarUnidad(unidadId)} />
                        } />
                    {verRotacion && <>
                        <Field field={"tipoUnidad"} label={"Tipo de Unidad"} cols={1} render={FormTextBox} disabled={true} />
                        <Field field={"auxiliar"} cols={1} render={({ value, ...props }) =>
                            <>
                                {value?.map((item, index) => (
                                    <div className="div-rotacion" key={index} id={item.id} onDrop={(event) => drop(event, item)} onDragOver={allowDrop}>
                                        <label>Nueva posición <span className="nueva-posicion">{item.posicion}</span></label>
                                    </div>
                                ))}
                            </>
                        } />
                        <Field field={"llantas"} cols={1} render={({ value, ...props }) =>
                            <div className="div-padre" style={{ height: ((value.length * 75) + value.length) }} onDrop={returnDrop} onDragOver={allowDrop}>
                                {value?.map((item, index) => (
                                    <div className="div-droppable" key={index} id={`0${item.id}`} draggable={true} onDragStart={drag}>
                                        <label style={{ width: '150px', cursor: 'move' }}><span className="posicion">{item.idCautin}</span></label>
                                        <label style={{ width: '200px' }}>Marca: <span className="posicion">{item.marca?.nombre}</span></label>
                                        <label>Posición actual <span className="posicion">{item.posicionActual}</span></label>
                                    </div>
                                ))}
                            </div>
                        } />
                        <Field cols={2} render={(...props) =>
                            <div className="d-flex justify-content-center mt-1">
                                <img src={`../../../img/unidades/TipoId${unidad?.tipoUnidadId}_.png`}
                                    height={150} alt="posicion" />
                            </div>
                        } />
                    </>
                    }
                </Form>
            </div>
            <div className="form-footer">
                {verRotacion &&
                    <Button label="Guardar" className="p-button-outlined" type="button" onClick={guardar} />
                }
            </div>
            <Dialog header="Actualizar profundidad y kilometros" visible={verDialog} style={{ width: '45vw' }}
                footer={renderFooterModal()} closable={false}>
                <div className="form-row">
                    <FieldInputNumber name={"profundidad"} value={llanta.profundidad} label="Profundidad (Mm)"
                        required={true} colMd={6} error={!llanta?.profundidad} fractionDigits={2} onChange={update} />
                    <FieldInputNumber name={"kilometros"} value={llanta?.kilometros} label="Kilometros"
                        required={true} colMd={6} error={!llanta?.kilometros} fractionDigits={2} onChange={update} />
                </div>
            </Dialog>
        </>
    )
};

export default RotacionLlanta;