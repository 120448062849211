import moment from "moment";

export const tiposMovimientos = {
    abono: 1,
    cargo: 2
}

export const estatus = {
    porComprobar: 1,
    comprobado: 2,
    abonado: 3,
    solicitada: 4,
    aprobada: 5,
    rechazada: 6
}

export const fechaMinimaSolicitud = moment().add(-3, 'days')._d;
export const fechaMaximaSolicitud = new Date();
