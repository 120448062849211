/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer } from "react";
import "../administracion.css";
import { useEffect } from "react";
import {serialize} from "object-to-formdata";
import { Button } from "primereact/button";
import formReducer from "../../shared/forms/formReducer";
import FieldDropDown from "../../shared/controls/fieldDropDown";
import FieldCalendar from "../../shared/controls/fieldCalendar";
import FieldTextArea from "../../shared/controls/fieldTextArea";
import useApiIncapacidad from "../../api/administracion/useApiIncapacidad";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import InputFile from "../../shared/controls/inputFile";

const IncapacidadDetalle = ({ incapacidadId, onSaved, close, soloLectura }) => {
    const api = useApiIncapacidad();
    const defaultEntity = () => ({ entity: { id: 0, empleado:{}, fechaInicio:null, fechaFinal:null,comentarios:null, tipoIncapacidadId:{} } });
    const [{ entity, errors }, dispatch] = useReducer(formReducer, {}, defaultEntity);
    useEffect(() => {
        async function cargar() {
            if (incapacidadId!==0){
                const id = await api.obtener(incapacidadId);
                dispatch({ type: "update", value: id });
            }
        }

        cargar().then();
    }, []);

    const actualizar = (value, propertyName) => {       
        dispatch({ type: "update", value: value, name: propertyName });
    };

   
   const guardar = async () => {
        const formData = serialize(entity ?? {}, {indices: true, dotsForObjectNotation: true});
        console.log(formData)
        await api.guardar(formData);
        onSaved();
    }

    return (
        <>
          <div className="form-container full-height" style={{ paddingLeft: "1px" }}>
                <div className="form-row">
                    <h5 className="col-12">Incapacidad</h5>

                    <FieldDropDown
                        name="tipoIncapacidadId"
                        source="motivoIncapacidad"
                        errors={errors}
                        label="Tipo de Incapacidad"
                        colMd={6}
                        filter={true}
                        required={true}
                        value={entity.tipoIncapacidadId}
                        onChange={actualizar}
                    />
                    <FieldDropDown
                        name="empleado"
                        source="empleados"
                        errors={errors}
                        label="Empleado"
                        colMd={12}
                        params={{todasLasEmpresas: true}}
                        filter={true}
                        value={entity.empleado}
                        onChange={actualizar}
                        disabled={entity.id > 0}
                    />
                </div>
                 <div className="form-row">
                    <FieldCalendar
                        name="fechaInicio"
                        label="Fecha Inicio"
                        colSm={6}
                        value={entity.fechaInicio}
                    
                        className="xs"
                        showButtonBar={false}
                        errors={errors}
                        onChange={actualizar}
                    />
                     <FieldCalendar
                        name="fechaFinal"
                        label="Fecha Final"
                        colSm={6}
                        value={entity.fechaFinal}
                        className="xs"
                        showButtonBar={false}
                        errors={errors}
                        onChange={actualizar}
                    />
                </div>
                <div className="form-row">
                    <FieldTextBox
                        name="folio"
                        label="Folio"
                        value={entity.folio}
                        colSm={6}
                        errors={errors}
                        onChange={actualizar}
                    />
                 
                </div>
                <div className="form-row">
                    <FieldTextArea
                        name="comentarios"
                        label="Comentarios"
                        value={entity.comentarios}
                        colSm={12}
                        onChange={actualizar}
                        rows={4}
                        errors={errors}
                    />
                </div>
                <div>
                Evidencia IMMS:
                <InputFile
                    name="evidencia"
                    value={entity.evidencia}
                    onChange={actualizar}
                />
            </div>
            </div>

            <div className="form-footer">
                <Button label="Salir" className="p-button-outlined" onClick={close} />
              
                    <Button className="btn-primary" label="Guardar" type="button" onClick={guardar} />
              
            </div>
        </>
    );
};

export default IncapacidadDetalle;
