/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Form from "../../../shared/forms/Form";
import Field from "../../../shared/forms/Field";
import FormDropDown from "../../../shared/forms/FormFields/FormDropDown";
import FormInputNumber from "../../../shared/forms/FormFields/FormInputNumber";
import useApiDieselTanque from "../../../api/taller/useApiDieselTanque";
import { useLoadingDispatch } from "../../../layout/show-loading-context";
import { tiposCombustible } from "../DieselConstantes";
import ConversionGalones from "./ConversionGalones";

const Urea = ({ value: urea, parent: carga, onChange, onValidate, formDisabled }) => {
    const api = useApiDieselTanque();
    const dispatch = useLoadingDispatch();

    const handleChange = async (nuevaUrea) => {
        if (nuevaUrea.tanqueId == null || nuevaUrea.litros == null || urea?.tanqueId === nuevaUrea.tanqueId) {
            onChange({
                ...nuevaUrea,
                litros: nuevaUrea.conversionGalones?.litros ?? nuevaUrea.litros,
                precio: nuevaUrea.conversionGalones?.precio ?? nuevaUrea.precio,
            });
            return;
        }
    };

    const onLitrosBlur = async () => {
        if (urea.litros == null || carga.esCargaExterna) {
            return;
        }

        const result = await api.obtenerPrecio({ ...urea, combustibleId: tiposCombustible.urea });

        if (!result.isSuccess) {
            dispatch({ type: "toast", message: result.message, severity: "warn" });
        }

        onChange({ ...urea, precio: result.precio });
    };

    return (
        <Form onChange={handleChange} columns={2} values={urea} onValidate={onValidate} formDisabled={formDisabled}>
            {carga.proveedorUbicacionId != null && (
                <Field
                    field={"tanqueId"}
                    label={"Ubicacion"}
                    required={true}
                    source={"diesel/tanques"}
                    params={{
                        combustibleId: carga.combustibleId,
                        empresaId: carga.proveedorEmpresaId,
                        ubicacionId: carga.proveedorUbicacionId,
                    }}
                    render={FormDropDown}
                />
            )}
            {carga.convertirGalonesALitros && (
                <Field field={"conversionGalones"} render={(carga) => <ConversionGalones {...carga} />} cols={2} />
            )}
            <Field
                field={"litros"}
                label={"Litros Mxn"}
                required={true}
                mode="decimal"
                disabled={carga.convertirGalonesALitros}
                debounceTimeOut={500}
                maxFractionDigits={2}
                render={FormInputNumber}
                onBlur={onLitrosBlur}
            />
            <Field
                field={"precio"}
                label={"Precio"}
                disabled={carga.proveedorUbicacionId != null}
                mode="decimal"
                maxFractionDigits={2}
                render={FormInputNumber}
            />
        </Form>
    );
};

export default Urea;
