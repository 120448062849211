import React, {useState} from 'react';
import {IntlProvider} from "react-intl";
import Spanish from '../lang/es.json';

let lang = Spanish;

const IntlWrapper = (props) => {
    const [locale, ] = useState('es');

    const [messages, ] = useState(lang);

    return (
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
    );
};


export default IntlWrapper;