import {Button} from "primereact/button";
import React, {useReducer} from "react";
import useApiViaje from "../../../api/operaciones/useApiViajes";
import formReducer from "../../../shared/forms/formReducer";
import alerts from "../../../shared/alerts";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import {Dialog} from "primereact/dialog";
import YupValidator from "../../../shared/YupValidator";
import {cancelacionViajeEsquema} from "./viajeValidacionEsquema";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import FieldTextArea from "../../../shared/controls/fieldTextArea";
import FieldInputNumber from "../../../shared/controls/fieldInputNumber";
import {SelectButton} from "primereact/selectbutton";

const opcionesDistancia = [{id: 1, nombre: "Km"}, {id: 2, nombre: "Mi"}];

const CancelarViaje = ({viajeId, timbrado, visible, hide, onSave, enviarCancelacion = false}) => {
    const api = useApiViaje();

    const defaultEntity = () => ({entity: {esTonu: false, esFleteEnFalso: false, unidadMedida: opcionesDistancia[0]}});

    const [{entity, errors}, dispatch] = useReducer(formReducer, {}, defaultEntity);
    const validator = new YupValidator(cancelacionViajeEsquema);

    const actualizar = (value, propertyName) => {
        if (propertyName === "unidadMedida") {
            if (value != null) {
                const unidadMedida = opcionesDistancia.find(e => e.nombre === value);
                dispatch({type: 'update', value: unidadMedida, name: "unidadMedida"});
            }
        } else {
            dispatch({type: 'update', value: value, name: propertyName});
        }

        dispatch({type: 'validate', errors: {}});

        //Si se cambia alguna de estas propiedades, las otras regresan a su valor default
        const propiedadesExluyentes = [
            {nombre: "motivo", defaultValue: null},
            {nombre: "esTonu", defaultValue: false},
            {nombre: "esFleteEnFalso", defaultValue: false}
        ];

        if (propiedadesExluyentes.some(e => e.nombre === propertyName)) {
            propiedadesExluyentes.forEach(e => {
                if (e.nombre !== propertyName) {
                    dispatch({type: 'update', value: e.defaultValue, name: e.nombre});
                }
            })
        }

        if (propertyName === "distanciaRecorrida" || propertyName === "tarifa") {
            const distanciaRecorrida = propertyName === "distanciaRecorrida" ? value : entity.distanciaRecorrida;
            const tarifa = propertyName === "tarifa" ? value : entity.tarifa;
            const facturacion = Number(distanciaRecorrida ?? 0) * Number(tarifa ?? 0);
            dispatch({type: 'update', value: facturacion, name: "facturacion"});
        }
    };

    const cancelar = async () => {
        const esValido = await validator.validate(entity);

        dispatch({type: 'validate', errors: validator.errors});

        if (esValido && await alerts.preguntarSiNoAdvertencia('¿Desea cancelar el viaje?')) {
            await api.cancelar(viajeId, entity);

            if (enviarCancelacion)
                await api.enviarEdi990D(viajeId, entity?.rechazo);

            if (timbrado === true) {
                await api.cancelarTimbrado(viajeId);
            }
            onSave();
        }
    };

    const renderFooterModal = () => {
        return (
            <div>
                <Button label="Cancelar" onClick={hide} className="p-button-outlined"/>
                <Button label="Confirmar" onClick={cancelar} autoFocus/>
            </div>
        );
    };

    return (
        <Dialog header="¿Desea cancelar el viaje?" visible={visible} style={{width: '35vw'}}
                footer={renderFooterModal()} onHide={hide}>
            <div className="form-row">
                <FieldDropDown name="motivo" label="Motivo de Cancelación" source="motivosCancelacionViaje"
                               colMd={6} valueById={true}
                               error={errors?.motivo} value={entity.motivo}
                               onChange={actualizar}/>

                <FieldCheckBox name="esTonu" label="Tonu" value={entity.esTonu} colMd={2} onChange={actualizar}/>

                <FieldCheckBox name="esFleteEnFalso" label="Flete en Falso" value={entity.esFleteEnFalso}
                               onChange={actualizar} colMd={3}/>

                {enviarCancelacion &&
                    <FieldDropDown name="rechazo" label="Motivo de Rechazo para EDI" source="codigosRechazo"
                                   colMd={12} valueById={true}
                                   error={errors?.rechazo} value={entity.rechazo}
                                   onChange={actualizar}/>
                }

                <FieldTextArea name="comentarios" label="Comentarios" value={entity.comentarios} colMd={12}
                               error={errors?.comentarios} onChange={actualizar}/>

                {entity.esFleteEnFalso &&
                    <>
                        <div className="form-group col-sm-5">
                            <label>Distancia Recorrida</label>
                            <div className="col-auto">
                                <FieldInputNumber name="distanciaRecorrida" colMd={12}
                                                  error={errors?.distanciaRecorrida}
                                                  value={entity.distanciaRecorrida} onChange={actualizar}
                                                  fractionDigits={2}/>
                                <SelectButton value={entity.unidadMedida.nombre}
                                              options={opcionesDistancia.map(e => e.nombre)}
                                              onChange={({value}) => actualizar(value, 'unidadMedida')}/>
                            </div>
                        </div>

                        <FieldInputNumber name="tarifa" label={`Tarifa ($/${entity.unidadMedida.nombre})`} colMd={3}
                                          value={entity.tarifa} error={errors?.tarifa} fractionDigits={2}
                                          onChange={actualizar}/>
                    </>
                }

                {(entity.esTonu || entity.esFleteEnFalso) &&
                    <FieldInputNumber name="facturacion" label="Facturación" colMd={4} fractionDigits={2}
                                      value={entity.facturacion} error={errors?.facturacion} disabled={!entity.esTonu}
                                      onChange={actualizar}/>
                }
            </div>
        </Dialog>
    );
};


export default CancelarViaje;
