/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import produce from "immer";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import FieldCalendarRange from "../../../shared/controls/fieldCalendarRange";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import FieldMultiSelect from "../../../shared/controls/fieldMultiSelect";
import useSessionState from "../../../hooks/useSessionState";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import { Button } from "primereact/button";
import constantes from "../../constantes";
import constantesCatalogos from "../../../catalogos/constantes";
import FieldAutoComplete from "../../../shared/controls/fieldAutoComplete";

const FiltroExpeditados = ({ filtroKey, handleSubmit, cambiarVista, esVistaDeLista, gridaApi }) => {
    const [filtro, setFiltro] = useSessionState(filtroKey, {
        estatus: [constantes.estatusOrdenesTrabajo.pteProgramar, constantes.estatusOrdenesTrabajo.programada, constantes.estatusOrdenesTrabajo.reprogramada]
    }
    );

    function actualizar(value, name) {
        const nuevoFiltro = name != null ? produce(filtro, draft => {
            draft[name] = value

            if (name === 'unidad') {
                draft.unidadId = value?.id ?? 0;
            }
        }) : value;
        setFiltro(nuevoFiltro);
    }

    useEffect(() => {
        handleSubmit(filtro);
    }, [gridaApi]);

    function onEnterKeyPress(event) {
        if (event.charCode === 13) {
            handleSubmit(filtro);
            event.preventDefault();
        }
    }

    return (
        <form className="form-filtro form-row" onKeyPress={onEnterKeyPress}>
            <div className="iconos">
                <Button label="VER LISTADO SOLICITUDES" className="p-button-outlined" type="button" onClick={() => cambiarVista(true)} disabled={esVistaDeLista} />
                <Button label="VER CALENDARIO" className="p-button-outlined" type="button" onClick={() => cambiarVista(false)} disabled={!esVistaDeLista} />
                <ButtonIcon title={"borrar filtros"} onClick={() => actualizar({})} iconName={'FcClearFilters'} />
                <ButtonIcon title="buscar" onClick={() => handleSubmit(filtro)} className={'action'}
                    iconName={'BsSearch'} />
            </div>
            <FieldTextBox name="ordenServicio" label="Orden Servicio" colMd={2} colLg={1} keyFilter={'int'}
                value={filtro.ordenServicio} onChange={actualizar} />
            <FieldDropDown name="mecanicoId" source="empleados" label="Mecánico" colMd={2} valueById={true}
                params={{ puestoIds: [constantesCatalogos.puestos.mecanicoA, constantesCatalogos.puestos.mecanicoB] }}
                value={filtro.mecanicoId} onChange={actualizar} />
            <FieldDropDown name="tipoServicioId" source="general/Tipo de servicio" label="Tipo Servicio" colMd={2} valueById={true}
                value={filtro.tipoServicioId} onChange={actualizar} />
            <FieldAutoComplete name="unidad" source="unidades" label="No. Económico" colMd={1} isString={true}
                params={{ id: 0, todasLasEmpresas: true }} value={filtro.unidad} onChange={actualizar} />
            <FieldDropDown name="tipoUnidadId" source="tiposUnidadFlotilla" label="Tipo de Unidad" colMd={2} valueById={true}
                value={filtro.tipoUnidadId} onChange={actualizar} />
            {esVistaDeLista &&
                <FieldCalendarRange name="fechas" label="Fechas" colMd={2} colLg={2}
                    value={filtro.fechas} onChange={actualizar} />
            }
            <FieldMultiSelect name="estatus" source="estatus/OrdenesDeTrabajo" label="Estatus" colMd={2} valueAsId={true}
                value={filtro.estatus} onChange={actualizar} />
        </form>
    )
};

export default FiltroExpeditados;
