import React from "react";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import FieldCalendarRange from "../../../shared/controls/fieldCalendarRange";
import FieldMultiSelect from "../../../shared/controls/fieldMultiSelect";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import FieldAutoComplete from "../../../shared/controls/fieldAutoComplete";

const FiltroSolicitudServicios = ({ filtro, onChange }) => {
    return (
        <>
            <FieldTextBox name="solicitudServicio" label="Solicitud Servicio" colMd={2} keyFilter={'int'}
                value={filtro.solicitudServicio} onChange={onChange} />

            <FieldDropDown name="tipoUnidadId" source="tiposUnidadFlotilla" label="Tipo de Unidad" colMd={2} valueById={true}
                value={filtro.tipoUnidadId} onChange={onChange} />

            <FieldAutoComplete name="unidad" source="unidades" label="No. Económico" colMd={1} isString={true}
                params={{ id: 0, todasLasEmpresas: true }} value={filtro.unidad} onChange={onChange} />

            <FieldCalendarRange name="fechas" label="Fechas" colMd={2}
                value={filtro.fechas} onChange={onChange} />

            <FieldMultiSelect name="estatus" source="estatus/SolicitudServicio" label="Estatus" colMd={2} valueAsId={true}
                value={filtro.estatus} onChange={onChange} />
        </>
    )
};

export default FiltroSolicitudServicios;
