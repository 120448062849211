
import React from "react";
import * as numeral from "numeral";

const DetalleRutaGrid = ({data}) => {
    return (
        <div className="p-d-flex com-detalle-ruta">
            <div className="p-d-flex p-flex-column bold">
                <span>Rendimiento</span>
                <span>Diesel</span>
                <span>Costo Diesel</span>
            </div>
            <div className="p-d-flex p-flex-column">
                <span>{numeral(data?.costo?.rendimiento).format('0,0.00')} {data.unidadDeMedida?.abreviatura}/Lt</span>
                <span>{numeral(data?.costo?.diesel).format('0,0.00')} Lt</span>
                <span>{numeral(data?.costo?.costoDiesel).format('$0,0.00')}</span>
            </div>
            <div className="p-d-flex p-flex-column">
                <span className="com-gasto-titulo">&nbsp;</span>
                <span >&nbsp;</span>
                <span className="border-top border-bottom border-left">COSTO POR {data.unidadDeMedida?.abreviatura?.toUpperCase()}</span>
            </div>
            {(data?.costo?.gastos??[]).map((gasto, index) => (
                    <div key={index} className="p-d-flex p-flex-column">
                        <span className="bold com-gasto-titulo">{gasto.tipoCostoNombre}</span>
                        <span >{numeral(gasto.costo).format('$0,0.00')}</span>
                        <span className="bold border-top border-bottom">{numeral(gasto.costoPorKilometro).format('$0,0.00')}</span>
                    </div>
                )
            )}
            <div className="p-d-flex p-flex-column">
                <span className="bold com-gasto-titulo">Costo x {data.unidadDeMedida?.abreviatura}</span>
                <span >&nbsp;</span>
                <span className="bold border-top border-bottom border-right">{numeral(data?.costo?.costoPorKilometro).format('$0,0.00')}</span>
            </div>
        </div>
    );
};

export default DetalleRutaGrid;

