/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer } from "react";
import clienteValidacionEsquema from "./clienteValidacionEsquema";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import formReducer from "../../shared/forms/formReducer";
import FormHandler from "../../shared/forms/formHandler";
import constantes from "../constantes";
import useApiClientes from "../../api/catalogos/clientes";
import DetalleCliente from "./detalleCliente";

const Cliente = ({ onSave, soloLectura }) => {
    const apiClientes = useApiClientes();

    const defaultCliente = () => (
        {
            entity: {
                tipoPersona: constantes.tiposPersona.moral,
                tipoCliente: { id: constantes.tiposCliente.nacional },
                moneda: { id: constantes.monedas.pesos },
                direccionFiscal: {
                    pais: { id: constantes.paises.mexico }
                }
            }
        }
    );

    const [{ entity, errors, ...state }, dispatch] = useReducer(
        formReducer, {}, defaultCliente
    );

    const formHandler = new FormHandler({
        validationSchema: clienteValidacionEsquema,
        onSave,
        api: apiClientes,
        dispatch,
        onSetErrors: setTabLevelErrors
    });

    useEffect(() => {
        if (state.submitted === true) {
            formHandler.validate(entity);
        }
    }, [entity]);


    function setTabLevelErrors(errors) {
        return {
            ...errors,
            generales: errors.razonSocial || errors.rFC || errors.usoCfdi || errors.plazo ||
                errors.nombre || errors.apellidoPaterno || errors.apellidoMaterno,
            direccionFiscal: errors.direccionFiscal || errors.telefono || errors.emailFacturacion,
            datosPago: errors.formaPago || errors.metodoPago
        };
    }

    async function close() {
        dispatch({ type: 'hide' });
        await onSave();
    }

    return (
        <>
            {!soloLectura &&
                <Button label="Agregar Cliente" style={{ width: 180 }} className="p-button-text" icon="pi pi-plus-circle"
                    onClick={() => dispatch({ type: 'show' })}
                />
            }

            <Sidebar visible={state.visible} position="right" dismissable={false}
                className="p-sidebar-bg" onHide={() => dispatch({ type: 'hide' })}>
                <DetalleCliente clienteId={0} onSave={close} />
            </Sidebar>
        </>
    );
};

export default Cliente;

