import useServiceFactory from "../serviceFactory";

const apiUrl = `/catalogos/modelosEquipo`;

const useApiModelosEquipos = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, { params, showLoading: true }),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, { showLoading: true }),
        guardar: async (params) => service.post(`${apiUrl}`, params)
    }
};

export default useApiModelosEquipos;