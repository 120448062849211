const constantes = {
    estatusCotizaciones: {
        enProceso: 1,
        enviada: 2,
        enConvenio: 3,
        cancelada: 4,
    },
    estastusRutas: {
        enProceso: 31,
        aceptada: 32,
        rechazada: 33,
    },
    estatusConvenios: {
        altaPendiente: 11,
        vigente: 12,
        suspendido: 13,
    },
    estatusExpeditados: {
        nuevo: 20,
        altaPendiente: 21,
        completo: 22,
        Suspendido: 23,
    },
    monedaPeso: {
        id: 1,
        nombre: "Pesos",
    },
    monedaUsd: {
        id: 2,
        nombre: "Dólares",
    },
    kilometros: {
        id: 1,
        nombre: "Kilómetros",
        abreviatura: "km",
    },
    millas: {
        id: 2,
        nombre: "millas",
        abreviatura: "mi",
    },
    tipoControlPatios: {
        acceso: 301,
        salida: 302,
    },
    estatusViajes: {
        porProgramar: 101,
        programado: 102,
        enRuta: 103,
        terminado: 104,
        segmentado: 105,
        cancelado: 106,
    },
    estatusUnidadViaje: {
        disponible: 1,
        enUso: 2,
        enMantenimiento: 3,
        tentativo: 4,
        porIniciarMtto: 5,
    },
    tipoViaje: {
        sencillo: 41,
        redondo: 42,
    },
    tipoProgramacionViaje: {
        convenio: 371,
        expeditado: 372,
        vacio: 373,
    },
    tipoControlPatio: {
        salida: 302,
    },
    estatusControlPatio: {
        programada: 91,
        cancelada: 176,
    },
    unidadViajeEstatusId: {
        enTiempo: 1,
        retrasado: 2,
        sinViaje: 3,
    },
    tipoMenus: {
        pagina: 2,
        opcion: 3,
    },
    tipoRuta: {
        inicio: 1,
        stop: 2,
        fin: 3,
    },
};

export default constantes;
