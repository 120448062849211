import useServiceFactory from "../serviceFactory";
const apiUrl = `operaciones/orderToCash`;
const useApiClientes = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, { params, showLoading: true }),
        excel: async (params) => service.get(`${apiUrl}/excel`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `OrderToCash.xlsx`
            }),
        excelCancelados: async (params) => service.get(`${apiUrl}/excelCancelados`,
        {
                params,
                showLoading: true,
                download: true,
                fileName: `OrderToCashCancelados.xlsx`
        }),
        registrarFechas: async (pendiente, params) => service.post(`${apiUrl}/registrarFechas/${pendiente}`, params),
        cancelarFecha: async (viajeId, comentarios) => service.post(`${apiUrl}/${viajeId}/cancelar`, {comentarios}),
        historial: async (viajeId) => service.get(`${apiUrl}/${viajeId}/historial`, {showLoading:true}),
        ligarFacturas: async (params) => service.put(`${apiUrl}/ligarFactura/`, params),
    }
};

export default useApiClientes;
