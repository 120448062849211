/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import UseApiOrdenServicio from "../../api/taller/useApiOrdenServicio";
import { RadioButton } from "primereact/radiobutton";
import LogoPdf from '../../img/LogoPdf.jpg';


const ComponentToPrint = ({ ordenTrabajoId }) => {


  const apiOrdenServicio = UseApiOrdenServicio();
  const [checklist, setChecklist] = useState([]);
  const [ordentrabajo, setOrden] = useState();
  const [fecha, setFecha] = useState();

  useEffect(() => {
    let timer1 = setTimeout(loadCheckList, 100);
    return () => {
      clearTimeout(timer1);
    };
  }, [ordenTrabajoId]);



  const loadCheckList = async () => {

    if (ordenTrabajoId === 0)
      return;


    let checklist = await apiOrdenServicio.obtieneCheckList(ordenTrabajoId);
    setChecklist(checklist);
    setFecha(new Date().toLocaleString());

    let orden = { entity: await apiOrdenServicio.obtener(ordenTrabajoId) };

    setOrden(orden);
  };



  return (


    <div>
      <div className={'section-container'}>

        <div className="flex-col-4">
          <img style={{ height: "80px" }} alt="LogoPage" src={LogoPdf} />
        </div>

        <div className="flex-col-11">
          <center>
            <div className="col-md-12">
              <h4>Checklist-Mantenimiento</h4>
            </div>
            <div className="col-md-12">
              <h4>Orden de Trabajo : {ordentrabajo?.entity?.codigo} Numero Económico: {ordentrabajo?.entity?.unidad.nombre}</h4>
            </div>
            <div className="col-md-12">
              <h4>Fecha y Hora: {fecha}</h4>
            </div>
          </center>
        </div>
      </div>

      <div className={`section-container`}>
        <div className="flex-col-6">
          {checklist.map((item, index) => {
            return (
              <div key={index} className="flex-col-6 danger">
                {(index % 2 ? "Impar" : "Par") === "Par" && (
                  <>
                    <div className="danger">
                      <div className="danger">
                        <table className="table">
                          <thead className="thead-light">
                            <tr>
                              <td><h6>{item.nombre}</h6></td>
                              <td><h6>OK</h6></td>
                              <td><h6>NECESITA SERVICIO</h6></td>
                            </tr>
                          </thead>
                          <tbody className={`${item.error === true ? "error" : ""}`}>

                            {checklist[index].checkList.map(function (
                              check,
                              indexcheck
                            ) {
                              return (
                                <tr key={indexcheck}>
                                  <td style={{ width: "60em" }}>
                                    {check.puntoInspeccion.nombre}
                                  </td>
                                  <td style={{ width: "20em" }}>
                                    <div className="">
                                      <RadioButton
                                      />
                                    </div>
                                  </td>

                                  <td style={{ width: "20em" }}>
                                    <div className="">
                                      <RadioButton
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>

        <div className="flex-col-6">
          {checklist.map((item, index) => {
            return (
              <div key={index} className="flex-col-6">
                {(index % 2 ? "Impar" : "Par") === "Impar" && (
                  <>
                    <div className="">
                      <div className="">
                        <table className="table">
                          <thead className="thead-light">
                            <tr>
                              <td><h6>{item.nombre}</h6></td>
                              <td><h6>OK</h6></td>
                              <td><h6>NECESITA SERVICIO</h6></td>
                            </tr>
                          </thead>
                          <tbody className={`${item.error === true ? "error" : ""}`}>

                            {checklist[index].checkList.map(function (
                              check,
                              indexcheck
                            ) {
                              return (
                                <tr key={indexcheck}>
                                  <td style={{ width: "60em" }}>
                                    {check.puntoInspeccion.nombre}
                                  </td>
                                  <td style={{ width: "20em" }}>
                                    <div className="">
                                      <RadioButton
                                      />
                                    </div>
                                  </td>

                                  <td style={{ width: "20em" }}>
                                    <div className="">
                                      <RadioButton
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  )
};

export default ComponentToPrint;