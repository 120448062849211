import {Button} from "primereact/button";
import React, {useReducer} from "react";
import formReducer from "../../../shared/forms/formReducer";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import {Dialog} from "primereact/dialog";
import * as yup from "yup";
import YupValidator from "../../../shared/YupValidator";
import FieldTextArea from "../../../shared/controls/fieldTextArea";
import FieldInputNumber from "../../../shared/controls/fieldInputNumber";

const validacionEsquema = yup.object().shape({
    motivo: yup.mixed().required(),
    tiempoDesactivacion: yup.number().required(),
    comentarios: yup.string().required()
});

const unidadesTiempo = [{id:1, nombre:'Hrs'}, {id:2, nombre:'Min'} ];

const ApagarNotificacion = ({notificacion, visible, hide, onSave}) => {
    const defaultEntity = () => ({entity: {unidadTiempo: 1}});

    const [{entity, errors}, dispatch] = useReducer(formReducer, {}, defaultEntity);
    const validator = new YupValidator(validacionEsquema);

    const actualizar = (value, propertyName) => {
        dispatch({type: 'update', value: value, name: propertyName});
    };

    const confirmar = async () => {
        const esValido = await validator.validate(entity);

        dispatch({type: 'validate', errors: validator.errors});

        if (esValido) {
            onSave(entity);
        }
    };

    const renderFooterModal = () => {
        return (
            <div>
                <Button label="Cancelar" onClick={hide} className="p-button-outlined"/>
                <Button label="Confirmar" onClick={confirmar} autoFocus/>
            </div>
        );
    };

    return (
        <Dialog header="¿Desea apagar las notificaciones?" visible={visible} style={{width: '35vw'}}
                footer={renderFooterModal()} onHide={hide}>
            <div className="form-row">
                <div className="col-12">
                    <h6>Notificaciones de {notificacion?.nombre}</h6>
                </div>

                <FieldDropDown name="motivo" label="Motivo de Cancelación" source="motivosApagarNotificacion"
                               colMd={7} valueById={true} error={errors?.motivo} value={entity.motivo}
                               onChange={actualizar} appendTo={document.body}/>

                <div className="col-5">
                    <label>Tiempo de Desactivación</label>
                    <div className="d-flex">
                        <FieldInputNumber name="tiempoDesactivacion" value={entity.tiempoDesactivacion} className="xs"
                                          showButtonBar={false} errors={errors} onChange={actualizar}/>
                        <FieldDropDown name="unidadTiempo" options={unidadesTiempo} colMd={2} valueById={true}
                                       errors={errors} value={entity.unidadTiempo} onChange={actualizar}
                                       filter={false} showClear={false}/>
                    </div>
                </div>

                <FieldTextArea name="comentarios" label="Comentarios" value={entity.comentarios} colMd={12}
                               error={errors?.comentarios} onChange={actualizar}/>
            </div>
        </Dialog>
    );
};


export default ApagarNotificacion;
