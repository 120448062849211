/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from "react";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import useApiEstaciones from "../../api/catalogos/useApiEstaciones";
import {updateArrayAtIndex} from "../../shared/arrayUtility";
import {dateToString, numberFormat} from "../../shared/format";
import {Button} from "primereact/button";
import InfoViaje from "./InfoViaje";
import _ from "lodash";
import {reorder} from "../../shared/DragAndDropUtility";


/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 4,
    margin: `0 0 4px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "white",

    // styles we need to apply on draggables
    ...draggableStyle
});
const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "transparent",
    width: 200,
    flexGrow: 1,
    border: '1px solid lightgrey'
});

function ViajeEstaciones() {
    const [state, setState] = useState([]);
    const [viaje, setViaje] = useState({})
    const [totales, setTotales] = useState({sugeridosPorEstacion:{}})

    const api = useApiEstaciones();

    const op = useRef(null);

    const load = async () => {
        const data = await api.viajes()
        setState(data)

        const viajes =  _.sum(data.map(e=>e.viajes.length))
        const estaciones = data.length;

        if (estaciones === 0)
            return;

        const promedio = viajes/estaciones;

        setTotales( {viajes, promedio, sugeridosPorEstacion: {min: Math.round(promedio-5),max: Math.round(promedio+5)}  })
    }

    useEffect(() => {
        load().then()
        setInterval(()=>{
            load().then()
        },300000)

    }, []);

    const onDragEnd = async (result) => {
        const {source, destination} = result;

        // dropped outside the list
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        if (sInd === dInd) {
            let estacion = state[sInd]
            const viajes = reorder(estacion.viajes, source.index, destination.index);
            estacion = {...estacion, viajes};
            setState(updateArrayAtIndex(state, sInd, estacion));
        } else {
            let estacionOrigen = state[sInd];
            const viaje = estacionOrigen.viajes[source.index];
            let estacionDestino = state[dInd]
            const result = move(estacionOrigen.viajes, estacionDestino.viajes, source, destination);
            const newState = [...state];
            newState[sInd] = {...estacionOrigen, viajes : result[sInd], cantidadViajes:result[sInd].length};
            newState[dInd] = {...estacionDestino, viajes: result[dInd], cantidadViajes: result[dInd].length};
            setState(newState);
            await api.asignarEstacion(estacionDestino.id, viaje.viajeId)
        }
    }

    const onShowViaje =(e, viaje) =>{
        op.current.toggle(e)
        setViaje(viaje)
    }

    const getHeaderColor = (value)=>{
        if (value >= totales.sugeridosPorEstacion.min  && value <= totales.sugeridosPorEstacion.max)
            return '#4b6fdb';
        return '#b43b3b';

    }

    return (
        <>
            <div className={'viaje-estaciones-header'}>
                <span className={'text-label'}>
                    Total Viajes:
                </span>
                <span className={'text-value'}>
                    {totales.viajes}
                </span>
                <span className={'text-label'} style={{marginLeft:'10px'}}>
                    Promedio Viajes:
                </span>
                <span className={'text-value'}>
                    {numberFormat(totales.promedio)}
                </span>
                <span className={'text-label'} style={{marginLeft:'10px'}}>
                    Sugeridos Por Estacion:
                </span>
                <span className={'text-value'}>
                    {totales.sugeridosPorEstacion.min} - {totales.sugeridosPorEstacion.max}
                </span>
            </div>
            <div style={{display: "flex", gap:'5px', flexGrow:1, justifyContent: "flex-start"}}>
                <DragDropContext onDragEnd={onDragEnd}>
                    {state.map((el, ind) => (
                        <React.Fragment key={el.id}>
                            <div className={'estacion'}>
                                <div className={'estacion-header'} style={{color:getHeaderColor(el.viajes.length)}}>
                                    <span>{el.nombre}</span> <span className={'unidades'}>({el.cantidadViajes} viajes)</span>
                                </div>
                            <Droppable droppableId={`${ind}`}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                        {...provided.droppableProps}
                                    >
                                        {el.viajes.map((item, index) => (
                                            <Draggable
                                                key={item.viajeId}
                                                draggableId={`${item.viajeId}`}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        <div className={'estacion-viaje'}>
                                                            <span className={'unidad'}>{item.unidad}</span>
                                                            {/*<span>{item.cartaPorte}</span>*/}
                                                            <div>{dateToString(item.fecha)}</div>
                                                            <div className={'cliente'}>{item.cliente}</div>
                                                            <span className={'info'} onClick={(e)=> onShowViaje(e,item) }>
                                                                 <Button icon="pi pi-info-circle" className="p-button-rounded p-button-info p-button-text" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                            </div>
                        </React.Fragment>
                    ))}
                </DragDropContext>
            </div>
            <InfoViaje refViaje={op} viaje={viaje}>

            </InfoViaje>
        </>
    );
}


export default ViajeEstaciones