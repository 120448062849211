/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import {Button} from "primereact/button";

const InputClipboard = ({onChange}) => {

    const onPasteClick = async () => {
        const clipboardContents = await navigator.clipboard.read();
        for (const item of clipboardContents) {
            if (!item.types.includes('image/png')) {
                alert('Debe copiar una imagen');
                return;
            }
            const blob = await item.getType('image/png');
            const file = new File([blob],
                'image.jpeg',
                {
                    type: blob.type,
                });

            onChange({
                isEmpty: false,
                url: URL.createObjectURL(blob),
                file,
                nombre:'captura.jpeg'
            });
        }
    }

    return (
        <Button className="p-button-rounded p-button-text" icon="pi pi-clone"
                                  title={'Copiar desde el portapapeles'}
                                  onClick={onPasteClick}/>

    )
}

export default InputClipboard;
