/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import ButtonIcon from "../../controls/ButtonIcon";

const DeleteButton = ({onDelete, formDisabled}) => {

    return (
        <>
            {!formDisabled &&
                <ButtonIcon title={'Eliminar'}
                            iconName={'RiDeleteBinLine'}
                            onClick={onDelete}/>
            }
        </>
    )
}

export default DeleteButton;
