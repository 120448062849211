import * as numeral from "numeral";

class FormatMoneda {

    constructor(mostrarEnDolares, tipoCambio){
        this.mostrarEnDolares = mostrarEnDolares;
        this.tipoCambio = tipoCambio;
    }

    format(pesos){
        if (!this.mostrarEnDolares) {
            return numeral(pesos).format('$0,0.00');
        }

        if (this.tipoCambio == null || this.tipoCambio === 0) {
            return "Sin T/C";
        }

        return numeral(pesos/this.tipoCambio).format('0,0.00') + ' USD';
    }
}

export const writeToClipboard = (text) => {
    navigator.clipboard.writeText(text)
        .then(() => {
            console.log('Text successfully copied to clipboard:', text);
        })
        .catch(err => {
            console.error('Unable to write to clipboard:', err);
        });
}


export {FormatMoneda};