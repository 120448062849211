/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import IconLibrary from "../iconLibrary";
import FileDownload from 'js-file-download';
import useServiceFactory from "../../api/serviceFactory";
import alerts from "../alerts"; 
import PdfViewer from "../componentes/pdfViewer";
import ImageViewer from "../componentes/imageViewer";
import {useEmpresa} from "../../layout/EmpresaProvider";

const UploadImageFile = ({name, value, onChange, error, errors, disabled}) => {
    const [file, setFile] = useState({isEmpty: true});
    const uniqueName = name + Date.now() + Math.random().toString().slice(2);
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URL});
    const [vistaPrevia, setVistaPrevia] = useState(false);

    const {empresaId} = useEmpresa();


    useEffect(() => {
        if (value == null) {
            setFile({isEmpty: true});
        } else {
            setFile({...value, isEmpty: (value.url == null && value.file == null)});
        }

    }, [value]);

    function handleUpload(event) {
        const newValue = {
            ...file,
            file: event.target.files[0],
            isEmpty: false,
            nombre: event.target.files[0].name,
            type:event.target.files[0].type
        };
        setFile(newValue);
        if (onChange != null) {
            onChange(newValue, name);
        }
    }

    async function eliminar() {
        const result = await alerts.preguntarSiNo('¿Desea eliminar el archivo adjunto?');
        if (result === false)
            return;

        setFile({isEmpty: true});
        if (onChange != null) {
            onChange({isEmpty: true}, name);
        }
    }

    async function download() {
        if (file.file == null) {
            await service.get(`archivos/descargar/${empresaId}?url=${file.url}`,
                {
                    showLoading: true,
                    download: true,
                    fileName: file.nombre
                })
        } else {
            FileDownload(file.file, file.nombre);
        }

    }

    return (
        <>
            <div className={`image-upload ${(error || errors?.[name]) ? 'error' : ''}`}>
                {file.isEmpty && !disabled &&
                <>
                    <label htmlFor={uniqueName}>
                        <IconLibrary iconName="BsSearch"/>
                    </label>
                    < input type="file" accept="image/png" id={uniqueName} onChange={handleUpload}/>
                </>
                }
                {!file.isEmpty &&
                <>
                    {(file.extension === 'pdf' || file.extension === 'png' || file.extension === 'jpg') &&
                    <label className="download" onClick={() => setVistaPrevia(true)}>
                        <IconLibrary iconName="GrFormView"/>
                    </label>
                    }

                    <label className="download" onClick={download}>
                        <IconLibrary iconName="MdAttachFile"/>
                    </label>

                    {!disabled &&
                    <label onClick={eliminar}>
                        <IconLibrary iconName="RiDeleteBinLine"/>
                    </label>
                    }
                </>
                }
            </div>
            {/*Modal con la vista previa del documento pdf*/}
            {(!file.isEmpty && file.extension === 'pdf') &&
            <PdfViewer header="Vista previa del documento" urlApi={`archivos/vistaPrevia/${empresaId}?url=${file.url}`}
                       descargar={download} visible={vistaPrevia}
                       hide={() => setVistaPrevia(false)} />
            } 
            {(!file.isEmpty && (file.extension === 'jpg' || file.extension === 'png')) &&
                <ImageViewer header="Vista previa del documento" name="foto" value={file} onChange={null} hide={() => setVistaPrevia(false)}  visible={vistaPrevia}  urlApi={`archivos/vistaPrevia/${empresaId}?url=${file.url}`} />
            }
        </>
    )
};


export default UploadImageFile;
