import React, {useState} from "react";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";

import FieldTextArea from "../../../shared/controls/fieldTextArea";
import useApiEmpleados from "../../../api/catalogos/useApiEmpleados";
import FieldCalendar from "../../../shared/controls/fieldCalendar";
import moment from "moment";



const ReIngresoEmpleado = ({empleado, onReingreso}) => {
    const [visibleModal, setVisibleModal] = useState(false);
    
    const [fechaReIngreso, setFechaReIngreso] = useState(moment().startOf('day').toDate());
    const [comentarios, setComentarios] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const apiEmpleado = useApiEmpleados();

    const reingreso = async () => {
        setSubmitted(true);
        if (fechaReIngreso == null || comentarios == null) {
            return;
        }
        await apiEmpleado.reingreso(empleado.id, {comentarios, fechaReIngreso});
        setSubmitted(false);
        setVisibleModal(false);
        onReingreso();
    };

    const renderFooter = () => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => setVisibleModal(false)} className="p-button-text"/>
                <Button label="Confirmar" icon="pi pi-check" onClick={reingreso} autoFocus/>
            </div>
        );
    };

    return (
        <>
            <Dialog header="¿Desea reingresar al personal?" visible={visibleModal} style={{width: '30vw'}}
                    footer={renderFooter('displayBasic')} onHide={() => setVisibleModal(false)}>
                <div className="form-row" style={{overflow:'hidden'}}>
                    <FieldCalendar name="fechaReIngreso" label="Fecha de reingreso" colMd={6} className="small"
                                   error={fechaReIngreso == null  && submitted} showButtonBar={false}
                                   filter
                                   value={fechaReIngreso}
                                   onChange={setFechaReIngreso}/> 
                    <FieldTextArea name="comentarios" label="Comentarios" colMd={12} rows={6}
                                   value={comentarios} onChange={setComentarios}/>

                </div>
            </Dialog>

            <Button label="Reingresar" className="p-button-outlined p-button-success" type="button"
                    onClick={() => setVisibleModal(true)}/>
        </>
    )
};

export default ReIngresoEmpleado;
