/* eslint-disable react-hooks/exhaustive-deps */
import SeguimientoIcon from "../../../../icons/SeguimientoYMonitoreo/SeguimientoIcons";
import {OverlayPanel} from "primereact/overlaypanel";
import {useEffect, useRef, useState} from "react";
import {colorIndicadores, tipoIndicador} from "../SeguimentoConstantes";
import {useSeguimientoMonitoreo} from "../SeguimientoMonitoreoProvider";
import {
    contadorTotales,
    horasDeManejo,
    indicadoresBrecha,
    indicadoresDiesel,
    indicadoresGeocercaProhibida, indicadoresGps,
    indicadoresLastPosition,
    indicadoresMotor, indicadoresUnidadSinViaje,
    indicadoresVelocidad
} from "./configuracionTotales";



const icons = [
    {name: "Velocidad", id: tipoIndicador.velocidad, opciones: indicadoresVelocidad, title:'Velocidad'},
    {name: "Motor", id: tipoIndicador.motor, opciones: indicadoresMotor, title:'Ralenty'},
    {name: "GeocercaProhibida", id: tipoIndicador.geocercaProhibida, opciones: indicadoresGeocercaProhibida, title:'Zona prohibida'},
    {name: "LastPosition", id: tipoIndicador.lastPosition, opciones: indicadoresLastPosition, title: 'ETA'},
    {name: "Gps", id: tipoIndicador.gps, opciones: indicadoresGps, title: 'Señal GPS'},
    {name: "Diesel", id: tipoIndicador.diesel, opciones: indicadoresDiesel, title: 'Extracción de diesel'},
    {name: "Brecha", id: tipoIndicador.brecha, opciones: indicadoresBrecha, title:'Separación Caja'},
    {name: "SinViaje", id: tipoIndicador.unidadSinViaje, opciones: indicadoresUnidadSinViaje, title:'Unidad en movimiento sin viaje'},
    {name: "HorasManejo", id: tipoIndicador.horasManejo, opciones: horasDeManejo, title: 'Horas de manejo'}

]

const TotalIndicadores = () => {
    const op = useRef(null);
    const [indicador, setIndicador] = useState({opciones: []})
    const [totales, setTotales] = useState(new Map())

    const {viajes, filtro} = useSeguimientoMonitoreo()

    const filtroSeleccionado = {indicadorId: filtro.value.indicadorId, colorId: filtro.value.colorId}

    const mostrarDetalle = (e, indicador) => {
        setIndicador(indicador)
        op.current.toggle(e)
    }


    const obtenerMapIndicadorValue = (indicadorId) => {
        const opciones = icons.find(icon => icon.id === indicadorId)?.opciones ?? contadorTotales;
        return [indicadorId, new Map(opciones.map(o => [o.colorId, 0]))]
    }

    const obtenerTotales = (viajes) => {
        let totales = new Map([
            obtenerMapIndicadorValue(tipoIndicador.velocidad),
            obtenerMapIndicadorValue(tipoIndicador.motor),
            obtenerMapIndicadorValue(tipoIndicador.geocercaProhibida),
            obtenerMapIndicadorValue(tipoIndicador.gps),
            obtenerMapIndicadorValue(tipoIndicador.lastPosition),
            obtenerMapIndicadorValue(tipoIndicador.diesel),
            obtenerMapIndicadorValue(tipoIndicador.brecha),
            obtenerMapIndicadorValue(tipoIndicador.unidadSinViaje),
            obtenerMapIndicadorValue(tipoIndicador.horasManejo),
            obtenerMapIndicadorValue(tipoIndicador.totales)
        ])

        const totalesViajes = totales.get(tipoIndicador.totales);

        for (let viaje of viajes) {
            if (viaje?.indicadores == null)
                break;


            for (let indicadorId in viaje.indicadores) {
                indicadorId = parseInt(indicadorId)
                if (viaje.indicadores[indicadorId] == null)
                    continue
                const colorId = viaje.indicadores[indicadorId].color
                let totalIndicador = totales.get(indicadorId);
                const total = totalIndicador.get(colorId) + 1;
                totalIndicador.set(colorId, total);
            }

            const total = totalesViajes.get(viaje.color) + 1;
            totalesViajes.set(viaje.color, total);
        }
        totales.set(tipoIndicador.totales, totalesViajes)
        setTotales(totales)
    }

    useEffect(() => {

        obtenerTotales(viajes)
    }, [viajes])

    const seleccionarFiltro = (indicadorId, colorId) => {
        op.current.hide()
        if (filtroSeleccionado.indicadorId === indicadorId && filtroSeleccionado.colorId === colorId) {
            filtro.setValue({...filtro.value, indicadorId: null, colorId: null})
            return
        }
        filtro.setValue({...filtro.value, indicadorId, colorId})
    }

    const getClassSeleccionado = (colorId) => {
        return filtroSeleccionado.indicadorId === indicador.id && filtroSeleccionado.colorId === colorId ?
            'seleccionado' : ''
    }

    const getColorSeleccionado = (indicadorId) => {
        return filtroSeleccionado.indicadorId === indicadorId ?
            colorIndicadores[filtroSeleccionado.colorId] : 'gris'
    }

    const getContraste = (indicadorId) => {
        const color = getColorSeleccionado(indicadorId)
        if (color === '')
            return ''

        return color !== 'gris' && color !== 'amarillo'
            ? 'Contraste' : '';
    }

    const getTotalSeleccionado = (indicadorId) => {
        if (filtroSeleccionado.indicadorId !== indicadorId)
            return null;

        return totales.get(indicadorId).get(filtroSeleccionado.colorId);
    }

    const getTotal =(indicador, color)=>{
        return  totales.get(indicador.id).get(color.colorId)
    }

    return (
        <>
            <div className="seg-total-indicadores">
                <div className={`seg-total`} style={{cursor: 'default'}} onClick={(e)=>
                    mostrarDetalle(e, {id:tipoIndicador.totales, opciones: contadorTotales })}
                >
                    {viajes.length}
                </div>
                <div className={`seg-indicadores`}>
                    {icons.map(icon =>
                        <div key={icon.name} className={`seg-total-icon`}
                             onClick={(e) => mostrarDetalle(e, icon)}
                             title={icon.title}>
                            <span className={`${getColorSeleccionado(icon.id)}`}>
                                <SeguimientoIcon iconName={`${icon.name}${getContraste(icon.id)}`}
                                                 continuePropagation={true}/>
                            </span>
                            <span className={`seg-total-cantidad`}>
                                {getTotalSeleccionado(icon.id)}
                            </span>
                        </div>
                    )}
                </div>
            </div>
            <OverlayPanel ref={op} id="overlay_panel" dismissable={true}
                          style={{width: '90px'}} className="seg-overlay-indicador">
                <div className={'seg-filtros-indicadores'}>
                    {indicador.opciones.map(color =>
                        <div className={`seg-filtro-indicador`} key={color.colorId} title={color.tooltip}
                             onClick={() => seleccionarFiltro(indicador.id, color.colorId)}>
                            <div className={`seg-filtro ${color.color} ${getClassSeleccionado(color.colorId)}`}>
                            </div>
                            <span>
                                {getTotal(indicador,color)}
                            </span>
                        </div>
                    )}
                </div>
            </OverlayPanel>
        </>


    )
}

export default TotalIndicadores