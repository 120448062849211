const ValidacionClabeTarjeta = (value) => {
  value = value.replace(/\s+/g, '').replace(/^\(|\)$/g, "");
  if (/^\d+$/.test(value)) {
    if (value.length >= 1) {               
     if (isValidClabe(value)) {
         return { isValid: true }; 
     } else {
       return { isValid: false, error: 'CLABE no válida' };
     }
   }
   else if (value.length >= 1) {
     return { isValid: false, error: 'La longitud del número es incorrecta.' };
   }
 }
  function isValidClabe(clabe) {
    if (clabe.length !== 18) return false;

    const factors = [3, 7, 1];
    let sum = 0;

    for (let i = 0; i < 17; i++) {
        const n = parseInt(clabe[i], 10);
        sum += n * factors[i % 3];
    }

    let checkDigit = 10 - (sum % 10);
    if (checkDigit === 10) checkDigit = 0;

    return checkDigit === parseInt(clabe[17], 10);
  }
}   

export default ValidacionClabeTarjeta;