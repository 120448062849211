import useServiceFactory from "../serviceFactory";

const apiUrl = `/operaciones/viajes/QS3`;

const useApiViajesFreightVerify = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URL});
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, {params, showLoading: true}),
        excel: async (params) => service.get(`${apiUrl}/excel`,
        {
            params,
            showLoading: true,
            download: true,
            fileName: `ViajesQS3.xlsx`
        }),
    }
};

export default useApiViajesFreightVerify;
