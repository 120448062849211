/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./prefacturacion.css";
import useApiPrefacturacion from "../../../api/operaciones/useApiPrefacturacion";
import { useEffect, useState } from "react";
import moment from "moment";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import { produce } from "immer";
import { Button } from "primereact/button";
import FieldTextArea from "../../../shared/controls/fieldTextArea";
import * as numeral from "numeral";
import { Dialog } from "primereact/dialog";
import FieldInputNumber from "../../../shared/controls/fieldInputNumber";
import constantes from "../../../catalogos/constantes";
import alerts from "../../../shared/alerts";

const PrefacturacionDetalle = ({ prefacturaId, close, onSave }) => {
    const api = useApiPrefacturacion();
    const [prefactura, setPrefactura] = useState({});
    const [cambioDireccionVisible, setCambioDireccionVisible] = useState(false);

    useEffect(() => {
        async function cargar() {
            const pref = await api.obtener(prefacturaId);
            setPrefactura(pref);
        }
        cargar().then();
    }, []);

    const actualizarConceptos = (value, propertyName, index) => {
        const newValue = produce(prefactura.conceptos, (draft) => {
            draft[index][propertyName] = value;
        });

        setPrefactura({ ...prefactura, conceptos: newValue })
    };

    const guardar = async (index) => {
        await api.actualizarDetalle(prefactura.id, prefactura);
        close();
    };

    const enviarPrefacturacion = async (index) => {
        await api.enviadoFacturar(prefactura.id, prefactura);
        onSave();
    };
    const marcarFacturado = async () => {
        if(prefactura.numeroFactura == null || prefactura.numeroFactura ==='') {
            alerts.aviso('Se requiere el número de factura');
        } else {
            await api.marcarFacturado(prefactura.id, prefactura);
            onSave();
        }
    };    
    const SeleccionarDireccion = async (nuevadireccion) => {

        setPrefactura({ ...prefactura, direccion: nuevadireccion })
        setCambioDireccionVisible(false);
    };

    const actualizar = (value, propertyName) => {
        if (propertyName === "descuentoPorcentaje") {
            var descuento = (value / 100) * prefactura.subtotal;
            var total = prefactura.subtotal - descuento;
            setPrefactura({ ...prefactura, descuento: descuento, descuentoPorcentaje: value, total: total })
            return;
        }

        const nuevo = produce(prefactura, draft => {
            draft[propertyName] = value
        });

        setPrefactura(nuevo);
    };

    return (
        <>



            <div className="form-container full-height">
                <div className="bg-light p-3">
                    <div className="prefacturacion-first">
                        <div>
                            <b>{prefactura.clienteNombre}</b>
                            <div>RFC: {prefactura.rfc}</div>
                            <div>{prefactura.direccion?.calle} {prefactura.direccion?.noExterior} {prefactura.direccion?.NoInterior} </div>
                            <div>{prefactura.direccion?.colonia} CP {prefactura.direccion?.codigoPostal}</div>
                            <div>{prefactura.direccion?.municipio?.nombre} {prefactura.direccion?.estado?.nombre}</div>
                        </div>
                        <div className="prefacturacion-header">
                            <div>
                                <b>ID</b>
                                <div>
                                    <FieldTextBox name="idViaje" onChange={(value, pname) => actualizar(value, pname)} value={prefactura.idViaje} rows={2} disabled={!prefactura.idViajeEditable} />
                                </div>
                            </div>
                            <div>{moment(prefactura.fechaInicio).format('DD/MM/YYYY')}</div>
                        </div>
                    </div>
                    <div style={{ float: "right", marginTop: "-10px", marginRight: "70px" }} >
                        <div>
                            <button type="button" className="btn btn-link btn-sm"
                                onClick={() => setCambioDireccionVisible(true)}
                                >
                                Cambiar Direccion Fiscal
                            </button>
                        </div>
                    </div>
                    <div className="prefacturacion-header">
                        <div>
                            <b>ORIGEN</b>
                            <div>{!prefactura.tieneAgrupados ? prefactura.origen : "Varios"}</div>
                        </div>

                        <div>
                            <b>DESTINO</b>
                            <div>{!prefactura.tieneAgrupados ? prefactura.destino : "Varios"}</div>
                        </div>
                    </div>

                    <div className="prefacturacion-resumen">
                        <div>
                            <div><b>UNIDAD</b></div>
                            <div>{!prefactura.tieneAgrupados ? prefactura.unidad?.numeroEconomico : "Varios"}</div>
                        </div>

                        <div>
                            <div><b>PLACA</b></div>
                            <div>{!prefactura.tieneAgrupados ? prefactura.unidad?.placas : "Varios"}</div>
                        </div>
                        <div>
                            <div><b>CAJA</b></div>
                            <div>{!prefactura.tieneAgrupados ? prefactura.caja : "Varios"}</div>
                        </div>
                        <div>
                            <div><b>PESO</b></div>
                            <div>{!prefactura.tieneAgrupados ? prefactura.peso : "Varios"}</div>
                        </div>
                        <div>
                            <div><b>CARTA PORTE</b></div>
                            <div>{!prefactura.tieneAgrupados ? prefactura.cartaPorte : "Varios"}</div>
                        </div>
                        <div>
                            <div><b>OPERADOR</b></div>
                            <div>{!prefactura.tieneAgrupados ? prefactura.operador : "Varios"}</div>
                        </div>

                        <div>
                            <div><b>KM</b></div>
                            <div>{prefactura.km}</div>
                        </div>

                    </div>
                </div>

                <div className="mt-4 liberacion-documentos">
                    <table className="table align-middle">
                        <thead className="thead-light">
                            <tr>
                                <th className="center" style={{ width: '2%' }}></th>
                                <th className="left" style={{ width: '30%' }}>UNIDAD DE MEDIDA</th>
                                <th className="center" style={{ width: '45%' }}>DESCRIPCION</th>
                                <th className="center" style={{ width: '22%' }}>PRECIO</th>
                                <th className="center" style={{ width: '22%' }}>IMPORTE</th>
                            </tr>
                        </thead>
                        <tbody>

                            {prefactura.conceptos?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.facturasAgrupadas != null && (item.facturasAgrupadas.split(",").length)} </td>
                                        <td>SERVICIO &#40;E48&#41;</td>
                                        <td className="input">
                                            <FieldTextArea name="descripcion"
                                                onChange={(value, pname) => actualizarConceptos(value, pname, index)} value={item.descripcion} rows={3}/>
                                        </td>
                                        <td className="center">{numeral(item.precio).format('$0,0.00')}</td>
                                        <td className="center">{numeral(item.importe).format('$0,0.00')}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>


                <div className="prefacturacion-header">
                    <div>
                        <b>Moneda</b>
                        <div style={{ marginTop: "3px", marginBottom: "3px" }}>{prefactura.moneda}</div>
                        <b>Condiciones de Pago</b>
                        <div style={{ marginTop: "3px", marginBottom: "3px" }}>{prefactura.formaPago}</div>
                        <b>Tipo Pago</b>
                        <div style={{ marginTop: "3px", marginBottom: "3px" }}>{prefactura.metodoPago}</div>
                    </div>

                    <div>
                        <div style={{ float: "right" }} className="prefacturacion-five">
                            <div >
                                <b>Subtotal</b></div>
                            <div>{numeral(prefactura.subtotal).format('$0,0.00')}</div>
                        </div>
                        <div style={{ float: "right", marginTop: "6px" }} className="prefacturacion-header-seven">
                            <div>

                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">%</span>
                                    <FieldInputNumber onChange={(value) => actualizar(value, "descuentoPorcentaje")} value={prefactura.descuentoPorcentaje} name="descuentoPorcentaje" fractionDigits={2}/>
                                </div>
                            </div>

                            <div style={{ marginTop: "8px" }}>
                                <b>Descuento</b></div>
                            <div style={{ marginTop: "8px" }}>{numeral(prefactura.descuento).format('$0,0.00')}</div>
                        </div>
                        <div style={{ float: "right", marginTop: "6px" }} className="prefacturacion-five">
                            <div >
                                <b>Total</b></div>
                            <div style={{ marginTop: "5px" }}>{numeral(prefactura.total).format('$0,0.00')}</div>
                        </div>
                    </div>
                </div>
                {(prefactura.estatusId === constantes.estatusPrefacturacion.enviadoAFacturacion
                    || prefactura.estatusId === constantes.estatusPrefacturacion.facturado || prefactura.facturaCCP !==0) &&
                <>
                    <br/>
                    <div className="form-row">
                        <FieldTextBox name="numeroFactura" onChange={(value, pname) => actualizar(value, pname)}
                        value={prefactura.numeroFactura} upperCase={true} label="Número Factura" colSm={4} />
                        <FieldInputNumber name="totalFactura" onChange={(value, pname) => actualizar(value, pname)}
                        value={prefactura.totalFactura} label="Total Factura" colSm={4}/>
                    </div>
                </>
                }
                <div className="form-row">
                    <FieldTextArea label="Observaciones" name="observaciones" onChange={(value) => actualizar(value, "observaciones")} value={prefactura.observaciones} colMd={12} rows={3}/>
                </div>
                <Dialog header="Seleccione una direccion" visible={cambioDireccionVisible} style={{ width: '50vw' }}
                    onHide={() => setCambioDireccionVisible(false)}>
                    <div className="form-row">
                        <table style={{ position: "initial" }} className="table table-sm table-striped table-hover table-fixed p-dropdown-panel py-2">
                            <thead className="thead-light">
                                <tr>
                                    <th style={{ width: '98%' }}>Direcciones</th>
                                    <th style={{ width: '5%' }}></th>
                                </tr>
                            </thead>
                            <tbody>

                                {prefactura.direcciones?.map((item, index) => {
                                    return (
                                        <tr onClick={() => SeleccionarDireccion(item.direccionFiscal)} key={index}>
                                            <td style={{ width: '98%' }} >{item.direccionCompleta},{item.direccionFiscal.externalId}</td>
                                            <td style={{ width: '5%' }}>
                                                {prefactura.direccion.id === item.direccionFiscal.id &&
                                                    <div className="d-flex"><div className={`circle-status success`} />
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </Dialog>
            </div>

            <div className="form-footer">
                {prefactura.facturaId === 0 && prefactura.facturaCCP ===0 &&
                    <>
                        <Button label="Guardar" className="p-button-outlined" onClick={guardar} />
                        <Button className="btn-primary" label='Facturar' onClick={enviarPrefacturacion} type="button" />
                    </>
                }
                {prefactura.estatusId === constantes.estatusPrefacturacion.enviadoAFacturacion
                && prefactura.facturaSinTimbrar 
                &&
                    <>
                        <Button label="Guardar Factura" className="p-button-outlined" onClick={marcarFacturado} />
                    </>
                }

            </div>
        </>
    )
};

export default PrefacturacionDetalle
