import React, { useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import agGridHelper from "../../shared/ag-grid-helper";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import { getSessionState } from "../../hooks/useSessionState";
import Filtro from "../../shared/componentes/filtro";
import { Sidebar } from "primereact/sidebar";
import DispersionDetalle from "./dispersionDetalle";
import FieldCalendarRange from "../../shared/controls/fieldCalendarRange";
import useApiDispersion from "../../api/administracion/useApiDispersion";
import constantes from "../../catalogos/constantes";

const Dispersion = ({ esquemaPago, semanaLaboral, soloLectura }) => {
    const filtroKey = 'FiltroDispersion' +
        ((esquemaPago === constantes.esquemaPago.liquidacion) ? "Operativa" :
            ((esquemaPago === constantes.esquemaPago.staff) ? "Staff" :
                (esquemaPago === constantes.esquemaPago.oficina) ? "Oficina" : "")
        );
    const esOperador = esquemaPago === constantes.esquemaPago.liquidacion;
    const [datos, setDatos] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [dispersion, setDispersion] = useState({});
    const api = useApiDispersion();

    const onGridReady = async (params) => {
        setGridApi(params.api);
    };

    async function buscar(filtro, paramsApi) {
        filtro = filtro ?? getSessionState(filtroKey);
        agGridHelper.showLoad(gridApi, paramsApi, true);
        var datos = await api.filtrar({ ...filtro, esquemaPago: esquemaPago });
        setDatos(datos);
        agGridHelper.hideLoad(gridApi, paramsApi, false);
    }

    async function onSave() {
        setSidebarVisible(false);
        const filtro = getSessionState(filtroKey);
        await buscar(filtro);
    }

    const verDispersion = async (id) => {
        const dispersionActual = await api.obtener(id);
        setSidebarVisible(true);
        setDispersion(dispersionActual);
    };

    function exportar(id) {
        api.excel(id, esOperador);
    }

    async function crearDispersion() {
        await api.dispersion(esquemaPago);
        const filtro = getSessionState(filtroKey);
        await buscar(filtro);
    }

    const ButtonsHeader = () => {
        return (
            <div className="ag-grid header-icons">
                <ButtonIcon marginTop="1px" color="#2760D3" title="Generar Dispersión"
                    className="grid-action" iconName={'AiFillDollarCircle'} onClick={crearDispersion} />
            </div>
        );
    };

    const RowButtons = ({ data, context }) => (
        <div className={'ag-grid row-icons'}>
            <ButtonIcon title={'Exportar'} iconName={'BiDownload'} onClick={() => context.exportar(data.id)} />
            <ButtonIcon title={'Ver'} iconName={'FaRegEdit'} onClick={() => context.verDispersion(data.id)} />
        </div>
    );

    return (
        <>
            <Filtro filtroKey={filtroKey} handleSubmit={buscar} fechasDiasInicio={7} fechasRangoDias={7}>
                <Filtros esOperador={esOperador} semanaLaboral={semanaLaboral} />
            </Filtro>

            <Sidebar visible={sidebarVisible} position="right" dismissable={false} className="p-sidebar-md"
                onHide={() => setSidebarVisible(false)}>
                <DispersionDetalle onSave={onSave} dispersionActual={dispersion} esOperador={esOperador}
                    onClose={() => setSidebarVisible(false)} soloLectura={soloLectura} />
            </Sidebar>

            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    frameworkComponents={
                        {
                            buttonsHeader: ButtonsHeader,
                            rowButtons: RowButtons,
                            dateFormatter: agGridHelper.DateTimeFormatter,
                        }
                    }
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    rowHeight={35}
                    context={{
                        verDispersion: verDispersion,
                        exportar: exportar
                    }}
                >
                    <AgGridColumn field="fecha" headerName="FECHA" cellRenderer="dateFormatter" flex={5} />
                    <AgGridColumn field="id" headerName="FOLIO" headerClass={'right'} cellClass={'right'} />
                    <AgGridColumn field="totalComision" headerName="COMISIÓN" headerClass={'right'} cellClass={'right'} hide={!esOperador}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalPrestamos" headerName="PRÉSTAMOS" headerClass={'right'} cellClass={'right'}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalCajaAhorro" headerName="CAJA AHORRO" headerClass={'right'} cellClass={'right'}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalHorasExtra" headerName="HORAS EXTRA" headerClass={'right'} cellClass={'right'} hide={esOperador}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalAnticipos" headerName="ANTICIPOS" headerClass={'right'} cellClass={'right'} hide={!esOperador}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalCompensaciones " headerName="COMPENSACIONES" headerClass={'right'} cellClass={'right'} hide={!esOperador}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalGastos " headerName="GASTOS" headerClass={'right'} cellClass={'right'} hide={!esOperador}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalDescuentos " headerName="DESCUENTOS" headerClass={'right'} cellClass={'right'} hide={!esOperador}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalGastosCajaChica" headerName="CAJA CHICA" headerClass={'right'} cellClass={'right'} hide={!esOperador}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalAjuste" headerName="AJUSTE" headerClass={'right'} cellClass={'right'} hide={!esOperador}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalBonos" headerName="BONOS" headerClass={'right'} cellClass={'right'}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalVacaciones" headerName="VACACIONES" headerClass={'right'} cellClass={'right'}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="totalImpuestos" headerName="IMPUESTOS" headerClass={'right'} cellClass={'right'}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn flex={5} field="totalPensionAlimenticia" headerName="PENSION ALIMENTICIA" headerClass={'right'} cellClass={'right'}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="total" headerName="MONTO" headerClass={'right'} cellClass={'right'}
                        valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn maxWidth={80}
                        headerName="VER"
                        cellRenderer="rowButtons"
                        pinned={'right'}
                        cellClass={'left'}
                        headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>
        </>
    )
};

const Filtros = ({ filtro, onChange, semanaLaboral }) => {
    return (
        <>
            <FieldCalendarRange name="fechas" label="Fechas" colMd={2} value={filtro.fechas} onChange={onChange} />
            <div className="form-group col-md-10 col-sm-12 right" >
                <label><b>Semana Laboral:</b> </label>
                {
                    semanaLaboral.fechaInicio && semanaLaboral.fechaFin &&
                    <span>
                        {` ${semanaLaboral.fechaInicio} - ${semanaLaboral.fechaFin}`}
                    </span>
                }
            </div>
        </>
    )
};

export default Dispersion;
