/* eslint-disable react-hooks/exhaustive-deps */
import Form from "../../../../shared/forms/Form";
import Field from "../../../../shared/forms/Field";
import FieldTextArea from "../../../../shared/controls/fieldTextArea";
import {Dialog} from "primereact/dialog";
import React, {useEffect, useState} from "react";
import {Button} from "primereact/button";
import useForm from "../../../../shared/forms/useForm";
import useApiSeguimientoMonitoreo from "../../../../api/operaciones/useApiSeguimientoMonitoreo";

const AtenderIncidenciaModal = ({viajeId, incidenciaActual, setIncidenciaActual, onChangeBitacora}) => {

    const [submitted, setSubmitted] = useState(false);
    const api = useApiSeguimientoMonitoreo()

    const {
        values,
        onChange,
        onValidate,
        isValid,
        setValues
    } = useForm();

    useEffect(() => {
        if (incidenciaActual != null)
            setValues({comentarios: incidenciaActual.comentarios})
    }, [incidenciaActual]);


    const visibleComentarios = incidenciaActual != null

    const enviarComentarios = async () => {

        setSubmitted(true);

        if (!isValid)
            return;

        const updated = await api.atenderBitacora(viajeId, incidenciaActual.id, values)
        setIncidenciaActual(null)
        onChangeBitacora({...incidenciaActual, ...updated})
    }

    const renderFooterModal = () => {
        return (
            <>
                    <div className="p-1">
                        <Button label={ incidenciaActual?.atendido ? 'Actualizar' : `Registrar`} onClick={enviarComentarios} type="button"/>
                    </div>
            </>
        );
    };

    return (
        <Dialog header="Seguimiento Incidencia" visible={visibleComentarios} style={{width: '400px'}}
                onHide={() => setIncidenciaActual(null)}
                footer={renderFooterModal}
        >
            <Form columns={1} values={values} onChange={onChange} onValidate={onValidate} submitted={submitted}>
                <Field label={'Comentarios'} field={'comentarios'} render={FieldTextArea} rows={5} required={true}/>
            </Form>
        </Dialog>
    )
}
export default AtenderIncidenciaModal;