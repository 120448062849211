import useServiceFactory from './serviceFactory'

const useApi = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URL});
    return {
        post: async (api, params, config) => service.post(api, params, config),
        get: async (api, params, config) => service.get(api, {...params, ...config})
    }
};

export default useApi;
