import { Sidebar } from "primereact/sidebar";
import Filtro from "../../shared/componentes/filtro";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import useApiConfiguracionLear from "../../api/operaciones/useApiConfiguracionLear";
import React, { useState } from "react";
import useSeguridad from "../../Seguridad/useSeguridad";
import constantes from "../../catalogos/constantes";
import agGridHelper from "../../shared/ag-grid-helper";
import FieldCalendarRange from "../../shared/controls/fieldCalendarRange";
import { getSessionState } from "../../hooks/useSessionState";
import DetalleConfiguracionLear from "./DetalleConfiguracionLear";

const fechaInicio = new Date();
fechaInicio.setDate(fechaInicio.getDate() - 14);
const defaultFilter = { fechaInicio: [fechaInicio, new Date()] };

const ConfiguracionLear = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.configuracionLear)
    const api = useApiConfiguracionLear();
    const [datos, setDatos] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const filtroKey = 'FiltroConfiguracionLear';
    const [nuevo, setNuevo] = useState(false);

    const buscar = async (filtro, paramsApi) => {
        agGridHelper.showLoad(gridApi, paramsApi, true);
        setDatos(await api.filtrar(filtro ?? getSessionState(filtroKey)));
        agGridHelper.hideLoad(gridApi, paramsApi, false);
    };

    const onGridReady = async (params) => {
        setGridApi(params.api);
    };

    return (
        <>
            <Sidebar visible={nuevo} position="right" dismissable={false}
                className="p-sidebar-lg" onHide={() => setNuevo(false)}>
                <DetalleConfiguracionLear soloLectura={soloLectura} onSave={() => setNuevo(false)} onChange={setDatos} />
            </Sidebar>
            <Filtro filtroKey={filtroKey} handleSubmit={buscar} classButtons="field-iconos btn-group" gridApi={gridApi}
                defaultValue={defaultFilter}>
                {!soloLectura &&
                    <button type="button" onClick={() => setNuevo(true)}
                        className={'btn btn-sm btn-primary'}>
                        Cargar
                    </button>
                }
                <FieldCalendarRange name="fechaInicio" label={'Fechas'} colLg={2} colMd={3}
                    value={defaultFilter.fechaInicio} />
            </Filtro>
            <div className="ag-theme-balham grid-principal com-usuarioes">
                <AgGridReact enableSroting={true} enableFilter={true} pagination={true}
                    className="left" onGridReady={onGridReady} rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns} rowHeight={35}
                    components={{
                        dateFormatter: agGridHelper.DateFormatter
                    }}>
                    <AgGridColumn field="razonSocial" headerName="Razon Social" text cellStyle={{ textAlign: 'left' }} />
                    <AgGridColumn field="convenio" headerName="Convenio" text cellStyle={{ textAlign: 'left' }} />
                    <AgGridColumn field="codigoRuta" headerName="Codigo de la Ruta" text cellStyle={{ textAlign: 'left' }} />
                    <AgGridColumn field="planta" headerName="Planta" text cellStyle={{ textAlign: 'left' }} />
                    <AgGridColumn field="fechaInicio" headerName="Fecha Inicio" cellRenderer="dateFormatter" />
                    <AgGridColumn field="fechaFin" headerName="Fecha Fin" cellRenderer="dateFormatter" />
                    <AgGridColumn field="porcentajeAllocation" headerName="% Allocation" cellRenderer={agGridHelper.decimalFormatter} cellClass={'right'} />
                    <AgGridColumn field="loadedCost" headerName="Loaded Cost" cellRenderer={agGridHelper.currencyFormatter} cellClass={'right'} />
                    <AgGridColumn field="stopCharges" headerName="Stop Charges" cellRenderer={agGridHelper.currencyFormatter} cellClass={'right'} />
                </AgGridReact>
            </div>
        </>
    );
};

export default ConfiguracionLear;