/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useState } from "react";
import "../administracion.css";
import { useEffect } from "react";
import { Button } from "primereact/button";
import formReducer from "../../shared/forms/formReducer";
import FormHandler from "../../shared/forms/formHandler";
import FieldDropDown from "../../shared/controls/fieldDropDown";
import FieldInputNumber from "../../shared/controls/fieldInputNumber";
import FieldCalendar from "../../shared/controls/fieldCalendar";
import FieldTextArea from "../../shared/controls/fieldTextArea";
import vacacionesValidacionEsquema from "./vacacionesValidacionEsquema";
import useApiVacaciones from "../../api/administracion/useApiVacaciones";
import FieldCheckBox from "../../shared/controls/fieldCheckBox";
import alerts from "../../shared/alerts";
const DetalleVacaciones = ({ vacacionesId, onSave, close, soloLectura }) => {
    const api = useApiVacaciones();

    const defaultEntity = () => ({ entity: { id: 0, editable: true } });
    const [{ entity, errors }, dispatch] = useReducer(formReducer, {}, defaultEntity);
    const [empleado, setEmpleado] = useState({});

    const formHandler = new FormHandler({
        validationSchema: vacacionesValidacionEsquema,
        api,
        dispatch,
    });

    useEffect(() => {
        async function cargar() {
            dispatch({ type: "update", value: await api.obtener(vacacionesId) });
        }

        if (vacacionesId > 0) {
            cargar().then();
        }
    }, []);

    const actualizar = (value, propertyName) => {
        dispatch({ type: 'update', value: value, name: propertyName });
    };

    const actualizarEmpleado = async (value, propertyName) => {
        if (value != null) {
            const empleado = await api.datosEmpleado({ empleadoId: value.id });
            setEmpleado(empleado);

            if (empleado.diasDisponibles <= 0){

                alerts.aviso('No tienes días para disfrutar vacaciones');
            }
        }

        actualizar(value, propertyName);
    };

    const guardar = async () => {
        await formHandler.save(entity);
        if (entity.diasDisponibles <= 0){
            alerts.aviso('El empleado no cuenta con días para disfrutar vacaciones');
        }
        if (formHandler.valdation.result) {
            onSave();
        }
    };

    return (
        <>
            <div className="form-container full-height" style={{ paddingLeft: "1px" }}>
                {entity.id === 0 ?
                    <div className="form-row">
                        <h5 className="col-12">Vacaciones</h5>
                        <FieldDropDown name="empleado" source="empleados" errors={errors} label="Empleado" colSm={12}
                                value={entity.empleado} onChange={actualizarEmpleado} filter />                        
                        <div className="col-sm-8" />
                        <FieldInputNumber name="dias" label="Días" value={entity.dias} colSm={4}
                            errors={errors} onChange={actualizar} />

                        <div className="col-sm-4 d-flex flex-column justify-content-center pl-2">
                            <div><b>Días Permitidos</b></div>
                            <div>
                                {empleado?.diasPorAntiguedad}
                            </div>
                        </div>

                        <div className="col-sm-4 d-flex flex-column justify-content-center">
                            <div><b>Días Disponibles</b></div>
                            <div>{empleado?.diasDisponibles}</div>
                        </div>

                        <FieldCalendar name="fechaPago" label="Fecha Pago" colSm={4} value={entity.fechaPago}
                            className="xs" showButtonBar={false} errors={errors} onChange={actualizar} />

                        <div className="col-8">
                            <label>Fecha de Vacaciones</label>
                            <div className="d-flex">
                                <FieldCalendar name="fechaInicio" value={entity.fechaInicio} showButtonBar={false}
                                    className="xs" errors={errors} onChange={actualizar} />
                                <FieldCalendar name="fechaRegreso" value={entity.fechaRegreso} showButtonBar={false}
                                    className="xs" errors={errors} onChange={actualizar} />
                            </div>
                        </div>

                        <FieldTextArea name="nota" label="Nota" value={entity.nota} colSm={12}
                            onChange={actualizar} rows={4} errors={errors} />

                        <FieldCheckBox name="vacacionesTrabajadas" value={entity.vacacionesTrabajadas} className="m-0"
                            onChange={actualizar} label="Vacaciones Trabajadas" colSm={12} />
                    </div>
                    :
                    <>
                        <div className="bg-light p-3">
                            <div>
                                {entity.empleado}
                            </div>

                            <div className="adm-resumen vacaciones">
                                <div>
                                    <div><b>Fecha Ingreso</b></div>
                                    <div>{entity.fechaIngreso}</div>
                                </div>

                                <div>
                                    <div><b>Antigüedad</b></div>
                                    <div>{entity.antiguedad}</div>
                                </div>

                                <div>
                                    <div><b>Días p/Antigüedad</b></div>
                                    <div>{entity.diasPorAntiguedad}</div>
                                </div>

                                <div>
                                    <div><b>Días Tomados</b></div>
                                    <div>{entity.diasTomados}</div>
                                </div>

                                <div>
                                    <div><b>Días Disponibles</b></div>
                                    <div>{entity.diasDisponibles}</div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-4">
                            <table className="table align-middle">
                                <thead className="thead-light">
                                    <tr>
                                        <th className="center" style={{ width: '35%' }}>Fecha Solicitados</th>
                                        <th className="center" style={{ width: '15%' }}>Dias</th>
                                        <th className="center" style={{ width: '25%' }}>Vacaciones Trabajadas</th>
                                        <th />
                                    </tr>
                                </thead>

                                <tbody>
                                    {entity.historial?.map((item, index) => {
                                        return (
                                            <tr className="text-center" key={index}>
                                                <td>{item.fecha}</td>
                                                <td>{item.dias}</td>
                                                <td>
                                                    <FieldCheckBox value={item.vacacionesTrabajadas} disabled colMd={12}
                                                        className="justify-content-center" />
                                                </td>
                                                <td>{item.estatus}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </>
                }
            </div>

            <div className="form-footer">
                <Button label="Salir" className="p-button-outlined" onClick={close} />

                {entity.id === 0 && !soloLectura &&
                    <Button className="btn-primary" label="Solicitar" type="button" onClick={guardar} />
                }
            </div>
        </>
    )
};

export default DetalleVacaciones
