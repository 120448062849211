import React, { useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import agGridHelper from "../../shared/ag-grid-helper";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import { getSessionState } from "../../hooks/useSessionState";
import Filtro from "../../shared/componentes/filtro";
import FieldCalendarRange from "../../shared/controls/fieldCalendarRange";
import FieldMultiSelect from "../../shared/controls/fieldMultiSelect";
import moment from "moment";
import useApiVacaciones from "../../api/administracion/useApiVacaciones";
import constantes from "../../administracion/constantes";
import {    
    AgDateTimeFormatter    
    
} from "../../shared/ag-grid-helper/GridFormatters";
const VacacionesPorAutorizar = ({ soloLectura }) => {
    const filtroKey = 'FiltroVacacionesPorAutorizar';
    const [datos, setDatos] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const api = useApiVacaciones();

    const onGridReady = async (params) => {
        setGridApi(params.api);
    };

    async function buscar(filtro, paramsApi) {
        agGridHelper.showLoad(gridApi, paramsApi, true);
        setDatos(await api.filtrarPorAutorizar(filtro ?? getSessionState(filtroKey)));
        agGridHelper.hideLoad(gridApi, paramsApi, false);
    }

    function exportar() {
        api.excelPorAutorizar(getSessionState(filtroKey));
    }

    async function autorizar(id, esRechazar = false) {
        if (esRechazar) {
            await api.rechazar(id);
        } else {
            await api.autorizar(id);
        }

        await buscar();
    }

    const ButtonsHeader = () => {
        return (
            <div className="ag-grid header-icons">
                <ButtonIcon title="Descargar" onClick={exportar} iconName="BiDownload" />
            </div>
        );
    };

    const DateCellRenderer = ({ data }) => (
        <div>
            {moment(data.fechaInicio).format('DD/MM/YYYY')}
            - {moment(data.fechaRegreso).format('DD/MM/YYYY')}
        </div>
    );

    const AutorizarCellRenderer = ({ data }) => (
        <div className="d-flex align-items-center justify-content-center">
            {data.estatusId === constantes.estatusVacaciones.porAutorizar && !soloLectura &&
                <>
                    <button title="Rechazar" type="button" className="button-icon" onClick={() => autorizar(data.id, true)}>
                        <i className="pi pi-times-circle text-danger" />
                    </button>
                    < button title="Autorizar" type="button" className="button-icon" onClick={() => autorizar(data.id)}>
                        <i className="pi pi-check-circle text-success" />
                    </button>
                </>
            }
        </div>
    );

    return (
        <>
            <Filtro filtroKey={filtroKey} handleSubmit={buscar}>
                <Filtros />
            </Filtro>

            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    frameworkComponents={
                        {
                            buttonsHeader: ButtonsHeader,
                            dateCellRenderer: DateCellRenderer,
                            autorizarCellRenderer: AutorizarCellRenderer,
                            goToDetail: agGridHelper.GoToDetailFormatter
                        }
                    }
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    rowHeight={35}
                >
                    <AgGridColumn field="empleado" cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn field="dias" headerName="Días Solicitados" />
                    <AgGridColumn field="fecha" headerName="Fecha de Vacaciones" />
                    <AgGridColumn field="nota" />
                    <AgGridColumn field="estatus" />
                    <AgGridColumn field="fechaSolicitud" headerName="Fecha Solicitud" valueFormatter={AgDateTimeFormatter} minWidth={150} />
                    <AgGridColumn field="fechaAutorizacion" headerName="Fecha Autorización" valueFormatter={AgDateTimeFormatter} minWidth={150} />
                    <AgGridColumn field="monto" headerName="Monto" valueFormatter={agGridHelper.decimalFormatter}/>
                    <AgGridColumn maxWidth={100} cellRenderer="autorizarCellRenderer" headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>
        </>
    )
};

const Filtros = ({ filtro, onChange }) => {
    return (
        <>
            <FieldMultiSelect name="empleados" source="empleados" label="Empleado" colLg={2} colMd={3}
                value={filtro.empleados} onChange={onChange} showFilter valueAsId={true} />

            <FieldCalendarRange name="fechas" label="Fecha Solicitado" colLg={2} colMd={3} value={filtro.fechas}
                onChange={onChange} />

            <FieldMultiSelect name="estatus" source="estatusVacaciones" label="Estatus" colLg={2} colMd={2}
                value={filtro.estatus} onChange={onChange} valueAsId={true} />
        </>
    )
};

export default VacacionesPorAutorizar;
