import * as yup from "yup";
import constantes from "../../constantes";


const guardar = yup.object().shape({     
    nombreSolicitante: yup.string().required(),
    tipoServicio: yup.object({
        id: yup.number().required().min(1)
    }),   
    mantenimiento:  yup.mixed().when("tipoServicio.id", {
        is: constantes.tiposServicio.MantenimientoPreventivo,
        then: yup.mixed().required()
    }),
    unidad: yup.object({
        id: yup.number().required().min(1)
    }) ,
    fechaEntrada: yup.mixed().required(),
    fechaSalida: yup.mixed().required(),
    servicios: yup.array()
        .of(
            yup.object().shape({
                
                actividadServicio: yup.mixed().when("existente", {
                    is: true,
                    then: yup.object().shape({
                        id: yup.number().required().min(1),
                    })
                }),
                descripcion: yup.mixed().when("existente", {
                    is: false,
                    then: yup.string().required(),
                })
            })
        ),
    refacciones: yup.array()
    .of(
        yup.object().shape({
            cantidad: yup.string().required(),
            unidadDeMedida: yup.mixed().when("existente", {
                is: false,
                then: yup.object().shape({
                    id: yup.number().required().min(1),
                })
            }),            
            refaccion: yup.mixed().when("existente", {
                is: true,
                then: yup.object().shape({
                    id: yup.number().required().min(1),
                })
            }),
            nombre: yup.mixed().when("existente", {
                is: false,
                then: yup.string().required(),
            })
        })
    ),  
});





const finalizar = yup.object().shape({     
    nombreSolicitante: yup.string().required(),
    tipoServicio: yup.object({
        id: yup.number().required().min(1)
    }),   
    mantenimiento:  yup.mixed().when("tipoServicio.id", {
        is: constantes.tiposServicio.MantenimientoPreventivo,
        then: yup.mixed().required()
    }),
    unidad: yup.object({
        id: yup.number().required().min(1)
    }) ,
    mecanico: yup.object({
        id: yup.number().required().min(1)
    }) ,
    odometro: yup.string().required(),
    servicios: yup.array()
        .of(
            yup.object().shape({
                
                actividadServicio: yup.mixed().when("existente", {
                    is: true,
                    then: yup.object().shape({
                        id: yup.number().required().min(1),
                    })
                }),
                descripcion: yup.mixed().when("existente", {
                    is: false,
                    then: yup.string().required(),
                })
            })
        ),
    refacciones: yup.array()
    .of(
        yup.object().shape({
            cantidad: yup.string().required(),
            unidadDeMedida: yup.mixed().when("existente", {
                is: false,
                then: yup.object().shape({
                    id: yup.number().required().min(1),
                })
            }),            
            refaccion: yup.mixed().when("existente", {
                is: true,
                then: yup.object().shape({
                    id: yup.number().required().min(1),
                })
            }),
            nombre: yup.mixed().when("existente", {
                is: false,
                then: yup.string().required(),
            })
        })
    ),  
});



const reprogramar = yup.object().shape({     
    ComentariosReprogramacion: yup.string().required(),
    motivoReprogramacion: yup.object({
        id: yup.number().required().min(1)
    }),   
   
});


const cancelacion = yup.object().shape({     
    comentarios: yup.string().required(),   
    fechaCancelacion : yup.string().required(),
    motivoCancelacion: yup.object({
        id: yup.number().required().min(1)
    }),   
});

const solicitudServicioValidacionEsquema = {
    guardar,
    reprogramar,
    finalizar,
    cancelacion
};

export default solicitudServicioValidacionEsquema;