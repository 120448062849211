/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import useSource from "../../api/source";
import { isTablet } from "react-device-detect";
import useWindowState from "../../hooks/useWindowState";
import { useReadOnly } from "../forms/ReadOnlyProvider";

const FieldDropDown = ({
    colMd,
    colSm,
    label,
    name,
    options,
    source,
    onChange,
    onBlur,
    value,
    className,
    scrollHeight,
    required,
    colLg,
    fireChangeOnValueChanged,
    fire,
    valueById,
    error,
    errors,
    filter,
    disabled,
    showClear,
    params,
    colMob,
    width,
    appendTo,
    time,
}) => {
    const { readOnly } = useReadOnly() ?? {};
    disabled = disabled ?? readOnly;

    if (!isTablet) {
        colMob = null;
    }

    const [dropOptions, setDropOptions] = useWindowState(`drop${source}`, options == null ? [] : options);
    const [selectedOption, setSelectedOption] = useState(null);
    const [lastParams, setLastParams] = useState(null);
    const apiSource = useSource();

    useEffect(() => {
        const timer = setTimeout(() => loadOptions(), time ?? 50);

        if (value == null) {
            setSelectedOption(null);
        }
        return () => clearTimeout(timer);
    }, [source, params, value, options]);

    async function loadOptions() {
        let newOptions = null;
        if (source != null && source.length > 0 && JSON.stringify(params) !== JSON.stringify(lastParams)) {
            setLastParams(params);
            newOptions = await apiSource.obtener(source, params);
            if (newOptions != null) {
                setDropOptions(newOptions);
                if (fireChangeOnValueChanged === true) {
                    const option = getOption(value);
                    if (option !== value) {
                        fireChange(name, option);
                    }
                }
            }
        }

        if (options != null && options.length > 0) {
            setDropOptions(options);
        }

        if (selectedOption == null) {
            setSelectedOption(getOption(value, newOptions));
        }
        if (fire) {
            setSelectedOption(getOption(value, newOptions));
        }
    }

    function onDropChange(event) {
        setSelectedOption(event.value);
        fireChange(name, event.value);
    }

    function getOption(value, options) {
        options = options ?? dropOptions;
        if (options == null) return null;
        if (typeof value === "number") return options.find((e) => e.id === value);

        const findValue = options.find((e) => e.id === value?.id);
        if (findValue != null) return findValue;

        return value;
    }

    function fireChange(itemName, itemValue) {
        if (onChange != null) {
            onChange(itemValue == null ? null : valueById ? itemValue.id : itemValue, itemName);
        }
    }

    return (
        <>
            {label && (
                <div
                    className={`form-group d-flex flex-column col-lg-${colMob ?? colLg ?? colMd} col-md-${
                        colMob ?? colMd ?? "4"
                    } col-sm-${colSm != null ? colSm : "12"}`}
                >
                    <div>
                        {required ? "*" : ""}
                        <label htmlFor={name}>{label}</label>
                    </div>
                    <Dropdown
                        value={selectedOption}
                        id={name}
                        options={dropOptions}
                        onChange={onDropChange}
                        className={`prime-form-control ${className ? className : ""} ${
                            error || errors?.[name] ? "error" : ""
                        }`}
                        optionLabel="nombre"
                        filter={filter}
                        showClear={showClear !== false}
                        filterBy="nombre"
                        placeholder={`Seleccionar ${label ?? ""}`}
                        onBlur={onBlur}
                        disabled={disabled === true}
                        scrollHeight={scrollHeight}
                        appendTo={appendTo}
                    />
                </div>
            )}
            {!label && (
                <>
                    <Dropdown
                        value={selectedOption}
                        id={name}
                        options={dropOptions}
                        onChange={onDropChange}
                        style={{ width: width }}
                        className={`prime-form-control ${className ? className : ""} ${
                            error || errors?.[name] ? "error" : ""
                        }`}
                        optionLabel="nombre"
                        filter={filter == null ? true : filter}
                        showClear={showClear !== false}
                        filterBy="nombre"
                        placeholder={`Seleccionar ${label ?? ""}`}
                        onBlur={onBlur}
                        disabled={disabled === true}
                        scrollHeight={scrollHeight}
                        appendTo={appendTo}
                    />
                </>
            )}
        </>
    );
};

export default FieldDropDown;
