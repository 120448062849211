import moment from "moment/moment";
import * as numeral from "numeral";

const format = {};

export const dateToString = (date, format) => {
    if (date == null)
        return ''
    return moment(date).format(format ?? "DD/MM/YYYY");
};

export const utcToString=(date, format)=>{
    if (date == null)
        return ''
    const fecha = moment.utc(date).local();
    return fecha.format(  format??'DD-MMM-YYYY HH:mm');
}

export const numberFormat = (value, precision) => {
    precision = precision ?? 2;
    const format = precision === 0 ? "" : "." + "".padStart(precision);
    return value == null ? "" : numeral(value).format(`0,0${format}`);
};

export const currencyFormat = (value, precision) => {
    precision = precision ?? 2;
    const format = precision === 0 ? "" : "." + "".padStart(precision);
    return value == null ? "" : numeral(value).format(`$ 0,0${format}`);
};

export const mostrarFechaNavegador = (fechaUtcString) => {
    // Crea un objeto Date usando la fecha UTC
    var offsetMinutos = new Date().getTimezoneOffset();
    var offsetMilisegundos = offsetMinutos * 60 * 1000;

    let fechaUtc = new Date(Date.parse(fechaUtcString));

    var fechaLocal = fechaUtc;
    if (!fechaUtcString.endsWith("Z")) {
        // Aplica el offset para convertir la fecha y hora a la zona horaria local del navegador
        fechaLocal = new Date(fechaUtc.getTime() - offsetMilisegundos);
    }

    var opcionesFormato = {
        day: "2-digit",
        month: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    };

    // Convierte la fecha a una cadena formateada
    return fechaLocal.toLocaleString(undefined, opcionesFormato).replace(/[/]/g, "-");
};

export const convertirSegundosATexto =(segundos)=> {
    let horas = Math.floor(segundos / 3600);
    let minutos = Math.floor((segundos % 3600) / 60);

    return `${horas.toString().padStart(2, '0')} hrs ${minutos.toString().padStart(2, '0')} min `   ;
}

export const convertirMinutosATexto =(minutos)=> {
    if (minutos == null ) {
        return '';
    }

    minutos = Math.round(minutos);

    let horas = Math.floor(minutos / 60);


    return `${horas > 0 ? horas + ' hrs ' : ''}${minutos%60} min`   ;
}

export default format;
