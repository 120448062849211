import constantes from "../../constantes";

export const defaultMapOptions = {
    fullscreenControl: false,
    zoomControl: true, // Mostrar control de zoom
    mapTypeControl: true,

};

export const tipoGeocerca = {
    linea: 1,
    poligono: 2,
    circulo: 3
}


export const tipoAlertas = {
    alert: 1,
    warning: 2
}

export const tipoIndicador = {
    velocidad: 1,
    motor: 2,
    geocercaProhibida: 3,
    lastPosition: 4,
    gps: 5,
    showRoute: 6,
    followInMap: 7,
    diesel: 8,
    brecha: 9,
    unidadSinViaje: 10,
    horasManejo:11,
    totales:99
}

export const nombreIndicador = {
    1: 'Velocidad',
    2: 'Motor',
    3: 'GeocercaProhibida',
    4: 'LastPosition',
    5: 'Gps',
    6: 'showRoute',
    7: 'followInMap',
    8: 'Diesel',
    9: 'Brecha',
    10:'SinViaje',
    11: 'HorasManejo'
}

export const tiposIconoRuta = {
    inicio: 1,
    enRuta: 2,
    finalizado: 3
}

export const colorIndicadores = {
    1: 'gris',
    2: 'verde',
    3: 'amarillo',
    4: 'rojo',
    5: 'azul',
    6: 'rosa'
}

export const nombreColorIndicador = {
    gris: 1,
    verde: 2,
    amarillo: 3,
    rojo: 4,
    azul: 5,
    rosa: 6
}

export const getLastPositionColor = (viaje, indicador)=>{
    if (constantes.tipoProgramacionViaje.vacio === viaje.tipoViajeId){
        return nombreColorIndicador.verde
    }

    if (indicador.color !==  nombreColorIndicador.rojo) {
        return nombreColorIndicador.verde
    }

    return  nombreColorIndicador.amarillo
}

export const getHorasManejoColor = (viaje, indicador)=>{
    if (indicador.color === nombreColorIndicador.rojo || indicador.color === nombreColorIndicador.amarillo) {
        return indicador.color;
    }

    return nombreColorIndicador.verde
}

export const indicadorColorMap = new Map([
        [tipoIndicador.geocercaProhibida, nombreColorIndicador.rojo],
        [tipoIndicador.diesel, nombreColorIndicador.rojo],
        [tipoIndicador.followInMap, nombreColorIndicador.amarillo],
        [tipoIndicador.velocidad, nombreColorIndicador.amarillo],
        [tipoIndicador.motor, nombreColorIndicador.amarillo],
        [tipoIndicador.gps, getLastPositionColor],
        [tipoIndicador.lastPosition, nombreColorIndicador.amarillo],
        [tipoIndicador.brecha, nombreColorIndicador.amarillo],
        [tipoIndicador.showRoute, nombreColorIndicador.amarillo],
        [tipoIndicador.showRoute, nombreColorIndicador.rojo],
        [tipoIndicador.horasManejo, getHorasManejoColor],
    ]
)

export const colorMap = new Map([
        [nombreColorIndicador.gris, "#E3E9ED"],
        [nombreColorIndicador.verde, "green"],
        [nombreColorIndicador.amarillo, "#F9DA3A"],
        [nombreColorIndicador.rojo, "#FF0000"],
    ]
)


export const geocercaOptions = {
    fillColor: "#3bc379",
    fillOpacity: 0.25,
    strokeColor: "#3bc379",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: true,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
};

export const tipoStop = {
    stop: 1,
    parking: 2
}

export const opcionViajes = 'Viajes'
export const opcionUnidades = 'Unidades'
export const filtroOpciones = [{name: opcionViajes, api: 'viajes'}, {name: opcionUnidades, api: 'unidades'}];
