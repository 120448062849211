/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Form from "../../../shared/forms/Form";
import useForm from "../../../shared/forms/useForm";
import { Sidebar } from "primereact/sidebar";
import moment from "moment";
import Field from "../../../shared/forms/Field";
import FormCalendar from "../../../shared/forms/FormFields/FormCalendar";
import { Button } from "primereact/button";
import FormInputNumber from "../../../shared/forms/FormFields/FormInputNumber";
import FormTextArea from "../../../shared/forms/FormFields/FormTextArea";
import useCajaApi from "../../../api/reportes/useCajaApi";
import _ from "lodash";
import { serialize } from "object-to-formdata";
import alerts from "../../../shared/alerts";
import { fechaMaximaSolicitud, fechaMinimaSolicitud } from "../CajaConstantes";
import FormClipboardImage from "../../../shared/forms/FormFields/FormClipboardImage";

const AbonarACuenta = ({ movimientoId, cajaId, solicitudesSeleccionadas, onCancel, onSaved, soloLectura }) => {
    const api = useCajaApi();
    const visible = movimientoId != null;
    const [submitted, setSubmitted] = useState(false);
    solicitudesSeleccionadas = solicitudesSeleccionadas ?? [];

    const {
        values,
        setValues,
        onChange,
        onValidate,
        isValid
    } = useForm();

    useEffect(() => {
        setSubmitted(false);
        load(movimientoId).then();
    }, [movimientoId]);

    const load = async (movimientoId) => {
        if (movimientoId === 0 || movimientoId == null) {
            setValues({
                fecha: moment().startOf('day')._d,
                monto: _.sumBy(solicitudesSeleccionadas, 'monto'),
            });
            return;
        }

        const result = await api.obtenerAbono(cajaId, movimientoId);
        setValues(result);

    }

    const onSave = async () => {
        setSubmitted(true);
        if (!isValid) {
            return;
        }

        const formData = serialize({
            ...values,
            solicitudIds: solicitudesSeleccionadas.map(e => e.id)
        }, { indices: true, dotsForObjectNotation: true });

        await api.abonar(cajaId, formData);
        onSaved();
    }

    const onDelete = async () => {
        if (await alerts.preguntarSiNoAdvertencia('¿Desea eliminar el abono?')) {
            await api.eliminarAbono(cajaId, movimientoId);
            onSaved();
        }
    }


    return (
        <>
            <Sidebar visible={visible} position="right" dismissable={false}
                className="p-sidebar-bg caja" onHide={onCancel}>
                <Form columns={2} values={values}
                    onChange={onChange}
                    onValidate={onValidate}
                    formDisabled={movimientoId > 0}
                    className={`form-container full-height ${submitted ? 'submitted' : ''}`}>
                    <h5 style={{ gridColumnEnd: 'span 2' }}>
                        Abonar a Cuenta
                    </h5>

                    <Field label="Fecha" field={"fecha"} minDate={fechaMinimaSolicitud} maxDate={fechaMaximaSolicitud}
                        render={FormCalendar} />
                    <Field label="Monto" field={"monto"} required={true} maxFractionDigits={2}
                        render={FormInputNumber} />
                    <Field label="Evidencia de Depósito" field={"evidencia"} required={true}
                        render={FormClipboardImage} />
                    <Field label="Comentario" field={"comentario"} cols={2} rows={5} required={true}
                        render={FormTextArea} />

                </Form>

                <div className="form-footer">
                    {movimientoId > 0 && !soloLectura &&
                        <Button label="Eliminar" className="p-button-outlined" type="button" onClick={onDelete} />
                    }
                    {movimientoId === 0 && !soloLectura &&
                        <Button label="Guardar" className="p-button-outlined" type="button" onClick={onSave} />
                    }

                </div>
            </Sidebar>
        </>
    )
}

export default AbonarACuenta;
