import React from "react";
import ReadOnlyProvider from "../../shared/forms/ReadOnlyProvider";
import DateFormatter from "../../shared/ag-grid-helper/dateFormatter";
const Historial = ({ historial, soloLectura}) => {

    return (
        <ReadOnlyProvider defaultReadOnly={soloLectura }>
            <h5 id="historia">Historial</h5>
            <table className="table table-borderless">
                <thead className="thead-light">
                    <tr>
                        <th scope="col" style={{ width: '15%' }}>Empleado</th>                       
                        <th scope="col" style={{ width: '10%' }}>Departamento</th>                    
                        <th scope="col" style={{ width: '20%' }}>Jefe Inmediato</th>
                        <th scope="col" style={{ width: '10%' }}>Usuario</th>
                        <th scope="col" style={{ width: '20%' }}>Fecha</th>
                        <th scope="col" style={{ width: '20%' }}>Estatus</th>
                        <th scope="col" style={{ width: '20%' }}>Fecha Desasignada</th>
                        
                    </tr>
                </thead>
                <tbody>
                    {historial?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="input">
                                    {item.empleado}
                                </td>
                                <td className="input">
                                    {item.departamento.nombre}
                                </td>
                                <td className="input">
                                    {item.jefeInmediato}
                                </td>
                                <td className="input">
                                    {item.auditUser}
                                </td>
                                <td className="input">
                                    <DateFormatter value={item.auditDate} />
                                </td>
                                <td className="input">
                                    {item.estatus}
                                </td>
                                <td className="input">
                                    <DateFormatter value={item.fechaDesasignada} />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>                
            </table>
        </ReadOnlyProvider>
    )
};

export default Historial;