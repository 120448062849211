import Filtro from "../../../shared/componentes/filtro";
import constantes from "../../../catalogos/constantes";
import {Sidebar} from "primereact/sidebar";
import DetalleViaje from "../programacion/detalleProgramacionViaje";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import React, {useRef, useState} from "react";
import {FormattedDate} from "react-intl";
import moment from "moment/moment";
import IconLibrary from "../../../shared/iconLibrary";
import constantesOperacion from "../../constantes";
import useApiViaje from "../../../api/operaciones/useApiViajes";
import {getSessionState} from "../../../hooks/useSessionState";
import produce from "immer";
import {FiltrosProgramacion} from "../programacion/programacionViaje";
import {currencyFormat} from "../../../shared/format";
import {useEmpresa} from "../../../layout/EmpresaProvider";

const styleEspacioCelda = {backgroundColor: '#f3f5fb', width: '1%', borderTop: 'none'};


const FacturacionMultiempresa = () => {
    const soloLectura = true;
    const filtroKey = 'filtrFacturacionMultiEmpresa';
    const [datos, setDatos] = useState([]);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [viajeId, setViajeId] = useState(0);
    const api = useApiViaje();
    const defaultFilter = {
        estatus: [
            constantesOperacion.estatusViajes.porProgramar,
            constantesOperacion.estatusViajes.programado,
            constantesOperacion.estatusViajes.enRuta,
            constantesOperacion.estatusViajes.segmentado
        ]
    };
    const ref = useRef(null);
    const detalleRef = useRef();
    const empresa = useEmpresa();

    function obtenerFiltro(filtro) {
        filtro = filtro ?? getSessionState(filtroKey);
        return {
            ...filtro
        };
    }

    async function buscar(filtro) {
        const newFilter = {
            ...filtro,
            empresaId: empresa.id
        };

        const viajes = await api.filtrarMultiempresa(obtenerFiltro(newFilter));
        setDatos(viajes);
    }

    async function onSave() {
        setSidebarVisible(false);
        await buscar();
    }

    function reporte() {
        api.reporteMultiempresa({...obtenerFiltro()});
    }

    function abrirForma(viajeId = 0) {
        setSidebarVisible(true);
        setViajeId(viajeId);
    }

    const expandir = (item, index) => {
        const expandido = !item.expandido;

        const newDatos = produce(datos, (draft) => {
            draft[index].expandido = expandido;

            const segmentos = datos.filter(e => e.viajePadreId === item.id);

            segmentos.forEach(s => {
                const indexSegmento = datos.findIndex(e => e.id === s.id);

                draft[indexSegmento].visible = expandido;
            })
        });

        setDatos(newDatos);
    };

    function obtenerEstiloFila(item) {
        const highlight = item.iluminado ? 'highlight' : '';
        const esSegmento = item.esSegmento ? 'viaje-segmento' : '';
        return `${highlight} ${esSegmento}`
    }


    return (
        <>
            <Filtro filtroKey={filtroKey} classButtons="field-iconos" styleForm={{marginTop: "40px"}}
                    handleSubmit={buscar} showFilterButtons modulo={constantes.modulos.administracionViajes}
                    fechasRangoDias={2} fechasDiasInicio={1} defaultValue={defaultFilter}>
                <>
                </>
                <FiltrosProgramacion filtro={defaultFilter}/>
            </Filtro>

            <Sidebar visible={sidebarVisible} position="right" dismissable={false} className="p-sidebar-xl"
                     onHide={() => setSidebarVisible(false)} showCloseIcon={true}>
                <DetalleViaje viajeId={viajeId} onSave={onSave} close={() => setSidebarVisible(false)} ref={detalleRef}
                              soloLectura={soloLectura}
                              facturacionMultiempresa={true}/>
            </Sidebar>

            <div className="table-custom">
                <table className="table table-sm table-striped table-fixed">
                    <thead>
                    <tr>
                        <th style={{width: '1%'}}/>
                        <th className="text-left" style={{width: '8%'}}>FECHA</th>
                        <th style={{width: '29%'}}>VIAJE</th>
                        <th style={{width: '8%'}}>CARTA PORTE</th>
                        <th className="text-left" style={{width: '8%'}}>ESTATUS</th>
                        <th style={{width: '4%'}}/>
                        <th style={styleEspacioCelda}/>
                        <th style={{width: '6%'}}>UNIDAD</th>
                        <th style={{width: '6%'}}>CAJA</th>
                        <th style={{width: '6%'}} className={"right"}>Total</th>
                        <th style={{width: '6%'}} className={"right"}>ABC</th>
                        <th style={{width: '6%'}} className={"right"}>TBX</th>
                        <th style={{width: '6%'}} className={"right"}>REX</th>
                        <th className="header-icons" style={{width: '8%'}}>
                            <div className="d-flex" style={{float: 'right'}}>
                                <ButtonIcon title="Descargar" onClick={reporte} iconName={'BiDownload'}/>
                            </div>
                        </th>
                    </tr>
                    </thead>

                    <tbody ref={ref}>
                    {datos.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                {(!item.esSegmento || item.visible) &&
                                    <tr key={item.id} className={obtenerEstiloFila(item)}>
                                        <td className="text-center p-0" style={{width: '1%'}}>
                                            {item.cliente?.esPreferente && !item.esSegmento &&
                                                <i className="pi pi-star text-primary"/>
                                            }
                                        </td>

                                        <td style={{width: '8%'}}>
                                            {!item.esSegmento &&
                                                <>
                                                    <FormattedDate value={item.fechaInicio} weekday="short"/> &nbsp;
                                                    {moment(item.fechaInicio).format('DD/MM/YYYY')}
                                                </>
                                            }
                                        </td>

                                        <td style={{width: '29%'}}>
                                            {!item.esSegmento &&
                                                <>
                                                    <b>{item.cliente || 'VACÍO'}</b>
                                                    <span className="pl-1 text-muted"
                                                          style={{fontSize: '0.8em'}}>{item.idViaje != null && item.idViaje !== "" && item.idViaje}
                                                        {item.idViajeRetorno != null && item.idViajeRetorno !== "" &&
                                                            ` / ${item.idViajeRetorno} (Ret.)`
                                                        }</span>
                                                </>
                                            }

                                            <div className="d-flex flex-wrap align-items-center"
                                                 style={{fontSize: '0.8em'}}>
                                                {item.rutas.map((r, index) => {
                                                        return <React.Fragment key={r.id}>
                                                            <div>{`${r.nombre} (${r.fechayHora})`}</div>
                                                            {index < item.rutas.length - 1 &&
                                                                <i className="pi pi-arrow-right px-1"/>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                )}
                                            </div>
                                        </td>

                                        <td className="text-center" style={{width: '8%'}}>
                                            {item.esSegmento ? '' : item.cartaPorte}
                                        </td>

                                        <td style={{width: '8%'}}>{item.estatus}</td>

                                        <td className="text-center" style={{width: '4%'}}>
                                            <div className="d-flex">
                                                {!item.esSegmento &&
                                                    <button type="button" className="btn btn-link btn-sm p-0"
                                                            onClick={() => abrirForma(item.id)}>
                                                        VER
                                                    </button>
                                                }

                                                {item.tieneSegmentos &&
                                                    <div className="cursor-pointer d-flex pl-1 align-items-center"
                                                         onClick={() => expandir(item, index)} title="Ver Segmentos"
                                                         style={{fontSize: "1rem"}}>
                                                        <IconLibrary
                                                            iconName={`IoIosArrowDrop${item.expandido ? 'downCircle' : 'rightCircle'}`}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </td>

                                        <td style={styleEspacioCelda}/>

                                        {item.tieneSegmentos && <td colSpan={2} style={{width: '12%'}}/>}

                                        {!item.tieneSegmentos &&
                                            <>
                                                <td style={{width: '6%'}} className={"center"}>
                                                    {item?.unidad}
                                                </td>

                                                <td style={{width: '6%'}} className={"center"}>
                                                    {item?.caja}
                                                </td>
                                            </>
                                        }
                                        <td style={{width: '6%'}} className={"right"}>
                                            {currencyFormat(item?.total)}
                                        </td>

                                        <td style={{width: '6%'}} className={"right"}>
                                            {currencyFormat(item?.abc)}
                                        </td>

                                        <td style={{width: '6%'}} className={"right"}>
                                            {currencyFormat(item?.tbx)}
                                        </td>
                                        <td style={{width: '6%'}} className={"right"}>
                                            {currencyFormat(item?.rex)}
                                        </td>

                                        <td style={{width: '8%'}}>
                                            <div className="d-flex align-items-center">
                                                {item.numeroFactura != null &&
                                                    <div className="d-flex pr-2" style={{fontSize: "1.1rem"}}>
                                                        <div className="text-primary" title="Timbrado">
                                                            <IconLibrary iconName="FaDollarSign"/>
                                                        </div>
                                                        <div className="d-flex flex-wrap align-items-center"
                                                             style={{fontSize: '0.9em'}}>
                                                            <div
                                                                className="d-flex flex-wrap align-items-center"
                                                                style={{fontSize: '0.9em'}}>
                                                                {`(${item.numeroFactura})`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </React.Fragment>
                        )
                    })
                    }
                    </tbody>
                </table>
            </div>

        </>
    );
};


export default FacturacionMultiempresa;
