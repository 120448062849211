/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import useApiOrderToCash from "../../api/operaciones/useApiOrderToCash";
import ExGrid from "../../shared/Grid/ExGrid";
import ExColumn from "../../shared/Grid/ExColumn";
import {Dialog} from "primereact/dialog";
import { AgDateTimeFormatter} from "../../shared/ag-grid-helper/GridFormatters";

const OrderToCashHistorial = ({viajeId, onHide}) => {
    const api = useApiOrderToCash();
    const [historial, setHistorial] = useState([]);

    const open = viajeId != null;

    useEffect(() => {
        if (viajeId != null){
            obtenerHistorial().then();
        }else {
            setHistorial([]);
        }

    }, [viajeId]);

    const obtenerHistorial = async () => {
        const historial = await api.historial(viajeId);
        setHistorial(historial);
    }

    return (
        <>
            <Dialog header="Historial" visible={open} style={{width: '1200px'}} onHide={onHide}>
                <ExGrid
                    className={'ocash-historial'}
                    headerClass="header-class"
                    data={historial}
                    getEntityId={(v)=>v.id}
                    style={{maxHeight:'80vh'}}
                    rowClass={(e)=> e.cancelado ? 'ocash-evento-cancelado' : '' }
                >
                    <ExColumn field={'tipoEvento'} title={'EVENTO'} width={'3fr'}></ExColumn>
                    <ExColumn field={'fecha'} title={'FECHA'} width={'2fr'} cellRenderer={AgDateTimeFormatter}></ExColumn>
                    <ExColumn field={'usuario'} title={'USUARIO'} width={'3fr'}></ExColumn>
                    <ExColumn field={'comentarios'} title={'COMENTARIOS'} width={'3fr'}></ExColumn>
                    <ExColumn field={'fechaCancelacion'} title={'FECHA CANCELACION'} width={'2fr'} cellRenderer={AgDateTimeFormatter}></ExColumn>
                </ExGrid>
            </Dialog>
        </>
    )
}

export default OrderToCashHistorial;
