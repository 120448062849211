/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import agGridHelper from "../../../shared/ag-grid-helper";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import useForm from "../../../shared/forms/useForm";
import moment from "moment";
import Filters from "../../../shared/forms/FormFilter/Filters";
import Field from "../../../shared/forms/Field";
import FormCalendarRange from "../../../shared/forms/FormFields/FormCalendarRange";
import Buttons from "../../../shared/forms/FormFilter/Buttons";
import FormFilter from "../../../shared/forms/FormFilter/FormFilter";
import FormMultiSelect from "../../../shared/forms/FormFields/FormMultiSelect";
import useApiDieselCarga from "../../../api/taller/useApiDieselCarga";
import CargaCombustibleDetalle from "./CargaCombustibleDetalle";
import { VerIconRenderer } from "../../../shared/ag-grid-helper/GridRenderers";
import { AgCurrencyFormatter, AgDateFormatter, AgNumberFormatter } from "../../../shared/ag-grid-helper/GridFormatters";
import FormTextBox from "../../../shared/forms/FormFields/FormTextBox";
import { v4 as uuidv4 } from "uuid";
import IconLibrary from "../../../shared/iconLibrary";
import constantes from "../../constantes";
import { useEmpresa } from "../../../layout/EmpresaProvider";

const filtroInicial = {
    fecha: [moment().startOf("month")._d, moment().startOf("day")._d],
};

const CargaCombustible = ({ soloLectura }) => {
    const api = useApiDieselCarga();
    const [datos, setDatos] = useState([]);
    const [cargaId, setCargaId] = useState(null);
    const { values: filter, onChange: onFilterChange, onClear } = useForm(filtroInicial);
    const [exportGuid, setExportGuid] = useState(null);
    const fileInputRef = useRef(null);
    const { empresaId } = useEmpresa();

    useEffect(() => {
        if (exportGuid != null) api.excel(filter).then();
    }, [exportGuid]);

    const filtrar = async (filtro) => {
        const datos = await api.filtrar(filtro);
        setDatos(datos);
    };

    const onExport = async () => {
        setExportGuid(uuidv4());
    };

    async function onUpload(event) {
        const formData = new FormData();
        formData.append("file", event.target.files[0]);
        await api.guardarCargasPorArchivo(formData);
        api.filtrar(filter);

        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    }

    const ButtonsHeader = () => {
        return (
            <div className={"ag-grid header-icons"}>
                {constantes.empresa.SCF === empresaId && (
                    <div className={`image-upload`} style={{ gridColumnEnd: "span 2", width: "fit-content" }}>
                        <>
                            <label htmlFor={1}>
                                <IconLibrary iconName="BsSearch" />
                            </label>

                            <input
                                type="file"
                                id={1}
                                ref={fileInputRef}
                                onChange={(event) => onUpload(event)}
                                accept=".xlsx,.xls"
                            />
                        </>
                    </div>
                )}
                <ButtonIcon title={"Descargar"} iconName={"BiDownload"} onClick={onExport} />
                {!soloLectura && (
                    <ButtonIcon
                        title="Agregar"
                        className={"grid-action"}
                        iconName={"MdAddBox"}
                        onClick={() => setCargaId(0)}
                    />
                )}
            </div>
        );
    };

    return (
        <>
            <FormFilter
                columns={6}
                gridStyle={{ paddingTop: "0", marginTop: "0" }}
                filter={filter}
                onChange={onFilterChange}
                onClear={onClear}
                onFilter={(filtro) => filtrar(filtro, null)}
            >
                <Filters>
                    <Field field="fecha" label="Fechas" render={FormCalendarRange} />
                    <Field
                        field="unidadIds"
                        label="Numero económico"
                        source="diesel/unidadesMotrizTransicion"
                        render={FormMultiSelect}
                    />
                    <Field field="operadorIds" label="Operador" source="diesel/operadores" render={FormMultiSelect} />
                    <Field
                        field="proveedorIds"
                        label="Proveedores"
                        source="diesel/proveedoresExistentes"
                        render={FormMultiSelect}
                    />
                    <Field field="tipoIds" label="Tipo" source="diesel/combustibles" render={FormMultiSelect} />
                    <Field field="ticket" label="Ticket" render={FormTextBox} />
                </Filters>
                <Buttons filter={true} clear={true} style={{ top: "-50px" }}></Buttons>
            </FormFilter>

            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    rowSelection={"single"}
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    frameworkComponents={{
                        buttonsHeader: ButtonsHeader,
                        verIconRenderer: VerIconRenderer,
                    }}
                    onGridReady={() => filtrar(filter).then()}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    context={{
                        onEdit: (id) => setCargaId(id),
                    }}
                    getRowClass={(params) => params.data.nombreColor}
                >
                    <AgGridColumn field="folio" headerName="FOLIO" cellClass={"center"} minWidth={100} />
                    <AgGridColumn field="combustible" headerName="TIPO" cellClass={"center"} minWidth={100} />
                    <AgGridColumn field="ubicacion" headerName="UBICACIÓN" cellClass={"center"} minWidth={100} />
                    <AgGridColumn
                        field="fechaRegistro"
                        valueFormatter={AgDateFormatter}
                        headerName="FECHA"
                        cellClass={"left"}
                        minWidth={100}
                    />
                    <AgGridColumn field="numeroEconomico" headerName="UNIDAD" headerClass={"center"} minWidth={100} />
                    <AgGridColumn field="operador" headerName="OPERADOR" minWidth={300} />
                    <AgGridColumn
                        field="litros"
                        headerName="LITROS"
                        valueFormatter={AgNumberFormatter}
                        minWidth={100}
                    />
                    <AgGridColumn
                        field="precio"
                        headerName="PRECIO"
                        valueFormatter={AgCurrencyFormatter}
                        precision={4}
                        minWidth={100}
                    />
                    <AgGridColumn field="ticket" headerName="TICKET" cellClass={"center"} minWidth={100} />
                    <AgGridColumn field="odometro" headerName="ODOMETRO" cellClass={"center"} minWidth={100} />
                    <AgGridColumn field="proveedor" headerName="PROVEEDOR" minWidth={100} />
                    <AgGridColumn field="controlador" headerName="CONTROLADOR" minWidth={100} />
                    <AgGridColumn field="sello" headerName="SELLO" cellClass={"center"} minWidth={100} />

                    <AgGridColumn
                        maxWidth={100}
                        headerName="VER"
                        cellRenderer="verIconRenderer"
                        pinned={"right"}
                        cellClass={"left"}
                        headerComponent="buttonsHeader"
                    />
                </AgGridReact>
            </div>
            <CargaCombustibleDetalle
                cargaId={cargaId}
                onSaved={() => {
                    filtrar(filter).then();
                    setCargaId(null);
                }}
                onCancel={() => setCargaId(null)}
            />
        </>
    );
};

export default CargaCombustible;
