import useServiceFactory from "../serviceFactory";

const apiUrl = `/administracion/vacaciones`;

const useApiVacaciones = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URL});
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, {params, showLoading: true}),
        filtrarPorAutorizar: async (params) => service.get(`${apiUrl}/filtrarPorAutorizar`, {
            params,
            showLoading: true
        }),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, {showLoading: true}),
        datosEmpleado: async (params) => service.get(`${apiUrl}/datosEmpleado`, {params}),
        guardar: async (params) => service.post(`${apiUrl}`, params),
        actualizar: async (id, params) => service.put(`${apiUrl}/${id}`, params),
        autorizar: async (id) => service.put(`${apiUrl}/${id}/autorizar`),
        rechazar: async (id) => service.put(`${apiUrl}/${id}/rechazar`),
        excel: async (params) => service.get(`${apiUrl}/excel`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `Vacaciones.xlsx`
            }),
        excelPorAutorizar: async (params) => service.get(`${apiUrl}/excel/porAutorizar`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `Vacaciones.xlsx`
            })
    }
};

export default useApiVacaciones;
