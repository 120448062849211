/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "primereact/button";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import FieldCalendar from "../../../shared/controls/fieldCalendar";
import React, { useEffect, useReducer } from "react";
import formReducer from "../../../shared/forms/formReducer";
import SolicitudServicioValidacionEsquema from "./solicitudServicioValidacionEsquema";
import FormHandler from "../../../shared/forms/formHandler";
import useApiSolicitud from "../../../api/catalogos/useApiSolicitudServicio";
import FieldAutoComplete from "../../../shared/controls/fieldAutoComplete";
import moment from "moment";
import constantes from "../../constantes";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import produce from "immer";

const DetalleSolicitudServicio = ({ onUpdateStatus, onSave, solicitudServicioId, closeSolicitud, soloLectura }) => {

    const apiSolicitud = useApiSolicitud();

    const defaultSolicitudServicio = () => (
        {
            entity: {
                id: 0,
                estatus: 0,
                fechaSolicitud: moment().startOf('day').toDate(),
                fallas: [{ numeroFalla: 1 }],

            }
        }
    );

    const [{ entity, errors, ...state }, dispatch] = useReducer(
        formReducer, {}, defaultSolicitudServicio
    );

    useEffect(() => {
        let timer1 = setTimeout(loadSolicitudServicio, 100);
        return () => {
            clearTimeout(timer1)
        }
    }, [solicitudServicioId]);

    useEffect(() => {
        if (state.submitted === true) {
            formHandler.setValidacionSchema(SolicitudServicioValidacionEsquema.guardar);
            formHandler.validate(entity);
        }
    }, [entity]);

    const loadSolicitudServicio = async () => {
        if (solicitudServicioId == null)
            return;

        if (solicitudServicioId === 0)
            dispatch({ type: 'reset', getDefault: () => entity });
        else {

            let solicitudServicio = { entity: await apiSolicitud.obtener(solicitudServicioId) };

            dispatch({ type: 'reset', getDefault: () => solicitudServicio ?? entity });
        }

    };

    const update = (value, propertyName) => {
        dispatch({ type: 'update', value: value, name: propertyName });
    };

    const formHandler = new FormHandler({
        validationSchema: SolicitudServicioValidacionEsquema.guardar,
        api: apiSolicitud,
        dispatch,
    });

    const save = async (callValidation) => {


        const solicitudGuardado = await formHandler.save(entity, true);

        if (formHandler.valdation.result === true || callValidation === false) {
            onSave();
        }
        return solicitudGuardado;
    };

    function agregarALista(lista) {

        var numeroFalla = lista.length + 1;
        const nuevo = produce(lista, draft => {
            draft.splice(numeroFalla, 0, { numeroFalla: numeroFalla });
        }
        );

        dispatch({ type: 'update', value: nuevo, name: "fallas" });

    }

    function eliminarDeLista(lista, index) {
        const nuevo = produce(lista, draft => {
            draft.splice(index, 1);
        }
        );

        dispatch({ type: 'update', value: nuevo, name: "fallas" });
    }

    function actualizarLista(lista, index, value, propertyName) {
        const nuevo = produce(lista, draft => {
            draft[index][propertyName] = value;
        }
        );

        dispatch({ type: 'update', value: nuevo, name: "fallas" });
    }

    return (
        <>
            <div className="form-container full-height">

                <div className="form-row">
                    <h5 className="col-12">Detalle Solicitud</h5>

                    <FieldCalendar name="fechaSolicitud"
                        label="Fecha de Solicitud"
                        className="small"
                        showButtonBar={false}
                        value={entity.fechaSolicitud}
                        colMd={6}
                        onChange={update}
                        errors={errors}
                        disabled
                    />

                    <FieldAutoComplete required name="solicitante" label="Nombre Solicitante" colMd={12} errors={errors}
                        source="empleados" params={{ id: 0, todasLasEmpresas: true }}
                        disabled={entity.id > 0}
                        value={entity.solicitante} onChange={update} />

                    <FieldAutoComplete required name="unidad" label="No Económico" colMd={6} errors={errors}
                        source="unidades"  params={{ id: 0, todasLasEmpresas: true }}
                        disabled={entity.id > 0}
                        value={entity.unidad} onChange={update} />

                    <FieldTextBox name="tipoUnidad" disabled={true} errors={errors} value={entity.unidad?.tipoUnidad}
                        label="Tipo Unidad" colMd={6} onChange={update} />

                </div>

                <table className="taller-detalle-solicitud table table-borderless">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col" style={{ width: '5%' }}>Numero</th>
                            <th scope="col" style={{ width: '58%' }}>Descripcion</th>
                            <th className={'input'} style={{ width: '10%' }}>
                                {!soloLectura &&
                                    <ButtonIcon disabled={entity.id > 0} title={'Agregar Falla'} iconName={'SiAddthis'}
                                        onClick={() => agregarALista(entity.fallas)} />
                                }
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {entity.fallas?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        {index + 1}
                                    </td>
                                    <td className="input">
                                        <FieldTextBox name="descripcion"
                                            value={item.descripcion}
                                            disabled={entity.id > 0}
                                            errors={errors?.[`fallas[${index}]`]}
                                            onChange={(value, pName) => actualizarLista(entity.fallas, index, value, pName)} />
                                    </td>

                                    <td className="input">
                                        {!soloLectura &&
                                            <ButtonIcon title={'Eliminar'}
                                                disabled={entity.id > 0 || entity.fallas.length === 1}
                                                onClick={() => eliminarDeLista(entity.fallas, index, "antidoping")}
                                                iconName={'RiDeleteBinLine'} />
                                        }
                                    </td>

                                </tr>
                            )
                        }

                        )}

                    </tbody>
                </table>

            </div>
            <div className="form-footer">
                <Button label="Cancelar" className="p-button-outlined" onClick={closeSolicitud} type="button" />
                {entity.estatus < constantes.estatusSolicitudes.programada && !soloLectura &&
                    <Button label='Guardar' type="button" onClick={save} />
                }
            </div>

        </>
    )
};

export default DetalleSolicitudServicio;