/* eslint-disable react-hooks/exhaustive-deps */
import useServiceFactory from "../../api/serviceFactory";
import {useEffect, useState} from "react";


export default function useOfflineApi() {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    const [apiCallInterval, setApiCallInterval] = useState(null)
    const storageKey = 'apiCalls';

    useEffect(() => {
        const interval = setInterval(retryCallApi,90000)
        setApiCallInterval(interval);
        return ()=>{
            clearInterval(apiCallInterval);
        }
    }, []);

    const retryCallApi = async ()=>{
        const list = getFromStorage();
        if (list.length === 0)
            return;

        const pendings= list.length;
        for (let i = 0; i<pendings; i++){
            const nextCall  = list.shift();
            updateStorage(list);
            if (nextCall.count <= 2) {
                pushCall(nextCall.entity, nextCall.apiUrl, (nextCall.count ?? 0));
            }
            await sleep(10)
        }
    }

    const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))


    const pushCall =(entity, apiUrl, count)=>{
        count = count ?? 0;
        service.post(apiUrl, entity, {showLoading:false, showSuccess:false, ignoreError: true})
            .then((e)=>{
                    if (e!= null && e.error){
                        const newList = [...getFromStorage(), {entity, apiUrl, count: count + 1 }];
                        updateStorage(newList)
                    }
                }
            )
            .catch((e)=> {
                count = e.message === "Network Error" ? count : (count + 1);
                const newList = [...getFromStorage(), {entity, apiUrl, count}];
                updateStorage(newList)
            }
        )
    }

    const updateStorage = (newList) =>{
        window.localStorage.setItem(storageKey, JSON.stringify(newList));
    }

    const getFromStorage = ()=>{
        const item = window.localStorage.getItem(storageKey);
        if (item==null || item.trim() === '' || item === 'null')
            return [];

        return  JSON.parse(item)

    }



    const value = {
        pushCall: pushCall
    };

    return value;
}
