import * as yup from "yup";

const rutaValidacionEsquema = yup.object().shape({
    viaje: yup.object({
        estadoOrigen: yup.mixed().required(),
        estadoDestino: yup.mixed().required(),
        origen: yup.mixed().required(),
        destino: yup.mixed().required(),
        tipoTarifa: yup.mixed().required(),
        tipoUnidad: yup.mixed().required(),
        // clasificacionCargaId: yup.number().required(),
        // clasificacionRutaId: yup.number().required(),
        puntosIntermedios: yup.array()
            .of(
                yup.object().shape({
                    estado: yup.mixed().required(),
                    ciudad: yup.mixed().required()
                })
            )
    }),
    costo: yup.object({
        longitud: yup.number().required().min(1),
        rendimiento: yup.number().required().min(1),
        costoDiesel: yup.number().required().min(1),
        // gastos:yup.array()
        //     .of(
        //         yup.object().shape({
        //             tipoCostoNombre: yup.mixed().required()
        //         })
        //     ),
    }),
    accesorios: yup.array()
        .of(
            yup.object().shape({
                accesorioId: yup.number().required(),
                cantidad: yup.number().required().min(1),
                precio: yup.number().required()
            })
        )
});

export default rutaValidacionEsquema;
