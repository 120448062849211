import produce from "immer";
import {Accordion, AccordionTab} from "primereact/accordion";
import React, {useState} from "react";
import FieldDropDown from "../../shared/controls/fieldDropDown";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import ButtonIcon from "../../shared/controls/ButtonIcon";
const Qs3 = ({entity, onChange}) => {
    const [active] = useState(null);

    const actualizarQS3 = (value, name, index) => {
        var newValue = produce(entity?.clienteQS3, (draft) => {
            draft[index][name] = value;
        });

        onChange(newValue, 'clienteQS3');
    };

    const agregar = () => {
        var newValue = produce(entity?.clienteQS3, (draft) => {
            draft.push({id: 0});
        });

        onChange(newValue, 'clienteQS3');
    };

    const eliminar = (index) => {
        var newValue = produce(entity?.clienteQS3, (draft) => {
            draft.splice(index, 1);
        });

        onChange(newValue, 'clienteQS3');
    };

    return (<>
        <div className="">
            <table className="table table-borderless">
                <thead className="thead-light">
                <tr>
                    <th scope="col" style={{width: "90%"}}>
                        QS3 Configuración
                    </th>
                    <th className={"input"} style={{width: "10%", textAlign: 'end'}}>
                        <ButtonIcon title={"Agregar Configuración"} iconName={"SiAddthis"}
                                    onClick={agregar}/>
                    </th>
                </tr>
                </thead>
            </table>
        </div>
        {entity?.clienteQS3?.map((item, index) => {
            return (<Accordion style={{marginTop: '1%'}} key={index}
                               activeIndex={active} multiple>
                <AccordionTab header={<>
                    <span>{item?.empresa?.nombre}</span>
                    <div onClick={(event) => event.stopPropagation()}
                         style={{width: '-60px', float: 'right'}}
                         className="d-flex">
                        <ButtonIcon title={"Eliminar"} marginTop={"6px"}
                                    onClick={() => eliminar(index)} iconName={"RiDeleteBinLine"}/>
                    </div>
                </>}>
                    <div className="form-row">
                        <FieldDropDown label={'Empresa'} name={'empresa'} value={item?.empresa} colMd={6}
                                      source={'empresas'}
                                       onChange={(value, name) => actualizarQS3(value, name, index)}/>
                        <FieldTextBox label={'URL'} name={'url'} value={item?.url} colMd={6}
                                      maxLength={250}
                                      onChange={(value, name) => actualizarQS3(value, name, index)}/>
                        
                        <FieldTextBox label={'Usuario'} name={'usuario'} value={item?.usuario} colMd={6}
                                      maxLength={250}
                                      onChange={(value, name) => actualizarQS3(value, name, index)}/>
                        <FieldTextBox label={'Password'} name={'contrasena'} value={item?.contrasena} colMd={6}
                                      maxLength={250}
                                      onChange={(value, name) => actualizarQS3(value, name, index)}/>
                       
                    </div>
                </AccordionTab>
            </Accordion>)
        })}
    </>);
};
export default Qs3;