/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Viajes from "./Viajes";

export const ESTATUS_DIARIO = {
    sinEstatus: 200,
    operando: 201,
    conProblemas: 202,
    enMantenimiento: 203,
    disponible: 204,
};

export const MOTIVO = {
    sinOperador: 1,
    indiciplina: 2,
    permiso: 3,
    correctivoPreventivo: 6,
    reparacionMayor: 7,
    siniestroGarantia: 8,
    facturando: 9,
    enTransito: 10,
    movimientos: 11,
    disponibleSinOperador: 12,
    disponibleConOperador: 13,
    sensoresGPSRótulación: 14,
};

export const estatusMap = new Map([
    [ESTATUS_DIARIO.operando, { clase: "operando", name: "Operando", color: "#3aea96" }],
    [ESTATUS_DIARIO.conProblemas, { clase: "conProblemas", name: "Sin Operador", color: "#ff5353" }],
    [ESTATUS_DIARIO.enMantenimiento, { clase: "enMantenimiento", name: "Mantenimiento", color: "#ffc000" }],
    [ESTATUS_DIARIO.disponible, { clase: "disponible", name: "Disponible", color: "#737373" }],
]);

export const motivoMap = new Map([
    [
        MOTIVO.sinOperador,
        { clase: "sinOperador", name: "SO", estatus: ESTATUS_DIARIO.conProblemas, descripcion: "Sin Operador" },
    ],
    [
        MOTIVO.indiciplina,
        { clase: "indiciplina", name: "IND", estatus: ESTATUS_DIARIO.conProblemas, descripcion: "Indiciplina" },
    ],
    [MOTIVO.permiso, { clase: "permiso", name: "PER", estatus: ESTATUS_DIARIO.conProblemas, descripcion: "Permiso" }],
    [
        MOTIVO.correctivoPreventivo,
        {
            clase: "correctivoPreventivo",
            name: "CP",
            estatus: ESTATUS_DIARIO.enMantenimiento,
            descripcion: "Correctivo/Preventivo",
        },
    ],
    [
        MOTIVO.reparacionMayor,
        {
            clase: "reparacionMayor",
            name: "RM",
            estatus: ESTATUS_DIARIO.enMantenimiento,
            descripcion: "Reparacion Mayor",
        },
    ],
    [
        MOTIVO.siniestroGarantia,
        {
            clase: "siniestroGarantia",
            name: "SG",
            estatus: ESTATUS_DIARIO.enMantenimiento,
            descripcion: "Siniestro/Garantia",
        },
    ],
    [
        MOTIVO.facturando,
        {
            clase: "facturando",
            name: "VTA",
            estatus: ESTATUS_DIARIO.operando,
            descripcion: "Facturando",
        },
    ],
    [
        MOTIVO.enTransito,
        {
            clase: "enTransito",
            name: "TRN",
            estatus: ESTATUS_DIARIO.operando,
            descripcion: "En Transito",
        },
    ],
    [
        MOTIVO.movimientos,
        {
            clase: "movimientos",
            name: "MOV",
            estatus: ESTATUS_DIARIO.operando,
            descripcion: "Movimientos",
        },
    ],

    [
        MOTIVO.disponibleSinOperador,
        {
            clase: "disponibleSinOperador",
            name: "DSO",
            estatus: ESTATUS_DIARIO.disponible,
            descripcion: "Disponible Sin Operador",
        },
    ],
    [
        MOTIVO.disponibleConOperador,
        {
            clase: "disponibleConOperador",
            name: "DCO",
            estatus: ESTATUS_DIARIO.disponible,
            descripcion: "Disponible Con Operador",
        },
    ],
    [
        MOTIVO.sensoresGPSRótulación,
        {
            clase: "sensoresGPSRótulación",
            name: "SGR",
            estatus: ESTATUS_DIARIO.disponible,
            descripcion: "Sensores GPS / Rótulación",
        },
    ],
]);

const StatusButton = ({
    statusId,
    onChange,
    facturacion,
    dia,
    unidadId,
    otraEmpresa,
    enabled = true,
    coordinador,
    coordinadorNombreCompleto,
}) => {
    const selected = (id) => {
        return statusId === id;
    };

    const getStyle = (id, color) => {
        if (statusId === id) {
            return {
                backgroundColor: color,
                color: "white",
            };
        }

        return {};
    };

    const handleChange = (id) => {
        if (enabled) onChange(id);
    };

    const getGridStyle = () => {
        return {
            display: "flex",
            gridGap: "2px 2px",
            justifyContent: "center",
        };
    };

    return (
        <>
            <div style={getGridStyle()}>
                {Array.from(estatusMap.entries()).map(([id, value], index) => (
                    <button
                        key={id}
                        disabled={!enabled}
                        className={`estatus ${enabled ? "" : "disabled"} compact-mode`}
                        style={getStyle(id, value.color)}
                        onClick={() => handleChange(id)}
                    >
                        {!selected(id) ? (
                            <div className="bar" style={{ backgroundColor: value.color }} />
                        ) : (
                            <span style={{ fontSize: "0.8em", color: "black" }}>{otraEmpresa}</span>
                        )}
                    </button>
                ))}
            </div>
            <Viajes
                dia={dia}
                facturacion={facturacion}
                unidadId={unidadId}
                coordinador={coordinador}
                coordinadorNombreCompleto={coordinadorNombreCompleto}
            />
        </>
    );
};

export default StatusButton;
