import LogoPage from "../img/logoCombustion.png";
import React from "react";

const Home = () => (
    <div className="logoLandingPage-padre">
        <img className="logoLandingPage-hijo" alt="LogoPage" src={LogoPage} />
    </div>
);

export default Home;
