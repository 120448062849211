/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import useApiUnidadNotificacion from "../../api/operaciones/useApiUnidadNotificacion";
import { Dialog } from "primereact/dialog";
import FieldTextArea from "../../shared/controls/fieldTextArea";
import useApiNotificationes from "../../api/shared/useApiNotificationes";

const AtenderNotification = ({ visible, setDialogVisible, titulo, notificacionAlerta }) => {
    const apiUnidad = useApiUnidadNotificacion();
    const [notificacion, setNotificacion] = useState({ esSeguimiento: false, estatusId: 0, esCreadoPorAlerta: true });
    const api = useApiNotificationes();

    useEffect(() => {
        if (visible) {
            setNotificacion({
                ...notificacion,
                tipoNotificacionId: notificacionAlerta.tipoNotificacion,
                comentarios: null,
            });
        }
    }, [visible]);

    const close = () => {
        setDialogVisible(false);
    };

    const guardar = async () => {
        await apiUnidad.save({ ...notificacion, viajeId: notificacionAlerta.entidadId });
        await api.borrar([notificacionAlerta.id]);

        setDialogVisible(false);
    };

    const renderFooterModal = () => {
        return (
            <div>
                <Button label="Cancelar" onClick={close} className="p-button-outlined" />
                <Button label="Confirmar" onClick={guardar} autoFocus />
            </div>
        );
    };
    return (
        <>
            <Dialog
                header={titulo}
                visible={visible}
                style={{ width: "35vw" }}
                footer={renderFooterModal()}
                onHide={close}
            >
                <div className="form-row">
                    <FieldTextArea
                        rows={4}
                        name="comentarios"
                        maxLength={150}
                        value={notificacion.comentarios}
                        onChange={(comentarios) => setNotificacion({ ...notificacion, comentarios })}
                    />
                </div>
            </Dialog>
        </>
    );
};

export default AtenderNotification;
