/* eslint-disable react-hooks/exhaustive-deps */
import {Button} from "primereact/button";
import React, {useEffect, useState} from "react";
import {useSeguimientoMonitoreo} from "../SeguimientoMonitoreoProvider";
import FieldRadioButtonList from "../../../../shared/controls/fieldRadioButtonList";
import {updateArrayAtIndex} from "../../../../shared/arrayUtility";
import useApiViajeSeguimientoMonitoreo from "../../../../api/operaciones/useApiSeguimientoMonitoreo";
import {writeToClipboard} from "../../../../shared/utility";
import constantes from "../../../../catalogos/constantes";
import FormCheckBox from "../../../../shared/forms/FormFields/FormCheckBox";
import FormInputNumber from "../../../../shared/forms/FormFields/FormInputNumber";
import Field from "../../../../shared/forms/Field";
import Form from "../../../../shared/forms/Form";
import alerts from "../../../../shared/alerts";

const tipoArrastre = [{id: constantes.tiposEquipo.motriz, nombre: 'Unidad'}, {id: constantes.tiposEquipo.arrastre, nombre: 'Caja'}]

const SeleccionViajesCuentaEspejo = ({visible, onCancel}) => {

    const api = useApiViajeSeguimientoMonitoreo();

    const [viajes, setViajes] = useState([])
    const [porTiempo, setPorTiempo] = useState({porTiempo: false, horas:0, minutos:0})

    const {
        viajesFiltrados,
        viajeVisible,
        unidadesOcultas
    } = useSeguimientoMonitoreo();

    useEffect(() => {
        if (viajesFiltrados) {

            const nuevosViajes = viajesFiltrados.filter(e => viajeVisible(e.viajeId)).map(v => {
                return {...v}
            });

            setViajes(nuevosViajes.map(n => {
                return viajes.find(v => v.viajeId === n.viajeId) ?? n
            }))
        }
    }, [visible,unidadesOcultas])

    const onChange = (value, index) => {
        const newItem = {...viajes[index], tipoEquipoId: value}
        setViajes(e => updateArrayAtIndex(e, index, newItem))
    }

    const copyLinkCuentaEspejo = async ()=>{
        const enviarViajes = viajes.map(e=> {return {viajeId:e.viajeId, tipoEquipoId:e.tipoEquipoId, unidadId:e.unidadId, cajaId:e.cajaId}})

        if (enviarViajes.findIndex(e=>(e.tipoEquipoId??0) === 0) >= 0){
            alerts.aviso('Favor de seleccionar si se compartirá caja o unidad para todos los viajes');
            return
        }

        const token = await api.generarToken({viajes:enviarViajes, duracion:porTiempo},{successMessage:'Se copiado el link para compartir viajes al portapapeles'})
        writeToClipboard(`${process.env.REACT_APP_API_CLIENTES_URL}/mapa/${token}`)
        onCancel()
    }

    if (!visible)
        return null;

    return (
        <div style={{width: '800px'}}
             className="seg-modal">
            <div className={'seg-dialog-header'}>
                <div className={'seg-dialog-title'}>
                    Compartir viajes
                </div>
                <Button icon="pi pi-times" className="p-button-rounded p-button-text seg-dialog-close"
                        onClick={onCancel}/>
            </div>
            <div className={'seg-viajes-cuenta-espejo'}>
                <span className={'bold'}>Cliente</span>
                <span className={'bold center'}>Carta Porte</span>
                <span className={'bold center'}>Unidad</span>
                <span className={'bold center'}>Caja</span>
                <span className={'bold center'}>Compartir:
                </span>
                {viajes.map((item, index) =>
                    <React.Fragment key={index}>
                        <span>{item.cliente}</span>
                        <span>{item.cartaPorte}</span>
                        <span>{item.unidad}</span>
                        <span>{item.caja}</span>
                        <span className={'center'}>
                                {item.viajeId > 0 &&
                                    <FieldRadioButtonList className={'center'} value={item.tipoEquipoId} options={tipoArrastre}
                                                          onChange={(value) => onChange(value, index)}/>
                                }
                        </span>
                    </React.Fragment>
                )}
            </div>
            <div className={'portiempo'}>
                <Form columns={3} values={porTiempo} onChange={setPorTiempo}
                      className={`form-container`}>
                    <Field field={'porTiempo'} label={'Compartir por un tiempo determinado'} render={FormCheckBox}></Field>
                    <Field field={'horas'} label={'Horas'} min={0} max={48} isVisible={(e)=> e.porTiempo} render={FormInputNumber}></Field>
                    <Field field={'minutos'} label={'Minutos'} min={0} max={48} isVisible={(e)=> e.porTiempo} render={FormInputNumber}></Field>
                </Form>
            </div>
            <div className={'fluid'} class={'dialog-footer center-buttons'}>
                <Button label="Generar link" className={'p-button-sm'} onClick={copyLinkCuentaEspejo} autoFocus/> :
            </div>
        </div>
    )
}
export default SeleccionViajesCuentaEspejo;