import useServiceFactory from "../serviceFactory";

const apiConvenios = `/operaciones/comercial/convenios`;
const apiGeocercasUrl = `/operaciones/gps`;
const apiClientes = `/catalogos/Clientes`;

const useApiConvenios = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URL});
    return {
        filtrar: async (params) => service.get(apiConvenios, {params}),
        obtener: async (id) => service.get(`${apiConvenios}/${id}`, {}),
        actualizar: async (id, params) => service.put(`${apiConvenios}/${id}`, params),
        setVigente: async (id, params) => service.put(`${apiConvenios}/${id}/vigente`, params),
        suspender: async (id) => service.patch(`${apiConvenios}/${id}/suspender`, {}),
        actualizarParada: async (id, paradaId, params) => service.put(`${apiConvenios}/${id}/paradas/${paradaId}`, params),
        actualizarGeocercas: async () => service.put(`${apiGeocercasUrl}/geocercas`, {showLoading: true}),
        agregarOrdenCompra: async (id, params) => service.post(`${apiConvenios}/agregarOrdenCompra/${id}`, params),
        obtenerViajes: async (id) => service.get(`${apiConvenios}/obtenerViajes/${id}`, {}),
        documentos: async (id, subClienteId) => service.get(`${apiConvenios}/${id}/documentos/${subClienteId}`),
        paradaDocumentos: async (id) => service.get(`${apiConvenios}/paradaDocumentos/${id}`),
        actualizarDocumentos: async (convenioId, id, params) => service.post(`${apiConvenios}/${convenioId}/actualizarDocumentos/${id}`, params),
        validarImpoExpo: async (id, internacional) => service.get(`${apiConvenios}/${id}/validarImpoExpo/${internacional}`),
        obtenerSubcliente: async (id) => service.get(`${apiClientes}/${id}/informacionSubCliente`, {}),
        guardarRelacionado: async (id, idRelacionaco) => service.put(`${apiConvenios}/${id}/guardarRelacionado/${idRelacionaco}`, {showLoading: true})
    };
};

export default useApiConvenios;
