import FileDownload from "js-file-download";
import useServiceFactory from "../../api/serviceFactory";
import {useEmpresa} from "../../layout/EmpresaProvider";

const  useDownloadFile = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URL});

    const {empresaId} = useEmpresa();

    return (file) => {
        if (file.file == null) {
            service.get(`archivos/descargar/${empresaId}?url=${file.url}`,
                {
                    showLoading: true,
                    download: true,
                    fileName: file.nombre
                })
        } else {
            FileDownload(file.file, file.nombre);
        }
    };
}

export default  useDownloadFile;
