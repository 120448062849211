import useServiceFactory from "../serviceFactory";
const apiUrl = `/proveedores`;
const useApiProveedoresDiesel = () => {
    const service = useServiceFactory({ uri: process.env["REACT_APP_DIESEL_API_URL"] });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, {params}),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, {showLoading: true}),
        guardar: async (params) => service.post(`${apiUrl}`, params),
        eliminar: async (id) => service.delete(`${apiUrl}/${id}`),
    }
};

export default useApiProveedoresDiesel;
