import useServiceFactory from "../serviceFactory";

const apiUrl = `/administracion/dispersion`;

const useApiDispersion = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URL});
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, {params, showLoading: true}),       
        obtener: async (id) => service.get(`${apiUrl}/${id}`, {showLoading: true}),
        dispersion: async (esquemaPago) => service.post(`${apiUrl}/creardispersion/${esquemaPago}` ,{showSuccess:false}),
        cancelarliquidacion: async (id,liquidacionId) => service.post(`${apiUrl}/cancelarliquidacion/${id}/${liquidacionId}`, {showSuccess:false}),
        excel: async (id,esOperador) => service.get(`${apiUrl}/excel/${id}/${esOperador}`,
            {
                id,
                showLoading: true,
                download: true,
                fileName: `Dispersion.xlsx`
            })
    }
};

export default useApiDispersion;
