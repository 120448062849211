import useServiceFactory from "../serviceFactory";

const apiUrl = `/operaciones/gps`;

const useApiGps = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URL});
    return {
        actualizarUnidades: async () => service.put(`${apiUrl}/unidades/web`, {showLoading: false}),
    }
};

export default useApiGps;
