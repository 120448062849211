/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import {EditIconRenderer, GridEditHeader, ReadOnlyCheckBoxRenderer} from "../../shared/ag-grid-helper/GridRenderers";
import agGridHelper from "../../shared/ag-grid-helper";
import SideBarEditForm from "../../shared/forms/SideBarEditForm";
import Form from "../../shared/forms/Form";
import Field from "../../shared/forms/Field";
import FormTextBox from "../../shared/forms/FormFields/FormTextBox";
import FormDropDown from "../../shared/forms/FormFields/FormDropDown";
import FormCheckBox from "../../shared/forms/FormFields/FormCheckBox";
import useApiProveedoresDiesel from "../../api/catalogos/useApiProveedoresDiesel";
import constantes from '../constantes';
import useSeguridad from "../../Seguridad/useSeguridad";

const Proveedores = () => {
const seguridad = useSeguridad();
const soloLectura = !seguridad.esEditable(constantes.menus.proveedoresDiesel);
    const api = useApiProveedoresDiesel();

    const [datos, setDatos] = useState([]);
    const [currentId, setCurrentId] = useState(null);

    useEffect(() => {
        load().then();
    }, []);

    const load = async () => {
        const datos = await api.filtrar();
        setDatos(datos);
    }

    return (
        <>
            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    rowSelection={'single'}
                    enableSorting={true}
                    frameworkComponents={{
                        readOnlyCheckBoxRenderer: ReadOnlyCheckBoxRenderer,
                        editRenderer: EditIconRenderer,
                        editHeader: GridEditHeader
                    }}
                    defaultColDef={agGridHelper.defaultColumns}
                    rowData={datos}
                    context={{
                        onEdit: (id) => setCurrentId(id),
                        onAdd: () => setCurrentId(0)
                    }}
                >
                    <AgGridColumn field="nombre" headerName="NOMBRE" cellClass={'left'}/>
                    <AgGridColumn field="tipo" headerName="TIPO" cellClass={'center'}/>
                    <AgGridColumn field="enabled" headerName="ACTIVO" cellClass={'flex center align-center'}
                                  cellRenderer={'readOnlyCheckBoxRenderer'}/>
                    <AgGridColumn maxWidth={60} headerComponent={soloLectura ? '' : 'editHeader'} cellClass={'center'}
                                  cellRenderer="editRenderer"/>
                </AgGridReact>
            </div>

            <SideBarEditForm id={currentId}
                             initialValues={{enabled: true}}
                             getByIdFunction={api.obtener}
                             saveFunction={api.guardar}
                             deleteFunction={api.eliminar}
                             formDisabled={soloLectura}
                             onSaved={() => {
                                 load().then();
                                 setCurrentId(null);

                             }}
                             onCancel={() => setCurrentId(null)}
            >
                <Form columns={1}>
                    <h5>Proveedor</h5>
                    <Field field={"nombre"} label={"Nombre"} required={true} render={FormTextBox}/>
                    <Field field={"tipoId"} label={"Tipo"} source="diesel/tiposproveedor" required={true}
                           render={FormDropDown}/>
                    <Field field={"enabled"} label={"Activo"} render={FormCheckBox}/>
                </Form>
            </SideBarEditForm>
        </>
    )
}

export default Proveedores;
