/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import PDFViewer from 'mgr-pdf-viewer-react';
import printJS from "print-js";
import useDownloadFile from "./useDownloadFile";
import {useEmpresa} from "../../layout/EmpresaProvider";
import alerts from "../alerts";

const uri = process.env.REACT_APP_API_URL;

const PdfViewer = ({header, value, urlApi, descargar, visible, hide, showApprove, onApprove, onReject, onReview, revisar = false}) => {

    const [document, setDocument] = useState({});
    const downloadFile = useDownloadFile();

    const {empresaId} = useEmpresa();


    useEffect(() => {
        if (value != null) {
            if (value.file != null) {
                setDocument({file: value.file});
            } else {
                setDocument({url: `${uri}/archivos/descargar/${empresaId}?url=${value.url}`})
            }
        } else
            setDocument({url: `${uri}/${urlApi}`})

    }, [visible, value, urlApi]);


    function getWidth() {
        if (window.innerWidth < 768)
            return '75vw';
        if (window.innerWidth < 1000)
            return '55vw';
        return '35vw';
    }

    const handleDownload = () => {
        if (descargar != null) {
            descargar();
        } else {
            downloadFile(value);
        }
    }

    const approveHandler = async () => {
        if ((await alerts.preguntarSiNo("¿Desea aprobar el documento?")) === true) {
            onApprove();
        }
    }

    const rejectHandler = async () => {
        if ((await alerts.preguntarSiNo("¿Desea rechazar el documento?")) === true) {
            onReject()
        }
    }


    const footer = (
        <div className="mt-2">
            <Button label="Cerrar" className="p-button-outlined" onClick={hide}/>
            <Button label="Descargar" onClick={handleDownload}/>
            {document.url != null &&
                <Button label="Imprimir" onClick={() => printJS(document.url)}/>
            }
            {showApprove &&
                <>
                    <Button label="Aprobar" className="p-button-outlined p-button-success" onClick={approveHandler} />
                    <Button label="Rechazar" className="p-button-outlined p-button-danger" onClick={rejectHandler}/>
                </>
            }
            {revisar &&
                <Button label="Solicitar Revisión" className="p-button-outlined p-button-success" onClick={onReview} />
            }
        </div>
    );

    return (
        <>
            <Dialog header={header} footer={footer} visible={visible} style={{width: getWidth()}} modal
                    onHide={hide}>
                <div className="form-row">
                    {<PDFViewer document={document}/>
                    }
                </div>
            </Dialog>
        </>
    );
};


export default PdfViewer;

