/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import useApiLiberacionViaje from "../../../api/administracion/useApiLiberacionViaje";
import InputFile from "../../../shared/controls/inputFile";
const Documentos = ({viaje, errors, actualizar, soloLectura = false}) => {

    const api = useApiLiberacionViaje();
    const [datos, setDatos] = useState([])
    useEffect(() => {
        load().then();
    }, [viaje?.id])

    const load = async () => {
        if (viaje?.id == null) {
            setDatos([]);
            return;
        }
        const datos = await api.obtenerDocumentos(viaje?.id);
        setDatos(datos);

    }

    
    return (
        <>
           <table className="table table-sm">
            <thead className="thead-light">
            <tr>
                <th style={{width: '10%'}}>DOCUMENTO</th>
                <th style={{width: '30%'}}>COMENTARIOS</th>
                <th style={{width: '40%'}}>SEGMENTO</th>
                <th style={{width: '15%'}}>ARCHIVO</th>                                    
            </tr>
            </thead>
            <tbody>

            {datos?.map((item) => {
                return (
                    <tr key={item?.id}>
                        <td>{item?.documento}</td>
                        <td>{item?.comentarios}</td>
                        <td>{item?.segmento}</td>
                        <td className="input">
                        {item.anexo !== null && 
                        <InputFile
                                name="archivo"
                                value={item.archivo}
                                disabled={true}
                                onChange={(value, pName) => actualizar(value, pName)}
                            />
                        }
                </td>
                    </tr>
                )
            })}
            </tbody>
        </table>
        </>
    );
};

export default Documentos;
