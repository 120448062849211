/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from 'react'
import {Button} from "primereact/button";
import useClipboardImage from "./useClipboardImage";
import InputClipboard from "./InputClipboard";
import InputClipboardFile from "./InputClipboardFile";
import ImageViewer from "../../componentes/imageViewer";
import PdfViewer from "../../componentes/pdfViewer";


const ClipboardImage = ({value, disabled, onChange, height, className}) => {
    const [visible, setVisible] = useState(false);
    const isPdf = value?.extension?.toLocaleString() === 'pdf';
    const isEmpty = value?.isEmpty ?? true;

    const {
        getUrl,
        onDelete
    } = useClipboardImage(value, onChange);

    return (
        <>
            <div className={`clipboard-image ${className}`}>
                {/*si está vacío y no deshabilitado muestra los botones para copiar o subir*/}
                {isEmpty && !disabled &&
                    <>
                        <InputClipboard onChange={onChange}/>
                        <InputClipboardFile onChange={onChange}/>
                    </>
                }
                {!isEmpty &&
                    <>
                        {isPdf ?
                            <Button className="p-button-rounded p-button-text" icon="pi pi-eye"
                                    title={'Vista previa'}
                                    onClick={()=>setVisible(true)}/>
                            :
                            <img alt="Imagen" src={getUrl(value)} height={height ?? 35} style={{cursor: "pointer"}}
                                 onClick={() => setVisible(true)}/>
                        }
                        {/*si si tiene archivo y no está deshabilitado, muestre el botón de eliminar*/}
                        {!disabled &&
                            <Button className="p-button-rounded p-button-text" icon="pi pi-trash"
                                    onClick={onDelete}/>
                        }
                    </>
                }
            </div>
            <ImageViewer value={value} visible={visible && !isPdf} hide={() => setVisible(false)}/>
            <PdfViewer value={value} urlApi={value?.url} visible={visible && isPdf} hide={() => setVisible(false)}/>
        </>
    )
}

export default ClipboardImage;
