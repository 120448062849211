/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import jwtDecode from 'jwt-decode'
import useApiSecurity from "../api/useApiSecurity";
import useMenus from "../api/menus";
import usePersistedState from "../hooks/usePersistedState";

const authContext = React.createContext(undefined)

const delayRefreshToken = 60000

const userStorageKey = 'user-ximple'

export const getStorageUser = () => {
    const user = localStorage.getItem(userStorageKey)
    try {
        return JSON.parse(user) ?? {}
    } catch (error) {
        return {}
    }
}

const storageUser = getStorageUser()

const useProvideAuth = () => {
    const [loggedOut, setLoggedOut] = React.useState(false)
    const [menuMap, setMenuMap] = React.useState(new Map())
    const apiSecurity = useApiSecurity()

    const apiMenus = useMenus();
    const [, setPestañas] = usePersistedState('pestañas', []);
    const [menus, setMenus] = usePersistedState('menus', []);

    const [user, setUser] = useState(storageUser)

    const isAuthenticated = useCallback (() => {
        return user?.token != null && Date.now() <= user.tokenExpiration
    },[user])

    const sessionExpired = useCallback (() => {
        return user?.token != null && (user.tokenExpiration - Date.now()) <= delayRefreshToken
    },[user])


    let intervalReview = null

    const onStorageUpdate = (e) => {
        const { key } = e
        if (key === 'authenticando') {
            const user = getStorageUser()
            if (user.loggedOut) {
                redirectToLogOut()
            } else {
                setUser(getStorageUser(user))
            }
        }
    }

    useEffect(() => {
        window.addEventListener('storage', onStorageUpdate)
        return () => {
            window.removeEventListener('storage', onStorageUpdate)
        }
    }, [])


    useEffect(() => {
        initializaeMenuMap()
        // reviewToken()
        return () => {
            if (intervalReview != null) {
                clearInterval(intervalReview)
            }
        }
    }, [user])

    const initializaeMenuMap = () => {
        if (user.menus == null || !user.menus.length) { return }

        setMenuMap(new Map(
            user.menus.map(e => {
                return [e.id, e]
            })))
    }

    const login = async (user) => {
        localStorage.setItem(userStorageKey, JSON.stringify(user))
        setUser(user)
    }

    const hasAccess = (option) => {
        return menuMap?.has(option)
    }

    const isEditable = (option) => {
        const menu = menuMap?.get(option)
        return menu?.editable ?? false
    }

    const logout = () => {
        clearUser(true)
        redirectToLogOut()
    }

    const redirectToLogOut = () => {
        setLoggedOut(true)
        window.location.href = '/login'
    }

    const clearUser = (loggedOut) => {
        localStorage.setItem(userStorageKey, JSON.stringify({ loggedOut }))
        setUser({})
    }

    const updateMenus = (menus, fullName) => {
        setUser({ ...user, menus, fullName })
    }

    let intervalToken = null
    const refreshToken = useCallback(() => {
        if (intervalToken != null) {
            return
        }
        intervalToken = setTimeout(async function () {
            if (isAuthenticated()) {
                const result = await apiSecurity.refreshToken(user.token)
                const decoded = jwtDecode(result.token)
                await login({
                    ...user,
                    token: result.token,
                    tokenExpiration: decoded.exp * 1000
                }).then()
            }
            intervalToken = null
        }, delayRefreshToken)
    }, [user])

    const updateToken =(token) =>{
        const newUser = {...user, token}
        localStorage.setItem(userStorageKey, JSON.stringify(user))
        setUser(newUser)
    }

    const loadMenu = async () => {
        if (!isAuthenticated())
            return;

        const result = await apiMenus.obtenerTodos(user.token);
        if (result?.menus == null){
            return;
        }
        const menus = result.menus;
        if (menus != null) {
            setMenus(menus);
            setPestañas(result.pestañas ?? []);
        }
    }


    return {
        user,
        loggedOut,
        login,
        logout,
        isAuthenticated,
        updateMenus,
        hasAccess,
        isEditable,
        refreshToken,
        updateToken,
        menus,
        loadMenu,
        sessionExpired
    }
}

const AuthProvider = ({ children }) => {
    const auth = useProvideAuth()
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    )
}

AuthProvider.propTypes = {
    children: PropTypes.any
}

export default AuthProvider

export const useAuth = () => {
    return React.useContext(authContext)
}
