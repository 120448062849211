/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import moment from "moment";
import * as numeral from "numeral";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import produce from "immer";

const Movimientos = ({liquidacion, movimientos, onChange, esOperador, soloConsulta}) => {

    const actualizar = (value, propertyName, index) => {
        const newValue = produce(movimientos, (draft) => {
            draft[index][propertyName] = value;
        });

        onChange(newValue,'movimientos');
    };

    return (
        <>
            <div className="form-row">
                <table className="table table-borderless">
                    <thead className="thead-light">
                    <tr>
                    {!soloConsulta && esOperador && liquidacion.aplazarMovimientos && <th/>}
                        <th style={{width: '20%'}}>TIPO</th>
                        <th style={{width: '20%'}}>CONCEPTO</th>
                        <th style={{width: '20%'}}>NOTA</th>
                        <th style={{width: '30%'}}>DETALLE</th>
                        <th style={{width: '10%'}}>MONTO</th>
                    </tr>
                    </thead>
                    <tbody>
                    {liquidacion.movimientos?.map((item, index) => {
                        return (
                            <tr key={index}>
                                {!soloConsulta && liquidacion.aplazarMovimientos && esOperador &&
                                <td>
                                    <FieldCheckBox name="seleccionado" value={item.seleccionado}
                                                   onChange={(value, pname) => actualizar(value, pname, index)}
                                    />
                                </td>                            
                                }                                
                                <td>{item.tipoNombre}</td>
                                <td>{item.concepto}</td>
                                <td>{item.nota}</td>
                                <td className="p-d-flex p-flex-column">
                                    {(
                                        item.tipoNombre === 'Complementaria' || item.tipoNombre ==='Prestamo'
                                     ) && item.monto > 0
                                    &&
                                    <>
                                    <span>Fecha Deposito: {moment(item.fecha).format('DD/MM/YYYY hh:mm a')}</span>
                                    </>
                                    }
                                    {(item.tipoNombre !== 'Complementaria' &&
                                      item.tipoNombre !=='Prestamo' &&
                                      item.tipoNombre !== 'Tiempo Extra' &&
                                      item.tipoNombre !== 'Faltas')
                                    &&
                                    <>
                                    <span>Fecha Otorgado: {moment(item.fecha).format('DD/MM/YYYY hh:mm a')}</span>
                                    <div>
                                        # Exhibiciones: <span className="bold">{item.numeroDeParcialidades}</span> |
                                        # Pago: <span className="bold">{item.parcialidad}/{item.numeroDeParcialidades}</span>
                                    </div>
                                    </>
                                    }  
                                    {(item.tipoNombre === 'Tiempo Extra')
                                    &&
                                    <>
                                    <span>Fecha Otorgado: {moment(item.fecha).format('DD/MM/YYYY hh:mm a')}</span>
                                    <div>
                                        # Horas Extra: <span className="bold">{item.horasTiempoExtra}</span>
                                    </div>
                                    </>
                                    }
                                    {(item.tipoNombre === 'Faltas')
                                    &&
                                    <>
                                    <span>Fecha Otorgado: {moment(item.fecha).format('DD/MM/YYYY hh:mm a')}</span>
                                    <div>
                                        # Días: <span className="bold">{item.diasDeFalta}</span>
                                    </div>
                                    </>
                                    }                                    
                                </td>
                                <td>{numeral(item.monto).format('$0,0.00')}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </>
    )
};


export default Movimientos;
