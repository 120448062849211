/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import Form from "../../../shared/forms/Form";
import Field from "../../../shared/forms/Field";
import FormFieldLabel, {fieldLabelTypes} from "../../../shared/forms/FormFields/FormFieldLabel";
import GastosPorComprobar from "./GastosPorComprobar";
import * as numeral from "numeral";
import _ from "lodash";

const ComprobacionGastos = ({value:comprobacion, onChange, onValidate, soloLectura}) => {

    const handleChange = (nuevaComprobacion) => {
        onChange(
            {
                ...nuevaComprobacion,
                montoComprobado: _.sumBy(nuevaComprobacion.gastosPorComprobar,'montoComprobado')
            }
        )
    }

    return (
        <>
            <Form
                columns={3}
                values={comprobacion}
                onChange={handleChange}
                onValidate={onValidate}
                formDisabled={comprobacion?.disabled || soloLectura}
            >
                <h5 style={{gridColumnEnd:'span 3'}}>
                    Evidencias Comprobación
                </h5>
                <Field label={"Solicitante"} field={"solicitante"} render={FormFieldLabel} cols={3} />
                <Field label="Monto" field={"montoSolicitado"} type={fieldLabelTypes.currency}
                       render={FormFieldLabel}/>
                <Field label="Monto Comprobado" field={"montoComprobado"} type={fieldLabelTypes.currency}
                       render={FormFieldLabel}/>
                <Field label="Diferencia" field={"montoPorComprobar"}
                       render={() =>  <span className="bold">{numeral(comprobacion?.montoSolicitado - comprobacion?.montoComprobado).format('$0,0.00')}</span> }
                />
                <h5 style={{gridColumnEnd:'span 3'}}>
                    Gastos por Comprobar
                </h5>
                <Field field="gastosPorComprobar" cols={3} render={(props)=>
                    <GastosPorComprobar {...props}/>
                }/>
            </Form>
        </>
    )
}

export default ComprobacionGastos;
