import React, { useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import agGridHelper from "../../shared/ag-grid-helper";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import { getSessionState } from "../../hooks/useSessionState";
import FieldCalendarRange from "../../shared/controls/fieldCalendarRange";
import FieldMultiSelect from "../../shared/controls/fieldMultiSelect";
import { FormattedDate } from "react-intl";
import moment from "moment";
import { Sidebar } from "primereact/sidebar";
import AjusteDieselDetalle from "./ajusteDieselDetalle";
import useApiAjusteDiesel from "../../api/administracion/useApiAjusteDiesel";
import Filtro from "../../shared/componentes/filtro";
import constantes from "../../catalogos/constantes";
import useSeguridad from "../../Seguridad/useSeguridad";

const AjusteDiesel = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.ajusteDiesel);
    const filtroKey = 'FiltroAjusteDiesel';
    const [datos, setDatos] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [movimientoId, setMovimientoId] = useState(false);
    const api = useApiAjusteDiesel();
    const defaultFilter = {
        fechas: [new Date(), new Date()]       
    };
    const onGridReady = async (params) => {
        setGridApi(params.api);
    };

    async function buscar(filtro, paramsApi) {
        filtro = filtro ?? getSessionState(filtroKey);
        agGridHelper.showLoad(gridApi, paramsApi, true);
        var a = await api.filtrar({ ...filtro })
        setDatos(a);
        agGridHelper.hideLoad(gridApi, paramsApi, false);
    }

    async function onSave() {
        setSidebarVisible(false);
        await buscar();
    }

    const verMovimiento = (id) => {
        setSidebarVisible(true);
        setMovimientoId(id);
    };

    function exportar() {
        api.excel(getSessionState(filtroKey));
    }

    const ButtonsHeader = () => {
        return (
            <div className="ag-grid header-icons">
                <ButtonIcon title="Descargar" onClick={exportar} iconName="BiDownload" />
                {!soloLectura &&
                    < ButtonIcon title="Agregar" className="grid-action" iconName="MdAddBox"
                        onClick={() => verMovimiento(0)} />
                }
            </div>
        );
    };

    const RowButtons = ({ data }) => (
        <div className="ag-grid row-icons">
            <button className="btn btn-link btn-sm" title={'Editar'} onClick={() => verMovimiento(data.id)}>
                Ver
            </button>
        </div>
    );

    const DateCellRenderer = ({ value }) => (
        <div>
            <FormattedDate value={value} weekday="short" /> &nbsp;
            {moment(value).format('DD/MM/YYYY')}
        </div>
    );

    return (
        <>
            <Filtro  defaultValue={defaultFilter} filtroKey={filtroKey} handleSubmit={buscar} classButtons="field-iconos btn-group">
                <Filtros />
            </Filtro>

            <Sidebar visible={sidebarVisible} position="right" dismissable={false} className="p-sidebar-md"
                onHide={() => setSidebarVisible(false)}>
                <AjusteDieselDetalle movimientoId={movimientoId} onSave={onSave}
                    close={() => setSidebarVisible(false)} />
            </Sidebar>

            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    frameworkComponents={
                        {
                            buttonsHeader: ButtonsHeader,
                            rowButtons: RowButtons,
                            dateCellRenderer: DateCellRenderer,
                            goToDetail: agGridHelper.GoToDetailFormatter
                        }
                    }
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    rowHeight={35}
                >
                    <AgGridColumn field="fechaRendimiento" headerName="Fecha Rendimiento" cellRenderer="dateCellRenderer" maxWidth={150} />
                    <AgGridColumn field="empleado.nombre" headerName="Operador" cellStyle={{ textAlign: "left" }} minWidth={150} maxWidth={250} />
                    <AgGridColumn field="nota" headerName="Nota" cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn field="diferenciaLitros" headerName="Diferencia (LT)" cellStyle={{ textAlign: "center" }} maxWidth={150}
                        valueFormatter={agGridHelper.decimalFormatter} />
                    <AgGridColumn field="ajuste" headerName="Ajuste ($)" cellStyle={{ textAlign: "right" }}
                        maxWidth={150} valueFormatter={agGridHelper.currencyFormatter} />
                    <AgGridColumn field="exhibiciones" headerName="Exhibiciones" cellStyle={{ textAlign: "center" }} maxWidth={100} />
                    <AgGridColumn field="liquidado" headerName="Liquidado" cellStyle={{ textAlign: "center" }} maxWidth={100} />
                    <AgGridColumn maxWidth={80}
                        headerName="VER"
                        cellRenderer="rowButtons"
                        pinned={'right'}
                        cellClass={'left'}
                        headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>
        </>
    )
};

const Filtros = ({ filtro, onChange }) => {
    return (
        <>
            <FieldMultiSelect name="empleados" source="empleados"
                params={{ puestoIds: [constantes.puestos.operador] }}
                filter={true}
                label="Empleado" colMd={2} colMob={3} value={filtro.empleados}
                onChange={onChange}
                showFilter
                valueAsId={true}
            />
            <FieldCalendarRange name="fechas" label="Fechas" colLg={2} colMd={3} value={filtro.fechas}
                onChange={onChange} />
        </>
    )
};

export default AjusteDiesel;
