import React, {useState,useReducer} from "react";
import useApiPatios from "../../../api/operaciones/useApiPatios";
import agGridHelper from "../../../shared/ag-grid-helper";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import FiltroControlPatios from "../filtroControlPatios";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import constantes from "../../constantes";
import {Sidebar} from "primereact/sidebar";
import SalidasDetalle from "./salidasDetalle";
import {getSessionState} from "../../../hooks/useSessionState";
import Filtro from "../../../shared/componentes/filtro";
import alerts from "../../../shared/alerts";
import {Link} from "react-router-dom";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import FieldTextArea from "../../../shared/controls/fieldTextArea";
import formReducer from "../../../shared/forms/formReducer";
import SalidasDetalleCancelacion from "./salidasDetalleCancelacion";

const Salidas = () => {
    const filtroKey = 'filtroControlPatiosSalidas';
    const [datos, setDatos] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [sidebarCancelacionVisible, setSidebarCancelacionVisible] = useState(false);
    const api = useApiPatios();
    const fechaInicio= new Date();
    fechaInicio.setDate(fechaInicio.getDate() - 1);
    const defaultFilter = {tipo: constantes.tipoControlPatios.salida, fechas:[fechaInicio, new Date()]};
    const [modalCancelar, setModalCancelar] = useState(false); 
    

    const defaultEntity = () => (
        {
            entity: {id: 0, nombreUsuario: localStorage.getItem('UsuarioNombre'), comentarios:""}
        }
    );
    const [{entity}, dispatch] = useReducer(
        formReducer, {}, defaultEntity
    );
    const actualizar = (value, propertyName) => {
        dispatch({type: 'update', value: value, name: propertyName}); 
    };
 
    async function buscar(filtro, paramsApi) {

        if(!validaFiltros(filtro))
        {
            agGridHelper.showLoad(gridApi, paramsApi, true);
            setDatos(await api.filtrar(obtenerFiltro(filtro)));
            agGridHelper.hideLoad(gridApi, paramsApi, false);
        }
        else{
            alerts.aviso('Debe especificar algun Operador o Unidad.');
        }
    }

    function validaFiltros(filtro){
       var result = false;
       
       var diff = filtro.fechas[1] - filtro.fechas[0]; 
       var diasDiff = diff/(1000*60*60*24);
       if(diasDiff>2)
       {
            if((filtro.unidad === undefined || filtro.unidad === null) && (filtro.caja === undefined || filtro.caja === null)){result = true;}
            if((filtro.operador === undefined || filtro.operador === null || filtro.operador === "") && result){result = true;}else{result=false;}
       }

       return result;
    }

    async function nuevo() {
        setSidebarVisible(true);
    }

    const onGridReady = async (params) => {
        setGridApi(params.api);
    };

    async function eliminar(gridApi) { 
        let rows = gridApi.getSelectedRows(); 
        let noProgramadas = rows.find(x => x.estatusId !== constantes.estatusControlPatio.programada);
        
        if (rows?.length === 0 || noProgramadas != null) {
            alerts.aviso('Debe seleccionar salidas programadas para cancelar');
            return;
        }

        setGridApi(gridApi);
        setModalCancelar(true) 
        actualizar(rows[0].id,"id");
        actualizar("","comentarios");
    }


    const ButtonsHeader = ({api}) => {
        return (
            <div className={'ag-grid header-icons'}>                
                <ButtonIcon title={'Descargar'} onClick={exportar} iconName={'BiDownload'}/>
                <ButtonIcon title="Eliminar" onClick={() => eliminar(api)} iconName={'RiDeleteBinLine'}/>
                <ButtonIcon title="Agregar" className={'grid-action center'} iconName={'MdAddBox'} onClick={nuevo}/>
            </div>
        );
    };

    const MostrarCancelacionPaso = (UsuarioCancela,comentarios) =>{ 
        if (UsuarioCancela !== null || comentarios != null) {
                
            actualizar(UsuarioCancela,"nombreUsuario");
            actualizar(comentarios,"comentarios");

            setSidebarCancelacionVisible(true);
            return;
        }
    }
    
    const ButtonsDetail = ({data}) => { 
        let paseCancelado = data.estatusId === constantes.estatusControlPatio.cancelada;
        return (  
            <> 
            <div className={'ag-grid header-icons'}>  
                {!paseCancelado &&
                    <Link to={ data?.tipoId === constantes.tipoControlPatio.salida ?'/controlDePatios/salida/1/'+ data.id
                    : '/controlDePatios/editar/0/'+ data.id}>Ver</Link>
                }
                {paseCancelado && 
                    <Link onClick={() => MostrarCancelacionPaso(data?.usuarioCancela, data?.comentarios)}>Ver</Link> 
                }
            </div>  
            </>
        );
    };

    const onSave = async () => {
        setSidebarVisible(false);
        const filtro = getSessionState(filtroKey) ?? defaultFilter;
        await buscar(filtro);
    };

    function exportar() {
        api.excel(obtenerFiltro(), false);
    }

    function obtenerFiltro(filtro) {
        filtro = filtro ??  getSessionState(filtroKey);
        return {...filtro, unidadId: filtro.unidad?.id, cajaId: filtro.caja?.id , nombreCaja:filtro.nombreCaja};
    }
  
    function getWidth() {
        if (window.innerWidth < 768)
            return '75vw';
        if (window.innerWidth < 1000)
            return '55vw';
        return '35vw';
    }

    const footer = (
        <div className="mt-2">
            <Button label="Regresar" className="p-button-outlined" onClick={() => setModalCancelar(false)}></Button>
            <Button label="Confirmar" onClick={cancelarSalida}></Button>
        </div>
    );

    async function cancelarSalida() { 
        let rows = gridApi.getSelectedRows();
        let noProgramadas = rows.find(x => x.estatusId !== constantes.estatusControlPatio.programada);

        if (rows?.length === 0 || noProgramadas != null) {
            alerts.aviso('Debe seleccionar salidas programadas para cancelar');
            return;
        }
        if (entity?.comentarios === "") {
            alerts.aviso('Debe ingresar el motivo de cancelación.');
            return;
        }
        setModalCancelar(false);
        const ids = rows.map(e => e.id).join(",");
        if (await alerts.preguntarSiNoAdvertencia('Se cancearán las salidas programadas ¿Desea continuar?')) {
            await api.cancelarSalidasProgramadas(ids, entity?.comentarios); 
            buscar().then();
        }

    }

    return (
        <>
            <Sidebar visible={sidebarVisible} position="right" dismissable={false} className="p-sidebar-md"
                     onHide={() => setSidebarVisible(false)}>
                <SalidasDetalle onSave={onSave} close={() => setSidebarVisible(false)}/>
            </Sidebar>

            <Sidebar visible={sidebarCancelacionVisible} position="right" dismissable={false} className="p-sidebar-md"
                     onHide={() => setSidebarCancelacionVisible(false)}>
                <SalidasDetalleCancelacion entity={entity} close={() => setSidebarCancelacionVisible(false)}/>
            </Sidebar>

            <Filtro filtroKey={filtroKey} handleSubmit={buscar} defaultValue={defaultFilter} gridApi={gridApi}>
                <FiltroControlPatios tipo={constantes.tipoControlPatios.salida}/>
            </Filtro>

            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    rowSelection={'multiple'}
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    frameworkComponents={
                        {
                            buttonsHeader: ButtonsHeader,
                            dateFormatter: agGridHelper.DateTimeFormatter,
                            goToDetail: ButtonsDetail,
                        }
                    }
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                >
                    <AgGridColumn maxWidth={40} checkboxSelection={true}/>
                    <AgGridColumn field="codigo" headerName="PASE" flex={2}/>
                    <AgGridColumn field="operador" headerName="OPERADOR"/>
                    <AgGridColumn field="tipo" headerName="TIPO"/>
                    <AgGridColumn field="fecha" headerName="FECHA" cellRenderer="dateFormatter" showTime
                                  minWidth={150} flex={3}/>
                    <AgGridColumn field="nombreUnidad" headerName="NO TRACTOR" flex={2}/>
                    <AgGridColumn field="nombreCaja" headerName="NO REMOLQUE" flex={2}/>
                    <AgGridColumn field="ubicacion" headerName="UBICACION" flex={2}/>
                    <AgGridColumn field="destino" headerName="DESTINO"/>               
                    <AgGridColumn field="vigilante" headerName="VIGILANTE"/>
                    <AgGridColumn field="estadoUnidad" headerName="ESTADO UNIDAD" flex={2}/>
                    <AgGridColumn field="estadoRemolque" headerName="ESTADO REMOLQUE" flex={2}/>
                    <AgGridColumn field="estadoLlantas" headerName="ESTADO LLANTAS" flex={2}/>
                    <AgGridColumn field="estatus" headerName="ESTATUS" flex={2}/>
                    <AgGridColumn maxWidth={120} headerName="VER"  cellRenderer="goToDetail" pinned={'right'}
                                  path={"ruta"} headerComponent="buttonsHeader"/>
                </AgGridReact>
            </div>

             {/*Modal para cancelar la salida*/}
             {modalCancelar && 
             <Dialog header={"¿Desea cancelar el pase de salida?"} footer={footer} visible={modalCancelar} style={{width: getWidth()}} modal onHide={() => setModalCancelar(false)}>
                <div className="form-row">     
                            <FieldTextBox name="usuario" label="Usuario:" colLg={1} colMd={52} width="15vw"
                                    value={entity.nombreUsuario} disabled/>
 
                            <FieldTextArea name="comentarios" label="Motivo de cancelación:" colMd={52} rows={11} 
                                    value={entity.comentarios} onChange={actualizar}/> 
                </div>
            </Dialog>
            }
        </>
    )
};

export default Salidas;
