import React from "react";
import { useState } from "react";
import agGridHelper from "../../shared/ag-grid-helper";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import { Sidebar } from "primereact/sidebar";
import useApiUsuarios from "../../api/catalogos/useApiUsuarios";
import FiltroUsuarios from "./filtroUsuarios";
import { getSessionState } from "../../hooks/useSessionState";
import DetalleUsuarios from "./detalleUsuarios";
import Filtro from "../../shared/componentes/filtro";
import constantes from "../constantes";
import useSeguridad from "../../Seguridad/useSeguridad";

const Usuarios = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.usuarios);
    const filtroKey = 'FiltroUsuarios';
    const [datos, setDatos] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [usuarioId, setUsuariosId] = useState(false);
    const api = useApiUsuarios();

    async function buscar(filtro, paramsApi) {
        agGridHelper.showLoad(gridApi, paramsApi, true);
        setDatos(await api.filtrar(filtro ?? getSessionState(filtroKey)));
        agGridHelper.hideLoad(gridApi, paramsApi, false);

    }

    const onGridReady = async (params) => {
        setGridApi(params.api);
    };

    async function nuevo() {
        setSidebarVisible(true);
        setUsuariosId(0);
    }

    async function onSave() {
        setSidebarVisible(false);
        await buscar();
    }

    function exportar() {
        api.excel(getSessionState(filtroKey));
    }

    const ButtonsHeader = () => {
        return (
            <div className={'ag-grid header-icons'}>
                <ButtonIcon title={'Descargar'} onClick={exportar} iconName={'BiDownload'} />
                {!soloLectura &&
                    <ButtonIcon title="Agregar" className={'grid-action'} iconName={'MdAddBox'} onClick={nuevo} />
                }
            </div>
        );
    };

    const editUsuarios = (id) => {
        setSidebarVisible(true);
        setUsuariosId(id);
    };

    const RowButtons = ({ data, context }) => (
        <div className={'ag-grid row-icons'}>
            <ButtonIcon title={'Editar'} iconName={'FaRegEdit'} onClick={() => context.editUsuarios(data.id)} />
        </div>
    );

    return (
        <>
            <Sidebar visible={sidebarVisible} position="right" dismissable={false}
                className="p-sidebar-md" onHide={() => setSidebarVisible(false)}>
                <DetalleUsuarios usuarioId={usuarioId} onSave={onSave} soloLectura={soloLectura}
                    close={() => setSidebarVisible(false)} />
            </Sidebar>

            <Filtro filtroKey={filtroKey} handleSubmit={buscar} defaultValue={{estatus:1}} classButtons="field-iconos btn-group" gridApi={gridApi}>
                <FiltroUsuarios />
            </Filtro>

            <div className="ag-theme-balham grid-principal com-usuarioes">
                <AgGridReact
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    className="left"
                    rowSelection={'multiple'}
                    frameworkComponents={
                        {
                            rowButtons: RowButtons,
                            goToDetail: agGridHelper.GoToDetailFormatter,
                            buttonsHeader: ButtonsHeader,
                            dateFormatter: agGridHelper.DateFormatter,
                            imageFormatter: agGridHelper.ImageFormatter
                        }
                    }
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    rowHeight={35}
                    context={{
                        editUsuarios: editUsuarios
                    }}
                    getRowClass={(params) => params.data.nombreColor}
                >
                    <AgGridColumn field="nombres" headerName="NOMBRE" text cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn field="perfiles" headerName="PERFIL" text cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn field="username" headerName="USUARIO" text cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn field="estatus" headerName="ESTATUS" text cellStyle={{ textAlign: "left" }} />
                    <AgGridColumn maxWidth={80}
                        headerName="VER"
                        cellRenderer="rowButtons"
                        pinned={'right'}
                        cellClass={'left'}
                        headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>
        </>
    )
};

export default Usuarios;
