import React from "react";
import * as numeral from "numeral";
import moment from "moment";

const PensionAlimenticia = ({liquidacion, pensionAlimenticia, onChange, esOperador}) => {
    return (
        <>
            <div className="form-row">
                <table className="table table-borderless">
                    <thead className="thead-light">
                    <tr>
                   
                        <th style={{width: '40%'}}>FECHA</th>
                        <th style={{width: '40%'}}>MONTO</th>
                    </tr>
                    </thead>
                    <tbody>
                    {liquidacion.pensionAlimenticia?.map((item, index) => {
                        return (
                            <tr key={index}>                                                         
                                <td>{moment(item.fecha).format('DD/MM/YYYY hh:mm a')}</td>
                                <td>{numeral(item.monto).format('$0,0.00')}</td>                           
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </>
    )
};
export default PensionAlimenticia;